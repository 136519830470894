import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import { IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import { ILogbookVersionWithLogbookDetail, IPassedIssueDateLogbooks } from './logbook-versions.model';

@Injectable({
  providedIn: 'root',
})
export class LogbookVersionsService {
  private readonly LOGBOOKS = {
    LOGBOOK_VERSIONS_URL: `${this.baseUrl}/logbook-versions/`,
    PASSED_ISSUE_DATE_URL: `${this.baseUrl}/logbook-versions/check-passed-issue-date/`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {}

  public getLogbookVersionsPassedIssue(
    params: HttpParams,
  ): Observable<IGetManyResponse<IPassedIssueDateLogbooks>> {
    return this.http.get<IGetManyResponse<IPassedIssueDateLogbooks>>(this.LOGBOOKS.PASSED_ISSUE_DATE_URL, {
      params,
    });
  }

  public getLogbookVersions(params: HttpParams): Observable<IGetManyResponse<ILogbookVersionWithLogbookDetail>> {
    return this.http.get<IGetManyResponse<ILogbookVersionWithLogbookDetail>>(this.LOGBOOKS.LOGBOOK_VERSIONS_URL, {
      params,
    });
  }
}
