import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatDatepickerComponent } from './scw-mat-datepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, NgxDaterangepickerMd.forRoot()],
  exports: [ScwMatDatepickerComponent],
  declarations: [ScwMatDatepickerComponent],
})
export class ScwMatDatepickerModule {}
