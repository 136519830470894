import { IClientSettingsState } from './client.model';
import * as Actions from './client.actions';
import { Action } from '@ngrx/store';

export const clientSettingsState: IClientSettingsState = {
  clientSettingsData: null,
  clientSettingsDataLoaded: false,
  clientSettingsDataLoading: false,
  clientEditSettingsLoaded: false,
  clientEditSettingsLoading: false,
  clientCommonInformation: null,
};

export function clientSettingsReducer(
  state: IClientSettingsState = clientSettingsState,
  baseAction: Action,
): IClientSettingsState {
  const action = baseAction as Actions.ClientActions;
  switch (action.type) {
    case Actions.GET_CLIENT_SETTINGS:
      return {
        ...state,
        clientSettingsData: null,
        clientSettingsDataLoading: true,
        clientSettingsDataLoaded: false,
      };
    case Actions.GET_CLIENT_SETTINGS_COMPLETED:
      return {
        ...state,
        clientSettingsData: action.payload,
        clientSettingsDataLoading: false,
        clientSettingsDataLoaded: true,
      };
    case Actions.EDIT_CLIENT_SETTINGS:
      return {
        ...state,
        clientEditSettingsLoading: true,
        clientEditSettingsLoaded: false,
      };
    case Actions.EDIT_CLIENT_SETTINGS_COMPLETED:
      return {
        ...state,
        clientEditSettingsLoading: false,
        clientEditSettingsLoaded: true,
      };
    case Actions.SET_CLIENT_INFO:
      return {
        ...state,
        clientCommonInformation: action.payload,
      };
    case Actions.SET_ASK_FOR_PIN_ON_ACTION:
      return {
        ...state,
        clientCommonInformation: { ...state.clientCommonInformation, askForPinOnAction: action.payload },
      };
    case Actions.SET_IS_PDF_ENHANCEMENT:
      return {
        ...state,
        clientCommonInformation: { ...state.clientCommonInformation, isPdfEnhancement: action.payload },
      };
    default:
      return state;
  }
}
