import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ITableQuery } from '../model/interface/common-page.model';
import * as _ from 'lodash';
import { IIssuer } from '../component/issuer/issuer.model';

export class ServiceUtilities {
  public static prepareGenericHttpParamsForRequest<T>(
    query: T & Partial<ITableQuery>,
    withPage: boolean = false,
  ): HttpParams {
    let httpParams: HttpParams = new HttpParams().append('ordering', '-id');
    const { page, rowsPerPage, sort, filters, search, ...otherParams } = query;

    if (page && rowsPerPage) {
      if (withPage) {
        httpParams = httpParams.append('page', page).append('limit', rowsPerPage);
      } else {
        httpParams = httpParams.append('offset', (page - 1) * rowsPerPage).append('limit', rowsPerPage);
      }
    }

    if (search) {
      httpParams = httpParams.append('search', search);
    }

    filters?.forEach((filter) => {
      httpParams = httpParams.append(
        ServiceUtilities.convertSnakeCaseExceptCrudUtils(filter.field),
        Array.isArray(filter.values) ? filter.values.join() : filter.values,
      );
    });

    Object.entries(otherParams).forEach(([key, value]) => {
      httpParams = httpParams.append(ServiceUtilities.convertSnakeCaseExceptCrudUtils(key), value);
    });

    if (!_.isEmpty(sort?.column)) {
      httpParams = httpParams.set(
        'ordering',
        `${sort?.type ?? ''}${ServiceUtilities.underscorePreservingSnakeCase(sort?.column)}`,
      );
    }

    return httpParams;
  }

  public static prepareGenericBodyForRequest<T>(
    query: T & Partial<ITableQuery>,
    withPage: boolean = false,
  ): Record<string, string | number> {
    let body: Record<string, string | number> = { ordering: '-id' };
    const { page, rowsPerPage, sort, filters, search, ...otherParams } = query;

    if (page && rowsPerPage) {
      if (withPage) {
        body = { ...body, page, limit: rowsPerPage };
      } else {
        body = { ...body, offset: (page - 1) * rowsPerPage, limit: rowsPerPage };
      }
    }

    if (search) {
      body = { ...body, search };
    }

    filters?.forEach((filter) => {
      body = {
        ...body,
        [ServiceUtilities.convertSnakeCaseExceptCrudUtils(filter.field)]: Array.isArray(filter.values)
          ? filter.values.join()
          : filter.values,
      };
    });

    Object.entries(otherParams).forEach(([key, value]) => {
      body = { ...body, [ServiceUtilities.convertSnakeCaseExceptCrudUtils(key)]: value };
    });

    if (!_.isEmpty(sort?.column)) {
      body = {
        ...body,
        ordering: `${sort?.type ?? ''}${ServiceUtilities.underscorePreservingSnakeCase(sort?.column)}`,
      };
    }

    return body;
  }

  private static convertSnakeCaseExceptCrudUtils(key: string): string {
    const [firstPart, ...rest] = key.split('__');
    return [_.snakeCase(firstPart), ...rest].join('__');
  }

  public static underscorePreservingSnakeCase(value: string | undefined): string {
    return (value ?? '')
      .replace(/_/g, '_dunderseparator_')
      .split('_')
      .reduce((original: string, current: string) => {
        return original.concat(current === 'dunderseparator' ? '_' : _.snakeCase(current));
      }, '');
  }

  public static makeBasicAuthHeaderFromIssuer(issuer: IIssuer): HttpHeaders {
    const headerValue: string = `Basic ${window.btoa(`${issuer.username}:${issuer.password}`)}`;

    return new HttpHeaders({ Authorization: headerValue });
  }
}
