import { IDatatableSort } from './app/shared/component/datatable/datatable.model';
import { EActionableType, EActionType } from './app/shared/model/enum/constants';
import { IRightAggregation } from './app/store/main/main.model';
import { IGenericObject } from './app/shared/model/interface/generic.model';
import { IButtonConfig } from './app/shared/component/scw-mat-ui/scw-mat-treetable/scw-mat-treetable.model';

export interface IPage {
  title: string;
  titleKey: string;
  icon: string;
  relatedModule?: string;
}

export interface ITableHeader {
  value: string;
  isOrderColumn?: boolean;
  name: string;
  icon?: string;
  type?: PageConfigurationTypes | string;
  pointerEvents?: string;
  class?: string;
  width?: string;
  disabled?: boolean;
  selected?: boolean;
  sortable?: boolean;
  draggable?: boolean;
  alwaysAtTheEnd?: boolean;
  linkedTo?: string;
  formattedValue?: string;
  sort?: IDatatableSort;
  subTitle?: string;
  tooltip?: ITooltip;
  checkboxLocation?: 'right' | 'bottom';
  checkbox?: boolean;
  formIoType?: string;
  selectedInnerLabels?: IFormIoOptions[];
  originalValue?: string;
  formIds?: string[];
  formVersionDbId?: number;
  formName?: string;
  formNames?: string[];
  formsWithNames?: string[];
  versionNumber?: number;
  button?: boolean;
  buttonConfig?: IButtonConfig;
  interactiveColumn?: boolean;
  showCancelButton?: boolean;
  isBooleanField?: boolean;
  hasBorderColoring?: boolean;
  actionColumn?: boolean;
}

export interface IFormIoOptions {
  value: string;
  label: string;
  order?: string;
  shortcut?: string;
}

export interface IMenuItems {
  menus: IMenu[];
  clientLogo: any;
  singleSiteAndLine: boolean;
}

export interface IMenu {
  key: string;
  id: number;
  name: string;
  staticName: any;
  levelId?: number;
  parentId: number;
  link: string;
  orders: number;
  status: boolean;
  icon: string;
  external: boolean;
  inProgress: boolean;
  isNew: boolean;
  appId: number;
  isUpgradeBadgeEnabled: boolean;
  requiredRights: IRightAggregation;
}

export enum PageConfigurationTypes {
  TABLE = 'table',
}

export interface ITableQuerySort {
  column: string;
  type: '-' | '';
}

export interface ITooltip {
  text?: string;
  tooltipClass?: string;
  icon?: string;
}

export interface IDatatableWithDraggableRowHeader {
  value: string;
  name: string;
  icon?: string;
  subTitle?: string;
  tooltip?: ITooltip;
  isOrderColumn?: boolean;
  width?: string;
}

export interface IFormSubmissionUserAction {
  editUser: IFormSubmissionUserActionTaker;
  reason: string;
  createdAt: string;
  logbookId: number;
  fields: any[];
  changes: any[];
  meta: IGenericObject<any>;
  fieldLabels?: any[];
  action: EActionType;
  createdBy?: ICreatedBy;
  mobileLogbookSubmission?: boolean;
}

export interface IFormSubmissionUserActionTaker {
  username: string;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  title: string;
}

export interface ICreatedBy {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
}

export interface IUserAction {
  id: number;
  client: number;
  user: IUserActionTaker;
  objectTypeName: EActionableType;
  objectType: number;
  objectId: number;
  action: EActionType;
  userId: number;
  meta: any;
  reason: string;
  createdAt: string;
  createdBy: ICreatedBy;
  before: IGenericObject<any>;
  after: IGenericObject<any>;
}

export interface IUserActionTaker {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
}

export interface IFormIoFile {
  data: Record<string, string>;
  hash: string;
  name: string;
  originalName: string;
  size: number;
  storage: string;
  type: string;
  url: string;
}

export interface ITableHeaderGroup {
  type: string;
  groupItems: ITableHeader[];
}
