import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatFormComponent } from './scw-mat-form.component';

@NgModule({
  imports: [CommonModule],
  exports: [ScwMatFormComponent],
  declarations: [ScwMatFormComponent],
})
export class ScwMatFormModule {}
