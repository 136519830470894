import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { forkJoin, Observable, of, map, mergeMap } from 'rxjs';
import { IAddRole, IBulkEditRole, IRightAssignment } from './roles.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IRightAssignmentTableData, IRole, IRoleSettings } from '../../../view/settings/roles/roles.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IRight } from '../rights/rights.model';
import { RightsService } from '../rights/rights.service';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { disabledWorkflowAssignedRights } from '../../../view/settings/roles/roles.constants';
import { ERights } from '../../main/main.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly ROLES = {
    ROLE_URL: `${this.baseUrl}/roles/`,
    WORKFLOW_ASSIGNED_ROLE_URL: `${this.baseUrl}/roles/workflow-assignment/`,
    BULK_EDIT_URL: `${this.baseUrl}/roles/bulk/`,
  };

  private readonly RIGHT_ASSIGNMENTS = {
    RIGHT_ASSIGNMENT_URL: `${this.baseUrl}/right-assignments/`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly rightsService: RightsService,
    private readonly translate: TranslateService,
  ) {}

  public getRoles(params: HttpParams): Observable<IGetManyResponse<IRole>> {
    return this.http.get<IGetManyResponse<IRole>>(this.ROLES.ROLE_URL, { params });
  }

  public getRolePermissions(params: HttpParams): Observable<IRoleSettings> {
    const httpParams: HttpParams = new HttpParams().append('limit', 5000);
    let roleRightParams: HttpParams = httpParams.append('object_type', 'role');

    return this.getRoles(params)
      .pipe(
        mergeMap((roles: IGetManyResponse<IRole>) => {
          const roleIdArray = roles.data.map((role: IRole) => role.id).join(',');
          roleRightParams = roleRightParams.append('object_id__in', roleIdArray);
          return forkJoin([
            of(roles),
            this.getRoleRightsAssignments(roleRightParams),
            this.rightsService.getRights(httpParams),
            this.getWorkflowAssignedRoles(roleIdArray),
          ]);
        }),
      )
      .pipe(
        map((responseList) => {
          const roles: IRole[] = _.get(responseList, '0.data', []);
          const rightsAssignments: IRightAssignment[] = _.get(responseList, '1.data', []);
          const rights: IRight[] = _.get(responseList, '2.data', []);

          return {
            rights,
            roles: roles.map((role: IRole) => {
              return {
                ...role,
                rights: rightsAssignments.filter(
                  (rightAssignment: IRightAssignment) => rightAssignment.objectId === role.id,
                ),
              };
            }),
            workflowAssignedRoles: _.get(responseList, '3.data', []),
            page: _.get(responseList, '0.page', []),
            pageCount: _.get(responseList, '0.pageCount', []),
            count: _.get(responseList, '0.count', []),
            total: _.get(responseList, '0.total', []),
            success: _.get(responseList, '0.success', []),
            date: _.get(responseList, '0.date', []),
            message: _.get(responseList, '0.message', []),
          };
        }),
      );
  }

  public addRole(role: IAddRole, issuer: IIssuer | null): Observable<IBaseOneResponse<IAddRole>> {
    return this.http.post<IBaseOneResponse<IAddRole>>(this.ROLES.ROLE_URL, {
      issuer,
      payload: role,
    });
  }

  public editRole(
    role: IAddRole,
    id: number,
    issuer: IIssuer | null,
    forceCheckOut?: boolean,
  ): Observable<IBaseOneResponse<IAddRole>> {
    return this.http.put<IBaseOneResponse<IAddRole>>(`${this.ROLES.ROLE_URL}${id}/`, {
      issuer,
      payload: { ...role, forceUpdate: forceCheckOut },
    });
  }

  public bulkEditRole(
    roles: IBulkEditRole[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IRole>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IRole>>>(`${this.ROLES.BULK_EDIT_URL}`, {
      issuer: issuerAndReason.issuer,
      reason: issuerAndReason.reason,
      payload: roles,
    });
  }

  public getRoleRightsAssignments(params: HttpParams): Observable<IGetManyResponse<IRightAssignment>> {
    return this.http.get<IGetManyResponse<IRightAssignment>>(this.RIGHT_ASSIGNMENTS.RIGHT_ASSIGNMENT_URL, { params });
  }

  public prepareRoleRightAssignmentTableData(
    rights: IRight[],
    rightAssignments?: IRightAssignment[],
  ): IRightAssignmentTableData[] {
    return rights.map((right: IRight): IRightAssignmentTableData => {
      const roleRightAssignment: IRightAssignment = _.find(rightAssignments, { rightId: right.id }) as IRightAssignment;
      return {
        id: roleRightAssignment?.id ?? null,
        rightId: right.id,
        rightNameId: right.nameId,
        allow: Boolean(roleRightAssignment?.allow),
        deny: Boolean(roleRightAssignment?.deny),
        isCheckboxDisabled: disabledWorkflowAssignedRights.includes(right.nameId),
        tooltip: this.getRoleRightAssignmentTableTooltip(right.nameId),
      };
    });
  }

  public getRoleRightAssignmentTableTooltip(rightNameId: string): string | undefined {
    switch (rightNameId) {
      case ERights.ENTRY_CREATE:
        return this.translate.instant('roles.createLogTooltip');
      case ERights.SETTING_LOGBOOK_EDIT:
      case ERights.SETTING_FORM_EDIT:
        return this.translate.instant('roles.createEditLogbookFormTooltip');
      case ERights.MANUAL_STATE_CHANGE:
        return this.translate.instant('roles.manualStateChangeTooltip');
      case ERights.OVERRIDE_AUTOMATION_AND_RULES:
        return this.translate.instant('roles.overrideAllRulesAndAutomationsTooltip');
      default:
        return;
    }
  }

  public getWorkflowAssignedRoles(roleIds: string): Observable<IGetManyResponse<number[]>> {
    const params: HttpParams = new HttpParams().set('role_ids', roleIds);
    return this.http.get<IGetManyResponse<number[]>>(this.ROLES.WORKFLOW_ASSIGNED_ROLE_URL, { params });
  }
}
