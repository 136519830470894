import { IRolesState } from './roles.model';
import * as Actions from './roles.actions';
import { Action } from '@ngrx/store';

export const rolesState: IRolesState = {
  rolesDataLoaded: false,
  rolesDataLoading: false,
  rolesData: null,
  rightsData: null,
  addRoleLoading: false,
  addRoleLoaded: false,
  editRoleLoading: false,
  editRoleLoaded: false,
  bulkEditRoleLoading: false,
  bulkEditRoleLoaded: false,
};

export function roleReducer(
  state: IRolesState = rolesState,
  baseAction: Action,
): IRolesState {
  const action = baseAction as Actions.RolesActions;

  switch (action.type) {
    case Actions.ROLES_DATA_LOADING:
      return {
        ...state,
        ...{
          rolesDataLoading: true,
          rolesDataLoaded: false,
        },
      };
    case Actions.ROLES_DATA_LOADED:
      return {
        ...state,
        ...{
          rolesData: action.payload.roles,
          rightsData: action.payload.rights,
          rolesDataLoading: false,
          rolesDataLoaded: true,
        },
      };
    case Actions.ADD_ROLE:
      return {
        ...state,
        addRoleLoading: true,
        addRoleLoaded: false,
      };
    case Actions.ADD_ROLE_COMPLETED:
      return {
        ...state,
        addRoleLoading: false,
        addRoleLoaded: true,
      };
    case Actions.EDIT_ROLE:
      return {
        ...state,
        editRoleLoading: true,
        editRoleLoaded: false,
      };
    case Actions.EDIT_ROLE_COMPLETED:
      return {
        ...state,
        editRoleLoading: false,
        editRoleLoaded: true,
      };
    case Actions.BULK_EDIT_ROLE:
      return {
        ...state,
        bulkEditRoleLoading: true,
        bulkEditRoleLoaded: false,
      };
    case Actions.BULK_EDIT_ROLE_COMPLETED:
      return {
        ...state,
        bulkEditRoleLoading: false,
        bulkEditRoleLoaded: true,
      };
    default:
      return state;
  }
}
