import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './user-configuration.actions';
import { of } from 'rxjs';
import * as AppActions from '../app/actions';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import { UserService } from '../../shared/service/user/user.service';
import * as UsersActions from '../settings/users/users.actions';
import * as UserActions from '../../store/user/actions';
import { IBaseOneResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IUser } from '../settings/users/users.model';
import { IConfig } from '../user/model';

@Injectable()
export class UserConfigurationEffects {
  constructor(
    private actions$: Actions,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly service: UserService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {}

  getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_USER_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.UpdateUserConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.editUserConfiguration(payload.configuration).pipe(
          switchMap((response: IBaseOneResponse<IUser>) => {
            return of(
              new ObjectActions.UpdateUserConfigurationLoaded(response.data.meta as IConfig),
              new UserActions.SetUserConfiguration(response.data.meta as IConfig),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
