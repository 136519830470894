import * as Actions from './logbook-master-data.actions';
import { Action } from '@ngrx/store';
import { ILogbookMasterDataState } from './logbook-master-data.model';

export const logbookMasterDataState: ILogbookMasterDataState = {
  logbookMasterDataData: null,
  logbookMasterDataDataLoading: false,
  logbookMasterDataDataLoaded: false,
  logbookMasterDataHistoryData: null,
  logbookMasterDataHistoryDataLoading: false,
  logbookMasterDataHistoryDataLoaded: false,
  logbookMasterDataDetail: null,
  logbookMasterDataDetailLoading: false,
  logbookMasterDataDetailLoaded: false,
  logbookMasterDataApprovalWorkflow: null,
  logbookMasterDataApprovalWorkflowLoading: false,
  logbookMasterDataApprovalWorkflowLoaded: false,
  logbookMasterDataWorkflowSteps: null,
  logbookMasterDataWorkflowStepsLoading: false,
  logbookMasterDataWorkflowStepsLoaded: false,
};

export function logbookMasterDataReducer(
  state: ILogbookMasterDataState = logbookMasterDataState,
  baseAction: Action,
): ILogbookMasterDataState {
  const action = baseAction as Actions.LogbookMasterDataActions;

  switch (action.type) {
    case Actions.LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD:
    case Actions.LOGBOOK_MASTER_DATA_DATA_LOAD:
      return {
        ...state,
        ...{
          logbookMasterDataDataLoading: true,
          logbookMasterDataDataLoaded: false,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_DATA_LOADED:
      return {
        ...state,
        ...{
          logbookMasterDataData: { ...action.payload },
          logbookMasterDataDataLoading: false,
          logbookMasterDataDataLoaded: true,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_HISTORY_DATA_LOADED:
      return {
        ...state,
        ...{
          logbookMasterDataHistoryData: { ...action.payload },
          logbookMasterDataHistoryDataLoading: false,
          logbookMasterDataHistoryDataLoaded: true,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_DETAIL_LOAD:
      return {
        ...state,
        ...{
          logbookMasterDataDetailLoading: true,
          logbookMasterDataDetailLoaded: false,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_DETAIL_LOADED:
      return {
        ...state,
        ...{
          logbookMasterDataDetail: { ...action.payload },
          logbookMasterDataDetailLoading: false,
          logbookMasterDataDetailLoaded: true,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD:
      return {
        ...state,
        ...{
          logbookMasterDataApprovalWorkflowLoading: true,
          logbookMasterDataApprovalWorkflowLoaded: false,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOADED:
      return {
        ...state,
        ...{
          logbookMasterDataApprovalWorkflow: { ...action.payload },
          logbookMasterDataApprovalWorkflowLoading: false,
          logbookMasterDataApprovalWorkflowLoaded: true,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD:
      return {
        ...state,
        ...{
          logbookMasterDataWorkflowStepsLoading: true,
          logbookMasterDataWorkflowStepsLoaded: false,
        },
      };
    case Actions.LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOADED:
      return {
        ...state,
        ...{
          logbookMasterDataWorkflowSteps: { ...action.payload },
          logbookMasterDataWorkflowStepsLoading: false,
          logbookMasterDataWorkflowStepsLoaded: true,
        },
      };
    default:
      return state;
  }
}
