import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, from, of } from 'rxjs';
import * as FilterLogbookActions from './logbook.actions';
import { LogbookService } from '../../../shared/service/filter/logbook.service';
import { DefaultQueryParameters } from '../../../../constants';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class FilterLogbookEffects {
  constructor(
    private actions$: Actions<FilterLogbookActions.FilterLogbookActions>,
    public service: LogbookService,
    public helperService: HelperService,
  ) {
  }

  loadFilterLogbooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterLogbookActions.START_LOAD_FILTER_LOGBOOKS),
      switchMap((objectData: FilterLogbookActions.StartLoadFilterLogbooks) => {
        if (typeof objectData.page === 'undefined') {
          objectData.page = 1;
        }

        if (typeof objectData.limit === 'undefined') {
          objectData.limit = DefaultQueryParameters.LIMIT;
        }

        return from(
          this.service.getLogbooks({
            params: {
              page: objectData.page,
              limit: objectData.limit,
              filterParams: objectData.filter,
              includeArchived: objectData.includeArchived,
              approvalStatuses: objectData.approvalStatuses,
            },
            sortParams: objectData.sortParams,
          }),
        ).pipe(
          switchMap((response) => {
            const modifiedResponse: any[] = [];
            response.forEach((item: any) => {
              modifiedResponse.push({
                ...item,
                name: this.helperService.getNameWithArchiveLabel(item.isArchived, item.name),
              });
            });
            return of(new FilterLogbookActions.FilterLogbooksLoaded(modifiedResponse));
          }),
          catchError(() => {
            return of(new FilterLogbookActions.FilterLogbooksLoaded([]));
          }),
        );
      }),
    ),
  );
}
