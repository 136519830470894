import { NgModule } from '@angular/core';
import { ScwMatInputModule } from './scw-mat-input/scw-mat-input.module';
import { ScwMatButtonModule } from './scw-mat-button/scw-mat-button.module';
import { ScwMatPickerModule } from './scw-mat-picker/scw-mat-picker.module';
import { ScwMatSearchModule } from './scw-mat-search/scw-mat-search.module';
import { ScwMatSmallButtonModule } from './scw-mat-small-button/scw-mat-small-button.module';
import { ScwMatDropdownModule } from './scw-mat-dropdown/scw-mat-dropdown.module';
import { ScwMatSelectModule } from './scw-mat-select/scw-mat-select.module';
import { ScwMatCheckboxModule } from './scw-mat-checkbox/scw-mat-checkbox.module';
import { ScwMatFormModule } from './scw-mat-form/scw-mat-form.module';
import { ScwMatDatepickerModule } from './scw-mat-datepicker/scw-mat-datepicker.module';
import { ScwMatBorderColoringModule } from './scw-mat-border-coloring/scw-mat-border-coloring.module';
import { ScwMatTextareaModule } from './scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatButtonGroupModule } from './scw-mat-button-group/scw-mat-button-group.module';
import { ScwMatModalModule } from './scw-mat-modal/scw-mat-modal.module';
import { ScwMatTreetableModule } from './scw-mat-treetable/scw-mat-treetable.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScwMatCheckboxGroupModule } from './scw-mat-checkbox-group/scw-mat-checkbox-group.module';

@NgModule({
  imports: [
    MatFormFieldModule,
    ScwMatInputModule,
    ScwMatButtonModule,
    ScwMatPickerModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    ScwMatDropdownModule,
    ScwMatSelectModule,
    ScwMatCheckboxModule,
    ScwMatFormModule,
    ScwMatDatepickerModule,
    ScwMatBorderColoringModule,
    ScwMatTextareaModule,
    ScwMatButtonGroupModule,
    ScwMatModalModule,
    ScwMatTreetableModule,
    ScwMatCheckboxGroupModule,
  ],
  exports: [
    MatFormFieldModule,
    ScwMatInputModule,
    ScwMatButtonModule,
    ScwMatPickerModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    ScwMatDropdownModule,
    ScwMatSelectModule,
    ScwMatCheckboxModule,
    ScwMatFormModule,
    ScwMatDatepickerModule,
    ScwMatBorderColoringModule,
    ScwMatTextareaModule,
    ScwMatButtonGroupModule,
    ScwMatModalModule,
    ScwMatTreetableModule,
    ScwMatCheckboxGroupModule,
  ],
})
export class ScwMatUiModule {}
