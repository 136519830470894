import * as Actions from './form-master-data.actions';
import { Action } from '@ngrx/store';
import { IFormMasterDataState } from './form-master-data.model';

export const formMasterDataState: IFormMasterDataState = {
  formMasterDataData: null,
  formMasterDataDataLoading: false,
  formMasterDataDataLoaded: false,
  formMasterDataHistoryData: null,
  formMasterDataHistoryDataLoading: false,
  formMasterDataHistoryDataLoaded: false,
  formMasterDataDetail: null,
  formMasterDataDetailLoading: false,
  formMasterDataDetailLoaded: false,
  formMasterDataApprovalWorkflow: null,
  formMasterDataApprovalWorkflowLoading: false,
  formMasterDataApprovalWorkflowLoaded: false,
  formMasterDataWorkflowSteps: null,
  formMasterDataWorkflowStepsLoading: false,
  formMasterDataWorkflowStepsLoaded: false,
};

export function formMasterDataReducer(
  state: IFormMasterDataState = formMasterDataState,
  baseAction: Action,
): IFormMasterDataState {
  const action = baseAction as Actions.FormMasterDataActions;

  switch (action.type) {
    case Actions.FORM_MASTER_DATA_HISTORY_DATA_LOAD:
    case Actions.FORM_MASTER_DATA_DATA_LOAD:
      return {
        ...state,
        ...{
          formMasterDataDataLoading: true,
          formMasterDataDataLoaded: false,
        },
      };
    case Actions.FORM_MASTER_DATA_DATA_LOADED:
      return {
        ...state,
        ...{
          formMasterDataData: { ...action.payload },
          formMasterDataDataLoading: false,
          formMasterDataDataLoaded: true,
        },
      };
    case Actions.FORM_MASTER_DATA_HISTORY_DATA_LOADED:
      return {
        ...state,
        ...{
          formMasterDataHistoryData: { ...action.payload },
          formMasterDataHistoryDataLoading: false,
          formMasterDataHistoryDataLoaded: true,
        },
      };
    case Actions.FORM_MASTER_DATA_DETAIL_LOAD:
      return {
        ...state,
        ...{
          formMasterDataDetailLoading: true,
          formMasterDataDetailLoaded: false,
        },
      };
    case Actions.FORM_MASTER_DATA_DETAIL_LOADED:
      return {
        ...state,
        ...{
          formMasterDataDetail: { ...action.payload },
          formMasterDataDetailLoading: false,
          formMasterDataDetailLoaded: true,
        },
      };
    case Actions.FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD:
      return {
        ...state,
        ...{
          formMasterDataApprovalWorkflowLoading: true,
          formMasterDataApprovalWorkflowLoaded: false,
        },
      };
    case Actions.FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOADED:
      return {
        ...state,
        ...{
          formMasterDataApprovalWorkflow: { ...action.payload },
          formMasterDataApprovalWorkflowLoading: false,
          formMasterDataApprovalWorkflowLoaded: true,
        },
      };
    case Actions.FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD:
      return {
        ...state,
        ...{
          formMasterDataWorkflowStepsLoading: true,
          formMasterDataWorkflowStepsLoaded: false,
        },
      };
    case Actions.FORM_MASTER_DATA_WORKFLOW_STEPS_LOADED:
      return {
        ...state,
        ...{
          formMasterDataWorkflowSteps: { ...action.payload },
          formMasterDataWorkflowStepsLoading: false,
          formMasterDataWorkflowStepsLoaded: true,
        },
      };
    default:
      return state;
  }
}
