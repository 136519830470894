import { Action } from '@ngrx/store';
import { IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { ILogbookVersionTableQuery } from '../../view/settings/logbook-versions/logbook-versions.model';

export const LOGBOOK_VERSIONS_DATA_LOADING = '[LOGBOOKS] LOGBOOK VERSIONS DATA LOAD';
export const LOGBOOK_VERSIONS_DATA_LOADED = '[LOGBOOKS] LOGBOOK VERSIONS DATA LOADED';
export const FETCH_ERROR = '[LOGBOOKS] FETCH ERROR';

export class LogbookVersionsDataLoading implements Action {
  readonly type = LOGBOOK_VERSIONS_DATA_LOADING;

  constructor(
    public tableQuery?: ILogbookVersionTableQuery,
    public statuses?: number[],
    public dispatchHideLoader: boolean = true,
  ) {}
}

export class LogbookVersionsDataLoaded implements Action {
  readonly type = LOGBOOK_VERSIONS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<any>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type LogbookVersionsActions = LogbookVersionsDataLoading | LogbookVersionsDataLoaded | FetchError;
