import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form.component';
import { IPage } from '../../../../constants.model';
import { PAGES } from '../../../../constants';

const titleKey: string = 'forms';
const page: IPage | undefined = PAGES.find((pageItem: IPage): boolean => pageItem.titleKey === titleKey);

const routes: Routes = [
  {
    path: '',
    component: FormComponent,
    data: {
      ...page,
      caption: '',
      status: true,
      setPageHeader: true,
      showPageConfiguration: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormRoutingModule {}
