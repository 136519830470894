import { ITabRow } from '../../shared/component/side-config-bar/side-config-bar.model';
import { PageConfigurationTypes, ITableHeader } from '../../../constants.model';
import { IConfig } from '../user/model';

export interface IUserConfigurationState {
  userConfigurationDataLoading: boolean;
  userConfigurationDataLoaded: boolean;
  userConfigurationData: IConfig | null;
  temporaryMode: boolean;
}

export interface IUserConfigurationResponse {
  configuration: IUserConfiguration | null;
}

export interface IComponentConfiguration {
  name: string;
  value?: ConfigurationValueDataTypes;
  children?: IComponentConfiguration[];
  type?: PageConfigurationTypes;
}

export type IUserConfiguration = {
  [key in ComponentNamesForUserConfiguration]?: Partial<ITableHeader>[];
};

export type ConfigurationValueDataTypes = string | string[] | number | number[] | boolean | ITabRow[];

export enum ComponentNamesForUserConfiguration {
  UserSettings = 'UserSettingsComponent',
  LogsFormEntries = 'LogsFormEntriesComponent',
  LogbookTasks = 'LogbookTasksComponent',
  FormTasks = 'FormTasksComponent',
  MasterDataTasks = 'MasterDataTasksComponent',
  LogbookHomeDetail = 'LogbookHomeDetailComponent',
  FormEntries = 'FormEntriesComponent',
  FormTemplates = 'FormTemplatesComponent',
  LogbookTemplates = 'LogbookTemplatesComponent',
  LogsReports = 'LogsReportsComponent',
  LogbooksTab = 'LogbooksComponent',
  AllLogbookVersionsTab = 'AllLogbookVersionComponent',
  Forms = 'FormsComponent',
}
