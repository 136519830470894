import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { Component, OnDestroy, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TFailedRecordData } from './bulk-error-modal.model';
import { uniq, camelCase } from 'lodash';

@OnDestroyDecorator
@Component({
  selector: 'bulk-error-modal',
  templateUrl: './bulk-error-modal.component.html',
  styleUrls: ['./bulk-error-modal.component.scss'],
})
export class BulkErrorModalComponent implements OnDestroy {
  @ViewChild('bulk_error_modal') errorModalTemplateRef!: ViewChildDecorator;

  private readonly scwModalSm: string = 'scw-modal-sm';

  protected modalTitle!: string;
  protected successMessage!: string;
  protected failureMessage!: string;
  protected failedRecordData: TFailedRecordData[] = [];

  protected bulkErrorModalRef!: NgbModalRef;

  public constructor(private readonly modal: NgbModal) {}

  public showModal(
    modalTitle: string,
    successMessage: string,
    failureMessage: string,
    failedRecordData: TFailedRecordData[],
  ): void {
    this.failedRecordData = failedRecordData;
    this.modalTitle = modalTitle;
    this.successMessage = successMessage;
    this.failureMessage = failureMessage;

    this.bulkErrorModalRef = this.modal.open(this.errorModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  public static formatFailedRecordsForBulkErrorModal<T extends { error?: string; data: unknown & { name: string } }>(
    failedRecordData: T[],
  ): TFailedRecordData[] {
    const failureErrors: (string | undefined)[] = uniq(
      failedRecordData.map((datum: T): string | undefined => datum.error),
    );
    const formattedFailedRecords: TFailedRecordData[] = [];

    failureErrors.forEach((error: string | undefined): void => {
      if (!error) {
        return;
      }

      const failedRecordsByErrorKey: unknown & { name: string }[] = failedRecordData
        .filter((datum: T): boolean => datum.error === error)
        .map((datum: T): unknown & { name: string } => datum.data);

      formattedFailedRecords.push({
        error: camelCase(error),
        data: failedRecordsByErrorKey,
      });
    });

    return formattedFailedRecords;
  }

  public ngOnDestroy(): void {
    this.bulkErrorModalRef?.close();
  }
}
