import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, catchError, switchMap } from 'rxjs';
import * as logBookAppReducer from '../../store/logbook.reducer';
import * as AppActions from '../app/actions';
import * as ReasonActions from './reason.actions';
import { ReasonService } from './reason.service';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IReason, IReasonAPI } from './reason.model';

@Injectable()
export class ReasonEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<logBookAppReducer.LogbookAppState>,
    private readonly service: ReasonService,
  ) {}

  getReasons = createEffect(() =>
    this.actions$.pipe(
      ofType(ReasonActions.GET_REASONS),
      switchMap((payload: ReasonActions.GetReasons) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getReasons().pipe(
          switchMap((response: IResponse<IReasonAPI[]>) => {
            const reasons: IReason[] = this.service.prepareActionReasons(response.data);

            if (payload.dispatchHideLoader) {
              return of(new ReasonActions.GetReasonsCompleted(reasons), new AppActions.HideLoader());
            }

            return of(new ReasonActions.GetReasonsCompleted(reasons));
          }),
          catchError((errorRes) => {
            return of(new ReasonActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ReasonActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  upsertReasons = createEffect(() =>
    this.actions$.pipe(
      ofType(ReasonActions.UPSERT_ACTION_REASONS),
      switchMap((payload: ReasonActions.UpsertActionReasons) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.upsertReasons(payload.payload, payload.issuer).pipe(
          switchMap((response: IResponse<IReasonAPI[]>) => {
            const reasons: IReason[] = this.service.prepareActionReasons(response.data);
            const modifiedResponse: IResponse<IReason[]> = {
              ...response,
              data: reasons,
            };

            return of(new ReasonActions.UpsertActionReasonsCompleted(modifiedResponse), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ReasonActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ReasonActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
