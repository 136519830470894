import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, skipWhile, take } from 'rxjs';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { IMainState } from '../../../store/main/main.model';
import { fragmentedUrls, parameterizedUrls } from '../../../../constants';
import { GetAuthorizedPages } from '../../../store/main/main.actions';

@Injectable({
  providedIn: 'root',
})
export class PagePermissionGuard implements CanActivate {
  constructor(public router: Router, private store: Store<logbookAppReducer.LogbookAppState>) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.store.dispatch(new GetAuthorizedPages());

      this.store
        .select('mainStore')
        .pipe(
          skipWhile((store: IMainState) => store.authorizedPagesLoading && !store.authorizedPagesLoaded),
          take(1),
        )
        .subscribe((store: IMainState) => {
          if (store.getNavigationMenuLoading || !store.getNavigationMenuLoaded) {
            return;
          }

          let formattedUrl: string = state.url.substring(1);
          if (fragmentedUrls.includes(formattedUrl.split('#')[0])) {
            formattedUrl = formattedUrl.split('#')[0];
          }

          if (parameterizedUrls.includes(formattedUrl.split('?')[0])) {
            formattedUrl = formattedUrl.split('?')[0];
          }

          const isAuthorized: boolean = store.authorizedPages.includes(formattedUrl);

          if (!isAuthorized) {
            this.router.navigate(['/error/forbidden']);
          }

          observer.next(isAuthorized);
          observer.complete();
        });
    });
  }
}
