import { Moment } from 'moment';

export interface IDateRange {
  alwaysShowCalendars?: boolean;
  autoApply?: boolean;
  className?: string;
  closeOnAutoApply?: boolean;
  customRangeDirection?: boolean;
  customStyle?: CSSStyleDeclaration;
  disabled?: boolean;
  drops: DateRangeDropsTypes;
  emptyWeekRowClass?: string;
  firstDayOfNextMonthClass?: string;
  firstMonthDayClass?: string;
  id?: string;
  inputModel?: IScwMatDatepickerReturn | null;
  isCustomDate?: Function;
  isInvalidDate?: Function;
  keepCalendarOpeningWithRange?: boolean;
  lastDayOfPreviousMonthClass?: string;
  lastMonthDayClass?: string;
  linkedCalendars?: boolean;
  locale?: IDateRangeLocale;
  lockStartDate?: boolean;
  maxDate?: Moment | null;
  minDate?: Moment | null;
  opens: DateRangeOpensTypes;
  placeholder?: string;
  ranges?: object;
  readonly?: boolean;
  showCancel?: boolean;
  showClearButton?: boolean;
  showCustomRangeLabel?: boolean;
  showISOWeekNumbers?: boolean;
  showRangeLabelOnInput?: boolean;
  showWeekNumbers?: boolean;
  singleDatePicker?: boolean;
  timePicker24Hour?: boolean;
  timePicker?: boolean;
  timePickerIncrement?: number;
  timePickerSeconds?: boolean;
}

export interface IDateRangeLocale {
  applyLabel?: string;
  cancelLabel?: string;
  clearLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  direction?: string;
  firstDay?: number;
  format?: string;
  monthNames?: string[];
  separator?: string;
  weekLabel?: string;
}

export interface IDateRangeRangesDefault {
  today: [Moment, Moment];
  yesterday: [Moment, Moment];
  thisWeek: [Moment, Moment];
  lastWeek: [Moment, Moment];
  thisMonth: [Moment, Moment];
  lastMonth: [Moment, Moment];
  thisYear: [Moment, Moment];
  lastYear: [Moment, Moment];
}

export interface IScwMatDatepickerReturn {
  endDate: Moment | null;
  startDate: Moment | null;
}

export interface IISOReturn {
  endDate: string;
  startDate: string;
}

export enum DateRangeOpensTypes {
  left = 'left',
  center = 'center',
  right = 'right',
}

export declare type DateRangeDropsTypes = 'up' | 'down';

export interface ScwMatDatepickerRule {
  required?: boolean;
  message?: string;
}
