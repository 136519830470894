<div class="border-coloring-container">
  <div [ngClass]="['border-coloring-stroke', 'border-coloring-stroke-' + BorderColors[type]]">&nbsp;</div>
  <div class="border-coloring-text">{{ text }}</div>
  <scw-mat-small-button
    *ngIf="statusViewButton"
    class="ml-2"
    [iconButton]="true"
    [ngbPopover]="popContent"
    [popoverTitle]="popTitle"
    [placement]="placement"
    (onClick)="statusViewButtonClickAction()"
  ><em class="fas fa-eye"></em
  ></scw-mat-small-button>
</div>

<ng-template #popOverDummy>
  <span></span>
</ng-template>
