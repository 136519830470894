import { IAuditTrailState } from './audit-trail.model';
import * as Actions from './audit-trail.actions';
import { Action } from '@ngrx/store';
import _ from 'lodash';
import { IIDName } from '../forms/forms.model';

export const auditTrailState: IAuditTrailState = {
  auditTrailDataLoading: false,
  auditTrailDataLoaded: false,
  auditTrailsData: null,
  fileDownloadStatus: false,
  loadingRowIds: [],
  selectedForms: {},
  selectedFormsLoading: false,
  selectedFormsLoaded: false,
  selectedScopeLogbook: {},
  selectedScopeLogbookLoading: false,
  selectedScopeLogbookLoaded: false,
  selectedScopeForm: {},
  selectedScopeFormLoading: false,
  selectedScopeFormLoaded: false,
  selectedFormSubmissionHistories: {},
  selectedFormSubmissionHistoryLoading: false,
  selectedFormSubmissionHistoryLoaded: false,
  genericLoading: false,
  genericLoaded: false,
};

export function auditTrailReducer(
  state: IAuditTrailState = auditTrailState,
  baseAction: Action,
): IAuditTrailState {
  const action = baseAction as Actions.AuditTrailActions;

  switch (action.type) {
    case Actions.AUDIT_TRAIL_DATA_LOADING:
      return {
        ...state,
        ...{
          auditTrailDataLoading: true,
          auditTrailDataLoaded: false,
        },
      };
    case Actions.AUDIT_TRAIL_DATA_LOADED:
      return {
        ...state,
        ...{
          auditTrailsData: action.payload.data,
          auditTrailDataLoading: false,
          auditTrailDataLoaded: true,
        },
      };
    case Actions.AUDIT_TRAIL_SELECTED_FORMS_LOADING:
      return {
        ...state,
        ...{
          selectedFormsLoading: true,
          selectedFormsLoaded: false,
          genericLoading: true,
          genericLoaded: false,
          loadingRowIds: [...state.loadingRowIds, action.rowId],
        },
      };
    case Actions.AUDIT_TRAIL_SELECTED_FORMS_LOADED:
      let selectedForms: IIDName[] = [];
      for (const item of action.payload.data) {
        selectedForms.push({
          id: item['form']['id'],
          name: item['name'],
          isArchived: item['isArchived'],
        });
      }
      let selectedFormObject = _.cloneDeep(state.selectedForms);
      selectedFormObject[action.rowId] = selectedForms;
      return {
        ...state,
        ...{
          loadingRowIds: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId),
          selectedFormsLoading: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length > 0,
          selectedFormsLoaded: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length == 0,
          genericLoading : false,
          genericLoaded: true,
          selectedForms: selectedFormObject,
        },
      };
    case Actions.AUDIT_TRAIL_SCOPE_LOGBOOK_LOADING:
      return {
        ...state,
        ...{
          selectedScopeLogbookLoading: true,
          selectedScopeLogbookLoaded: false,
          genericLoading: true,
          genericLoaded: false,
          loadingRowIds: [...state.loadingRowIds, action.rowId],
        },
      };
    case Actions.AUDIT_TRAIL_SCOPE_LOGBOOK_LOADED:
      const selectedScopeLogbook: IIDName[] = [];
      for (const item of action.payload.data) {
        selectedScopeLogbook.push({ id: item['id'], name: item['name'] });
      }
      const selectedScopeLogbookObject = _.cloneDeep(state.selectedScopeLogbook);
      selectedScopeLogbookObject[action.rowId] = selectedScopeLogbook;
      return {
        ...state,
        ...{
          loadingRowIds: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId),
          selectedScopeLogbookLoading: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length > 0,
          selectedScopeLogbookLoaded: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length === 0,
          genericLoading: false,
          genericLoaded: true,
          selectedScopeLogbook: selectedScopeLogbookObject,
        },
      };
    case Actions.AUDIT_TRAIL_SCOPE_FORM_LOADING:
      return {
        ...state,
        ...{
          selectedScopeFormLoading: true,
          selectedScopeFormLoaded: false,
          genericLoading: true,
          genericLoaded: false,
          loadingRowIds: [...state.loadingRowIds, action.rowId],
        },
      };
    case Actions.AUDIT_TRAIL_SCOPE_FORM_LOADED:
      const selectedScopeForm: IIDName[] = [];
      for (const item of action.payload.data) {
        selectedScopeForm.push({ id: item['id'], name: item['name'] });
      }
      const selectedScopeFormObject = _.cloneDeep(state.selectedScopeForm);
      selectedScopeFormObject[action.rowId] = selectedScopeForm;
      return {
        ...state,
        ...{
          loadingRowIds: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId),
          selectedScopeFormLoading: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length > 0,
          selectedScopeFormLoaded: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length === 0,
          genericLoading: false,
          genericLoaded: true,
          selectedScopeForm: selectedScopeFormObject,
        },
      };
    case Actions.AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          selectedFormSubmissionHistoryLoading: true,
          selectedFormSubmissionHistoryLoaded: false,
          genericLoading: true,
          genericLoaded: false,
          loadingRowIds: [...state.loadingRowIds, action.historyRowId],
        },
      };
    case Actions.AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADED:
      const selectedFormSubmissionHistory: any[] = [];
      for (const item of action.payload.data) {
        selectedFormSubmissionHistory.push({ changes: item['changes'], fieldLabels: item['fieldLabels'] });
      }
      const selectedFormSubmissionHistoryObject = _.cloneDeep(state.selectedFormSubmissionHistories);
      selectedFormSubmissionHistoryObject[action.rowId] = selectedFormSubmissionHistory;
      return {
        ...state,
        ...{
          loadingRowIds: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId),
          selectedFormSubmissionHistoryLoading: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length > 0,
          selectedFormSubmissionHistoryLoaded: state.loadingRowIds.filter(loadingId => loadingId !== action.rowId).length === 0,
          genericLoading: false,
          genericLoaded: true,
          selectedFormSubmissionHistories: selectedFormSubmissionHistoryObject,
        },
      };
    case Actions.AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT:
      return {
        ...state,
        fileDownloadStatus: true,
      };
    case Actions.AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };
    case Actions.CLEAR:
      return { ...auditTrailState };
    default:
      return state;
  }
}
