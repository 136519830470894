import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScwMatPickerComponent } from './scw-mat-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatButtonModule],
  exports: [ScwMatPickerComponent],
  declarations: [ScwMatPickerComponent],
})
export class ScwMatPickerModule {}
