import * as Actions from './form-scope.actions';
import { IFormScopesState } from '../scopes.model';
import { Action } from '@ngrx/store';

export const formScopesState: IFormScopesState = {
  formScopesDataLoaded: false,
  formScopesDataLoading: false,
  formScopes: null,
  formScopeAddLoading: false,
  formScopeAddLoaded: false,
  formScopeEditLoading: false,
  formScopeEditLoaded: false,
  formScopesBulkEditLoading: false,
  formScopesBulkEditLoaded: false,
};

export function formScopesReducer(state: IFormScopesState = formScopesState, baseAction: Action): IFormScopesState {
  const action = baseAction as Actions.FormScopeActions;

  switch (action.type) {
    case Actions.FORM_SCOPES_DATA_LOADING:
      return {
        ...state,
        formScopesDataLoading: true,
        formScopesDataLoaded: false,
      };
    case Actions.FORM_SCOPES_DATA_LOADED:
      return {
        ...state,
        formScopes: action.payload,
        formScopesDataLoading: false,
        formScopesDataLoaded: true,
      };
    case Actions.ADD_FORM_SCOPE:
      return {
        ...state,
        formScopeAddLoading: true,
        formScopeAddLoaded: false,
      };
    case Actions.ADD_FORM_SCOPE_COMPLETED:
      return {
        ...state,
        formScopeAddLoading: false,
        formScopeAddLoaded: true,
      };
    case Actions.EDIT_FORM_SCOPE:
      return {
        ...state,
        formScopeEditLoading: true,
        formScopeEditLoaded: false,
      };
    case Actions.EDIT_FORM_SCOPE_COMPLETED:
      return {
        ...state,
        formScopeEditLoading: false,
        formScopeEditLoaded: true,
      };
    case Actions.BULK_EDIT_FORM_SCOPES:
      return {
        ...state,
        formScopesBulkEditLoading: true,
        formScopesBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_FORM_SCOPES_COMPLETED:
      return {
        ...state,
        formScopesBulkEditLoading: false,
        formScopesBulkEditLoaded: true,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        formScopeAddLoading: false,
        formScopeEditLoading: false,
      };
    default:
      return state;
  }
}
