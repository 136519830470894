import { SafeUrl } from '@angular/platform-browser';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface IMenuSearchableKey {
  name?: string;
  state?: string;
}

export interface IMainState {
  getNavigationMenuLoading: boolean;
  getNavigationMenuLoaded: boolean;
  authorizedPages: string[];
  menu: INavigationInformation;
  clientLogo: string | null;
  clientLogoUrl: SafeUrl | null;
  allowedDomainsLoading: boolean;
  allowedDomainsLoaded: boolean;
  authorizedPagesLoading: boolean;
  authorizedPagesLoaded: boolean;
  allowedDomains: IAllowedDomain[];
  userRights: any;
}

export interface IMenu {
  id: number;
  name: string;
  staticName: string | null;
  children?: IMenu[];
  parentId: number;
  link: string;
  orders: number;
  status: boolean;
  icon: string;
  external: boolean;
  inProgress: boolean;
  isUpgradeBadgeEnabled: boolean;
  isNew: boolean;
  appId: number;
  key: string;
  requiredRights: IRightAggregation;
}

export interface INavigationInformation {
  menus: IMenu[];
  clientLogo: string | null;
}

export interface IAllowedDomain {
  id: number;
  domain: string;
}

export interface INotificationChannel {
  id: number;
  name: string;
}

export enum ERights {
  ENTRY_CREATE = 'entry-create',
  ENTRY_EDIT = 'entry-edit',
  TASK_VIEW = 'task-view',
  TASK_APPROVE_LOGS_FORM_ENTRIES = 'task-approve-logs-form-entries',
  TASK_APPROVE_LOGBOOK_TASKS = 'task-approve-logbook-tasks',
  TASK_APPROVE_FORM_TASKS = 'task-approve-form-tasks',
  TASK_APPROVE_MASTER_DATA = 'task-approve-master-data',
  SETTING_VIEW = 'setting-view',
  SETTING_CLIENT_EDIT = 'setting-client-edit',
  SETTING_USER_EDIT = 'setting-user-edit',
  SETTING_FORM_MD_EDIT = 'setting-form-md-edit',
  SETTING_LOGBOOK_MD_EDIT = 'setting-logbook-md-edit',
  SETTING_ROLE_EDIT = 'setting-role-edit',
  SETTING_SCOPE_EDIT = 'setting-scope-edit',
  SETTING_LOGBOOK_EDIT = 'setting-logbook-edit',
  SETTING_FORM_EDIT = 'setting-form-edit',
  SETTING_LOGBOOK_ARCHIVE = 'setting-logbook-archive',
  SETTING_FORM_ARCHIVE = 'setting-form-archive',
  SETTING_WORKFLOW_EDIT = 'setting-workflow-edit',
  REPORT_AUDITLOG_VIEW = 'report-auditlog-view',
  MOBILE_LOGBOOK_MOVE = 'mobile-logbook-move',
  REPORT_LOGS_VIEW = 'report-logs-view',
  REPORT_FORM_TEMPLATES_VIEW = 'report-form-templates-view',
  REPORT_LOGBOOK_TEMPLATES_VIEW = 'report-logbook-templates-view',
  REPORT_MY_FORM_ENTRIES_VIEW = 'report-my-form-entries-view',
  REPORT_MY_FORM_ENTRIES_EDIT = 'report-my-form-entries-edit',
  SUBMIT_TICKET = 'submit-ticket',
  SETTING_CLIENT_CONFIGURATION_EDIT = 'setting-client-configuration-edit',
  SETTING_STATE_EDIT = 'setting-state-edit',
  SETTING_ACTIVITY_TYPE_EDIT = 'setting-activity-type-edit',
  SETTING_RULE_EDIT = 'setting-rule-edit',
  SETTING_AUTOMATION_EDIT = 'setting-automation-edit',
  MANUAL_STATE_CHANGE = 'manual-state-change',
  OVERRIDE_AUTOMATION_AND_RULES = 'override-automation-and-rules',
}

export enum ERightOperators {
  AND = 'and',
  OR = 'or',
}

export interface IRightAggregation {
  rights: ERights[];
  operator: ERightOperators;
}

export type IUserRights = {
  [key in ERights]: boolean;
};

export enum EManualLogActionType {
  PDF_DOWNLOAD = 'pdf_download',
  EXCEL_DOWNLOAD = 'excel_download',
  EXCEL_TEMPLATE_DOWNLOAD = 'excel_template_download',
  EXCEL_IMPORT = 'excel_import',
  UPDATING = 'updating',
  SIGNED = 'signed',
}

export enum EManualLogObjectContentModel {
  USER = 'user',
  FORM_SUBMISSION = 'formsubmission',
  FORM_VERSION = 'formversion',
  FORM = 'form',
  LOGBOOK = 'logbook',
  LOGBOOK_VERSION = 'logbookversion',
  FIELD_VERSION = 'fieldversion',
  LOGS = 'logs',
}

export type TManualLogLocation =
  | 'home'
  | 'my_reports'
  | 'report_form_entries'
  | 'report_form_templates'
  | 'report_logbook_templates'
  | 'report_logs'
  | 'logbooks'
  | 'my_tasks'
  | 'my_tasks_logs_and_form_entries'
  | 'my_tasks_logbook_tasks'
  | 'my_tasks_form_tasks'
  | 'my_tasks_master_data'
  | 'my_tasks_logbook_tasks_archival'
  | 'my_tasks_form_tasks_archival';
