import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IAddEditWorkFlowStep, IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import { Observable } from 'rxjs';
import { IAddWorkflow, IWorkflow, IWorkflowSteps } from './workflows.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IRole } from '../../../view/settings/roles/roles.model';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  private readonly WORKFLOWS = {
    WORKFLOW_URL: `${this.baseUrl}/workflows/`,
    BULK_EDIT_URL: `${this.baseUrl}/workflows/bulk/update/`,
    ROLE_URL: `${this.baseUrl}/roles/my-task-authorized-roles/`,
  };

  private readonly WORKFLOW_STEPS = {
    WORKFLOW_STEPS_URL: `${this.baseUrl}/workflow-items/`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getWorkflows(params: HttpParams, issuer?: IIssuer): Observable<IGetManyResponse<IWorkflow>> {
    return this.http.get<IGetManyResponse<IWorkflow>>(this.WORKFLOWS.WORKFLOW_URL, {
      params,
      ...(issuer && { headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer) }),
    });
  }

  public getWorkflowsSteps(id: number): Observable<IGetManyResponse<IWorkflowStepsData>> {
    return this.http.get<IGetManyResponse<IWorkflowStepsData>>(`${this.WORKFLOW_STEPS.WORKFLOW_STEPS_URL}${id}/`);
  }

  public getRoles(params: HttpParams): Observable<IGetManyResponse<IRole>> {
    return this.http.get<IGetManyResponse<IRole>>(this.WORKFLOWS.ROLE_URL, { params });
  }

  public addWorkflow(workflow: IAddWorkflow, issuer: IIssuer | null): Observable<IBaseOneResponse<IWorkflow>> {
    return this.http.post<IBaseOneResponse<IWorkflow>>(this.WORKFLOWS.WORKFLOW_URL, {
      issuer,
      payload: workflow,
    });
  }

  public addWorkflowSteps(
    workflowSteps: IAddEditWorkFlowStep[],
    id: number,
    issuerAndReason: IIssuerAndReason | null,
  ): Observable<IBaseOneResponse<IWorkflowStepsData>> {
    return this.http.post<IBaseOneResponse<IWorkflowStepsData>>(`${this.WORKFLOW_STEPS.WORKFLOW_STEPS_URL}${id}/`, {
      issuer: issuerAndReason?.issuer,
      payload: { items: workflowSteps, reason: issuerAndReason?.reason },
    });
  }

  public editWorkflow(
    workflow: IAddWorkflow,
    id: number,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IWorkflow>> {
    return this.http.put<IBaseOneResponse<IWorkflow>>(`${this.WORKFLOWS.WORKFLOW_URL}${id}/`, {
      issuer,
      payload: workflow,
    });
  }

  public editWorkflowSteps(
    workflowSteps: IAddEditWorkFlowStep[],
    id: number,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IWorkflowSteps>> {
    return this.http.put<IBaseOneResponse<IWorkflowSteps>>(`${this.WORKFLOW_STEPS.WORKFLOW_STEPS_URL}${id}/`, {
      issuer: issuerAndReason.issuer,
      payload: { items: workflowSteps, reason: '' },
    });
  }

  public deleteWorkflowSteps(
    workflowSteps: IWorkflowSteps,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IWorkflowSteps>> {
    return this.http.delete<IBaseOneResponse<IWorkflowSteps>>(`${this.WORKFLOW_STEPS.WORKFLOW_STEPS_URL}`, {
      body: {
        issuer,
        payload: workflowSteps,
      },
    });
  }
}
