import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { catchError, switchMap, of, map } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { LogsFormEntriesService } from '../../../shared/service/my-tasks/logs-form-entries/logs-form-entries.service';
import * as ObjectActions from './logs-form-entries.actions';
import { IFormSubmissionData, ILogsFormEntriesData } from './logs-form-entries.model';
import { IReviewResults } from '../my-tasks.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import { IFormSubmissionUserAction } from '../../../../constants.model';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class LogsFormEntriesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: LogsFormEntriesService,
    private readonly helperService: HelperService,
    private readonly workflowService: WorkflowsService,
  ) {}

  getReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGS_FORM_ENTRIES_LOADING),
      switchMap((payload: ObjectActions.StartLogsFormEntriesLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);

        return this.service.getReviewData(body).pipe(
          map((response: IGetManyResponse<ILogsFormEntriesData>): IGetManyResponse<ILogsFormEntriesData> => {
            return {
              ...response,
              data: response.data.map((row: ILogsFormEntriesData): ILogsFormEntriesData => {
                return {
                  ...row,
                  stateDurationFormatted: row.stateDuration
                    ? `${row.stateDuration} ${
                        ComponentUtilities.findOneOptionForSelect(
                          this.helperService.getTimeType(),
                          row.stateDurationType,
                        )[0].name
                      }`
                    : null,
                };
              }),
            };
          }),
          switchMap((response: IGetManyResponse<ILogsFormEntriesData>) => {
            return of(new ObjectActions.LogsFormEntriesLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogsFormEntriesRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGS_FORM_ENTRIES_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartLogsFormEntriesRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogsFormEntriesRecord(payload.logbookId, payload.id).pipe(
          switchMap((response: IGetOneResponse<IFormSubmissionData>) => {
            return of(new ObjectActions.LogsFormEntriesRecordLoaded(response.data),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogsFormEntriesHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGS_FORM_ENTRIES_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartLogsFormEntriesHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogsFormEntriesHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<IFormSubmissionUserAction>) => {
            return of(new ObjectActions.LogsFormEntriesHistoryLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  reviewFormEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_APPROVE_LOADING),
      switchMap((payload: ObjectActions.StartEntryApproveLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryApproveLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectPermanentEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_PERMANENT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectPermanentLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectPermanentLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('limit', 1000).append('just_workflow_data', true);

        return this.workflowService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.WorkflowDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
