import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ScwMatButtonComponent } from './scw-mat-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  exports: [ScwMatButtonComponent],
  declarations: [ScwMatButtonComponent],
})
export class ScwMatButtonModule {}
