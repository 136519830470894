import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { IIssuer, IIssuerForm } from './issuer.model';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ofType } from '@ngrx/effects';
import { Subscription, take } from 'rxjs';
import { HelperService } from '../../service/helper.service';
import { HttpStatusCode } from '@angular/common/http';
import { IClientSettingsState } from '../../../store/settings/client/client.model';
import { PinChangeComponent } from '../pin-change/pin-change.component';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { IGenericObject } from '../../model/interface/generic.model';
import * as _ from 'lodash';
import { NAME_COLUMN_MAX_LENGTH } from '../../../../constants';

@OnDestroyDecorator
@Component({
  selector: 'app-issuer',
  templateUrl: './issuer.component.html',
  styleUrls: [
    './issuer.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../../../../scss/custom.scss',
  ],
  host: { class: 'issuer-component' },
})
export class IssuerComponent implements OnInit, AfterViewChecked {
  @ViewChild('issuer_modal') issuerModalRef!: ViewChildDecorator;
  @ViewChild(PinChangeComponent) pinChangeComponent!: PinChangeComponent;

  @Input() actionsToSubscribe: string[] = [];
  @Input() onActionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() issuerInput!: IIssuer | null;
  @Output() onIssuerEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() issuerInputChange: EventEmitter<IIssuer | null> = new EventEmitter<IIssuer | null>();

  constructor(
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly storeActions: ActionsSubject,
    public readonly translate: TranslateService,
    public readonly helperService: HelperService,
    private readonly ngbModal: NgbModal,
  ) {}

  public askForPinOnAction$!: boolean;
  private readonly subscriptions: Subscription[] = [];
  public ngbModalRef!: NgbModalRef;
  public issuer!: IIssuer | null;
  public issuerRules = {
    username: [
      this.helperService.getMaxLengthFormRule(NAME_COLUMN_MAX_LENGTH),
      this.helperService.getRequiredFormRule(),
    ],
    pin: [
      this.helperService.getRequiredFormRule(),
      {
        pattern: /^\d{4,6}$/,
        message: this.translate.instant('general.inputErrorMessages.pinRule'),
      },
    ],
  };
  public issuerForm: IIssuerForm = {
    username: {
      value: null,
      isEnabled: false,
      rules: [],
    },
    pin: {
      value: null,
      isEnabled: false,
      rules: [],
    },
  };

  public fn: any;

  public isDisabled: boolean = false;
  public pinRuleArray: number[] = [4, 5, 6];
  private hasAnotherModalOnTop: boolean = false;

  public submit(isValid: boolean): void {
    if (!this.issuerForm.username.value || !this.issuerForm.pin.value) {
      this.changeButtonDisable(false);
    }
    this.issuer = {
      username: this.issuerForm.username.value,
      password: this.issuerForm.pin.value,
    };
    this.issuerInputChange.emit(this.issuer);
    this.onIssuerEmit.emit(isValid);
  }

  public ngOnInit(): void {
    this.store.select('clientStore').subscribe((state: IClientSettingsState) => {
      this.askForPinOnAction$ = state.clientCommonInformation?.askForPinOnAction ?? true;
    });

    this.actionsToSubscribe.forEach((action) => {
      this.subscriptions.push(
        this.storeActions.pipe(ofType(action)).subscribe((response: any) => {
          if (
            response.payload?.error?.errors?.some(
              (errors: any) =>
                errors?.errors?.password?.some(
                  (item: IGenericObject<string>) => item['code'] === 'pin_expired_time',
                ) === true,
            ) &&
            response.payload.status === HttpStatusCode.NotAcceptable
          ) {
            this.changeButtonDisable(false);
            this.helperService.showToastMessage(
              false,
              this.translate.instant('general.error'),
              this.translate.instant('general.pinExpireError'),
            );

            if (this.issuerInput?.username) {
              this.pinChangeComponent.showModal('expire', this.issuerInput.username);
            }
          }

          if (
            response.payload?.error?.errors?.some(
              (errors: any) =>
                errors?.errors?.unknown?.some((item: IGenericObject<string>) => item['code'] === 'pin_temporary') ===
                true,
            ) &&
            response.payload.status === HttpStatusCode.NotAcceptable
          ) {
            if (this.issuerInput?.username) {
              this.pinChangeComponent.showModal('temporary', this.issuerInput?.username);
              this.changeButtonDisable(true);
              return;
            }
          }

          if (
            response.payload?.error?.errors?.some(
              (errors: any) =>
                errors?.errors?.issuer?.some((code: any) => code['code'] === 'issuer_not_found') === true,
            ) ||
            (response.payload?.error?.errors?.some(
              (errors: any) =>
                errors?.errors?.issuer?.some((code: any) => code['code'] === 'issuer_not_authorized') === true,
            ) &&
              response.payload.status === HttpStatusCode.NotAcceptable)
          ) {
            if (this.issuerInput?.username) {
              this.changeButtonDisable(false);
              return;
            }
          }

          this.changeButtonDisable(false);
          this.closeModal();
        }),
      );
    });
  }

  public showModal(): void {
    this.changeButtonDisable(false);
    this.ngbModalRef = this.ngbModal.open(this.issuerModalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm issuer-modal',
    });
  }

  public pressEnter(): void {
    if (
      this.issuerForm.username.value &&
      this.issuerForm.pin.value &&
      this.pinRuleArray.includes(this.issuerForm.pin.value?.length) &&
      this.issuerForm.pin.value?.match(/^[0-9]+$/)
    ) {
      this.changeButtonDisable(true);
    }
    (document.querySelector('#submitButton > button') as HTMLButtonElement).click();
  }

  public closeModal(): void {
    if (!this.isDisabled) {
      this.issuer = null;
      this.issuerForm.username.value = null;
      this.issuerForm.pin.value = null;
      this.issuerInputChange.emit(this.issuer);
      this.ngbModalRef?.dismiss();
      this.changeButtonDisable(false);
    }
  }

  public changeButtonDisable(status: boolean): void {
    this.isDisabled = status;
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach((subs: Subscription) => subs?.unsubscribe());
  }

  public ngAfterViewChecked(): void {
    if (!this.isDisabled) {
      return;
    }

    const modals: NodeListOf<Element> = document.querySelectorAll('ngb-modal-window');
    const isLastModalIssuerModal: boolean = Boolean(_.last(modals)?.classList?.contains('issuer-modal'));

    if (isLastModalIssuerModal && this.hasAnotherModalOnTop) {
      this.changeButtonDisable(false);
      this.hasAnotherModalOnTop = false;
    } else if (!isLastModalIssuerModal) {
      this.changeButtonDisable(true);
      this.hasAnotherModalOnTop = true;
    }
  }
}
