import { IFormTasksState } from './form-tasks.model';
import { Action } from '@ngrx/store';
import * as Actions from './form-tasks.actions';
import * as _ from 'lodash';

export const formTasksInitialState: IFormTasksState = {
  FormTasksLoading: false,
  FormTasksLoaded: false,
  FormTasksData: {
    success: false,
    data: [],
    page: undefined,
    pageCount: undefined,
    count: undefined,
    total: undefined,
  },
  FormTasksBeforeRecordLoading: false,
  FormTasksBeforeRecordLoaded: false,
  FormTasksBeforeRecord: null,
  FormTasksAfterRecordLoading: false,
  FormTasksAfterRecordLoaded: false,
  FormTasksAfterRecord: null,
  FormTasksBeforeFieldSetLoading: false,
  FormTasksBeforeFieldSetLoaded: false,
  FormTasksBeforeFieldset: [],
  FormTasksAfterFieldSetLoading: false,
  FormTasksAfterFieldSetLoaded: false,
  FormTasksAfterFieldset: [],
  FormTasksHistoryDataLoading: false,
  FormTasksHistoryDataLoaded: false,
  FormTasksHistoryData: null,
  FormTasksActiveVersionHistoryDataLoading: false,
  FormTasksActiveVersionHistoryDataLoaded: false,
  FormTasksActiveVersionHistoryData: null,
  FormTasksCurrentVersionHistoryDataLoading: false,
  FormTasksCurrentVersionHistoryDataLoaded: false,
  FormTasksCurrentVersionHistoryData: null,
  FormTasksReviewDataLoading: false,
  FormTasksReviewDataLoaded: false,
  FormTasksReviewData: null,
  tableSettings: [],
  errors: [],
};

export function formTasksReducer(state: IFormTasksState = formTasksInitialState, baseAction: Action) {
  const action = baseAction as Actions.FormTasksActions;

  switch (action.type) {
    case Actions.START_FORM_TASKS_LOADING:
      return {
        ...state,
        ...{
          FormTasksLoading: true,
          FormTasksLoaded: false,
        },
      };
    case Actions.FORM_TASKS_LOADED:
      return {
        ...state,
        ...{
          FormTasksData: { ...action.payload },
          FormTasksLoading: false,
          FormTasksLoaded: true,
        },
      };
    case Actions.START_FORM_TASKS_BEFORE_RECORD_LOADING:
      return {
        ...state,
        ...{
          FormTasksBeforeRecordLoading: true,
          FormTasksBeforeRecordLoaded: false,
        },
      };
    case Actions.FORM_TASKS_BEFORE_RECORD_LOADED:
      return {
        ...state,
        ...{
          FormTasksBeforeRecordLoading: false,
          FormTasksBeforeRecordLoaded: true,
          FormTasksBeforeRecord: { ...action.payload },
        },
      };
    case Actions.START_FORM_TASKS_AFTER_RECORD_LOADING:
      return {
        ...state,
        ...{
          FormTasksAfterRecordLoading: true,
          FormTasksAfterRecordLoaded: false,
        },
      };
    case Actions.FORM_TASKS_AFTER_RECORD_LOADED:
      return {
        ...state,
        ...{
          FormTasksAfterRecordLoading: false,
          FormTasksAfterRecordLoaded: true,
          FormTasksAfterRecord: { ...action.payload },
        },
      };
    case Actions.START_FORM_TASKS_BEFORE_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          FormTasksBeforeFieldSetLoading: true,
          FormTasksBeforeFieldsetLoaded: false,
        },
      };
    case Actions.FORM_TASKS_BEFORE_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          FormTasksBeforeFieldSetLoading: false,
          FormTasksBeforeFieldsetLoaded: true,
          FormTasksBeforeFieldset: { ...action.payload },
        },
      };
    case Actions.START_FORM_TASKS_AFTER_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          FormTasksAfterFieldSetLoading: true,
          FormTasksAfterFieldsetLoaded: false,
        },
      };
    case Actions.FORM_TASKS_AFTER_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          FormTasksAfterFieldSetLoading: false,
          FormTasksAfterFieldsetLoaded: true,
          FormTasksAfterFieldset: { ...action.payload },
        },
      };
    case Actions.START_FORM_TASKS_HISTORY_LOADING:
      return {
        ...state,
        ...{
          FormTasksHistoryDataLoading: true,
          FormTasksHistoryDataLoaded: false,
        },
      };
    case Actions.FORM_TASKS_HISTORY_LOADED:
      return {
        ...state,
        ...{
          FormTasksHistoryDataLoading: false,
          FormTasksHistoryDataLoaded: true,
          FormTasksHistoryData: { ...action.payload },
        },
      };
    case Actions.START_FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          FormTasksActiveVersionHistoryDataLoading: true,
          FormTasksActiveVersionHistoryDataLoaded: false,
        },
      };
    case Actions.FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          FormTasksActiveVersionHistoryDataLoading: false,
          FormTasksActiveVersionHistoryDataLoaded: true,
          FormTasksActiveVersionHistoryData: { ...action.payload },
        },
      };

    case Actions.START_FORM_TASKS_CURRENT_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          FormTasksCurrentVersionHistoryDataLoading: true,
          FormTasksCurrentVersionHistoryDataLoaded: false,
        },
      };
    case Actions.FORM_TASKS_CURRENT_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          FormTasksCurrentVersionHistoryDataLoading: false,
          FormTasksCurrentVersionHistoryDataLoaded: true,
          FormTasksCurrentVersionHistoryData: { ...action.payload },
        },
      };
    case Actions.START_ENTRY_APPROVE_LOADING:
    case Actions.START_ENTRY_REJECT_PERMANENT_LOADING:
    case Actions.START_ENTRY_REJECT_LOADING:
      return {
        ...state,
        ...{
          FormTasksReviewData: null,
          FormTasksReviewDataLoading: true,
          FormTasksReviewDataLoaded: false,
        },
      };
    case Actions.ENTRY_APPROVE_LOADED:
    case Actions.ENTRY_REJECT_PERMANENT_LOADED:
    case Actions.ENTRY_REJECT_LOADED:
      return {
        ...state,
        ...{
          FormTasksReviewData: { ...action.payload.data },
          FormTasksReviewDataLoading: false,
          FormTasksReviewDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.CLEAR_STATE:
      return { ...formTasksInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };

    default:
      return state;
  }
}
