import { NgModule } from '@angular/core';
import { ScwMatCheckboxComponent } from './scw-mat-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule],
  exports: [ScwMatCheckboxComponent],
  declarations: [ScwMatCheckboxComponent],
})
export class ScwMatCheckboxModule {}
