import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ScwMatDropdownMenuItem } from './scw-mat-dropdown.model';

@Component({
  selector: 'scw-mat-dropdown',
  templateUrl: './scw-mat-dropdown.component.html',
  styleUrls: ['./scw-mat-dropdown.component.scss'],
})
export class ScwMatDropdownComponent {
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() menu: ScwMatDropdownMenuItem[] = [];
  @Output() onClickItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownMenuContainer') dropdownMenuContainer!: ElementRef;

  public show: boolean = false;

  constructor(private readonly renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.dropdownMenuContainer?.nativeElement.contains(e.target)) {
        this.onClickDropdownButton(false);
      }
    });
  }

  public onClickDropdownButton(changeTo: boolean): void {
    this.show = changeTo;
  }

  public onClickDropdownMenuItem(item: ScwMatDropdownMenuItem): void {
    this.onClickItem.emit(item.key);
    this.onClickDropdownButton(!this.show);
  }
}
