import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  IBaseOneResponse,
  IBulkResponseData,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  ILogbookMasterData,
  ILogbookMasterDataDetail,
  ILogbookMasterDataFields,
  ILogbookMasterDataHistory,
  IMasterDataPreviousVersion,
} from './logbook-master-data.model';
import { forkJoin, Observable } from 'rxjs';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import * as moment from 'moment-timezone';
import { ILogbookMasterDataFormPayload } from '../../../view/settings/master-data/logbook-master-data/logbook-master-data.component.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IWorkflow } from '../workflows/workflows.model';
import { EFieldType, ICompareField } from '../../../shared/standalone/compare-changes/compare-changes.model';
import { ISelect } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import * as _ from 'lodash';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import { IScwMatDatepickerReturn } from '../../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { IFormMasterDataDetail } from '../form-master-data/form-master-data.model';

@Injectable({
  providedIn: 'root',
})
export class LogbookMasterDataService {
  private readonly LOGBOOK_MASTER_DATA = {
    BASE_URL: '/field-versions/',
    FIELD_VERSION_ITEMS: '/field-version-items/',
    USER_ACTIONS_FIELD_VERSIONS: '/user-actions/field-versions/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getLogbookMasterData(params: HttpParams): Observable<IGetManyResponse<ILogbookMasterData>> {
    return this.http.get<IGetManyResponse<ILogbookMasterData>>(`${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}`, {
      params: LogbookMasterDataService.setFieldVersionTypeOnHttpParam(params),
    });
  }

  private static setFieldVersionTypeOnHttpParam(params: HttpParams): HttpParams {
    return params.set('field_version_type', 'logbook');
  }

  public getLogbookMasterDataHistory(id: number): Observable<IGetManyResponse<ILogbookMasterDataHistory>> {
    return this.http.get<IGetManyResponse<ILogbookMasterDataHistory>>(
      `${this.baseUrl}${this.LOGBOOK_MASTER_DATA.USER_ACTIONS_FIELD_VERSIONS}${id}`,
    );
  }

  public getLogbookMasterDetailDataMainPart(
    id: number | null,
    getPreviousVersion: boolean = false,
  ): Observable<IBaseOneResponse<ILogbookMasterDataDetail>> {
    const params: HttpParams = new HttpParams().append('get_previous_version', getPreviousVersion ? 1 : 0);

    return this.http.get<IBaseOneResponse<ILogbookMasterDataDetail>>(
      `${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}${id}`,
      { params },
    );
  }

  public getLogbookMasterDetailDataFieldsPart(
    id: number | null,
  ): Observable<IGetManyResponse<ILogbookMasterDataFields>> {
    return this.http.get<IGetManyResponse<ILogbookMasterDataFields>>(
      `${this.baseUrl}${this.LOGBOOK_MASTER_DATA.FIELD_VERSION_ITEMS}${id}`,
    );
  }

  public getLogbookMasterDetailData(
    id: number | null,
    getPreviousVersion: boolean = false,
  ): Observable<[IBaseOneResponse<ILogbookMasterDataDetail>, IGetManyResponse<ILogbookMasterDataFields>]> {
    const sourcesObject: [
      Observable<IBaseOneResponse<ILogbookMasterDataDetail>>,
      Observable<IGetManyResponse<ILogbookMasterDataFields>>,
    ] = [
      this.getLogbookMasterDetailDataMainPart(id, getPreviousVersion),
      this.getLogbookMasterDetailDataFieldsPart(id),
    ];

    return forkJoin(sourcesObject);
  }

  public sendToReviewLogbookMaster(id: number | null, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(
      `${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}${this.LOGBOOK_MASTER_DATA.SEND_TO_REVIEW}${id}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public activateLogbookMaster(id: number | null, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(
      `${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}${this.LOGBOOK_MASTER_DATA.ACTIVATE}${id}`,
      {
        issuer,
        payload: {},
      },
      {
        params: LogbookMasterDataService.setFieldVersionTypeOnHttpParam(new HttpParams()),
      },
    );
  }

  public deleteLogbookMaster(id: number | null, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<any>> {
    return this.http.delete<IBulkResponseData>(`${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}${id}`, {
      body: {
        issuer: issuerAndReason.issuer,
        payload: {
          reason: issuerAndReason.reason,
        },
      },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public addLogbookMaster(payload: any, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(`${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}`, {
      issuer: issuerAndReason.issuer,
      payload: { ...payload, reason: issuerAndReason.reason },
    });
  }

  public editLogbookMaster(id: number | null, payload: any, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.put<IBaseOneResponse<any>>(`${this.baseUrl}${this.LOGBOOK_MASTER_DATA.BASE_URL}${id}`, {
      issuer,
      payload,
    });
  }

  public formatDetailToAdd(
    masterDataDetail: ILogbookMasterDataDetail,
    isClone: boolean = false,
  ): ILogbookMasterDataFormPayload {
    return {
      name: '',
      field_version_type: 'logbook',
      workflow_id: masterDataDetail.workflow.id,
      issued_date: masterDataDetail.issuedDate && !isClone ? moment(masterDataDetail.issuedDate).format() : null,
      fields:
        masterDataDetail.fields?.map((field: any, _index: number) => {
          return {
            id: null,
            name: field.name,
            order: field.order,
            meta: {
              ...field.meta,
              options: field.meta.options.map((option: any) => {
                return {
                  id: null,
                  label: option.label,
                  status: true,
                };
              }),
            },
          };
        }) ?? [],
      deleted_fields: [],
    };
  }

  public setCompareFields(
    item: ILogbookMasterDataDetail | IFormMasterDataDetail | null,
    previousVersionData: IMasterDataPreviousVersion,
    workflowData: IWorkflow[],
    timezone: string,
  ): ICompareField[] {
    const workflow: ISelect<number, string>[] | null = ComponentUtilities.findOneOptionForSelect(
      workflowData,
      Number(_.get(item, 'workflow.id', 0)),
      'id',
    );
    const issuedDate: IScwMatDatepickerReturn = {
      startDate: item?.issuedDate ? moment(item.issuedDate).tz(timezone) : null,
      endDate: item?.issuedDate ? moment(item.issuedDate).tz(timezone) : null,
    };

    return [
      {
        type: EFieldType.INPUT,
        label: 'settings.masterData.logbook.addEditForm.versionNumber',
        required: true,
        highlighted: previousVersionData.versionNumber !== item?.versionNumber,
        values: {
          before: previousVersionData.versionNumber,
          after: item?.versionNumber,
        },
      },
      {
        type: EFieldType.SELECT,
        label: 'settings.masterData.logbook.addEditForm.workflowId',
        required: true,
        highlighted: previousVersionData.workflow[0]?.id !== item?.workflow.id,
        values: {
          before: previousVersionData.workflow,
          after: workflow,
        },
        button: {
          buttonIconCls: 'fas fa-eye',
          disabled: (item: ISelect<number, string>[] | null) => {
            return _.isNil(item) || item[0].id === 1;
          },
        },
        data: workflowData,
      },
      {
        type: EFieldType.DATE,
        label: 'settings.forms.modal.issuedDate.label',
        required: false,
        highlighted: !_.isEqual(issuedDate, previousVersionData.issuedDate),
        values: {
          before: previousVersionData.issuedDate,
          after: issuedDate,
        },
      },
    ];
  }
}
