import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ISelect, ScwMatSelectRule } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IFormField } from '../../../shared/model/interface/scw-form.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';

export declare type ModalType = 'add' | 'edit' | 'delete' | 'bulk-delete' | 'bulk-edit';

export interface IActivityTypeForm {
  name: IFormField<string | null, ScwMatInputRule>;
  isActive: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  description: IFormField<string | null, ScwMatInputRule>;
}

export interface IActivityTypeFormRules {
  name: ScwMatInputRule[];
  description: ScwMatInputRule[];
  isActive: ScwMatSelectRule[];
}

export interface IActivityTypeTableQuery extends ITableQuery {
  isActive: number[] | null;
}

export const fetchAllDataDefaultTableQuery: { tableQuery: IActivityTypeTableQuery } = {
  tableQuery: { rowsPerPage: 5000, page: 1, sort: { type: 'ASC', column: 'id' }, isActive: null },
};
