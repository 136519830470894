import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IReason } from '../reason/reason.model';
import { IFormField } from '../../shared/model/interface/scw-form.model';
import { ISelect, ScwMatSelectRule } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IClientPageConfiguration } from '../../view/settings/logs-table-configuration/logs-table-configuration.model';

export interface IPageConfigurationState {
  isUserHomePageConfigurationLoading: boolean;
  isUserHomePageConfigurationLoaded: boolean;
  isUserHomePageConfigurationAdd: boolean;
  isUserHomePageConfigurationAddCompleted: boolean;
  isClientPageConfigurationLoading: boolean;
  isClientPageConfigurationLoaded: boolean;
  isClientPageConfigurationAdd: boolean;
  isClientPageConfigurationAddCompleted: boolean;
  userHomePageConfigurationData: IPageConfiguration | null;
  clientPageConfigurationData: IClientPageConfiguration[];
}

export interface IPageConfiguration {
  id?: number;
  logbookIds?: number[];
  name?: string | null;
  level: string;
  meta: IConfigurationMeta[];
  location: string;
  createdById: number;
  clientId: number;
  selectedFormIds?: number[];
  isClientConfiguration?: boolean;
}

export interface IAddPageConfiguration {
  issuer?: IIssuer | null;
  reason?: string | null;
  payload: IPageConfiguration | IPageConfiguration[];
}

export interface IPageConfigurationParams {
  userId?: number;
  logbookId?: number | number[];
}

export interface IConfigurationMeta {
  type: string;
  formIds?: string[];
  value?: string;
  columnName?: string;
}

export interface IFormPageConfiguration {
  forms: IFormField<ISelect<string, string>[] | null, ScwMatSelectRule>;
}

export interface IConfigurationDropdown {
  id: string;
  name: string;
}

export interface IConfigurationFormDropdown {
  formId: number;
  formFrmId: string;
  name: string;
}

export enum EPageConfigurationLevel {
  USER = 'user',
  CLIENT = 'client',
}

export enum EPageConfigurationLocation {
  HOME = 'home',
  SETTINGS = 'client_settings',
  FORM_SETTINGS = 'form_settings',
  LOGBOOK_SETTINGS = 'logbook_settings',
  ALL_LOGBOOK_VERSION_SETTINGS = 'all_logbook_version_settings',
}

export enum EPageConfigurationFieldType {
  MASTER_DATA = 'masterData',
  FORM_IO_FIELDS = 'formIoFields',
}
