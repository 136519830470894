import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EFieldType, ICompareField, ICompareFormio, ICompareHeader } from './compare-changes.model';
import { CommonModule } from '@angular/common';
import { ScwMatUiModule } from '../../component/scw-mat-ui/scw-mat-ui.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { FormioModule } from 'angular-formio';
import { HelperService } from '../../service/helper.service';

@Component({
  selector: 'scw-compare-changes',
  standalone: true,
  templateUrl: './compare-changes.component.html',
  styleUrls: ['./compare-changes.component.scss'],
  imports: [CommonModule, SharedModule, ScwMatUiModule, TranslateModule, FormioModule],
})
export class CompareChangesComponent {
  @Input() compareHeaders!: ICompareHeader[];
  @Input() compareFields!: ICompareField[];
  @Input() fieldArray!: { [value: string]: any[] };
  @Input() showUserDefinedFields: boolean = false;
  @Input() formioFields!: ICompareFormio;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  public readonly fieldType: typeof EFieldType = EFieldType;
  public linkObserver = new MutationObserver(this.getLink.bind(this));

  constructor(public readonly helperService: HelperService, public readonly translate: TranslateService) {}

  public onButtonClick(item: any): void {
    this.buttonClick.emit(item);
  }

  public formIOLinkObserverSelector(): void {
    setTimeout(() => {
      document.querySelectorAll('.formio-form').forEach((ele) => {
        if (ele) {
          this.linkObserver.observe(ele, {
            subtree: true,
            childList: true,
          });
        }
      });
    });
    this.getLink();
  }

  public getLink(): void {
    document.querySelectorAll('a[ref="fileLink"]').forEach((ele: Element) => {
      if (!ele) {
        return;
      }

      if (ele.hasAttribute('data-linkChanged') && !ele.hasAttribute('href')) {
        ele?.addEventListener('click', this.downloadFile.bind(this));
        return;
      }

      ele.setAttribute('data-linkChanged', '1');
      ele.removeAttribute('href');

      document.querySelectorAll('a[ref="fileLink"]').forEach((additionalEle: Element, index: number) => {
        const additionalAElement: Node = additionalEle.cloneNode(true);

        document.querySelectorAll('a[ref="fileLink"]')[index]?.replaceWith(additionalAElement);

        additionalAElement.addEventListener('click', this.downloadFile.bind(this));
      });
    });
  }

  public downloadFile(event: any): boolean {
    event.preventDefault();
    event.stopPropagation();

    this.helperService.showToastMessage(
      false,
      this.translate.instant('general.failed'),
      this.translate.instant('general.fileDownloadError'),
    );

    return true;
  }
}
