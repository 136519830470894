import { Action } from '@ngrx/store';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IReason } from './reason.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IActionReasonPayload } from '../../view/settings/client/action-reasons/action-reasons.model';

export const GET_REASONS = '[REASON] Get Reasons';
export const GET_REASONS_COMPLETED = '[REASON] Get Reasons Completed';
export const UPSERT_ACTION_REASONS = '[ACTION REASONS] UPSERT ACTION REASONS';
export const UPSERT_ACTION_REASONS_COMPLETED = '[ACTION REASONS] UPSERT ACTION REASONS COMPLETED';
export const FETCH_ERROR = '[REASON] Fetch Error';

export class GetReasons implements Action {
  readonly type = GET_REASONS;

  constructor(public dispatchHideLoader: boolean) {}
}

export class GetReasonsCompleted implements Action {
  readonly type = GET_REASONS_COMPLETED;

  constructor(public payload: IReason[]) {}
}

export class UpsertActionReasons implements Action {
  readonly type = UPSERT_ACTION_REASONS;
  constructor(public payload: IActionReasonPayload, public issuer: IIssuer | null) {}
}

export class UpsertActionReasonsCompleted implements Action {
  readonly type = UPSERT_ACTION_REASONS_COMPLETED;
  constructor(public payload: IResponse<IReason[]>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type ReasonActions =
  | GetReasons
  | GetReasonsCompleted
  | UpsertActionReasons
  | UpsertActionReasonsCompleted
  | FetchError;
