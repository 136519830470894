import { IWorkflow } from '../settings/workflows/workflows.model';
import { IUser } from '../settings/users/users.model';
import { ISelect } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ExtendedComponentSchema } from 'formiojs';
import { FormioForm } from 'angular-formio';
import { IFormSubmission } from '../home/home.model';
import { IFormMasterDataDetail } from '../settings/form-master-data/form-master-data.model';

export interface IFormsState {
  formsDataLoaded: boolean;
  formsDataLoading: boolean;
  forms: IForm[];
  allFormVersionsDataLoaded: boolean;
  allFormVersionsDataLoading: boolean;
  allFormVersions: IFormVersionSettings[];
  allVersionTotal: number | undefined;
  formAddLoading: boolean;
  formAddLoaded: boolean;
  formEditLoading: boolean;
  formEditLoaded: boolean;
  formsBulkEditLoading: boolean;
  formsBulkEditLoaded: boolean;
}

export interface IFormTableQuery {
  page: number;
  rowsPerPage: number;
  search?: string;
  sort?: {
    type: string;
    column: string;
  };
  isActive?: boolean;
  versionType?: 0 | 1 | null;
}

export interface IForm {
  id: number;
  form: IForm;
  formId: string;
  legacyId: string | null;
  formVersionId: number;
  name: string;
  approvalStepPosition: number;
  approveCompleted: boolean;
  activityType: EFormActivityType | null;
  formSubmissionFrequency: string;
  versionNumber: number;
  issuedDate: string;
  isSubmitted: boolean;
  formVersionApproveCompleted: boolean;
  formApproveCompleted: boolean;
  approvedByUsername: string;
  approvedByFirstName: string;
  approvedByLastName: string;
  approvedByEmail: string;
  approvedByTitle: string;
  approvalDate: string;
  isActive: boolean;
  createdAt: string;
  createdByUsername: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByEmail: string;
  createdByTitle: string;
  isArchived: boolean;
  toBeArchived: boolean;
  workflowDetail?: ISelect<number, string>;
  workflowEntriesDetail?: ISelect<number, string>;
  fieldMeta: any[];
  meta: FormioForm[];
  fieldVersion: number;
  fieldVersionId: number;
  activatedAt: string;
  criticalFieldCount: number;
  archiveInProgress?: boolean;
}

export interface IPassedIssueDateForms {
  formId: number;
  name: string;
}

export interface IFormVersion {
  activatedAt: string;
  activityType: number | null;
  activityTypeFormatted: string;
  approvalDate: string;
  approvalStepPosition: number;
  approvals: any;
  approveCompleted: boolean;
  approvedById: number;
  approvedRole: number;
  createdAt: string;
  createdBy: IUser;
  deletedAt: string;
  deletedById: number;
  fieldMeta: any[];
  fieldVersion: number;
  fieldVersionId: number;
  form: IForm;
  formId: string;
  formSubmissionFrequency: number;
  id: number;
  legacyId: string | null;
  impactOnBatch: boolean;
  isActive: boolean;
  isSubmitted: boolean;
  isSubmittedOnce: boolean;
  issuedDate: string;
  meta: any;
  name: string;
  submittedAt: string;
  updatedAt: string;
  updatedById: number;
  versionNumber: number;
  workflow: { id: number; name: string; noStep: boolean };
  workflowId: number;
  workflowEntries: number;
  workflowEntriesId: number;
  criticalFieldCount: number;
  ongoingForms?: IFormSubmission[];
  activationInProgress?: boolean;
  archiveInProgress?: boolean;
  workflowDetail?: ISelect<number, string>;
  workflowEntriesDetail?: ISelect<number, string>;
  isArchived?: boolean;
  toBeArchived?: boolean;
  useCheckIn: boolean;
}

export interface ISpecificFormData {
  formVersion: IFormVersion;
  previousVersion: IFormVersion;
  workflows: IWorkflow[];
}

export interface IFormVersionHistory {
  action: string;
  after: object;
  before: object;
  createdBy: {
    email: string;
    firstName: string;
    lastName: string;
    title: string;
    username: string;
  };
  id: number;
  meta: any;
  objectId: number;
  objectType: number;
  reason: string | null;
  userId: number;
}

export interface IFormSettingsFilterOutput {
  versionType: 0 | 1 | null;
  selectedStatusId: number[];
}

export interface IFormActionPayload {
  isActive: boolean;
  isSubmitted: boolean;
  fieldVersionId: number;
  fieldMeta: any[];
  meta: ExtendedComponentSchema[] | undefined;
  legacyId: string | null;
  versionNumber: string | null;
  activityType: EFormActivityType | null;
  issuedDate: string | null;
  name: string | null;
  workflowId: number;
  workflowEntriesId: number;
  reason: string | null;
  criticalFieldCount: number;
  useCheckIn: boolean;
  scopeId?: number | null;
}

export interface IFormVersionSettings {
  activatedAt: string;
  activationInProgress: boolean;
  activityType: number | null;
  activityTypeFormatted: string;
  approvalDate: string;
  approvalStepPosition: number;
  approvals: any;
  approveCompleted: boolean;
  approvedBy: number;
  approvedRole: number;
  client: number;
  createdAt: string;
  createdBy: {
    email: string;
    firstName: string;
    lastName: string;
    title: string;
    username: string;
    createdById: number;
  };
  criticalFieldCount: number;
  fieldMeta: any[];
  fieldVersion: number;
  form: {
    activatedAt: string;
    approvalDate: string;
    approvalStepPosition: number;
    approvals: null;
    approveCompleted: boolean;
    approvedBy: number;
    approvedRole: number;
    archiveInProgress: boolean;
    clientId: number;
    createdAt: string;
    createdBy: number;
    deletedById: number;
    formId: string;
    id: number;
    isArchived: boolean;
    isSubmitted: boolean;
    isSubmittedOnce: boolean;
    submittedAt: string;
    toBeArchived: boolean;
    updatedAt: string;
    workflow: number;
  };
  formId: number;
  id: number;
  impactOnBatch: boolean;
  isActive: boolean;
  isSubmitted: boolean;
  isSubmittedOnce: boolean;
  issuedDate: string;
  legacyId: string;
  meta: any[];
  name: string;
  reason: string;
  submittedAt: string;
  submittedBy: number;
  updatedAt: string;
  updatedBy: number;
  versionNumber: number;
  workflow: number;
  workflowDetail: IIDName;
  workflowEntries: number;
  workflowEntriesDetail: IIDName;
  workflowId: number;
}

export interface IIDName {
  id: number;
  name: string;
  isArchived?: boolean;
}

export enum EFormActivityType {
  CLEANING = 'cleaning',
  MAINTENANCE = 'maintenance',
  SETUP = 'setup',
  RUN = 'run',
  LINE_CLEARANCE = 'line_clearance',
  CALIBRATION = 'calibration',
  OTHER = 'other',
}

export interface IFormExcelPreDefined {
  activityType: string;
  formEntryWorkflow: number;
  formTemplateWorkflow: number;
  issuedDate: string;
  legacyId: string;
  name: string;
  fieldMeta: any[];
  workflowId: number;
  workflowEntriesId: number;
  activityTypeDropdown: ISelect<string, string>;
  formEntryWorkflowDropdown: ISelect<number, string>;
  formTemplateWorkflowDropdown: ISelect<number, string>;
  useCheckInDropdown: ISelect<number, string>;
  useCheckIn: number;
}

export interface IFormExcelUserDefined {
  [key: string]: any | any[] | object;
}

export interface IFormExcelData extends IFormExcelUserDefined, IFormExcelPreDefined {}

export interface IFormExcel {
  formTemplateApprovalWorkflowData: ISelect<number, string>[];
  formEntryApprovalWorkflowData: ISelect<number, string>[];
  activityTypeData: ISelect<number, string>[];
  formData: IFormExcelData[];
  fieldVersion: number | null;
}

export interface IFormExcelWithError extends IFormExcelData {
  errors: any[];
  success?: boolean;
}

export interface IFormExcelContent {
  workflows: IWorkflow[] | null;
  activityType: ISelect<number, string>[] | null;
  activeMasterDataData: IFormMasterDataDetail | null;
  yesNo: ISelect<number | string, string>[] | null;
  yesDropdown: ISelect<number | string, string>[] | null;
}

export interface IGetFormVersionsParams extends IFormTableQuery {
  formId: number | null;
}

export interface IGetFormVersionHistoryParams {
  formId?: number;
  versionIds?: number[];
}
