import { NgModule } from '@angular/core';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { ScwMatSmallButtonComponent } from './scw-mat-small-button.component';

@NgModule({
  imports: [ScwMatButtonModule],
  exports: [ScwMatSmallButtonComponent],
  declarations: [ScwMatSmallButtonComponent],
})
export class ScwMatSmallButtonModule {}
