import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TreeTableModule } from 'primeng/treetable';
import { ScwMatTreetableComponent } from './scw-mat-treetable.component';
import { ScwMatCheckboxModule } from '../scw-mat-checkbox/scw-mat-checkbox.module';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ScwMatSearchModule } from '../scw-mat-search/scw-mat-search.module';
import { ScwMatBorderColoringModule } from '../scw-mat-border-coloring/scw-mat-border-coloring.module';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { ScwMatTextareaModule } from '../scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatInputModule } from '../scw-mat-input/scw-mat-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';

@NgModule({
  imports: [
    CommonModule,
    TreeTableModule,
    HttpClientModule,
    FormsModule,
    ScwMatCheckboxModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    ScwMatSearchModule,
    ScwMatBorderColoringModule,
    ScwMatButtonModule,
    ScwMatSmallButtonModule,
    ScwMatTextareaModule,
    ScwMatInputModule,
    TranslateModule,
    NgbModule,
  ],
  declarations: [ScwMatTreetableComponent],
  bootstrap: [ScwMatTreetableComponent],
  exports: [ScwMatTreetableComponent],
})
export class ScwMatTreetableModule {}
