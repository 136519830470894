import { ElementRef, Injectable } from '@angular/core';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { EColumnWidth } from '../datatable/datatable.model';
import { EApprovalStatuses } from '../../model/enum/constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { capitalize, head } from 'lodash';
import { IBulkResponseRecord, IGetOneResponse } from '../../model/interface/crud-response-interface.model';
import { IBulkActionSingleResponse, TBulkActions } from './bulk-action.model';
import { TFailedRecordData } from '../../component/bulk-error-modal/bulk-error-modal.model';
import { BulkErrorModalComponent } from '../../component/bulk-error-modal/bulk-error-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BulkActionConfirmationModalComponent } from '../../component/bulk-action-confirmation-modal/bulk-action-confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class BulkActionService {
  private readonly successToastTitle: string = this.translate.instant('general.success');
  private readonly checkboxHeader: ITableHeader = {
    value: '',
    name: '',
    selected: true,
    sortable: false,
    width: EColumnWidth.checkbox,
    type: PageConfigurationTypes.TABLE,
  };

  public readonly selectableStatuses: EApprovalStatuses[] = [
    EApprovalStatuses.NOT_SUBMITTED,
    EApprovalStatuses.APPROVED,
    EApprovalStatuses.COMPLETED,
  ];

  public constructor(private readonly translate: TranslateService, private readonly toast: ToastrService) {}

  public appendCheckboxHeader(headers: ITableHeader[]): ITableHeader[] {
    return [this.checkboxHeader, ...headers];
  }

  public getIsSelectAll(
    selectedData: unknown[],
    tableData: (unknown & { approvalStepPosition: EApprovalStatuses })[],
  ): boolean {
    return (
      selectedData.length !==
      tableData.filter((datum: unknown & { approvalStepPosition: EApprovalStatuses }): boolean =>
        this.selectableStatuses.includes(datum.approvalStepPosition),
      ).length
    );
  }

  public getIsDraftRecordSelected(
    selectedIds: number[],
    tableData: (unknown & { id: number; approvalStepPosition: EApprovalStatuses })[],
  ): boolean {
    return (
      tableData.filter(
        (datum: unknown & { id: number; approvalStepPosition: EApprovalStatuses }): boolean =>
          selectedIds.includes(datum.id) && datum.approvalStepPosition === EApprovalStatuses.NOT_SUBMITTED,
      ).length > 0
    );
  }

  public getIsApprovedOrCompletedRecordSelected(
    selectedIds: number[],
    tableData: (unknown & { id: number; approvalStepPosition: EApprovalStatuses })[],
  ): boolean {
    return (
      tableData.filter(
        (datum: unknown & { id: number; approvalStepPosition: EApprovalStatuses }): boolean =>
          selectedIds.includes(datum.id) &&
          [EApprovalStatuses.APPROVED, EApprovalStatuses.COMPLETED].includes(datum.approvalStepPosition),
      ).length > 0
    );
  }

  public onTableCheckboxChanged(event: MatCheckboxChange, selectedIds: number[]): void {
    const recordId: number = Number(event.source.value);

    if (event.checked) {
      selectedIds.push(recordId);
    } else {
      const recordIndex: number = selectedIds.indexOf(recordId);

      if (recordIndex >= 0) {
        selectedIds.splice(recordIndex, 1);
      }
    }
  }

  public selectOrUnselectAll(
    checkBoxElementRef: ElementRef,
    checkboxIdPrefix: string,
    tableData: (unknown & { id: number })[],
    isSelectAll: boolean,
  ): void {
    if (checkBoxElementRef.nativeElement.querySelector(`[id^="${checkboxIdPrefix}"]`) !== null) {
      tableData.forEach((datum: unknown & { id: number }): void => {
        const checkbox: HTMLInputElement | undefined = head(
          document.getElementById(`${checkboxIdPrefix}${datum.id}`)?.getElementsByTagName('input'),
        );

        if (checkbox && isSelectAll !== checkbox?.checked) {
          checkbox.click();
        }
      });
    }
  }

  public handleBulkActionSuccess(
    response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>,
    bulkActionRecordType: 'logbook' | 'form',
    actionType: TBulkActions,
    bulkErrorModalComponentInstance: BulkErrorModalComponent,
  ): void {
    if (response.data.some((datum: IGetOneResponse<IBulkActionSingleResponse>) => !datum.success)) {
      const failedRecordData: IGetOneResponse<IBulkActionSingleResponse>[] = [];

      response.data.forEach((datum: IGetOneResponse<IBulkActionSingleResponse>): void => {
        if (!datum.success) {
          failedRecordData.push(datum);
        }
      });

      const formattedFailedRecordData: TFailedRecordData[] =
        BulkErrorModalComponent.formatFailedRecordsForBulkErrorModal(failedRecordData);

      const modalTitle: string = this.translate.instant(`bulkAction.${bulkActionRecordType}.modal.title.${actionType}`);
      const successfulMessage: string = this.translate.instant('general.changesSavedSuccessfullyWithCountAndType', {
        count: response.data.length - failedRecordData.length,
        type: this.translate.instant(`bulkAction.${bulkActionRecordType}.optionalPlural`),
      });
      const failureMessage: string = this.translate.instant(
        `bulkAction.error.modal.${bulkActionRecordType}FailureMessage`,
      );

      bulkErrorModalComponentInstance.showModal(
        modalTitle,
        successfulMessage,
        failureMessage,
        formattedFailedRecordData,
      );
    } else {
      this.toast.success(
        this.translate.instant('general.changesSavedSuccessfullyWithCountAndType', {
          count: response.data.length,
          type: this.translate.instant(`bulkAction.${bulkActionRecordType}.optionalPlural`),
        }),
        this.successToastTitle,
        {
          closeButton: false,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );
    }
  }

  public getNoSelectableRecord(tableData: (unknown & { approvalStepPosition: EApprovalStatuses })[]): boolean {
    return (
      !tableData.length ||
      !tableData.some((datum: unknown & { approvalStepPosition: EApprovalStatuses }): boolean =>
        this.selectableStatuses.includes(datum.approvalStepPosition),
      )
    );
  }

  public handleBulkActionConfirmationModal(
    bulkActionRecordType: 'logbook' | 'form',
    actionType: TBulkActions,
    bulkActionConfirmationModalComponent: BulkActionConfirmationModalComponent,
    isSingleData: boolean = false,
  ): void {
    const actionTypeKey: string = actionType === 'bulk-submit' ? 'submit' : 'activate';
    const singleDataConfirmationKeyPrefix: string = `settings.${bulkActionRecordType}s.${actionTypeKey}${capitalize(
      bulkActionRecordType,
    )}`;
    const modalTitleKey: string = isSingleData
      ? `${singleDataConfirmationKeyPrefix}.header`
      : `bulkAction.${bulkActionRecordType}.modal.title.${actionType}`;
    const bulkActionTextKey: string = isSingleData
      ? `${singleDataConfirmationKeyPrefix}.text`
      : `bulkAction.confirmationModal.${actionType}.confirmationText`;

    const modalTitle: string = this.translate.instant(modalTitleKey);
    const bulkActionText: string = this.translate.instant(bulkActionTextKey);
    const bulkActionButtonText: string = this.translate.instant(
      `bulkAction.confirmationModal.${actionType}.confirmationButton`,
    );

    bulkActionConfirmationModalComponent.showModal(modalTitle, bulkActionText, bulkActionButtonText, actionType);
  }
}
