import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IFilterComponent, ISimpleOutputOptions } from '../filter.class';
import {
  DateRangeDropsTypes,
  DateRangeOpensTypes,
  IDateRangeReturn,
} from '../../date-range-picker/date-range-picker.model';
import { TranslateService } from '@ngx-translate/core';
import { DateRangePickerComponent } from '../../date-range-picker/date-range-picker.component';
import { IFilterDateRangePicker } from './filter-date-range-picker.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IGenericObject } from '../../../model/interface/generic.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './filter-date-range-picker.component.html',
  styleUrls: [],
})
export class FilterDateRangePickerComponent implements OnInit, OnDestroy, IFilterComponent {
  outputOptions!: ISimpleOutputOptions;
  outputSubject!: Subject<any>;
  @ViewChild('filterDateRangePickerContainer', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;
  @Input() defaultSettings!: IFilterDateRangePicker;
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  elementID!: string;
  public defaultPickerSettings: IFilterDateRangePicker = {
    alwaysShowCalendars: true,
    autoApply: false,
    className: '',
    wide: true,
    closeOnAutoApply: false,
    customRangeDirection: false,
    disabled: false,
    isRequired: false,
    drops: DateRangeDropsTypes.down,
    emptyWeekRowClass: '',
    firstDayOfNextMonthClass: '',
    firstMonthDayClass: '',
    isCustomDate: () => {
      return false;
    },
    isInvalidDate: () => {
      return false;
    },
    keepCalendarOpeningWithRange: true,
    lastDayOfPreviousMonthClass: '',
    lastMonthDayClass: '',
    lockStartDate: false,
    maxDate: undefined,
    minDate: undefined,
    opens: DateRangeOpensTypes.right,
    placeholder: this.translate.instant('dateRangePicker.placeholder'),
    readonly: true,
    showCancel: true,
    showClearButton: true,
    showCustomRangeLabel: true,
    showISOWeekNumbers: false,
    showRangeLabelOnInput: false,
    showWeekNumbers: true,
    singleDatePicker: false,
    timePicker: false,
    timePickerIncrement: 1,
    timePickerSeconds: false,
    dateLimit: undefined,
  };
  private datePickerComponent!: DateRangePickerComponent;
  private selectedDates!: IDateRangeReturn;
  private disabledSubjectSubscription!: Subscription;
  public isDisabled: boolean = false;
  public dependedOptionConfiguration: any[] = [];
  private element!: ComponentRef<DateRangePickerComponent>;
  private componentFactory!: ComponentFactory<DateRangePickerComponent>;

  constructor(private translate: TranslateService, private componentFactoryResolver: ComponentFactoryResolver) {}
  ngOnInit(): void {
    this.defaultPickerSettings = {
      ...this.defaultSettings,
    };
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(DateRangePickerComponent);
    this.createComponent();
    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus: boolean) => {
      this.isDisabled = disabledStatus;
      this.element.instance['disabled'] = disabledStatus;
    });
    this.element.instance['disabled'] = this.isDisabled;
  }

  private createComponent(): void {
    this.container.clear();
    this.element = this.container.createComponent(this.componentFactory);
    Object.keys(this.defaultPickerSettings).forEach((property) => {
      _.set(this.element.instance, property, this.defaultPickerSettings[property as keyof IFilterDateRangePicker]);
    });
    this.datePickerComponent = this.element.instance;
    this.datePickerComponent.inputModelChange.subscribe((data) => {
      this.selectedDates = data;
      this.outputSubject.next(this.getFiltersOutputs());
    });
  }

  public setSelectedDates(dates: IDateRangeReturn): void {
    this.datePickerComponent.inputModel = dates;
    this.datePickerComponent.inputModelChange.next(dates);
  }

  public getFiltersOutputs(): any {
    const output: IGenericObject<any> = {};
    output[this.outputOptions.filterObjectId] = this.selectedDates;
    this.publishValue(this.selectedDates);
    return output;
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((config: any) => {
      if (
        this.elementID === config?.dependedElementId &&
        config?.dependedOption in this.defaultPickerSettings
      ) {
        _.set(
          this.defaultPickerSettings,
          config.dependedOption,
          config?.getDependentValue(config?.value),
        );
        if (config?.additionalChanges) {
          this.defaultPickerSettings = {
            ...this.defaultPickerSettings,
            ...config?.additionalChanges(
              this.defaultPickerSettings[config.dependedOption as keyof IFilterDateRangePicker],
            ),
          };
        }
        this.createComponent();
      }
    });
  }

  public publishValue(value: any): void {
    this.dependedOptionConfiguration.forEach((config) => {
      if (config.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();

    this.dependedOptionConfiguration?.forEach((option) => {
      option.dependedOptionListener?.complete();
    });
  }
}
