<mat-form-field class="no-line" floatLabel="never" [class.block]="block" [class.no-padding]="noPadding">
  <label *ngIf="label" class="textarea-label">{{ label }}</label>
  <textarea
    matInput
    class="scw-mat-textarea"
    cols="70"
    [class.has-errors]="hasErrors"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [placeholder]="placeholder || ''"
    [cdkTextareaAutosize]="autosize"
    [cdkAutosizeMinRows]="autosizeMinRows"
    [cdkAutosizeMaxRows]="autosizeMaxRows"
    [ngClass]="className"
    [(ngModel)]="inputModel"
    (keyup)="onKeyup.emit()"
    (keyup.enter)="onKeyupEnter.emit()"
    (ngModelChange)="onNgModelChange()"
    matTextareaAutosize
  ></textarea>
  <ng-template [ngIf]="errorText || hint">
    <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
      <em
        class="sub-text-icon fas"
        [class.fa-exclamation-circle]="errorText"
        [class.fa-info-circle]="!errorText && hint"
      ></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
      <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
    </mat-hint>
  </ng-template>
</mat-form-field>
