import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalComponent } from './scw-mat-modal.component';
import { ScwMatFormModule } from '../scw-mat-form/scw-mat-form.module';
import { ScwMatModalSectionTitleModule } from './scw-mat-modal-section-title/scw-mat-modal-section-title.module';
import { ScwMatModalSeparatorModule } from './scw-mat-modal-separator/scw-mat-modal-separator.module';
import { ScwMatModalTextWithIconModule } from './scw-mat-modal-text-with-icon/scw-mat-modal-text-with-icon.module';
import { ScwMatModalFailedItemsModule } from './scw-mat-modal-failed-items/scw-mat-modal-failed-items.module';

@NgModule({
  declarations: [ScwMatModalComponent],
  imports: [
    CommonModule,
    ScwMatFormModule,
    ScwMatModalSectionTitleModule,
    ScwMatModalSeparatorModule,
    ScwMatModalTextWithIconModule,
    ScwMatModalFailedItemsModule,
  ],
  exports: [
    ScwMatModalComponent,
    ScwMatModalSectionTitleModule,
    ScwMatModalSeparatorModule,
    ScwMatModalTextWithIconModule,
    ScwMatModalFailedItemsModule,
  ],
})
export class ScwMatModalModule {}
