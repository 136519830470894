<button
  mat-flat-button
  type="button"
  class="scw-mat-button"
  onfocus=blur()
  [class.block]="block"
  [disabled]="disabled"
  [class.mat-cta]="type === 'cta'"
  [class.mat-standard]="type === 'standard'"
  [class.mat-danger]="type === 'danger'"
  [class.mat-ghost]="type === 'ghost'"
  [class.mat-approve]="type === 'approve'"
  [class.mat-sendBack]="type === 'sendBack'"
  [class.icon-button]="iconButton"
  [class.button-xs]="size === 'xs'"
  [class.button-sm]="size === 'sm'"
  [class.button-md]="size === 'md'"
  [class.button-lg]="size === 'lg'"
  [class.fab]="fab"
  [ngClass]="className"
  (click)="onClickEmitter()"
>
  <span>
    <ng-content></ng-content>
  </span>
</button>
