
export interface IDocumentFilter {
  dateRange: IDate;
}

export interface IDate {
  startDate: string;
  endDate: string;
}

export interface IDocumentPageConfig extends IPageConfig {
  id: ContentType;
  reference?: IReferenceContent;
}

export interface IReferenceContent {
  ids: ContentType[];
}

export interface IPageConfig {
  page: {
    orientation: PageOrientation;
    padding?: IDocumentPadding;
    isSinglePage?: boolean;
  };
  size?: IDocumentSize;
  pageContentHeight?: number;
  remainingPageContentHeightBalance: number;
}

export interface IDocumentSize {
  width?: number;
  height?: number;
}

export interface IDocumentPadding {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

export interface IDocumentBanner {
  header?: IDocumentHeader;
  footer?: IDocumentFooter;
}

export interface IDocumentHeader {
  title: string;
  signature?: string[];
  doubleImage?: boolean;
  fileName?: string;
  uri?: string;
}

export interface IDocumentFooter extends IDocumentUri {
  doubleImage?: boolean;
  noImage?: boolean;
  noPage?: boolean;
  uri: string;
}

export interface IDocumentUri {
  uri: string;
}

export interface IDocument {
  type: DocumentTypes;
  pageConfigs: IDocumentPageConfig[];
  autoFill: boolean;
  printableComponentsSelectors: IPrintableComponent[];
  formIoSelectors?: IDocumentFormIoSelectors[];
  bannerConfiguration: IDocumentBanner
}

export enum DocumentTypes {
  PDF = 'pdf',
  TEXT = 'text',
  IMG = 'img',
}

export enum PageOrientation {
  portrait = 'p',
  landscape = 'l',
}


export enum ContentType {
  QR = 1,
  QR_LABEL = 2,
  FORM_ENTRY = 3,
}

export enum EDocumentElementSelector {
  CANVAS = 'canvas',
  IS_PRINTABLE = '.is-printable',
}

export interface IPageHeaderFooterInfo {
  header: boolean;
  footer: boolean;
}

export interface ISearchSelectorCounters {
  [key: string]: number;
}

export interface ISplitCanvasProperties {
  pageNumber: number;
  index: number[];
  positionY: number[];
}

export interface IDocumentSelectors {
  selector: string;
  parentContainerClass: string;
  isPanel?: boolean;
  isPanelHeader?: boolean;
  isRootLevel?: boolean;
  childrenComponentCoordinates?: IDocumentFormIoSelectors[]
}

export interface IDocumentFormIoSelectors extends IDocumentSelectors{
  index: string;
}

export interface IPrintableComponent  extends IDocumentSelectors{
  orientationPreference: PageOrientation;
  formioElement?: boolean;
  smartCrop?: boolean;
  printAsAutoTable?: boolean;
  startNewPage?: boolean;
  zoomLevel?: number;
}

export interface IDocumentContentToBePrinted {
  canvasElement: HTMLCanvasElement | HTMLElement;
  originIndex: number;
  forcedCanvasHeight: number;
  forcedCanvasWidth: number;
  imageRatio: number;
}
