import { IMyReportsState } from './my-reports.model';
import { Action } from '@ngrx/store';
import * as Actions from './my-reports.actions';
import * as _ from 'lodash';

export const myReportsInitialState: IMyReportsState = {
  myReportsDataLoading: false,
  myReportsDataLoaded: false,
  myReports: null,
  myReportsGetLoading: false,
  myReportsGetCompleted: false,
  report: null,
  myReportsAddLoading: false,
  myReportsAddCompleted: false,
  myReportsEditLoading: false,
  myReportsEditCompleted: false,
  myReportsRemoveLoading: false,
  myReportsRemoveCompleted: false,
  myReportsBulkRemoveLoading: false,
  myReportsBulkRemoveCompleted: false,
  errors: [],
};

export function myReportsReducer(state: IMyReportsState = myReportsInitialState, baseAction: Action) {
  const action = baseAction as Actions.MyReportsActions;

  switch (action.type) {
    case Actions.START_MY_REPORTS_LOADING:
      return {
        ...state,
        ...{
          myReportsDataLoading: true,
          myReportsDataLoaded: false,
        },
      };
    case Actions.MY_REPORTS_LOADED:
      return {
        ...state,
        ...{
          myReports: action.payload,
          myReportsDataLoading: false,
          myReportsDataLoaded: true,
        },
      };
    case Actions.START_GET_RECORD_LOADING:
      return {
        ...state,
        ...{
          myReportsGetLoading: true,
          myReportsGetCompleted: false,
        },
      };
    case Actions.GET_RECORD_COMPLETED:
      return {
        ...state,
        ...{
          report: action.payload,
          myReportsGetLoading: false,
          myReportsGetCompleted: true,
        },
      };
    case Actions.START_ADD_RECORD_LOADING:
      return {
        ...state,
        myReportsAddLoading: true,
        myReportsAddCompleted: false,
      };
    case Actions.ADD_RECORD_COMPLETED:
      return {
        ...state,
        myReportsAddLoading: false,
        myReportsAddCompleted: true,
      };
    case Actions.REMOVE_RECORD_COMPLETED:
    case Actions.START_REMOVE_RECORD_LOADING:
    case Actions.START_EDIT_RECORD_LOADING:
      return {
        ...state,
        myReportsEditLoading: true,
        myReportsEditCompleted: false,
      };
    case Actions.EDIT_RECORD_COMPLETED:
      return {
        ...state,
        myReportsEditLoading: false,
        myReportsEditCompleted: true,
      };
    case Actions.START_BULK_REMOVE_RECORD_LOADING:
      return {
        ...state,
        myReportsBulkRemoveLoading: true,
        myReportsBulkRemoveCompleted: false,
      };
    case Actions.BULK_REMOVE_RECORD_COMPLETED:
      return {
        ...state,
        myReportsBulkRemoveLoading: false,
        myReportsBulkRemoveCompleted: true,
      };
    case Actions.CLEAR_STATE:
      return { ...myReportsInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };

    default:
      return state;
  }
}
