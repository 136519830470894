import { ILogsReportsState } from './logs-reports.model';
import { Action } from '@ngrx/store';
import * as Actions from './logs-reports.actions';
import _ from 'lodash';

export const logsReportsInitialState: ILogsReportsState = {
  getLogsReportsData: false,
  getLogsReportsDataCompleted: false,
  logsReports: null,
  tableSettings: [],
  filters: null,
  errors: [],
};

export function logsReportsReducer(state: ILogsReportsState = logsReportsInitialState, baseAction: Action) {
  const action = baseAction as Actions.LogsReportsActions;

  switch (action.type) {
    case Actions.GET_LOGS_REPORTS_DATA:
      return {
        ...state,
        getLogsReportsData: true,
        getLogsReportsDataCompleted: false,
      };
    case Actions.GET_LOGS_REPORTS_DATA_COMPLETED:
      return {
        ...state,
        logsReports: action.payload,
        getLogsReportsData: false,
        getLogsReportsDataCompleted: true,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };

    default:
      return state;
  }
}
