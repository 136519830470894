<div class="page-config-container">
  <div>
    <scw-mat-small-button (onClick)="goToMyReport()" [hidden]="!showReturnToMyReportButton" class="mr-3">
      <em class="fas fa-arrow-left"></em>
      {{ 'pageConfiguration.returnToMyReport' | translate }}
    </scw-mat-small-button>
    <scw-mat-small-button
      [disabled]="isConfigurationButtonDisabled"
      [iconButton]="true"
      (onClick)="showModal(user_home_config_modal, config_modal, forceRemoteColumnLoad)"
    >
      <em class="fas fa-sliders-h"></em>
    </scw-mat-small-button>
  </div>
</div>
<ng-template #config_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button id="close-button-config" type="button" class="close" onfocus="blur()" aria-label="Close"
            (click)="onCloseClick()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div class="col-12">
        <div class="row">
          <div class="row" *ngIf="tabs[0].table.length">
            <div class="col-12">
              <h6 class="scw-header">
                <em class="fas fa-table"></em>
                {{ 'pageConfiguration.modal.tableConfiguration' | translate }}
              </h6>
            </div>
            <div class="col-12">
              <div class="label-text pb-2">
                {{ tableLabelTranslation }}
              </div>
            </div>
            <div class="col-12">
              <div class="pb-2">
                <scw-mat-small-button
                  (onClick)="selectOrUnselectAll(tabs[0].table.length !== selectedRecords.length)"
                >{{
                  tabs[0].table.length === selectedRecords.length
                    ? ('general.unselectAll' | translate)
                    : ('general.selectAll' | translate)
                  }}</scw-mat-small-button
                >
              </div>
            </div>
            <div class="col-12">
              <div
                cdkDropList
                class="datatable-list"
                (cdkDropListDropped)="drop($event, tabs[0].table)"
                cdkDropListOrientation="vertical"
                [cdkDropListEnterPredicate]="cdkDropListEnterPredicateColumn"
              >
                <ng-container *ngFor="let item of tabs[0].table; trackBy: trackByValue">
                  <ng-template [ngIf]="item.draggable">
                    <div class="datatable-box move-cursor" [id]="item.value" cdkDrag [cdkDragData]="item">
                      <div class="page_configuration_item-left">
                        <scw-mat-checkbox
                          [id]="'table-config-checkbox-' + item.value"
                          [(inputModel)]="!!item.selected"
                          [disabled]="!!item.disabled"
                          (onChange)="changeCheckBox($event, pageHeader.moveUncheckedToEnd ?? true)"
                        >
                        </scw-mat-checkbox>
                        <div class="page_configuration_item-left-title">{{ item.name }}</div>
                      </div>
                      <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="!item.draggable">
                    <div class="datatable-box" [id]="item.value">
                      <div class="page_configuration_item-left">
                        <scw-mat-checkbox
                          [id]="'table-config-checkbox-' + item.value"
                          [(inputModel)]="!!item.selected"
                          [disabled]="!!item.disabled"
                          (onChange)="changeCheckBox($event, false)"
                        >
                        </scw-mat-checkbox>
                        <div class="page_configuration_item-left-title">{{ item.name }}</div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="apply(true)" [type]="'standard'">
        {{ 'general.setAsDefault' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #user_home_config_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button id="close-button-new-config" type="button" class="close" onfocus="blur()" aria-label="Close"
            (click)="onCloseClick()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div class="col-12">
        <div class="filter-header mb-4">
          <h6 class="scw-header">
            <em class="fas fa-filter"></em>
            {{ 'pageConfiguration.modal.filterConfiguration' | translate }}
          </h6>
          <scw-mat-select
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'pageConfiguration.modal.labels.forms' | translate }}"
            [data]="formDropdownData"
            [singleSelection]="false"
            [enableSearchFilter]="true"
            [enableCheckAll]="true"
            [showCheckbox]="true"
            [inputModel]="configurationFormData?.forms?.value"
            (inputModelChange)="onFormChange($event)"
            (onDeSelectAll)="onFormChange($event)"
          ></scw-mat-select>
          <h6 class="scw-header">
            <em class="fas fa-table"></em>
            {{ 'pageConfiguration.modal.tableConfiguration' | translate }}
          </h6>
          <div class="label-text pb-2">
            {{ tableLabelTranslation }}
          </div>
          <scw-mat-search
            class="scw_mat-add_edit_modal-item-input"
            placeholder="{{ 'general.search' | translate }}"
            behavior="delayed"
            [minLength]="0"
            [block]="true"
            [(inputModel)]="searchBoxText"
            (onSearch)="onKeyUpSearchBox($event)"
          >
          </scw-mat-search>
        </div>
        <div class="row section-list">
          <ng-container
            *ngTemplateOutlet="tableHeaderSection; context: { $implicit: generalConfigurationArray }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="apply(true)" [type]="'standard'">
        {{ 'general.setAsDefault' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #tableHeaderSection let-section>
  <div class="row table-header-row">
    <div class="col-6 header-section">
      <div class="column-title available-column-title">
        {{ 'pageConfiguration.modal.labels.availableColumns' | translate }}
        <scw-mat-button
          [type]="'standard'"
          size="xs"
          class="move-button"
          (onClick)="onSelectAllClick()"
          [disabled]="isSelectAllButtonDisabled">
          <span>
          {{ 'pageConfiguration.modal.buttons.selectAll' | translate }}
        </span>
        </scw-mat-button>
      </div>
      <div class="label-text header-item-drop-area-title">
        <div class="header-item-drop-area-icon"><i class="fas fa-exclamation-circle"></i></div>
        <div
          class="header-item-drop-area-description">{{ 'pageConfiguration.modal.labels.availableColumnsDescription' | translate }}</div>
      </div>
      <div *ngFor="let headerGroup of section">
        <div class="header-group">
          <div class="scw-header header-group-title">{{ headerGroup.type }}</div>
          <div
            cdkDropList
            class="configuration-datatable-list field-group-list"
            [id]="headerGroup.type"
          >
            <ng-container *ngFor="let groupItem of headerGroup.groupItems; trackBy: trackByValue">
              <div *ngIf="!groupItem.selected"
                   [ngClass]="{
                   'datatable-box': true,
                   'mobile-device': helperService.isTouchDevice}"
                   [id]="groupItem.value">
                <div class="d-flex">
                  <scw-mat-checkbox
                    [id]="'table-config-checkbox-' + groupItem.value"
                    [(inputModel)]="!!groupItem.selected"
                    [disabled]="!!groupItem.disabled"
                    (onChange)="changeCheckBox($event, false)"
                  >
                  </scw-mat-checkbox>
                  <div class="px-2">{{ groupItem.name }}
                  </div>
                </div>
                <div *ngIf="headerGroup.type !== masterDataText">
                  <scw-mat-button
                    [id]="'show-form-button-' + groupItem.value"
                    [type]="'standard'"
                    [ngbPopover]="preDefinedFieldsPopover"
                    size="xs"
                    class="form-button"
                    (onClick)="getFieldsForm(groupItem, groupItem.value)"
                  >
                    <span>
                    {{ 'pageConfiguration.modal.buttons.forms' | translate }}
                    </span>
                  </scw-mat-button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="right-group-list">
      <div class="column-title selected-column-title">
        {{ 'pageConfiguration.modal.labels.selectedColumns' | translate }}
        <scw-mat-button
          [type]="'standard'"
          class="move-button"
          size="xs"
          (onClick)="onResetClick()"
          [disabled]="isResetButtonDisabled">
          <span>
          {{ 'pageConfiguration.modal.buttons.reset' | translate }}
        </span>
        </scw-mat-button>
      </div>
      <div class="label-text header-item-drop-area-title">
        <div class="header-item-drop-area-icon"><i class="fas fa-exclamation-circle"></i></div>
        <div
          class="header-item-drop-area-description">{{ 'pageConfiguration.modal.labels.dropAreaDescription' | translate }}</div>
      </div>
      <div
        cdkDropList
        class="configuration-datatable-list overflow-auto"
        (cdkDropListDropped)="drop($event,  tabs[0].table)"
        cdkDropListOrientation="vertical"
      >
        <ng-container *ngFor="let selectedItem of this.tabs[0].table; trackBy: trackByValue">
          <div
            [id]="selectedItem.value"
            [ngClass]="{
            'datatable-box': true,
            'move-cursor': true,
            'mobile-device': helperService.isTouchDevice,
            'datatable-box_disabled_columns': selectedItem.disabled,
            'datatable-box_right_columns': !selectedItem.disabled}"
            cdkDrag
            [cdkDragData]="selectedItem"
          >
            <div class="d-flex">
              <scw-mat-checkbox
                [id]="'table-config-checkbox-' + selectedItem.value"
                [(inputModel)]="!!selectedItem.selected"
                [disabled]="!!selectedItem.disabled"
                (onChange)="changeCheckBox($event, false)"
              >
              </scw-mat-checkbox>
              <div class="px-2">
                {{ selectedItem.name }}
              </div>
            </div>
            <div class="d-flex">
              <div *ngIf="selectedItem.type !== masterDataText" class="show-forms-button">
                <scw-mat-button
                  [id]="'show-form-button-' + selectedItem.value"
                  [type]="'standard'"
                  [ngbPopover]="preDefinedFieldsPopover"
                  size="xs"
                  (onClick)="getFieldsForm(selectedItem, selectedItem.value)"
                >
                <span>
                  {{ 'pageConfiguration.modal.buttons.forms' | translate }}
                </span>
                </scw-mat-button>
              </div>
              <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #config_custom_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button id="close-button" type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="ml-3 mr-3">
      <div class="row">
        <div class="col-8">
          <div class="datatable-buttons d-flex align-items-center justify-content-between mb-16px">
            <div class="datatable-buttons-left">
              <scw-mat-small-button>
                {{ 'pageConfiguration.customManager.table.create' | translate }}
              </scw-mat-small-button>
            </div>
            <div class="datatable-buttons-right"></div>
          </div>
          <datatable
            paginationSize="lg"
            noDataText="{{ 'general.datatable.noData' | translate }}"
            trackByProperty="id"
            [serverSide]="true"
            [shouldPaginate]="false"
            [headers]="customColumnManagerHeaders"
            [items]="customColumnManagerData$"
          >
            <ng-template let-item let-i="index">
              <ng-container *ngFor="let header of customColumnManagerHeaders; index as i">
                <td
                  class="height-min-line"
                  [class.roleCellWithBorderColor]="header.value === 'approvalStepPosition'"
                  [class.selectedCell]="item['selected']"
                >
                  <ng-container *ngIf="header.value === 'actions'; else createdBy">
                    <div class="h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0 gap-3">
                      <ng-container *ngIf="item['selected']; else notSelectedRow">
                        {{ 'pageConfiguration.customManager.table.selected' | translate }}
                      </ng-container>
                      <ng-template #notSelectedRow>
                        <ng-container>
                          <scw-mat-small-button type="cta">
                            {{ 'pageConfiguration.customManager.table.apply' | translate }}
                          </scw-mat-small-button>
                        </ng-container>
                      </ng-template>

                      <scw-mat-small-button [disabled]="!item['ownedByMe']">
                        {{ 'pageConfiguration.customManager.table.saveCurrentSetup' | translate }}
                      </scw-mat-small-button>
                      <scw-mat-small-button [disabled]="!item['ownedByMe']" [iconButton]="true"
                      ><em class="fas fa-trash"></em
                      ></scw-mat-small-button>
                    </div>
                  </ng-container>
                  <ng-template #createdBy>
                    <ng-container *ngIf="header.value === 'createdBy'; else publishedColumn">
                      <app-e-signature
                        [signatureDetails]="item[header.value]"
                        [createdAt]="item['createdAt']"
                      ></app-e-signature>
                    </ng-container>
                  </ng-template>

                  <ng-template #publishedColumn>
                    <ng-container *ngIf="header.value === 'published'; else base">
                      <scw-mat-checkbox [value]="item.published" [disabled]="!item['ownedByMe']"></scw-mat-checkbox>
                    </ng-container>
                  </ng-template>

                  <ng-template #base>
                    <ng-container>
                      {{ item[header.value] }}
                    </ng-container>
                  </ng-template>
                </td>
              </ng-container>
            </ng-template>
          </datatable>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="row" *ngIf="tabs[0].table.length">
              <div class="col-12">
                <h6 class="scw-header">
                  <em class="fas fa-table"></em>
                  {{ 'pageConfiguration.modal.tableConfiguration' | translate }} for
                  <h3>Cleaning Forms Only</h3>
                </h6>
              </div>
              <div class="col-12">
                <div class="label-text">
                  {{ tableLabelTranslation }}
                </div>
              </div>
              <div class="col-12">
                <div
                  id="datatable-list-id"
                  cdkDropList
                  class="datatable-list"
                  (cdkDropListDropped)="drop($event, tabs[0].table)"
                  cdkDropListOrientation="vertical"
                  [cdkDropListEnterPredicate]="cdkDropListEnterPredicateColumn"
                >
                  <ng-container *ngFor="let item of tabs[0].table; trackBy: trackByValue">
                    <div class="datatable-box" [id]="item.name" cdkDrag [cdkDragData]="item">
                      <div class="page_configuration_item-left">
                        <scw-mat-checkbox
                          [id]="'table-config-checkbox-' + item.name"
                          [(inputModel)]="!!item.selected"
                          [disabled]="!!item.disabled"
                          (onChange)="changeCheckBox($event, true)"
                        >
                        </scw-mat-checkbox>
                        <div class="page_configuration_item-left-title">{{ item.name }}</div>
                      </div>
                      <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #unsaved_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <h6 class="scw-header">
        {{ 'pageConfiguration.unsavedChanges.label' | translate }}
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="dont-apply-button">
      <scw-mat-button (onClick)="dontApply()" [type]="'danger'">
        {{ 'general.dontApply' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()" [type]="'standard'">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #preDefinedFieldsPopover>
  <ng-template [ngIf]="generalConfigurationArray.length" [ngIfElse]="noData">
    <div *ngFor="let form of popUpFormNames; index as i">
      <ul class="scw-basic-list">
        <li>{{ form }}</li>
      </ul>
    </div>
  </ng-template>
  <ng-template #noData>
    {{ 'datatable.noData' | translate }}
  </ng-template>
</ng-template>
