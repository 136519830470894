import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../model/interface/crud-response-interface.model';
import { HelperService } from '../../helper.service';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../../store/logbook.reducer';
import { IAddMyReport, IDeleteMyReport, IMyReportsData } from '../../../../store/reports/my-reports/my-reports.model';
import { IIssuer } from '../../../component/issuer/issuer.model';

@Injectable({
  providedIn: 'root',
})
export class MyReportsService {
  private readonly MY_REPORTS = {
    BASE_URL: '/my-reports/',
    BULK: 'bulk/',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public readonly helperService: HelperService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {}

  public getMyReportData(params: HttpParams): Observable<IGetManyResponse<IMyReportsData>> {
    return this.http.get<IGetManyResponse<IMyReportsData>>(`${this.baseUrl}${this.MY_REPORTS.BASE_URL}`, {
      params,
    });
  }

  public getReport(id: number): Observable<IGetOneResponse<IMyReportsData>> {
    return this.http.get<IGetOneResponse<IMyReportsData>>(`${this.baseUrl}${this.MY_REPORTS.BASE_URL}${id}/`);
  }

  public addReport(recordPayload: IAddMyReport, issuer: IIssuer | null): Observable<IBaseOneResponse<IAddMyReport>> {
    const url = `${this.baseUrl}${this.MY_REPORTS.BASE_URL}`;
    return this.http.post<IBaseOneResponse<IAddMyReport>>(url, {
      issuer,
      payload: recordPayload,
    });
  }

  public editReport(recordPayload: IAddMyReport, issuer: IIssuer | null): Observable<IBaseOneResponse<IAddMyReport>> {
    const url = `${this.baseUrl}${this.MY_REPORTS.BASE_URL}${recordPayload.id}/`;
    return this.http.put<IBaseOneResponse<IAddMyReport>>(url, {
      issuer,
      payload: recordPayload,
    });
  }

  public deleteReport(recordPayload: IAddMyReport, issuer: IIssuer | null): Observable<IBaseOneResponse<IAddMyReport>> {
    const url = `${this.baseUrl}${this.MY_REPORTS.BASE_URL}${recordPayload.id}/`;
    return this.http.delete<IBaseOneResponse<IAddMyReport>>(url, {
      body: {
        issuer,
        payload: recordPayload,
      },
    });
  }
  public bulkDeleteReport(
    recordPayload: IDeleteMyReport,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IAddMyReport>> {
    const url = `${this.baseUrl}${this.MY_REPORTS.BASE_URL}${this.MY_REPORTS.BULK}`;
    return this.http.delete<IBaseOneResponse<IAddMyReport>>(url, {
      body: {
        issuer,
        payload: recordPayload,
      },
    });
  }
}
