import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of, zip } from 'rxjs';
import { UsersService } from './users.service';
import * as UsersActions from './users.actions';
import * as logbookAppReducer from '../../logbook.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import * as AppActions from '../../app/actions';
import {
  IBaseCrudResponse,
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IRightAssignmentsAndRights,
  IUser,
  IUserShortInfo,
  IUserWithExcelDropdowns,
  IUserWithExcelDropdownsAndErrors,
} from './users.model';
import * as _ from 'lodash';
import { emptyAction, BULK_REQUEST_SPLIT_THRESHOLD } from '../../../../constants';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IRightAssignment } from '../roles/roles.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable()
export class UsersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: UsersService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.USER_SETTINGS_DATA_LOADING),
      switchMap((payload: UsersActions.UserSettingsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let userParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest<ITableQuery>({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        if (payload.tableQuery.sort.column === 'details') {
          userParams = userParams.set(
            'ordering',
            `${payload.tableQuery.sort.type}details,${payload.tableQuery.sort.type}id`,
          );
        }

        if (!_.isEmpty(payload.tableQuery.advancedFilter)) {
          userParams = this.service.advancedFilterService.getSearchString(payload.tableQuery, userParams);
        }

        return this.service.getUserSettingsData(userParams).pipe(
          switchMap((response) => {
            return of(new UsersActions.UserSettingsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadUserExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DOWNLOAD_USERS_EXCEL),
      switchMap((objectData: UsersActions.DownloadUsersExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadUserExcel(objectData.withData, objectData.filters, objectData.columns);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadUserErrorExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DOWNLOAD_USERS_ERROR_EXCEL),
      switchMap((objectData: UsersActions.DownloadUsersErrorExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadUserExcel(objectData.withData, null, objectData.columns, true, objectData.payload);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UPLOAD_USER_EXCEL),
      switchMap((objectData: UsersActions.UploadUsersExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const requests: Observable<IBulkResponseData>[] = [];

        if (objectData.payload.length > BULK_REQUEST_SPLIT_THRESHOLD) {
          this.splitExcelUploadRequest(requests, objectData.payload, objectData.issuerAndReason);
        } else {
          requests.push(this.service.uploadExcel(objectData.payload, objectData.issuerAndReason));
        }

        return zip(requests).pipe(
          switchMap((response: IBulkResponseData[]) => {
            const errorsWithKeysMapped: IUserWithExcelDropdownsAndErrors[] = [];
            const errors: IUserWithExcelDropdownsAndErrors[] = _.flatten(
              response.map((item: IBulkResponseData) => item?.errors as unknown as IUserWithExcelDropdownsAndErrors[]),
            );

            errors?.forEach((user: IUserWithExcelDropdownsAndErrors) =>
              errorsWithKeysMapped.push(
                _.mapKeys(user, (_v, k) => _.camelCase(k)) as unknown as IUserWithExcelDropdownsAndErrors,
              ),
            );

            this.errorMessageService.getTranslatedErrorMessage(errorsWithKeysMapped ?? []);

            const success: boolean = response.every((item: IBulkResponseData) => item.success);
            const mergedArray: IUserWithExcelDropdowns[] =
              this.excelHelperService.mergeBulkResponseWithRequestData<IUserWithExcelDropdownsAndErrors>(
                {
                  success,
                  data: errorsWithKeysMapped as IBaseCrudResponse[],
                },
                errorsWithKeysMapped,
              );

            return of(
              new UsersActions.UploadUsersExcelCompleted(mergedArray, success, objectData.issuerAndReason),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.ADD_USER),
      switchMap((objectData: UsersActions.AddUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addUser(objectData.payload, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IUser>) => {
            return of(new UsersActions.AddUserCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.EDIT_USER),
      switchMap((objectData: UsersActions.EditUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editUser(objectData.payload, objectData.issuer, objectData.forceCheckOut).pipe(
          switchMap((response: IBaseOneResponse<IUser>) => {
            if (objectData.isSelf) {
              return of(
                new UsersActions.EditUserCompleted(response),
                new AppActions.GetCurrentUser('application-settings'),
              );
            }

            return of(new UsersActions.EditUserCompleted(response));
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  pinChange = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.PIN_CHANGE),
      switchMap((objectData: UsersActions.PinChange) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.changeUserPin(objectData.payload, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IUser>) => {
            if (objectData.isSelf) {
              return of(
                new UsersActions.PinChangeCompleted(response),
                new AppActions.GetCurrentUser('application-settings'),
              );
            }
            return of(new UsersActions.PinChangeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getRightAssignments = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_RIGHT_ASSIGNMENTS),
      switchMap((objectData: UsersActions.GetRightAssignments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('limit', 1000);
        const userRightAssignmentParams: HttpParams = httpParams
          .append('object_id', Number(objectData.userId))
          .append('object_type', 'user')
          .append('ordering', 'id');
        const roleRightAssignmentParams: HttpParams = httpParams
          .append('object_id', Number(objectData.roleId))
          .append('object_type', 'role')
          .append('ordering', 'id');
        const rightParams: HttpParams = httpParams.append('ordering', 'orders');

        return this.service
          .getPermissions(
            userRightAssignmentParams,
            roleRightAssignmentParams,
            rightParams,
            Boolean(objectData.userId),
            Boolean(objectData.roleId),
          )
          .pipe(
            switchMap((response) => {
              return of(
                new UsersActions.GetRightAssignmentsCompleted(
                  response,
                  Boolean(objectData.userId),
                  Boolean(objectData.roleId),
                ),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.EDIT_USERS),
      switchMap((objectData: UsersActions.EditUsers) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editUsers(objectData.payload, objectData.issuerAndReason).pipe(
          switchMap((response: IGetManyResponse<IBulkResponseRecord<IUser>>) => {
            return of(new UsersActions.EditUsersCompleted(response));
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getRoleRightAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_ROLE_RIGHT_ASSIGNMENTS),
      switchMap((objectData: UsersActions.GetRoleRightAssignments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: HttpParams = new HttpParams().append('limit', 5000);
        const rightAssignmentParams: HttpParams = params
          .append('ordering', 'id')
          .append('object_id', objectData.roleId)
          .append('object_type', 'role');
        const rightParams: HttpParams = params.append('ordering', 'orders');

        return this.service.getRoleRightsAssignmentsWithRights(rightAssignmentParams, rightParams).pipe(
          switchMap((response: IRightAssignmentsAndRights) => {
            return of(
              new UsersActions.GetRoleRightAssignmentsCompleted(
                response,
                objectData.openPermissionExceptionTable,
                objectData.checkPermissionExceptionsToKeep,
              ),
              !objectData.checkPermissionExceptionsToKeep ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getUserRightAssignments = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_USER_RIGHT_ASSIGNMENTS),
      switchMap((objectData: UsersActions.GetUserRightAssignments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: HttpParams = new HttpParams()
          .append('limit', 5000)
          .append('object_id__in', objectData.userIds.join(','))
          .append('object_type', 'user')
          .append('ordering', 'id');

        return this.service.getUserRightsAssignments(params).pipe(
          switchMap((response: IGetManyResponse<IRightAssignment>) => {
            return of(new UsersActions.GetUserRightAssignmentsCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  setUserRightAssignments = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SET_USER_RIGHT_ASSIGNMENTS),
      switchMap((objectData: UsersActions.SetUserRightAssignments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.setUserRightsAssignments(objectData.userRightAssignments, objectData.issuerAndReason).pipe(
          switchMap((response: IGetManyResponse<IRightAssignment>) => {
            return of(new UsersActions.SetUserRightAssignmentsCompleted(response.data));
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getUserShortInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_USER_SHORT_INFORMATION),
      switchMap((payload: UsersActions.GetUserShortInformation) => {
        return this.service.getUserViaUsername(payload.username).pipe(
          switchMap((response: IGetOneResponse<IUserShortInfo>) => {
            return of(new UsersActions.GetUserShortInformationCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  private splitExcelUploadRequest(
    requests: Observable<IBulkResponseData>[],
    users: IUser[],
    issuerAndReason: IIssuerAndReason | null,
  ): void {
    const usersPerRequest: number = 65;
    const requestCount: number = Math.ceil(users.length / usersPerRequest);

    [...Array(requestCount)].forEach((value: undefined, index: number) => {
      requests.push(
        this.service.uploadExcel(users.slice(index * usersPerRequest, (index + 1) * usersPerRequest), issuerAndReason),
      );
    });
  }
}
