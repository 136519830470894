import { Action } from '@ngrx/store';
import { IPageHeaderState } from './page-header.model';
import { IPageHeader } from '../../shared/service/page-header/page-header.model';

export const UPDATE_PAGE_MODE = '[PAGE HEADER] Update page mode';
export const COUNTDOWN_TIMEOUT = '[PAGE HEADER] Countdown timeout';
export const UPDATE_FILTER_BAR_VISIBILITY = '[PAGE HEADER] Update filter bar visibility';
export const RUN_PRINT_FUNCTIONALITY = '[PAGE HEADER] Run print functionality';
export const CHANGE_PRINT_BUTTON_STATUS = '[PAGE HEADER] Change print button status';
export const SET_PAGE_HEADER_INFO = '[PAGE HEADER] SET PAGE HEADER INFO';
export const UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY = '[PAGE HEADER] Update page header buttons visibility';
export const RESET_STORE_TO_DEFAULT = '[PAGE HEADER] Reset Store To Default';

export class UpdatePageMode implements Action {
  readonly type = UPDATE_PAGE_MODE;

  constructor(public isDashboardMode: boolean) {}
}

export class CountdownTimeout implements Action {
  readonly type = COUNTDOWN_TIMEOUT;
}

export class UpdateFilterBarVisibility implements Action {
  readonly type = UPDATE_FILTER_BAR_VISIBILITY;

  constructor(public isVisible: boolean) {}
}

export class RunPrintFunctionality implements Action {
  readonly type = RUN_PRINT_FUNCTIONALITY;

  constructor(public isPrintAllowed: boolean) {}
}

export class ChangePrintButtonStatus implements Action {
  readonly type = CHANGE_PRINT_BUTTON_STATUS;

  constructor(public isPrintButtonDisabled: boolean) {}
}

export class SetPageHeaderInfo implements Action {
  readonly type = SET_PAGE_HEADER_INFO;

  constructor(public pageHeader: IPageHeader) {}
}

export class UpdatePageHeaderButtonsVisibility implements Action {
  readonly type = UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY;

  constructor(public isPageHeaderButtonsVisible: Partial<IPageHeaderState>) {}
}

export class ResetStoreToDefault implements Action {
  readonly type = RESET_STORE_TO_DEFAULT;
}

export type PageHeaderActions =
  | UpdatePageMode
  | CountdownTimeout
  | UpdateFilterBarVisibility
  | RunPrintFunctionality
  | ChangePrintButtonStatus
  | ResetStoreToDefault
  | SetPageHeaderInfo
  | UpdatePageHeaderButtonsVisibility;
