import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { Subject, Subscription } from 'rxjs';
import { ReasonComponent } from '../reason/reason.component';
import { IssuerComponent } from '../issuer/issuer.component';
import { IClientSettingsState } from '../../../store/settings/client/client.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { PinChangeComponent } from '../pin-change/pin-change.component';

@OnDestroyDecorator
@Component({
  selector: 'scw-before-action-preparer',
  templateUrl: './before-action-preparer.component.html',
  styleUrls: ['./before-action-preparer.component.scss'],
})
export class BeforeActionPreparerComponent implements OnInit {
  @ViewChild(ReasonComponent) reasonComponent!: ReasonComponent;
  @ViewChild(IssuerComponent) issuerComponent!: IssuerComponent;
  @ViewChild(PinChangeComponent) pinChangeComponent!: PinChangeComponent;
  @Input() reasonActionsToSubscribe: string[] = [];
  @Input() issuerActionsToSubscribe: string[] = [];
  @Input() actionSubject!: Subject<boolean>;
  @Input() functionToContinue!: Function;
  @Input() parentRef!: any;
  @Input() dispatchHideLoader: boolean = true;

  constructor(private readonly store: Store<logbookAppReducer.LogbookAppState>) {}
  public reason: string | null = null;
  public issuer: any | null = null;
  public askForPinOnAction$: boolean = true;
  public isPinTemporary: boolean = false;
  private readonly subscriptions: Subscription[] = [];

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.isPinTemporary = state.isPinTemporary ?? false;
      }),
      this.store.select('clientStore').subscribe((state: IClientSettingsState) => {
        this.askForPinOnAction$ = Boolean(state.clientCommonInformation?.askForPinOnAction);
      }),
      this.actionSubject.subscribe((askForReason: boolean) => {
        if (this.isPinTemporary) {
          this.pinChangeComponent.showModal('temporary');
        } else if (askForReason && !this.isPinTemporary) {
          this.reasonComponent.showModal();
        } else if (this.askForPinOnAction$ && !this.isPinTemporary) {
          this.issuerComponent.showModal();
        } else {
          this.functionCaller();
        }
      }),
    );
  }

  public getReason(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    if (this.askForPinOnAction$ && this.reason) {
      this.issuerComponent.showModal();
    } else if (!this.askForPinOnAction$) {
      this.functionCaller();
    }
  }

  public getIssuer(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    if ((!this.askForPinOnAction$ && !this.issuer) || (this.askForPinOnAction$ && this.issuer)) {
      this.functionCaller();
    }
  }

  public functionCaller(): void {
    const payload = {
      issuer: this.issuer,
      reason: this.reason,
    };

    this.functionToContinue.call(this.parentRef, payload);
  }

  public closeAllModals(): void {
    this.pinChangeComponent.closeModal();
    this.reasonComponent.closeModal();
    this.issuerComponent.closeModal();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
