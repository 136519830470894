import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  private url: string;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router) {
    this.url = `${environment.ssoUrl}${environment.ssoLogoutUrl}`;
  }

  async ngOnInit() {
    this.http
      .get<any>(this.url)
      .pipe()
      .subscribe((_resData: null) => {
        window.location.href = environment.ssoProviderLogoutUrl + location.origin;
      });
  }
}
