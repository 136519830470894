import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as ObjectActions from '../../reports/logs-reports/logs-reports.actions';
import * as AppActions from '../../app/actions';
import { catchError, of, switchMap } from 'rxjs';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { LogsReportsService } from './logs-reports.service';
import { IFormSubmission } from '../../home/home.model';
import * as _ from 'lodash';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class LogsReportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: LogsReportsService,
  ) {}

  getLogsReportData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGS_REPORTS_DATA),
      switchMap((payload: ObjectActions.GetLogsReportsData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = this.prepareLogsParams(payload.tableQuery);

        return this.service.getLogs(body).pipe(
          switchMap((response: IGetManyResponse<IFormSubmission>) => {
            return of(new ObjectActions.GetLogsReportsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EXCEL),
      switchMap((payload: ObjectActions.DownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number> = {
          ...this.prepareLogsParams(payload.filters),
          page: 1,
          limit: 5000,
        };

        this.service.downloadLogsExcel(body, payload.selectedHeaders);

        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  private prepareLogsParams(tableQuery: ITableQuery): Record<any, string | number> {
    let body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(
      _.omit(tableQuery, 'advancedFilter'),
      true,
    );

    if (!_.isEmpty(tableQuery.advancedFilter)) {
      const params: HttpParams = this.service.advancedFilterService.getSearchString(tableQuery);

      params.keys().forEach((key: string): void => {
        _.set(body, key, params.get(key));
      });
    }

    return body;
  }
}
