import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MENU_INFORMATION } from '../../../constants';
import * as _ from 'lodash';
import { ERightOperators, IMainState, IUserRights } from '../../store/main/main.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import * as MainActions from '../../store/main/main.actions';
import { take } from 'rxjs';
import { LogbookAppState } from '../../store/logbook.reducer';
import { IMenuNode } from './nav-items.model';
import { TranslateService } from '@ngx-translate/core';
import {
  collapseOpenDropdown,
  collapseOtherNavBarDropdowns,
  goLink,
  toggleNavBarDropdown,
} from 'src/app/shared/helper/navbar-helper';
import { LayoutConstants } from '../layout-constants';
import { LayoutAnimations } from '../animations';
import { IMenu } from '../../../constants.model';
import { Router } from '@angular/router';

@Component({
  selector: 'scw-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: [
    './nav-items.component.scss',
    '../main/main.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
    '../../../scss/navigation-bar.scss',
  ],
  animations: [
    LayoutAnimations.notificationBottom,
    LayoutAnimations.mobileHeaderNavRight,
    LayoutAnimations.fadeInOutTranslate,
    LayoutAnimations.mobileMenuTop,
  ],
})
export class NavItemsComponent extends LayoutConstants implements OnInit {
  @Input() navBarDropdowns!: any;
  @Output() navBarDropdownsChange = new EventEmitter<any>();
  @Input() profileDropdown!: any;
  @Output() profileDropdownChange = new EventEmitter<any>();
  @Input() profileDropdownClass!: any;
  @Output() profileDropdownClassChange = new EventEmitter<any>();

  public navItems: IMenuNode[] = [];
  public $event!: Event;
  goLink = goLink;
  toggleNavBarDropdown = toggleNavBarDropdown;
  collapseOpenDropdown = collapseOpenDropdown;
  collapseOtherNavBarDropdowns = collapseOtherNavBarDropdowns;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly store: Store<LogbookAppState>,
    private readonly storeActions: ActionsSubject,
    public readonly translate: TranslateService,
    public router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.store
      .select('mainStore')
      .pipe(take(1))
      .subscribe((state: IMainState) => {
        this.buildMenu(_.cloneDeep(state.userRights));
      });

    this.storeActions
      .pipe(ofType(MainActions.SET_AUTHORIZED_PAGES))
      .subscribe((response: MainActions.SetAuthorizedPages) => {
        this.buildMenu(_.cloneDeep(response.data));
      });
  }

  private buildMenu(userRights: IUserRights) {
    const menuItems: IMenuNode[] = _.sortBy(
      _.map(
        _.cloneDeep(MENU_INFORMATION.menus).filter(
          (node: IMenu) =>
            !node.external &&
            (!node.requiredRights.rights.length ||
              (node.requiredRights.operator === ERightOperators.OR
                ? _.some(node.requiredRights.rights, (requiredRight) => {
                    return _.keys(_.pickBy(userRights)).includes(requiredRight);
                  })
                : _.every(node.requiredRights.rights, (requiredRight) => {
                    return _.keys(_.pickBy(userRights)).includes(requiredRight);
                  }))),
        ),
        (menuItem) => ({
          ...menuItem,
          nameKey: `menus.${menuItem.name}`,
        }),
      ),
      'orders',
    ) as IMenuNode[];
    const roots: IMenuNode[] = _.filter(menuItems, { parentId: 0 });
    roots.forEach((root: IMenuNode) => {
      root.children = _.filter(menuItems, { parentId: root.id });
    });
    this.navItems = roots;
    this.navBarDropdowns = _.filter(roots, (root) => root?.children.length).reduce(
      (obj, currentRoot) => Object.assign(obj, { [(currentRoot as IMenuNode).name]: { state: 'an-off', klass: '' } }),
      {},
    );
    this.navBarDropdownsChange.emit(this.navBarDropdowns);
  }
}
