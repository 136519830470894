<ng-template #reason_modal let-modal>
  <scw-mat-form (scwFormSubmit)="submit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'modal.header.actionReason' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <ng-template [ngIf]="reasons$.length">
            <scw-mat-select
              label="{{ 'modal.reason.reason.label' | translate }}*"
              class="scw_mat-add_edit_modal-item-input"
              [rules]="[{ required: true }]"
              [data]="reasons$"
              [(inputModel)]="reasonInputModel"
              [tagToBody]="true"
              [enableSearchFilter]="true"
              (inputModelChange)="onReasonChange($event)"
            >
            </scw-mat-select>
          </ng-template>
        </div>
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            *ngIf="showInput"
            class="scw_mat-add_edit_modal-item-input"
            [label]="inputLabelTranslation"
            [rules]="reasonRule"
            [(inputModel)]="reason"
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="closeModal()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          {{ 'general.save' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
