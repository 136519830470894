import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import {
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { forkJoin, map, Observable, Subject, takeUntil } from 'rxjs';
import {
  IFormSubmissionRequestBody,
  ILogbook,
  ILogbookExcel,
  ILogbookExcelContent,
  ILogbookExcelData,
  ILogbookExcelWithError,
  ILogbookSettings,
  ILogbookStates,
  IStateStates,
} from './logbooks.model';
import { FormsService } from '../forms/forms.service';
import * as _ from 'lodash';
import { WorkflowsService } from '../settings/workflows/workflows.service';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IFormSubmission, IHomeFormDetail, IHomeFormSubmission, ILogbookHomeDetail } from '../home/home.model';
import { IForm, IFormVersion } from '../forms/forms.model';
import { IFormSubmissionUserAction, IUserAction } from '../../../constants.model';
import {
  ILogbookCreate,
  ILogbookForm,
  ILogbookHistory,
  ILogbookVersionWithWorkflowDetail,
  IOrderByPayload,
  IPreviousLogbookVersion,
} from '../../view/settings/logbook/logbook.model';
import { ILogbookVersion } from '../logbook-versions/logbook-versions.model';
import { ICloneLogbookPayload } from '../../view/settings/logbook-versions/logbook-versions.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import * as moment from 'moment-timezone';
import {
  ILogbookMasterDataDetail,
  ILogbookMasterDataFields,
} from '../settings/logbook-master-data/logbook-master-data.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { ISelect } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { TranslateService } from '@ngx-translate/core';
import {
  CellTypes,
  ExcelHelperService,
  ICreateExcel,
  IExcelColumnDefinition,
  IExcelColumnKeys,
} from '../../shared/service/excel/excel-helper.service';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { HelperService } from '../../shared/service/helper.service';
import * as LogbookActions from '../logbooks/logbooks.actions';
import * as AppActions from '../app/actions';
import { ComponentUtilities } from '../../shared/helper/component-utilities';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../shared/model/enum/excel-date-format';
import { EApprovalStatuses } from '../../shared/model/enum/constants';
import { EFieldType, ICompareField } from '../../shared/standalone/compare-changes/compare-changes.model';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

@Injectable({
  providedIn: 'root',
})
export class LogbooksService {
  private readonly URLS = {
    LOGBOOKS_URL: `${this.baseUrl}/logbooks/`,
    LOGBOOKS_BULK_URL: `${this.baseUrl}/logbooks/bulk/`,
    LOGBOOKS_CHILDREN_URL: `${this.baseUrl}/logbooks/children/`,
    SEND_TO_ARCHIVE_URL: `${this.baseUrl}/logbooks/to_be_archived`,
    ARCHIVE_URL: `${this.baseUrl}/logbooks/archive`,
    MASTER_DATA_URL: `${this.baseUrl}/master-data/`,
    WORKFLOW_STEPS_URL: `${this.baseUrl}/workflow-items/`,
    LOGBOOK_VERSION_URL: `${this.baseUrl}/logbook-versions/`,
    LOGBOOK_FORM_URL: `${this.baseUrl}/forms/`,
    LOGBOOK_VERSION_HISTORY_URL: `${this.baseUrl}/user-actions/logbook-versions`,
    LOGBOOK_HISTORY_URL: `${this.baseUrl}/user-actions/logbooks/`,
    FIELD_VERSION_ITEMS: `${this.baseUrl}/field-version-items/`,
    LOGBOOK_VERSION_ORDER_BY_URL: `${this.baseUrl}/logbooks/change-order-by`,
    LOGBOOK_HOME_DETAIL_URL: `${this.baseUrl}/logbooks/home/`,
    FORM_SUBMISSIONS_URL: `${this.baseUrl}/form-submissions/`,
    USER_ACTIONS_URLS: {
      FORM_SUBMISSIONS: `${this.baseUrl}/user-actions/form-submissions/`,
      LOGBOOKS: `${this.baseUrl}/user-actions/logbooks-version-history/`,
      FORMS: `${this.baseUrl}/user-actions/forms-version-history/`,
    },
    CHANGE_LOGBOOK_PARENT_URL: `${this.baseUrl}/logbook-versions/change-parent/`,
    FORMS_URL: `${this.baseUrl}/forms/`,
    BULK: {
      SUBMIT: `${this.baseUrl}/logbook-versions/bulk/send-to-review`,
      ACTIVATE: `${this.baseUrl}/logbook-versions/bulk/activate`,
    },
    SELECTED_STATES_URL: `${this.baseUrl}/logbooks/get-states/`,
    SELECT_STATES_URL: `${this.baseUrl}/logbooks/set-states/`,
    SET_CURRENT_STATES_URL: `${this.baseUrl}/logbooks/set-current-states/`,
    AVAILABLE_STATES_URL: `${this.baseUrl}/states/list/`,
  };
  private timezone: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private dateTimeFormat!: string;
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: true,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: true,
    },
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly formsService: FormsService,
    private readonly workFlowService: WorkflowsService,
    private readonly translate: TranslateService,
    private excelHelper: ExcelHelperService,
    private helperService: HelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
            this.dateTimeFormat = `${this.dateFormat$} ${this.timeFormat$}`;
          }

          this.timezone = state.timezone ?? 'utc';

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getLogbooks(params: HttpParams, issuer?: IIssuer): Observable<IGetManyResponse<ILogbook>> {
    if (issuer) {
      return this.http.get<IGetManyResponse<ILogbook>>(this.URLS.LOGBOOKS_URL, {
        params,
        headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer),
      });
    }

    return this.http.get<IGetManyResponse<ILogbook>>(this.URLS.LOGBOOKS_URL, { params });
  }

  public getLogbookChildren(params: HttpParams, parentId: number = 0): Observable<IGetManyResponse<ILogbook>> {
    return this.http.get<IGetManyResponse<ILogbook>>(`${this.URLS.LOGBOOKS_CHILDREN_URL}${parentId}`, { params });
  }

  public getLogbookHomeDetail(logbookId: number): Observable<IResponse<ILogbookHomeDetail>> {
    return this.http.get<IResponse<ILogbookHomeDetail>>(`${this.URLS.LOGBOOK_HOME_DETAIL_URL}${logbookId}/`);
  }

  public getOngoingLogbooks(params?: HttpParams): Observable<IGetManyResponse<ILogbook>> {
    return this.http.get<IGetManyResponse<ILogbook>>(`${this.URLS.LOGBOOKS_URL}ongoing/`, { params });
  }

  public createFormSubmission(
    logbookId: number,
    formId: number,
    formContent: any,
    criticalityStatus: boolean,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IFormSubmission>> {
    return this.http.post<IBaseOneResponse<IFormSubmission>>(
      `${this.URLS.FORM_SUBMISSIONS_URL}create/${logbookId}/${formId}`,
      {
        issuer: issuerAndReason.issuer,
        payload: {
          criticalityStatus: Number(criticalityStatus),
          meta: JSON.stringify(formContent),
          reason: issuerAndReason.reason,
        },
      },
    );
  }

  public saveFormSubmission(
    logbookId: number,
    formSubmissionId: number,
    formContent: any,
    criticalityStatus: number,
    issuerAndReason: IIssuerAndReason,
    isCheckOut: boolean,
    isBulkCheckOut: boolean,
  ): Observable<IBaseOneResponse<IFormSubmission>> {
    return this.http.put<IBaseOneResponse<IFormSubmission>>(
      `${this.URLS.FORM_SUBMISSIONS_URL}${logbookId}/${formSubmissionId}`,
      {
        issuer: issuerAndReason.issuer,
        payload: {
          criticalityStatus,
          meta: JSON.stringify(formContent),
          reason: issuerAndReason.reason ?? '',
          checkOut: isCheckOut,
          bulkCheckOut: isBulkCheckOut,
        },
      },
    );
  }

  public submitFormSubmission(
    formSubmissionId: number,
    formContent: any,
    criticalityStatus: number,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IFormSubmission>> {
    return this.http.post<IBaseOneResponse<IFormSubmission>>(
      `${this.URLS.FORM_SUBMISSIONS_URL}send-to-review/${formSubmissionId}`,
      {
        issuer: issuerAndReason.issuer,
        payload: {
          criticalityStatus,
          meta: JSON.stringify(formContent),
          reason: issuerAndReason.reason ?? '',
        },
      },
    );
  }

  public getFormSubmissions(params: HttpParams, logbookId: number): Observable<IGetManyResponse<IFormSubmission>> {
    return this.http.get<IGetManyResponse<IFormSubmission>>(`${this.URLS.FORM_SUBMISSIONS_URL}${logbookId}`, {
      params,
    });
  }

  public cancelFormSubmission(
    logbookId: number,
    formSubmissionId: number,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IResponse<IFormSubmission>> {
    return this.http.delete<IResponse<IFormSubmission>>(
      `${this.URLS.FORM_SUBMISSIONS_URL}${logbookId}/${formSubmissionId}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
          },
        },
      },
    );
  }

  public getFormSubmissionHistory(
    params: HttpParams,
    formSubmissionId: number,
  ): Observable<IGetManyResponse<IFormSubmissionUserAction>> {
    return this.http.get<IGetManyResponse<IFormSubmissionUserAction>>(
      `${this.URLS.USER_ACTIONS_URLS.FORM_SUBMISSIONS}${formSubmissionId}`,
      {
        params,
      },
    );
  }

  public getFormSubmission(
    logbookId: number,
    formSubmissionId: number,
    body?: IFormSubmissionRequestBody,
  ): Observable<IResponse<IHomeFormSubmission>> {
    if (!_.isNil(body)) {
      return this.http.post<IResponse<IHomeFormSubmission>>(
        `${this.URLS.FORM_SUBMISSIONS_URL}${logbookId}/${formSubmissionId}`,
        body,
      );
    }

    return this.http.get<IResponse<IHomeFormSubmission>>(
      `${this.URLS.FORM_SUBMISSIONS_URL}${logbookId}/${formSubmissionId}`,
    );
  }

  public getFormSubmissionTableData(params: HttpParams, logbookId: number): Observable<ILogbookHomeDetail> {
    const observables: any = [this.getFormSubmissions(params, logbookId), this.getLogbookHomeDetail(logbookId)];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          formSubmissionTableData: _.get(responseList, '0.data', []) as IFormSubmission[],
          formSubmissionTableDataCount: _.get(responseList, '0.total', []) as number,
          logbook: _.get(responseList, '1.data.logbook', []) as ILogbook,
          scopedForms: _.get(responseList, '1.data.scopedForms', []) as IFormVersion[],
          activeLogDetail: _.get(responseList, '1.data.activeLogDetail', []) as IHomeFormDetail[],
          isLogbookFreeToMove: _.get(responseList, '1.data.isLogbookFreeToMove', []) as boolean,
        };
      }),
    );
  }

  public getLogbooksVersionHistoryData(logbookId: number): Observable<IGetManyResponse<IUserAction>> {
    return this.http.get<IGetManyResponse<IUserAction>>(`${this.URLS.USER_ACTIONS_URLS.LOGBOOKS}${logbookId}`);
  }

  public getFormHistoryData(formId: number): Observable<IGetManyResponse<IUserAction>> {
    return this.http.get<IGetManyResponse<IUserAction>>(`${this.URLS.USER_ACTIONS_URLS.FORMS}${formId}`);
  }

  public getLogbook(params: HttpParams): Observable<IGetManyResponse<ILogbook>> {
    return this.http.get<IGetManyResponse<ILogbook>>(`${this.URLS.LOGBOOKS_URL}`, { params });
  }

  public getChildrenLogbooks(parentId: number, params: HttpParams): Observable<IGetManyResponse<ILogbook>> {
    return this.http.get<IGetManyResponse<ILogbook>>(`${this.URLS.LOGBOOKS_CHILDREN_URL}${parentId}`, { params });
  }

  public getLogBookData(
    logbookId: number | null,
    logbookVersionId: number | null,
    previousVersionId: number | null,
    issuer?: IIssuer,
    isIncludeDisabled?: boolean,
    isOnlyActiveForms: boolean = true,
  ): Observable<ILogbookSettings> {
    let params: HttpParams = new HttpParams().append('limit', 5000);
    const logbookParams: HttpParams = params.append('is_active_only', 1);
    let formParams: HttpParams = params;

    if (isOnlyActiveForms) {
      formParams = params.append('is_active_only', 1);
    }

    params = params.append('just_workflow_data', true);

    if (isIncludeDisabled) {
      params = params.append('include_disabled', true);
    }

    const observables: any = [
      this.workFlowService.getWorkflows(params, issuer),
      this.formsService.getForms(formParams, issuer),
      this.getLogbooks(logbookParams, issuer),
      ...(logbookId && logbookVersionId ? [this.getLogbookVersion(logbookId, logbookVersionId, issuer)] : []),
      ...(logbookId && previousVersionId ? [this.getLogbookVersion(logbookId, previousVersionId, issuer)] : []),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', []),
          forms: _.get(responseList, '1.data', []),
          logbooks: _.get(responseList, '2.data', []),
          logbookVersionData: _.get(responseList, '3.data', null),
          previousVersionData: _.get(responseList, '4.data', null),
        };
      }),
    );
  }

  public getLogbookVersions(
    logbookId: number | null,
    params: HttpParams,
  ): Observable<IGetManyResponse<ILogbookVersion>> {
    return this.http.get<IGetManyResponse<ILogbookVersion>>(`${this.URLS.LOGBOOK_VERSION_URL}${logbookId}`, {
      params,
    });
  }

  public getLogbookForms(ids: number[]): Observable<IGetManyResponse<any>> {
    const params = new HttpParams().append('form_ids', JSON.stringify(ids));
    return this.http.get<IGetManyResponse<any>>(`${this.URLS.LOGBOOK_FORM_URL}`, { params });
  }

  public addLogbook(logbook: ILogbook, issuer: IIssuer | null): Observable<IBaseOneResponse<ILogbook>> {
    return this.http.post<IBaseOneResponse<ILogbook>>(this.URLS.LOGBOOKS_URL, {
      issuer,
      payload: logbook,
    });
  }

  public addLogbookVersion(
    logbookId: number | null,
    logbookVersion: ILogbookVersion,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<ILogbookVersion>> {
    return this.http.post<IBaseOneResponse<ILogbookVersion>>(`${this.URLS.LOGBOOK_VERSION_URL}${logbookId}`, {
      issuer,
      payload: logbookVersion,
    });
  }

  public editLogbook(
    logbook: ILogbook,
    logbookId: number | null | undefined,
    logbookVersionId: number | null,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<any>> {
    return this.http.put<IGetManyResponse<ILogbook>>(
      `${this.URLS.LOGBOOK_VERSION_URL}${logbookId}/${logbookVersionId}`,
      {
        issuer,
        payload: logbook,
      },
    );
  }

  public getLogbookHistory(
    params: HttpParams,
    logbookID?: number,
    getTemplateHistory: boolean = false,
  ): Observable<IGetManyResponse<ILogbookHistory>> {
    if (getTemplateHistory) {
      return this.http.get<IGetManyResponse<ILogbookHistory>>(`${this.URLS.LOGBOOK_HISTORY_URL}${logbookID}`);
    }

    return this.http.get<IGetManyResponse<ILogbookHistory>>(`${this.URLS.LOGBOOK_VERSION_HISTORY_URL}`, {
      params,
    });
  }

  public getLogbookVersion(
    logbookId: number,
    logbookVersionId: number,
    issuer?: IIssuer,
  ): Observable<IBaseOneResponse<ILogbookVersion>> {
    if (issuer) {
      return this.http.get<IBaseOneResponse<ILogbookVersion>>(
        `${this.URLS.LOGBOOK_VERSION_URL}${logbookId}/${logbookVersionId}`,
        issuer && {
          headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer),
        },
      );
    }
    return this.http.get<IBaseOneResponse<ILogbookVersion>>(
      `${this.URLS.LOGBOOK_VERSION_URL}${logbookId}/${logbookVersionId}`,
    );
  }

  public deleteLogbookVersion(
    logbookId: number | null,
    logbookVersionId: number | null,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<ILogbookVersion>> {
    return this.http.delete<IBaseOneResponse<ILogbookVersion>>(
      `${this.URLS.LOGBOOK_VERSION_URL}${logbookId}/${logbookVersionId}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: { reason: issuerAndReason.reason },
        },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      },
    );
  }

  public cloneLogbook(
    logbook: ICloneLogbookPayload,
    logbookId: number | undefined,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<ILogbook>> {
    return this.http.post<IGetManyResponse<ILogbook>>(`${this.URLS.LOGBOOK_VERSION_URL}${logbookId}`, {
      issuer,
      payload: logbook,
    });
  }

  public sendLogbookToArchive(id: number | null, issuerAndReason: IIssuerAndReason): Observable<IGetManyResponse<any>> {
    return this.http.put<IGetManyResponse<any>>(`${this.URLS.SEND_TO_ARCHIVE_URL}/${id}`, {
      issuer: issuerAndReason.issuer,
      payload: { reason: issuerAndReason.reason },
    });
  }

  public archiveLogbook(id: number | null, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<ILogbook>> {
    return this.http.put<IBaseOneResponse<ILogbook>>(`${this.URLS.ARCHIVE_URL}/${id}`, {
      issuer: issuerAndReason.issuer,
      payload: { reason: issuerAndReason.reason },
    });
  }

  public submitLogbook(
    logbookVersionId: number,
    logbookId: number,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<any>> {
    return this.http.post<IGetManyResponse<any>>(
      `${this.URLS.LOGBOOK_VERSION_URL}send-to-review/${logbookId}/${logbookVersionId}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public activateLogbook(
    logbookVersionId: number,
    logbookId: number,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<any>> {
    return this.http.post<IGetManyResponse<any>>(
      `${this.URLS.LOGBOOK_VERSION_URL}activate/${logbookId}/${logbookVersionId}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public editOrderBy(versionIds: IOrderByPayload[]): Observable<IBaseOneResponse<ILogbookVersion>> {
    return this.http.put<IBaseOneResponse<ILogbookVersion>>(`${this.URLS.LOGBOOK_VERSION_ORDER_BY_URL}/`, {
      payload: versionIds,
    });
  }

  public populateAddEditModalData(
    logbookId: number | null,
    logbookVersionId: number | null,
    issuer: IIssuer | undefined,
  ): Observable<any> {
    const params: HttpParams = new HttpParams().append('limit', 5000);

    const observables: any = [
      this.workFlowService.getWorkflows(params, issuer),
      this.formsService.getForms(params, issuer),
      this.getLogbooks(params, issuer),
      ...(logbookId && logbookVersionId ? [this.getLogbookVersion(logbookId, logbookVersionId, issuer)] : []),
    ];
    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          workflows: _.get(responseList, '0.data', []),
          forms: _.get(responseList, '1.data', []),
          logbooks: _.get(responseList, '2.data', []),
          logbookVersionData: _.get(responseList, '3.data', null),
        };
      }),
    );
  }

  public changeLogbookParent(
    logbookId: number,
    logbookVersionId: number,
    parentLogbookId: number | null,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<ILogbookVersion>> {
    return this.http.put<IBaseOneResponse<ILogbookVersion>>(
      `${this.URLS.CHANGE_LOGBOOK_PARENT_URL}${logbookId}/${logbookVersionId}`,
      {
        issuer: issuerAndReason.issuer,
        payload: {
          reason: issuerAndReason.reason,
          ...(parentLogbookId && { parentLogbookId }),
        },
      },
    );
  }

  public getAllForms(): Observable<IGetManyResponse<IForm>> {
    const httpParams: HttpParams = new HttpParams().set('page', 1).append('limit', 5000);
    return this.http.get<IGetManyResponse<IForm>>(`${this.URLS.FORMS_URL}`, { params: httpParams });
  }

  public async downloadExcel(
    withData: boolean,
    activeMasterDataData: ILogbookMasterDataDetail,
    workflows: IWorkflow[],
    withErrorColumn: boolean = false,
    data?: ILogbookExcelWithError[],
    issuerAndReason?: IIssuerAndReason | null,
  ): Promise<void> {
    const params: HttpParams = new HttpParams().append('limit', 5000);
    const logbookParams: HttpParams = params.append('statuses', JSON.stringify([EApprovalStatuses.ACTIVE]));
    const formParams: HttpParams = params.append('is_active_only', 1);
    const observables: Observable<IGetManyResponse<ILogbook> | IGetManyResponse<IForm>>[] = [
      this.getLogbooks(logbookParams),
      this.formsService.getForms(formParams),
    ];
    forkJoin(observables).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.logbookSettings');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      const logbooks: ILogbook[] = _.get(responseList, '0.data', []);
      const forms: IForm[] = _.get(responseList, '1.data', []);
      let excelData: ILogbookExcelWithError[] = [];

      if (withData && data) {
        excelData = _.cloneDeep(data);

        excelData.forEach((logbook: ILogbookExcelWithError) => {
          this.formatLogbookData(logbook, {
            activeMasterDataData,
            workflows,
            logbooks,
            forms,
            yesNo: this.helperService.getYesNoDropdownExcel(),
            yesDropdown: this.helperService.getYesDropdownExcel(),
          });
        });
      }

      const excelOptions: ICreateExcel = this.getExcelColumns(
        {
          activeMasterDataData,
          workflows,
          logbooks,
          forms,
          yesNo: this.helperService.getYesNoDropdownExcel(),
          yesDropdown: this.helperService.getYesDropdownExcel(),
          timeTypeDropdown: this.helperService.getTimeTypeExcel(),
        },
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      this.excelHelper
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          withData,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
          true,
          true,
          1001,
          true,
          activeMasterDataData.id,
        )
        .then(
          () => {
            if (withData) {
              this.store.dispatch(new LogbookActions.DownloadErrorExcelCompleted(issuerAndReason));
            } else {
              this.store.dispatch(new LogbookActions.DownloadExcelCompleted());
            }

            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new LogbookActions.FetchError({}));
          },
        );
    });
  }

  private getExcelColumns(content: ILogbookExcelContent, withErrorColumn: boolean): ICreateExcel {
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$);
    const excelColumns: ICreateExcel = {
      columns: [
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.name'),
          key: 'name',
          width: 20,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          isRequired: false,
          header: this.translate.instant('excel.column.legacyId'),
          key: 'legacyId',
          width: 20,
        },
        {
          header: this.translate.instant('excel.column.isMainLogbook'),
          key: 'isMainLogbook',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.yesNo,
            prop: 'isMainLogbookDropdown.id',
            dataProperty: 'isMainLogbookDropdown.name',
            dataId: 'isMainLogbookDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.parentLogbook'),
          key: 'parentLogbook',
          width: 20,
          type: ValueType.String,
          isRequired: false,
          dropdownOptions: {
            data:
              content?.logbooks?.map((item) => {
                return {
                  ...item,
                  name: `${item.logbookId} - ${item.name}`,
                };
              }) ?? [],
            prop: 'parentLogbookDropdown.id',
            dataProperty: 'parentLogbookDropdown.name',
            dataId: 'parentLogbookDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.isMobileLogbook'),
          key: 'isMobileLogbook',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.yesNo,
            prop: 'isMobileLogbookDropdown.id',
            dataProperty: 'isMobileLogbookDropdown.name',
            dataId: 'isMobileLogbookDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.workflow'),
          key: 'workflow',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.workflows,
            prop: 'approvalWorkflowDropdown.id',
            dataProperty: 'approvalWorkflowDropdown.name',
            dataId: 'approvalWorkflowDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          isRequired: false,
          header: this.translate.instant('excel.column.selectedForms'),
          key: 'selectedForms',
          width: 20,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.logbook.selectedForms.prompt'),
            promptTitle: this.translate.instant('excel.logbook.selectedForms.promptTitle'),
          },
        },
        {
          header: this.translate.instant('excel.column.issuedDate'),
          key: 'issuedDate',
          width: 20,
          type: ValueType.Date,
          style: { numFmt: '@' },
          isRequired: false,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [dateFormula],
            errorStyle: 'Error',
            error: this.translate.instant('excel.logbook.issuedDate.prompt', {
              property: this.translate.instant('excel.column.issuedDate'),
              date: moment().format(this.dateFormat$),
            }),
            showInputMessage: true,
            prompt: this.translate.instant('excel.logbook.issuedDate.prompt', {
              property: this.translate.instant('excel.column.issuedDate'),
              date: moment().format(this.dateFormat$),
            }),
            promptTitle: this.translate.instant('excel.logbook.issuedDate.promptTitle'),
          },
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          isRequired: false,
          header: this.translate.instant('excel.column.order'),
          key: 'order',
          width: 20,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          isRequired: false,
          header: this.translate.instant('excel.column.stateDuration'),
          key: 'stateDuration',
          width: 20,
        },
        {
          header: this.translate.instant('excel.column.stateDurationType'),
          key: 'stateDurationType',
          width: 20,
          type: ValueType.String,
          isRequired: false,
          dropdownOptions: {
            data: content.timeTypeDropdown,
            prop: 'stateDurationTypeDropdown.id',
            dataProperty: 'stateDurationTypeDropdown.name',
            dataId: 'stateDurationTypeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
      ],
    };
    content?.activeMasterDataData?.fields?.forEach((item: ILogbookMasterDataFields) => {
      switch (item.meta['type']) {
        case 'Checkbox':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dropdownOptions: {
              data: content.yesDropdown,
              prop: `${item.name}Dropdown.id`,
              dataProperty: `${item.name}Dropdown.name`,
              dataId: `${item.name}Dropdown.id`,
            },
            dataValidation: {
              type: CellTypes.LIST,
            },
          });
          break;
        case 'CheckboxMulti':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'Datetime':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.Date,
            style: { numFmt: '@' },
            isRequired: item.meta['mandatory'],
            isDateTimeFormat: true,
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [null],
              errorStyle: 'Error',
              showInputMessage: true,
              prompt: this.translate.instant('excel.date.prompt', {
                property: item.name,
                date: moment().format(this.dateTimeFormat),
              }),
              promptTitle: this.translate.instant('excel.date.promptTitle', { fieldName: item.name }),
            },
          });
          break;
        case 'Dropdown':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dropdownOptions: {
              data: item.meta['options'].map((option: any) => {
                return {
                  id: option['id'],
                  name: option['label'],
                };
              }),
              prop: `${item.name}Dropdown.id`,
              dataProperty: `${item.name}Dropdown.name`,
              dataId: `${item.name}Dropdown.id`,
            },
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'Text':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'TextArea':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        case 'DropdownMulti':
          excelColumns.columns.push({
            header: item.name,
            key: item.meta['fieldId'],
            width: 20,
            type: ValueType.String,
            isRequired: item.meta['mandatory'],
            dataValidation: {
              type: CellTypes.CUSTOM,
              allowBlank: false,
              showErrorMessage: true,
              formulae: [],
              errorStyle: 'Error',
              showInputMessage: true,
            },
          });
          break;
        default:
      }
    });

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getLogbooksFromExcel(
    file: File,
    logbookMasterData: ILogbookMasterDataDetail,
  ): Promise<ILogbookExcel | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const logbookSheet: Worksheet = workbook.getWorksheet(this.translate.instant('pageTitles.logbookSettings'));
    const isMainLogbookDataSheet: Worksheet = workbook.getWorksheet('isMainLogbookDataSheet');
    const isMobileLogbookDataSheet: Worksheet = workbook.getWorksheet('isMobileLogbookDataSheet');
    const parentLogbookDataSheet: Worksheet = workbook.getWorksheet('parentLogbookDataSheet');
    const workflowDataSheet: Worksheet = workbook.getWorksheet('workflowDataSheet');
    const stateDurationTypeSheet: Worksheet = workbook.getWorksheet('stateDurationTypeDataSheet');

    if (!logbookSheet) {
      return null;
    }

    const isMainLogbookData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      isMainLogbookDataSheet,
    );
    const isMobileLogbookData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      isMobileLogbookDataSheet,
    );
    const parentLogbookData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      parentLogbookDataSheet,
    );
    const workflowData: ISelect<string, string>[] = this.getGenericSheetData<string, string>(workflowDataSheet);
    const stateDurationTypeData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(
      stateDurationTypeSheet,
    );
    const { columns } = this.getExcelColumns(
      {
        activeMasterDataData: logbookMasterData,
        workflows: null,
        logbooks: null,
        forms: null,
        yesNo: null,
        yesDropdown: null,
        timeTypeDropdown: null,
      },
      false,
    );
    const columnKeys: IExcelColumnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      isMainLogbookData,
      isMobileLogbookData,
      parentLogbookData,
      workflowData,
      stateDurationTypeData,
      fieldVersion: !_.isNil(workbook.description) ? Number(workbook.description) : null,
      logbookData: this.excelHelper.getExcelRowsFromWorkSheet<ILogbookExcelData>(logbookSheet, columnKeys, {
        dateFormat: this.dateFormat$,
        timeFormat: this.timeFormat$,
        timezone: this.timezone,
      }),
    };
  }

  private getGenericSheetData<IdType, NameType>(sheet: Worksheet | null): ISelect<IdType, NameType>[] {
    if (!sheet) {
      return [];
    }

    const genericColumns = {
      id: { key: 'id', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
      name: { key: 'name', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
    };

    return this.excelHelper.getExcelRowsFromWorkSheet<ISelect<IdType, NameType>>(sheet, genericColumns);
  }

  public uploadExcel(
    logbookExcelData: ILogbookCreate[],
    issuerAndReason: IIssuerAndReason | null,
    scopeId?: number | null,
  ): Observable<IBulkResponseData> {
    return this.http.post<IBulkResponseData>(this.URLS.LOGBOOKS_BULK_URL, {
      scopeId,
      issuer: issuerAndReason?.issuer,
      payload: logbookExcelData,
      reason: issuerAndReason?.reason,
    });
  }

  public formatLogbookData(logbook: ILogbookExcelWithError, content: Partial<ILogbookExcelContent>): void {
    if (Array.isArray(logbook.selectedForms)) {
      logbook.selectedForms = String(
        logbook.selectedForms.map((formId: number) => {
          return _.find(content.forms, { id: formId })?.formId ?? '';
        }),
      );
    }
    logbook.issuedDate = logbook.issuedDate ? moment(logbook.issuedDate).format(this.dateFormat$) : '';
    content.activeMasterDataData?.fields?.forEach((item: ILogbookMasterDataFields) => {
      switch (item.meta['type']) {
        case 'Checkbox':
          const value: number | undefined = _.find(logbook['fieldMeta'], {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
          const contentValue: number | string = value ? 1 : '0';
          logbook[`${item['name']}Dropdown`] = {
            id: _.isNil(value) ? null : _.find(content.yesNo, { id: contentValue })?.id,
            name: _.isNil(value)
              ? null
              : ComponentUtilities.findOneOptionForSelect(content.yesNo ?? [], contentValue)?.[0]?.name,
          };
          break;
        case 'Dropdown':
          const id: number | undefined = _.find(logbook.fieldMeta, { fieldId: item['meta']['fieldId'] })?.inputModel[0]
            ?.id;
          logbook[`${item['name']}Dropdown`] = {
            id,
            name: ComponentUtilities.findOneOptionForSelect(
              (item['meta']['options'] as any[]) ?? [],
              id ?? 0,
              'id',
            )?.[0]?.label,
          };
          break;
        case 'CheckboxMulti':
        case 'DropdownMulti':
          const multiInputModel = _.find(logbook.fieldMeta, { fieldId: item['meta']['fieldId'] })?.inputModel;
          if (!Array.isArray(multiInputModel)) {
            return;
          }
          logbook[item['meta']['fieldId']] = multiInputModel
            .map((item: { id: number; label: string; status: boolean }) => {
              return item.label;
            })
            .join(', ');
          break;
        case 'Datetime':
          const inputModel: any | undefined = _.find(logbook.fieldMeta, {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
          logbook[item['meta']['fieldId']] = inputModel
            ? inputModel.startDate !== 'Invalid date'
              ? moment(inputModel['startDate']).tz(this.timezone).format(`${this.dateFormat$} ${this.timeFormat$}`)
              : 'Invalid date'
            : '';
          break;
        default:
          logbook[item['meta']['fieldId']] = _.find(logbook.fieldMeta, {
            fieldId: item['meta']['fieldId'],
          })?.inputModel;
      }
    });
    this.setForeignKeyDropdowns(logbook, content);
  }

  private setForeignKeyDropdowns(logbook: ILogbookExcelData, content: Partial<ILogbookExcelContent>) {
    const selectedParentLogbook: ILogbook | undefined = ComponentUtilities.findOneOptionForSelect(
      content.logbooks ?? [],
      logbook.parentLogbook ?? 0,
    )?.[0];

    logbook.parentLogbookDropdown = {
      id: logbook.parentLogbook,
      name: selectedParentLogbook ? `${selectedParentLogbook.logbookId} - ${selectedParentLogbook.name}` : '',
    };
    logbook.isMobileLogbookDropdown = {
      id: logbook.isMobileLogbook,
      name: ComponentUtilities.findOneOptionForSelect(content.yesNo ?? [], logbook.isMobileLogbook ?? 0)?.[0]?.name,
    };
    logbook.isMainLogbookDropdown = {
      id: Number(logbook.mainLogbook),
      name: ComponentUtilities.findOneOptionForSelect(content.yesNo ?? [], Number(logbook.mainLogbook))?.[0]?.name,
    };
    logbook.approvalWorkflowDropdown = {
      id: Number(logbook.workflow),
      name: ComponentUtilities.findOneOptionForSelect(content.workflows ?? [], logbook.workflow ?? 0)?.[0]?.name,
    };
  }

  public prepareLogbookExcelData(
    logbookExcelData: ILogbookExcel,
    issuerAndReason: IIssuerAndReason,
    activeMasterDataId: number,
    activeMasterDataData: ILogbookMasterDataDetail,
    allFormData: IForm[],
  ): ILogbookCreate[] {
    const payload: ILogbookCreate[] = [];
    logbookExcelData.logbookData.forEach((item: ILogbookExcelData) => {
      payload.push({
        isActive: false,
        isSubmitted: false,
        fieldVersion: activeMasterDataId,
        fieldMeta: ComponentUtilities.formatExcelUserDefinedFields(
          item,
          activeMasterDataData,
          this.translate,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
        ),
        legacyId: item.legacyId,
        issuedDate: item.issuedDate
          ? moment(item.issuedDate, this.dateFormat$, true).tz(this.timezone, true).endOf('day').utc().format()
          : null,
        name: item.name ? item.name.trim() : null,
        workflow: item.workflow ? Number(item.workflow) : null,
        parentLogbook: item.parentLogbook,
        selectedForms: String(item.selectedForms)
          ? this.formatExcelSelectedForms(String(item.selectedForms), allFormData)
          : [],
        mainLogbook: item.isMainLogbook,
        isMobileLogbook: item.isMobileLogbook,
        loggable: !!String(item.selectedForms)?.split(', ').length,
        reason: issuerAndReason.reason,
        order: Number(item.order) === 0 ? null : Number(item.order),
        stateDuration: item.stateDuration,
        stateDurationType: item.stateDurationType,
      });
    });

    return payload;
  }

  public formatExcelSelectedForms(excelSelectedForms: string, allFormData: IForm[]): number[] {
    const selectedFormIds: number[] = [];
    excelSelectedForms.split(', ').forEach((item: string) => {
      const form: IForm | undefined = _.find(allFormData, { formId: item });
      if (form) {
        selectedFormIds.push(form.id);
      } else if (item !== 'null' && item !== 'undefined') {
        selectedFormIds.push(-1);
      }
    });

    return _.uniq(selectedFormIds);
  }

  public adjustPreviousVersionFields(
    previousVersion: ILogbookVersionWithWorkflowDetail,
    formData: IForm[],
    logbookData: ISelect<number, string>[],
    workflowData: IWorkflow[],
  ): IPreviousLogbookVersion {
    const yesNoDropdown: ISelect<number, string>[] = this.helperService.getYesNoDropdown();
    const timeTypeDropdown: ISelect<number, string>[] = this.helperService.getTimeType();

    if (!previousVersion) {
      return {
        selectedForms: [],
        logbookId: null,
        legacyId: null,
        versionNumber: null,
        logbookName: null,
        mainLogbook: [],
        parentLogbook: [],
        isMobileLogbook: [],
        approvalWorkFlow: [],
        issuedDate: {
          startDate: null,
          endDate: null,
        },
        stateDuration: null,
        stateDurationType: [],
      };
    }

    const selectedForms: ISelect<number, string>[] = previousVersion.selectedForms.map((formId: number) => {
      return _.find(formData, { id: formId });
    }) as ISelect<number, string>[];

    return {
      selectedForms,
      logbookId: previousVersion.logbook.logbookId,
      legacyId: previousVersion.legacyId,
      versionNumber: previousVersion.versionNumber,
      logbookName: previousVersion.name,
      mainLogbook: ComponentUtilities.findOneOptionForSelect(yesNoDropdown, Number(previousVersion.mainLogbook)),
      parentLogbook: ComponentUtilities.findOneOptionForSelect(logbookData, previousVersion.parentLogbook, 'id'),
      isMobileLogbook: ComponentUtilities.findOneOptionForSelect(
        yesNoDropdown,
        Number(previousVersion.isMobileLogbook),
      ),
      approvalWorkFlow: ComponentUtilities.findOneOptionForSelect(workflowData, previousVersion.workflow.id),
      issuedDate: {
        startDate: previousVersion.issuedDate ? moment(previousVersion.issuedDate).tz(this.timezone) : null,
        endDate: previousVersion.issuedDate ? moment(previousVersion.issuedDate).tz(this.timezone) : null,
      },
      stateDuration: previousVersion.stateDuration,
      stateDurationType: ComponentUtilities.findOneOptionForSelect(timeTypeDropdown, previousVersion.stateDurationType),
    };
  }

  public setCompareFields(
    logbookForm: ILogbookForm,
    previousVersionData: IPreviousLogbookVersion,
    logbookDropdownData: ISelect<number, string>[],
  ): ICompareField[] {
    const yesNoDropdown: ISelect<number, string>[] = this.helperService.getYesNoDropdown();
    const timeTypeDropdown: ISelect<number, string>[] = this.helperService.getTimeType();
    const noFormsSelectedLabel: string = this.translate.instant('myTasks.logbookTasksShowModal.noSelectedForms');
    const selectedForms: IForm[] = this.helperService.getFormNameWithArchiveLabel(
      _.sortBy(logbookForm.selectedForms.value, 'id'),
    );
    previousVersionData.selectedForms = this.helperService.getFormNameWithArchiveLabel(
      previousVersionData.selectedForms,
    );

    return [
      {
        type: EFieldType.INPUT,
        label: 'logbook.modal.logbookName.label',
        required: true,
        highlighted: previousVersionData.logbookName !== logbookForm.logbookName.value,
        values: {
          before: previousVersionData.logbookName,
          after: logbookForm.logbookName.value,
        },
      },
      {
        type: EFieldType.INPUT,
        label: 'logbook.modal.logbookId.label',
        required: true,
        highlighted: previousVersionData.logbookId !== logbookForm.logbookId.value,
        values: {
          before: previousVersionData.logbookId,
          after: logbookForm.logbookId.value,
        },
      },
      {
        type: EFieldType.INPUT,
        label: 'logbook.modal.legacyId.label',
        required: false,
        highlighted: previousVersionData.legacyId?.trim() !== logbookForm.legacyId.value?.trim(),
        values: {
          before: previousVersionData.legacyId,
          after: logbookForm.legacyId.value,
        },
      },
      {
        type: EFieldType.INPUT,
        label: 'logbook.modal.versionNumber.label',
        required: true,
        highlighted: previousVersionData.versionNumber !== Number(logbookForm.versionNumber.value),
        values: {
          before: previousVersionData.versionNumber,
          after: logbookForm.versionNumber.value,
        },
      },
      {
        type: EFieldType.SELECT,
        label: 'logbook.modal.mainLogbook.label',
        required: true,
        highlighted:
          previousVersionData.mainLogbook[0]?.id !==
          (logbookForm.mainLogbook.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.mainLogbook,
          after: logbookForm.mainLogbook.value,
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.SELECT,
        label: 'logbook.modal.parentLogbook.label',
        required: true,
        highlighted:
          previousVersionData.parentLogbook[0]?.id !==
          (logbookForm.parentLogbook.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.parentLogbook,
          after: logbookForm.parentLogbook.value,
        },
        data: logbookDropdownData,
        depends: {
          before: !!(previousVersionData.mainLogbook.length && previousVersionData.mainLogbook[0].id === 0),
          after: !!(logbookForm.mainLogbook.value && logbookForm.mainLogbook.value[0].id === 0),
        },
      },
      {
        type: EFieldType.SELECT,
        label: 'logbook.modal.mobileLogbook.label',
        required: true,
        highlighted:
          previousVersionData.isMobileLogbook[0]?.id !==
          (logbookForm.isMobileLogbook.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.isMobileLogbook,
          after: logbookForm.isMobileLogbook.value,
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.SELECT,
        label: 'logbook.modal.approvalWorkFlow.label',
        required: true,
        highlighted:
          previousVersionData.approvalWorkFlow[0]?.id !==
          (logbookForm.approvalWorkFlow.value as ISelect<number, string>[])[0]?.id,
        values: {
          before: previousVersionData.approvalWorkFlow,
          after: logbookForm.approvalWorkFlow.value,
        },
        button: {
          buttonIconCls: 'fas fa-eye',
          disabled: (item: ISelect<number, string>[] | null) => {
            return _.isNil(item) || item[0].id === 1;
          },
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.LABEL,
        label: 'logbook.modal.selectedForms.label',
        required: false,
        highlighted: !_.isEqual(_.sortBy(previousVersionData.selectedForms, 'id'), _.sortBy(selectedForms, 'id')),
        values: {
          before: previousVersionData.selectedForms.length
            ? _.map(previousVersionData.selectedForms, 'name').join(', ')
            : noFormsSelectedLabel,
          after: selectedForms.length ? _.map(selectedForms, 'name').join(', ') : noFormsSelectedLabel,
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.DATE,
        label: 'settings.masterData.logbook.addEditForm.issuedDate',
        required: false,
        highlighted: !_.isEqual(logbookForm.issuedDate.value, previousVersionData.issuedDate),
        values: {
          before: previousVersionData.issuedDate,
          after: logbookForm.issuedDate.value,
        },
        data: yesNoDropdown,
      },
      {
        type: EFieldType.GROUP,
        label: 'settings.masterData.logbook.addEditForm.stateDuration.label',
        highlighted:
          previousVersionData.stateDuration !== Number(logbookForm.stateDuration) ||
          previousVersionData.stateDurationType[0]?.id !==
            (logbookForm.stateDurationType.value as ISelect<number, string>[])[0]?.id,
        values: null,
        content: [
          {
            type: EFieldType.INPUT,
            label: 'settings.masterData.logbook.addEditForm.stateDuration.time',
            required: true,
            highlighted: previousVersionData.stateDuration !== Number(logbookForm.stateDuration),
            values: {
              before: previousVersionData.stateDuration,
              after: logbookForm.stateDuration.value,
            },
          },
          {
            type: EFieldType.SELECT,
            label: 'settings.masterData.logbook.addEditForm.stateDuration.type',
            required: true,
            highlighted:
              previousVersionData.stateDurationType[0]?.id !==
              (logbookForm.stateDurationType.value as ISelect<number, string>[])[0]?.id,
            values: {
              before: previousVersionData.stateDurationType,
              after: logbookForm.stateDurationType.value,
            },
            data: timeTypeDropdown,
          },
        ],
      },
    ];
  }

  public bulkSubmitLogbooks(
    logbookVersionIds: number[],
    issuer: IIssuer | null,
  ): Observable<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>> {
    return this.http.post<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>>(`${this.URLS.BULK.SUBMIT}`, {
      issuer,
      payload: { logbookVersionIds },
    });
  }

  public bulkActivateLogbooks(
    logbookVersionIds: number[],
    issuer: IIssuer | null,
  ): Observable<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>> {
    return this.http.post<IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>>(
      `${this.URLS.BULK.ACTIVATE}`,
      {
        issuer,
        payload: { logbookVersionIds },
      },
    );
  }

  public getLogbookStateData(logbookId: number, params: HttpParams): Observable<IGetOneResponse<ILogbookStates>> {
    return this.http.get<IGetOneResponse<ILogbookStates>>(`${this.URLS.SELECTED_STATES_URL}${logbookId}`, { params });
  }

  public getStateListData(params: HttpParams): Observable<IGetManyResponse<IStateStates>> {
    return this.http.get<IGetManyResponse<IStateStates>>(`${this.URLS.AVAILABLE_STATES_URL}`, { params });
  }

  public postLogbookStateData(
    logbookId: number,
    payload: ILogbookStates,
    issuerAndReason?: IIssuerAndReason | null,
  ): Observable<IGetManyResponse<ILogbookStates>> {
    return this.http.post<IGetManyResponse<ILogbookStates>>(
      `${this.URLS.SELECT_STATES_URL}${logbookId}`,
      {
        issuer: issuerAndReason?.issuer,
        payload: {
          ...payload,
          reason: issuerAndReason?.reason,
        },
      },
    );
  }

  public updateLogbookCurrentStates(
    logbookId: number,
    states: number[],
    issuerAndReason: IIssuerAndReason | null,
  ): Observable<IGetManyResponse<ILogbookStates>> {
    return this.http.post<IGetManyResponse<ILogbookStates>>(`${this.URLS.SET_CURRENT_STATES_URL}${logbookId}`, {
      issuer: issuerAndReason?.issuer,
      payload: {
        states,
        reason: issuerAndReason?.reason,
      },
    });
  }
}
