import { Action } from '@ngrx/store';

export const FETCH_ERROR = '[APP] Fetch Error';
export const SHOW_LOADER = '[APP] Show loader';
export const HIDE_LOADER = '[APP] Hide loader';
export const SHOW_TOP_LOADER = '[APP] Show top loader';
export const HIDE_TOP_LOADER = '[APP] Hide top loader';
export const LOG_EXTERNAL_PAGE_VIEWS = '[APP] Log External Page Views';
export const GET_CURRENT_USER = '[APP] Get Current User';
export const SHOW_MASK = '[APP] Show Mask';
export const HIDE_MASK = '[APP] Hide Mask';
export const EMPTY_ACTION = '[APP] Empty Action';

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class ShowLoader implements Action {
  readonly type = SHOW_LOADER;

  constructor(public loadingText: string | null = null, public loading: boolean = true) { }
}

export class HideLoader implements Action {
  readonly type = HIDE_LOADER;

  constructor(public loading: boolean = false) { }
}

export class ShowTopLoader implements Action {
  readonly type = SHOW_TOP_LOADER;
}

export class HideTopLoader implements Action {
  readonly type = HIDE_TOP_LOADER;
}

export class LogExternalPageViews implements Action {
  readonly type = LOG_EXTERNAL_PAGE_VIEWS;

  constructor(public url: string) { }
}

export class GetCurrentUser implements Action {
  readonly type = GET_CURRENT_USER;

  constructor(public action: string) { }
}

export class ShowMask implements Action {
  readonly type = SHOW_MASK;
}

export class HideMask implements Action {
  readonly type = HIDE_MASK;
}

export class EmptyAction implements Action {
  readonly type = EMPTY_ACTION;
}

export type AppActions
  = ShowLoader
  | HideLoader
  | ShowTopLoader
  | HideTopLoader
  | LogExternalPageViews
  | ShowMask
  | HideMask
  | EmptyAction
  ;
