import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, of, take } from 'rxjs';
import * as ObjectActions from './main.actions';
import { IAllowedDomain } from './main.model';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../constants';
import { MainService } from './main.service';
import { IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import { IClientSettingsState } from '../settings/client/client.model';
import { IGetCurrentUserResponse } from '../app/model';

@Injectable()
export class MainEffects {
  constructor(
    private actions$: Actions<ObjectActions.MainActions>,
    private readonly mainService: MainService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {}

  getAllowedDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ALLOWED_DOMAINS),
      switchMap(() => {
        const params: HttpParams = new HttpParams().set('limit', STATIC_MAX_LIMIT_OF_CRUD);

        return this.mainService.getAllowedDomains(params).pipe(
          switchMap((response: IGetManyResponse<IAllowedDomain>) => {
            return of(new ObjectActions.GetAllowedDomainsCompleted(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  checkRight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_PERMISSION),
      switchMap((objectData: ObjectActions.CheckPermission) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let askForPinOnAction = true;
        this.store
          .select('clientStore')
          .pipe(take(1))
          .subscribe((state: IClientSettingsState) => {
            askForPinOnAction = Boolean(state.clientCommonInformation?.askForPinOnAction);
          });
        if (askForPinOnAction && objectData.issuer) {
          return this.mainService.checkUserRight(objectData.payload, objectData.issuer).pipe(
            switchMap((response: IResponse<any>) => {
              return of(
                new ObjectActions.CheckPermissionCompleted(response, objectData.issuer),
                objectData.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
              );
            }),
            catchError((error) => {
              return of(
                new ObjectActions.FetchError(error),
                objectData.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
              );
            }),
          );
        }
        return of(
          new ObjectActions.CheckPermissionCompleted({ success: true }, objectData.issuer),
          objectData.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  createManualLog = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_MANUAL_LOG),
      switchMap((objectData: ObjectActions.CreateManualLog) => {
        return this.mainService
          .createManualLog(
            objectData.actionType,
            objectData.contentModel,
            objectData.objectId,
            objectData.location,
            objectData.issuer,
            objectData.reason,
          )
          .pipe(
            switchMap((response: IResponse<number>) => {
              return of(new ObjectActions.CreateManualLogCompleted(response.data));
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error));
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  changeUpdatingManualLog = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHANGE_UPDATING_MANUAL_LOG),
      switchMap((objectData: ObjectActions.ChangeUpdatingManualLog) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.mainService
          .changeUpdatingManualLog(objectData.actionType, objectData.logId, objectData.issuer)
          .pipe(
            switchMap((response: IResponse<number>) => {
              return of(new ObjectActions.ChangeUpdatingManualLogCompleted(response.data), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error));
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  getAuthorizedPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_AUTHORIZED_PAGES),
      switchMap((objectData: ObjectActions.GetAuthorizedPages) => {
        return this.mainService.getAuthorizedPages('application-parameters').pipe(
          switchMap((response: IGetCurrentUserResponse) => {
            if (response.success) {
              return of(new ObjectActions.SetAuthorizedPages(response.data.userRights));
            }

            throw new Error('Failed fetching authorized pages');
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );
}
