import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, of, map } from 'rxjs';
import * as ObjectActions from './audit-trail.actions';
import * as AppActions from '../app/actions';
import { HttpParams } from '@angular/common/http';
import * as LogbookAppReducer from '../logbook.reducer';
import { Store } from '@ngrx/store';
import { IAuditTrailResponse } from '../../view/audit-trail/audit-trail.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { AuditTrailService } from '../../shared/service/audit-trail/audit-trail.service';
import { emptyAction } from '../../../constants';
import { IBulkResponseRecord, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IFormVersionLite } from '../my-tasks/logbook-tasks/logbook-tasks.model';
import { IFormSubmissionUserAction } from '../../../constants.model';
import { IGenericFilter } from '../../shared/model/interface/common-page.model';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Injectable()
export class AuditTrailEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: AuditTrailService,
    private translate: TranslateService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
  ) {}

  getAuditTrailData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_DATA_LOADING),
      switchMap((payload: ObjectActions.AuditTrailDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
        });

        if (payload.tableQuery.filters) {
          for (const row of payload.tableQuery.filters) {
            body = this.generateBodyParams(row, body);
          }
        }

        if (payload.tableQuery?.sort && payload.tableQuery.sort.column !== '') {
          _.set(body, 'ordering', `${payload.tableQuery.sort.type}${payload.tableQuery.sort.column}`);
        }

        return this.service.getAuditTrails(body).pipe(
          switchMap((response: IAuditTrailResponse) => {
            return of(new ObjectActions.AuditTrailDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadReportExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT),
      switchMap((objectData: ObjectActions.DownloadReportExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(
          objectData.tableQuery,
        );
        this.service.downloadReportExcel(body, objectData.selectedColumns);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadReportExcelCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT_COMPLETED),
      switchMap(() => {
        this.store.dispatch(new AppActions.HideLoader());
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSelectedForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_SELECTED_FORMS_LOADING),
      switchMap((payload: ObjectActions.AuditTrailSelectedFormsLoading) => {
        const archiveFormLabel: string = this.translate.instant('home.logbook.archived');
        return this.service.getSelectedForms(payload.entry_ids).pipe(
          map((response: IBulkResponseRecord<IFormVersionLite>) => {
            return {
              ...response,
              data: response.data.map((item: any) => {
                return {
                  ...item,
                  name: item.isArchived ? `${item.name} (${archiveFormLabel})` : item.name,
                };
              }),
            };
          }),
          switchMap((response: IBulkResponseRecord<IFormVersionLite>) => {
            return of(
              new ObjectActions.AuditTrailSelectedFormsLoaded(payload.rowId, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.AuditTrailSelectedFormsLoaded(payload.rowId, errorRes),
              new ObjectActions.FetchError(errorRes),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getScopeLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_SCOPE_LOGBOOK_LOADING),
      switchMap((payload: ObjectActions.AuditTrailScopeLogbookLoading) => {
        return this.service.getScopeLogbook(payload.entry_ids).pipe(
          switchMap((response: IBulkResponseRecord<IFormVersionLite>) => {
            return of(
              new ObjectActions.AuditTrailScopeLogbookLoaded(payload.rowId, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.AuditTrailScopeLogbookLoaded(payload.rowId, errorRes),
              new ObjectActions.FetchError(errorRes),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getScopeForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_SCOPE_FORM_LOADING),
      switchMap((payload: ObjectActions.AuditTrailScopeFormLoading) => {
        return this.service.getScopeForm(payload.entry_ids).pipe(
          switchMap((response: IBulkResponseRecord<IFormVersionLite>) => {
            return of(
              new ObjectActions.AuditTrailScopeFormLoaded(payload.rowId, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.AuditTrailScopeFormLoaded(payload.rowId, errorRes),
              new ObjectActions.FetchError(errorRes),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getLogsFormEntriesHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.AuditTrailFormSubmissionHistoryLoading) => {
        return this.service.getLogsFormEntriesHistory(payload.formSubmissionId, payload.historyRowId).pipe(
          switchMap((response: IGetManyResponse<IFormSubmissionUserAction>) => {
            return of(
              new ObjectActions.AuditTrailFormSubmissionHistoryLoaded(payload.historyRowId, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.AuditTrailFormSubmissionHistoryLoaded(payload.historyRowId, errorRes),
              new ObjectActions.FetchError(errorRes),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  public generateBodyParams(
    row: IGenericFilter,
    param: Record<string, string | number>,
  ): Record<string, string | number> {
    let body: Record<string, string | number> = param;
    if (row.field === 'startDate') {
      body = { ...body, start_date: row.values ? row.values.toString() : '' };
    }

    if (row.field === 'endDate') {
      body = { ...body, end_date: row.values ? row.values.toString() : '' };
    }

    if (row.field === 'users' && Array.isArray(row.values)) {
      body = { ...body, users: JSON.stringify(row.values) };
    }

    if (row.field === 'actions' && Array.isArray(row.values)) {
      body = { ...body, actions: JSON.stringify(row.values) };
    }

    if (row.field === 'locations' && Array.isArray(row.values)) {
      body = { ...body, locations: JSON.stringify(row.values) };
    }

    return body;
  }
}
