import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, catchError, switchMap } from 'rxjs';
import { IResponse } from '../../../shared/model/interface/generic-api-response.model';
import * as logBookAppReducer from '../../../store/logbook.reducer';
import * as AppActions from '../../app/actions';
import * as ClientActions from './client.actions';
import { IClient, IClientSettings, IUploadResponse } from './client.model';
import { ClientService } from './client.service';

@Injectable()
export class ClientEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<logBookAppReducer.LogbookAppState>,
    private readonly service: ClientService,
  ) {}

  getClientSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.GET_CLIENT_SETTINGS),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getClientWithAllowedDomain().pipe(
          switchMap((response: IClient) => {
            return of(new ClientActions.GetClientSettingsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ClientActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ClientActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editClient = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.EDIT_CLIENT_SETTINGS),
      switchMap((objectData: ClientActions.EditClientSettings) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editClientSettings(objectData.payload, objectData.issuer).pipe(
          switchMap((response: IResponse<IClientSettings>) => {
            return of(
              new ClientActions.EditClientSettingsCompleted(response),
              new AppActions.GetCurrentUser('application-parameters'),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ClientActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ClientActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadLogo = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.CLIENT_LOGO_UPLOAD),
      switchMap((objectData: ClientActions.UploadLogo) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadLogo(objectData.payload as File, objectData.issuerAndReason.issuer).pipe(
          switchMap((response: IResponse<IUploadResponse>) => {
            return of(
              new ClientActions.UploadLogoCompleted(response, objectData.issuerAndReason),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ClientActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ClientActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
