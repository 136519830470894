import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { ILogbookMasterData, ILogbookMasterDataDetail, ILogbookMasterDataHistory } from './logbook-master-data.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../workflows/workflows.model';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { ILogbookMasterDataFormPayload } from '../../../view/settings/master-data/logbook-master-data/logbook-master-data.component.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export const LOGBOOK_MASTER_DATA_DATA_LOAD = '[LOGBOOK_MASTER_DATA] LOGBOOK_MASTER_DATA DATA LOAD';
export const LOGBOOK_MASTER_DATA_REFRESH_LOAD = '[LOGBOOK_MASTER_DATA] LOGBOOK_MASTER_REFRESH DATA LOAD';
export const LOGBOOK_MASTER_DATA_DATA_LOADED = '[LOGBOOK_MASTER_DATA] LOGBOOK_MASTER_DATA DATA LOADED';
export const LOGBOOK_MASTER_DATA_ADD_LOAD = '[LOGBOOK_MASTER_ADD] LOGBOOK_MASTER_ADD DATA LOAD';
export const LOGBOOK_MASTER_DATA_ADD_LOADED = '[LOGBOOK_MASTER_ADD] LOGBOOK_MASTER_ADD DATA LOADED';
export const LOGBOOK_MASTER_DATA_EDIT_LOAD = '[LOGBOOK_MASTER_EDIT] LOGBOOK_MASTER_EDIT DATA LOAD';
export const LOGBOOK_MASTER_DATA_EDIT_LOADED = '[LOGBOOK_MASTER_EDIT] LOGBOOK_MASTER_EDIT DATA LOADED';
export const LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD =
  '[LOGBOOK_MASTER_DATA_HISTORY] LOGBOOK_MASTER_DATA_HISTORY DATA LOAD';
export const LOGBOOK_MASTER_DATA_HISTORY_DATA_LOADED =
  '[LOGBOOK_MASTER_DATA_HISTORY] LOGBOOK_MASTER_DATA_HISTORY DATA LOADED';
export const LOGBOOK_MASTER_DATA_DETAIL_LOAD = '[LOGBOOK_MASTER_DETAIL] LOGBOOK_MASTER_DETAIL DATA LOAD';
export const LOGBOOK_MASTER_DATA_DETAIL_LOADED = '[LOGBOOK_MASTER_DETAIL] LOGBOOK_MASTER_DETAIL DATA LOADED';
export const LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOAD =
  '[LOGBOOK_MASTER_SEND_TO_REVIEW] LOGBOOK_MASTER_SEND_TO_REVIEW DATA LOAD';
export const LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOADED =
  '[LOGBOOK_MASTER_SEND_TO_REVIEW] LOGBOOK_MASTER_SEND_TO_REVIEW DATA LOADED';
export const LOGBOOK_MASTER_DATA_ACTIVATE_LOAD = '[LOGBOOK_MASTER_ACTIVATE] LOGBOOK_MASTER_ACTIVATE DATA LOAD';
export const LOGBOOK_MASTER_DATA_ACTIVATE_LOADED = '[LOGBOOK_MASTER_ACTIVATE] LOGBOOK_MASTER_ACTIVATE DATA LOADED';
export const LOGBOOK_MASTER_DATA_DELETE_LOAD = '[LOGBOOK_MASTER_DELETE] LOGBOOK_MASTER_DELETE DATA LOAD';
export const LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD =
  '[LOGBOOK_MASTER_APPROVAL_WORKFLOW] LOGBOOK_MASTER_APPROVAL_WORKFLOW DATA LOAD';
export const LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOADED =
  '[LOGBOOK_MASTER_APPROVAL_WORKFLOW] LOGBOOK_MASTER_APPROVAL_WORKFLOW DATA LOADED';
export const LOGBOOK_MASTER_DATA_DELETE_LOADED = '[LOGBOOK_MASTER_DELETE] LOGBOOK_MASTER_DELETE DATA LOADED';
export const LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD =
  '[LOGBOOK_MASTER_WORKFLOW_STEPS] LOGBOOK_MASTER_WORKFLOW_STEPS DATA LOAD';
export const LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOADED =
  '[LOGBOOK_MASTER_WORKFLOW_STEPS] LOGBOOK_MASTER_WORKFLOW_STEPS DATA LOADED';
export const LOGBOOK_MASTER_DATA_CLONE_LOAD = '[LOGBOOK_MASTER_CLONE] LOGBOOK_MASTER_CLONE DATA LOAD';
export const LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOAD =
  '[LOGBOOK_MASTER_PASSED_ISSUE_DATE] LOGBOOK_MASTER_PASSED_ISSUE_DATE DATA LOAD';
export const LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOADED =
  '[LOGBOOK_MASTER_PASSED_ISSUE_DATE] LOGBOOK_MASTER_PASSED_ISSUE_DATE DATA LOADED';
export const LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD =
  '[LOGBOOK_MASTER_DRAFT_VERSION_EXISTS] LOGBOOK_MASTER_DRAFT_VERSION_EXISTS DATA LOAD';
export const LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED =
  '[LOGBOOK_MASTER_DRAFT_VERSION_EXISTS] LOGBOOK_MASTER_DRAFT_VERSION_EXISTS DATA LOADED';
export const FETCH_ERROR = '[LOGBOOK_MASTER_DATA] FETCH ERROR';

export class LogbookMasterDataDataLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DATA_LOAD;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery) {}
}

export class LogbookMasterDataDataLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export class LogbookMasterDataAddLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_ADD_LOAD;

  constructor(public payload: ILogbookMasterDataFormPayload, public issuerAndReason: IIssuerAndReason) {}
}

export class LogbookMasterDataAddLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_ADD_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataEditLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_EDIT_LOAD;

  constructor(public id: number | null, public payload: ILogbookMasterDataFormPayload, public issuer: IIssuer | null) {}
}

export class LogbookMasterDataEditLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_EDIT_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataHistoryDataLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD;

  constructor(public id: number) {}
}

export class LogbookMasterDataHistoryDataLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_HISTORY_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterDataHistory>) {}
}

export class LogbookMasterDataDetailLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DETAIL_LOAD;

  constructor(public id: number, public getPreviousVersion: boolean = false) {}
}

export class LogbookMasterDataDetailLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DETAIL_LOADED;

  constructor(public payload: IBaseOneResponse<ILogbookMasterDataDetail>) {}
}

export class LogbookMasterDataSendToReviewLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOAD;

  constructor(public id: number | null, public issuer: IIssuer | null) {}
}

export class LogbookMasterDataSendToReviewLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataActivateLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_ACTIVATE_LOAD;

  constructor(public id: number | null, public issuer: IIssuer | null) {}
}

export class LogbookMasterDataActivateLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_ACTIVATE_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataDeleteLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DELETE_LOAD;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class LogbookMasterDataDeleteLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DELETE_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataApprovalWorkflowLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD;

  constructor(public searchText: string, public limit?: number, public skipLoader?: boolean) {}
}

export class LogbookMasterDataApprovalWorkflowLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export class LogbookMasterDataWorkflowsStepsLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD;

  constructor(public id: number) {}
}

export class LogbookMasterDataWorkflowsStepsLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class LogbookMasterDataCloneLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_CLONE_LOAD;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: IGenericObject<any>) {}
}

export class LogbookMasterDataRefreshLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_REFRESH_LOAD;

  constructor(public payload: LogbookMasterDataActions['type'] | null = null) {}
}

export class LogbookMasterDataPassedIssueDateLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOAD;
}

export class LogbookMasterDataPassedIssueDateLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export class LogbookMasterDataDraftVersionExistsLoad implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD;
}

export class LogbookMasterDataDraftVersionExistsLoaded implements Action {
  readonly type = LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export type LogbookMasterDataActions =
  | LogbookMasterDataDataLoad
  | LogbookMasterDataDataLoaded
  | LogbookMasterDataAddLoad
  | LogbookMasterDataAddLoaded
  | LogbookMasterDataEditLoad
  | LogbookMasterDataEditLoaded
  | LogbookMasterDataHistoryDataLoad
  | LogbookMasterDataHistoryDataLoaded
  | LogbookMasterDataDetailLoad
  | LogbookMasterDataDetailLoaded
  | LogbookMasterDataDeleteLoad
  | LogbookMasterDataDeleteLoaded
  | LogbookMasterDataSendToReviewLoad
  | LogbookMasterDataSendToReviewLoaded
  | LogbookMasterDataActivateLoad
  | LogbookMasterDataActivateLoaded
  | LogbookMasterDataApprovalWorkflowLoad
  | LogbookMasterDataApprovalWorkflowLoaded
  | LogbookMasterDataWorkflowsStepsLoad
  | LogbookMasterDataWorkflowsStepsLoaded
  | LogbookMasterDataCloneLoad
  | LogbookMasterDataRefreshLoad
  | LogbookMasterDataPassedIssueDateLoad
  | LogbookMasterDataPassedIssueDateLoaded
  | LogbookMasterDataDraftVersionExistsLoad
  | LogbookMasterDataDraftVersionExistsLoaded
  | FetchError;

export const LOGBOOK_MASTER_DATA_ACTIONS: {
  LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_EDIT_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_EDIT_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DETAIL_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_ADD_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_ACTIVATE_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DELETE_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DELETE_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DATA_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_HISTORY_DATA_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_ACTIVATE_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DATA_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_ADD_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DETAIL_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_CLONE_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_REFRESH_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOADED: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD: LogbookMasterDataActions['type'];
  LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED: LogbookMasterDataActions['type'];
  FETCH_ERROR: LogbookMasterDataActions['type'];
} = {
  LOGBOOK_MASTER_DATA_DATA_LOAD,
  LOGBOOK_MASTER_DATA_DATA_LOADED,
  LOGBOOK_MASTER_DATA_ADD_LOAD,
  LOGBOOK_MASTER_DATA_ADD_LOADED,
  LOGBOOK_MASTER_DATA_EDIT_LOAD,
  LOGBOOK_MASTER_DATA_EDIT_LOADED,
  LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD,
  LOGBOOK_MASTER_DATA_HISTORY_DATA_LOADED,
  LOGBOOK_MASTER_DATA_DETAIL_LOAD,
  LOGBOOK_MASTER_DATA_DETAIL_LOADED,
  LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOAD,
  LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOADED,
  LOGBOOK_MASTER_DATA_DELETE_LOAD,
  LOGBOOK_MASTER_DATA_DELETE_LOADED,
  LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD,
  LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOADED,
  LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD,
  LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOADED,
  LOGBOOK_MASTER_DATA_ACTIVATE_LOAD,
  LOGBOOK_MASTER_DATA_ACTIVATE_LOADED,
  LOGBOOK_MASTER_DATA_CLONE_LOAD,
  LOGBOOK_MASTER_DATA_REFRESH_LOAD,
  LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOAD,
  LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOADED,
  LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD,
  LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED,
  FETCH_ERROR,
};
