import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscription, take } from 'rxjs';
import { IBaseOneResponse } from '../../../model/interface/crud-response-interface.model';
import {
  FieldTypes,
  IFieldSelect,
  IFilter,
  IFilterOutput,
  InputTypes,
  IOperatorSelect,
  OperatorTypes,
  RawSqlOperators,
  SqlOperators,
  TargetEndpoints,
} from './advanced-filter.model';
import { IConfig, User } from '../../../../store/user/model';
import * as _ from 'lodash';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../helper/date';
import { advancedFilterFilterCardSubject } from '../../../../../constants';
import { ITableQuery } from '../../../model/interface/common-page.model';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../../store/logbook.reducer';

@Injectable({
  providedIn: 'root',
})
export class AdvancedFilterService {
  constructor(
    @Inject('API_BASE_URL')
    private readonly api: string,
    private readonly http: HttpClient,
    private readonly store: Store<LogbookAppState>,
  ) {}

  protected filterCard$!: FilterCardComponent | null;
  private filterCardSubscription!: Subscription;
  private timezone$!: string;

  public subscribeFilterCard(): void {
    this.filterCardSubscription = advancedFilterFilterCardSubject.subscribe((filterCard: any) => {
      this.filterCard$ = filterCard;
    });
  }

  private getValueForStringOperator(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    value: { [key: string]: any },
  ): string {
    const isNumberFieldZero = fieldType === FieldTypes.number && value['$ne'] === '0';
    if (operator === SqlOperators.notEquals && fieldType !== FieldTypes.decimal && !isNumberFieldZero) {
      return `${path}${operator}`;
    }

    if (operator === SqlOperators.notEquals && (fieldType === FieldTypes.decimal || isNumberFieldZero)) {
      return `${path}${operator}`;
    }

    return `${path}${operator}`;
  }

  private getValueForBooleanOperator(path: string, operator: SqlOperators) {
    if (operator === SqlOperators.isNull) {
      return { $or: [{ [path]: { $isnull: true } }, { [path]: { $eq: '' } }] };
    }

    return { [path]: { [operator]: true } };
  }

  private generateValueByOperator(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    operatorType: OperatorTypes,
    value: string,
  ): string | void {
    if (operatorType === OperatorTypes.string || operatorType === OperatorTypes.boolean) {
      return this.getValueForStringOperator(path, fieldType, operator, { [operator]: value });
    }
  }

  private getRawOperatorByOperator(operator: SqlOperators): RawSqlOperators {
    switch (operator) {
      case SqlOperators.iExact:
        return RawSqlOperators.EQUALS;

      case SqlOperators.notEquals:
        return RawSqlOperators.NOT_EQUAL;

      case SqlOperators.greaterThan:
        return RawSqlOperators.GREATER_THAN;

      case SqlOperators.greaterThanEquals:
        return RawSqlOperators.GREATER_THAN_OR_EQUAL;

      case SqlOperators.lessThan:
        return RawSqlOperators.LESS_THAN;

      case SqlOperators.lessThanEquals:
        return RawSqlOperators.LESS_THAN_OR_EQUAL;

      case SqlOperators.isNull:
        return RawSqlOperators.IS_NULL;

      case SqlOperators.excludes:
        return RawSqlOperators.NOT_LIKE;

      case SqlOperators.contains:
        return RawSqlOperators.LIKE;

      case SqlOperators.range:
        return RawSqlOperators.BETWEEN;

      default:
        return RawSqlOperators.EQUALS;
    }
  }

  private generateRawValue(
    path: string,
    fieldType: FieldTypes,
    operator: RawSqlOperators,
    operatorType: OperatorTypes,
    value: string,
  ): { [key: string]: any } {
    return { path, fieldType, operator, operatorType, value };
  }

  public generateQuery(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    operatorType: OperatorTypes,
    endpointType: TargetEndpoints,
    value: string,
  ): string | void {
    if (endpointType === TargetEndpoints.DjangoDefault) {
      return this.generateValueByOperator(path, fieldType, operator, operatorType, value);
    }

    throw new Error('Advanced Filter: Invalid target endpoint');
  }

  public setAsDefault(userConfig: IConfig | null): Observable<IBaseOneResponse<User>> {
    return this.http.put<IBaseOneResponse<User>>(`${this.api}/users/configuration`, {
      payload: {
        meta: userConfig,
      },
    });
  }

  public getRequestQueryParameterName(endpointType: TargetEndpoints): string {
    switch (endpointType) {
      case TargetEndpoints.DjangoDefault:
        return 's';

      case TargetEndpoints.Custom:
        return 'advancedFilterParams';

      default:
        return 's';
    }
  }

  public prepareValue(filter: IFilter): any {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.timezone$ = state.timezone ?? '';
      });
    if (filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime) {
      const format: string = filter.selectedFieldType === InputTypes.date ? mysqlDateFormat : mysqlTimestampFormat;

      if (!_.isEmpty(filter.selectedOperator) && filter.selectedOperator[0].type === OperatorTypes.range) {
        return {
          startDate: moment.utc(filter.value.startDate.valueOf()).format(format),
          endDate: moment.utc(filter.value.endDate.valueOf()).format(format),
        };
      }

      if (_.isEmpty(filter.selectedOperator) || filter.selectedOperator[0].type !== OperatorTypes.string) {
        return filter.value;
      }

      if (_.get(filter, 'value.startDate', null)) {
        return filter.value.startDate.tz(this.timezone$, true).toISOString();
      }

      if (typeof filter.value === 'string') {
        return filter.value;
      }
    }

    return filter.value;
  }

  public prepareForOutput(filters: IFilter[]): IFilterOutput[] {
    return filters
      .filter((filter: IFilter) => filter.isActive && filter.selectedField?.length && filter.selectedOperator?.length)
      .map((filter: IFilter): IFilterOutput => {
        const selectedField: IFieldSelect = filter.selectedField[0];
        const selectedOperator: IOperatorSelect = filter.selectedOperator[0];

        return {
          path: selectedField.path,
          type: selectedField.type,
          searchBy: _.get(selectedField, 'searchBy', undefined),
          operator: { name: selectedOperator.sql, type: selectedOperator.type },
          queryType: selectedField.queryType,
          value: this.prepareValue(filter),
        };
      });
  }

  public setFilterCard(filterCard: FilterCardComponent): void {
    advancedFilterFilterCardSubject.next(filterCard);
  }

  public unsetFilterCard(): void {
    this.filterCard$ = null;
  }

  public updateTable(): void {
    if (!this.filterCard$) {
      throw new Error('FilterCard is not set!');
    }

    this.filterCard$.onUpdateButtonClick();
  }

  public getSearchString(tableQuery: ITableQuery, params?: HttpParams): HttpParams {
    let httpParams: HttpParams = _.cloneDeep(params) ?? new HttpParams();

    if (tableQuery.advancedFilter) {
      const advancedFilter = tableQuery.advancedFilter.filters;

      for (const filter of advancedFilter) {
        switch (filter.type) {
          case FieldTypes.predefined:
            httpParams = this.generateQueryForPredefined(httpParams, filter, tableQuery);
            break;
          case FieldTypes.dateFormat:
            httpParams = this.generateQueryForDateFormat(httpParams, filter, tableQuery);
            break;
          default:
            httpParams = this.generateQueryForDefault(httpParams, filter, tableQuery);
            break;
        }
      }
    }

    return httpParams;
  }

  private generateQueryForPredefined(
    httpParams: HttpParams,
    filter: IFilterOutput,
    tableQuery: ITableQuery,
  ): HttpParams {
    if (!tableQuery?.advancedFilter) {
      return httpParams;
    }

    const filterValue: string = _.get(filter.value, `[0][${filter.searchBy}]`, '');
    const param: string | void = this.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      filterValue,
    );

    if (param) {
      if (['logbook_scope_id', 'form_scope_id'].includes(filter.path) && Number(filterValue) < 0) {
        return httpParams.append(
          'logbook_scope_id' === filter.path ? 'is_all_logbook_scope' : 'is_all_form_scope',
          filter.operator.name === SqlOperators.exact,
        );
      }

      if (
        ['logbook_activity_type', 'form_activity_type'].includes(filter.path) &&
        [SqlOperators.isNull, SqlOperators.isNotNull].includes(filter.operator.name)
      ) {
        return httpParams.append(param, true);
      }

      if (filter.operator.name === SqlOperators.isNull || filter.operator.name === SqlOperators.isNotNull) {
        return httpParams.append(param, true);
      }

      return httpParams.append(param, filter.value?.[0]?.id);
    }

    return httpParams;
  }

  private generateQueryForDefault(httpParams: HttpParams, filter: IFilterOutput, tableQuery: ITableQuery): HttpParams {
    if (!tableQuery?.advancedFilter) {
      return httpParams;
    }

    const param: string | void = this.generateQuery(
      filter.path,
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      _.get(filter.value, `[0][${filter.searchBy}]`, ''),
    );

    if (param) {
      if (filter.operator.name === SqlOperators.isBlank || filter.operator.name === SqlOperators.isNotBlank) {
        return httpParams.append(param, true);
      }

      return httpParams.append(param, filter.value);
    }

    return httpParams;
  }

  private generateQueryForDateFormat(
    httpParams: HttpParams,
    filter: IFilterOutput,
    tableQuery: ITableQuery,
  ): HttpParams {
    if (!tableQuery?.advancedFilter) {
      return httpParams;
    }

    let params: HttpParams = _.cloneDeep(httpParams);
    let param: string | void = this.generateQuery(
      filter.path.split('-')[0],
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      _.get(filter.value, `[0][${filter.searchBy}]`, '').split('|')[0],
    );

    if (param) {
      params = params.append(param, filter.value[0].id.split('|')[0]);
    }

    param = this.generateQuery(
      filter.path.split('-')[1],
      filter.type,
      filter.operator.name,
      filter.operator.type,
      tableQuery.advancedFilter.target,
      _.get(filter.value, `[0][${filter.searchBy}]`, '').split('|')[1],
    );

    if (param) {
      params = params.append(param, filter.value[0].id.split('|')[1]);
    }

    return params;
  }
}
