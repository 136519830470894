import { IFormTemplatesState } from './form-templates.model';
import * as Actions from './form-templates.actions';
import { Action } from '@ngrx/store';

export const formTemplateState: IFormTemplatesState = {
  getAllMasterDataLoading: false,
  getAllMasterDataLoaded: false,
  allMasterDataData: null,
  tableSettings: [],
};

export function formTemplatesReducer(
  state: IFormTemplatesState = formTemplateState,
  baseAction: Action,
): IFormTemplatesState {
  const action = baseAction as Actions.FormTemplateActions;

  switch (action.type) {
    case Actions.GET_ALL_MASTER_DATA:
      return {
        ...state,
        getAllMasterDataLoading: true,
        getAllMasterDataLoaded: false,
      };
    case Actions.GET_ALL_MASTER_DATA_COMPLETED:
      return {
        ...state,
        getAllMasterDataLoading: false,
        getAllMasterDataLoaded: true,
        allMasterDataData: action.payload.data,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
