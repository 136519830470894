import { createAction, props } from '@ngrx/store';
import {
  ISavablePageFilters,
  TReadSavedFilterProps,
} from '../../../shared/service/filter/savable-filter/savable-filter.type';
import { IResponse } from '../../../shared/model/interface/generic-api-response.model';

export const writeSavableFilter = createAction(
  '[SavableFilter] Write Savable Filter',
  props<{ filterData: ISavablePageFilters } & { isUpdate?: boolean }>(),
);
export const writeSavableFilterSuccess = createAction(
  '[SavableFilter] Write Savable Filter Success',
  props<IResponse<ISavablePageFilters>>(),
);
export const writeSavableFilterFailure = createAction('[SavableFilter] Write Savable Filter Failure', props<any>());

export const readSavableFilter = createAction('[SavableFilter] Read Savable Filter', props<TReadSavedFilterProps>());
export const readSavableFilterSuccess = createAction(
  '[SavableFilter] Read Savable Filter Success',
  props<IResponse<ISavablePageFilters>>(),
);
export const readSavableFilterFailure = createAction('[SavableFilter] Read Savable Filter Failure', props<any>());
