import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import { PageConfigurationService } from './page-configuration.service';
import * as ObjectActions from './page-configuration.actions';
import * as AppActions from '../app/actions';
import { of, switchMap } from 'rxjs';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IConfigurationFormDropdown, IPageConfiguration } from './page-configuration.model';
import { catchError } from 'rxjs/operators';
import { IClientPageConfiguration } from '../../view/settings/logs-table-configuration/logs-table-configuration.model';

@Injectable()
export class PageConfigurationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly pageConfigurationService: PageConfigurationService,
  ) {}

  loadUserHomePageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.USER_HOME_PAGE_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.UserHomePageConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadUserHomePageConfiguration(payload.request).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(new ObjectActions.UserHomePageConfigurationLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addUserHomePageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_USER_HOME_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.AddUserHomePageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService.addUserHomePageConfiguration(payload.request).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(new ObjectActions.AddUserHomePageConfigurationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  updateUserHomePageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_USER_HOME_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.UpdateUserHomePageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService.updateUserHomePageConfiguration(payload.request).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(
              new ObjectActions.UpdateUserHomePageConfigurationCompleted(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  loadClientHomeConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CLIENT_PAGE_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.ClientPageConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadClientHomeConfiguration().pipe(
          switchMap((response: IGetManyResponse<IClientPageConfiguration>) => {
            return of(new ObjectActions.ClientPageConfigurationLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteClientHomeConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_CLIENT_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.DeleteClientPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.deleteClientHomeConfiguration(payload.request).pipe(
          switchMap((response: IGetManyResponse<IClientPageConfiguration>) => {
            return of(new ObjectActions.DeleteClientPageConfigurationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  loadSelectedLogbookFormData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SELECTED_LOGBOOK_FORM_DATA_LOADING),
      switchMap((payload: ObjectActions.SelectedLogbookFormDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadSelectedLogbookForms(payload.formIds, payload.parentLogbookIds).pipe(
          switchMap((response: IGetManyResponse<IConfigurationFormDropdown>) => {
            return of(
              new ObjectActions.SelectedLogbookFormDataLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addClientPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_CLIENT_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.AddClientPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService.addClientPageConfiguration(payload.request).pipe(
          switchMap((response: IGetManyResponse<IClientPageConfiguration>) => {
            return of(new ObjectActions.AddClientPageConfigurationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editClientPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_CLIENT_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.EditClientPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService.editClientPageConfiguration(payload.request).pipe(
          switchMap((response: IGetManyResponse<IClientPageConfiguration>) => {
            return of(new ObjectActions.EditClientPageConfigurationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  loadUserFormSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.UserFormSettingsPageConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadUserFormSettingsPageConfiguration(payload.userId).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(new ObjectActions.UserFormSettingsPageConfigurationLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  loadUserLogbookSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.UserLogbookSettingsPageConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadUserLogbookSettingsPageConfiguration(payload.userId).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(
              new ObjectActions.UserLogbookSettingsPageConfigurationLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  loadUserAllLogbookVersionSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADING),
      switchMap((payload: ObjectActions.UserAllLogbookVersionSettingsPageConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.pageConfigurationService.loadUserAllLogbookVersionSettingsPageConfiguration(payload.userId).pipe(
          switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
            return of(
              new ObjectActions.UserAllLogbookVersionSettingsPageConfigurationLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addUserFormSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_USER_FORM_SETTINGS_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.AddUserFormSettingsPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService
          .addUserFormSettingsPageConfiguration(payload.request, payload.isUpdate)
          .pipe(
            switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
              return of(
                new ObjectActions.AddUserFormSettingsPageConfigurationCompleted(response),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addUserLogbookSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.AddUserLogbookSettingsPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService
          .addUserLogbookSettingsPageConfiguration(payload.request, payload.isUpdate)
          .pipe(
            switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
              return of(
                new ObjectActions.AddUserLogbookSettingsPageConfigurationCompleted(response),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addUserALLLogbookVersionSettingsPageConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION),
      switchMap((payload: ObjectActions.AddUserAllLogbookVersionSettingsPageConfiguration) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.pageConfigurationService
          .addUserALLLogbookVersionSettingsPageConfiguration(payload.request, payload.isUpdate)
          .pipe(
            switchMap((response: IBaseOneResponse<IPageConfiguration>) => {
              return of(
                new ObjectActions.AddUserAllLogbookVersionSettingsPageConfigurationCompleted(response),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
