import { Directive, ElementRef, HostListener } from '@angular/core';
import * as screenfull from 'screenfull';

@Directive({
  selector: '[appToggleFullScreen]',
})
export class ToggleFullScreenDirective {
  constructor(private elements: ElementRef) {}

  @HostListener('click')
  onClick(): void {
    const targetElement: HTMLElement | null = document.getElementById(this.elements.nativeElement.target);

    if (!screenfull.isEnabled) {
      return;
    }

    if (targetElement === null) {
      this.elements.nativeElement.querySelector('.fas')?.classList?.toggle('fa-expand-arrows-alt');
      this.elements.nativeElement.querySelector('.fas')?.classList?.toggle('fa-compress-arrows-alt');
      screenfull.toggle();
    } else if (screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      screenfull.request(targetElement);
    }
  }
}
