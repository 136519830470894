import * as moment from 'moment-timezone';
import { MomentDateFormat } from './date.constants';

moment.tz.setDefault('utc');

export const mysqlTimestampFormat = 'YYYY-MM-DD HH:mm:ss';
export const mysqlDateFormat = 'YYYY-MM-DD';
export const iosTimestampFormat = 'YYYY/MM/DD HH:mm:ss';
export const generalDateTimeFormat: string = 'MMM d, yyyy h:mm aa';
export const monthAndHourAmPmFormat: string = 'MMM D, h:mm A';
export const monthAnd24HourFormat: string = 'MMM D, H:mm';
export const defaultDateFormat: string = 'dd/MM/yyyy';
export const monthNameDateFormat: string = 'DD MMM YYYY';
export const momentWeekFormat: string = 'GGGG/WW';
export const momentMonthFormat: string = 'YYYY/MM';

export function formatDate(date: Date) {
  let result = '';
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    result = `${year}-${month}-${day}`;
  }
  return result;
}

export function dateStringToDateObject(datetime: unknown) {
  let result:any;
  if (datetime !== null && datetime !== '' && datetime !== undefined) {
    if (datetime instanceof Date) {
      result = datetime;
    } else if (typeof  datetime === 'string') {
      result = new Date(datetime.replace(/T/g, ' ').replace(/-/g, '/'));
    }
  } else {
    result = '';
  }
  return result;
}

export function replaceDateStringForValidator(dateString: string) {
  if (typeof dateString !== 'string') {
    return '';
  }

  return dateString.replace(/T/g, ' ')
    .replace(/-/g, '/')
    .substring(0, 19);
}

export function getCurrentDateTime(timeZone: string): Date {
  const formattedMoment = moment().tz(timeZone).format(mysqlTimestampFormat);
  if (typeof formattedMoment === 'string') {
    return dateStringToDateObject(formattedMoment);
  }
  return new Date(formattedMoment);
}

export function getCurrentDateTimeWithoutTimeZoneString(): string {
  const formattedMoment = moment().format(MomentDateFormat.TIME_24H);
  if (typeof formattedMoment === 'string') {
    return formattedMoment;
  }
  return '';
}

export function getCurrentDateTimeAsMoment(timeZone: string): moment.Moment {
  return moment().tz(timeZone);
}
