import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScwMatInputComponent } from './scw-mat-input.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule],
  exports: [ScwMatInputComponent],
  declarations: [ScwMatInputComponent],
})
export class ScwMatInputModule {}
