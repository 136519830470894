import { environment } from '../environments/environment';

export function getApiUrl(): string {
    return environment.apiUrl;
}

export function formatDurationFromSecond(duration: number): string {
  let secondDuration = duration;
  let negative = '';

  if (secondDuration < 0) {
    secondDuration = secondDuration * -1;
    negative = '-';
  }

  const hours = Math.floor(secondDuration / 3600);
  const minutes = Math.floor((secondDuration - hours * 3600) / 60);
  const seconds = parseInt((secondDuration - hours * 3600 - minutes * 60).toFixed(0), 10);

  let hoursDt = hours.toString();

  if (hours < 10) {
    hoursDt = `0${hoursDt}`;
  }

  let minutesDt = minutes.toString();

  if (minutes < 10) {
    minutesDt = `0${minutesDt}`;
  }

  let secondsDt = seconds.toString();

  if (seconds < 10) {
    secondsDt = `0${secondsDt}`;
  }

  return `${negative + hoursDt}:${minutesDt}:${secondsDt}`;
}
