<mat-form-field class="no-line" floatLabel="never" [class.block]="block" [class.no-padding]="noPadding">
  <label *ngIf="label" class="input-label">{{ label }}</label>
  <input
    matInput
    class="scw-mat-input"
    autocomplete="{{ autocompleteAttribute ?? 'off' }}"
    [class.has-errors]="hasErrors"
    [class.scw-mat-password-input]="type === 'password' && !hideEyeIcon"
    [class.input-xs]="size === 'xs'"
    [class.input-sm]="size === 'sm'"
    [class.input-md]="size === 'md'"
    [class.input-lg]="size === 'lg'"
    [type]="manageableType"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [placeholder]="placeholder || ''"
    [ngClass]="className"
    [(ngModel)]="inputModel"
    (keyup)="onKeyup.emit()"
    (keyup.enter)="onKeyupEnter.emit()"
    (ngModelChange)="onNgModelChange()"
    [ngModelOptions]="{ standalone: true }"
  />
  <div class="eye-icon-button" *ngIf="type === 'password' && !hideEyeIcon">
    <em
      class="eye-icon fas"
      [class.fa-eye]="manageableType === 'text'"
      [class.fa-eye-slash]="manageableType === 'password'"
      [class.eye-icon-disabled]="disabled"
      (click)="onClickEyeIcon()"
    ></em>
  </div>
  <ng-template [ngIf]="errorText || hint">
    <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
      <em
        class="sub-text-icon fas"
        [class.fa-exclamation-circle]="errorText"
        [class.fa-info-circle]="!errorText && hint"
      ></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
      <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
    </mat-hint>
  </ng-template>
</mat-form-field>
