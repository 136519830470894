import { Action } from '@ngrx/store';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IFieldVersion, IFormTemplateTableQuery } from '../../../view/reports/form-templates/form-templates.model';
import { ITableHeader } from '../../../../constants.model';
import { IFormVersionHistory } from '../../forms/forms.model';
import { IFormMasterDataDetail } from '../../settings/form-master-data/form-master-data.model';
import { IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import {
  ILogbookTemplateData,
  ILogbookTemplateTableQuery,
} from '../../../view/reports/logbook-templates/logbook-templates.model';
import { IGetLogbookTemplates } from './logbook-templates.model';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { IDateRangeReturn } from '../../../shared/component/date-range-picker/date-range-picker.model';

export const GET_ALL_MASTER_DATA = '[LOGBOOK TEMPLATES] GET ALL MASTER DATA';
export const GET_ALL_MASTER_DATA_COMPLETED = '[LOGBOOK TEMPLATES] GET ALL MASTER DATA COMPLETED';
export const GET_LOGBOOK_TEMPLATES = '[LOGBOOK TEMPLATES] GET ALL LOGBOOK TEMPLATES';
export const GET_LOGBOOK_TEMPLATES_COMPLETED = '[LOGBOOK TEMPLATES] GET ALL LOGBOOK TEMPLATES COMPLETED';
export const SET_TABLE_SETTINGS = '[LOGBOOK TEMPLATES] LOGBOOK TEMPLATES TABLE SETTINGS SET';
export const SET_SELECTED_FILTERS = '[LOGBOOK TEMPLATES] SET SELECTED FILTERS';
export const DOWNLOAD_LOGBOOK_TEMPLATES_EXCEL = '[LOGBOOK TEMPLATES] DOWNLOAD LOGBOOK TEMPLATES';
export const DOWNLOAD_LOGBOOK_TEMPLATES_EXCEL_COMPLETED = '[LOGBOOK TEMPLATES] DOWNLOAD LOGBOOK TEMPLATES COMPLETED';
export const GET_MASER_DATA = '[LOGBOOK TEMPLATES] GET MASTER DATA';
export const GET_MASER_DATA_LOADED = '[LOGBOOK TEMPLATES] GET MASTER DATA LOADED';
export const GET_LOGBOOK_TEMPLATE_DATA_LOADING = '[LOGBOOK TEMPLATES] GET LOGBOOK TEMPLATE DATA LOADING';
export const GET_LOGBOOK_TEMPLATE_DATA_LOADED = '[LOGBOOK TEMPLATES] GET LOGBOOK TEMPLATE DATA LOADED';
export const GET_LOGBOOK_TEMPLATE_HISTORY = '[LOGBOOK TEMPLATES] GET LOGBOOK TEMPLATE HISTORY LOADING';
export const GET_LOGBOOK_TEMPLATE_HISTORY_LOADED = '[LOGBOOK TEMPLATES] GET LOGBOOK TEMPLATE HISTORY LOADED';
export const WORKFLOW_STEP_DATA_LOADING = '[LOGBOOK TEMPLATES] WORKFLOW STEP DATA LOAD';
export const WORKFLOW_STEP_DATA_LOADED = '[LOGBOOK TEMPLATES] WORKFLOW STEP DATA LOADED';
export const FETCH_ERROR = '[LOGBOOK TEMPLATES] FETCH ERROR';
export const GET_ALL_MASTER_DATA_COMPLETED_TO_ZIP = '[LOGBOOK TEMPLATES] GET ALL MASTER DATA COMPLETED TO ZIP';
export const GET_LOGBOOK_TEMPLATES_COMPLETED_TO_ZIP = '[LOGBOOK TEMPLATES] GET LOGBOOK TEMPLATES COMPLETED TO ZIP';

export class GetAllMasterData implements Action {
  readonly type = GET_ALL_MASTER_DATA;

  constructor(
    public formIds: number[] | null,
    public logbookIds: number[] | null,
    public dispatchHideLoader: boolean = true,
    public sourceObjectDate: IDateRangeReturn | null,
  ) {}
}

export class GetAllMasterDataCompleted implements Action {
  readonly type = GET_ALL_MASTER_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<IFieldVersion>) {}
}

export class GetLogbookTemplates implements Action {
  readonly type = GET_LOGBOOK_TEMPLATES;
  constructor(public tableQuery: ILogbookTemplateTableQuery, public dispatchHideLoader: boolean = true) {}
}

export class GetLogbookTemplatesCompleted implements Action {
  readonly type = GET_LOGBOOK_TEMPLATES_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbookTemplateData>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IFormTemplateTableQuery) {}
}

export class DownloadLogbookTemplatesExcel implements Action {
  readonly type = DOWNLOAD_LOGBOOK_TEMPLATES_EXCEL;

  constructor(
    public filters: IFormTemplateTableQuery,
    public columns: ITableHeader[],
    public approvalStatuses: IGenericObject<string>,
    public rawMasterDataColumns: ITableHeader[],
  ) {}
}

export class DownloadLogbookTemplatesExcelCompleted implements Action {
  readonly type = DOWNLOAD_LOGBOOK_TEMPLATES_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export class GetMasterDataLoading implements Action {
  readonly type = GET_MASER_DATA;

  constructor(public id: number) {}
}

export class GetMasterDataLoaded implements Action {
  readonly type = GET_MASER_DATA_LOADED;

  constructor(public payload: IBaseOneResponse<IFormMasterDataDetail>) {}
}

export class GetLogbookTemplatesData implements Action {
  readonly type = GET_LOGBOOK_TEMPLATE_DATA_LOADING;

  constructor(public id: number) {}
}

export class GetLogbookTemplatesDataLoaded implements Action {
  readonly type = GET_LOGBOOK_TEMPLATE_DATA_LOADED;

  constructor(public payload: IGetLogbookTemplates) {}
}

export class GetLogbookTemplatesHistoryLoading implements Action {
  readonly type = GET_LOGBOOK_TEMPLATE_HISTORY;

  constructor(public id: number) {}
}

export class GetLogbookTemplatesHistoryLoaded implements Action {
  readonly type = GET_LOGBOOK_TEMPLATE_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormVersionHistory>) {}
}

export class GetWorkflowStepData implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADING;

  constructor(public id: number) {}
}

export class GetWorkflowStepDataLoaded implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflowStepsData>) {}
}

export class GetAllMasterDataCompletedToZip implements Action {
  readonly type = GET_ALL_MASTER_DATA_COMPLETED_TO_ZIP;
}

export class GetLogbookTemplatesCompletedToZip implements Action {
  readonly type = GET_LOGBOOK_TEMPLATES_COMPLETED_TO_ZIP;
}

export type LogbookTemplateActions =
  | GetAllMasterData
  | GetAllMasterDataCompleted
  | GetLogbookTemplates
  | GetLogbookTemplatesCompleted
  | SetTableSettings
  | DownloadLogbookTemplatesExcel
  | DownloadLogbookTemplatesExcelCompleted
  | FetchError
  | GetMasterDataLoading
  | GetMasterDataLoaded
  | GetLogbookTemplatesData
  | GetLogbookTemplatesDataLoaded
  | GetLogbookTemplatesHistoryLoading
  | GetLogbookTemplatesHistoryLoaded
  | GetWorkflowStepData
  | GetWorkflowStepDataLoaded
  | GetAllMasterDataCompletedToZip
  | GetLogbookTemplatesCompletedToZip;
