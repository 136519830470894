<input
  type="text"
  class="{{ cls }} fixed-height"
  name="search"
  maxlength="{{ maxLength }}"
  [(ngModel)]="searchBoxNgModel"
  placeholder="{{ placeHolder }}"
  (ngModelChange)="getFiltersOutputs()"
  [disabled]="isDisabled"
/>
