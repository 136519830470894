import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BorderColors, TColorInput } from './scw-mat-border-coloring.model';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'scw-mat-border-coloring',
  templateUrl: './scw-mat-border-coloring.component.html',
  styleUrls: ['./scw-mat-border-coloring.component.scss'],
})
export class ScwMatBorderColoringComponent {
  @Input() type: TColorInput = BorderColors.active;
  @Input() text!: string;
  @Input() statusViewButton: boolean = false;
  @Input() placement: PlacementArray = 'auto';
  @ViewChild('popOverDummy', { static: true }) popOverDummyTemplate!: TemplateRef<any>;
  @Input() popContent: TemplateRef<any> = this.popOverDummyTemplate;
  @Input() popTitle: string = '';
  @Output() viewButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  public readonly BorderColors = BorderColors;

  public statusViewButtonClickAction(): void {
    this.viewButtonClicked.emit();
  }
}
