import { Inject, Injectable } from '@angular/core';
import { IFilterService, IUserCRUDResponse, IUserFilterDataResponse } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUsersFilterData } from '../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class UserService implements IFilterService {
  constructor(private http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly routes: string = 'users/filter_options';

  getUsers(page: number, limit: number, search?: string): Promise<IUsersFilterData[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', page);
    params = params.set('limit', String(limit));
    if (search) {
      params = params.set('search', String(search));
    }
    return this.getData(params);
  }

  public getData(params?: HttpParams): Promise<IUsersFilterData[]> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      this.http.get(`${this.baseUrl}/${this.routes}`, { params }).subscribe((response: IUserFilterDataResponse) => {
        if (response.hasOwnProperty('data')) {
          const result: IUsersFilterData[] = response.data;
          for (let i = 0; i < result.length; i = i + 1) {
            const row: IUsersFilterData = result[i];
            result[i].fullName = row.fullName;
          }
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }
}
