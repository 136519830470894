import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { IAuditTrail } from 'src/app/view/audit-trail/audit-trail.model';
import { IBulkResponseRecord, IGetManyResponse } from '../../model/interface/crud-response-interface.model';
import { IFormSubmissionUserAction, ITableHeader } from '../../../../constants.model';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { CellTypes, ExcelHelperService, ICreateExcel, IExcelColumnDefinition } from '../excel/excel-helper.service';
import { ValueType } from 'exceljs';
import * as AuditTrailActions from '../../../store/audit-trail/audit-trail.actions';
import { ILogsFormEntriesColumn } from '../../../view/my-tasks/logs-form-entries/logs-form-entries.component.model';
import { IFormEntriesData } from '../../../store/reports/form-entries/form-entries.model';
import { HelperService } from '../helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../model/enum/excel-date-format';
import { IFormVersionLite } from '../../../store/my-tasks/logbook-tasks/logbook-tasks.model';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService {
  public timezone$: string = 'utc';
  public dateFormat$!: string;
  public timeFormat$!: string;

  private readonly LOGS = {
    LOG_URL: `${this.baseUrl}/logs/`,
  };
  private readonly LOGBOOK_VERSIONS = {
    ALL: '/form-versions/all',
  };
  private readonly SCOPE = {
    LOGBOOKS: '/scopes/logbooks/',
    FORMS: '/scopes/forms/',
  };
  private readonly LOGS_FORM_ENTRIES = {
    USER_ACTIONS_FORM_SUBMISSONS: '/user-actions/form-submission-history/',
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: false,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: true,
    },
  };

  constructor(
    public http: HttpClient,
    public readonly excelHelperService: ExcelHelperService,
    public readonly helperService: HelperService,
    private readonly translate: TranslateService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone$ = state.timezone ?? 'utc';

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getAuditTrails(params: Record<string, string | number>): Observable<IGetManyResponse<IAuditTrail>> {
    return this.http.post<IGetManyResponse<IAuditTrail>>(
      this.LOGS.LOG_URL,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  private getReportObservables(params: Record<string, string | number>) {
    const observables: Observable<
      any | IGetManyResponse<ILogsFormEntriesColumn> | IGetManyResponse<IFormEntriesData>
    >[] = [this.getAuditTrails(params)];
    return observables;
  }

  public async downloadReportExcel(
    params: Record<string, string | number>,
    selectedColumns: ITableHeader[],
  ): Promise<void> {
    forkJoin(this.getReportObservables(params)).subscribe((responseList) => {
      const sheetTitle: string = this.translate.instant('pageTitles.audit-trail');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone$).format(this.dateFormat$)}`;
      let excelData: any[] = [];

      excelData = _.get(responseList, '0.data', []) as any[];
      excelData = this.makeHumanReadable(excelData, selectedColumns);

      const excelOptions: ICreateExcel = this.getReportExcelColumns(selectedColumns);
      excelOptions.data = excelData;
      this.excelHelperService
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          true,
          this.timezone$,
          this.dateFormat$,
          this.timeFormat$,
          false,
          false,
          true,
          1001,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new AuditTrailActions.DownloadReportExcelCompleted());
          },
          () => {
            this.store.dispatch(new AuditTrailActions.DownloadReportExcelCompleted());
          },
        );
    });
  }
  private makeHumanReadable(records: any[], auditLogsColumns: ITableHeader[]): any[] {
    for (const row of records) {
      auditLogsColumns.forEach(() => {
        this.makeColumnReadable(row);
      });

      if (typeof moment(row.createdAt).isValid()) {
        row.createdAt = this.formatDate(row.createdAt);
      }
    }
    return records;
  }
  private formatDate(inputDate: string): string {
    return HelperService.formatDateTimeWithLineBreak(inputDate);
  }
  private makeColumnReadable(row: any): any {
    return row;
  }

  private getReportExcelColumns(selectedColumns: ITableHeader[]): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [],
    };
    for (const column of selectedColumns) {
      if (['actions'].indexOf(column.value) === -1) {
        const newColumn = {
          ...(this.commonExcelAttributes as IExcelColumnDefinition),
          header: column.name,
          key: column.value,
          width: 20,
        };

        excelColumns.columns.push(newColumn);
      }
    }

    return excelColumns;
  }

  public getSelectedForms(entryIds: number[]): Observable<IBulkResponseRecord<IFormVersionLite>> {
    const url = `${this.baseUrl}${this.LOGBOOK_VERSIONS.ALL}`;
    return this.http.post<IBulkResponseRecord<IFormVersionLite>>(url, {
      payload: {
        entry_ids: entryIds,
      },
    });
  }

  public getScopeLogbook(entryIds: number[]): Observable<IBulkResponseRecord<IFormVersionLite>> {
    const url = `${this.baseUrl}${this.SCOPE.LOGBOOKS}`;
    return this.http.post<IBulkResponseRecord<IFormVersionLite>>(url, {
      payload: {
        entry_ids: entryIds,
      },
    });
  }

  public getScopeForm(entryIds: number[]): Observable<IBulkResponseRecord<IFormVersionLite>> {
    const url = `${this.baseUrl}${this.SCOPE.FORMS}`;
    return this.http.post<IBulkResponseRecord<IFormVersionLite>>(url, {
      payload: {
        entryIds,
      },
    });
  }

  public getLogsFormEntriesHistory(
    id: number,
    historyId: number,
  ): Observable<IGetManyResponse<IFormSubmissionUserAction>> {
    return this.http.get<IGetManyResponse<IFormSubmissionUserAction>>(
      `${this.baseUrl}${this.LOGS_FORM_ENTRIES.USER_ACTIONS_FORM_SUBMISSONS}${id}/${historyId}`,
    );
  }
}
