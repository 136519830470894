import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalTextWithIconComponent } from './scw-mat-modal-text-with-icon.component';

@NgModule({
  declarations: [ScwMatModalTextWithIconComponent],
  exports: [ScwMatModalTextWithIconComponent],
  imports: [CommonModule],
})
export class ScwMatModalTextWithIconModule {}
