import { ISelect, ScwMatSelectRule } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';

export interface IGenericForm {
  isEnabled: boolean;
}

export interface ISelectForm extends IGenericForm {
  value: ISelect<number, string>[];
  rules?: ScwMatSelectRule[];
}

export interface IStringInputForm extends IGenericForm {
  value: string;
  rules?: ScwMatInputRule[];
}

export interface INumberInputForm extends IGenericForm {
  value: number;
  rules?: ScwMatInputRule[];
}

export interface IForm {
  [key: string]: ISelectForm | IStringInputForm | INumberInputForm;
}

export interface IFormField<ValueType, RuleType> {
  isEnabled: boolean;
  value: ValueType;
  rules: RuleType[];
  defaultValue?: ValueType;
}

export const defaultFormValue = { isEnabled: true, value: null, rules: [] };
export const defaultFormString = { isEnabled: true, value: '', rules: [] };
