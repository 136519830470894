import { Action } from '@ngrx/store';
import { IDefaultFilters, IFilter } from '../../shared/component/filter/advanced-filter/advanced-filter.model';

export const SAVE_FILTER_CONFIG = '[ADVANCED_FILTER] SAVE FILTER CONFIG';
export const SAVE_FILTER_CONFIG_COMPLETED = '[ADVANCED_FILTER] SAVE FILTER CONFIG COMPLETED';
export const SET_DEFAULT_FILTERS = '[ADVANCED_FILTER] SET DEFAULT FILTERS';
export const DISTRIBUTE_FILTERS = '[ADVANCED_FILTER] DISTRIBUTE FILTERS';

export class SaveFilterConfig implements Action {
  public readonly type = SAVE_FILTER_CONFIG;

  constructor(public userId: string, public pageName: string, public filters: IFilter[]) {}
}

export class SaveFilterConfigCompleted implements Action {
  public readonly type = SAVE_FILTER_CONFIG_COMPLETED;

  constructor(public payload: IDefaultFilters) {}
}

export class SetDefaultFilters implements Action {
  public readonly type = SET_DEFAULT_FILTERS;

  constructor(public payload: IDefaultFilters) {}
}

export class DistributeFilters implements Action {
  public readonly type = DISTRIBUTE_FILTERS;

  constructor(public filters: IFilter[]) {}
}

export type AdvancedFilterActions =
  | SaveFilterConfig
  | SaveFilterConfigCompleted
  | SetDefaultFilters
  | DistributeFilters;
