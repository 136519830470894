import { Action } from '@ngrx/store';
import {
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { IAuditTrail } from '../../view/audit-trail/audit-trail.model';
import { IAuditTrailTableQuery } from './audit-trail.model';
import { ITableQuery } from '../../shared/model/interface/common-page.model';
import { IFormSubmissionUserAction, ITableHeader } from '../../../constants.model';
import { IFormVersionLite } from '../my-tasks/logbook-tasks/logbook-tasks.model';


export const AUDIT_TRAIL_DATA_LOADING = '[AUDIT_TRAIL] DATA LOADING';
export const AUDIT_TRAIL_DATA_LOADED = '[AUDIT_TRAIL] DATA LOADED';
export const AUDIT_TRAIL_SELECTED_FORMS_LOADING = '[AUDIT_TRAIL] SELECTED FORMS LOADING';
export const AUDIT_TRAIL_SELECTED_FORMS_LOADED = '[AUDIT_TRAIL] SELECTED FORMS LOADED';
export const AUDIT_TRAIL_SCOPE_LOGBOOK_LOADING = '[AUDIT_TRAIL] SCOPE LOGBOOK LOADING';
export const AUDIT_TRAIL_SCOPE_LOGBOOK_LOADED = '[AUDIT_TRAIL] SCOPE LOGBOOK LOADED';
export const AUDIT_TRAIL_SCOPE_FORM_LOADING = '[AUDIT_TRAIL] SCOPE FORM LOADING';
export const AUDIT_TRAIL_SCOPE_FORM_LOADED = '[AUDIT_TRAIL] SCOPE FORM SUBMISSON HISTORY LOADED';
export const AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADING = '[AUDIT_TRAIL] FORM SUBMISSON HISTORY LOADING';
export const AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADED = '[AUDIT_TRAIL] FORM SUBMISSON HISTORY LOADED';
export const AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT = '[AUDIT_TRAIL] DOWNLOAD EXCEL REPORT';
export const AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT_COMPLETED = '[AUDIT_TRAIL] DATA LOADED COMPLETED';
export const CLEAR = '[AUDIT_TRAIL] CLEAR';
export const FETCH_ERROR = '[AUDIT_TRAIL] FETCH ERROR';

export class AuditTrailDataLoading implements Action {
  readonly type = AUDIT_TRAIL_DATA_LOADING;
  constructor(public tableQuery: IAuditTrailTableQuery) {
  }
}

export class AuditTrailDataLoaded implements Action {
  readonly type = AUDIT_TRAIL_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IAuditTrail>) {}
}

export class AuditTrailSelectedFormsLoading implements Action {
  readonly type = AUDIT_TRAIL_SELECTED_FORMS_LOADING;
  constructor(public rowId: number, public entry_ids: number[]) {}
}

export class AuditTrailSelectedFormsLoaded implements Action {
  readonly type = AUDIT_TRAIL_SELECTED_FORMS_LOADED;
  constructor(public rowId: number, public payload: IBulkResponseRecord<IFormVersionLite>) {}
}

export class AuditTrailScopeLogbookLoading implements Action {
  readonly type = AUDIT_TRAIL_SCOPE_LOGBOOK_LOADING;
  constructor(public rowId: number, public entry_ids: number[]) {}
}

export class AuditTrailScopeLogbookLoaded implements Action {
  readonly type = AUDIT_TRAIL_SCOPE_LOGBOOK_LOADED;
  constructor(public rowId: number, public payload: IBulkResponseRecord<IFormVersionLite>) {}
}

export class AuditTrailScopeFormLoading implements Action {
  readonly type = AUDIT_TRAIL_SCOPE_FORM_LOADING;
  constructor(public rowId: number, public entry_ids: number[]) {}
}

export class AuditTrailScopeFormLoaded implements Action {
  readonly type = AUDIT_TRAIL_SCOPE_FORM_LOADED;
  constructor(public rowId: number, public payload: IBulkResponseRecord<IFormVersionLite>) {}
}

export class AuditTrailFormSubmissionHistoryLoading implements Action {
  readonly type = AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADING;
  constructor(public formSubmissionId: number, public historyRowId: number) {}
}

export class AuditTrailFormSubmissionHistoryLoaded implements Action {
  readonly type = AUDIT_TRAIL_FORM_SUBMISSION_HISTORY_LOADED;
  constructor(public rowId: number, public payload: IBulkResponseRecord<IFormSubmissionUserAction>) {}
}

export class DownloadReportExcel implements Action {
  readonly type = AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT;

  constructor(
    public tableQuery: ITableQuery,
    public selectedColumns: ITableHeader[],
  ) {}
}

export class DownloadReportExcelCompleted implements Action {
  readonly type = AUDIT_TRAIL_DOWNLOAD_EXCEL_REPORT_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export class Clear implements Action {
  readonly type = CLEAR;
}

export type AuditTrailActions =
  | AuditTrailDataLoading
  | AuditTrailDataLoaded
  | DownloadReportExcel
  | DownloadReportExcelCompleted
  | AuditTrailSelectedFormsLoading
  | AuditTrailSelectedFormsLoaded
  | AuditTrailScopeLogbookLoading
  | AuditTrailScopeLogbookLoaded
  | AuditTrailScopeFormLoading
  | AuditTrailScopeFormLoaded
  | AuditTrailFormSubmissionHistoryLoading
  | AuditTrailFormSubmissionHistoryLoaded
  | FetchError
  | Clear;
