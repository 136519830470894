import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScwMatTextareaComponent } from './scw-mat-textarea.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule],
  exports: [ScwMatTextareaComponent],
  declarations: [ScwMatTextareaComponent],
})
export class ScwMatTextareaModule {}
