import { Subject } from 'rxjs';
import { IFilterCondition } from '../../../../store/filter/filter.model';
import { FilterDataObjectTypes } from '../filter.class';

export interface IDropdownSettings extends ICommonSettings {
  singleSelection?: boolean;
  enableCheckAll?: boolean;
  selectAllText?: string;
  unSelectAllText?: string;
  filterSelectAllText?: string;
  filterUnSelectAllText?: string;
  enableFilterSelectAll?: boolean;
  enableSearchFilter?: boolean;
  searchBy?: string[];
  maxHeight?: number;
  badgeShowLimit?: number;
  classes?: string;
  limitSelection?: number;
  searchPlaceholderText?: string;
  groupBy?: string;
  showCheckbox?: boolean;
  noDataLabel?: string;
  searchAutofocus?: boolean;
  lazyLoading?: boolean;
  lazyLoadingLimit?: number;
  labelKey?: string;
  primaryKey?: string;
  position?: string;
  loading?: boolean;
  selectGroup?: boolean;
  addNewItemOnFilter?: boolean;
  addNewButtonText?: string;
  escapeToClose?: boolean;
  autoPosition?: boolean;
  clearAll?: boolean;
  clearSearchOnOpen?: boolean;
  selectAll?: boolean;
  defaultSelection?: IDefaultSelection;
  enableServerSideSearch?: boolean;
  searchProps?: IFilterCondition[];
  server?: ApiServer;
  tagToBody?: boolean;
  searchMinimumCharacter?: number;
}

export interface ICommonSettings {
  isRequired: boolean;
  disabled?: boolean;
  text?: string;
}

export interface IDefaultSelection {
  key: string;
  values: (string | number)[];
  initialDataToAddIntoDropdown?: FilterDataObjectTypes[];
  overrideData?: boolean;
  initialData?: FilterDataObjectTypes[];
}

export enum ApiServer {
  Phreeze = 'phreeze',
  NestJS = 'nest',
  Django = 'django',
}

export type DependencyType =
  | {
    elementId: string;
    elementIdKey: string;
    searchBy: string;
    splitBy?: string;
    serverSideFilter?: true;
    server: ApiServer;
    isDataLocatedAtParent?: boolean;
    dataPathAtParent?: string;
  }
  | {
    elementId: string;
    elementIdKey: string;
    searchBy: string;
    splitBy?: string;
    serverSideFilter?: false | null;
    server?: never;
    isDataLocatedAtParent?: boolean;
    dataPathAtParent?: string;
  };

export interface DropdownFilterConfiguration {
  elementId: string;
  filteredElementId: string;
  filterListener: Subject<any>;
  submit: boolean;
  filterData?: any;
  isDataLocatedAtParent?: boolean;
  dataPathAtParent?: string;
}

export interface ILazyLoading {
  startIndex: number;
  endIndex: number;
  scrollStartPosition: number;
  scrollEndPosition: number;
  startIndexWithBuffer: number;
  endIndexWithBuffer: number;
}
