<ng-template #bulk_action_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ bulkActionHeader }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ bulkActionText }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="onBulkActionConfirm(bulkActionType)">
        {{ bulkActionConfirmButtonText }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
