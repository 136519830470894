import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, from, of } from 'rxjs';
import { FilterUserActionTypes, FilterUserActions, FilterUsersLoaded, StartLoadFilterUsers } from './user.actions';
import { UserService } from '../../../shared/service/filter/user.service';
import { DefaultQueryParameters } from '../../../../constants';

@Injectable()
export class FilterUserEffects {
  constructor(private actions$: Actions<FilterUserActions>, public service: UserService) {
  }
  loadFilterUsers$ = createEffect(() => this.actions$.pipe(
    ofType(FilterUserActionTypes.START_LOAD_FILTER_USERS),
    switchMap((objectData: StartLoadFilterUsers) => {

      if (typeof objectData.page === 'undefined') {
        objectData.page = 1;
      }
      if (typeof objectData.limit === 'undefined') {
        objectData.limit = DefaultQueryParameters.LIMIT;
      }

      return from(
        this.service.getUsers(objectData.page, objectData.limit, objectData.search),
      ).pipe(
        switchMap((response) => {
          return of(new FilterUsersLoaded(response));
        }),
        catchError(() => {
          return of(new FilterUsersLoaded([]));
        }),
      );
    }),
  ));
}
