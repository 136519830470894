import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import {
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { catchError, map, of, switchMap } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { LogbookTasksService } from '../../../shared/service/my-tasks/logbook-tasks/logbook-tasks.service';
import * as ObjectActions from './logbook-tasks.actions';
import { IFieldSetData, IFormVersionLite, ILogbookTasksData, ILogbookVersion } from './logbook-tasks.model';
import { ILogbookTasksHistory } from '../../../view/my-tasks/logbook-tasks/logbook-tasks.component.model';
import { IReviewResults } from '../my-tasks.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import { IWorkflow } from '../../settings/workflows/workflows.model';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class LogbookTasksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: LogbookTasksService,
    private readonly helperService: HelperService,
    private readonly workflowService: WorkflowsService,
  ) {}

  getReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);

        return this.service.getReviewData(body, payload.reviewType).pipe(
          map((response: IGetManyResponse<ILogbookTasksData>): IGetManyResponse<ILogbookTasksData> => {
            return {
              ...response,
              data: response.data.map((row: ILogbookTasksData): ILogbookTasksData => {
                return {
                  ...row,
                  stateDurationFormatted: row.stateDuration
                    ? `${row.stateDuration} ${
                        ComponentUtilities.findOneOptionForSelect(
                          this.helperService.getTimeType(),
                          row.stateDurationType,
                        )[0].name
                      }`
                    : null,
                };
              }),
            };
          }),
          switchMap((response: IGetManyResponse<ILogbookTasksData>) => {
            return of(new ObjectActions.LogbookTasksLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksBeforeRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_BEFORE_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksBeforeRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksActiveRecord(payload.logbookId).pipe(
          switchMap((response: IGetOneResponse<ILogbookVersion>) => {
            return of(new ObjectActions.LogbookTasksBeforeRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksAfterRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_AFTER_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksAfterRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksRecord(payload.logbookId, payload.id).pipe(
          switchMap((response: IGetOneResponse<ILogbookVersion>) => {
            return of(new ObjectActions.LogbookTasksAfterRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksBeforeFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksBeforeFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(new ObjectActions.LogbookTasksBeforeFieldSetLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksAfterFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_AFTER_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksAfterFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(new ObjectActions.LogbookTasksAfterFieldSetLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksHistoryForOneVersion(payload.id, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<ILogbookTasksHistory>) => {
            return of(new ObjectActions.LogbookTasksHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksActiveVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksActiveVersionHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksHistory(payload.id, true, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<ILogbookTasksHistory>) => {
            return of(new ObjectActions.LogbookTasksActiveVersionHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksCurrentVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksCurrentVersionHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookTasksHistory(payload.id, false, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<ILogbookTasksHistory>) => {
            return of(new ObjectActions.LogbookTasksCurrentVersionHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookTasksSelectedForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOGBOOK_TASKS_SELECTED_FORMS_LOADING),
      switchMap((payload: ObjectActions.StartLogbookTasksSelectedFormsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getSelectedForms(payload.entry_ids, payload.getOnlyActiveForms).pipe(
          switchMap((response: IBulkResponseRecord<IFormVersionLite>) => {
            return of(new ObjectActions.LogbookTasksSelectedFormsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  reviewFormEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_APPROVE_LOADING),
      switchMap((payload: ObjectActions.StartEntryApproveLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryApproveLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectPermanentEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_PERMANENT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectPermanentLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectPermanentLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().append('limit', 1000).append('just_workflow_data', true);

        return this.workflowService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.WorkflowDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
