<mat-checkbox
  [ngClass]="hasErrors ? 'mat-checkbox-error' : ''"
  [(ngModel)]="inputModel"
  [disabled]="disabled"
  [id]="id"
  [labelPosition]="labelPosition"
  [name]="name"
  [required]="required"
  [value]="value"
  (change)="onChangeEmitter($event)"
  (ngModelChange)="onNgModelChange()"
>
  <ng-content></ng-content>
</mat-checkbox>
<ng-template [ngIf]="errorText">
  <mat-hint [class.error]="errorText">
    <em class="sub-text-icon fas" [class.fa-exclamation-circle]="errorText"></em>
    <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
  </mat-hint>
</ng-template>
