import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { IValidationError } from '../model/interface/crud-response-interface.model';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(public translate: TranslateService) {}
  public getTranslatedErrorMessage(data: any[]): void {
    try {
      data.forEach((message) => {
        if (message.success || !message.errors) {
          return;
        }

        const messages: string[] = [];

        Object.entries(message.errors).forEach(([key, errors]) => {
          const errorMessage: IValidationError = ErrorMessageService.formatErrorMessage(key, errors, message);
          this.appendApiError(errorMessage, Object.keys(errorMessage.constraints), messages);
        });

        _.set(message, 'message', messages);
      });
    } catch (error) {
      if (error instanceof TypeError) {
        return;
      }

      throw error;
    }
  }

  public getOption(errorKey: string, errorMessage: IValidationError): string {
    if (errorKey === 'whitelistValidation') {
      const whitelistReason = Object.keys(_.get(errorMessage, `contexts.${errorKey}`, {}))[0] || null;
      const whitelistReasonKey = `apiErrorMessages.${whitelistReason}`;

      return whitelistReason ? `${this.translate.instant(whitelistReasonKey)} ` : '';
    }

    const compareProperty = _.get(errorMessage, `contexts.${errorKey}.compareProperty`, false);

    if (compareProperty) {
      return this.translate.instant(`apiErrorMessages.properties.${compareProperty}`);
    }

    const translateOption = _.get(errorMessage, `contexts.${errorKey}.translateOption`, false);

    if (translateOption) {
      return this.translate.instant(`apiErrorMessages.translateOption.${translateOption}`);
    }

    return _.get(errorMessage, `contexts.${errorKey}.option]`, '');
  }

  public appendApiError(errorMessage: IValidationError, errorKeys: string[], messages: string[]): void {
    errorKeys.forEach((errorKey) => {
      this.translate.get(`apiErrorMessages.properties.${errorMessage.property}`).subscribe((property: string) => {
        this.translate
          .get(`apiErrorMessages.${errorKey}`, {
            property,
            option: this.getOption(errorKey, errorMessage),
          })
          .subscribe((translatedErrorMessage: string) => {
            messages.push(translatedErrorMessage);
          });
      });
    });
  }

  public additionalOptions(errorKey: string, errorMessage: IValidationError): { [key: string]: string } {
    const additionalOptions = {};
    for (const [key, value] of Object.entries(_.get(errorMessage, `contexts.${errorKey}`, {}))) {
      const translateString = `apiErrorMessages.additionalOptions.${errorKey}.${key}`;
      const translatedResult = this.translate.instant(translateString);

      _.set(additionalOptions, key, translateString === translatedResult ? value : translatedResult);
    }

    return additionalOptions;
  }

  public static formatErrorMessage(key: string, errors: any, data: any): IValidationError {
    return {
      children: [],
      constraints: errors.reduce((filtered: any, error: any) => {
        return { ...filtered, [_.camelCase(error.code)]: error.string };
      }, {}),
      property: key,
      value: data[key] ?? null,
    };
  }

  public static formatApiErrorMessages(baseErrors: any[]): IValidationError[] | void {
    const formattedApiErrorMessages: IValidationError[] = [];
    const errors: any[] = HelperService.shallowSnakeCaseToCamelCaseMapper(baseErrors);

    Object.entries(errors).forEach(([key, err]) => {
      formattedApiErrorMessages.push(ErrorMessageService.formatErrorMessage(key, err, {}));
    });

    return formattedApiErrorMessages;
  }
}
