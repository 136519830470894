import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-mat-checkbox-group',
  templateUrl: './scw-mat-checkbox-group.component.html',
  styleUrls: ['./scw-mat-checkbox-group.component.scss'],
})
export class ScwMatCheckboxGroupComponent implements OnInit {
  @Input() checkboxes: any[] = [];
  @Input() disabled: boolean = false;
  @Input() inputModel: any[] = [];
  @Input() required: boolean = false;
  @Input() hasErrors: boolean = false;
  @Input() errorText: string | null = null;
  @Output() onChange: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
  @Output() inputModelChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor(private readonly translate: TranslateService) {}

  public reset(): void {
    this.inputModel = this.inputModel.map((item) => {
      return {
        label: item.label,
        value: false,
      };
    });
    this.clearErrorMessage();
  }

  public clearErrorMessage(): void {
    this.hasErrors = false;
    this.errorText = null;
  }

  public showErrorMessage(message: string): void {
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public checkRules(): void {
    if (!this.inputModel.some((item) => item.value) && this.required) {
      this.showErrorMessage(this.errorText ?? this.translate.instant('scwMatForm.validation.required'));
    } else {
      this.clearErrorMessage();
    }
  }

  public onChangeEmitter($event: MatCheckboxChange): void {
    this.onChange.emit($event);
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }

  public ngOnInit(): void {
    let tempInputModel: any[] = [];

    this.checkboxes.forEach((checkbox, index: number) => {
      let found = false;

      for (const inputModel of this.inputModel) {
        if (inputModel['id'] === checkbox['id']) {
          found = true;

          tempInputModel.push({
            id: checkbox.id,
            value: inputModel ? inputModel.value : false,
            label: this.checkboxes[index].label,
          });

          break;
        }
      }

      if (!found) {
        tempInputModel.push({
          id: checkbox.id,
          value: false,
          label: this.checkboxes[index].label,
        });
      }
    });

    this.inputModel = tempInputModel;
  }
}
