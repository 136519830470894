import { Action } from '@ngrx/store';
import { IResponse } from '../../../shared/model/interface/generic-api-response.model';
import { IClient, IClientSettings, IUploadResponse } from './client.model';
import { IFile } from '../../../view/settings/client/client.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export const GET_CLIENT_SETTINGS = '[CLIENT] Get client settings';
export const GET_CLIENT_SETTINGS_COMPLETED = '[CLIENT] Get client settings completed';
export const EDIT_CLIENT_SETTINGS = '[CLIENT] Edit client settings';
export const EDIT_CLIENT_SETTINGS_COMPLETED = '[CLIENT] Edit client settings completed';
export const CLIENT_LOGO_UPLOAD = '[CLIENT] Client logo uploaded';
export const CLIENT_LOGO_UPLOAD_COMPLETED = '[CLIENT] Client logo uploaded completed';
export const SET_CLIENT_INFO = '[CLIENT] Set client information';
export const SET_ASK_FOR_PIN_ON_ACTION = '[CLIENT] Set ask for pin on action';
export const SET_IS_PDF_ENHANCEMENT = '[CLIENT] Set is pdf enhancement';
export const FETCH_ERROR = '[CLIENT] Fetch Error';

export class GetClientSettings implements Action {
  readonly type = GET_CLIENT_SETTINGS;
}

export class GetClientSettingsCompleted implements Action {
  readonly type = GET_CLIENT_SETTINGS_COMPLETED;
  constructor(public payload: IClient) {}
}

export class EditClientSettings implements Action {
  readonly type = EDIT_CLIENT_SETTINGS;
  constructor(public payload: IClientSettings, public issuer: IIssuer | null) {}
}

export class EditClientSettingsCompleted implements Action {
  readonly type = EDIT_CLIENT_SETTINGS_COMPLETED;
  constructor(public payload: IResponse<IClientSettings>) {}
}

export class UploadLogo implements Action {
  readonly type = CLIENT_LOGO_UPLOAD;
  constructor(public payload: IFile, public issuerAndReason: IIssuerAndReason) {}
}

export class UploadLogoCompleted implements Action {
  readonly type = CLIENT_LOGO_UPLOAD_COMPLETED;
  constructor(public payload: IResponse<IUploadResponse>, public issuerAndReason: IIssuerAndReason) {}
}

export class SetClientInfo implements Action {
  readonly type = SET_CLIENT_INFO;

  constructor(public payload: Partial<IClientSettings>) {}
}

export class SetAskForPinOnAction implements Action {
  readonly type = SET_ASK_FOR_PIN_ON_ACTION;

  constructor(public payload: boolean) {}
}

export class SetIsPdfEnhancement implements Action {
  readonly type = SET_IS_PDF_ENHANCEMENT;

  constructor(public payload: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type ClientActions =
  | EditClientSettings
  | EditClientSettingsCompleted
  | GetClientSettings
  | GetClientSettingsCompleted
  | UploadLogo
  | UploadLogoCompleted
  | SetClientInfo
  | SetAskForPinOnAction
  | SetIsPdfEnhancement
  | FetchError;
