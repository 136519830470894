import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { ScwMatDropdownMenuItem } from '../../shared/component/scw-mat-ui/scw-mat-dropdown/scw-mat-dropdown.model';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss'],
})
export class ClientSelectionComponent implements OnInit {
  private url: string;

  public clientOptions: ScwMatDropdownMenuItem[] = [];

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router) {
    this.url = `${environment.ssoUrl}/client-selection`;
  }

  async ngOnInit() {
    this.http
      .get<any>(this.url)
      .pipe()
      .subscribe((resData: IResponse<ScwMatDropdownMenuItem[]>) => {
        this.clientOptions = resData.data;
      },
        );
  }

  public async onClickDropdown(key: string): Promise<void> {
    this.http
      .post<any>(this.url + '/' + key + '/', {})
      .pipe()
      .subscribe(() => {
        this.router.navigate(['/home'], { relativeTo: this.activatedRoute, queryParams: {} });
      });
  }
}
