import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import { IRight } from './rights.model';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  private readonly RIGHTS = { RIGHTS_URL: `${this.baseUrl}/rights/` };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getRights(params: HttpParams): Observable<IGetManyResponse<IRight>> {
    return this.http.get<IGetManyResponse<IRight>>(this.RIGHTS.RIGHTS_URL, { params });
  }
}
