<div [class.card]="isCard">
  <div [class.card-block]="isCard">
    <div class="row">
      <div class="col-md-{{ mainGridWide }}">
        <div #row1 class="row"></div>
        <div #row2 class="row"></div>
      </div>
      <div class="col-md-{{ additionalGridWide }}">
        <ng-content select="[additionalFields]"></ng-content>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="enableFilterUpdateCard">
  <app-filter-update
    [invalid]="invalidUpdate"
    [message]="updateMessage || ''"
    [enableSetAsDefault]="enableSetAsDefault"
    [showSaveFilterSettingsButton]="showSaveFilterSettingsButton"
    [autoApply]="filterAutoApply"
    (clickedUpdateButtonEvent)="onUpdateButtonClick()"
    (clickedSetAsDefaultButtonEvent)="onSetAsDefaultButtonClick()"
    (clickedSaveFilterSettingsButtonEvent)="onSaveFilterSettingsButtonClick()"
    [hidden]="!showUpdateCard"
  ></app-filter-update>
</ng-container>
