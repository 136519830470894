import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import * as ObjectActions from './actions';

import * as AppActions from '../app/actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions) {}

  fetchError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FETCH_ERROR),
      map(() => {
        return new AppActions.HideLoader();
      }),
    ),
  );
}
