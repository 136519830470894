import { Component, EventEmitter, OnDestroy, Output, ViewChild, ViewChildDecorator } from '@angular/core';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TBulkActions } from '../../service/bulk-action/bulk-action.model';

@OnDestroyDecorator
@Component({
  selector: 'bulk-action-confirmation-modal',
  templateUrl: './bulk-action-confirmation-modal.component.html',
  styleUrls: [],
})
export class BulkActionConfirmationModalComponent implements OnDestroy {
  @ViewChild('bulk_action_confirmation_modal') bulkActionConfirmationModalTemplateRef!: ViewChildDecorator;
  @Output() onBulkActionConfirmation: EventEmitter<TBulkActions> = new EventEmitter<TBulkActions>();

  private readonly scwModalSm: string = 'scw-modal-sm';

  protected bulkActionHeader!: string;
  protected bulkActionText!: string;
  protected bulkActionConfirmButtonText!: string;
  protected bulkActionType!: TBulkActions;

  protected bulkActionConfirmationModalRef!: NgbModalRef;

  public constructor(private readonly modal: NgbModal) {}

  public showModal(
    bulkActionHeader: string,
    bulkActionText: string,
    bulkActionConfirmButtonText: string,
    bulkActionType: TBulkActions,
  ): void {
    this.bulkActionHeader = bulkActionHeader;
    this.bulkActionText = bulkActionText;
    this.bulkActionConfirmButtonText = bulkActionConfirmButtonText;
    this.bulkActionType = bulkActionType;

    this.bulkActionConfirmationModalRef = this.modal.open(this.bulkActionConfirmationModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  public onBulkActionConfirm(bulkActionType: TBulkActions): void {
    this.onBulkActionConfirmation.emit(bulkActionType);
    this.bulkActionConfirmationModalRef?.close();
  }

  public ngOnDestroy(): void {
    this.bulkActionConfirmationModalRef?.close();
  }
}
