<ng-template #issuer_modal let-modal>
  <scw-mat-form (scwFormSubmit)="submit($event)" [shouldAutoComplete]="true">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'modal.header.userAuthentication' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            [autocompleteAttribute]="'issuerUsername'"
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.username.label' | translate }}*"
            [rules]="issuerRules.username"
            [(inputModel)]="issuerForm.username.value"
          ></scw-mat-input>
        </div>
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            [autocompleteAttribute]="'issuerPassword'"
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.pin.label' | translate }}*"
            type="password"
            [rules]="issuerRules.pin"
            [(inputModel)]="issuerForm.pin.value"
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button
          type="standard"
          (window:keydown.escape)="closeModal()"
          (onClick)="closeModal()"
          [disabled]="isDisabled"
        >
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button
          id="submitButton"
          [isSubmitButton]="true"
          (window:keydown.enter)="pressEnter()"
          [disabled]="isDisabled"
        >
          {{ 'general.ok' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
<scw-pin-change (issuerSubmitButtonDisabled)="changeButtonDisable($event)"> </scw-pin-change>
