import { ILogbookTasksState } from './logbook-tasks.model';
import { Action } from '@ngrx/store';
import * as Actions from './logbook-tasks.actions';
import * as _ from 'lodash';

export const logbookTasksInitialState: ILogbookTasksState = {
  LogbookTasksLoading: false,
  LogbookTasksLoaded: false,
  LogbookTasksData: {
    success: false,
    data: [],
    page: undefined,
    pageCount: undefined,
    count: undefined,
    total: undefined,
  },
  LogbookTasksBeforeRecordLoading: false,
  LogbookTasksBeforeRecordLoaded: false,
  LogbookTasksBeforeRecord: null,
  LogbookTasksAfterRecordLoading: false,
  LogbookTasksAfterRecordLoaded: false,
  LogbookTasksAfterRecord: null,
  LogbookTasksBeforeFieldSetLoading: false,
  LogbookTasksBeforeFieldSetLoaded: false,
  LogbookTasksBeforeFieldset: [],
  LogbookTasksAfterFieldSetLoading: false,
  LogbookTasksAfterFieldSetLoaded: false,
  LogbookTasksAfterFieldset: [],
  LogbookTasksHistoryDataLoading: false,
  LogbookTasksHistoryDataLoaded: false,
  LogbookTasksHistoryData: null,
  LogbookTasksActiveVersionHistoryDataLoading: false,
  LogbookTasksActiveVersionHistoryDataLoaded: false,
  LogbookTasksActiveVersionHistoryData: null,
  LogbookTasksCurrentVersionHistoryDataLoading: false,
  LogbookTasksCurrentVersionHistoryDataLoaded: false,
  LogbookTasksCurrentVersionHistoryData: null,
  LogbookTasksSelectedFormsLoading: false,
  LogbookTasksSelectedFormsLoaded: false,
  LogbookTasksSelectedFormsData: null,
  LogbookTasksStartWorkflowLoading: false,
  LogbookTasksWorkflowLoaded: false,
  LogbookTasksWorkflowData: null,
  LogbookTasksReviewDataLoading: false,
  LogbookTasksReviewDataLoaded: false,
  LogbookTasksReviewData: null,
  tableSettings: [],
  errors: [],
};

export function logbookTasksReducer(state: ILogbookTasksState = logbookTasksInitialState, baseAction: Action) {
  const action = baseAction as Actions.LogbookTasksActions;

  switch (action.type) {
    case Actions.START_LOGBOOK_TASKS_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksLoading: true,
          LogbookTasksLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksData: { ...action.payload },
          LogbookTasksLoading: false,
          LogbookTasksLoaded: true,
        },
      };
    case Actions.START_LOGBOOK_TASKS_BEFORE_RECORD_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksBeforeRecordLoading: true,
          LogbookTasksBeforeRecordLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_BEFORE_RECORD_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksBeforeRecordLoading: false,
          LogbookTasksBeforeRecordLoaded: true,
          LogbookTasksBeforeRecord: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_AFTER_RECORD_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksAfterRecordLoading: true,
          LogbookTasksAfterRecordLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_AFTER_RECORD_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksAfterRecordLoading: false,
          LogbookTasksAfterRecordLoaded: true,
          LogbookTasksAfterRecord: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksBeforeFieldSetLoading: true,
          LogbookTasksBeforeFieldSetLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksBeforeFieldSetLoading: false,
          LogbookTasksBeforeFieldSetLoaded: true,
          LogbookTasksBeforeFieldset: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_AFTER_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksAfterFieldSetLoading: true,
          LogbookTasksAfterFieldSetLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_AFTER_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksAfterFieldSetLoading: false,
          LogbookTasksAfterFieldSetLoaded: true,
          LogbookTasksAfterFieldset: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_HISTORY_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksHistoryDataLoading: true,
          LogbookTasksHistoryDataLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_HISTORY_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksHistoryDataLoading: false,
          LogbookTasksHistoryDataLoaded: true,
          LogbookTasksHistoryData: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksActiveVersionHistoryDataLoading: true,
          LogbookTasksActiveVersionHistoryDataLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksActiveVersionHistoryDataLoading: false,
          LogbookTasksActiveVersionHistoryDataLoaded: true,
          LogbookTasksActiveVersionHistoryData: { ...action.payload },
        },
      };

    case Actions.START_LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksCurrentVersionHistoryDataLoading: true,
          LogbookTasksCurrentVersionHistoryDataLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksCurrentVersionHistoryDataLoading: false,
          LogbookTasksCurrentVersionHistoryDataLoaded: true,
          LogbookTasksCurrentVersionHistoryData: { ...action.payload },
        },
      };
    case Actions.START_LOGBOOK_TASKS_SELECTED_FORMS_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksSelectedFormsLoading: true,
          LogbookTasksSelectedFormsLoaded: false,
        },
      };
    case Actions.LOGBOOK_TASKS_SELECTED_FORMS_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksSelectedFormsLoading: false,
          LogbookTasksSelectedFormsLoaded: true,
          LogbookTasksSelectedFormsData: { ...action.payload },
        },
      };
      case Actions.LOGBOOK_TASKS_SELECTED_FORMS_CLEAR:
      return {
        ...state,
        ...{
          LogbookTasksSelectedFormsLoading: false,
          LogbookTasksSelectedFormsLoaded: false,
          LogbookTasksSelectedFormsData: null,
        },
      };
    case Actions.START_ENTRY_APPROVE_LOADING:
    case Actions.START_ENTRY_REJECT_PERMANENT_LOADING:
    case Actions.START_ENTRY_REJECT_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksReviewData: null,
          LogbookTasksReviewDataLoading: true,
          LogbookTasksReviewDataLoaded: false,
        },
      };
    case Actions.ENTRY_APPROVE_LOADED:
    case Actions.ENTRY_REJECT_PERMANENT_LOADED:
    case Actions.ENTRY_REJECT_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksReviewData: { ...action.payload.data },
          LogbookTasksReviewDataLoading: false,
          LogbookTasksReviewDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.CLEAR_STATE:
      return { ...logbookTasksInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };
    case Actions.START_WORKFLOW_DATA_LOADING:
      return {
        ...state,
        ...{
          LogbookTasksStartWorkflowLoading: true,
          LogbookTasksWorkflowLoaded: false,
        },
      };
    case Actions.WORKFLOW_DATA_LOADED:
      return {
        ...state,
        ...{
          LogbookTasksStartWorkflowLoading: false,
          LogbookTasksWorkflowLoaded: true,
          LogbookTasksStartWorkflowData: action.payload,
        },
      };
    default:
      return state;
  }
}
