import * as Actions from './advanced-filter.actions';
import { IAdvancedFilterStore } from './advanced-filter.model';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

export const advancedFilterInitialState: IAdvancedFilterStore = {
  isSaveFilterConfigLoading: false,
  isSaveFilterConfigLoaded: false,
  savedFilters: {},
  defaultFilters: {},
};

export const advancedFilterReducer = (state: IAdvancedFilterStore = advancedFilterInitialState, baseAction: Action) => {
  const action = baseAction as Actions.AdvancedFilterActions;

  switch (action.type) {
    case Actions.SAVE_FILTER_CONFIG:
      return {
        ...state,
        isDefaultFiltersLoading: true,
        isDefaultFiltersLoaded: false,
      };

    case Actions.SAVE_FILTER_CONFIG_COMPLETED:
      return {
        ...state,
        isDefaultFiltersLoading: false,
        isDefaultFiltersLoaded: true,
        savedFilters: _.cloneDeep(action.payload),
      };

    case Actions.SET_DEFAULT_FILTERS:
      return {
        ...state,
        defaultFilters: _.cloneDeep(action.payload),
      };

    default:
      return state;
  }
};
