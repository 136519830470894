import { Action } from '@ngrx/store';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IAllowedDomain } from '../main/main.model';

export const GET_ALLOWED_DOMAINS = '[FORGOT PIN] Get Allowed Domains';
export const GET_ALLOWED_DOMAINS_COMPLETED = '[FORGOT PIN] Get Allowed Domains Completed';
export const FORGOT_PIN = '[FORGOT PIN] Forgot Pin';
export const FORGOT_PIN_COMPLETED = '[FORGOT PIN] Forgot Pin Completed';
export const RESET_PIN = '[FORGOT PIN] Reset Pin';
export const RESET_PIN_COMPLETED = '[FORGOT PIN] Reset Pin Completed';
export const CHECK_VALIDITY = '[FORGOT PIN] Check Validity';
export const CHECK_VALIDITY_COMPLETED = '[FORGOT PIN] Check Validity Completed';
export const FETCH_ERROR = '[FORGOT PIN] Fetch Error';

export class GetAllowedDomains implements Action {
  readonly type = GET_ALLOWED_DOMAINS;
}

export class GetAllowedDomainsCompleted implements Action {
  readonly type = GET_ALLOWED_DOMAINS_COMPLETED;

  constructor(public payload: IAllowedDomain[]) {}
}

export class ForgotPin implements Action {
  readonly type = FORGOT_PIN;

  constructor(public mail: string) {}
}

export class ForgotPinCompleted implements Action {
  readonly type = FORGOT_PIN_COMPLETED;

  constructor(public payload: IResponse<any>) {}
}

export class ResetPin implements Action {
  readonly type = RESET_PIN;

  constructor(public userId: number, public pin: string) {}
}

export class ResetPinCompleted implements Action {
  readonly type = RESET_PIN_COMPLETED;

  constructor(public payload: IResponse<any>) {}
}

export class CheckValidity implements Action {
  readonly type = CHECK_VALIDITY;

  constructor(public userId: number, public pin: string) {}
}

export class CheckValidityCompleted implements Action {
  readonly type = CHECK_VALIDITY_COMPLETED;

  constructor(public payload: IResponse<any>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type ForgotPinActions =
  | GetAllowedDomains
  | GetAllowedDomainsCompleted
  | ForgotPin
  | ForgotPinCompleted
  | ResetPin
  | ResetPinCompleted
  | CheckValidity
  | CheckValidityCompleted
  | FetchError;
