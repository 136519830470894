import { Action } from '@ngrx/store';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import {
  IAddPageConfiguration,
  IConfigurationFormDropdown,
  IPageConfiguration,
  IPageConfigurationParams,
} from './page-configuration.model';
import {
  IClientPageConfiguration,
  IClientPageDeleteConfiguration,
} from '../../view/settings/logs-table-configuration/logs-table-configuration.model';

export const USER_HOME_PAGE_CONFIGURATION_LOADING = '[CONFIGURATION] USER HOME PAGE CONFIGURATION LOADING';
export const USER_HOME_PAGE_CONFIGURATION_LOADED = '[CONFIGURATION] USER HOME PAGE CONFIGURATION LOADED';
export const CLIENT_PAGE_CONFIGURATION_LOADING = '[CONFIGURATION] CLIENT PAGE CONFIGURATION LOADING';
export const CLIENT_PAGE_CONFIGURATION_LOADED = '[CONFIGURATION] CLIENT PAGE CONFIGURATION LOADED';
export const ADD_USER_HOME_PAGE_CONFIGURATION = '[CONFIGURATION] ADD USER HOME PAGE CONFIGURATION';
export const ADD_USER_HOME_PAGE_CONFIGURATION_COMPLETED = '[CONFIGURATION] ADD USER HOME PAGE CONFIGURATION COMPLETED';
export const UPDATE_USER_HOME_PAGE_CONFIGURATION = '[CONFIGURATION] UPDATE USER HOME PAGE CONFIGURATION';
export const UPDATE_USER_HOME_PAGE_CONFIGURATION_COMPLETED =
  '[CONFIGURATION] UPDATE USER HOME PAGE CONFIGURATION COMPLETED';
export const ADD_CLIENT_PAGE_CONFIGURATION = '[CONFIGURATION] ADD CLIENT PAGE CONFIGURATION';
export const ADD_CLIENT_PAGE_CONFIGURATION_COMPLETED = '[CONFIGURATION] ADD CLIENT PAGE CONFIGURATION COMPLETED';
export const EDIT_CLIENT_PAGE_CONFIGURATION = '[CONFIGURATION] EDIT CLIENT PAGE CONFIGURATION';
export const EDIT_CLIENT_PAGE_CONFIGURATION_COMPLETED = '[CONFIGURATION] EDIT CLIENT PAGE CONFIGURATION COMPLETED';
export const DELETE_CLIENT_PAGE_CONFIGURATION = '[CONFIGURATION] DELETE CLIENT PAGE CONFIGURATION';
export const DELETE_CLIENT_PAGE_CONFIGURATION_COMPLETED = '[CONFIGURATION] DELETE CLIENT PAGE CONFIGURATION COMPLETED';
export const SELECTED_LOGBOOK_FORM_DATA_LOADING = '[CONFIGURATION] SELECTED LOGBOOK DATA LOADING';
export const SELECTED_LOGBOOK_FORM_DATA_LOADED = '[CONFIGURATION] SELECTED LOGBOOK DATA LOADED';
export const USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADING =
  '[CONFIGURATION] USER FORM SETTINGS PAGE CONFIGURATION LOADING';
export const USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADED =
  '[CONFIGURATION] USER FORM SETTINGS PAGE CONFIGURATION LOADED';
export const USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADING =
  '[CONFIGURATION] USER LOGBOOK SETTINGS PAGE CONFIGURATION LOADING';
export const USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADED =
  '[CONFIGURATION] USER LOGBOOK SETTINGS PAGE CONFIGURATION LOADED';
export const USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADING =
  '[CONFIGURATION] USER ALL LOGBOOK VERSION SETTINGS PAGE CONFIGURATION LOADING';
export const USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADED =
  '[CONFIGURATION] USER ALL LOGBOOK VERSION SETTINGS PAGE CONFIGURATION LOADED';
export const ADD_USER_FORM_SETTINGS_PAGE_CONFIGURATION = '[CONFIGURATION] ADD USER FORM SETTINGS PAGE CONFIGURATION';
export const ADD_USER_FORM_SETTINGS_PAGE_CONFIGURATION_COMPLETED =
  '[CONFIGURATION] ADD USER FORM SETTINGS PAGE CONFIGURATION COMPLETED';
export const ADD_USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION =
  '[CONFIGURATION] ADD USER LOGBOOK SETTINGS PAGE CONFIGURATION';
export const ADD_USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_COMPLETED =
  '[CONFIGURATION] ADD USER LOGBOOK SETTINGS PAGE CONFIGURATION COMPLETED';
export const ADD_USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION =
  '[CONFIGURATION] ADD USER ALL LOGBOOK VERSION SETTINGS PAGE CONFIGURATION';
export const ADD_USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_COMPLETED =
  '[CONFIGURATION] ADD USER ALL LOGBOOK VERSION SETTINGS PAGE CONFIGURATION COMPLETED';
export const FETCH_ERROR = '[CONFIGURATION] FETCH ERROR';

export class UserHomePageConfigurationLoading implements Action {
  public readonly type = USER_HOME_PAGE_CONFIGURATION_LOADING;

  constructor(public request: IPageConfigurationParams) {}
}

export class UserHomePageConfigurationLoaded implements Action {
  public readonly type = USER_HOME_PAGE_CONFIGURATION_LOADED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class ClientPageConfigurationLoading implements Action {
  public readonly type = CLIENT_PAGE_CONFIGURATION_LOADING;

  constructor(public request?: IPageConfigurationParams) {}
}

export class ClientPageConfigurationLoaded implements Action {
  public readonly type = CLIENT_PAGE_CONFIGURATION_LOADED;

  constructor(public payload: IGetManyResponse<IClientPageConfiguration>) {}
}

export class AddUserHomePageConfiguration implements Action {
  public readonly type = ADD_USER_HOME_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration) {}
}

export class AddUserHomePageConfigurationCompleted implements Action {
  public readonly type = ADD_USER_HOME_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class UpdateUserHomePageConfiguration implements Action {
  public readonly type = UPDATE_USER_HOME_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration) {}
}

export class UpdateUserHomePageConfigurationCompleted implements Action {
  public readonly type = UPDATE_USER_HOME_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class AddClientPageConfiguration implements Action {
  public readonly type = ADD_CLIENT_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration) {}
}

export class AddClientPageConfigurationCompleted implements Action {
  public readonly type = ADD_CLIENT_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IGetManyResponse<IClientPageConfiguration>) {}
}

export class EditClientPageConfiguration implements Action {
  public readonly type = EDIT_CLIENT_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration) {}
}

export class EditClientPageConfigurationCompleted implements Action {
  public readonly type = EDIT_CLIENT_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IGetManyResponse<IClientPageConfiguration>) {}
}

export class DeleteClientPageConfiguration implements Action {
  public readonly type = DELETE_CLIENT_PAGE_CONFIGURATION;

  constructor(public request: IClientPageDeleteConfiguration) {}
}

export class DeleteClientPageConfigurationCompleted implements Action {
  public readonly type = DELETE_CLIENT_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IGetManyResponse<IClientPageConfiguration>) {}
}

export class SelectedLogbookFormDataLoading implements Action {
  public readonly type = SELECTED_LOGBOOK_FORM_DATA_LOADING;

  constructor(public formIds: number[], public parentLogbookIds: number[], public dispatchHideLoader: boolean = true) {}
}

export class SelectedLogbookFormDataLoaded implements Action {
  public readonly type = SELECTED_LOGBOOK_FORM_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IConfigurationFormDropdown>) {}
}

export class UserFormSettingsPageConfigurationLoading implements Action {
  public readonly type = USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADING;

  constructor(public userId: number) {}
}

export class UserFormSettingsPageConfigurationLoaded implements Action {
  public readonly type = USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class UserLogbookSettingsPageConfigurationLoading implements Action {
  public readonly type = USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADING;

  constructor(public userId: number) {}
}

export class UserLogbookSettingsPageConfigurationLoaded implements Action {
  public readonly type = USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class UserAllLogbookVersionSettingsPageConfigurationLoading implements Action {
  public readonly type = USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADING;

  constructor(public userId: number) {}
}

export class UserAllLogbookVersionSettingsPageConfigurationLoaded implements Action {
  public readonly type = USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class AddUserFormSettingsPageConfiguration implements Action {
  public readonly type = ADD_USER_FORM_SETTINGS_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration, public isUpdate: boolean) {}
}

export class AddUserFormSettingsPageConfigurationCompleted implements Action {
  public readonly type = ADD_USER_FORM_SETTINGS_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class AddUserLogbookSettingsPageConfiguration implements Action {
  public readonly type = ADD_USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration, public isUpdate: boolean) {}
}

export class AddUserLogbookSettingsPageConfigurationCompleted implements Action {
  public readonly type = ADD_USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class AddUserAllLogbookVersionSettingsPageConfiguration implements Action {
  public readonly type = ADD_USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION;

  constructor(public request: IAddPageConfiguration, public isUpdate: boolean) {}
}

export class AddUserAllLogbookVersionSettingsPageConfigurationCompleted implements Action {
  public readonly type = ADD_USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IPageConfiguration>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type PageConfigurationActions =
  | UserHomePageConfigurationLoading
  | UserHomePageConfigurationLoaded
  | ClientPageConfigurationLoading
  | ClientPageConfigurationLoaded
  | AddUserHomePageConfiguration
  | AddUserHomePageConfigurationCompleted
  | UpdateUserHomePageConfiguration
  | UpdateUserHomePageConfigurationCompleted
  | AddClientPageConfiguration
  | AddClientPageConfigurationCompleted
  | EditClientPageConfiguration
  | EditClientPageConfigurationCompleted
  | DeleteClientPageConfiguration
  | DeleteClientPageConfigurationCompleted
  | SelectedLogbookFormDataLoading
  | SelectedLogbookFormDataLoaded
  | UserFormSettingsPageConfigurationLoading
  | UserFormSettingsPageConfigurationLoaded
  | UserLogbookSettingsPageConfigurationLoading
  | UserLogbookSettingsPageConfigurationLoaded
  | UserAllLogbookVersionSettingsPageConfigurationLoading
  | UserAllLogbookVersionSettingsPageConfigurationLoaded
  | AddUserFormSettingsPageConfiguration
  | AddUserFormSettingsPageConfigurationCompleted
  | AddUserLogbookSettingsPageConfiguration
  | AddUserLogbookSettingsPageConfigurationCompleted
  | AddUserAllLogbookVersionSettingsPageConfiguration
  | AddUserAllLogbookVersionSettingsPageConfigurationCompleted
  | FetchError;
