import { Action } from '@ngrx/store';
import { IAddWorkflow, IWorkflow, IWorkflowSteps, IWorkflowTableQuery } from './workflows.model';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IAddEditWorkFlowStep, IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IRole } from '../../../view/settings/roles/roles.model';
import { IFormMasterData } from '../form-master-data/form-master-data.model';
import { ILogbookMasterData } from '../logbook-master-data/logbook-master-data.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export const WORKFLOWS_DATA_LOADING = '[WORKFLOWS] WORKFLOW DATA LOADING';
export const WORKFLOWS_STEPS_DATA_LOADING = '[WORKFLOWS] WORKFLOW STEPS DATA LOADING';
export const WORKFLOWS_STEPS_DATA_LOADED = '[WORKFLOWS] WORKFLOW STEPS DATA LOADED';
export const WORKFLOWS_DATA_LOADED = '[WORKFLOWS] WORKFLOW DATA LOADED';
export const ADD_WORKFLOW = '[WORKFLOWS] ADD WORKFLOW';
export const SAVE_WORKFLOW_STEPS = '[WORKFLOWS] ADD WORKFLOW STEPS';
export const SAVE_WORKFLOW_COMPLETED = '[WORKFLOWS] ADD WORKFLOW COMPLETED';
export const ADD_WORKFLOW_STEPS_COMPLETED = '[WORKFLOWS] ADD WORKFLOW STEPS COMPLETED';
export const EDIT_WORKFLOW = '[WORKFLOWS] EDIT WORKFLOW';
export const EDIT_WORKFLOW_STEPS = '[WORKFLOWS] EDIT WORKFLOW STEPS';
export const EDIT_WORKFLOW_COMPLETED = '[WORKFLOWS] EDIT WORKFLOW COMPLETED';
export const EDIT_WORKFLOW_STEPS_COMPLETED = '[WORKFLOWS] EDIT WORKFLOW STEPS COMPLETED';
export const DELETE_WORKFLOW_STEPS = '[WORKFLOWS] DELETE WORKFLOW STEPS';
export const DELETE_WORKFLOW_STEPS_COMPLETED = '[WORKFLOWS] DELETE WORKFLOW COMPLETED';
export const GET_ROLES_DATA_LOADING = '[WORKFLOWS] ROLES DATA LOADING';
export const GET_ROLES_DATA_LOADED = '[WORKFLOWS] ROLES DATA LOADED';
export const GET_FORM_MASTER_DATA_WORKFLOW_LOADING = '[WORKFLOWS] FORM MASTER DATA WORKFLOW LOADING';
export const GET_FORM_MASTER_DATA_WORKFLOW_LOADED = '[WORKFLOWS] FORM MASTER DATA WORKFLOW LOADED';
export const GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADING = '[WORKFLOWS] LOGBOOK MASTER DATA WORKFLOW LOADING';
export const GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADED = '[WORKFLOWS] LOGBOOK MASTER DATA WORKFLOW LOADED';

export const FETCH_ERROR = '[WORKFLOWS] FETCH ERROR';

export class WorkflowsDataLoading implements Action {
  readonly type = WORKFLOWS_DATA_LOADING;

  constructor(public tableQuery: IWorkflowTableQuery) {}
}

export class WorkflowsDataLoaded implements Action {
  readonly type = WORKFLOWS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export class WorkflowsStepsDataLoading implements Action {
  readonly type = WORKFLOWS_STEPS_DATA_LOADING;

  constructor(public id: number) {}
}

export class WorkflowsStepsDataLoaded implements Action {
  readonly type = WORKFLOWS_STEPS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflowStepsData>) {}
}

export class GetRolesDataLoading implements Action {
  readonly type = GET_ROLES_DATA_LOADING;
}

export class GetFormMasterDataWorkflowLoading implements Action {
  readonly type = GET_FORM_MASTER_DATA_WORKFLOW_LOADING;
}

export class GetRolesDataLoaded implements Action {
  readonly type = GET_ROLES_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IRole>) {}
}

export class GetFormMasterDataWorkflowLoaded implements Action {
  readonly type = GET_FORM_MASTER_DATA_WORKFLOW_LOADED;

  constructor(public payload: IGetManyResponse<IFormMasterData>) {}
}

export class GetLogbookMasterDataWorkflowLoading implements Action {
  readonly type = GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADING;
}

export class GetLogbookMasterDataWorkflowLoaded implements Action {
  readonly type = GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export class AddWorkflow implements Action {
  readonly type = ADD_WORKFLOW;

  constructor(public workflow: IAddWorkflow, public issuer: IIssuer | null) {}
}

export class SaveWorkflowSteps implements Action {
  readonly type = SAVE_WORKFLOW_STEPS;

  constructor(
    public workflowSteps: IAddEditWorkFlowStep[],
    public id: number,
    public issuerAndReason: IIssuerAndReason | null,
  ) {}
}

export class SaveWorkflowCompleted implements Action {
  readonly type = SAVE_WORKFLOW_COMPLETED;

  constructor(public payload: IBaseOneResponse<IWorkflow>) {}
}

export class AddWorkflowStepsCompleted implements Action {
  readonly type = ADD_WORKFLOW_STEPS_COMPLETED;

  constructor(public payload: IBaseOneResponse<IWorkflowStepsData>) {}
}

export class EditWorkflow implements Action {
  readonly type = EDIT_WORKFLOW;

  constructor(public workflow: IAddWorkflow, public id: number, public issuer: IIssuer | null) {}
}

export class EditWorkflowSteps implements Action {
  readonly type = EDIT_WORKFLOW_STEPS;

  constructor(
    public workflowSteps: IAddEditWorkFlowStep[],
    public id: number,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class EditWorkflowCompleted implements Action {
  readonly type = EDIT_WORKFLOW_COMPLETED;

  constructor(public payload: IBaseOneResponse<IWorkflow>) {}
}

export class EditWorkflowStepsCompleted implements Action {
  readonly type = EDIT_WORKFLOW_STEPS_COMPLETED;

  constructor(public payload: IBaseOneResponse<IWorkflowSteps>) {}
}

export class DeleteWorkflowSteps implements Action {
  readonly type = DELETE_WORKFLOW_STEPS;

  constructor(public workflowSteps: IWorkflowSteps, public id: number, public issuer: IIssuer | null) {}
}

export class DeleteWorkflowStepsCompleted implements Action {
  readonly type = DELETE_WORKFLOW_STEPS_COMPLETED;

  constructor(public payload: IBaseOneResponse<IWorkflowSteps>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type WorkflowsActions =
  | WorkflowsDataLoading
  | WorkflowsStepsDataLoading
  | WorkflowsDataLoaded
  | WorkflowsStepsDataLoaded
  | GetRolesDataLoading
  | GetRolesDataLoaded
  | GetFormMasterDataWorkflowLoading
  | GetFormMasterDataWorkflowLoaded
  | GetLogbookMasterDataWorkflowLoading
  | GetLogbookMasterDataWorkflowLoaded
  | AddWorkflow
  | SaveWorkflowCompleted
  | EditWorkflow
  | EditWorkflowCompleted
  | AddWorkflowStepsCompleted
  | SaveWorkflowSteps
  | EditWorkflowSteps
  | EditWorkflowStepsCompleted
  | DeleteWorkflowSteps
  | DeleteWorkflowStepsCompleted
  | FetchError;
