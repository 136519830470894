import { Action } from '@ngrx/store';
import {
  IUser,
  IUserTableQuery,
  IUserSettings,
  IUserAndRoleRightAssignments,
  IUserWithExcelDropdowns,
  IRightAssignmentsAndRights,
  IUserShortInfo,
} from './users.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ITableHeader } from '../../../../constants.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IRightAssignment } from '../roles/roles.model';

export const USER_SETTINGS_DATA_LOADING = '[USERS] USERS SETTINGS DATA LOADING';
export const USER_SETTINGS_DATA_LOADED = '[USERS] USERS SETTINGS DATA LOADED';
export const SET_TABLE_SETTINGS = '[USERS] USERS TABLE SETTINGS SET';
export const DOWNLOAD_USERS_EXCEL = '[USERS] DOWNLOAD USERS EXCEL';
export const DOWNLOAD_USERS_EXCEL_COMPLETED = '[USERS] DOWNLOAD USERS EXCEL COMPLETED';
export const DOWNLOAD_USERS_EXCEL_WITH_DATA_COMPLETED = '[USERS] DOWNLOAD USERS EXCEL WITH DATA COMPLETED';
export const DOWNLOAD_USERS_ERROR_EXCEL = '[USERS] DOWNLOAD USERS ERROR EXCEL';
export const DOWNLOAD_USERS_ERROR_EXCEL_COMPLETED = '[USERS] DOWNLOAD USERS ERROR EXCEL COMPLETED';
export const UPLOAD_USER_EXCEL = '[USERS] UPLOAD USERS EXCEL';
export const UPLOAD_USER_EXCEL_COMPLETED = '[USERS] UPLOAD USERS EXCEL COMPLETED';
export const ADD_USER = '[USERS] ADD USER';
export const ADD_USER_COMPLETED = '[USERS] ADD USER COMPLETED';
export const EDIT_USER = '[USERS] EDIT USER';
export const EDIT_USER_COMPLETED = '[USERS] EDIT USER COMPLETED';
export const PIN_CHANGE = '[USERS] PIN CHANGE';
export const PIN_CHANGE_COMPLETED = '[USERS] PIN CHANGE COMPLETED';
export const EDIT_USERS = '[USERS] EDIT USERS';
export const EDIT_USERS_COMPLETED = '[USERS] EDIT USERS COMPLETED';
export const SET_SELECTED_FILTERS = '[USERS] SET SELECTED FILTERS';
export const GET_RIGHT_ASSIGNMENTS = '[USERS] GET RIGHT ASSIGNMENTS';
export const GET_RIGHT_ASSIGNMENTS_COMPLETED = '[USERS] GET RIGHT ASSIGNMENTS COMPLETED';
export const GET_ROLE_RIGHT_ASSIGNMENTS = '[USERS] GET ROLE RIGHT ASSIGNMENTS';
export const GET_ROLE_RIGHT_ASSIGNMENTS_COMPLETED = '[USERS] GET ROLE RIGHT ASSIGNMENTS COMPLETED';
export const GET_USER_RIGHT_ASSIGNMENTS = '[USERS] GET USER RIGHT ASSIGNMENTS';
export const GET_USER_RIGHT_ASSIGNMENTS_COMPLETED = '[USERS] GET USER RIGHT ASSIGNMENTS COMPLETED';
export const SET_USER_RIGHT_ASSIGNMENTS = '[USERS] SET USER RIGHT ASSIGNMENTS';
export const SET_USER_RIGHT_ASSIGNMENTS_COMPLETED = '[USERS] SET USER RIGHT ASSIGNMENTS COMPLETED';
export const GET_USER_SHORT_INFORMATION = '[USERS] GET USER SHORT INFORMATION';
export const GET_USER_SHORT_INFORMATION_COMPLETED = '[USERS] GET USER SHORT INFORMATION COMPLETED';
export const FETCH_ERROR = '[USERS] FETCH USERS ERROR';

export class UserSettingsDataLoading implements Action {
  readonly type = USER_SETTINGS_DATA_LOADING;

  constructor(public tableQuery: IUserTableQuery) {}
}

export class UserSettingsDataLoaded implements Action {
  readonly type = USER_SETTINGS_DATA_LOADED;

  constructor(public payload: IUserSettings) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class DownloadUsersExcel implements Action {
  readonly type = DOWNLOAD_USERS_EXCEL;

  constructor(public filters: IUserTableQuery, public columns: string[], public withData: boolean) {}
}

export class DownloadUsersExcelCompleted implements Action {
  readonly type = DOWNLOAD_USERS_EXCEL_COMPLETED;
}

export class DownloadUsersExcelWithDataCompleted implements Action {
  readonly type = DOWNLOAD_USERS_EXCEL_WITH_DATA_COMPLETED;

  constructor(public withErrorColumn: boolean = false) {}
}

export class DownloadUsersErrorExcel implements Action {
  readonly type = DOWNLOAD_USERS_ERROR_EXCEL;

  constructor(public payload: IUserWithExcelDropdowns[], public columns: string[] | null, public withData: boolean) {}
}

export class DownloadUsersErrorExcelCompleted implements Action {
  readonly type = DOWNLOAD_USERS_ERROR_EXCEL_COMPLETED;
}

export class UploadUsersExcel implements Action {
  readonly type = UPLOAD_USER_EXCEL;

  constructor(public payload: IUser[], public issuerAndReason: IIssuerAndReason | null) {}
}

export class UploadUsersExcelCompleted implements Action {
  readonly type = UPLOAD_USER_EXCEL_COMPLETED;

  constructor(
    public payload: (IUserWithExcelDropdowns & { errorMessages?: string })[],
    public success: boolean,
    public issuerAndReason: IIssuerAndReason | null,
  ) {}
}

export class AddUser implements Action {
  readonly type = ADD_USER;

  constructor(public payload: IUser, public issuer: IIssuer | null) {}
}

export class AddUserCompleted implements Action {
  readonly type = ADD_USER_COMPLETED;

  constructor(public payload: IUser) {}
}

export class EditUser implements Action {
  readonly type = EDIT_USER;

  constructor(
    public payload: Partial<IUser>,
    public isSelf: boolean,
    public issuer: IIssuer | null,
    public forceCheckOut?: boolean,
  ) {}
}

export class EditUserCompleted implements Action {
  readonly type = EDIT_USER_COMPLETED;

  constructor(public payload: IBaseOneResponse<IUser>) {}
}

export class PinChange implements Action {
  readonly type = PIN_CHANGE;

  constructor(public payload: Partial<IUser>, public isSelf: boolean, public issuer: IIssuer | null) {}
}

export class PinChangeCompleted implements Action {
  readonly type = PIN_CHANGE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IUser>) {}
}

export class EditUsers implements Action {
  readonly type = EDIT_USERS;

  constructor(public payload: Partial<IUser>[], public issuerAndReason: IIssuerAndReason) {}
}

export class EditUsersCompleted implements Action {
  readonly type = EDIT_USERS_COMPLETED;

  constructor(public payload: IGetManyResponse<IBulkResponseRecord<IUser>>) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IUserTableQuery) {}
}

export class GetRightAssignments implements Action {
  readonly type = GET_RIGHT_ASSIGNMENTS;

  constructor(public userId: number | null, public roleId: number | null) {}
}

export class GetRightAssignmentsCompleted implements Action {
  readonly type = GET_RIGHT_ASSIGNMENTS_COMPLETED;

  constructor(
    public payload: IUserAndRoleRightAssignments,
    public getUserRightAssignments: boolean,
    public getRoleRightAssignments: boolean,
  ) {}
}

export class GetRoleRightAssignments implements Action {
  readonly type = GET_ROLE_RIGHT_ASSIGNMENTS;

  constructor(
    public roleId: number,
    public openPermissionExceptionTable: boolean = true,
    public checkPermissionExceptionsToKeep: boolean = false,
  ) {}
}

export class GetRoleRightAssignmentsCompleted implements Action {
  readonly type = GET_ROLE_RIGHT_ASSIGNMENTS_COMPLETED;

  constructor(
    public payload: IRightAssignmentsAndRights,
    public openPermissionExceptionTable: boolean,
    public checkPermissionExceptionsToKeep: boolean,
  ) {}
}

export class GetUserRightAssignments implements Action {
  readonly type = GET_USER_RIGHT_ASSIGNMENTS;

  constructor(public userIds: number[]) {}
}

export class GetUserRightAssignmentsCompleted implements Action {
  readonly type = GET_USER_RIGHT_ASSIGNMENTS_COMPLETED;

  constructor(public payload: IRightAssignment[]) {}
}

export class SetUserRightAssignments implements Action {
  readonly type = SET_USER_RIGHT_ASSIGNMENTS;

  constructor(public userRightAssignments: IRightAssignment[], public issuerAndReason: IIssuerAndReason) {}
}

export class SetUserRightAssignmentsCompleted implements Action {
  readonly type = SET_USER_RIGHT_ASSIGNMENTS_COMPLETED;

  constructor(public payload: IRightAssignment[]) {}
}

export class GetUserShortInformation implements Action {
  readonly type = GET_USER_SHORT_INFORMATION;
  constructor(public username: string | null | undefined) {}
}

export class GetUserShortInformationCompleted implements Action {
  readonly type = GET_USER_SHORT_INFORMATION_COMPLETED;
  constructor(public payload: IGetOneResponse<IUserShortInfo>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type UserSettingsActions =
  | UserSettingsDataLoading
  | UserSettingsDataLoaded
  | SetTableSettings
  | DownloadUsersExcel
  | DownloadUsersExcelCompleted
  | DownloadUsersExcelWithDataCompleted
  | DownloadUsersErrorExcel
  | DownloadUsersErrorExcelCompleted
  | UploadUsersExcel
  | UploadUsersExcelCompleted
  | AddUser
  | AddUserCompleted
  | EditUser
  | EditUserCompleted
  | PinChange
  | PinChangeCompleted
  | EditUsers
  | EditUsersCompleted
  | SetSelectedFilters
  | GetRightAssignments
  | GetRightAssignmentsCompleted
  | GetRoleRightAssignments
  | GetRoleRightAssignmentsCompleted
  | GetUserRightAssignments
  | GetUserRightAssignmentsCompleted
  | SetUserRightAssignments
  | SetUserRightAssignmentsCompleted
  | GetUserShortInformation
  | GetUserShortInformationCompleted
  | FetchError;
