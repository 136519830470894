import { User } from './model';
import * as Actions from './actions';
import { Action } from '@ngrx/store';

const initialState: User = {
  clientId: null,
  id: null,
  username: null,
  email: null,
  title: null,
  clientLogoId: null,
  clientName: null,
  clientCode: null,
  userId: null,
  roleId: null,
  role: null,
  language: null,
  locale: null,
  timezone: null,
  dateFormat: null,
  dateTimeFormat: null,
  timeFormat: null,
  timeFormatHourAndMinute: null,
  decimalSeparator: '.',
  thousandSeparator: ',',
  logbookScopeId: null,
  formScopeId: null,
  isUserLoaded: false,
  isUserLoading: false,
  errors: [],
  isTokenRefreshed: false,
  isFeedTokenLoading: false,
  isFeedTokenLoaded: false,
  isConfigurationLoaded: false,
  isConfigurationLoading: false,
  notificationToken: null,
  notificationsLoading: false,
  notificationsLoaded: false,
  notifications: { results: [], next: undefined, duration: null, unseen: 0, unread: 0 },
  firstName: null,
  lastName: null,
  configuration: null,
  isPinTemporary: false,
  scopeLogbookIds: [],
  isAllLogbookScope: false,
  isAllFormScope: false,
  formActivationFlow: null,
};

export function userReducer(state: User = initialState, baseAction: Action): User {
  const action = baseAction as Actions.UserActions;

  switch (action.type) {
    case Actions.SET_USER_INFO:
      return {
        ...state,
        clientId: returnValueOrNull(action.payload, 'clientId'),
        username: returnValueOrNull(action.payload, 'username'),
        userId: returnValueOrNull(action.payload, 'userId'),
        roleId: returnValueOrNull(action.payload, 'roleId'),
        role: returnValueOrNull(action.payload, 'role'),
        isPinTemporary: returnValueOrNull(action.payload, 'isPinTemporary'),
        language: returnValueOrNull(action.payload, 'language'),
        timezone: returnValueOrNull(action.payload, 'timezone'),
        dateFormat: returnValueOrNull(action.payload, 'dateFormat'),
        firstName: returnValueOrNull(action.payload, 'firstName'),
        lastName: returnValueOrNull(action.payload, 'lastName'),
        email: returnValueOrNull(action.payload, 'email'),
        title: returnValueOrNull(action.payload, 'title'),
        clientLogoId: returnValueOrNull(action.payload, 'clientLogoId'),
        clientName: returnValueOrNull(action.payload, 'clientName'),
        decimalSeparator: returnValueOrNull(action.payload, 'decimalSeparator'),
        thousandSeparator: returnValueOrNull(action.payload, 'thousandSeparator'),
        locale: returnValueOrNull(action.payload, 'locale'),
        scopeLogbookIds: returnValueOrNull(action.payload, 'scopeLogbookIds'),
        scopeFormIds: returnValueOrNull(action.payload, 'scopeFormIds'),
        isAllLogbookScope: returnValueOrNull(action.payload, 'isAllLogbookScope', true),
        isAllFormScope: returnValueOrNull(action.payload, 'isAllFormScope', true),
        logbookScopeId: returnValueOrNull(action.payload, 'logbookScopeId'),
        formScopeId: returnValueOrNull(action.payload, 'formScopeId'),
        formActivationFlow: returnValueOrNull(action.payload, 'formActivationFlow'),
      };

    case Actions.CURRENT_USER_LOADING:
      return { ...state, isUserLoading: true };
    case Actions.CURRENT_USER_LOADED:
      return { ...state, isUserLoading: false, isUserLoaded: true };

    case Actions.FETCH_ERROR:
      return { ...state, errors: action.payload };

    case Actions.SET_USER_CONFIGURATION:
      return {
        ...state,
        isConfigurationLoaded: true,
        isConfigurationLoading: false,
        configuration: action.configuration,
      };
    case Actions.UPDATE_USER_LOGBOOK_SCOPE_IDS:
      return {
        ...state,
        scopeLogbookIds: state.scopeLogbookIds?.concat(action.logbookScopeIds),
      };
    default:
      return state;
  }
}

export function returnValueOrNull(payload: any, key: string, shouldCastToBoolean: boolean = false): any | null {
  if (shouldCastToBoolean) {
    return Boolean(payload[key]);
  }

  return payload[key] ?? null;
}
