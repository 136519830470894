<ng-template #forgot_pin let-modal>
  <scw-mat-form (scwFormSubmit)="sendMailToAPI($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'general.forgotPin' | translate }}
      </h3>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="m-b-15 m-t-5 info-message d-flex justify-content-start">
        {{ 'scwMatForm.infoMessages.forgotPin' | translate }}
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.forgotPin.mail.label' | translate }}*"
            [rules]="formRules.email"
            [(inputModel)]="email"
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="closeModal()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          {{ 'general.sendRequest' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
