<div class="compare-changes-modal-body">
  <h5>
    <em class="fas fa-clock"></em>
    {{ 'myTasks.logbookTasksShowModal.changeHistory' | translate }}
  </h5>
  <br />
  <div class="row">
    <ng-container *ngFor="let header of compareHeaders">
      <div class="col">
        <h5>
          {{ header.label | translate }}
        </h5>
        <br />
        <div class="scw_mat-add_edit_modal-item">
          <div class="pt-3 mb-3 px-3 inner-form-section">
            <ng-template [ngIf]="header.innerLabel">
              <h5>
                <ng-template [ngIf]="header.innerLabelIconCls">
                  <em [class]="header.innerLabelIconCls"></em>
                </ng-template>

                {{ header.innerLabel | translate }}
              </h5>
            </ng-template>
            <br />
            <scw-mat-form>
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngFor="let item of compareFields">
                    <ng-template [ngIf]="item.button">
                      <div class="float-right fixed-button-position">
                        <scw-mat-button
                          type="standard"
                          [iconButton]="true"
                          [disabled]="
                            item.values[header.value].length === 0 || item.button.disabled(item.values[header.value])
                          "
                          (onClick)="onButtonClick(item.values[header.value])"
                        >
                          <em [class]="item.button?.buttonIconCls"></em>
                        </scw-mat-button>
                      </div>
                    </ng-template>

                    <ng-template
                      [ngIf]="
                        item.depends === undefined ||
                        item.depends[header.value] === undefined ||
                        item.depends[header.value] === true
                      "
                    >
                      <ng-container *ngIf="item.type === fieldType.INPUT; else date">
                        <scw-mat-input
                          class="scw_mat-add_edit_modal-item-input"
                          [class.highlighted-field]="item.highlighted"
                          label="{{ item.label | translate }}{{ item.required ? '*' : '' }}"
                          [(inputModel)]="item.values[header.value]"
                          [disabled]="true"
                        ></scw-mat-input>
                      </ng-container>
                      <ng-template #date>
                        <ng-container *ngIf="item.type === fieldType.DATE; else select">
                          <scw-mat-datepicker
                            class="scw_mat-add_edit_modal-item-input"
                            [class.highlighted-field]="item.highlighted"
                            label="{{ item.label | translate }}{{ item.required ? '*' : '' }}"
                            dataPropertyKey="issuedDate"
                            [(inputModel)]="item.values[header.value]"
                            [disabled]="true"
                            [singleDatePicker]="true"
                            [autoApply]="true"
                            [showCustomRangeLabel]="false"
                            [ranges]="null"
                            [showClearButton]="true"
                            [formatInitialModel]="false"
                          ></scw-mat-datepicker>
                        </ng-container>
                      </ng-template>
                      <ng-template #select>
                        <ng-container *ngIf="item.type === fieldType.SELECT; else label">
                          <scw-mat-select
                            [class.highlighted-field]="item.highlighted"
                            label="{{ item.label | translate }}{{ item.required ? '*' : '' }}"
                            [disabled]="true"
                            [(inputModel)]="item.values[header.value]"
                            [singleSelection]="item.values[header.value].length == 1"
                            [data]="item.data"
                          ></scw-mat-select>
                        </ng-container>
                      </ng-template>
                      <ng-template #label>
                        <ng-container *ngIf="item.type === fieldType.LABEL; else group">
                          <label class="select-label" [class.highlighted-field]="item.highlighted">{{
                            item.label | translate
                          }}</label>
                          <p class="bolder_forms">{{ item.values[header.value] }}</p>
                        </ng-container>
                      </ng-template>
                      <ng-template #group>
                        <ng-container *ngIf="item.type === fieldType.GROUP">
                          <label class="select-label">{{ item.label | translate }}</label>
                          <div class="py-3 mb-3 px-3 pb-1 form-frame">
                            <ng-container *ngFor="let subItem of item.content">
                              <div class="col p-0">
                                <ng-container *ngIf="subItem.type === fieldType.INPUT; else date">
                                  <scw-mat-input
                                    class="scw_mat-add_edit_modal-item-input"
                                    [class.highlighted-field]="subItem.highlighted"
                                    label="{{ subItem.label | translate }}{{ subItem.required ? '*' : '' }}"
                                    [(inputModel)]="subItem.values[header.value]"
                                    [disabled]="true"
                                  ></scw-mat-input>
                                </ng-container>
                                <ng-template #date>
                                  <ng-container *ngIf="subItem.type === fieldType.DATE; else select">
                                    <scw-mat-datepicker
                                      class="scw_mat-add_edit_modal-item-input"
                                      [class.highlighted-field]="subItem.highlighted"
                                      label="{{ subItem.label | translate }}{{ subItem.required ? '*' : '' }}"
                                      dataPropertyKey="issuedDate"
                                      [(inputModel)]="subItem.values[header.value]"
                                      [disabled]="true"
                                      [singleDatePicker]="true"
                                      [autoApply]="true"
                                      [showCustomRangeLabel]="false"
                                      [ranges]="null"
                                      [showClearButton]="true"
                                      [formatInitialModel]="false"
                                    ></scw-mat-datepicker>
                                  </ng-container>
                                </ng-template>
                                <ng-template #select>
                                  <ng-container *ngIf="subItem.type === fieldType.SELECT; else label">
                                    <scw-mat-select
                                      [class.highlighted-field]="subItem.highlighted"
                                      label="{{ subItem.label | translate }}{{ subItem.required ? '*' : '' }}"
                                      [disabled]="true"
                                      [(inputModel)]="subItem.values[header.value]"
                                      [singleSelection]="subItem.values[header.value].length == 1"
                                      [data]="subItem.data"
                                    ></scw-mat-select>
                                  </ng-container>
                                </ng-template>
                                <ng-template #label>
                                  <ng-container *ngIf="subItem.type === fieldType.LABEL">
                                    <label class="select-label" [class.highlighted-field]="subItem.highlighted">{{
                                      subItem.label | translate
                                    }}</label>
                                    <p class="bolder_forms">{{ subItem.values[header.value] }}</p>
                                  </ng-container>
                                </ng-template>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                  <ng-template [ngIf]="showUserDefinedFields">
                    <hr class="scw-hr" />
                    <h6 class="scw-header">
                      <em class="fas fa-user"></em>&nbsp;{{
                        'general.subtitles.userDefinedFields' | translate
                      }}&nbsp;<em
                        class="fas fa-info-circle"
                        container="body"
                        [ngbPopover]="userDefinedFieldsPopover"
                        placement="right auto"
                      ></em>
                    </h6>
                    <scw-user-defined-field-renderer
                      [fieldArray]="fieldArray[header.value]"
                      [disableAllFields]="true"
                    ></scw-user-defined-field-renderer>
                  </ng-template>
                </div>
              </div>
            </scw-mat-form>
          </div>
        </div>
        <ng-container *ngIf="formioFields && formioFields[header.value]">
          <div class="scw_mat-add_edit_modal-item">
            <div class="pt-3 mb-3 px-3 inner-form-section">
              <h5>{{ formioFields[header.value]?.name }}</h5>
              <br />
              <div class="pt-3 mb-3 px-3 inner-form-section">
                <form action="javascript:void(0);" onSubmit="return false;" autocomplete="off">
                  <formio [form]="formioFields[header.value]?.form" (ready)="formIOLinkObserverSelector()"></formio>
                </form>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>

<ng-template #userDefinedFieldsPopover>
  <ul class="scw-basic-list">
    <li>
      {{ 'settings.masterData.logbook.preDefinedFields.infoMessage2' | translate }}
    </li>
  </ul>
</ng-template>
