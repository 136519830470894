import { App } from './model';
import * as Actions from './actions';
import { Action } from '@ngrx/store';

export const initialState: App = {
  loading: false,
  topLoading: false,
  loadingText: null,
  cicoAccess: null,
  showTour: false,
  activityListUpdate: false,
  masking: false,
};

export function appReducer(state: App = initialState, baseAction: Action): App {
  const action = baseAction as Actions.AppActions;

  switch (action.type) {
    case Actions.SHOW_LOADER:
      return {
        ...state,
        ...{ loading: true, loadingText: action.loadingText },
      };

    case Actions.HIDE_LOADER:
      return {
        ...state,
        ...{ loading: false, loadingText: null },
      };

    case Actions.SHOW_TOP_LOADER:
      return {
        ...state,
        ...{ topLoading: true },
      };

    case Actions.HIDE_TOP_LOADER:
      return {
        ...state,
        ...{ topLoading: false },
      };
    case Actions.SHOW_MASK:
      return {
        ...state,
        ...{ masking: true },
      };

    case Actions.HIDE_MASK:
      return {
        ...state,
        ...{ masking: false },
      };

    default:
      return state;
  }
}
