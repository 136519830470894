<div class="page-loader" [hidden]="!loading$">
  <div class="page-loader-block">
    <svg id="loader2" viewBox="0 0 100 100">
      <circle id="circle-loader2" class="stroke-white" cx="50" cy="50" r="45"></circle>
    </svg>
    <div class="page-loader-text-block" [hidden]="!loadingText$">
      {{ loadingText$ }}
    </div>
  </div>
</div>
