<div
  class="scw-mat-search-container"
  [class.is-button-on-left]="buttonPosition === 'left'"
  [class.is-button-on-right]="buttonPosition === 'right'"
>
  <ng-template [ngIf]="buttonPosition === 'left'" [ngIfElse]="right">
    <scw-mat-small-button
      [className]="['scw-mat-small-button-search-icon-button', 'left']"
      [disabled]="disabled"
      (onClick)="onSearchEvent('onClickButton')"
    >
      <em class="fas fa-search"></em>
    </scw-mat-small-button>
  </ng-template>
  <scw-mat-input
    [disabled]="disabled"
    [block]="block"
    [className]="className"
    [label]="label"
    [placeholder]="placeholder"
    [maxlength]="maxlengthString"
    [rules]="rules"
    [(inputModel)]="inputModel"
    (onKeyup)="onSearchEvent('onKeyup')"
    (onKeyupEnter)="onSearchEvent('onEnter')"
    (inputModelChange)="onInputModelChange()"
    #inputComponent
  ></scw-mat-input>
  <ng-template #right>
    <scw-mat-small-button
      [className]="['scw-mat-small-button-search-icon-button', 'right']"
      [disabled]="disabled"
      (onClick)="onSearchEvent('onClickButton')"
    >
      <em class="fas fa-search"></em>
    </scw-mat-small-button>
  </ng-template>
</div>
