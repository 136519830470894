<app-reason
  [dispatchHideLoader]="dispatchHideLoader"
  [actionsToSubscribe]="reasonActionsToSubscribe"
  [(reason)]="reason"
  (onReasonEmit)="getReason($event)"
></app-reason>
<app-issuer
  [actionsToSubscribe]="issuerActionsToSubscribe"
  [(issuerInput)]="issuer"
  (onIssuerEmit)="getIssuer($event)"
></app-issuer>
<scw-pin-change> </scw-pin-change>
