<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title>SCW Digital Logbook - Create Password</title>
    <link rel="shortcut icon" href="images/favicon.ico" />
    <meta name="viewport" content="width=device-width" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" />
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
      rel="stylesheet"
    />
  </head>
  <body>
    <div class="container">
      <div class="row login-window my-4">
        <div id="leftSideContainer" class="col d-none d-lg-flex align-items-center">
          <div class="left_side">
            <img class="logbook-logo" src="assets/images/logbook_new_logo.png" alt="" />
            <div class="left-container">
              <div class="logbook-text">DIGITAL LOGBOOK</div>

              <div class="content-text">
                {{ 'resetPin.logbookDescription' | translate }}
              </div>
            </div>
            <div class="scw-ai-logo-container">
              <span class="scw-ai-logo-text">powered by</span>
              <img class="scw-ai-logo" src="assets/images/scw_ai_logo.png" alt="" />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center w-100 logbook-mobile-logo-container">
          <img class="logbook-mobile-logo" src="assets/images/logbook_new_logo.png" alt="" />
        </div>
        <div class="col align-self-end create-new-pin-container">
          <form id="login-form">
            <h1 class="title">{{ 'resetPin.createNewPin' | translate }}</h1>
            <p class="description-text">{{ 'resetPin.createNewPinInfo' | translate }}</p>
            <div class="form-group w-100">
              <label>{{ 'resetPin.newPin' | translate }}</label>
              <input
                id="password"
                class="password-input"
                placeholder="●●●●●●●●"
                name="password"
                type="password"
                tabindex="2"
                pattern="[0-9]*"
                [(ngModel)]="pin"
                (input)="passwordChange($event)"
              />
              <span
                id="passwordEyeIcon"
                class="password-input-eye-icon password fas fa-eye-slash"
                (click)="showPin(false)"
              ></span>
              <div class="password-control">
                <p>
                  <em id="number" class="fas fa-times-circle"></em>&nbsp;{{ 'resetPin.inputValidation' | translate }}
                </p>
              </div>
            </div>
            <div class="form-group mt-1 w-100">
              <label>{{ 'resetPin.confirmNewPin' | translate }}</label>
              <input
                id="passwordConfirm"
                class="password-input mb-2"
                placeholder="●●●●●●●●"
                name="password"
                type="password"
                tabindex="2"
                pattern="[0-9]*"
                (input)="passwordChange($event)"
                [(ngModel)]="confirmPin"
              />
              <span
                id="passwordConfirmEyeIcon"
                class="password-input-eye-icon password-confirm fas fa-eye-slash"
                (click)="showPin(true)"
              ></span>
              <div class="error-message invisible">
                <em class="fas fa-exclamation-circle mr-1"></em>
                {{ 'resetPin.pinMismatch' | translate }}
              </div>
            </div>
            <div class="response alert py-1 d-none">
              <em class="fas fa-info-circle information-icon"></em>
            </div>
            <button
              class="w-100"
              id="submitButton"
              disabled="false"
              type="submit"
              tabindex="4"
              (click)="sendPassword()"
            >
              {{ 'resetPin.updatePin' | translate }}
            </button>
            <a class="back-button" (click)="window.location.assign(windowLocation)">
              <em class="fas fa-chevron-left"></em>
              <span class="border-0">{{ 'resetPin.backToHome' | translate }}</span>
            </a>
          </form>
        </div>
        <div class="d-flex justify-content-center w-100 scw-ai-mobile-logo-container">
          <img class="scw-ai-mobile-logo" src="assets/images/scw_ai_logo.png" alt="" />
        </div>
      </div>
    </div>
  </body>
</html>
