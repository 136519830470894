<ng-template #pin_change_modal let-modal>
  <scw-mat-form (scwFormSubmit)="submitPin($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'general.changePin' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-md-12 m-b-15">
        <div class="row">
          <div class="col-xs-1">
            <em class="fas fa-exclamation-circle fa-lg p-t-10"></em>
          </div>
          <div class="col-md-11 info-message">
            <p class="m-t-5" *ngIf="pinChangeType === 'edit'">
              {{'scwMatForm.infoMessages.pinChange' | translate}}
            </p>
            <p class="m-t-5" *ngIf="pinChangeType === 'expire'">
              {{'scwMatForm.infoMessages.pinExpire' | translate}}
            </p>
            <p class="m-t-5" *ngIf="pinChangeType === 'temporary'">
              {{'scwMatForm.infoMessages.pinTemporary' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.username.label' | translate }}*"
            [rules]="pinFormRules.username"
            [(inputModel)]="username"
            [disabled]="true"
          ></scw-mat-input>
        </div>
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.currentPin.label' | translate }}*"
            type="password"
            [rules]="pinFormRules.currentPin"
            [(inputModel)]="pinForm.currentPin.value"
          ></scw-mat-input>
        </div>
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.newPin.label' | translate }}*"
            type="password"
            [rules]="pinFormRules.newPin"
            [(inputModel)]="pinForm.newPin.value"
            (inputModelChange)="checkPinInputRules(confirmationPin)"
          ></scw-mat-input>
        </div>
      </div>
      <div class="scw_mat-add_edit_modal-body">
        <div class="scw_mat-add_edit_modal-item">
          <scw-mat-input
            class="scw_mat-add_edit_modal-item-input"
            label="{{ 'modal.issuer.newPinConfirmation.label' | translate }}*"
            type="password"
            [rules]="pinFormRules.confirmationPin"
            [(inputModel)]="pinForm.confirmationPin.value"
            #confirmationPin
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="closeModal()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          {{ 'general.saveChanges' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

