import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isObservable, Subject, take, Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { HelperService } from '../../../service/helper.service';
import { IAdvancedFilterStore } from '../../../../store/advanced-filter/advanced-filter.model';
import * as logbookAppReducer from '../../../../store/logbook.reducer';
import { User } from '../../../../store/user/model';
import { DateRangeOpensTypes } from '../../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { ScwMatFormComponent } from '../../scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import { IOutputOptions } from '../filter.class';
import {
  AdvancedFilterObjects,
  FieldTypes,
  IAddFilterOptions,
  IAdvancedFilterOutput,
  IField,
  IFieldSelect,
  IFilter,
  IHiddenField,
  InputTypes,
  IOperator,
  IOperatorSelect,
  IOperatorsFor,
  IPredefinedFieldSelect,
  IPredefinedSelect,
  Operator,
  OperatorTypes,
  QueryTypes,
  SelectAllTypes,
  SqlOperators,
  SubmitTypes,
  TargetEndpoints,
} from './advanced-filter.model';
import { AdvancedFilterService } from './advanced-filter.service';
import * as AdvancedFilterActions from '../../../../store/advanced-filter/advanced-filter.actions';
import { advancedFilterFilterCardSubject, TSeparator } from '../../../../../constants';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';

@OnDestroyDecorator
@Component({
  selector: 'advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent implements OnInit, OnDestroy {
  @ViewChild(ScwMatFormComponent, { static: false }) scwMatForm!: ScwMatFormComponent;

  public readonly outputSubject!: Subject<{ [advancedFilter: string]: IAdvancedFilterOutput }>;
  public readonly outputOptions!: IOutputOptions;
  private readonly subscriptions: Subscription[] = [];

  public readonly OperatorTypes: typeof OperatorTypes = OperatorTypes;
  public readonly InputTypes: typeof InputTypes = InputTypes;
  public readonly SqlOperators: typeof SqlOperators = SqlOperators;
  public readonly QueryTypes: typeof QueryTypes = QueryTypes;
  public readonly DateRangeOpens: typeof DateRangeOpensTypes = DateRangeOpensTypes;

  public advancedFilterModalRef!: NgbModalRef;
  public confirmationModalRef!: NgbModalRef;
  private modalRefs: NgbModalRef[] = [];

  public filters: IFilter[] = [];
  public initialFilters: IFilter[] = [];
  public appliedFilterCount: number = 0;

  public isAddButtonDisabled: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public isApplyButtonDisabled: boolean = false;
  public isSetDefaultButtonDisabled: boolean = false;

  public isChangesApplied: boolean = true;

  public selectAllType: SelectAllTypes = SelectAllTypes.select;

  private userId$!: string;
  private filterCard$!: FilterCardComponent | null;

  private decimalSeparator: TSeparator = ',';
  public dateFormat: string | null = null;

  @Input() filterObject!: AdvancedFilterObjects;
  @Input() defaultValues: IFilter[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isSetDefaultButtonHide: boolean = false;

  private targetEndpoint!: TargetEndpoints;

  public readonly operatorsFor: IOperatorsFor = {
    number: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.greaterThan, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.lessThan, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.greaterThanEquals, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.lessThanEquals, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
      { name: Operator.isNotNull, sql: SqlOperators.isNotNull, type: OperatorTypes.boolean },
    ],
    decimal: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.greaterThan, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.lessThan, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.greaterThanEquals, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.lessThanEquals, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
    ],
    date: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.greaterThan, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.lessThan, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.greaterThanEquals, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.lessThanEquals, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
      { name: Operator.isNotNull, sql: SqlOperators.isNotNull, type: OperatorTypes.boolean },
    ],
    datetime: [
      { name: Operator.equals, sql: SqlOperators.exact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.greaterThan, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.lessThan, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.greaterThanEquals, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.lessThanEquals, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
      { name: Operator.isNotNull, sql: SqlOperators.isNotNull, type: OperatorTypes.boolean },
    ],
    string: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.contains, sql: SqlOperators.contains, type: OperatorTypes.string },
      { name: Operator.doesNotContain, sql: SqlOperators.excludes, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.isBlank, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.isNotBlank, type: OperatorTypes.boolean },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
      { name: Operator.isNotNull, sql: SqlOperators.isNotNull, type: OperatorTypes.boolean },
    ],
    boolean: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
    ],
    predefined: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.exact, sql: SqlOperators.exact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.contains, sql: SqlOperators.contains, type: OperatorTypes.string },
      { name: Operator.doesNotContain, sql: SqlOperators.excludes, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
      { name: Operator.isNotNull, sql: SqlOperators.isNotNull, type: OperatorTypes.boolean },
    ],
    duration: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.greaterThan, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.lessThan, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.greaterThanEquals, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.lessThanEquals, type: OperatorTypes.string },
      { name: Operator.isNull, sql: SqlOperators.isNull, type: OperatorTypes.boolean },
    ],
    dateFormat: [
      { name: Operator.equals, sql: SqlOperators.iExact, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.notEquals, type: OperatorTypes.string },
    ],
  };

  public fields: (IFieldSelect | IPredefinedFieldSelect)[] = [];
  private initialFields: (IFieldSelect | IPredefinedFieldSelect)[] = [];

  private readonly hiddenFields: IHiddenField[] = [];

  public readonly inputRules = {
    number: [
      {
        minValue: 0,
        message: this.translate.instant('scwMatForm.validation.minValue', { minValue: 0 }),
      },
      {
        maxValue: 1000000,
        message: this.translate.instant('scwMatForm.validation.maxValue', { maxValue: 1000000 }),
      },
    ],
    decimal: [
      {
        decimal: {
          min: 0.0,
          max: 1000000,
          integerStep: {
            max: 7,
          },
          decimalStep: {
            max: 2,
          },
        },
        message: this.translate.instant('scwMatForm.validation.decimal', {
          min: HelperService.prepareDecimalValue(0.0, this.decimalSeparator),
          max: 1000000,
          decimalStepMax: 2,
        }),
      },
    ],
    duration: [
      {
        pattern: /^(?:\\d+):(?:[012345]\\d)$/,
        message: this.translate.instant('scwMatForm.validation.duration'),
      },
    ],
  };

  public submitType!: SubmitTypes;

  constructor(
    public readonly translate: TranslateService,
    public readonly advancedFilterService: AdvancedFilterService,
    public readonly helperService: HelperService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly toast: ToastrService,
    private readonly storeActions: ActionsSubject,
    private readonly advancedFilterModal: NgbModal,
    private readonly confirmationModal: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.storeActions.pipe(ofType(AdvancedFilterActions.SAVE_FILTER_CONFIG_COMPLETED)).subscribe(() => {
        this.onApply(true);
      }),
      advancedFilterFilterCardSubject.subscribe((filterCard: any) => {
        this.filterCard$ = filterCard;
      }),
    );

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        if (user.userId) {
          this.userId$ = user.userId;
        }

        this.decimalSeparator = user.decimalSeparator;
        this.dateFormat = user.dateFormat;
      });

    this.store
      .select('advancedFilterStore')
      .pipe(take(1))
      .subscribe((state: IAdvancedFilterStore) => {
        if (this.defaultValues.length !== 0) {
          this.filters = _.cloneDeep(this.defaultValues);
          this.initialFilters = _.cloneDeep(this.filters);
          this.isChangesApplied = true;
          this.setAppliedFilterCount();
          this.onFormChange();
          return;
        }

        if (_.isEmpty(state.defaultFilters) || _.isEmpty(state.defaultFilters[this.filterObject.pageName])) {
          this.addFilter();
          this.initialFilters = _.cloneDeep(this.filters);
          this.isChangesApplied = true;
          return;
        }

        this.filters = _.cloneDeep(state.defaultFilters[this.filterObject.pageName]);

        this.translateSelectedOperators();
        this.setOperatorsForType();

        this.initialFilters = _.cloneDeep(this.filters);
        this.isChangesApplied = true;
        this.setAppliedFilterCount();

        this.onFormChange();
      });

    this.fields = this.filterObject.fields.map((field: IField, index: number) => {
      const baseField: IFieldSelect = {
        id: index,
        field: this.translate.instant(field.label),
        path: field.path,
        type: field.type,
        queryType: field.queryType,
        excludeOperators: field.excludeOperators,
        showSearchBox: {
          showSearchBox: field.showSearchBox?.showSearchBox ?? false,
          searchBehaviour: field.showSearchBox?.searchBehaviour ?? 'live',
        },
        singleSelection: field.singleSelection ?? true,
        showCheckbox: field.showCheckbox ?? false,
      };

      if (isObservable(field.predefinedValues)) {
        return {
          ...baseField,
          predefinedValues: [],
          predefinedValuesObservable: field.predefinedValues,
          observableIdProperty: field.observableIdProperty,
          observableNameProperty: field.observableNameProperty,
          searchBy: field.searchBy,
        };
      }

      if (Array.isArray(field.predefinedValues)) {
        return {
          ...baseField,
          predefinedValues: field.predefinedValues,
          searchBy: field.searchBy,
        };
      }

      return {
        ...baseField,
      };
    });
    this.targetEndpoint = this.filterObject.targetEndpoint;

    this.populatePredefinedFieldsWithObservables();
    this.setOperatorsForType();

    this.isChangesApplied = true;
    this.initialFilters = _.cloneDeep(this.filters);
    this.initialFields = _.cloneDeep(this.fields);

    this.onFormChange();
  }

  private populatePredefinedFieldsWithObservables(): void {
    this.fields
      .filter((field: IFieldSelect | IPredefinedFieldSelect) =>
        isObservable(_.get(field, 'predefinedValuesObservable', null)),
      )
      .forEach((field: any) => {
        field.predefinedValuesObservable?.pipe(take(2)).subscribe((state: any) => {
          field.predefinedValues = state.data.map((predefinedValue: any) => {
            return {
              id: field?.observableIdProperty ? predefinedValue[field?.observableIdProperty] : '',
              name: field?.observableNameProperty ? predefinedValue[field?.observableNameProperty] : '',
            };
          });
          this.initialFields = _.cloneDeep(this.fields);
        });

        delete field.predefinedValuesObservable;
      });
  }

  public showAdvancedFilterModal(templateRef: TemplateRef<any>): void {
    if (this.isDisabled) return;

    this.advancedFilterModalRef = this.advancedFilterModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-lg scw-modal-all-scrollable',
    });

    this.modalRefs.push(this.advancedFilterModalRef);
  }

  public showConfirmationModal(templateRef: TemplateRef<any>): void {
    this.confirmationModalRef = this.confirmationModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });

    this.modalRefs.push(this.confirmationModalRef);
  }

  public closeAllModals(): void {
    this.fields = _.cloneDeep(this.initialFields);
    this.modalRefs.forEach((modal: NgbModalRef) => modal.close());
    this.modalRefs = [];
  }

  public addFilter(options: IAddFilterOptions | null = null): void {
    this.filters = this.filters.concat({
      isActive: options?.isActive ?? true,
      operators: [],
      selectedOperator: [],
      selectedField: [],
      selectedFieldType: InputTypes.text,
      value: null,
    });

    this.onFormChange();
  }

  public deleteFilters(): void {
    this.filters = this.filters.filter((filter: IFilter) => !filter.isActive);

    if (_.isEmpty(this.filters)) {
      this.addFilter({ isActive: false });
    }

    this.onFormChange();
  }

  private static determineInputType(filter: IFilter): InputTypes {
    if (_.isEmpty(filter.selectedField)) {
      return InputTypes.text;
    }

    switch (_.head(filter.selectedField)?.type) {
      case FieldTypes.string:
        return InputTypes.text;

      case FieldTypes.predefined:
        return InputTypes.select;

      case FieldTypes.number:
        return InputTypes.number;

      case FieldTypes.decimal:
        return InputTypes.decimal;

      case FieldTypes.date:
        return InputTypes.date;

      case FieldTypes.dateTime:
        return InputTypes.dateTime;

      case FieldTypes.boolean:
        return InputTypes.checkbox;

      case FieldTypes.duration:
        return InputTypes.duration;

      case FieldTypes.dateFormat:
        return InputTypes.dateFormat;

      default:
        return InputTypes.text;
    }
  }

  private setSelectAllType(): void {
    if (this.filters.every((filter: IFilter) => filter.isActive)) {
      this.selectAllType = SelectAllTypes.deSelect;
    } else {
      this.selectAllType = SelectAllTypes.select;
    }
  }

  private setAddButtonState(): void {
    const invalidFilters: IFilter[] = this.filters.filter(
      (filter: IFilter) => !AdvancedFilterComponent.isFilterValid(filter),
    );

    this.isAddButtonDisabled = invalidFilters.length >= 3;
  }

  private setDeleteButtonState(isSomeFiltersActive: boolean): void {
    this.isDeleteButtonDisabled = !isSomeFiltersActive;
  }

  private setFieldsValueInputType(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.selectedFieldType = AdvancedFilterComponent.determineInputType(filter);
    });
  }

  private translateSelectedOperators(): void {
    this.filters.forEach((filter: IFilter) => {
      if (!filter.selectedOperator?.length) {
        return;
      }

      filter.selectedOperator[0].translatedOperator = this.translate.instant(
        `filterCard.advancedFilter.operators.${filter.selectedOperator[0].operator}`,
      );
    });
  }

  private setOperatorsForType(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) return;

      const fieldType: FieldTypes = filter.selectedField[0].type;

      filter.operators = this.operatorsFor[fieldType]
        .filter((operator: IOperator) => {
          const excludedOperators: SqlOperators[] = _.get(filter, 'selectedField[0].excludeOperators', []);

          return !excludedOperators.includes(operator.sql);
        })
        .map((operator: IOperator, index: number): IOperatorSelect => {
          return {
            id: index,
            operator: operator.name,
            translatedOperator: this.translate.instant(`filterCard.advancedFilter.operators.${operator.name}`),
            type: operator.type,
            sql: operator.sql,
            showSearchBox: {
              showSearchBox: filter.selectedField[0].showSearchBox?.showSearchBox ?? false,
              searchBehaviour: filter.selectedField[0].showSearchBox?.searchBehaviour ?? 'live',
            },
          };
        });
    });
  }

  private translatePredefinedValues(): void {
    if (!this.filters.some((filter: IFilter) => _.get(_.head(filter.selectedField), 'predefinedValues', null))) {
      return;
    }

    for (const filter of this.filters) {
      if (_.isEmpty(filter.selectedField) || _.isEmpty(_.get(filter.selectedField, '0.predefinedValues'))) {
        continue;
      }

      _.get(filter.selectedField, '0.predefinedValues')?.forEach((predefinedValue: IPredefinedSelect) => {
        predefinedValue.name = this.translate.instant(predefinedValue.name);
      });
    }
  }

  public selectAll(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.isActive = true;
    });

    this.onFormChange();
  }

  public deSelectAll(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.isActive = false;
    });

    this.onFormChange();
  }

  private checkOperatorMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (!filter.selectedField?.length || !filter.selectedOperator?.length) {
        return;
      }

      const selectedField: IFieldSelect = filter.selectedField[0];
      const selectedOperator: IOperatorSelect = filter.selectedOperator[0];

      const isValidOperator: boolean = this.operatorsFor[selectedField.type]
        .map((operator: IOperator) => operator.name)
        .includes(selectedOperator.operator);

      if (!isValidOperator) {
        filter.selectedOperator = [];
      }
    });
  }

  private static validateSingleDate(filter: IFilter): boolean {
    return (
      moment(_.get(filter, 'value.startDate', null)).isValid() && moment(_.get(filter, 'value.endDate', null)).isValid()
    );
  }

  private static isFilterValid(filter: IFilter): boolean {
    if (!filter.selectedField?.length || !filter.selectedOperator?.length) {
      return false;
    }

    if (filter.selectedOperator[0].type === OperatorTypes.boolean) {
      return true;
    }

    switch (filter.selectedFieldType) {
      case InputTypes.dateTime:
      case InputTypes.date:
        return AdvancedFilterComponent.validateSingleDate(filter);

      default:
        return !_.isEmpty(filter.value);
    }
  }

  private checkAreSelectedFiltersValid(): void {
    const activeFilters: IFilter[] = this.filters.filter((filter: IFilter) => filter.isActive);

    if (_.isEmpty(activeFilters)) {
      this.isApplyButtonDisabled = false;
      this.isSetDefaultButtonDisabled = false;
      return;
    }

    const isAllValid: boolean = !activeFilters.some((filter: IFilter) => {
      return !AdvancedFilterComponent.isFilterValid(filter);
    });

    if (!isAllValid) {
      this.isApplyButtonDisabled = true;
      this.isSetDefaultButtonDisabled = true;
    } else {
      this.isApplyButtonDisabled = false;
      this.isSetDefaultButtonDisabled = false;
    }
  }

  public checkChanges(): void {
    const difference: IFilter[] = _.differenceWith(this.filters, this.initialFilters, _.isEqual);
    this.isChangesApplied = _.isEmpty(difference);
  }

  private checkOperatorQueryTypeMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (!filter.selectedField?.length) {
        return;
      }

      if (filter.selectedField[0].queryType === QueryTypes.withinRequestQueryString) {
        filter.selectedOperator = filter.operators?.[0] ? [filter.operators[0]] : [];
      }
    });
  }

  private restoreHiddenFieldsIfNotUsed(): void {
    this.hiddenFields.forEach((hiddenField: IHiddenField, index: number) => {
      const isFieldUsed: boolean = this.filters.some((filter: IFilter) => {
        return !_.isEmpty(filter.selectedField) && filter.selectedField[0].path === hiddenField.field.path;
      });

      if (isFieldUsed) {
        return;
      }

      this.fields.splice(hiddenField.atIndex, 0, hiddenField.field);

      // Trigger Angular change detection
      this.fields = _.cloneDeep(this.fields);
      this.hiddenFields.splice(index, 1);
    });

    this.initialFields = _.cloneDeep(this.fields);
  }

  private hideFieldsShouldUsedOnce(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) {
        return;
      }

      if (filter.selectedField[0].queryType === QueryTypes.withinRequestQueryString) {
        const selectedField: IFieldSelect | IPredefinedFieldSelect | undefined = this.fields.find(
          (field: IFieldSelect) => field.path === filter.selectedField[0].path,
        );

        if (!selectedField) {
          return;
        }

        this.hiddenFields.push({
          atIndex: this.fields.indexOf(selectedField),
          field: selectedField,
        });

        this.fields = this.fields.filter((field: IFieldSelect) => {
          return field.path !== filter.selectedField[0].path;
        });
      }
    });

    this.initialFields = _.cloneDeep(this.fields);
  }

  private setHiddenFields(): void {
    this.hideFieldsShouldUsedOnce();
    this.restoreHiddenFieldsIfNotUsed();
  }

  public onFieldChange(filterIndex: number): void {
    this.filters[filterIndex].selectedOperator = [];
    this.filters[filterIndex].value = '';

    // Trigger Angular change detection
    this.filters = _.cloneDeep(this.filters);
    this.onFormChange();
  }

  public onOperatorChange(filter: IFilter): void {
    if (filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime) {
      filter.value = null;
    }

    this.onFormChange();
  }

  public onFormChange(): void {
    const isSomeFiltersActive: boolean = this.filters.some((filter: IFilter) => filter.isActive);

    this.isChangesApplied = false;

    this.setSelectAllType();
    this.setAddButtonState();
    this.setDeleteButtonState(isSomeFiltersActive);
    this.setFieldsValueInputType();
    this.setOperatorsForType();
    this.checkOperatorMatch();
    this.checkAreSelectedFiltersValid();
    this.translatePredefinedValues();
    this.checkChanges();
    this.checkOperatorValueMatch();
    this.checkOperatorQueryTypeMatch();
    this.setHiddenFields();
  }

  public onSubmit(isValid: boolean): void {
    if (!isValid) return;

    if (this.filterCard$?.invalidUpdate) {
      this.toast.error(this.filterCard$?.updateMessage ?? undefined, this.translate.instant('general.error'), {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });

      return;
    }

    if (this.submitType === SubmitTypes.apply) {
      this.onApply();
    } else if (this.submitType === SubmitTypes.setAsDefault) {
      this.onSetAsDefault();
    }
  }

  public inputInvalid($event: boolean): void {
    this.isApplyButtonDisabled = !$event;
    this.isSetDefaultButtonDisabled = !$event;
  }

  public clickSetAsDefaultButton(): void {
    this.submitType = SubmitTypes.setAsDefault;
  }

  public clickApplyButton(): void {
    this.submitType = SubmitTypes.apply;
  }

  public async onSetAsDefault(): Promise<void> {
    this.store.dispatch(
      new AdvancedFilterActions.SaveFilterConfig(this.userId$, this.filterObject.pageName, _.cloneDeep(this.filters)),
    );
  }

  public setAppliedFilterCount(): void {
    this.appliedFilterCount = this.filters.filter((filter: IFilter) => filter.isActive).length;
  }

  private checkOperatorValueMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (!filter.selectedOperator?.length) {
        return;
      }

      if (filter.selectedOperator[0].type === OperatorTypes.boolean) {
        filter.value = '';
      }
    });
  }

  public onDontSave(): void {
    this.filters = _.cloneDeep(this.initialFilters);
    this.onFormChange();
    this.closeAllModals();
  }

  public onApply(afterSetAsDefault?: boolean): void {
    this.outputSubject.next({
      [this.outputOptions.filterObjectId]: {
        filters: this.advancedFilterService.prepareForOutput(_.cloneDeep(this.filters)),
        rawFilters: _.cloneDeep(this.filters),
        target: this.targetEndpoint,
        page: this.filterObject.pageName,
      },
    });
    this.setAppliedFilterCount();
    this.initialFilters = _.cloneDeep(this.filters);
    this.isChangesApplied = true;
    this.closeAllModals();
    this.advancedFilterService.updateTable();

    if (afterSetAsDefault) {
      this.store
        .select('advancedFilterStore')
        .pipe(take(1))
        .subscribe((state: IAdvancedFilterStore) => {
          this.store.dispatch(
            new AdvancedFilterActions.SetDefaultFilters({
              ...state.defaultFilters,
              [this.filterObject.pageName]: _.cloneDeep(this.filters),
            }),
          );
        });
    }
  }

  public ngOnDestroy(): void {
    this.closeAllModals();
    this.advancedFilterService.unsetFilterCard();

    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
