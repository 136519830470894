import * as Actions from './user-configuration.actions';
import { IUserConfigurationState } from './user-configuration.model';
import { Action } from '@ngrx/store';

const initialState: IUserConfigurationState = {
  userConfigurationDataLoading: false,
  userConfigurationDataLoaded: false,
  userConfigurationData: null,
  temporaryMode: false,
};

export function userConfigurationReducer(
  state: IUserConfigurationState = initialState,
  baseAction: Action,
): IUserConfigurationState {
  const action = baseAction as Actions.UserConfigurationActions;

  switch (action.type) {
    case Actions.GET_USER_CONFIGURATION_DATA_LOADING:
    case Actions.UPDATE_USER_CONFIGURATION_LOADING:
      return {
        ...state,
        userConfigurationDataLoading: true,
        userConfigurationDataLoaded: false,
      };
    case Actions.GET_USER_CONFIGURATION_DATA_LOADED:
    case Actions.UPDATE_USER_CONFIGURATION_LOADED:
      return {
        ...state,
        userConfigurationDataLoading: false,
        userConfigurationDataLoaded: true,
        userConfigurationData: action.payload ? action.payload : null,
      };

    case Actions.USER_CONFIGURATION_FETCH_ERROR:
      return {
        ...state,
        userConfigurationDataLoading: false,
        userConfigurationDataLoaded: true,
      };

    case Actions.UPDATE_TEMPORARY_MODE:
      return {
        ...state,
        temporaryMode: action.temporaryMode,
      };
    default:
      return state;
  }
}
