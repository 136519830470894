import { Action } from '@ngrx/store';
import {
  IGetLogbookHistoryParams,
  IGetLogbookVersionsParams,
  ILogbook,
  ILogbookExcelWithError,
  ILogbookSettings,
  ILogbookStates,
  ILogbookTableQuery,
  IStateStates,
  LogbookAndLogbookVersionLocationType,
  MasterDataLocationType,
} from './logbooks.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import {
  ILogbookMasterData,
  ILogbookMasterDataDetail,
} from '../settings/logbook-master-data/logbook-master-data.model';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import { ILogbookCreate, ILogbookHistory, IOrderByPayload } from '../../view/settings/logbook/logbook.model';
import { IWorkflowStepsData } from '../../view/settings/workflows/workflows.model';
import { ILogbookVersion, IPassedIssueDateLogbooks } from '../logbook-versions/logbook-versions.model';
import { ICloneLogbookPayload } from '../../view/settings/logbook-versions/logbook-versions.model';
import { IForm } from '../forms/forms.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { ITableHeader } from '../../../constants.model';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

export const LOGBOOKS_DATA_LOADING = '[LOGBOOKS] LOGBOOKS DATA LOAD';
export const LOGBOOKS_DATA_LOADED = '[LOGBOOKS] LOGBOOKS DATA LOADED';
export const GET_LOGBOOK_CHILDREN_DATA_LOADING = '[LOGBOOKS] LOGBOOK CHILDREN DATA LOADING';
export const GET_LOGBOOK_CHILDREN_DATA_LOADED = '[LOGBOOKS] LOGBOOK CHILDREN DATA LOADED';
export const ADD_LOGBOOK = '[LOGBOOKS] ADD LOGBOOKS';
export const ADD_LOGBOOK_COMPLETED = '[LOGBOOKS] ADD LOGBOOKS COMPLETED';
export const ADD_LOGBOOK_VERSION = '[LOGBOOKS] ADD LOGBOOK VERSION';
export const ADD_LOGBOOK_VERSION_COMPLETED = '[LOGBOOKS] ADD LOGBOOK VERSION COMPLETED';
export const EDIT_LOGBOOK = '[LOGBOOKS] EDIT LOGBOOKS';
export const EDIT_LOGBOOK_COMPLETED = '[LOGBOOKS] EDIT LOGBOOKS COMPLETED';
export const DELETE_LOGBOOK_VERSION = '[LOGBOOKS] DELETE LOGBOOK VERSION';
export const DELETE_LOGBOOK_VERSION_COMPLETED = '[LOGBOOKS] DELETE LOGBOOK VERSION COMPLETED';
export const CLONE_LOGBOOK = '[LOGBOOKS] CLONE LOGBOOK';
export const CLONE_LOGBOOK_COMPLETED = '[LOGBOOKS] CLONE LOGBOOK COMPLETED';
export const GET_SPECIFIC_LOGBOOK_DATA_LOADING = '[LOGBOOKS] LOGBOOK DATA LOADING';
export const GET_SPECIFIC_LOGBOOK_DATA_LOADED = '[LOGBOOKS] LOGBOOK DATA LOADED';
export const WORKFLOW_STEP_DATA_LOADING = '[LOGBOOKS] WORKFLOW STEP DATA LOADING';
export const WORKFLOW_STEP_DATA_LOADED = '[LOGBOOKS] WORKFLOW STEP DATA LOADED';
export const WORKFLOW_DATA_LOADING = '[LOGBOOKS] WORKFLOW DATA LOADING';
export const WORKFLOW_DATA_LOADED = '[LOGBOOKS] WORKFLOW DATA LOADED';
export const GET_LOGBOOK_VERSION = '[LOGBOOKS] GET ALL LOGBOOK VERSIONS';
export const GET_LOGBOOK_VERSION_LOADED = '[LOGBOOKS] GET ALL LOGBOOK VERSIONS LOADED';
export const GET_LOGBOOK_FORMS = '[LOGBOOKS] GET LOGBOOK FORMS';
export const GET_LOGBOOK_FORMS_COMPLETED = '[LOGBOOKS] GET LOGBOOK FORMS COMPLETED';
export const GET_LOGBOOK_MASTER_DATA = '[LOGBOOKS] GET LOGBOOK MASTER DATA';
export const GET_LOGBOOK_MASTER_DATA_COMPLETED = '[LOGBOOKS] GET LOGBOOK MASTER DATA COMPLETED';
export const GET_ACTIVE_LOGBOOK_MASTER_DATA = '[LOGBOOKS] GET ACTIVE LOGBOOK MASTER DATA';
export const GET_ACTIVE_LOGBOOK_MASTER_DATA_COMPLETED = '[LOGBOOKS] GET ACTIVE LOGBOOK MASTER DATA COMPLETED';
export const SEND_LOGBOOK_TO_ARCHIVE = '[LOGBOOKS] SEND LOGBOOK TO ARCHIVE ';
export const SEND_LOGBOOK_TO_ARCHIVE_COMPLETED = '[LOGBOOKS] ARCHIVE LOGBOOK';
export const ARCHIVE_LOGBOOK = '[LOGBOOKS] ARCHIVE LOGBOOK ';
export const ARCHIVE_LOGBOOK_COMPLETED = '[LOGBOOKS] ARCHIVE LOGBOOK COMPLETED';
export const SUBMIT_LOGBOOK = '[LOGBOOKS] SEND LOGBOOK TO SUBMIT ';
export const SUBMIT_LOGBOOK_COMPLETED = '[LOGBOOKS] SUBMIT LOGBOOK COMPLETED';
export const ACTIVATE_LOGBOOK = '[LOGBOOKS] ACTIVATE LOGBOOK ';
export const ACTIVATE_LOGBOOK_COMPLETED = '[LOGBOOKS] ACTIVATE LOGBOOK COMPLETED';
export const GET_LOGBOOK_HISTORY = '[LOGBOOKS] GET LOGBOOK HISTORY';
export const GET_LOGBOOK_HISTORY_COMPLETED = '[LOGBOOKS] GET LOGBOOK HISTORY COMPLETED';
export const EDIT_LOGBOOK_VERSION_ORDER_BY = '[LOGBOOKS] EDIT LOGBOOK VERSION ORDER BY';
export const EDIT_LOGBOOK_VERSION_ORDER_BY_COMPLETED = '[LOGBOOKS] EDIT LOGBOOK VERSION ORDER BY COMPLETED';
export const GET_ADD_EDIT_MODAL_DATA = '[LOGBOOKS] GET ADD EDIT MODAL DATA';
export const GET_ADD_EDIT_MODAL_DATA_COMPLETED = '[LOGBOOKS] GET ADD EDIT MODAL DATA COMPLETED';
export const CHANGE_LOGBOOK_PARENT = '[LOGBOOKS] CHANGE LOGBOOK PARENT';
export const CHANGE_LOGBOOK_PARENT_COMPLETED = '[LOGBOOKS] CHANGE LOGBOOK PARENT COMPLETED';
export const CHECK_PASSED_ISSUE_DATE = '[LOGBOOKS] CHECK PASSED ISSUE DATE';
export const CHECK_PASSED_ISSUE_DATE_COMPLETED = '[LOGBOOKS] CHECK PASSED ISSUE DATE COMPLETED';
export const GET_ALL_FORMS = '[LOGBOOKS] GET ALL FORMS';
export const GET_ALL_FORMS_COMPLETED = '[LOGBOOKS] GET ALL FORMS COMPLETED';
export const DOWNLOAD_EXCEL = '[LOGBOOKS] LOGBOOK EXCEL DOWNLOAD';
export const DOWNLOAD_EXCEL_COMPLETED = '[LOGBOOKS] LOGBOOK EXCEL DOWNLOAD COMPLETED';
export const UPLOAD_EXCEL = '[LOGBOOKS] LOGBOOK EXCEL UPLOAD';
export const UPLOAD_EXCEL_COMPLETED = '[LOGBOOKS] LOGBOOK EXCEL UPLOAD COMPLETED';
export const DOWNLOAD_ERROR_EXCEL = '[LOGBOOKS] LOGBOOK ERROR EXCEL DOWNLOAD';
export const DOWNLOAD_ERROR_EXCEL_COMPLETED = '[LOGBOOKS] LOGBOOK ERROR EXCEL DOWNLOAD COMPLETED';
export const SET_TABLE_SETTINGS = '[LOGBOOKS] LOGBOOK SET TABLE SETTINGS';
export const SET_ALL_LOGBOOK_VERSION_TABLE_SETTINGS = '[LOGBOOKS] SET ALL LOGBOOK VERSION TABLE SETTINGS';
export const BULK_SUBMIT_LOGBOOKS = '[LOGBOOKS] BULK SUBMIT LOGBOOKS';
export const BULK_SUBMIT_LOGBOOKS_COMPLETED = '[LOGBOOKS] BULK SUBMIT LOGBOOKS COMPLETED';
export const BULK_ACTIVATE_LOGBOOKS = '[LOGBOOKS] BULK ACTIVATE LOGBOOKS';
export const BULK_ACTIVATE_LOGBOOKS_COMPLETED = '[LOGBOOKS] BULK ACTIVATE LOGBOOKS COMPLETED';
export const GET_LOGBOOK_STATES_DATA_LOADING = '[LOGBOOKS] LOGBOOK STATES DATA LOADING';
export const GET_LOGBOOK_STATES_DATA_LOADED = '[LOGBOOKS] LOGBOOK STATES DATA LOADED';
export const GET_STATES_LIST_DATA_LOADING = '[LOGBOOKS] STATES LIST DATA LOADING';
export const GET_STATES_LIST_DATA_LOADED = '[LOGBOOKS] STATES LIST DATA LOADED';
export const UPDATE_LOGBOOK_STATES = '[LOGBOOKS] UPDATE LOGBOOK STATES';
export const UPDATE_LOGBOOK_STATES_COMPLETED = '[LOGBOOKS] UPDATE LOGBOOK STATES COMPLETED';
export const UPDATE_LOGBOOK_CURRENT_STATES = '[LOGBOOKS] UPDATE LOGBOOK ACTIVE STATES';
export const UPDATE_LOGBOOK_CURRENT_STATES_COMPLETED = '[LOGBOOKS] UPDATE LOGBOOK ACTIVE STATES COMPLETED';
export const FETCH_ERROR = '[LOGBOOKS] FETCH ERROR';

export class LogbooksDataLoading implements Action {
  readonly type = LOGBOOKS_DATA_LOADING;

  constructor(
    public tableQuery?: ILogbookTableQuery,
    public statuses?: number[],
    public issuer?: IIssuer,
    public includeArchived: boolean = true,
    public dispatchHideLoader: boolean = true,
  ) {}
}

export class LogbooksDataLoaded implements Action {
  readonly type = LOGBOOKS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class GetLogbookChildrenDataLoading implements Action {
  readonly type = GET_LOGBOOK_CHILDREN_DATA_LOADING;

  constructor(public parentId: number, public statusIds?: number[], public searchString?: string | null) {}
}

export class GetLogbookChildrenDataLoaded implements Action {
  readonly type = GET_LOGBOOK_CHILDREN_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class AddLogbook implements Action {
  readonly type = ADD_LOGBOOK;

  constructor(public logbook: any, public issuer: IIssuer | null) {}
}

export class AddLogbookCompleted implements Action {
  readonly type = ADD_LOGBOOK_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbook>) {}
}

export class AddLogbookVersion implements Action {
  readonly type = ADD_LOGBOOK_VERSION;

  constructor(public logbookId: number | null, public logbookVersion: any, public issuer: IIssuer | null) {}
}

export class AddLogbookVersionCompleted implements Action {
  readonly type = ADD_LOGBOOK_VERSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbookVersion>) {}
}

export class EditLogbook implements Action {
  readonly type = EDIT_LOGBOOK;

  constructor(
    public logbook: any,
    public logbookId: number | undefined,
    public logbookVersionId: number | null,
    public issuer: IIssuer | null,
  ) {}
}

export class EditLogbookCompleted implements Action {
  readonly type = EDIT_LOGBOOK_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbook>) {}
}

export class DeleteLogbookVersion implements Action {
  readonly type = DELETE_LOGBOOK_VERSION;

  constructor(
    public logbookId: number | null,
    public logbookVersionId: number | null,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class DeleteLogbookVersionCompleted implements Action {
  readonly type = DELETE_LOGBOOK_VERSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbookVersion>) {}
}

export class GetSpecificLogbookData implements Action {
  readonly type = GET_SPECIFIC_LOGBOOK_DATA_LOADING;

  constructor(
    public dispatchLocation: LogbookAndLogbookVersionLocationType,
    public logbookId: number | null = null,
    public logbookVersionId: number | null = null,
    public issuer?: IIssuer,
    public previousVersionId: number | null = null,
    public isIncludeDisabled: boolean = false,
    public isOnlyActiveForms: boolean = true,
  ) {}
}

export class GetSpecificLogbookDataLoaded implements Action {
  readonly type = GET_SPECIFIC_LOGBOOK_DATA_LOADED;

  constructor(public payload: ILogbookSettings, public dispatchLocation: LogbookAndLogbookVersionLocationType) {}
}

export class WorkflowStepsLoading implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADING;

  constructor(public id: number) {}
}

export class WorkflowStepsLoaded implements Action {
  readonly type = WORKFLOW_STEP_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflowStepsData>) {}
}

export class GetAllLogbookVersions implements Action {
  readonly type = GET_LOGBOOK_VERSION;

  constructor(
    public params: IGetLogbookVersionsParams,
    public dispatchLocation: LogbookAndLogbookVersionLocationType,
  ) {}
}

export class GetAllLogbookVersionsCompleted implements Action {
  readonly type = GET_LOGBOOK_VERSION_LOADED;

  constructor(
    public payload: IGetManyResponse<ILogbookVersion>,
    public dispatchLocation: LogbookAndLogbookVersionLocationType,
  ) {}
}

export class CloneLogbook implements Action {
  readonly type = CLONE_LOGBOOK;

  constructor(
    public clonedLogbook: ICloneLogbookPayload,
    public logbookId: number | undefined,
    public issuer: IIssuer | null,
  ) {}
}

export class CloneLogbookCompleted implements Action {
  readonly type = CLONE_LOGBOOK_COMPLETED;

  constructor(public payload: IGetManyResponse<any>) {}
}

export class GetLogbookForms implements Action {
  readonly type = GET_LOGBOOK_FORMS;

  constructor(public formIds: number[]) {}
}

export class GetLogbookFormsCompleted implements Action {
  readonly type = GET_LOGBOOK_FORMS_COMPLETED;

  constructor(public payload: IGetManyResponse<any>) {}
}

export class GetLogbooksMasterData implements Action {
  readonly type = GET_LOGBOOK_MASTER_DATA;

  constructor(public masterDataId: number, public dispatchLocation: MasterDataLocationType) {}
}

export class GetLogbooksMasterDataCompleted implements Action {
  readonly type = GET_LOGBOOK_MASTER_DATA_COMPLETED;

  constructor(
    public payload: IBaseOneResponse<ILogbookMasterDataDetail>,
    public dispatchLocation: MasterDataLocationType,
  ) {}
}

export class GetActiveLogbooksMasterData implements Action {
  readonly type = GET_ACTIVE_LOGBOOK_MASTER_DATA;

  constructor(public dispatchHideLoader: boolean = true) {}
}

export class GetActiveLogbooksMasterDataCompleted implements Action {
  readonly type = GET_ACTIVE_LOGBOOK_MASTER_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbookMasterData>) {}
}

export class SendLogbookToArchive implements Action {
  readonly type = SEND_LOGBOOK_TO_ARCHIVE;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class SendLogbookToArchiveCompleted implements Action {
  readonly type = SEND_LOGBOOK_TO_ARCHIVE_COMPLETED;

  constructor(public payload: any) {}
}

export class ArchiveLogbook implements Action {
  readonly type = ARCHIVE_LOGBOOK;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class ArchiveLogbookCompleted implements Action {
  readonly type = ARCHIVE_LOGBOOK_COMPLETED;

  constructor(public payload: any) {}
}

export class SubmitLogbook implements Action {
  readonly type = SUBMIT_LOGBOOK;

  constructor(public logbookId: number, public logbookVersionId: number, public issuer: IIssuer | null) {}
}

export class SubmitLogbookCompleted implements Action {
  readonly type = SUBMIT_LOGBOOK_COMPLETED;

  constructor(public payload: any) {}
}

export class ActivateLogbook implements Action {
  readonly type = ACTIVATE_LOGBOOK;

  constructor(public logbookId: number, public logbookVersionId: number, public issuer: IIssuer | null) {}
}

export class ActivateLogbookCompleted implements Action {
  readonly type = ACTIVATE_LOGBOOK_COMPLETED;

  constructor(public payload: any) {}
}

export class GetLogbookHistory implements Action {
  readonly type = GET_LOGBOOK_HISTORY;

  constructor(
    public params: IGetLogbookHistoryParams,
    public logbookID?: number,
    public getTemplateHistory: boolean = false,
  ) {}
}

export class GetLogbookHistoryCompleted implements Action {
  readonly type = GET_LOGBOOK_HISTORY_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbookHistory>) {}
}

export class EditLogbookVersionOrderBy implements Action {
  readonly type = EDIT_LOGBOOK_VERSION_ORDER_BY;

  constructor(public logbookVersionIds: IOrderByPayload[]) {}
}

export class EditLogbookVersionOrderByCompleted implements Action {
  readonly type = EDIT_LOGBOOK_VERSION_ORDER_BY_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbookVersion>) {}
}

export class GetAddEditModalData implements Action {
  readonly type = GET_ADD_EDIT_MODAL_DATA;

  constructor(
    public logbookId: number | null,
    public logbookVersionId: number | null,
    public issuer: IIssuer | undefined,
    public isEdit: boolean,
  ) {}
}

export class GetAddEditModalDataCompleted implements Action {
  readonly type = GET_ADD_EDIT_MODAL_DATA_COMPLETED;

  constructor(public payload: any, public isEdit: boolean) {}
}

export class ChangeLogbookParent implements Action {
  readonly type = CHANGE_LOGBOOK_PARENT;

  constructor(
    public logbookId: number,
    public logbookVersionId: number,
    public parentLogbookId: number | null,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class ChangeLogbookParentCompleted implements Action {
  readonly type = CHANGE_LOGBOOK_PARENT_COMPLETED;

  constructor(public payload: IBaseOneResponse<ILogbookVersion>) {}
}

export class CheckPassedIssueDate implements Action {
  readonly type = CHECK_PASSED_ISSUE_DATE;
}

export class CheckPassedIssueDateCompleted implements Action {
  readonly type = CHECK_PASSED_ISSUE_DATE_COMPLETED;

  constructor(public payload: IGetManyResponse<IPassedIssueDateLogbooks>) {}
}

export class GetAllForms implements Action {
  readonly type = GET_ALL_FORMS;
}

export class GetAllFormsCompleted implements Action {
  readonly type = GET_ALL_FORMS_COMPLETED;

  constructor(public payload: IGetManyResponse<IForm>) {}
}

export class GetWorkflowData implements Action {
  readonly type = WORKFLOW_DATA_LOADING;
}

export class GetWorkflowDataCompleted implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(public activeMasterDataData: ILogbookMasterDataDetail, public workflows: IWorkflow[]) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_EXCEL_COMPLETED;
}

export class UploadExcel implements Action {
  readonly type = UPLOAD_EXCEL;

  constructor(
    public logbooks: ILogbookCreate[],
    public issuerAndReason: IIssuerAndReason,
    public activeMasterDataData: ILogbookMasterDataDetail,
    public dispatchLocation: LogbookAndLogbookVersionLocationType,
    public scopeId?: number | null,
  ) {}
}

export class UploadExcelCompleted implements Action {
  readonly type = UPLOAD_EXCEL_COMPLETED;

  constructor(
    public payload: (ILogbookExcelWithError & { errorMessages?: string })[],
    public success: boolean,
    public issuerAndReason: IIssuerAndReason | null,
    public dispatchLocation: LogbookAndLogbookVersionLocationType,
  ) {}
}

export class DownloadErrorExcel implements Action {
  readonly type = DOWNLOAD_ERROR_EXCEL;

  constructor(
    public payload: ILogbookExcelWithError[],
    public activeMasterDataData: ILogbookMasterDataDetail,
    public workflow: IWorkflow[],
    public logbooks: ILogbook[],
    public forms: IForm[],
    public withData: boolean,
    public issuerAndReason?: IIssuerAndReason | null,
  ) {}
}

export class DownloadErrorExcelCompleted implements Action {
  readonly type = DOWNLOAD_ERROR_EXCEL_COMPLETED;

  constructor(public issuerAndReason?: IIssuerAndReason | null) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class SetAllLogbookVersionTableSettings implements Action {
  readonly type = SET_ALL_LOGBOOK_VERSION_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class BulkSubmitLogbooks implements Action {
  readonly type = BULK_SUBMIT_LOGBOOKS;

  constructor(public logbookVersionIds: number[], public issuer: IIssuer | null) {}
}

export class BulkSubmitLogbooksCompleted implements Action {
  readonly type = BULK_SUBMIT_LOGBOOKS_COMPLETED;

  constructor(public response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) {}
}

export class BulkActivateLogbooks implements Action {
  readonly type = BULK_ACTIVATE_LOGBOOKS;

  constructor(public logbookVersionIds: number[], public issuer: IIssuer | null) {}
}

export class BulkActivateLogbooksCompleted implements Action {
  readonly type = BULK_ACTIVATE_LOGBOOKS_COMPLETED;

  constructor(public response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) {}
}

export class GetLogbookStatesDataLoading implements Action {
  readonly type = GET_LOGBOOK_STATES_DATA_LOADING;

  constructor(public logbookId: number) {}
}

export class GetLogbookStatesDataLoaded implements Action {
  readonly type = GET_LOGBOOK_STATES_DATA_LOADED;

  constructor(public payload: IGetOneResponse<ILogbookStates>) {}
}

export class GetStatesListDataLoading implements Action {
  readonly type = GET_STATES_LIST_DATA_LOADING;

  constructor() {}
}

export class GetStatesListDataLoaded implements Action {
  readonly type = GET_STATES_LIST_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IStateStates>) {}
}

export class UpdateLogbookStates implements Action {
  readonly type = UPDATE_LOGBOOK_STATES;

  constructor(
    public logbookId: number,
    public logbookStates: ILogbookStates,
    public issuerAndReason?: IIssuerAndReason | null,
  ) {}
}

export class UpdateLogbookStatesCompleted implements Action {
  readonly type = UPDATE_LOGBOOK_STATES_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbookStates>) {}
}

export class UpdateLogbookCurrentStates implements Action {
  readonly type = UPDATE_LOGBOOK_CURRENT_STATES;

  constructor(public logbookId: number, public states: number[], public issuerAndReason: IIssuerAndReason | null) {}
}

export class UpdateLogbookCurrentStatesCompleted implements Action {
  readonly type = UPDATE_LOGBOOK_CURRENT_STATES_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbookStates>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object, public errorType?: string) {}
}

export type LogbooksActions =
  | LogbooksDataLoading
  | LogbooksDataLoaded
  | AddLogbook
  | AddLogbookCompleted
  | AddLogbookVersion
  | AddLogbookVersionCompleted
  | EditLogbook
  | EditLogbookCompleted
  | DeleteLogbookVersion
  | DeleteLogbookVersionCompleted
  | GetSpecificLogbookDataLoaded
  | GetSpecificLogbookData
  | WorkflowStepsLoading
  | WorkflowStepsLoaded
  | GetAllLogbookVersions
  | GetAllLogbookVersionsCompleted
  | GetLogbookForms
  | GetLogbookFormsCompleted
  | CloneLogbook
  | CloneLogbookCompleted
  | GetLogbooksMasterData
  | GetLogbooksMasterDataCompleted
  | GetActiveLogbooksMasterData
  | GetActiveLogbooksMasterDataCompleted
  | SendLogbookToArchive
  | SendLogbookToArchiveCompleted
  | ArchiveLogbook
  | ArchiveLogbookCompleted
  | SubmitLogbook
  | SubmitLogbookCompleted
  | ActivateLogbook
  | ActivateLogbookCompleted
  | GetLogbookHistory
  | GetLogbookHistoryCompleted
  | GetLogbookChildrenDataLoading
  | GetLogbookChildrenDataLoaded
  | EditLogbookVersionOrderBy
  | EditLogbookVersionOrderByCompleted
  | GetAddEditModalData
  | GetAddEditModalDataCompleted
  | ChangeLogbookParent
  | ChangeLogbookParentCompleted
  | CheckPassedIssueDate
  | CheckPassedIssueDateCompleted
  | GetAllForms
  | GetAllFormsCompleted
  | GetWorkflowData
  | GetWorkflowDataCompleted
  | UploadExcel
  | UploadExcelCompleted
  | DownloadErrorExcel
  | DownloadErrorExcelCompleted
  | SetTableSettings
  | SetAllLogbookVersionTableSettings
  | FetchError
  | BulkSubmitLogbooks
  | BulkSubmitLogbooksCompleted
  | BulkActivateLogbooks
  | BulkActivateLogbooksCompleted
  | GetLogbookStatesDataLoading
  | GetLogbookStatesDataLoaded
  | GetStatesListDataLoading
  | GetStatesListDataLoaded
  | UpdateLogbookStates
  | UpdateLogbookStatesCompleted
  | UpdateLogbookCurrentStates
  | UpdateLogbookCurrentStatesCompleted;
