import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IFormSubmission } from '../../home/home.model';
import { ITableHeader } from '../../../../constants.model';

export const GET_LOGS_REPORTS_DATA = '[Logs Reports] Get Logs Reports Data';
export const GET_LOGS_REPORTS_DATA_COMPLETED = '[Logs Reports] Get Logs Reports Data Completed';
export const SET_TABLE_SETTINGS = '[Logs Reports] Set Table Settings';
export const SET_SELECTED_FILTERS = '[Logs Reports] Set Selected Filters';
export const DOWNLOAD_EXCEL = '[Logs Reports] Download Excel';
export const DOWNLOAD_EXCEL_COMPLETED = '[Logs Reports] Download Excel Completed';
export const FETCH_ERROR = '[Logs Reports] Fetch Error';

export class GetLogsReportsData implements Action {
  readonly type = GET_LOGS_REPORTS_DATA;

  constructor(public tableQuery: ITableQuery) {}
}

export class GetLogsReportsDataCompleted implements Action {
  readonly type = GET_LOGS_REPORTS_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<IFormSubmission>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: ITableQuery) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(public filters: ITableQuery, public selectedHeaders: string[]) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export type LogsReportsActions =
  | GetLogsReportsData
  | GetLogsReportsDataCompleted
  | SetTableSettings
  | SetSelectedFilters
  | DownloadExcel
  | DownloadExcelCompleted
  | FetchError;
