import { Column, ValueType, DataValidation, Style } from 'exceljs';
import { IGenericObject } from '../../model/interface/generic.model';

export const EXCEL_DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const EXCEL_DEFAULT_TIME_FORMAT = 'HH:MM';
export const CELL_ADDRESS_CONSTANT = '$_CELLADDRESS_$';
export const TWO_DIGIT_NUMBER_FORMAT = '0.00############################';
export const EXCEL_FONT_FAMILY = {
  arial: { name: 'Arial Black', value: 2 },
};

export type TExcelProcessorRequestMessage<P = any, D = any> = {
  action: EExcelExporterWorkerRequestAction;
  payload: P;
  /**
   * @description Passed worker dependencies can not be services or classes that require the main thread context
   */
  dependencies?: D;
};

export type TExcelProcessorResponseMessage<P = any> = {
  action: EExcelExportWorkerResponseAction;
  payload: P;
  workerID: string;
};

export type TExcelProcessorHandler<P = any, D = any> = (message: TExcelProcessorRequestMessage<P, D>) => void;

export type TExportExcelProcessMessagePayload = {
  sheetTitle: string;
  excelName: string;
  params: ICreateExcel;
  options: Partial<ICreateAndDownloadOptions>;
};

export enum ECellTypes {
  LIST = 'list',
  WHOLE = 'whole',
  DECIMAL = 'decimal',
  DATE = 'date',
  TEXTLENGTH = 'textLength',
  CUSTOM = 'custom',
  READONLY = 'readOnly',
}

export enum EExcelSheetState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  VERY_HIDDEN = 'veryHidden',
}

/**
 * @description Action keys which worker receives and must handle
 */
export enum EExcelExporterWorkerRequestAction {
  EXECUTE_EXPORT_PROCESS = 'EXECUTE_EXPORT_PROCESS',
  TERMINATE = 'TERMINATE',
  SET_WORKER_ID = 'SET_WORKER_ID',
}

/**
 * @description Action keys which worker sends back to the main thread
 */
export enum EExcelExportWorkerResponseAction {
  TRANSMIT_RESULT = 'TRANSMIT_RESULT',
  ERROR = 'ERROR',
}

export interface IExcelDropdownSettings {
  data: any[] | null;
  prop: string;
  dataProperty: string;
  dataId: string;
  sheetName?: string;
  primaryKeyColumnWidth?: number;
}

export interface IExcelColumnDefinition extends Partial<Column> {
  header: string;
  key: string;
  width: number;
  type: ValueType;
  dataValidation: Partial<DataValidation> & Pick<DataValidation, 'type'>;
  dropdownOptions?: IExcelDropdownSettings;
  hidden?: boolean;
  style?: Partial<Style>;
  maxLength?: number;
  allowPunctuation?: boolean;
  number?: number;
  isAdditional?: boolean;
  isDateTimeFormat?: boolean;
  removePropertyIfNull?: boolean;
  isRequired?: boolean;
}

export interface ICreateExcel {
  data?: any[];
  columns: IExcelColumnDefinition[];
}

export interface IExcelColumnKeys {
  [header: string]: {
    key: string;
    type: ValueType;
    dataValidationType: ECellTypes;
    isDateTimeFormat?: boolean;
    removePropertyIfNull?: boolean;
  };
}

export interface IDownloadExcelFilters {
  siteId: number;
  selectedDownloadOffset: string | null;
  limit: number;
}

export interface IDataWithExcelId {
  excelId: string;
  excelLabel: string;
}

export interface IDependentColumnConfiguration {
  data: IGenericObject<any>[];
  key: string;
  label: string;
  relatesToField?: string;
}

export interface IEnumeratedDropdownOption {
  id: string;
  name: string;
}

export interface IExcelDateFormatInformation {
  timeFormat$: string;
  dateFormat$: string;
  timezone: string;
  locale$?: string;
  dateFormatRaw$?: string;
  dateTimeFormatRaw$?: string;
}

export interface ICreateAndDownloadOptions {
  withData: boolean;
  timezone: string;
  dateFormat: string;
  timeFormat: string;
  createAsCsvFile: boolean;
  isDisabledColumnsFirstLine: boolean;
  addDateTimeFormula: boolean;
  excelRowFormatLimit: number;
  locked: boolean;
  fixColumnKeyIssues: boolean;
  masterDataID?: number;
}
