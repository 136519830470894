import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalFailedItemsComponent } from './scw-mat-modal-failed-items.component';

@NgModule({
  imports: [CommonModule],
  exports: [ScwMatModalFailedItemsComponent],
  declarations: [ScwMatModalFailedItemsComponent],
})
export class ScwMatModalFailedItemsModule {}
