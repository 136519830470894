import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScwMatInputRule, ScwMatInputSize, ScwMatInputType } from './scw-mat-input.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../../store/logbook.reducer';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { TSeparator } from '../../../../../constants';

@Component({
  selector: 'scw-mat-input',
  templateUrl: './scw-mat-input.component.html',
  styleUrls: ['./scw-mat-input.component.scss'],
  host: { class: 'scw-mat-input-host' },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScwMatInputComponent),
      multi: true,
    },
  ],
})
export class ScwMatInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() inputModel: any;
  @Input() isValid: boolean = false;
  @Input() label: string | null = null;
  @Input() type: ScwMatInputType = 'text';
  @Input() size: ScwMatInputSize = 'md';
  @Input() placeholder: string | null = null;
  @Input() hint: string | null = null;
  @Input() maxlength: string | null = null;
  @Input() disabled: boolean = false;
  @Input() hideEyeIcon: boolean = false;
  @Input() block: boolean = false;
  @Input() className!:
    | string[]
    | {
        [klass: string]: any;
      };
  @Input() hasErrors: boolean = false;
  @Input() errorText: string | null = null;
  @Input() rules: ScwMatInputRule[] = [];
  @Input() noPadding: boolean = false;
  @Input() dataPropertyKey?: string;
  @Input() autocompleteAttribute?: string;
  @Input() shouldAutoComplete: boolean = false;

  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyupEnter: EventEmitter<any> = new EventEmitter<any>();

  public manageableType: ScwMatInputType = 'text';
  private isAnyError: boolean = false;
  private decimalSeparator: TSeparator = '.';
  private readonly emailRegex: RegExp = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  private readonly integerRegex: RegExp = new RegExp(/^(?!-0$)-?(0|[1-9][0-9]*)$/);
  private readonly subscriptions: Subscription[] = [];
  private onChange: any = () => {};
  private onTouch: any = () => {};

  constructor(private readonly store: Store<LogbookAppState>, private translate: TranslateService) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(input: string): void {
    this.inputModel = input;
  }

  public reset(): void {
    if (this.type === 'password') {
      this.manageableType = 'password';
    }

    this.inputModel = '';
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatInputRule): void {
    if (_.isNil(this.inputModel) || this.inputModel.length === 0) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private minValueRule(rule: ScwMatInputRule): void {
    if (this.inputModel && (!this.integerRegex.test(this.inputModel) || this.inputModel < (rule.minValue ?? 0))) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.minValue', { minValue: rule.minValue }),
      );
    }
  }

  private maxValueRule(rule: ScwMatInputRule): void {
    if (this.inputModel && (!this.integerRegex.test(this.inputModel) || this.inputModel > (rule.maxValue ?? 0))) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.maxValue', { maxValue: rule.maxValue }),
      );
    }
  }

  private minLengthRule(rule: ScwMatInputRule): void {
    if (this.inputModel && this.inputModel.length < (rule.minLength ?? 0)) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.minLength', { minLength: rule.minLength }),
      );
    }
  }

  private maxLengthRule(rule: ScwMatInputRule): void {
    if (this.inputModel && this.inputModel.length > (rule.maxLength ?? 0)) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.maxLength', { maxLength: rule.maxLength }),
      );
    }
  }

  private decimalRule(rule: ScwMatInputRule): void {
    if (!this.inputModel) {
      return;
    }

    const separator = rule.decimal?.separator || this.decimalSeparator;
    const min = rule.decimal?.min || 0;
    const max = rule.decimal?.max;
    const integerStepMin = rule.decimal?.integerStep.min || 0;
    const integerStepMax = rule.decimal?.integerStep.max;
    const decimalStepMin = rule.decimal?.decimalStep.min || 0;
    const decimalStepMax = rule.decimal?.decimalStep.max;
    const decimalRegex: RegExp =
      separator === '.'
        ? new RegExp(
            // tslint:disable-next-line:max-line-length
            `^[-]?[0-9]{${integerStepMin},${integerStepMax}}(\\.[0-9]{${decimalStepMin},${decimalStepMax}})?$`,
          )
        : new RegExp(
            // tslint:disable-next-line:max-line-length
            `^[-]?[0-9]{${integerStepMin},${integerStepMax}}(,[0-9]{${decimalStepMin},${decimalStepMax}})?$`,
          );
    const inputModelClone: string = String(_.cloneDeep(this.inputModel));
    const actualValue: number = separator === '.' ? this.inputModel : Number(inputModelClone.replace(',', '.'));

    if (!decimalRegex.test(this.inputModel) || actualValue < min || actualValue > (max ?? 0)) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.decimal', {
            min: rule.decimal?.min,
            max: rule.decimal?.max,
            decimalStepMax: rule.decimal?.decimalStep.max,
          }),
      );
    }
  }

  private rangeValueRule(rule: ScwMatInputRule): void {
    if (
      this.inputModel &&
      (!this.integerRegex.test(this.inputModel) ||
        this.inputModel < (rule.rangeValue?.min ?? 0) ||
        this.inputModel > (rule.rangeValue?.max ?? 0))
    ) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.rangeValue', {
            min: rule.rangeValue?.min,
            max: rule.rangeValue?.max,
          }),
      );
    }
  }

  private rangeLengthRule(rule: ScwMatInputRule): void {
    if (
      this.inputModel &&
      (this.inputModel.length < (rule.rangeLength?.min ?? 0) || this.inputModel.length > (rule.rangeLength?.max ?? 0))
    ) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.rangeLength', {
            min: rule.rangeLength?.min,
            max: rule.rangeLength?.max,
          }),
      );
    }
  }

  private emailRule(rule: ScwMatInputRule): void {
    if (this.inputModel && !this.emailRegex.test(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.email'));
    }
  }

  private patternRule(rule: ScwMatInputRule): void {
    if (this.inputModel && rule.pattern && !rule.pattern.test(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('general.error'));
    }
  }

  private customRule(rule: ScwMatInputRule): void {
    if (this.inputModel && rule.custom && rule.validator && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('general.error'));
    }
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        case 'minValue' in rule:
          this.minValueRule(rule);
          break;
        case 'maxValue' in rule:
          this.maxValueRule(rule);
          break;
        case 'minLength' in rule:
          this.minLengthRule(rule);
          break;
        case 'maxLength' in rule:
          this.maxLengthRule(rule);
          break;
        case 'decimal' in rule:
          this.decimalRule(rule);
          break;
        case 'rangeValue' in rule:
          this.rangeValueRule(rule);
          break;
        case 'rangeLength' in rule:
          this.rangeLengthRule(rule);
          break;
        case 'email' in rule:
          this.emailRule(rule);
          break;
        case 'pattern' in rule:
          this.patternRule(rule);
          break;
        case 'custom' in rule:
          this.customRule(rule);
          break;
        default:
          return;
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onClickEyeIcon(): void {
    this.manageableType = this.manageableType === 'text' ? 'password' : 'text';
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

  public ngOnInit(): void {
    this.manageableType = this.type;

    this.subscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.decimalSeparator = state.decimalSeparator;
      }),
    );
  }
}
