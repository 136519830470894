import { ILogsFormEntriesState } from './logs-form-entries.model';
import { Action } from '@ngrx/store';
import * as Actions from './logs-form-entries.actions';
import _ from 'lodash';

export const logsFormEntriesInitialState: ILogsFormEntriesState = {
  LogsFormEntriesLoading: false,
  LogsFormEntriesLoaded: false,
  LogsFormEntriesData: {
    success: false,
    data: [],
    page: undefined,
    pageCount: undefined,
    count: undefined,
    total: undefined,
  },
  LogsFormEntriesRecordLoading: false,
  LogsFormEntriesRecordLoaded: false,
  LogsFormEntriesRecord: null,
  LogsFormEntriesHistoryDataLoading: false,
  LogsFormEntriesHistoryDataLoaded: false,
  LogsFormEntriesHistoryData: null,
  LogsFormEntriesReviewDataLoading: false,
  LogsFormEntriesReviewDataLoaded: false,
  LogsFormEntriesReviewData: null,
  tableSettings: [],
  errors: [],
};

export function logsFormEntriesReducer(state: ILogsFormEntriesState = logsFormEntriesInitialState, baseAction: Action) {
  const action = baseAction as Actions.LogsFormEntriesActions;

  switch (action.type) {
    case Actions.START_LOGS_FORM_ENTRIES_LOADING:
      return {
        ...state,
        ...{
          LogsFormEntriesLoading: true,
          LogsFormEntriesLoaded: false,
        },
      };
    case Actions.LOGS_FORM_ENTRIES_LOADED:
      return {
        ...state,
        ...{
          LogsFormEntriesData: { ...action.payload },
          LogsFormEntriesLoading: false,
          LogsFormEntriesLoaded: true,
        },
      };
    case Actions.START_LOGS_FORM_ENTRIES_RECORD_LOADING:
      return {
        ...state,
        ...{
          LogsFormEntriesRecordLoading: true,
          LogsFormEntriesRecordLoaded: false,
        },
      };
    case Actions.LOGS_FORM_ENTRIES_RECORD_LOADED:
      return {
        ...state,
        ...{
          LogsFormEntriesRecord: { ...action.payload },
          LogsFormEntriesRecordLoading: false,
          LogsFormEntriesRecordLoaded: true,
        },
      };

    case Actions.START_LOGS_FORM_ENTRIES_HISTORY_LOADING:
      return {
        ...state,
        ...{
          LogsFormEntriesHistoryDataLoading: true,
          LogsFormEntriesHistoryDataLoaded: false,
        },
      };
    case Actions.LOGS_FORM_ENTRIES_HISTORY_LOADED:
      return {
        ...state,
        ...{
          LogsFormEntriesHistoryData: { ...action.payload },
          LogsFormEntriesHistoryDataLoading: false,
          LogsFormEntriesHistoryDataLoaded: true,
        },
      };

    case Actions.START_ENTRY_APPROVE_LOADING:
    case Actions.START_ENTRY_REJECT_PERMANENT_LOADING:
    case Actions.START_ENTRY_REJECT_LOADING:
      return {
        ...state,
        ...{
          LogsFormEntriesReviewData: null,
          LogsFormEntriesReviewDataLoading: true,
          LogsFormEntriesReviewDataLoaded: false,
        },
      };
    case Actions.ENTRY_APPROVE_LOADED:
    case Actions.ENTRY_REJECT_PERMANENT_LOADED:
    case Actions.ENTRY_REJECT_LOADED:
      return {
        ...state,
        ...{
          LogsFormEntriesReviewData: { ...action.payload.data },
          LogsFormEntriesReviewDataLoading: false,
          LogsFormEntriesReviewDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.CLEAR_STATE:
      return { ...logsFormEntriesInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };

    default:
      return state;
  }
}
