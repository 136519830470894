import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGroupComponent } from './button-group.component';
import { ScwMatButtonGroupModule } from '../../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.module';

@NgModule({
  imports: [CommonModule, ScwMatButtonGroupModule],
  exports: [ButtonGroupComponent],
  declarations: [ButtonGroupComponent],
})
export class ButtonGroupModule {}
