import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  IBaseOneResponse,
  IBulkResponseData,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IFormMasterData,
  IFormMasterDataDetail,
  IFormMasterDataFields,
  IFormMasterDataHistory,
} from './form-master-data.model';
import { forkJoin, Observable } from 'rxjs';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import * as moment from 'moment-timezone';
import { IFormMasterDataFormPayload } from '../../../view/settings/master-data/form-master-data/form-master-data.component.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable({
  providedIn: 'root',
})
export class FormMasterDataService {
  private readonly FORM_MASTER_DATA = {
    BASE_URL: '/field-versions/',
    FIELD_VERSION_ITEMS: '/field-version-items/',
    USER_ACTIONS_FIELD_VERSIONS: '/user-actions/field-versions/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getFormMasterData(params: HttpParams): Observable<IGetManyResponse<IFormMasterData>> {
    return this.http.get<IGetManyResponse<IFormMasterData>>(`${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}`, {
      params: FormMasterDataService.setFieldVersionTypeOnHttpParam(params),
    });
  }

  private static setFieldVersionTypeOnHttpParam(params: HttpParams): HttpParams {
    return params.set('field_version_type', 'form');
  }

  public getFormMasterDataHistory(id: number): Observable<IGetManyResponse<IFormMasterDataHistory>> {
    return this.http.get<IGetManyResponse<IFormMasterDataHistory>>(
      `${this.baseUrl}${this.FORM_MASTER_DATA.USER_ACTIONS_FIELD_VERSIONS}${id}`,
    );
  }

  public getFormMasterDetailDataMainPart(
    id: number | null,
    getPreviousVersion: boolean = false,
  ): Observable<IBaseOneResponse<IFormMasterDataDetail>> {
    const params: HttpParams = new HttpParams().append('get_previous_version', getPreviousVersion ? 1 : 0);

    return this.http.get<IBaseOneResponse<IFormMasterDataDetail>>(
      `${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}${id}`,
      { params },
    );
  }

  public getFormMasterDetailDataFieldsPart(id: number | null): Observable<IGetManyResponse<IFormMasterDataFields>> {
    return this.http.get<IGetManyResponse<IFormMasterDataFields>>(
      `${this.baseUrl}${this.FORM_MASTER_DATA.FIELD_VERSION_ITEMS}${id}`,
    );
  }

  public getFormMasterDetailData(
    id: number | null,
    getPreviousVersion: boolean = false,
  ): Observable<[IBaseOneResponse<IFormMasterDataDetail>, IGetManyResponse<IFormMasterDataFields>]> {
    const sourcesObject: [
      Observable<IBaseOneResponse<IFormMasterDataDetail>>,
      Observable<IGetManyResponse<IFormMasterDataFields>>,
    ] = [this.getFormMasterDetailDataMainPart(id, getPreviousVersion), this.getFormMasterDetailDataFieldsPart(id)];

    return forkJoin(sourcesObject);
  }

  public sendToReviewFormMaster(id: number | null, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(
      `${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}${this.FORM_MASTER_DATA.SEND_TO_REVIEW}${id}`,
      {
        issuer,
        payload: {},
      },
    );
  }

  public activateFormMaster(id: number | null, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(
      `${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}${this.FORM_MASTER_DATA.ACTIVATE}${id}`,
      {
        issuer,
        payload: {},
      },
      {
        params: FormMasterDataService.setFieldVersionTypeOnHttpParam(new HttpParams()),
      },
    );
  }

  public deleteFormMaster(id: number | null, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<any>> {
    return this.http.delete<IBulkResponseData>(`${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}${id}`, {
      body: {
        issuer: issuerAndReason.issuer,
        payload: {
          reason: issuerAndReason.reason,
        },
      },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public addFormMaster(payload: any, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<any>> {
    return this.http.post<IBaseOneResponse<any>>(`${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}`, {
      issuer: issuerAndReason.issuer,
      payload: { ...payload, reason: issuerAndReason.reason },
    });
  }

  public editFormMaster(id: number | null, payload: any, issuer: IIssuer | null): Observable<IBaseOneResponse<any>> {
    return this.http.put<IBaseOneResponse<any>>(`${this.baseUrl}${this.FORM_MASTER_DATA.BASE_URL}${id}`, {
      issuer,
      payload,
    });
  }

  public formatDetailToAdd(
    masterDataDetail: IFormMasterDataDetail,
    isClone: boolean = false,
  ): IFormMasterDataFormPayload {
    return {
      name: '',
      field_version_type: 'form',
      workflow_id: masterDataDetail.workflow.id,
      issued_date: masterDataDetail.issuedDate && !isClone ? moment(masterDataDetail.issuedDate).format() : null,
      fields:
        masterDataDetail.fields?.map((field: any) => {
          return {
            id: null,
            name: field.name,
            order: field.order,
            meta: {
              ...field.meta,
              options: field.meta.options.map((option: any) => {
                return {
                  id: null,
                  label: option.label,
                  status: true,
                };
              }),
            },
          };
        }) ?? [],
      deleted_fields: [],
    };
  }
}
