import { Action } from '@ngrx/store';
import { IConfig, User } from './model';
import { IGetFeedToken, NotificationList } from '../../shared/service/notification/notification.service';

export const CLEAR_USER_LOAD_STATUS = '[USER] Clear User Load Status';
export const SET_USER_INFO = '[USER] Set user information';
export const CURRENT_USER_LOADING = '[USER] Current user is loading';
export const CURRENT_USER_LOADED = '[USER] Current user is loaded';
export const FETCH_ERROR = '[USER] Fetch Error';
export const SET_ACCESS_TOKEN_LOADED = '[USER] Set Access token is loaded';
export const GET_USER_FEED_TOKEN = '[USER] Get user feed token';
export const GET_USER_FEED_TOKEN_LOADED = '[USER] Get user feed token loaded';
export const GET_NOTIFICATION_LIST = '[USER] Get notification list';
export const GET_NOTIFICATION_LIST_LOADED = '[USER] Get notification list loaded';
export const GET_NOTIFICATION_LIST_WITH_LIMIT_ZERO_LOADED = '[USER] Get notification list with limit zero loaded';
export const SET_USER_CONFIGURATION = '[USER] Set User Configuration';
export const UPDATE_USER_LOGBOOK_SCOPE_IDS = '[USER] Update User Logbook Scope Ids';

export class ClearUserLoadStatus implements Action {
  readonly type = CLEAR_USER_LOAD_STATUS;
}

export class SetUserInfo implements Action {
  readonly type = SET_USER_INFO;

  constructor(public payload: Partial<User>) {}
}

export class CurrentUserLoading implements Action {
  readonly type = CURRENT_USER_LOADING;
}

export class CurrentUserLoaded implements Action {
  readonly type = CURRENT_USER_LOADED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class SetAccessTokenLoaded implements Action {
  readonly type = SET_ACCESS_TOKEN_LOADED;
}

export class GetUserFeedToken implements Action {
  readonly type = GET_USER_FEED_TOKEN;
}

export class GetUserFeedTokenLoaded implements Action {
  readonly type = GET_USER_FEED_TOKEN_LOADED;
  constructor(public userFeed: IGetFeedToken) {}
}

export class GetNotificationList implements Action {
  readonly type = GET_NOTIFICATION_LIST;
  constructor(public justCount: boolean = false, public nextId: string | null = null) {}
}
export class GetNotificationListLoaded implements Action {
  readonly type = GET_NOTIFICATION_LIST_LOADED;
  constructor(public notifications: NotificationList) {}
}

export class GetNotificationListWithLimitZeroLoaded implements Action {
  readonly type = GET_NOTIFICATION_LIST_WITH_LIMIT_ZERO_LOADED;
  constructor(public notifications: NotificationList) {}
}

export class SetUserConfiguration implements Action {
  readonly type = SET_USER_CONFIGURATION;

  constructor(public configuration: IConfig) {}
}

export class UpdateUserLogbookScopeIds implements Action {
  readonly type = UPDATE_USER_LOGBOOK_SCOPE_IDS;

  constructor(public logbookScopeIds: number[]) {}
}

export type UserActions =
  | ClearUserLoadStatus
  | SetUserInfo
  | CurrentUserLoading
  | CurrentUserLoaded
  | FetchError
  | SetAccessTokenLoaded
  | GetUserFeedToken
  | GetUserFeedTokenLoaded
  | GetNotificationList
  | GetNotificationListLoaded
  | GetNotificationListWithLimitZeroLoaded
  | SetUserConfiguration
  | UpdateUserLogbookScopeIds;
