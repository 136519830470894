import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LogbookAppState } from '../../../store/logbook.reducer';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnDestroy, OnInit {
  public loading$!: boolean;
  public loadingText$: string | null = null;
  private appStoreSubscription!: Subscription;

  constructor(private store: Store<LogbookAppState>) {}

  ngOnDestroy(): void {
    if (this.appStoreSubscription) {
      this.appStoreSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.appStoreSubscription = this.store.select('app').subscribe((state) => {
      this.loading$ = state.loading;
      this.loadingText$ = state.loadingText;
    });
  }
}
