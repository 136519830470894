import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IFormSubmissionData, ILogsFormEntriesData } from './logs-form-entries.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormSubmissionUserAction, ITableHeader } from '../../../../constants.model';
import { IReview, IReviewResults } from '../my-tasks.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';

export const START_LOGS_FORM_ENTRIES_LOADING = '[Logs Form Entries] Start Logs Form Entries Loading';
export const LOGS_FORM_ENTRIES_LOADED = '[Logs Form Entries] Logs Form Entries Loaded';
export const START_LOGS_FORM_ENTRIES_RECORD_LOADING = '[Logs Form Entries] Start Logs Form Entries Record Loading';
export const LOGS_FORM_ENTRIES_RECORD_LOADED = '[Logs Form Entries] Logs Form Entries Record Loaded';
export const START_LOGS_FORM_ENTRIES_HISTORY_LOADING = '[Logs Form Entries] Start Logs Form Entries History Loading';
export const LOGS_FORM_ENTRIES_HISTORY_LOADED = '[Logs Form Entries] Logs Form Entries History Loaded';
export const START_ENTRY_APPROVE_LOADING = '[Logs Form Entries] Entry Approve Loading';
export const ENTRY_APPROVE_LOADED = '[Logs Form Entries] Entry Approve Loaded';
export const START_ENTRY_REJECT_LOADING = '[Logs Form Entries] Entry Reject Loading';
export const ENTRY_REJECT_LOADED = '[Logs Form Entries] Entry Reject Loaded';
export const START_ENTRY_REJECT_PERMANENT_LOADING = '[Logs Form Entries] Entry Reject Permanent Loading';
export const ENTRY_REJECT_PERMANENT_LOADED = '[Logs Form Entries] Entry Reject Permanent Loaded';
export const SET_TABLE_SETTINGS = '[Logs Form Entries] Logs Form Entries Set Table Settings';
export const CLEAR_STATE = '[Logs Form Entries] Clear State';
export const FETCH_ERROR = '[Logs Form Entries] Fetch Error';
export const START_WORKFLOW_DATA_LOADING = '[Logs Form Entries] Start Workflow DataLoading';
export const WORKFLOW_DATA_LOADED = '[Logs Form Entries] Workflow Data Loaded';

export class StartLogsFormEntriesLoading implements Action {
  readonly type = START_LOGS_FORM_ENTRIES_LOADING;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery) {}
}

export class LogsFormEntriesLoaded implements Action {
  readonly type = LOGS_FORM_ENTRIES_LOADED;

  constructor(public payload: IGetManyResponse<ILogsFormEntriesData>) {}
}

export class StartLogsFormEntriesRecordLoading implements Action {
  readonly type = START_LOGS_FORM_ENTRIES_RECORD_LOADING;

  constructor(public logbookId: number, public id: number, public dispatchHideLoader: boolean = true) {}
}

export class LogsFormEntriesRecordLoaded implements Action {
  readonly type = LOGS_FORM_ENTRIES_RECORD_LOADED;

  constructor(public payload: IFormSubmissionData) {}
}

export class StartLogsFormEntriesHistoryLoading implements Action {
  readonly type = START_LOGS_FORM_ENTRIES_HISTORY_LOADING;

  constructor(public id: number, public dispatchHideLoader: boolean = true) {}
}

export class LogsFormEntriesHistoryLoaded implements Action {
  readonly type = LOGS_FORM_ENTRIES_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormSubmissionUserAction>) {}
}

export class StartEntryApproveLoading implements Action {
  readonly type = START_ENTRY_APPROVE_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryApproveLoaded implements Action {
  readonly type = ENTRY_APPROVE_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectLoading implements Action {
  readonly type = START_ENTRY_REJECT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryRejectLoaded implements Action {
  readonly type = ENTRY_REJECT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectPermanentLoading implements Action {
  readonly type = START_ENTRY_REJECT_PERMANENT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryRejectPermanentLoaded implements Action {
  readonly type = ENTRY_REJECT_PERMANENT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;

  constructor(public payload: IGenericObject<any>) {}
}

export class StartWorkflowDataLoading implements Action {
  readonly type = START_WORKFLOW_DATA_LOADING;
}

export class WorkflowDataLoaded implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export type LogsFormEntriesActions =
  | StartLogsFormEntriesLoading
  | LogsFormEntriesLoaded
  | StartLogsFormEntriesRecordLoading
  | LogsFormEntriesRecordLoaded
  | StartLogsFormEntriesHistoryLoading
  | LogsFormEntriesHistoryLoaded
  | StartEntryApproveLoading
  | EntryApproveLoaded
  | StartEntryRejectLoading
  | EntryRejectLoaded
  | StartEntryRejectPermanentLoading
  | EntryRejectPermanentLoaded
  | SetTableSettings
  | FetchError
  | ClearState
  | StartWorkflowDataLoading
  | WorkflowDataLoaded;
