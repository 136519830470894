import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalSeparatorComponent } from './scw-mat-modal-separator.component';

@NgModule({
  declarations: [ScwMatModalSeparatorComponent],
  exports: [ScwMatModalSeparatorComponent],
  imports: [CommonModule],
})
export class ScwMatModalSeparatorModule {}
