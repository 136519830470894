import { Component, Input, OnDestroy } from '@angular/core';
import { IPageHeader } from '../../service/page-header/page-header.model';
import { LayoutConstants } from '../../../layout/layout-constants';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends LayoutConstants implements OnDestroy {
  @Input() pageHeader: IPageHeader = {
    title: undefined,
    titleKey: undefined,
    icon: undefined,
    fullScreenButton: false,
    fullScreenTargetElementId: undefined,
    showPageConfiguration: false,
    showCountDownButton: false,
    showFilterBarVisibilityButton: false,
    showPrintFunctionalityButton: false,
    hidePageHeader: false,
    noOverlap: false,
    moveUncheckedToEnd: true,
  };
  private readonly subscriptions: Subscription[] = [];
  public showFilterBarVisibilityButton: boolean = false;
  public showPrintFunctionalityButton: boolean = false;
  public hideHeaderActions: boolean = false;

  constructor(private readonly store: Store<logbookAppReducer.LogbookAppState>) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.store.select('pageHeaderStore').subscribe(() => { return; }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
