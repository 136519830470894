import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, switchMap } from 'rxjs';
import { IGetCurrentUserResponse } from './model';
import * as ObjectActions from './actions';
import * as UserActions from '../user/actions';
import * as MainActions from '../main/main.actions';
import * as ClientActions from '../settings/client/client.actions';
import * as AdvancedFilterActions from '../advanced-filter/advanced-filter.actions';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
import { environment } from '../../../environments/environment';
import { Action } from '@ngrx/store';
import { emptyUserMeta, MENU_INFORMATION } from '../../../constants';
import * as _ from 'lodash';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions<ObjectActions.AppActions>,
    private http: HttpClient,
    private monitoringService: MonitoringService,
  ) {}

  private APP_URLS = {
    GET: {
      GET_CURRENT_USER: '/users/get-current-user',
    },
    POST: {
      LOG_EXTERNAL_PAGE_VIEWS: '/api/log-external-page-views',
    },
  };

  logExternalPageViews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ObjectActions.LOG_EXTERNAL_PAGE_VIEWS),
        switchMap((objectData: ObjectActions.LogExternalPageViews) => {
          return this.http.post(environment.apiUrl + this.APP_URLS.POST.LOG_EXTERNAL_PAGE_VIEWS, {
            url: objectData.url,
          });
        }),
      ),
    { dispatch: false },
  );
  private actionsWillBeExecute: Action[] = [];

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_CURRENT_USER),
      switchMap((objectData: ObjectActions.GetCurrentUser) => {
        return this.http
          .get<IGetCurrentUserResponse>(environment.apiUrl + this.APP_URLS.GET.GET_CURRENT_USER, {
            params: {
              action: objectData.action,
            },
          })
          .pipe(
            switchMap((response: IGetCurrentUserResponse) => {
              if (response.success) {
                try {
                  this.actionsWillBeExecute = [
                    new UserActions.CurrentUserLoading(),
                    new MainActions.GetNavigationMenuCompleted(MENU_INFORMATION, true),
                    new MainActions.SetAuthorizedPages(response.data.userRights),
                    new UserActions.SetUserInfo({
                      clientId: response.data.clientId,
                      username: response.data.username,
                      title: response.data.title,
                      userId: String(response.data.id),
                      roleId: response.data.roleId,
                      role: response.data.role,
                      isPinTemporary: response.data.isPinTemporary,
                      language: response.data.language ?? response.data.client.language,
                      timezone: response.data.timezone ?? response.data.client.timezone,
                      dateFormat: response.data.dateFormat ?? response.data.client.dateFormat,
                      firstName: response.data.firstName,
                      lastName: response.data.lastName,
                      email: response.data.email,
                      clientLogoId: response.data.client.logoId,
                      clientName: response.data.client.name,
                      decimalSeparator: response.data.decimalSeparator ?? response.data.client.decimalSeparator,
                      thousandSeparator: response.data.thousandSeparator ?? response.data.client.thousandSeparator,
                      locale: response.data.locale ?? response.data.client.locale,
                      scopeLogbookIds: response.data.scopeLogbookIds,
                      scopeFormIds: response.data.scopeFormIds,
                      isAllLogbookScope: response.data.isAllLogbookScope,
                      isAllFormScope: response.data.isAllFormScope,
                      logbookScopeId: response.data.logbookScopeId,
                      formScopeId: response.data.formScopeId,
                      formActivationFlow: response.data.client.formActivationFlow,
                    }),
                    new UserActions.SetUserConfiguration(response.data.meta),
                    new ClientActions.SetClientInfo({
                      decimalSeparator: response.data.client.decimalSeparator,
                      thousandSeparator: response.data.client.thousandSeparator,
                      language: response.data.client.language,
                      timezone: response.data.client.timezone,
                      locale: response.data.client.locale,
                      dateFormat: response.data.client.dateFormat,
                      askForPinOnAction: response.data.client.askForPinOnAction,
                      name: response.data.client.name,
                      accessToOee: response.data.client.accessToOee,
                      oeeUrl: response.data.client.oeeUrl,
                      meta: response.data.client.meta,
                      notificationChannels: response.data.client.notificationChannels,
                      notificationScope: response.data.client.notificationScope,
                      knowledgeBase: response.data.client.knowledgeBase,
                      isRejectButtonVisible: response.data.client.isRejectButtonVisible,
                      isPdfEnhancement: response.data.client.isPdfEnhancement
                    }),
                    new AdvancedFilterActions.SetDefaultFilters(
                      { ..._.cloneDeep(emptyUserMeta), ..._.cloneDeep(response.data.meta) }.filterConfig,
                    ),
                  ];
                  this.monitoringService.setAuthenticatedUser(
                    response.data.id.toString(),
                    response.data.clientId.toString(),
                    '',
                  );
                  this.actionsWillBeExecute.push(new UserActions.CurrentUserLoaded());
                } catch (error) {}
              }

              return of(...this.actionsWillBeExecute);
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error));
            }),
          );
      }),
    ),
  );
}
