import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  DocumentTypes,
  IDocument,
  IDocumentBanner,
  IDocumentFormIoSelectors,
  IPrintableComponent,
  PageOrientation,
} from './document-generator.model';
import * as _ from 'lodash';
import { FormioForm } from 'angular-formio';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../service/helper.service';

moment.tz.setDefault('utc');

@Injectable({
  providedIn: 'root',
})
export class DocumentGeneratorService {
  public defaultDocumentData: IDocument;

  constructor(public readonly translate: TranslateService, public readonly helperService: HelperService) {
    this.defaultDocumentData = {
      pageConfigs: [],
      type: DocumentTypes.PDF,
      autoFill: true,
      printableComponentsSelectors: [],
      formIoSelectors: [],
      bannerConfiguration: {},
    };
  }

  public getMainComponents(
    location: string,
    parentContainerClass: string,
    userDefinedFields: boolean = false,
  ): IPrintableComponent[] {
    let documentMainComponents: IPrintableComponent[] = [];

    switch (location) {
      case 'logs-form-entries':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        if (userDefinedFields) {
          documentMainComponents.push({
            parentContainerClass,
            selector: '.user-defined-fields',
            orientationPreference: PageOrientation.portrait,
          });
        }
        documentMainComponents.push({
          parentContainerClass,
          selector: '.form-preview',
          orientationPreference: PageOrientation.portrait,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '',
          orientationPreference: PageOrientation.portrait,
          formioElement: true,
          smartCrop: true,
          isRootLevel: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history-header',
          orientationPreference: PageOrientation.landscape,
          startNewPage: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history',
          orientationPreference: PageOrientation.landscape,
          printAsAutoTable: true,
        });
        break;
      case 'logbook-tasks':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.change-history',
            orientationPreference: PageOrientation.landscape,
          },
          {
            parentContainerClass,
            selector: '.active-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.active-versions-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
          {
            parentContainerClass,
            selector: '.logbook-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.logbook-versions-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
        ];
        break;
      case 'logbook-tasks-archived':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.logbook-details',
            orientationPreference: PageOrientation.landscape,
          },
          {
            parentContainerClass,
            selector: '.logbook-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.logbook-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
        ];
        break;
      case 'form-tasks':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.change-history',
            orientationPreference: PageOrientation.landscape,
            formioElement: false,
          },
          {
            parentContainerClass,
            selector: '.active-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.active-versions-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
          {
            parentContainerClass,
            selector: '.form-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.form-versions-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
        ];
        break;
      case 'form-tasks-archived':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
          {
            parentContainerClass,
            selector: '.form-items',
            orientationPreference: PageOrientation.portrait,
            formioElement: true,
            smartCrop: true,
          },
          {
            parentContainerClass,
            selector: '.form-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.form-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
        ];
        break;
      case 'master-data-tasks':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.change-history',
            orientationPreference: PageOrientation.landscape,
          },
          {
            parentContainerClass,
            selector: '.active-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.active-versions-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
          {
            parentContainerClass,
            selector: '.master-data-version-logs-history-header',
            orientationPreference: PageOrientation.landscape,
            startNewPage: true,
          },
          {
            parentContainerClass,
            selector: '.master-data-history',
            orientationPreference: PageOrientation.landscape,
            printAsAutoTable: true,
          },
        ];
        break;
      case 'log-reports':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        if (userDefinedFields) {
          documentMainComponents.push({
            parentContainerClass,
            selector: '.user-defined-fields',
            orientationPreference: PageOrientation.portrait,
          });
        }
        documentMainComponents.push({
          parentContainerClass,
          selector: '.form-preview',
          orientationPreference: PageOrientation.portrait,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '',
          orientationPreference: PageOrientation.portrait,
          formioElement: true,
          smartCrop: true,
          isRootLevel: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history-header',
          orientationPreference: PageOrientation.landscape,
          startNewPage: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history',
          orientationPreference: PageOrientation.landscape,
          printAsAutoTable: true,
        });
        break;
      case 'form-entries':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        if (userDefinedFields) {
          documentMainComponents.push({
            parentContainerClass,
            selector: '.user-defined-fields',
            orientationPreference: PageOrientation.portrait,
          });
        }
        documentMainComponents.push({
          parentContainerClass,
          selector: '.form-preview',
          orientationPreference: PageOrientation.portrait,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '',
          orientationPreference: PageOrientation.portrait,
          formioElement: true,
          smartCrop: true,
          isRootLevel: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history-header',
          orientationPreference: PageOrientation.landscape,
          startNewPage: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.history-table',
          orientationPreference: PageOrientation.landscape,
          printAsAutoTable: true,
        });
        break;
      case 'form-templates':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        if (userDefinedFields) {
          documentMainComponents.push({
            parentContainerClass,
            selector: '.user-defined-fields',
            orientationPreference: PageOrientation.portrait,
          });
        }
        documentMainComponents.push({
          parentContainerClass,
          selector: '',
          orientationPreference: PageOrientation.portrait,
          formioElement: true,
          smartCrop: true,
          isRootLevel: true,
        });
        break;
      case 'settings-form':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.is-printable',
            orientationPreference: PageOrientation.portrait,
          },
          {
            parentContainerClass,
            selector: '',
            orientationPreference: PageOrientation.portrait,
            formioElement: true,
            smartCrop: true,
            isRootLevel: true,
          },
        ];
        break;
      case 'settings-logbook':
      case 'settings-logbook-versions':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.qr-code',
            orientationPreference: PageOrientation.portrait,
          },
          {
            parentContainerClass,
            selector: '.qr-code-detail',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        break;
      case 'logbook-home-detail':
        documentMainComponents = [
          {
            parentContainerClass,
            selector: '.pre-defined-fields',
            orientationPreference: PageOrientation.portrait,
          },
        ];
        if (userDefinedFields) {
          documentMainComponents.push({
            parentContainerClass,
            selector: '.user-defined-fields',
            orientationPreference: PageOrientation.portrait,
          });
        }
        documentMainComponents.push({
          parentContainerClass,
          selector: '.form-preview',
          orientationPreference: PageOrientation.portrait,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '',
          orientationPreference: PageOrientation.portrait,
          formioElement: true,
          smartCrop: true,
          isRootLevel: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history-header',
          orientationPreference: PageOrientation.landscape,
          startNewPage: true,
        });
        documentMainComponents.push({
          parentContainerClass,
          selector: '.logs-history',
          orientationPreference: PageOrientation.landscape,
          printAsAutoTable: true,
        });
        break;
    }

    return documentMainComponents;
  }

  public async craftPrintableDocument(
    documentMainComponents: IPrintableComponent[],
    formIo: FormioForm | null,
    parentContainerClass: string,
    bannerConfiguration: IDocumentBanner,
  ): Promise<IDocument> {
    const craftedDocument: IDocument = _.cloneDeep(this.defaultDocumentData);
    craftedDocument.formIoSelectors = [];

    craftedDocument.printableComponentsSelectors = [];
    const printableComponentArray: IPrintableComponent[] = [];

    if (formIo) {
      if (formIo.components?.length) {
        let formComponentsSelectors: IDocumentFormIoSelectors[] = await this.getComponentsSelectors(
          formIo,
          parentContainerClass,
          '',
          true,
        );

        if (formComponentsSelectors?.length) {
          for (const comp of formComponentsSelectors) {
            printableComponentArray.push({
              parentContainerClass,
              selector: comp.selector,
              orientationPreference: PageOrientation.portrait,
              formioElement: true,
              smartCrop: true,
              printAsAutoTable: false,
              isPanel: comp.isPanel,
              isPanelHeader: comp.isPanelHeader,
              isRootLevel: comp.isRootLevel,
              childrenComponentCoordinates: comp.childrenComponentCoordinates,
            });
          }
        }
      }
    }
    for (const printable of documentMainComponents) {
      if (printable.formioElement) {
        craftedDocument.printableComponentsSelectors =
          craftedDocument.printableComponentsSelectors.concat(printableComponentArray);
      } else {
        printable.isRootLevel = true;
        craftedDocument.printableComponentsSelectors.push(printable);
      }
    }

    craftedDocument.bannerConfiguration = bannerConfiguration;

    return craftedDocument;
  }

  public async getComponentsSelectors(
    formComponent: any,
    parentContainerClass: string,
    parentSelectorIndex: string = '',
    isRootLevel: boolean = false,
  ): Promise<IDocumentFormIoSelectors[]> {
    let childComponentsSelectors: IDocumentFormIoSelectors[] = [];
    let childComponentsSelector: IDocumentFormIoSelectors;

    for (const component of formComponent.components) {
      const index = formComponent.components.indexOf(component);
      const parentIndex: string = `${parentSelectorIndex}${parentSelectorIndex !== '' ? '-' : ''}${index}`;

      const craftedSelector = ` #${component.id}`;
      let selectedElementClassList: string[] = [];

      const parentElement = document.getElementsByClassName(parentContainerClass);
      let findElement: HTMLElement | null;

      if (parentElement) {
        if (parentElement.length > 0) {
          findElement = parentElement[0].querySelector(craftedSelector);
          if (findElement) {
            selectedElementClassList = Array.from(findElement.classList);
          }

          if (selectedElementClassList.includes('formio-hidden')) {
            continue;
          }

          if (component.type === 'panel') {
            childComponentsSelector = {
              index: parentIndex,
              parentContainerClass,
              selector: craftedSelector,
              isPanel: true,
              isRootLevel,
            };

            let childrenComponentCoordinates: IDocumentFormIoSelectors[] = await this.getComponentsSelectors(
              component,
              parentContainerClass,
              parentIndex,
            );

            if (isRootLevel) {
              childComponentsSelector.index = `${childComponentsSelector.index}-`;
              const rootRecord = _.cloneDeep(childComponentsSelector);
              rootRecord.isPanelHeader = true;
              childComponentsSelector.childrenComponentCoordinates = [rootRecord];

              childComponentsSelector.childrenComponentCoordinates =
                childComponentsSelector.childrenComponentCoordinates.concat(childrenComponentCoordinates);

              const rootRecordFooter = _.cloneDeep(rootRecord);
              rootRecordFooter.isPanelHeader = false;
              childComponentsSelector.childrenComponentCoordinates.push(rootRecordFooter);
              childComponentsSelectors.push(childComponentsSelector);
            } else {
              const middlePanelRecord = _.cloneDeep(childComponentsSelector);
              middlePanelRecord.isPanelHeader = true;
              childComponentsSelectors.push(middlePanelRecord);

              childComponentsSelectors = childComponentsSelectors.concat(childrenComponentCoordinates);

              const middlePanelFooter = _.cloneDeep(childComponentsSelector);
              middlePanelFooter.isPanelHeader = false;
              childComponentsSelectors.push(middlePanelFooter);
            }
          } else {
            childComponentsSelector = {
              index: parentIndex,
              parentContainerClass,
              selector: craftedSelector,
              isPanel: false,
              isRootLevel,
            };
            childComponentsSelectors.push(childComponentsSelector);
          }
        }
      }
    }
    return childComponentsSelectors;
  }
}
