import { IFormEntriesState } from './form-entries.model';
import { Action } from '@ngrx/store';
import * as Actions from './form-entries.actions';
import * as _ from 'lodash';

export const formEntriesInitialState: IFormEntriesState = {
  FormEntriesLoading: false,
  FormEntriesLoaded: false,
  FormEntriesData: {
    success: false,
    data: [],
    page: undefined,
    pageCount: undefined,
    count: undefined,
    total: undefined,
  },
  FormEntriesRecordLoading: false,
  FormEntriesRecordLoaded: false,
  FormEntriesRecord: null,
  FormEntriesColumnData: null,
  FormEntriesColumnDataLoading: false,
  FormEntriesColumnDataLoaded: false,
  FormEntriesHistoryDataLoading: false,
  FormEntriesHistoryDataLoaded: false,
  FormEntriesHistoryData: null,
  fileDownloadStatus: false,
  tableSettings: [],
  tableHeaders: [],
  tableDefaultHeaders: [],
  errors: [],
};

export function formEntriesReducer(state: IFormEntriesState = formEntriesInitialState, baseAction: Action) {
  const action = baseAction as Actions.FormEntriesActions;

  switch (action.type) {
    case Actions.START_FORM_ENTRIES_LOADING:
      return {
        ...state,
        ...{
          FormEntriesLoading: true,
          FormEntriesLoaded: false,
        },
      };
    case Actions.FORM_ENTRIES_LOADED:
      return {
        ...state,
        ...{
          FormEntriesData: { ...action.payload },
          FormEntriesLoading: false,
          FormEntriesLoaded: true,
        },
      };
    case Actions.START_FORM_ENTRIES_RECORD_LOADING:
      return {
        ...state,
        ...{
          FormEntriesRecordLoading: true,
          FormEntriesRecordLoaded: false,
        },
      };
    case Actions.FORM_ENTRIES_RECORD_LOADED:
      return {
        ...state,
        ...{
          FormEntriesRecord: { ...action.payload },
          FormEntriesRecordLoading: false,
          FormEntriesRecordLoaded: true,
        },
      };
    case Actions.START_FORM_ENTRIES_COLUMN_LOADING:
      return {
        ...state,
        ...{
          FormEntriesColumnDataLoading: true,
          FormEntriesColumnDataLoaded: false,
        },
      };
    case Actions.FORM_ENTRIES_COLUMN_LOADED:
      return {
        ...state,
        ...{
          FormEntriesColumnData: { ...action.payload },
          FormEntriesColumnDataLoading: false,
          FormEntriesColumnDataLoaded: true,
        },
      };

    case Actions.START_FORM_ENTRIES_HISTORY_LOADING:
      return {
        ...state,
        ...{
          FormEntriesHistoryDataLoading: true,
          FormEntriesHistoryDataLoaded: false,
        },
      };
    case Actions.FORM_ENTRIES_HISTORY_LOADED:
      return {
        ...state,
        ...{
          FormEntriesHistoryData: { ...action.payload },
          FormEntriesHistoryDataLoading: false,
          FormEntriesHistoryDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_REPORT_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };
    case Actions.DOWNLOAD_REPORT_EXCEL_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };

    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.headers,
        tableHeaders: action.headers,
      };

    case Actions.SET_FORM_ENTRIES_HEADERS:
      return {
        ...state,
        tableSettings: action.headers,
        tableHeaders: action.headers,
        tableDefaultHeaders: action.defaultHeaders,
      };
    case Actions.CLEAR_STATE:
      return { ...formEntriesInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };

    default:
      return state;
  }
}
