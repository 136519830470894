import { IMasterDataTasksState } from './master-data-tasks.model';
import { Action } from '@ngrx/store';
import * as Actions from './master-data-tasks.actions';
import * as _ from 'lodash';

export const masterDataTasksInitialState: IMasterDataTasksState = {
  MasterDataTasksLoading: false,
  MasterDataTasksLoaded: false,
  MasterDataTasksData: {
    success: false,
    data: [],
    page: undefined,
    pageCount: undefined,
    count: undefined,
    total: undefined,
  },
  MasterDataTasksBeforeRecordLoading: false,
  MasterDataTasksBeforeRecordLoaded: false,
  MasterDataTasksBeforeRecord: null,
  MasterDataTasksAfterRecordLoading: false,
  MasterDataTasksAfterRecordLoaded: false,
  MasterDataTasksAfterRecord: null,
  MasterDataTasksBeforeFieldSetLoading: false,
  MasterDataTasksBeforeFieldSetLoaded: false,
  MasterDataTasksBeforeFieldset: [],
  MasterDataTasksAfterFieldSetLoading: false,
  MasterDataTasksAfterFieldSetLoaded: false,
  MasterDataTasksAfterFieldset: [],
  MasterDataTasksHistoryDataLoading: false,
  MasterDataTasksHistoryDataLoaded: false,
  MasterDataTasksHistoryData: null,
  MasterDataTasksActiveVersionHistoryDataLoading: false,
  MasterDataTasksActiveVersionHistoryDataLoaded: false,
  MasterDataTasksActiveVersionHistoryData: null,
  MasterDataTasksCurrentVersionHistoryDataLoading: false,
  MasterDataTasksCurrentVersionHistoryDataLoaded: false,
  MasterDataTasksCurrentVersionHistoryData: null,
  MasterDataTasksReviewDataLoading: false,
  MasterDataTasksReviewDataLoaded: false,
  MasterDataTasksReviewData: null,
  tableSettings: [],
  errors: [],
};

export function masterDataTasksReducer(state: IMasterDataTasksState = masterDataTasksInitialState, baseAction: Action) {
  const action = baseAction as Actions.MasterDataTasksActions;

  switch (action.type) {
    case Actions.START_MASTER_DATA_TASKS_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksLoading: true,
          MasterDataTasksLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksData: { ...action.payload },
          MasterDataTasksLoading: false,
          MasterDataTasksLoaded: true,
        },
      };
    case Actions.START_MASTER_DATA_TASKS_BEFORE_RECORD_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksBeforeRecordLoading: true,
          MasterDataTasksBeforeRecordLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_BEFORE_RECORD_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksBeforeRecordLoading: false,
          MasterDataTasksBeforeRecordLoaded: true,
          MasterDataTasksBeforeRecord: { ...action.payload },
        },
      };
    case Actions.START_MASTER_DATA_TASKS_AFTER_RECORD_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksAfterRecordLoading: true,
          MasterDataTasksAfterRecordLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_AFTER_RECORD_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksAfterRecordLoading: false,
          MasterDataTasksAfterRecordLoaded: true,
          MasterDataTasksAfterRecord: { ...action.payload },
        },
      };
    case Actions.START_MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksBeforeFieldSetLoading: true,
          MasterDataTasksBeforeFieldsetLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksBeforeFieldSetLoading: false,
          MasterDataTasksBeforeFieldsetLoaded: true,
          MasterDataTasksBeforeFieldset: { ...action.payload },
        },
      };
    case Actions.START_MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksAfterFieldSetLoading: true,
          MasterDataTasksAfterFieldsetLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksAfterFieldSetLoading: false,
          MasterDataTasksAfterFieldsetLoaded: true,
          MasterDataTasksAfterFieldset: { ...action.payload },
        },
      };
    case Actions.START_MASTER_DATA_TASKS_HISTORY_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksHistoryDataLoading: true,
          MasterDataTasksHistoryDataLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_HISTORY_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksHistoryDataLoading: false,
          MasterDataTasksHistoryDataLoaded: true,
          MasterDataTasksHistoryData: { ...action.payload },
        },
      };
    case Actions.START_MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksActiveVersionHistoryDataLoading: true,
          MasterDataTasksActiveVersionHistoryDataLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksActiveVersionHistoryDataLoading: false,
          MasterDataTasksActiveVersionHistoryDataLoaded: true,
          MasterDataTasksActiveVersionHistoryData: { ...action.payload },
        },
      };

    case Actions.START_MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksCurrentVersionHistoryDataLoading: true,
          MasterDataTasksCurrentVersionHistoryDataLoaded: false,
        },
      };
    case Actions.MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksCurrentVersionHistoryDataLoading: false,
          MasterDataTasksCurrentVersionHistoryDataLoaded: true,
          MasterDataTasksCurrentVersionHistoryData: { ...action.payload },
        },
      };
    case Actions.START_ENTRY_APPROVE_LOADING:
    case Actions.START_ENTRY_REJECT_PERMANENT_LOADING:
    case Actions.START_ENTRY_REJECT_LOADING:
      return {
        ...state,
        ...{
          MasterDataTasksReviewData: null,
          MasterDataTasksReviewDataLoading: true,
          MasterDataTasksReviewDataLoaded: false,
        },
      };
    case Actions.ENTRY_APPROVE_LOADED:
    case Actions.ENTRY_REJECT_PERMANENT_LOADED:
    case Actions.ENTRY_REJECT_LOADED:
      return {
        ...state,
        ...{
          MasterDataTasksReviewData: { ...action.payload.data },
          MasterDataTasksReviewDataLoading: false,
          MasterDataTasksReviewDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.CLEAR_STATE:
      return { ...masterDataTasksInitialState };

    case Actions.FETCH_ERROR:
      const errors = _.cloneDeep(state.errors);
      errors.push(action.payload);
      return {
        ...state,
        errors,
      };
    default:
      return state;
  }
}
