import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormioForm, FormioOptions } from '@formio/angular/formio.common';
import * as _ from 'lodash';
import { FormioComponent } from '@formio/angular';

@Component({
  selector: 'scw-formio',
  templateUrl: './formio.component.html',
})
export class ScwFormioComponent implements OnInit, OnChanges {
  @Input() form: FormioForm | undefined;
  @Input() submission: any;
  @Input() options: FormioOptions | undefined;
  @Input() readOnly!: boolean;
  @Output() ready: EventEmitter<object> = new EventEmitter<object>();
  @Output() submit: EventEmitter<object> = new EventEmitter<object>();
  @Output() formioRef: EventEmitter<FormioComponent> = new EventEmitter<FormioComponent>();

  @ViewChild('formioComponent', { static: true }) formioComponent!: FormioComponent;

  public ngOnInit(): void {
    this.formioRef.emit(this.formioComponent);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('submission') &&
      _.get(this.submission, 'data', null) &&
      typeof this.submission.data === 'string'
    ) {
      try {
        this.submission.data = JSON.parse(this.submission.data);
      } catch {
        this.submission.data = {};
      }
    }
  }

  public onReady($event: object): void {
    this.prepareDeleteFileButtonListener();

    this.ready.emit($event);
  }

  public onSubmit($event: object): void {
    this.submit.emit($event);
  }

  /**
   * TODO Remove this 2 methods after formiojs version update.
   */
  private prepareDeleteFileButtonListener(): void {
    const hasFileSelector: boolean = !!document.querySelector('.fileSelector');

    if (hasFileSelector) {
      const formio: Element = document.querySelector('formio') as Element;

      formio.removeEventListener('mousemove', this.formioEventListener);
      formio.addEventListener('mousemove', this.formioEventListener);
    }
  }

  private formioEventListener(): void {
    const removeFileSelector: NodeListOf<Element> | null = document.querySelectorAll('i.fa.fa-remove');

    if (removeFileSelector.length) {
      removeFileSelector.forEach((selector: Element): void => {
        selector.addEventListener('click', (): void => {
          document.querySelectorAll('.fileSelector[hidden]').forEach((hiddenSelector: Element): void => {
            const hasTrashIcon: Element | null | undefined =
              hiddenSelector.nextElementSibling?.querySelector('i.fa.fa-remove');

            if (!hasTrashIcon) {
              // @ts-ignore
              hiddenSelector.hidden = false;
            }
          });
        });
      });
    }
  }
}
