import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IReason, IReasonAPI } from './reason.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IActionReasonPayload } from "../../view/settings/client/action-reasons/action-reasons.model";

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  private readonly REASON = {
    REASON_URL: `${this.baseUrl}/predefined-reasons/`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getReasons(): Observable<IResponse<IReasonAPI[]>> {
    return this.http.get<IResponse<IReasonAPI[]>>(this.REASON.REASON_URL);
  }

  public upsertReasons(
    reasonPayload: IActionReasonPayload,
    issuer: IIssuer | null,
  ): Observable<IResponse<IReasonAPI[]>> {
    return this.http.put<IResponse<IReasonAPI[]>>(this.REASON.REASON_URL, {
      issuer,
      payload: reasonPayload,
    });
  }

  public prepareActionReasons(responseData: IReasonAPI[]): IReason[] {
    return responseData?.map((item: IReasonAPI): IReason => {
      return {
        id: item.id,
        name: item.text,
        hasAdditionalField: item.hasAdditionalField,
        additionalFieldLabel: item?.additionalFieldLabel,
        isActive: item.isActive,
      };
    });
  }
}
