import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, of, take } from 'rxjs';
import * as ObjectActions from './roles.actions';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import { RolesService } from './roles.service';
import { IAddRole } from './roles.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IRole, IRoleSettings } from '../../../view/settings/roles/roles.model';
import { HelperService } from '../../../shared/service/helper.service';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';

@Injectable()
export class RolesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: RolesService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly helperService: HelperService,
  ) {}

  getRoleData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROLES_DATA_LOADING),
      switchMap((payload: ObjectActions.RolesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        return this.service.getRolePermissions(httpParams).pipe(
          switchMap((response: IRoleSettings) => {
            return of(new ObjectActions.RolesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addRole = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_ROLE),
      switchMap((objectData: ObjectActions.AddRole) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addRole(objectData.role, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IAddRole>) => {
            return of(new ObjectActions.AddRoleCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editRole = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_ROLE),
      switchMap((objectData: ObjectActions.EditRole) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let userRoleId: number | null = null;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state) => {
            userRoleId = state.roleId;
          });

        return this.service.editRole(objectData.role, objectData.id, objectData.issuer, objectData.forceCheckOut).pipe(
          switchMap((response: IBaseOneResponse<IAddRole>) => {
            if (objectData.id === userRoleId) {
              return of(
                new ObjectActions.EditRoleCompleted(response),
                new AppActions.HideLoader(),
                new AppActions.GetCurrentUser('application-parameters'),
              );
            }
            return of(new ObjectActions.EditRoleCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditRole = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_ROLE),
      switchMap((objectData: ObjectActions.BulkEditRole) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditRole(objectData.roles, objectData.issuerAndReason).pipe(
          switchMap((response: IGetManyResponse<IBulkResponseRecord<IRole>>) => {
            return of(new ObjectActions.BulkEditRoleCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
