import { Component, Input, OnInit } from '@angular/core';
import { IESignatureModel } from './e-signature.model';
import { HelperService } from '../../service/helper.service';
import { systemUserDefaultEmail } from '../../../../constants';

@Component({
  selector: 'app-e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.scss'],
})
export class ESignatureComponent implements OnInit {
  @Input() signatureDetails: IESignatureModel | null = null;
  @Input() createdAt!: string;
  public createdAtTime!: string | null;
  public isSystemUser: boolean = false;

  public ngOnInit(): void {
    this.isSystemUser = this.signatureDetails?.email === systemUserDefaultEmail

    if (this.createdAt) {
      this.createdAtTime = HelperService.formatDateTimeWithLineBreak(this.createdAt);
    } else {
      this.createdAtTime = '';
    }
  }
}
