import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../logbook.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { Observable, Subject } from 'rxjs';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IBulkEditScope, IScope } from './scopes.model';
import { IAddScope } from '../../view/settings/scopes/scopes.model';
import { ITreeTableNode } from '../../shared/component/scw-mat-ui/scw-mat-treetable/scw-mat-treetable.model';
import { ITreeTableLogbook } from '../../view/settings/scopes/logbook-scopes/logbook-scopes.model';
import { ITreeTableForm } from '../../view/settings/scopes/form-scopes/form-scopes.model';
import { IForm } from '../forms/forms.model';
import { ILogbook } from '../logbooks/logbooks.model';
import * as _ from 'lodash';
import { ComponentUtilities } from '../../shared/helper/component-utilities';

@Injectable({
  providedIn: 'root',
})
export class ScopesService {
  private readonly SCOPES = {
    SCOPES_URL: `${this.baseUrl}/scopes/`,
    SCOPES_BULK_UPDATE_URL: `${this.baseUrl}/scopes/bulk/update`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly translate: TranslateService,
  ) {}

  public getScopes(params: HttpParams): Observable<IGetManyResponse<IScope>> {
    return this.http.get<IGetManyResponse<IScope>>(this.SCOPES.SCOPES_URL, { params });
  }

  public addScope(formScope: IAddScope, issuer: IIssuer | null): Observable<IBaseOneResponse<IScope>> {
    return this.http.post<IBaseOneResponse<IScope>>(this.SCOPES.SCOPES_URL, {
      issuer,
      payload: formScope,
    });
  }

  public editScope(
    formScope: IAddScope,
    id: number,
    issuer: IIssuer | null,
    forceCheckOut?: boolean,
  ): Observable<IBaseOneResponse<IScope>> {
    return this.http.put<IBaseOneResponse<IScope>>(`${this.SCOPES.SCOPES_URL}${id}/`, {
      issuer,
      payload: { ...formScope, forceUpdate: forceCheckOut },
    });
  }

  public bulkEditScope(
    formScopes: IBulkEditScope[],
    issuer: IIssuer | null,
    reason: string | null,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IScope>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IScope>>>(`${this.SCOPES.SCOPES_BULK_UPDATE_URL}`, {
      issuer,
      reason,
      payload: formScopes,
    });
  }

  public filterFormsScope(
    logbookData: ILogbook[],
    formData: IForm[],
    formScope: IScope | null | undefined,
    archivedTranslation: string,
    unassignedFormsText: string,
    isAllFormsSelected: boolean = false,
  ): ITreeTableNode<ITreeTableLogbook>[] {
    const formattedForms: ITreeTableNode<ITreeTableForm>[] = formData.map((form: IForm) => {
      return {
        ...form,
        checkboxes: {
          includeInScope: formScope === undefined ? isAllFormsSelected : formScope?.objectItems.includes(form.id),
        },
        ...(form.isArchived && { name: `${form.name} (${archivedTranslation})` }),
      };
    });

    const assignedFormIds: number[] = [];
    const treetableItems: any[] = logbookData.map((logbook: ILogbook) => {
      assignedFormIds.push(...logbook.selectedForms);

      return {
        ...logbook,
        children: _.filter(formattedForms, (form: IForm) => logbook.selectedForms?.includes(form.id)).map((form) => {
          return { data: form };
        }),
        ...(logbook.isArchived && { name: `${logbook.name} (${archivedTranslation})` }),
      };
    });

    const unassignedForms: any[] = _.filter(formattedForms, (form: IForm) => !assignedFormIds.includes(form.id)).map(
      (form) => {
        return { data: form };
      },
    );

    if (unassignedForms.length) {
      treetableItems.push({
        name: unassignedFormsText,
        id: -1,
        parentLogbookId: null,
        children: _.cloneDeep(unassignedForms),
      });
    }

    const scopeTreeTableLogbooks: ITreeTableNode<ITreeTableLogbook>[] = ComponentUtilities.makeTreeTableTreeFromArray(
      treetableItems,
      'parentLogbookId',
      '0',
      true,
    );

    return scopeTreeTableLogbooks.filter((treeItem: ITreeTableNode<ITreeTableLogbook>) => {
      return ScopesService.checkIfLogbookHasForm(treeItem);
    });
  }

  private static checkIfLogbookHasForm(node: any): boolean {
    if (node.children) {
      node.children = node.children.filter((child: any): boolean => {
        return this.checkIfLogbookHasForm(child);
      });

      if (node.children.length === 0) {
        return false;
      }
    }

    return true;
  }
}
