import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildDecorator} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { IPinForm, IPinFormRules } from './pin-change.model';
import { IUser } from '../../../store/settings/users/users.model';
import * as _ from 'lodash';
import * as UserActions from '../../../store/user/actions';
import * as AppActions from '../../../store/app/actions';
import * as UsersActions from '../../../store/settings/users/users.actions';
import { UsersService } from '../../../store/settings/users/users.service';
import { User } from '../../../store/user/model';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { Subscription, take } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { HelperService } from '../../service/helper.service';
import { ScwMatInputComponent } from '../scw-mat-ui/scw-mat-input/scw-mat-input.component';

@OnDestroyDecorator
@Component({
  selector: 'scw-pin-change',
  templateUrl: './pin-change.component.html',
  styleUrls: ['./pin-change.component.scss'],
  host: { class: 'pin-change-component' },
})
export class PinChangeComponent implements OnInit {
  @ViewChild('pin_change_modal') pinChangeModalRef!: ViewChildDecorator;
  @Input() pinChangeType!: string | undefined;
  @Output() issuerSubmitButtonDisabled = new EventEmitter<boolean>();

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly translate: TranslateService,
    private readonly usersService: UsersService,
    private readonly storeActions: ActionsSubject,
    public readonly helperService: HelperService,
  ) {}

  private readonly subscriptions: Subscription[] = [];
  private readonly successTitleTranslation: string = this.translate.instant('general.success');
  private readonly successMessageTranslation: string = this.translate.instant('general.changesSavedSuccessfully');

  public ngbModalRef!: NgbModalRef;
  public username!: string | null;
  public roleId?: number;
  public userInformation!: Partial<IUser>;
  public isCurrentUser: boolean = true;

  public currentPin: string | null = '';
  public newPin: string | null = '';
  public confirmationPin: string | null = '';

  public pinFormRules: IPinFormRules = {
    username: [this.helperService.getRequiredFormRule()],
    currentPin: [
      this.helperService.getRequiredFormRule(),
      {
        pattern: /^\d{4,6}$/,
        message: this.translate.instant('settings.users.password.ruleText'),
      },
    ],
    newPin: [
      this.helperService.getRequiredFormRule(),
      {
        pattern: /^\d{4,6}$/,
        message: this.translate.instant('settings.users.password.ruleText'),
      },
    ],
    confirmationPin: [
      {
        custom: true,
        validator: this.confirmationPinValidator.bind(this),
        message: this.translate.instant('settings.users.password.confirmationControlText'),
      },
      this.helperService.getRequiredFormRule(),
      {
        pattern: /^\d{4,6}$/,
        message: this.translate.instant('settings.users.password.ruleText'),
      },
    ],
  };

  public ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.username = state.username;
        this.userInformation = {
          id: Number(state.userId),
          email: state.email,
          roleId: state.roleId,
        };
      });

    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(UsersActions.PIN_CHANGE_COMPLETED))
        .subscribe((response: UsersActions.PinChangeCompleted) => {
          if (!response.payload.success) {
            return;
          }

          this.usersService.helperService.showToastMessage(
            true,
            this.successTitleTranslation,
            this.successMessageTranslation,
          );
          this.closeModal();
        }),
      this.storeActions.pipe(ofType(UserActions.CURRENT_USER_LOADED)).subscribe(() => {
        this.store.dispatch(new AppActions.HideLoader());
      }),
    );
  }

  public pinForm: IPinForm = {
    username: {
      value: this.username,
      isEnabled: false,
      rules: this.pinFormRules.username,
    },
    currentPin: {
      value: null,
      isEnabled: false,
      rules: this.pinFormRules.currentPin,
    },
    newPin: {
      value: null,
      isEnabled: false,
      rules: this.pinFormRules.newPin,
    },
    confirmationPin: {
      value: null,
      isEnabled: false,
      rules: this.pinFormRules.confirmationPin,
    },
  };

  public confirmationPinValidator(input: string | number | null): boolean {
    return input === this.pinForm.newPin.value;
  }

  private prepareUserObjectForBackEnd(reason: string | null): Partial<IUser> {
    const newPin: string | null = _.get(this.pinForm.newPin, 'value');

    if (!this.isCurrentUser) {
      return {
        reason,
        username: this.username,
        password: newPin,
      };
    }

    return {
      reason,
      username: _.get(this.pinForm.username, 'value'),
      id: this.userInformation.id,
      email: this.userInformation.email,
      roleId: this.userInformation.roleId,
      password: newPin,
    };
  }

  public showModal(changeModalType?: string, username?: string | null): void {
    this.pinChangeType = changeModalType;

    if (username && this.username !== username) {
      this.username = username;
      this.isCurrentUser = false;
    }

    this.ngbModalRef = this.ngbModal.open(this.pinChangeModalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  public closeModal(): void {
    this.issuerSubmitButtonDisabled.emit(false);
    this.pinForm.currentPin.value = null;
    this.pinForm.newPin.value = null;
    this.pinForm.confirmationPin.value = null;
    this.ngbModalRef?.dismiss();
  }

  public submitPin(isValid: boolean): void {
    this.currentPin = _.get(this.pinForm.currentPin, 'value');
    this.newPin = _.get(this.pinForm.newPin, 'value');
    this.confirmationPin = _.get(this.pinForm.confirmationPin, 'value');

    if (!isValid) {
      return;
    }

    if (this.newPin === this.confirmationPin) {
      this.usersService.store.dispatch(
        new UsersActions.PinChange(
          this.prepareUserObjectForBackEnd(this.translate.instant('general.pinChange')),
          this.isCurrentUser,
          {
            username: this.username,
            password: this.currentPin,
          },
        ),
      );
    }
  }

  public checkPinInputRules(formComponentInstance: ScwMatInputComponent): void {
    formComponentInstance.onNgModelChange();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
