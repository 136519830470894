import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleFullScreenDirective } from './directive/fullscreen/toggle-fullscreen.directive';
import { AccordionAnchorDirective } from './directive/accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './directive/accordion/accordionlink.directive';
import { AccordionDirective } from './directive/accordion/accordion.directive';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandlerService } from './service/error-service/global-error-handler.service';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { TitleComponent } from '../layout/main/title/title.component';
import { CardComponent } from './component/card/card.component';
import { CardToggleDirective } from './component/card/card-toggle.directive';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomMaxDirective } from './directive/validators/custom-max-validator.directive';
import { CustomMinDirective } from './directive/validators/custom-min-validator.directive';
import { CustomMinDateDirective } from './directive/validators/custom-min-date-validator.directive';
import { CustomMaxDateDirective } from './directive/validators/custom-max-date-validator.directive';
import { AppHttpInterceptor } from '../http.interceptor';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { DatePickerComponent, DatePickerRendererComponent } from './component/date-picker/date-picker.component';
import { DatatableComponent } from './component/datatable/datatable.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomMaxLengthValidatorDirective } from './directive/validators/custom-max-length-validator.directive';
import { DateRangePickerComponent } from './component/date-range-picker/date-range-picker.component';
import { SelectModule } from 'ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ArchwizardModule } from 'angular-archwizard';
import { getApiUrl } from '../utilities';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RouterModule } from '@angular/router';
import { FilterCardModule } from './component/filter/filter-card/filter-card.module';
import { SearchBoxComponent } from './component/filter/search-box/search-box.component';
import { SideConfigBarComponent } from './component/side-config-bar/side-config-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { DisableControlDirective } from './directive/disable-control-directive';
import { PaginationCustomizerDirective } from './directive/pagination-customizer/pagination-customizer.directive';
import { EmptyStringToNullDirective } from './directive/transformers/empty-string-to-null.directive';
import { IFrameResizerDirective } from './directive/iframe-resizer/iframe-resizer.directive';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { MomentDatePipe } from './pipe/moment-date.pipe';
import { ScwMatUiModule } from './component/scw-mat-ui/scw-mat-ui.module';
import { PageConfigurationComponent } from './component/page-configuration/page-configuration.component';
import { FilterVisibilityButtonComponent } from './component/page-header/filter-visibility-button/filter-visibility-button.component';
import { FileGeneratorComponent } from './component/file-generator/file-generator.component';
import { PrintFunctionalityButtonComponent } from './component/page-header/print-functionality-button/print-functionality-button.component';
import { ToggleNestedTableDirective } from './directive/toggle-nested-table/toggle-nested-table.directive';
import { DefaultIntl } from '../../constants';
import { JwtInterceptor } from '../jwt.interceptor';
import { IssuerComponent } from './component/issuer/issuer.component';
import { ReasonComponent } from './component/reason/reason.component';
import { BeforeActionPreparerComponent } from './component/before-action-preparer/before-action-preparer.component';
import { ESignatureComponent } from './component/esignature/e-signature.component';
import { DatatableWithDraggableRowsComponent } from './component/datatable-with-draggable-rows/datatable-with-draggable-rows.component';
import { PinChangeComponent } from './component/pin-change/pin-change.component';
import { UserDefinedFieldRendererComponent } from './component/user-defined-field-renderer/user-defined-field-renderer.component';
import { AngularFittextModule } from 'angular-fittext';
import { AppVersionPipe } from './pipe/app-version-pipe';
import { HistoryTableComponent } from './component/history-table/history-table.component';
import { ForgotPinComponent } from './component/forgot-pin/forgot-pin.component';
import { TimezonePipe } from './pipe/moment/timezone.pipe';
import { LocalePipe } from './pipe/moment/locale.pipe';
import {
  ScopeSettingsNavigatorModalComponent,
} from './component/scope-settings-navigator-modal/scope-settings-navigator-modal.component';
import { ScwFormioComponent } from './component/formio/formio.component';
import { FormioModule } from '@formio/angular';
import { ActionReasonsComponent } from '../view/settings/client/action-reasons/action-reasons.component';
import { FormatPipe } from './pipe/moment/format.pipe';
import { HistoryTableTimePipe } from './pipe/history-table-formio-pipes';
import { FormSubmissionReasonPipe, LogHistoryReasonPipe } from './pipe/reason-pipe';
import { DocumentGeneratorComponent } from './component/document-generator/document-generator.component';
import { BulkErrorModalComponent } from './component/bulk-error-modal/bulk-error-modal.component';
import {
  BulkActionConfirmationModalComponent,
} from './component/bulk-action-confirmation-modal/bulk-action-confirmation-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule.forChild(),
    Ng2SmartTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TreeModule,
    UiSwitchModule.forRoot({
      defaultBgColor: '#efefef',
      defaultBoColor: '#a6a4a4',
    }),
    ArchwizardModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule,
    MatProgressBarModule,
    FilterCardModule,
    DragDropModule,
    MatTabsModule,
    SelectModule,
    AngularMultiSelectModule,
    ScwMatUiModule,
    FormioModule,
  ],
  exports: [
    NgbModule,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    IFrameResizerDirective,
    CardToggleDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    TitleComponent,
    CardComponent,
    SpinnerComponent,
    ClickOutsideModule,
    TimezonePipe,
    LocalePipe,
    AppVersionPipe,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    CustomMaxDirective,
    CustomMinDirective,
    CustomMinDateDirective,
    CustomMaxDateDirective,
    Ng2SmartTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DatePickerRendererComponent,
    DatePickerComponent,
    DatatableComponent,
    DatatableWithDraggableRowsComponent,
    CustomMaxLengthValidatorDirective,
    NgxDaterangepickerMd,
    CommonModule,
    UiSwitchModule,
    ArchwizardModule,
    AngularMultiSelectModule,
    DateRangePickerComponent,
    FilterCardModule,
    SideConfigBarComponent,
    DisableControlDirective,
    NgxDaterangepickerMd,
    FilterCardModule,
    EmptyStringToNullDirective,
    MatProgressBarModule,
    PageHeaderComponent,
    PageConfigurationComponent,
    MomentDatePipe,
    ScwMatUiModule,
    FileGeneratorComponent,
    DocumentGeneratorComponent,
    ToggleNestedTableDirective,
    IssuerComponent,
    ReasonComponent,
    BeforeActionPreparerComponent,
    ESignatureComponent,
    PinChangeComponent,
    UserDefinedFieldRendererComponent,
    AngularFittextModule,
    HistoryTableComponent,
    ForgotPinComponent,
    ScopeSettingsNavigatorModalComponent,
    ScwFormioComponent,
    ActionReasonsComponent,
    FormatPipe,
    HistoryTableTimePipe,
    FormSubmissionReasonPipe,
    LogHistoryReasonPipe,
    BulkErrorModalComponent,
    BulkActionConfirmationModalComponent,
  ],
  declarations: [
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    IFrameResizerDirective,
    TitleComponent,
    CardComponent,
    SpinnerComponent,
    TimezonePipe,
    LocalePipe,
    AppVersionPipe,
    CustomMaxDirective,
    CustomMinDirective,
    CustomMinDateDirective,
    CustomMaxDateDirective,
    DatePickerComponent,
    DatePickerRendererComponent,
    DatatableComponent,
    DatatableWithDraggableRowsComponent,
    CustomMaxLengthValidatorDirective,
    DateRangePickerComponent,
    SearchBoxComponent,
    SideConfigBarComponent,
    DisableControlDirective,
    PaginationCustomizerDirective,
    EmptyStringToNullDirective,
    PageHeaderComponent,
    MomentDatePipe,
    PageConfigurationComponent,
    FilterVisibilityButtonComponent,
    FileGeneratorComponent,
    DocumentGeneratorComponent,
    PrintFunctionalityButtonComponent,
    ToggleNestedTableDirective,
    IssuerComponent,
    ReasonComponent,
    BeforeActionPreparerComponent,
    ESignatureComponent,
    PinChangeComponent,
    UserDefinedFieldRendererComponent,
    HistoryTableComponent,
    ForgotPinComponent,
    ScwFormioComponent,
    ScopeSettingsNavigatorModalComponent,
    ScwFormioComponent,
    ActionReasonsComponent,
    FormatPipe,
    HistoryTableTimePipe,
    FormSubmissionReasonPipe,
    LogHistoryReasonPipe,
    BulkErrorModalComponent,
    BulkActionConfirmationModalComponent,
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'API_BASE_URL',
      useFactory: getApiUrl,
    },
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
