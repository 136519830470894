<scw-mat-form #formInstance>
  <ng-template [ngIf]="!twoColumns">
    <div *ngFor="let field of fieldArray">
      <scw-mat-select
        *ngIf="field.type === 'DropdownMulti' || field.type === 'Dropdown'"
        labelKey="label"
        [label]="field.mandatory ? field.name + '*' : field.name"
        [data]="field.options"
        [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
        [singleSelection]="field.type !== 'DropdownMulti'"
        [showCheckbox]="field.type === 'DropdownMulti'"
        [(inputModel)]="field.inputModel"
        [disabled]="disableAllFields"
      >
      </scw-mat-select>
      <scw-mat-input
        *ngIf="field.type === 'Text'"
        [label]="field.mandatory ? field.name + '*' : field.name"
        [rules]="
          field.mandatory
            ? [{ required: true, message: requiredFormTranslation }, helperService.getMaxLengthFormRule(255)]
            : [helperService.getMaxLengthFormRule(255)]
        "
        [(inputModel)]="field.inputModel"
        [disabled]="disableAllFields"
      >
      </scw-mat-input>
      <scw-mat-textarea
        *ngIf="field.type === 'TextArea'"
        [label]="field.mandatory ? field.name + '*' : field.name"
        [block]="true"
        [(inputModel)]="field.inputModel"
        [rules]="
          field.mandatory
            ? [{ required: true, message: requiredFormTranslation }, helperService.getMaxLengthFormRule(255)]
            : [helperService.getMaxLengthFormRule(255)]
        "
        [disabled]="disableAllFields"
      ></scw-mat-textarea>
      <div [ngClass]="{'datepicker-div-with-margin': fieldArray.length === field.order}">
        <scw-mat-datepicker
          *ngIf="field.type === 'Datetime'"
          [label]="field.mandatory ? field.name + '*' : field.name"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [disabled]="disableAllFields"
          [singleDatePicker]="true"
          [timePicker]="true"
          [ranges]="null"
          [showCustomRangeLabel]="false"
          [alwaysShowCalendars]="false"
          [showCancel]="false"
          [autoApply]="true"
          [showClearButton]="true"
          [(inputModel)]="field.inputModel"
          [drops]="fieldArray.length === field.order ? 'up' : 'down'"
          [formatInitialModel]="!isPreviewModal"
          (inputModelChange)="dateInputModelChanged($event, field.fieldId)"
        ></scw-mat-datepicker>
      </div>
      <div class="p-b-15" *ngIf="field.type === 'Checkbox' || field.type === 'CheckboxMulti'">
        <label class="checkbox-label">{{ field.mandatory ? field.name + '*' : field.name }}</label>
        <scw-mat-checkbox
          *ngIf="field.type === 'Checkbox'"
          [label]="field.name"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [disabled]="disableAllFields"
          [(inputModel)]="field.inputModel"
          [required]="field.mandatory || false"
        ></scw-mat-checkbox>
        <scw-mat-checkbox-group
          *ngIf="field.type === 'CheckboxMulti'"
          [checkboxes]="field.options"
          [required]="field.mandatory || false"
          [(inputModel)]="field.inputModel"
          [disabled]="disableAllFields"
        ></scw-mat-checkbox-group>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="twoColumns">
    <div class="row">
      <div *ngFor="let field of fieldArray; index as i" class="col-md-6">
        <scw-mat-select
          *ngIf="field.type === 'DropdownMulti' || field.type === 'Dropdown'"
          labelKey="label"
          [label]="field.mandatory ? field.name + '*' : field.name"
          [data]="field.options"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [singleSelection]="field.type !== 'DropdownMulti'"
          [showCheckbox]="field.type === 'DropdownMulti'"
          [(inputModel)]="field.inputModel"
          [disabled]="disableAllFields"
        >
        </scw-mat-select>
        <scw-mat-input
          *ngIf="field.type === 'Text'"
          [label]="field.mandatory ? field.name + '*' : field.name"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [maxlength]="field.maxlength"
          [(inputModel)]="field.inputModel"
          [disabled]="disableAllFields"
        >
        </scw-mat-input>
        <scw-mat-textarea
          *ngIf="field.type === 'TextArea'"
          [label]="field.mandatory ? field.name + '*' : field.name"
          [block]="true"
          [(inputModel)]="field.inputModel"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [disabled]="disableAllFields"
        ></scw-mat-textarea>
        <scw-mat-datepicker
          *ngIf="field.type === 'Datetime'"
          [label]="field.mandatory ? field.name + '*' : field.name"
          [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
          [disabled]="disableAllFields"
          [singleDatePicker]="true"
          [timePicker]="true"
          [ranges]="null"
          [showCustomRangeLabel]="false"
          [alwaysShowCalendars]="false"
          [showCancel]="false"
          [autoApply]="true"
          [showClearButton]="true"
          [(inputModel)]="field.inputModel"
          [formatInitialModel]="!isPreviewModal"
          (inputModelChange)="dateInputModelChanged($event, field.fieldId)"
        ></scw-mat-datepicker>
        <div class="p-b-15" *ngIf="field.type === 'Checkbox' || field.type === 'CheckboxMulti'">
          <label class="checkbox-label">{{ field.mandatory ? field.name + '*' : field.name }}</label>
          <scw-mat-checkbox
            *ngIf="field.type === 'Checkbox'"
            [label]="field.name"
            [rules]="field.mandatory ? [{ required: true, message: requiredFormTranslation }] : []"
            [disabled]="disableAllFields"
            [(inputModel)]="field.inputModel"
            [required]="field.mandatory || false"
          ></scw-mat-checkbox>
          <scw-mat-checkbox-group
            *ngIf="field.type === 'CheckboxMulti'"
            [checkboxes]="field.options"
            [required]="field.mandatory || false"
            [(inputModel)]="field.inputModel"
            [disabled]="disableAllFields"
          ></scw-mat-checkbox-group>
        </div>
      </div>
    </div>
  </ng-template>
</scw-mat-form>
