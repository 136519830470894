import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../model/interface/generic-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly USER = {
    USER_URL: `${this.baseUrl}/users/`,
  };

  constructor(
    private readonly store: Store<AppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {}

  public editUserConfiguration(params: any): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.USER.USER_URL}configuration`, {
      payload: { meta: params },
    });
  }
}
