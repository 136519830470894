import { ILogbookTemplatesState } from './logbook-templates.model';
import * as Actions from './logbook-templates.actions';
import { Action } from '@ngrx/store';

export const logbookTemplateState: ILogbookTemplatesState = {
  getAllMasterDataLoading: false,
  getAllMasterDataLoaded: false,
  allMasterDataData: null,
  tableSettings: [],
};

export function logbookTemplatesReducer(
  state: ILogbookTemplatesState = logbookTemplateState,
  baseAction: Action,
): ILogbookTemplatesState {
  const action = baseAction as Actions.LogbookTemplateActions;

  switch (action.type) {
    case Actions.GET_ALL_MASTER_DATA:
      return {
        ...state,
        getAllMasterDataLoading: true,
        getAllMasterDataLoaded: false,
      };
    case Actions.GET_ALL_MASTER_DATA_COMPLETED:
      return {
        ...state,
        getAllMasterDataLoading: false,
        getAllMasterDataLoaded: true,
        allMasterDataData: action.payload.data,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
