import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  DateRangeOpensTypes,
  IDateRange,
  DateRangeDropsTypes,
  IDateRangeLocale,
  IDateRangeReturn,
} from './date-range-picker.model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import * as moment from 'moment';
import { take, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { HelperService } from '../../service/helper.service';
import { DateRanges } from '../../../../constants';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements IDateRange, OnInit, OnDestroy {
  @Input() alwaysShowCalendars = true;
  @Input() autoApply = false;
  @Input() className = '';
  @Input() closeOnAutoApply = false;
  @Input() customRangeDirection = false;
  @Input() customStyle: any = null;
  @Input() disabled = false;
  @Input() wide = true;
  @Input() drops = DateRangeDropsTypes.down;
  @Input() emptyWeekRowClass = '';
  @Input() firstDayOfNextMonthClass = '';
  @Input() firstMonthDayClass = '';
  @Input() id = 'ngx-date-range-picker';
  @Input() inputModel?: IDateRangeReturn;
  @Input() isCustomDate = () => {
    return false;
  };
  @Input() isInvalidDate = () => {
    return false;
  };
  @Input() keepCalendarOpeningWithRange = true;
  @Input() lastDayOfPreviousMonthClass = '';
  @Input() lastMonthDayClass = '';
  @Input() linkedCalendars: any;
  @Input() locale: any;
  @Input() lockStartDate = false;
  @Input() maxDate: any = null;
  @Input() minDate: any = null;
  @Input() opens = DateRangeOpensTypes.right;
  @Input() placeholder = this.translate.instant('dateRangePicker.placeholder');
  @Input() ranges: any;
  @Input() readonly = true;
  @Input() showCancel = true;
  @Input() showClearButton = true;
  @Input() showCustomRangeLabel = true;
  @Input() showISOWeekNumbers = false;
  @Input() showRangeLabelOnInput = false;
  @Input() showWeekNumbers = true;
  @Input() singleDatePicker = false;
  timePicker24Hour = true;
  @Input() timePicker = false;
  @Input() timePickerIncrement = 1;
  @Input() timePickerSeconds = false;
  @Input() emitOnNull = false;
  @Input() dateLimit: any = null;

  @Output() inputModelChange = new EventEmitter<IDateRangeReturn>();

  private userSubscription!: Subscription;
  private timezone$!: string | null;

  constructor(
    private store: Store<LogbookAppState>,
    public translate: TranslateService,
    private readonly helperService: HelperService,
  ) {}

  private getDefaultRanges(): DateRanges {
    const rangeTimes: DateRanges = this.helperService.getDateRangesWithTimezone([
      'today',
      'yesterday',
      'thisWeek',
      'lastWeek',
      'thisMonth',
      'lastMonth',
      'thisYear',
      'lastYear',
    ]);

    return this.helperService.getDefaultRanges(rangeTimes);
  }

  public ngOnInit(): void {
    this.userSubscription = this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state) => {
        const locale$: string = state.language ?? '';
        const dateFormat$: string = state.dateFormat ?? '';
        const timeFormat$: string = moment()
          .locale(state.locale ?? 'en')
          .localeData()
          .longDateFormat('LT');
        const displayFormatValue: string = dateFormat$ + ' ' + timeFormat$;
        this.timezone$ = state.timezone;

        const defaultLocale: IDateRangeLocale = {
          applyLabel: this.translate.instant('dateRangePicker.locale.applyLabel'),
          cancelLabel: this.translate.instant('dateRangePicker.locale.cancelLabel'),
          clearLabel: this.translate.instant('dateRangePicker.locale.clearLabel'),
          customRangeLabel: this.translate.instant('dateRangePicker.locale.customRangeLabel'),
          daysOfWeek: moment.localeData(locale$).weekdaysShort(),
          direction: 'ltr',
          firstDay: moment.localeData(locale$).firstDayOfWeek(),
          format: displayFormatValue,
          monthNames: moment.localeData(locale$).monthsShort(),
          separator: ' - ',
          weekLabel: this.translate.instant('dateRangePicker.locale.weekLabel'),
        };

        this.locale = { ...this.locale, ...defaultLocale };

        if (typeof this.ranges === 'undefined') {
          this.ranges = this.getDefaultRanges();
        }
      });
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public onNgModelChange(): void {
    const startDate = _.get(this.inputModel, 'startDate', null);
    const endDate = _.get(this.inputModel, 'endDate', null);

    if (this.emitOnNull || (startDate !== null && endDate !== null)) {
      if (
        endDate !== null &&
        startDate !== null &&
        endDate.diff(startDate, 's') === 0 &&
        '00:00:00' === endDate.format('HH:mm:ss')
      ) {
        endDate.add(1, 'days').subtract(1, 'second');
      }
    }

    const inputModelClone: IDateRangeReturn = _.cloneDeep(this.inputModel) as IDateRangeReturn;

    if (this.timezone$) {
      inputModelClone.startDate = moment
        .tz(inputModelClone.startDate.utc().format('YYYY-MM-DD HH:mm:ss'), this.timezone$)
        .utc();
      inputModelClone.endDate = moment
        .tz(inputModelClone.endDate.utc().format('YYYY-MM-DD HH:mm:ss'), this.timezone$)
        .utc();
    }

    this.inputModelChange.emit(inputModelClone);
  }
}
