import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './form-scope.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { ScopesService } from '../scopes.service';
import { IScope } from '../scopes.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { User } from '../../user/model';

@Injectable()
export class FormScopesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly service: ScopesService,
  ) {}

  getFormScopeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_SCOPES_DATA_LOADING),
      switchMap((payload: ObjectActions.FormScopesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          objectType: 'form',
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        return this.service.getScopes(httpParams).pipe(
          switchMap((response: IGetManyResponse<IScope>) => {
            return of(new ObjectActions.FormScopesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addFormScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_FORM_SCOPE),
      switchMap((objectData: ObjectActions.AddFormScope) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addScope(objectData.scope, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IScope>) => {
            return of(new ObjectActions.AddFormScopeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editFormScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_FORM_SCOPE),
      switchMap((objectData: ObjectActions.EditFormScope) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let userFormScopeId: number | null | undefined = null;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            userFormScopeId = state.formScopeId;
          });

        return this.service
          .editScope(objectData.scope, objectData.id, objectData.issuer, objectData.forceCheckOut)
          .pipe(
            switchMap((response: IBaseOneResponse<IScope>) => {
              if (objectData.id === userFormScopeId) {
                return of(
                  new ObjectActions.EditFormScopeCompleted(response),
                  new AppActions.HideLoader(),
                  new AppActions.GetCurrentUser('application-parameters'),
                );
              }

              return of(new ObjectActions.EditFormScopeCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditFormScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_FORM_SCOPES),
      switchMap((objectData: ObjectActions.BulkEditFormScopes) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditScope(objectData.scopes, objectData.issuer, objectData.reason).pipe(
          switchMap((response: IGetManyResponse<IBulkResponseRecord<IScope>>) => {
            return of(new ObjectActions.BulkEditFormScopesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
