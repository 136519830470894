import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private appInsights!: ApplicationInsights;

  constructor() {
    if (environment.appInsights.instrumentationKey !== null) {
      this.appInsightsInitializer();
    }
  }

  public logException(exception: Error, severityLevel?: number): void {
    if (this.appInsights === undefined) {
      console.error(exception);
      return;
    }

    this.appInsights.trackException({ exception, severityLevel });
  }

  public setAuthenticatedUser(userId: string, accountId: string, defaultSite: string): void {
    if (this.appInsights === undefined) {
      return;
    }

    this.appInsights.setAuthenticatedUserContext(userId, accountId);
    const telemetryInitializer = (envelope: any) => {
      envelope.data['defaultSite'] = defaultSite;
    };

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public logoutAuthorizedUser(): void {
    if (this.appInsights === undefined) {
      return;
    }

    this.appInsights.clearAuthenticatedUserContext();
  }

  private appInsightsInitializer(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey ?? undefined,
        enableAutoRouteTracking: true,
        cookieDomain: 'scm3d.com',
        autoTrackPageVisitTime: true,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });
    this.appInsights.loadAppInsights();

    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.cloud.role'] = environment.appInsights.cloudRole;
    };

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }
}
