<ng-template #bulk_error_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ modalTitle }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section inner-modal">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <div>
        <h6 class="scw-header">
          {{ successMessage }}
        </h6>
        <h6 class="scw-header mt-22 color-red">
          {{ failureMessage }}
        </h6>
        <div class="mt-22">
          <div class="f-w-700 m-b-15" *ngFor="let failedRecordDatum of failedRecordData">
            {{ 'apiErrorMessages.' + failedRecordDatum.error | translate }}
            <div class="f-w-500 m-l-15" *ngFor="let failedRecordDatumRecord of failedRecordDatum.data">
              {{ failedRecordDatumRecord.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
