import { Action } from '@ngrx/store';
import { ILogbooksCRUD } from '../../../shared/component/filter/filter.class';
import { IFilterCondition, ISortOption } from '../filter.model';

export const START_LOAD_FILTER_LOGBOOKS = '[Filter Logbook] Load Filter Logbooks';
export const FILTER_LOGBOOKS_LOADED = '[Filter Logbook] Filter Logbooks Loaded';
export const RESET_LOGBOOK_STORE = '[Filter Logbook] Reset store data for Logbook filter';

export class StartLoadFilterLogbooks implements Action {
  readonly type = START_LOAD_FILTER_LOGBOOKS;
  constructor(
    public page: number | undefined,
    public limit: number | undefined,
    public filter: IFilterCondition[] | undefined,
    public sortParams: ISortOption,
    public includeArchived: number,
    public approvalStatuses: number[] = [],
  ) {}
}

export class FilterLogbooksLoaded implements Action {
  readonly type = FILTER_LOGBOOKS_LOADED;

  constructor(public data: ILogbooksCRUD[]) {
  }
}

export class ResetLogbookStore implements Action {
  readonly type = RESET_LOGBOOK_STORE;
}

export type FilterLogbookActions = StartLoadFilterLogbooks | FilterLogbooksLoaded | ResetLogbookStore;
