<ng-template #scope_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ scopeModalHeader }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3 d-flex text-break">
      <em class="fas fa-exclamation-circle fa-lg pr-2"></em>
      {{ scopeModalText }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="goToScopeSettings()">
        {{ 'general.assign' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
