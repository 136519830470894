<div class="inner-form-section" [class.errors]="hasErrors">
  <div class="d-flex flex-column w-100 gap-3 my-3">
    <ng-template ngFor [ngForOf]="checkboxes" let-checkbox let-i="index">
      <div class="d-flex flex-row w-100 gap-1">
        <scw-mat-checkbox
          [(inputModel)]="inputModel[i]['value']"
          (onChange)="onChangeEmitter($event)"
          (inputModelChange)="onNgModelChange()"
          [disabled]="disabled"
        >
        </scw-mat-checkbox>
        <label style="margin-bottom: unset">{{ checkbox.label }}</label>
      </div>
    </ng-template>
  </div>
</div>
<ng-template [ngIf]="errorText">
  <mat-hint [class.error]="errorText">
    <em class="sub-text-icon fas" [class.fa-exclamation-circle]="errorText"></em>
    <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
  </mat-hint>
</ng-template>
