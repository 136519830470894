import { Action } from '@ngrx/store';
import { IBulkEditScope, IScope, IScopeTableQuery } from '../scopes.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IAddScope } from '../../../view/settings/scopes/scopes.model';

export const FORM_SCOPES_DATA_LOADING = '[SCOPES] FORM SCOPES DATA LOAD';
export const FORM_SCOPES_DATA_LOADED = '[SCOPES] FORM SCOPES DATA LOADED';
export const ADD_FORM_SCOPE = '[SCOPES] ADD FORM SCOPES';
export const ADD_FORM_SCOPE_COMPLETED = '[SCOPES] ADD FORM SCOPES COMPLETED';
export const EDIT_FORM_SCOPE = '[SCOPES] EDIT FORM SCOPES';
export const EDIT_FORM_SCOPE_COMPLETED = '[SCOPES] EDIT FORM SCOPES COMPLETED';
export const BULK_EDIT_FORM_SCOPES = '[SCOPES] BULK EDIT FORM SCOPES';
export const BULK_EDIT_FORM_SCOPES_COMPLETED = '[SCOPES] BULK EDIT FORM SCOPES COMPLETED';
export const FETCH_ERROR = '[SCOPES] FORM FETCH ERROR';

export class FormScopesDataLoading implements Action {
  readonly type = FORM_SCOPES_DATA_LOADING;

  constructor(public tableQuery: IScopeTableQuery) {}
}

export class FormScopesDataLoaded implements Action {
  readonly type = FORM_SCOPES_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IScope>) {}
}

export class AddFormScope implements Action {
  readonly type = ADD_FORM_SCOPE;

  constructor(public scope: IAddScope, public issuer: IIssuer | null) {}
}
export class AddFormScopeCompleted implements Action {
  readonly type = ADD_FORM_SCOPE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IScope>) {}
}

export class EditFormScope implements Action {
  readonly type = EDIT_FORM_SCOPE;

  constructor(
    public scope: IAddScope,
    public id: number,
    public issuer: IIssuer | null,
    public forceCheckOut?: boolean,
  ) {}
}
export class EditFormScopeCompleted implements Action {
  readonly type = EDIT_FORM_SCOPE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IScope>) {}
}

export class BulkEditFormScopes implements Action {
  readonly type = BULK_EDIT_FORM_SCOPES;

  constructor(public scopes: IBulkEditScope[], public issuer: IIssuer | null, public reason: string | null) {}
}
export class BulkEditFormScopesCompleted implements Action {
  readonly type = BULK_EDIT_FORM_SCOPES_COMPLETED;

  constructor(public payload: IGetManyResponse<IBulkResponseRecord<IScope>>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type FormScopeActions =
  | FormScopesDataLoading
  | FormScopesDataLoaded
  | AddFormScope
  | AddFormScopeCompleted
  | EditFormScope
  | EditFormScopeCompleted
  | BulkEditFormScopes
  | BulkEditFormScopesCompleted
  | FetchError;
