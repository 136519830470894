import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterCardComponent } from './filter-card.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FilterDateRangePickerComponent } from '../filter-date-range-picker/filter-date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FilterUpdateComponent } from '../../filter-update/filter-update.component';
import { ScwMatUiModule } from '../../scw-mat-ui/scw-mat-ui.module';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import { ButtonGroupModule } from '../button-group/button-group.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AngularMultiSelectModule,
        NgxDaterangepickerMd,
        MatProgressBarModule,
        ScwMatUiModule,
        TranslateModule,
        ButtonGroupModule,
    ],
    declarations: [
        FilterCardComponent,
        DropdownComponent,
        FilterUpdateComponent,
        FilterDateRangePickerComponent,
        AdvancedFilterComponent,
    ],
    exports: [FilterCardComponent, FilterUpdateComponent, ButtonGroupModule],
    providers: []
})
export class FilterCardModule {}
