import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatCheckboxGroupComponent } from './scw-mat-checkbox-group.component';
import { ScwMatCheckboxModule } from '../scw-mat-checkbox/scw-mat-checkbox.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [CommonModule, ScwMatCheckboxModule, MatFormFieldModule],
  exports: [ScwMatCheckboxGroupComponent],
  declarations: [ScwMatCheckboxGroupComponent],
})
export class ScwMatCheckboxGroupModule {}
