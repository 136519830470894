import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, from, of } from 'rxjs';
import * as ObjectActions from './form.actions';
import { FormService } from '../../../shared/service/filter/form.service';
import { DefaultQueryParameters } from '../../../../constants';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class FilterFormEffects {
  constructor(
    private actions$: Actions<ObjectActions.FilterFormActions>,
    public service: FormService,
    public helperService: HelperService,
  ) {
  }

  loadFilterForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LOAD_FILTER_FORMS),
      switchMap((objectData: ObjectActions.StartLoadFilterForms) => {
        if (typeof objectData.page === 'undefined') {
          objectData.page = 1;
        }

        if (typeof objectData.limit === 'undefined') {
          objectData.limit = DefaultQueryParameters.LIMIT;
        }

        return from(this.service.getForms({
          params: {
            page: objectData.page,
            limit: objectData.limit,
            filterParams: objectData.filter,
            includeArchived: objectData.includeArchived,
          },
        })).pipe(
          switchMap((response) => {
            const modifiedResponse: any[] = [];
            response.forEach((item: any) => {
              modifiedResponse.push({
                ...item,
                name: this.helperService.getNameWithArchiveLabel(item.isArchived, item.name),
              });
            });
            return of(new ObjectActions.FilterFormsLoaded(modifiedResponse));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterFormsLoaded([]));
          }),
        );
      }),
    ),
  );
}
