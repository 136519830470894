import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormTemplatesService } from './form-templates.service';
import * as FormTemplateActions from './form-templates.actions';
import * as logbookAppReducer from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IFieldVersion, IFormTemplateData } from '../../../view/reports/form-templates/form-templates.model';
import { HttpParams } from '@angular/common/http';
import * as UsersActions from '../../settings/users/users.actions';
import * as _ from 'lodash';
import { emptyAction } from '../../../../constants';
import { FormMasterDataService } from '../../settings/form-master-data/form-master-data.service';
import { IFormVersionHistory } from '../../forms/forms.model';
import { FormsService } from '../../forms/forms.service';
import { IGetFormTemplates, IGetFormTemplatesAndMasterData } from './form-templates.model';
import { IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import { EFormVersionsRequestingPages } from '../../../shared/model/enum/constants';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';

@Injectable()
export class FormTemplatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: FormTemplatesService,
    private readonly formService: FormsService,
    private readonly formMasterDataService: FormMasterDataService,
    private readonly workflowService: WorkflowsService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
  ) {}

  getAllMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_ALL_MASTER_DATA),
      switchMap((payload: FormTemplateActions.GetAllMasterData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let body: Record<string, string | number> = {};

        if (payload.formIds) {
          body = { ...body, form_ids: JSON.stringify(payload.formIds) };
        }

        if (payload.logbookIds) {
          body = { ...body, logbook_ids: JSON.stringify(payload.logbookIds) };
        }

        if (payload.sourceObjectDate) {
          body = { ...body, source_object_date: JSON.stringify(payload.sourceObjectDate) };
        }

        return this.service.getAllMasterData(body).pipe(
          switchMap((response: IGetManyResponse<IFieldVersion>) => {
            return of(
              new FormTemplateActions.GetAllMasterDataCompleted(response),
              payload.dispatchHideLoader
                ? new AppActions.HideLoader()
                : new FormTemplateActions.GetAllMasterDataCompletedToZip(),
            );
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_FORM_TEMPLATES),
      switchMap((payload: FormTemplateActions.GetFormTemplates) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
          page: payload.tableQuery?.page ?? 1,
          sort: payload.tableQuery?.sort,
          rowsPerPage: payload.tableQuery?.rowsPerPage ?? 10,
          ...(!_.isNil(payload.tableQuery?.selectedForms) &&
            Array.isArray(payload.tableQuery?.selectedForms) && {
              forms: JSON.stringify(payload.tableQuery?.selectedForms),
            }),
          ...(!_.isNil(payload.tableQuery?.selectedLogbooks) &&
            Array.isArray(payload.tableQuery?.selectedLogbooks) && {
              logbooks: JSON.stringify(payload.tableQuery?.selectedLogbooks),
            }),
          ...(!_.isNil(payload.tableQuery?.selectedDateRange) && {
            date: JSON.stringify(payload.tableQuery.selectedDateRange),
          }),
          ...(!_.isNil(payload.tableQuery.selectedStatuses) &&
            Array.isArray(payload.tableQuery.selectedStatuses) && {
              statuses: JSON.stringify(payload.tableQuery.selectedStatuses),
            }),
        });

        if (!_.isEmpty(payload.tableQuery.advancedFilter)) {
          body = {
            ...body,
            advanced_filter: JSON.stringify(this.service.getSearchString(payload.tableQuery)),
          };
        }

        body = { ...body, requesting_page: EFormVersionsRequestingPages.FORM_TEMPLATES };

        return this.service.getFormTemplates(body).pipe(
          switchMap((response: IGetManyResponse<IFormTemplateData>) => {
            return of(
              new FormTemplateActions.GetFormTemplatesCompleted(response),
              payload.dispatchHideLoader
                ? new AppActions.HideLoader()
                : new FormTemplateActions.GetFormTemplatesCompletedToZip(),
            );
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.DOWNLOAD_FORM_TEMPLATES_EXCEL),
      switchMap((objectData: FormTemplateActions.DownloadFormTemplatesExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(
          objectData.filters,
          objectData.columns,
          objectData.approvalStatuses,
          objectData.activityTypeDropdownItems,
          objectData.rawMasterDataColumns,
        );

        return emptyAction;
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_MASER_DATA),
      switchMap((payload: FormTemplateActions.GetMasterDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.formMasterDataService.getFormMasterDetailData(payload.id).pipe(
          switchMap((response) => {
            return of(
              new FormTemplateActions.GetMasterDataLoaded({
                success: response[0].success && response[1].success,
                data: { ...response[0].data, fields: response[1].data ?? [] },
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_FORM_TEMPLATE_HISTORY),
      switchMap((payload: FormTemplateActions.GetFormTemplatesHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTemplatesHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<IFormVersionHistory>) => {
            return of(new FormTemplateActions.GetFormTemplatesHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_FORM_TEMPLATE_DATA_LOADING),
      switchMap((payload: FormTemplateActions.GetFormTemplatesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTemplateData(payload.id, payload.issuer).pipe(
          switchMap((response: IGetFormTemplates) => {
            return of(new FormTemplateActions.GetFormTemplatesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataFormTemplatesData = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.GET_MASER_DATA_FORM_TEMPLATE_DATA_LOADING),
      switchMap((payload: FormTemplateActions.GetMasterDataFormTemplatesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTemplateDataAndMasterData(payload.fieldVersion, payload.id, payload.issuer).pipe(
          switchMap((response: IGetFormTemplatesAndMasterData) => {
            return of(
              new FormTemplateActions.GetMasterDataFormTemplatesDataLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkFlowStepData = createEffect(() =>
    this.actions$.pipe(
      ofType(FormTemplateActions.WORKFLOW_STEP_DATA_LOADING),
      switchMap((payload: FormTemplateActions.GetWorkflowStepData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workflowService.getWorkflowsSteps(payload.id).pipe(
          switchMap((response: IGetManyResponse<IWorkflowStepsData>) => {
            return of(new FormTemplateActions.GetWorkflowStepDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FormTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
