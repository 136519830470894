import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalSectionTitleComponent } from './scw-mat-modal-section-title.component';

@NgModule({
  declarations: [ScwMatModalSectionTitleComponent],
  exports: [ScwMatModalSectionTitleComponent],
  imports: [CommonModule],
})
export class ScwMatModalSectionTitleModule {}
