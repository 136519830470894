import { FilterConditionTypes } from '../../shared/component/filter/filter.class';

export interface IHttpOption extends ISearchCondition{
  filter?: IFilterCondition[];
  page?: number;
  limit?: number;
  sort?: string;
  s?: string;
  groupBy?: string;
  fields?: string;
}

export interface IFilterCondition {
  prop: string;
  condition: FilterConditionTypes;
  query?: string;
  isNotOrOperand?: boolean;
}

export interface ISearchCondition {
  search?: string;
}

export interface ISortOption {
  sort: string | null;
  direction: string | null;
}

export interface ILogbookFilterCard {
  params: IParamFilterCard;
  sortParams: ISortOption;
}

export interface IFormFilterCard {
  params: IParamFilterCard;
}

export interface IParamFilterCard {
  page: number;
  limit: number;
  filterParams: IFilterCondition[] | undefined;
  includeArchived: number;
  approvalStatuses?: number[];
}

export enum EArchivedFilter {
  ALL = -1,
  NOT_ARCHIVED = 0,
}
