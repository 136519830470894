<div
  class="date-range-picker-container"
  [class.width-auto]="!wide"
>
  <input
    type="text"
    class="ngx-date-range-picker"
    ngxDaterangepickerMd
    [alwaysShowCalendars]="alwaysShowCalendars"
    [autoApply]="autoApply"
    [closeOnAutoApply]="closeOnAutoApply"
    [customRangeDirection]="customRangeDirection"
    [attr.disabled]="disabled ? '' : null"
    [drops]="drops"
    [emptyWeekRowClass]="emptyWeekRowClass"
    [firstDayOfNextMonthClass]="firstDayOfNextMonthClass"
    [id]="id"
    [isCustomDate]="isCustomDate"
    [isInvalidDate]="isInvalidDate"
    [keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
    [lastDayOfPreviousMonthClass]="lastDayOfPreviousMonthClass"
    [lastMonthDayClass]="lastMonthDayClass"
    [linkedCalendars]="linkedCalendars"
    [locale]="locale"
    [lockStartDate]="lockStartDate"
    [maxDate]="maxDate"
    [dateLimit]="dateLimit"
    [minDate]="minDate"
    [opens]="opens"
    [placeholder]="placeholder"
    [ranges]="ranges"
    [readonly]="readonly"
    [showCancel]="showCancel"
    [showClearButton]="showClearButton"
    [showCustomRangeLabel]="showCustomRangeLabel"
    [showISOWeekNumbers]="showISOWeekNumbers"
    [showRangeLabelOnInput]="showRangeLabelOnInput"
    [showWeekNumbers]="showWeekNumbers"
    [singleDatePicker]="singleDatePicker"
    [timePicker24Hour]="timePicker24Hour"
    [timePicker]="timePicker"
    [timePickerIncrement]="timePickerIncrement"
    [timePickerSeconds]="timePickerSeconds"
    [ngClass]="className"
    [ngStyle]="customStyle"
    [(ngModel)]="inputModel"
    (ngModelChange)="onNgModelChange()"
  />
</div>
