export enum StatusTypes {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum EApprovalStatuses {
  SUBMITTED = 1,
  NOT_SUBMITTED = 0,
  SEND_BACK = -1,
  OBSOLETE = -2,
  APPROVED = -3,
  COMPLETED = -4,
  ACTIVE = -5,
  CANCELED = -6,
  REJECTED = -7,
  FLAGGED_TO_BE_OBSOLETE = -8,
}

export enum ELogbookApprovalStatuses {
  SUBMITTED = 1,
  SEND_BACK = -1,
  APPROVED = -2,
  REJECTED = -3,
}

export enum ELogbookReviewTypes {
  TEMPLATES = 0,
  ARCHIVES = 1,
}

export enum ECriticalityStatuses {
  NOT_EXCEPTIONAL = 0,
  EXCEPTIONAL = 1,
  NOT_APPLICABLE_EXCEPTIONAL = -1,
}

export enum EDueDateType {
  HOUR = 0,
  DAY = 1,
}

export enum EActionType {
  CREATED = 'created',
  UPDATED = 'updated',
  OBSOLETED = 'obsoleted',
  CANCELED = 'canceled',
  TO_BE_ARCHIVED = 'to_be_archived',
  TO_BE_REVIEWED = 'to_be_reviewed',
  ARCHIVED = 'archived',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVATED = 'activated',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
  SEND_BACK = 'send_back',
}

export enum EActionableType {
  FORM_SUBMISSION = 'formsubmission',
  FIELD_VERSION = 'fieldversion',
  LOGBOOK_VERSION = 'logbookversion',
  FORM_VERSION = 'formversion',
  LOGBOOK = 'logbook',
}

export enum EObjectTypes {
  FORM_SUBMISSIONS = 'form_submissions',
}

export enum ECheckInMechanism {
  CHECK_IN = 'check-in',
  CHECK_OUT = 'check-out',
}

export enum ECssDisplay {
  BLOCK = 'block',
  NONE = 'none',
}

export enum CheckInMechanism {
  FORM_SUBMISSIONS = 'form_submissions',
}

export enum EFormActivationFlow {
  ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES = 'activate_version_while_there_are_ongoing_entries',
  ACTIVATE_VERSION_AFTER_COMPLETING_ONGOING_ENTRIES = 'activate_version_after_completing_ongoing_entries',
}

export enum EFormVersionsRequestingPages {
  FORM_SETTINGS = 'form_settings',
  FORM_TEMPLATES = 'form_templates',
}

export enum ELogbookVersionsRequestingPages {
  LOGBOOK_SETTINGS = 'logbook_settings',
}
