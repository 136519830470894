import { Action } from '@ngrx/store';
import { IBulkEditScope, IScope, IScopeTableQuery } from '../scopes.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IAddScope } from '../../../view/settings/scopes/scopes.model';

export const LOGBOOK_SCOPES_DATA_LOADING = '[SCOPES] LOGBOOK SCOPES DATA LOAD';
export const LOGBOOK_SCOPES_DATA_LOADED = '[SCOPES] LOGBOOK SCOPES DATA LOADED';
export const ADD_LOGBOOK_SCOPE = '[SCOPES] ADD LOGBOOK SCOPES';
export const ADD_LOGBOOK_SCOPE_COMPLETED = '[SCOPES] ADD LOGBOOK SCOPES COMPLETED';
export const EDIT_LOGBOOK_SCOPE = '[SCOPES] EDIT LOGBOOK SCOPES';
export const EDIT_LOGBOOK_SCOPE_COMPLETED = '[SCOPES] EDIT LOGBOOK SCOPES COMPLETED';
export const BULK_EDIT_LOGBOOK_SCOPES = '[SCOPES] BULK EDIT LOGBOOK SCOPES';
export const BULK_EDIT_LOGBOOK_SCOPES_COMPLETED = '[SCOPES] BULK EDIT LOGBOOK SCOPES COMPLETED';
export const FETCH_ERROR = '[SCOPES] FETCH ERROR';

export class LogbookScopesDataLoading implements Action {
  readonly type = LOGBOOK_SCOPES_DATA_LOADING;

  constructor(public tableQuery: IScopeTableQuery) {}
}

export class LogbookScopesDataLoaded implements Action {
  readonly type = LOGBOOK_SCOPES_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IScope>) {}
}

export class AddLogbookScope implements Action {
  readonly type = ADD_LOGBOOK_SCOPE;

  constructor(public scope: IAddScope, public issuer: IIssuer | null) {}
}
export class AddLogbookScopeCompleted implements Action {
  readonly type = ADD_LOGBOOK_SCOPE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IScope>) {}
}

export class EditLogbookScope implements Action {
  readonly type = EDIT_LOGBOOK_SCOPE;

  constructor(
    public scope: IAddScope,
    public id: number,
    public issuer: IIssuer | null,
    public forceCheckOut?: boolean,
  ) {}
}
export class EditLogbookScopeCompleted implements Action {
  readonly type = EDIT_LOGBOOK_SCOPE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IScope>) {}
}

export class BulkEditLogbookScopes implements Action {
  readonly type = BULK_EDIT_LOGBOOK_SCOPES;

  constructor(public scopes: IBulkEditScope[], public issuer: IIssuer | null, public reason: string | null) {}
}
export class BulkEditLogbookScopesCompleted implements Action {
  readonly type = BULK_EDIT_LOGBOOK_SCOPES_COMPLETED;

  constructor(public payload: IGetManyResponse<IBulkResponseRecord<IScope>>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type LogbookScopeActions =
  | LogbookScopesDataLoading
  | LogbookScopesDataLoaded
  | AddLogbookScope
  | AddLogbookScopeCompleted
  | EditLogbookScope
  | EditLogbookScopeCompleted
  | BulkEditLogbookScopes
  | BulkEditLogbookScopesCompleted
  | FetchError;
