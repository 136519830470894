import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, take } from 'rxjs';
import { ScwMatFormComponent } from '../scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { HelperService } from '../../service/helper.service';
import { IScwMatDatepickerReturn } from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { User } from '../../../store/user/model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';

@OnDestroyDecorator
@Component({
  selector: 'scw-user-defined-field-renderer',
  templateUrl: './user-defined-field-renderer.component.html',
  styleUrls: ['./user-defined-field-renderer.component.scss'],
})
export class UserDefinedFieldRendererComponent implements OnInit {
  @ViewChild('formInstance', { static: true }) formInstance!: ScwMatFormComponent;

  public readonly requiredFormTranslation: string = this.translate.instant('scwMatForm.validation.required');
  private readonly subscriptions: Subscription[] = [];
  private timezone$: string | null = '';

  @Input() inputModel: any;
  @Input() twoColumns: boolean = false;
  @Input() fieldArray: any[] = [];
  @Input() actionSubject!: Subject<boolean>;
  @Input() disableAllFields: boolean = false;
  @Input() isPreviewModal: boolean = false;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly translate: TranslateService,
    public readonly helperService: HelperService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.timezone$ = state.timezone;
        }),
      this.actionSubject?.subscribe((checkRules: boolean) => {
        if (checkRules) {
          const isValid: boolean = this.formInstance.triggerRuleCheckers();
          if (isValid) {
            const inputModels: any[] = this.fieldArray.map((field) => {
              return {
                fieldId: field.fieldId,
                inputModel: field.inputModel,
              };
            });
            this.inputModelChange.emit(inputModels);
          }
          this.isValid.emit(isValid);
        }
      }),
    );
  }

  public dateInputModelChanged($event: any, fieldId: string): void {
    const field: any | undefined = _.find(this.fieldArray, { fieldId });

    if (this.timezone$ && field && !_.isNil($event)) {
      field.inputModel = {
        startDate: moment($event?.startDate).tz(this.timezone$, true),
        endDate: moment($event?.endDate).tz(this.timezone$, true),
      };
    }
  }
}
