import * as Actions from './logbook-versions.actions';
import { Action } from '@ngrx/store';
import { ILogbookVersionState } from './logbook-versions.model';

export const logbookVersionsState: ILogbookVersionState = {
  logbookVersionsDataLoaded: false,
  logbookVersionsDataLoading: false,
  logbookVersions: [],
  logbookVersionsTotalCount: 0,
};

export function logbookVersionsReducer(
  state: ILogbookVersionState = logbookVersionsState,
  baseAction: Action,
): ILogbookVersionState {
  const action = baseAction as Actions.LogbookVersionsActions;
  switch (action.type) {
    case Actions.LOGBOOK_VERSIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          logbookVersionsDataLoading: true,
          logbookVersionsDataLoaded: false,
        },
      };
    case Actions.LOGBOOK_VERSIONS_DATA_LOADED:
      return {
        ...state,
        ...{
          logbookVersions: action.payload.data,
          logbookVersionsTotalCount: action.payload?.total ?? 0,
          logbookVersionsDataLoading: false,
          logbookVersionsDataLoaded: true,
        },
      };
    default:
      return state;
  }
}
