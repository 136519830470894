import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ActionsSubject } from '@ngrx/store';
import sweetalert2 from 'sweetalert2';

// tslint:disable-next-line:function-name
export function OnDestroyDecorator<T extends { new (...args: any[]): {} }>(constructor: T): void {
  const onDestroy: any = constructor.prototype?.ngOnDestroy;

  if (typeof onDestroy === 'function') {
    constructor.prototype.ngOnDestroy = function () {
      onDestroy.apply(this, arguments);

      const propertyNames: string[] = Object.getOwnPropertyNames(this);

      for (const property of propertyNames) {
        if (!(property in this)) {
          continue;
        }

        if (this[property] instanceof NgbModalRef) {
          (this[property] as NgbModalRef)?.dismiss();
        }

        if (this[property] instanceof Subscription) {
          (this[property] as Subscription)?.unsubscribe();
        }

        if (
          this[property] &&
          Array.isArray(this[property]) &&
          this[property]?.length > 0 &&
          this[property][0] instanceof Subscription
        ) {
          (this[property] as Subscription[])?.forEach((sub: Subscription) => sub?.unsubscribe());
        }

        if (this[property] instanceof ActionsSubject && !(this[property] as ActionsSubject)?.closed) {
          (this[property] as ActionsSubject)?.complete();
        }
      }

      sweetalert2?.close();
    };
  }
}
