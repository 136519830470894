export enum EScopesSettingsTabs {
  logbookScopes = 'logbook-scopes',
  formScopes = 'form-scopes',
}

export interface IAddScope {
  name: string | null;
  isActive: boolean;
  objectItems: {};
  reason: string | null;
  description: string | null;
}

/**
 * An enum to better track the manually given special IDs
 * of the default scopes; 'All Logbooks' and 'All Forms'.
 *
 * '-1' for the 'logbookScopes.AllLogbooks' scope,
 * '-2' for the 'formScopes.AllForms' scope.
 */
export enum EStaticScopeIDs {
  ALL_LOGBOOKS_SCOPE_ID = -1,
  ALL_FORMS_SCOPE_ID = -2,
}
