import { Action } from '@ngrx/store';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IRight } from './rights.model';

export const RIGHTS_DATA_LOADING = '[RIGHTS] RIGHT DATA LOADING';
export const RIGHTS_DATA_LOADED = '[RIGHTS] RIGHT DATA LOADED';
export const FETCH_ERROR = '[RIGHTS] FETCH ERROR';

export class RightsDataLoading implements Action {
  readonly type = RIGHTS_DATA_LOADING;
}

export class RightsDataLoaded implements Action {
  readonly type = RIGHTS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IRight>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type RightsActions = RightsDataLoading | RightsDataLoaded | FetchError;
