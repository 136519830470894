import { IMenu, IMenuSearchableKey } from '../../store/main/main.model';
import { ELogbookGridView } from '../../view/home/logbook-grid-view/logbook-grid-view.model';
import * as HomeActions from '../../store/home/home.actions';

export function onResize(this: any, event: any): void {
  this.windowWidth = event.target.innerWidth;
  this.setHeaderAttributes(this.windowWidth);

  let reSizeFlag = true;

  if (this.pcodedDeviceType === this.deviceType.tablet && this.windowWidth >= 768 && this.windowWidth <= 1024) {
    reSizeFlag = false;
  } else if (this.pcodedDeviceType === this.deviceType.phone && this.windowWidth < 768) {
    reSizeFlag = false;
  }

  /* for check device */
  if (reSizeFlag) {
    this.setMenuAttributes(this.windowWidth);
  }

  if (this.pcodedDeviceType !== this.deviceType.desktop) {
    this.pageHeaderPaddingTop = '0';
  }
}

export function setHeaderAttributes(this: any, windowWidth: number): void {
  if (windowWidth < 992) {
    this.navRight = 'nav-off';
  } else {
    this.navRight = 'nav-on';
  }
}

export function setMenuAttributes(this: any, windowWidth: number): void {
  if (windowWidth >= 768 && windowWidth <= 1024) {
    this.pcodedDeviceType = this.deviceType.tablet;
    this.verticalNavType = 'offcanvas';
    this.verticalEffect = 'overlay';
    this.toggleIcon = 'icon-menu';
    this.headerFixedTop = '0';
  } else if (windowWidth < 768) {
    this.pcodedDeviceType = this.deviceType.phone;
    this.verticalNavType = 'offcanvas';
    this.verticalEffect = 'overlay';
    this.toggleIcon = 'icon-menu';
    this.headerFixedTop = '0';
  } else {
    this.pcodedDeviceType = this.deviceType.desktop;
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.toggleIcon = 'icon-x';
    this.headerFixedTop = '50px';
  }
}

export function toggleProfileDropdown(this: any): void {
  this.profileDropdown = this.profileDropdown === 'an-off' ? 'an-animate' : 'an-off';
  this.profileDropdownClass = this.profileDropdown === 'an-animate' ? 'show' : '';

  if (this.profileDropdown === 'an-animate') {
    this.collapseOtherNavBarDropdowns('profile');
  }
}

export function toggleNavBarDropdown(this: any, element: string): void {
  if (this.navBarDropdowns[element]) {
    this.navBarDropdowns[element].state = this.navBarDropdowns[element].state === 'an-off' ? 'an-animate' : 'an-off';
    this.navBarDropdowns[element].klass = this.navBarDropdowns[element].state === 'an-animate' ? 'show' : '';

    if (this.navBarDropdowns[element].state === 'an-animate') {
      this.collapseOtherNavBarDropdowns(element);
    }
  }
}

export function collapseOpenDropdown(this: any, element: string): void {
  if (
    this.navBarDropdowns[element] &&
    this.navBarDropdowns[element].state === 'an-animate' &&
    this.navBarDropdowns[element].klass === 'show'
  ) {
    this.navBarDropdowns[element].state = 'an-off';
    this.navBarDropdowns[element].klass = '';
  }

  if (element === 'profile' && this.profileDropdown === 'an-animate' && this.profileDropdownClass === 'show') {
    this.profileDropdown = 'an-off';
    this.profileDropdownClass = '';
  }
}

export function collapseOtherNavBarDropdowns(this: any, element: string) {
  Object.keys(this.navBarDropdowns).forEach((navBarRoot) => {
    if (navBarRoot !== element) {
      this.navBarDropdowns[navBarRoot].state = 'an-off';
      this.navBarDropdowns[navBarRoot].klass = '';
    }
  });
  if (element !== 'profile') {
    this.profileDropdown = 'an-off';
    this.profileDropdownClass = '';
    this.profileDropdownClassChange.emit(this.profileDropdownClass);
    this.profileDropdownChange.emit(this.profileDropdown);
  }
}

export function setBackgroundPattern(pattern: string): void {
  document.querySelector('body')?.setAttribute('themebg-pattern', pattern);
}

export function goLink(this: any, data: any): void | string {
  const state = `/${data['state']}`;

  if (data['external']) {
    let url = window.location.origin;
    url = url.replace('8002', '8001');
    url += state;
    window.location.href = url;
  } else {
    if (state === '/home') {
      this.store.dispatch(new HomeActions.GridViewChanged(ELogbookGridView.Network));
    }

    this.router.navigate([state]).then((r: any) => r);
  }
}

export function findMenuItemInTreeMenu(items: IMenu[], search: IMenuSearchableKey): IMenu | null {
  let output: IMenu | null = null;

  for (const item of items) {
    if (item.children?.length ?? 0 > 0) {
      output = findMenuItemInChildrenMenu(item.children ?? [], search);
    }

    if (output !== null) {
      return output;
    }
  }

  return null;
}

function findMenuItemInChildrenMenu(children: IMenu[], search: IMenuSearchableKey): IMenu | null {
  for (const item of children) {
    if (item.children?.length ?? 0 > 0) {
      const output = findMenuItemInChildrenMenu(item.children ?? [], search);

      if (output !== null) {
        return output;
      }
    }

    for (const key in search) {
      const itemElement = item[key as keyof IMenu];
      if (
        Array.isArray(itemElement) &&
        itemElement.includes(search[key as keyof IMenuSearchableKey] as unknown as IMenu)
      ) {
        return item;
      }
    }
  }

  return null;
}
