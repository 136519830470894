import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IMasterDataVersion,
  IMasterDataTasksData,
  IFieldSetData,
} from '../../../../store/my-tasks/master-data-tasks/master-data-tasks.model';
import { IGetManyResponse, IGetOneResponse } from '../../../model/interface/crud-response-interface.model';
import { IMasterDataTasksHistory } from '../../../../view/my-tasks/master-data-tasks/master-data-tasks.component.model';
import { TranslateService } from '@ngx-translate/core';
import { ITableHeader } from '../../../../../constants.model';
import * as _ from 'lodash';
import { IIssuer } from '../../../component/issuer/issuer.model';
import { IReview, IReviewResults } from '../../../../store/my-tasks/my-tasks.model';

@Injectable({
  providedIn: 'root',
})
export class MasterDataTasksService {
  private readonly FIELDSET_ITEMS = '/field-version-items/';
  private readonly FIELD_VERSIONS = {
    BASE_URL: '/field-versions/',
    ACTIVE: 'active/',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    USER_ACTIONS: '/user-actions/grouped-field-versions/',
    USER_ACTIONS_FOR_ONE_VERSION: '/user-actions/field-versions/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getReviewData(params: Record<string, string | number>): Observable<IGetManyResponse<IMasterDataTasksData>> {
    let url = `${this.baseUrl}${this.FIELD_VERSIONS.BASE_URL}${this.FIELD_VERSIONS.REVIEW}`;
    return this.http.post<IGetManyResponse<IMasterDataTasksData>>(
      url,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public reviewEntries(reviewPayload: IReview, issuer: IIssuer | null): Observable<IGetOneResponse<IReviewResults[]>> {
    let url = `${this.baseUrl}${this.FIELD_VERSIONS.BASE_URL}${this.FIELD_VERSIONS.REVIEW}`;

    return this.http.post<IGetOneResponse<IReviewResults[]>>(url, {
      issuer,
      payload: reviewPayload,
    });
  }

  public getMasterDataTasksHistory(id: number): Observable<IGetManyResponse<IMasterDataTasksHistory>> {
    const url = `${this.baseUrl}${this.FIELD_VERSIONS.USER_ACTIONS}${id}`;
    return this.http.get<IGetManyResponse<IMasterDataTasksHistory>>(url);
  }

  public getMasterDataTasksHistoryForOneVersion(id: number): Observable<IGetManyResponse<IMasterDataTasksHistory>> {
    const url = `${this.baseUrl}${this.FIELD_VERSIONS.USER_ACTIONS_FOR_ONE_VERSION}${id}`;
    return this.http.get<IGetManyResponse<IMasterDataTasksHistory>>(url);
  }

  public getMasterDataTasksRecord(id: number): Observable<IGetOneResponse<IMasterDataVersion>> {
    let url = `${this.baseUrl}${this.FIELD_VERSIONS.BASE_URL}${id}`;
    return this.http.get<IGetOneResponse<IMasterDataVersion>>(url);
  }

  public getMasterDataTasksActiveRecord(fieldVersionType: string): Observable<IGetOneResponse<IMasterDataVersion>> {
    let url = `${this.baseUrl}${this.FIELD_VERSIONS.BASE_URL}${this.FIELD_VERSIONS.ACTIVE}${fieldVersionType}`;
    return this.http.get<IGetOneResponse<IMasterDataVersion>>(url);
  }

  public getMasterDataTasksFieldSet(id: number): Observable<IGetOneResponse<IFieldSetData[]>> {
    let url = `${this.baseUrl}${this.FIELDSET_ITEMS}${id}`;
    return this.http.get<IGetOneResponse<IFieldSetData[]>>(url);
  }

  public static getMasterDataTasksTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'fieldVersionType',
        name: translateService.instant('myTasks.table.headers.fieldType'),
        sortable: true,
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'versionNumber',
        name: translateService.instant('myTasks.table.headers.fieldVersion'),
        sortable: true,
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'createdBy',
        name: translateService.instant('myTasks.table.headers.createdBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'createdAt',
        name: translateService.instant('myTasks.table.headers.createdAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedBy',
        name: translateService.instant('myTasks.table.headers.submittedBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedAt',
        name: translateService.instant('myTasks.table.headers.submittedAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'approvalStepPosition',
        name: translateService.instant('myTasks.table.headers.status'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'dueDate',
        name: translateService.instant('myTasks.table.headers.dueDate'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'actions',
        name: translateService.instant('myTasks.table.headers.history'),
        sortable: false,
        selected: true,
        draggable: true,
        disabled: true,
      },
    ]);
  }
}
