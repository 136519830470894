import { Action } from '@ngrx/store';
import { IUsersCRUD, IUsersFilterData } from '../../../shared/component/filter/filter.class';

export enum FilterUserActionTypes {
  START_LOAD_FILTER_USERS = '[Filter User] Load Filter Users',
  FILTER_USERS_LOADED = '[Filter User] Filter Users Loaded',
  RESET_USER_STORE = '[Filter User] Reset store data for user filter',
}

export class StartLoadFilterUsers implements Action {
  readonly type = FilterUserActionTypes.START_LOAD_FILTER_USERS;

  constructor(public page: number | undefined, public limit: number | undefined, public search: string | undefined) {}
}

export class FilterUsersLoaded implements Action {
  readonly type = FilterUserActionTypes.FILTER_USERS_LOADED;

  constructor(public data: IUsersFilterData[]) {}
}

export class ResetUserStore implements Action {
  readonly type = FilterUserActionTypes.RESET_USER_STORE;
}

export type FilterUserActions = StartLoadFilterUsers | FilterUsersLoaded | ResetUserStore;
