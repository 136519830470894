import { IWorkflowState } from './workflows.model';
import * as Actions from './workflows.actions';
import { Action } from '@ngrx/store';

export const workflowState: IWorkflowState = {
  workflowDataLoading: false,
  workflowDataLoaded: false,
  workflowStepsDataLoaded: false,
  workflowStepsDataLoading: false,
  workflowDataTotal: 0,
  workflowData: null,
  workflowStepsData: null,
  addWorkflowLoading: false,
  addWorkflowLoaded: false,
  rolesDataLoaded: false,
  rolesDataLoading: false,
  rolesData: null,
  formMasterData: null,
  editWorkflowStepsLoading: false,
  editWorkflowStepsLoaded: false,
  addWorkflowStepsLoading: false,
  addWorkflowStepsLoaded: false,
  deleteWorkflowStepsLoaded: false,
  deleteWorkflowStepsLoading: false,
  formMasterDataLoading: false,
  formMasterDataLoaded: false,
  logbookMasterDataLoaded: false,
  logbookMasterDataLoading: false,
  logbookMasterData: null,
};

export function workflowReducer(state: IWorkflowState = workflowState, baseAction: Action): IWorkflowState {
  const action = baseAction as Actions.WorkflowsActions;
  switch (action.type) {
    case Actions.WORKFLOWS_DATA_LOADING:
      return {
        ...state,
        workflowDataLoading: true,
        workflowDataLoaded: false,
      };
    case Actions.WORKFLOWS_STEPS_DATA_LOADING:
      return {
        ...state,
        workflowStepsDataLoading: true,
        workflowStepsDataLoaded: false,
      };
    case Actions.WORKFLOWS_DATA_LOADED:
      return {
        ...state,
        workflowData: action.payload.data,
        workflowDataTotal: action.payload.total,
        workflowDataLoading: false,
        workflowDataLoaded: true,
      };
    case Actions.WORKFLOWS_STEPS_DATA_LOADED:
      return {
        ...state,
        workflowStepsData: action.payload,
        workflowStepsDataLoading: false,
        workflowStepsDataLoaded: true,
      };
    case Actions.ADD_WORKFLOW:
      return {
        ...state,
        addWorkflowLoading: true,
        addWorkflowLoaded: false,
      };
    case Actions.SAVE_WORKFLOW_STEPS:
      return {
        ...state,
        addWorkflowStepsLoading: true,
        addWorkflowStepsLoaded: false,
      };
    case Actions.SAVE_WORKFLOW_COMPLETED:
      return {
        ...state,
        addWorkflowLoading: false,
        addWorkflowLoaded: true,
      };
    case Actions.EDIT_WORKFLOW_STEPS:
      return {
        ...state,
        editWorkflowStepsLoading: true,
        editWorkflowStepsLoaded: false,
      };
    case Actions.EDIT_WORKFLOW_STEPS_COMPLETED:
      return {
        ...state,
        editWorkflowStepsLoading: false,
        editWorkflowStepsLoaded: true,
      };
    case Actions.ADD_WORKFLOW_STEPS_COMPLETED:
      return {
        ...state,
        addWorkflowStepsLoading: false,
        addWorkflowStepsLoaded: true,
      };
    case Actions.DELETE_WORKFLOW_STEPS:
      return {
        ...state,
        deleteWorkflowStepsLoading: true,
        deleteWorkflowStepsLoaded: false,
      };
    case Actions.DELETE_WORKFLOW_STEPS_COMPLETED:
      return {
        ...state,
        deleteWorkflowStepsLoading: false,
        deleteWorkflowStepsLoaded: true,
      };
    case Actions.GET_ROLES_DATA_LOADING:
      return {
        ...state,
        rolesDataLoading: true,
        rolesDataLoaded: false,
      };
    case Actions.GET_ROLES_DATA_LOADED:
      return {
        ...state,
        rolesData: action.payload,
        rolesDataLoading: false,
        rolesDataLoaded: true,
      };
    case Actions.GET_FORM_MASTER_DATA_WORKFLOW_LOADING:
      return {
        ...state,
        formMasterDataLoading: true,
        formMasterDataLoaded: false,
      };
    case Actions.GET_FORM_MASTER_DATA_WORKFLOW_LOADED:
      return {
        ...state,
        formMasterData: action.payload,
        formMasterDataLoading: false,
        formMasterDataLoaded: true,
      };
    case Actions.GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADING:
      return {
        ...state,
        logbookMasterDataLoading: true,
        logbookMasterDataLoaded: false,
      };
    case Actions.GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADED:
      return {
        ...state,
        logbookMasterData: action.payload,
        logbookMasterDataLoading: false,
        logbookMasterDataLoaded: true,
      };
    default:
      return state;
  }
}
