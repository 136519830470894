import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import * as AppActions from '../../../store/app/actions';
import * as MainActions from '../../../store/main/main.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PageHeaderService } from '../../service/page-header/page-header.service';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { ImageCompression, jsPDF, jsPDFOptions } from 'jspdf';
import autoTable, { CellHookData } from 'jspdf-autotable';

import {
  EDocumentElementSelector,
  IDocument,
  IDocumentContentToBePrinted,
  IDocumentFilter,
  IDocumentFormIoSelectors,
  IDocumentPadding,
  IDocumentPageConfig,
  IPrintableComponent,
  PageOrientation,
} from './document-generator.model';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { IPageHeader } from '../../service/page-header/page-header.model';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import * as htmlToImage from 'html-to-image';

import { fontawesomeCssBase64Data } from '../../../../assets/icon/fontawesome/fontawesome-css-data';

@Component({
  selector: 'document-generator',
  template:
    '<link href="assets/fonts/ZenKakuGothicAntique-Regular.ttf" rel="prefetch" as="font" type="font/tff" class="d-none">',
})
export class DocumentGeneratorComponent implements OnInit, OnDestroy {
  @Input() initializePrint: Subject<boolean> = new Subject<boolean>();
  @Input() document!: IDocument;
  @Input() filterParameters?: IDocumentFilter;
  @Input() printableComponentClassSelector: EDocumentElementSelector = EDocumentElementSelector.IS_PRINTABLE;

  private pageConfigs: IDocumentPageConfig[] = [];
  private currentPageIndex: number = 0;

  private readonly dimensions: {
    longEdge: number;
    shortEdge: number;
    longEdgesPadding: number;
    shortEdgesPadding: number;
  } = {
    longEdge: 630,
    shortEdge: 446,
    longEdgesPadding: 30,
    shortEdgesPadding: 30,
  };

  private readonly pageDimensions: {
    height: number;
    width: number;
    leftOrRightPadding: number;
    topOrBottomPadding: number;
  } = {
    height: this.dimensions.longEdge,
    width: this.dimensions.shortEdge,
    leftOrRightPadding: this.dimensions.longEdgesPadding,
    topOrBottomPadding: this.dimensions.shortEdgesPadding,
  };

  private readonly pageType: string = 'A4';
  private readonly fileFormat: string = 'JPEG';
  private readonly imageCompression: ImageCompression = 'FAST';
  private readonly defaultImageAlias: string | undefined;
  private readonly dataUrlType: string = 'image/jpeg';
  private readonly dataUrlQuality: number = 0.5;
  private readonly pageTranslation = this.translate.instant('general.page');
  private readonly pageOfTranslation = this.translate.instant('general.of');

  private downloadedFileName!: string;
  private pageHeaderSubscription!: Subscription;
  private htmlContents: HTMLElement[] | HTMLCanvasElement[] = [];
  private canvasContents: HTMLCanvasElement[] = [];
  private canvasContentToBePrinted: IDocumentContentToBePrinted[] = [];
  private headerImage: HTMLImageElement = new Image();
  private defaultImage: HTMLImageElement = new Image();
  private footerImage: HTMLImageElement = new Image();
  private pageContentWithForPortrait: string = '1000px';
  private pageContentWithForLandscape: string = '1450px';
  private pageContentWithForPortraitInNumber: number = 1000;
  private pageContentWithForLandscapeInNumber: number = 1450;
  private gapBetweenComponentsInPixel: number = 10;
  private formIoPanelPadding: number = 20;
  private formIoPanelBottomMargin: number = 8;

  public defaultFilePadding: IDocumentPadding = {
    top: 50,
    bottom: 50,
    left: 30,
    right: 30,
  };

  public defaultDocumentPageConfig: IDocumentPageConfig = {
    id: 1,
    page: {
      padding: this.defaultFilePadding,
      orientation: PageOrientation.portrait,
    },
    pageContentHeight: 0,
    remainingPageContentHeightBalance: 0,
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translate: TranslateService,
    private readonly pageHeaderService: PageHeaderService,
    private readonly store: Store<LogbookAppState>,
  ) {}

  public ngOnInit(): void {
    this.pageConfigs = [];
    this.currentPageIndex = 0;

    this.initializePrint.subscribe((initializePrint: boolean) => {
      if (!initializePrint) {
        return;
      }

      this.store.dispatch(new AppActions.ShowLoader());
      setTimeout(() => {
        this.downloadDocument()
          .then(() => {
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new MainActions.PdfDownloaded());
          })
          .catch((e) => {
            console.log(e);
            this.store.dispatch(new AppActions.HideLoader());
          });
      });
    });
  }

  private async initializeJsPDF(firstOrientation: PageOrientation): Promise<jsPDF> {
    let pageConfig: IDocumentPageConfig = _.cloneDeep(this.defaultDocumentPageConfig);
    pageConfig.page.orientation = firstOrientation;
    await this.createPageConfig(pageConfig);
    let orientation: PageOrientation = this.pageConfigs[0].page.orientation;
    const pdfOptions: jsPDFOptions = {
      orientation,
      compress: true,
      unit: 'px',
      format: this.pageType,
    };

    const doc: jsPDF = new jsPDF(pdfOptions);
    return doc;
  }

  public async downloadDocument(): Promise<void> {
    if (!this.document.printableComponentsSelectors.length) {
      return;
    }
    await this.preparePageDefaults();
    let firstOrientation: PageOrientation = this.document.printableComponentsSelectors[0].orientationPreference;
    const doc: jsPDF = await this.initializeJsPDF(firstOrientation);
    doc.addFont('assets/fonts/ZenKakuGothicAntique-Regular.ttf', 'ZenKakuGothicAntique', 'normal');
    doc.addFont('assets/fonts/OpenSans-Regular.ttf', 'OpenSans', 'normal');
    doc.setFont('OpenSans', 'normal');
    doc.setDocumentProperties({
      title: `${this.downloadedFileName}_${moment().format('YYYY_MM_DD')}.pdf`,
      subject: '',
      author: 'SCW.AI',
      keywords: 'logbook, scw, supply chain wizard ',
      creator: 'SCW.AI Logbook',
    });

    await this.craftHtmlContentOnDom();
    await this.convertToCanvas();
    await this.resizeCanvases();
    await this.putCanvasesIntoPages(doc);
    await this.putHeaderFooterIntoPages(doc);

    doc.save(`${this.downloadedFileName}_${moment().format('YYYY_MM_DD')}.pdf`);
    const removeItem: NodeListOf<HTMLDivElement> = document.querySelectorAll('.temp-document-remove-item');
    removeItem.forEach((item: HTMLDivElement) => item.remove());
  }

  private async preparePageDefaults(): Promise<void> {
    await this.setHeaderImageSrc();
    await this.setFooterImageSrc();
    this.defaultImage.src = `${await this.getBase64FromUrl('assets/images/digital-logbook-black-text.png')}`;

    if (this.document.bannerConfiguration.header?.fileName) {
      this.downloadedFileName = this.document.bannerConfiguration.header?.fileName?.replace(/ /g, '') ?? '';
    } else if (this.document.bannerConfiguration.header?.title === '') {
      this.pageHeaderSubscription = this.pageHeaderService.pageHeader.subscribe((item: IPageHeader) => {
        _.set(this.document.bannerConfiguration, 'header.title', item.title);
        this.downloadedFileName = item.title?.replace(/ /g, '') ?? '';
      });
    } else {
      this.downloadedFileName = this.document.bannerConfiguration.header?.title.replace(/ /g, '') ?? '';
    }

    this.pageConfigs = [];
    this.currentPageIndex = 0;
  }

  private async createPageConfig(pageConfig: IDocumentPageConfig) {
    if (pageConfig.page.orientation === PageOrientation.landscape) {
      this.pageDimensions.height = this.dimensions.shortEdge;
      this.pageDimensions.width = this.dimensions.longEdge;
      this.pageDimensions.leftOrRightPadding = this.dimensions.shortEdgesPadding;
      this.pageDimensions.topOrBottomPadding = this.dimensions.longEdgesPadding;
    } else {
      this.pageDimensions.height = this.dimensions.longEdge;
      this.pageDimensions.width = this.dimensions.shortEdge;
      this.pageDimensions.leftOrRightPadding = this.dimensions.longEdgesPadding;
      this.pageDimensions.topOrBottomPadding = this.dimensions.shortEdgesPadding;
    }
    pageConfig.pageContentHeight =
      this.pageDimensions.height -
      ((pageConfig.page?.padding?.top ?? this.pageDimensions.topOrBottomPadding) +
        (pageConfig.page?.padding?.bottom ?? this.pageDimensions.topOrBottomPadding));

    pageConfig.remainingPageContentHeightBalance = pageConfig.pageContentHeight;

    this.pageConfigs.push(pageConfig);
  }

  private async createPage(doc: jsPDF) {
    this.currentPageIndex++;
    const pageConfig = this.pageConfigs[this.currentPageIndex];
    doc.addPage(this.pageType, pageConfig.page?.orientation ?? PageOrientation.portrait);
  }

  private async craftHtmlContentOnDom() {
    this.htmlContents = [];
    let styleWidth: string = this.pageContentWithForPortrait;

    for (let index: number = 0; index < this.document.printableComponentsSelectors.length; index += 1) {
      const component: IPrintableComponent = this.document.printableComponentsSelectors[index];

      if (component.isRootLevel) {
        const printableComponent: IPrintableComponent = this.document.printableComponentsSelectors[index];
        const parentElement: HTMLCollectionOf<Element> = document.getElementsByClassName(
          printableComponent.parentContainerClass,
        );
        let findElement: HTMLElement | null;

        if (parentElement) {
          if (parentElement.length > 0) {
            findElement = parentElement[0].querySelector(`${component.selector}`);

            if (findElement && (findElement.clientHeight || findElement.scrollHeight || findElement.offsetHeight)) {
              styleWidth =
                printableComponent.orientationPreference === PageOrientation.landscape
                  ? this.pageContentWithForLandscape
                  : styleWidth;
              const printElement: HTMLElement = document.createElement('div');
              printElement.style.width = styleWidth;

              if (
                findElement.classList.contains('formio-component-selectboxes') ||
                findElement.classList.contains('formio-component-radio')
              ) {
                try {
                  const figureBody: CSSStyleDeclaration = window.getComputedStyle(findElement);

                  if (figureBody) {
                    const elementLocation: string = 'children.1.children.0.children.0.children.1.innerHTML';

                    if (_.get(findElement, elementLocation, null) === '') {
                      _.set(findElement, elementLocation, '&nbsp');
                    }
                  }
                } catch (err) {}
              }

              if (['.pre-defined-fields', '.is-printable', '.user-defined-fields'].indexOf(component.selector) !== -1) {
                printElement.style.width = '700px';
              }
              printElement.style.fontSize = '18px';

              const newAssignedParentClassId: string = (Math.random() + 1).toString(36).substring(2, 12);
              this.document.printableComponentsSelectors[index].parentContainerClass = newAssignedParentClassId;
              printElement.classList.add(newAssignedParentClassId);
              printElement.classList.add('temp-document-remove-item');
              printElement.appendChild(findElement.cloneNode(true));
              document.body.appendChild(printElement);

              if (component.selector === '.qr-code') {
                const selectedSVGElement: SVGSVGElement | null = printElement.querySelector('svg');
                printElement.style.paddingTop = '150px';

                if (selectedSVGElement) {
                  selectedSVGElement.setAttribute('width', '400');
                  selectedSVGElement.setAttribute('height', '400');
                }
              }

              if (component.selector === '.qr-code-detail') {
                printElement.classList.add('h3');
                printElement.style.lineHeight = '50px';
                let selectedElements: HTMLElement[] = Array.from(printElement.querySelectorAll('.logbook-data-label'));
                selectedElements.forEach((element: HTMLElement) => {
                  element.classList.remove('logbook-data-label');
                });

                selectedElements = Array.from(printElement.querySelectorAll('.logbook-data-text'));
                selectedElements.forEach((element: HTMLElement) => {
                  element.classList.remove('logbook-data-text');
                  element.classList.add(...['h2', 'font-weight-bolder']);
                });
              }

              this.htmlContents.push(printElement as HTMLCanvasElement);
            }
          }
        }
      }
    }
  }

  private async convertToCanvas() {
    this.canvasContents = [];
    for (const htmlElement of this.htmlContents) {
      const imageData: string = await htmlToImage.toJpeg(htmlElement, {
        backgroundColor: 'white',
        quality: 0.95,
        cacheBust: true,
        skipAutoScale: true,
        skipFonts: false,
        fontEmbedCSS: fontawesomeCssBase64Data,
      });

      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
      const image: HTMLImageElement = new Image();
      await this.loadImage(image, imageData);
      canvas.height = image.height;
      canvas.width = image.width;
      context?.drawImage(image, 0, 0);

      this.canvasContents.push(canvas);
    }
  }

  private async loadImage(image: HTMLImageElement, path: string) {
    return new Promise((resolve, reject) => {
      image.crossOrigin = 'Anonymous';
      image.src = path;
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (e) => {
        reject(e);
      };
    });
  }

  private async resizeCanvases() {
    this.canvasContentToBePrinted = [];
    for (let index: number = 0; index < this.canvasContents.length; index += 1) {
      const canvas: HTMLCanvasElement = this.canvasContents[index] as HTMLCanvasElement;
      const component: IPrintableComponent = this.document.printableComponentsSelectors[index];
      let forcedCanvasWidth: number = this.dimensions.shortEdge - this.dimensions.shortEdgesPadding * 2;

      if (component.orientationPreference === PageOrientation.landscape) {
        forcedCanvasWidth = this.dimensions.longEdge - this.dimensions.longEdgesPadding * 2;
      }

      const forcedCanvasHeight: number = (canvas.height * forcedCanvasWidth) / canvas.width;
      const imageRatio: number = canvas.width / canvas.height;

      this.canvasContentToBePrinted.push({
        canvasElement: canvas,
        originIndex: index,
        forcedCanvasWidth,
        forcedCanvasHeight,
        imageRatio,
      });
    }
  }

  private async putCanvasesIntoPages(doc: jsPDF) {
    let parentPositionPixelCounter: number = 0;
    for (let index: number = 0; index < this.canvasContentToBePrinted.length; index += 1) {
      const canvasContent: IDocumentContentToBePrinted = this.canvasContentToBePrinted[index];
      const printableComponent: IPrintableComponent =
        this.document.printableComponentsSelectors[canvasContent.originIndex];

      if (
        printableComponent.orientationPreference !== this.pageConfigs[this.currentPageIndex].page.orientation ||
        printableComponent.startNewPage === true
      ) {
        await this.createNextPage(doc, printableComponent.orientationPreference);
        parentPositionPixelCounter = 0;
      }

      const canvas: HTMLCanvasElement = canvasContent.canvasElement as HTMLCanvasElement;

      const canvasImgURL: string = canvas.toDataURL(this.dataUrlType, this.dataUrlQuality);
      const positionX: number = this.pageConfigs[this.currentPageIndex].page?.padding?.left ?? 0;

      const printableComponentsSelector: IPrintableComponent =
        this.document.printableComponentsSelectors[canvasContent.originIndex];

      if (printableComponentsSelector.printAsAutoTable) {
        const parentElement = document.getElementsByClassName(printableComponentsSelector.parentContainerClass);
        let findElement: any;

        if (parentElement) {
          if (parentElement.length > 0) {
            findElement = parentElement[0].querySelector(`${printableComponentsSelector.selector}`);
            let findTable = findElement.querySelector('table');
            autoTable(doc, {
              startY:
                (parentPositionPixelCounter ?? 0) +
                (this.pageConfigs[this.currentPageIndex].page?.padding?.top ?? this.pageDimensions.topOrBottomPadding),
              tableWidth: canvasContent.forcedCanvasWidth,
              pageBreak: 'auto',
              margin: {
                top:
                  this.pageConfigs[this.currentPageIndex].page?.padding?.top ?? this.pageDimensions.topOrBottomPadding,
                bottom:
                  this.pageConfigs[this.currentPageIndex].page?.padding?.bottom ??
                  this.pageDimensions.topOrBottomPadding,
                left:
                  this.pageConfigs[this.currentPageIndex].page?.padding?.left ?? this.pageDimensions.leftOrRightPadding,
                right:
                  this.pageConfigs[this.currentPageIndex].page?.padding?.right ??
                  this.pageDimensions.leftOrRightPadding,
              },
              useCss: true,
              html: findTable,
              styles: { fontSize: 8 },
              didParseCell: (data: CellHookData) => {
                data.cell.text = data.cell.text.map((item: string) => {
                  const itemInArray: string[] = item.split(' ');
                  let piecesOfItem: string[] = [];
                  const rowSize: number = 80;
                  for (let piece of itemInArray) {
                    let modifiedItem = [];
                    if (piece.indexOf(' ') === -1 && piece.length > rowSize) {
                      for (let i = 0; i <= piece.length; i++) {
                        modifiedItem.push(piece.substring(i * rowSize, i * rowSize + rowSize));
                      }
                      piece = modifiedItem.join(' ');
                    }
                    piecesOfItem.push(piece);
                  }
                  item = piecesOfItem.join(' ');
                  return item.trim();
                });
                data.cell.styles.fontSize = 10;
                data.cell.styles.font = 'OpenSans';
                data.cell.styles.cellPadding = {
                  top: 1.5,
                  bottom: 1.5,
                  right: 3.5,
                  left: 3.5,
                };
              },
            });
            this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance = 0;
          }
        }
      } else {
        if (
          canvasContent.forcedCanvasHeight > this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance
        ) {
          parentPositionPixelCounter = await this.splitCanvasAndAddToPage(
            doc,
            canvas,
            canvasContent.forcedCanvasWidth,
            canvasContent.forcedCanvasHeight,
            parentPositionPixelCounter,
            printableComponentsSelector,
          );
        } else {
          doc.addImage(
            canvasImgURL,
            this.fileFormat,
            positionX,
            (parentPositionPixelCounter ?? 0) +
              (this.pageConfigs[this.currentPageIndex].page?.padding?.top ?? this.pageDimensions.topOrBottomPadding),
            canvasContent.forcedCanvasWidth,
            canvasContent.forcedCanvasHeight,
            this.defaultImageAlias,
            this.imageCompression,
          );
          this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance =
            this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance -
            canvasContent.forcedCanvasHeight;
          parentPositionPixelCounter += canvasContent.forcedCanvasHeight;
        }
      }
    }
  }

  private async createNextPage(doc: jsPDF, orientationPreference: PageOrientation) {
    let pageConfig: IDocumentPageConfig = _.cloneDeep(this.defaultDocumentPageConfig);

    if (orientationPreference === PageOrientation.landscape) {
      pageConfig.page.orientation = PageOrientation.landscape;
    }
    await this.createPageConfig(pageConfig);
    await this.createPage(doc);
  }

  private async splitCanvasAndAddToPage(
    doc: jsPDF,
    canvas: HTMLCanvasElement,
    forcedCanvasWidth: number,
    forcedCanvasHeight: number,
    parentPositionPixelCounter: number,
    printableComponentsSelector: IPrintableComponent,
  ): Promise<number> {
    const positionX: number = this.pageConfigs[this.currentPageIndex].page?.padding?.left ?? 0;
    let positionMovingYPixel: number = parentPositionPixelCounter + this.gapBetweenComponentsInPixel;
    this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance =
      this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance - this.gapBetweenComponentsInPixel;

    if (printableComponentsSelector.smartCrop && printableComponentsSelector.childrenComponentCoordinates) {
      positionMovingYPixel = await this.smartHeightCropping(
        doc,
        canvas,
        printableComponentsSelector,
        positionX,
        forcedCanvasWidth,
        forcedCanvasHeight,
        positionMovingYPixel,
      );
    } else {
      positionMovingYPixel = await this.regularHeightCropping(
        doc,
        canvas,
        printableComponentsSelector.orientationPreference,
        forcedCanvasWidth,
        forcedCanvasHeight,
        positionX,
        positionMovingYPixel,
      );
    }

    this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance =
      this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance - this.gapBetweenComponentsInPixel;
    return positionMovingYPixel + this.gapBetweenComponentsInPixel;
  }

  private async smartHeightCropping(
    doc: jsPDF,
    canvas: HTMLCanvasElement,
    printableComponentsSelector: IPrintableComponent,
    positionX: number,
    forcedCanvasWidth: number,
    forcedCanvasHeight: number,
    positionMovingYPixel: number,
  ): Promise<number> {
    let defaultPanelHeaderHeight: number = 49.5;
    let positionMovingYPixelLocal: number = positionMovingYPixel;
    let imageCropPositionCounterInPixel: number = 0;
    let calculatedHeight: number = 0;
    let cumulativeCalculatedHeight: number = 0;
    let realComponentCount: number = 0;
    let htmlContentWidth: number =
      printableComponentsSelector.orientationPreference === PageOrientation.portrait
        ? this.pageContentWithForPortraitInNumber
        : this.pageContentWithForLandscapeInNumber;

    let index: number = 1;
    let previousBottomMargin: number = 0;
    let panelBottomMarginBalanceArray: any = {};
    let childrenComponentCoordinatesArray: IDocumentFormIoSelectors[] =
      printableComponentsSelector.childrenComponentCoordinates || [];
    for (const childComp of childrenComponentCoordinatesArray) {
      let onBufferHeight: number = 0;
      const parentElement: HTMLCollectionOf<Element> = document.getElementsByClassName(
        printableComponentsSelector.parentContainerClass,
      );
      let findElement: HTMLElement | null;

      if (parentElement) {
        if (parentElement.length > 0) {
          findElement = parentElement[0].querySelector(`${childComp.selector}`);

          if (findElement && findElement.offsetHeight && findElement.offsetWidth) {
            if (childComp.isPanel) {
              if (childComp.isPanelHeader) {
                let headerHeight: number = 0;
                try {
                  const headerElement: Element = findElement.children[0]?.children[0];

                  if (headerElement) {
                    let headerComp: CSSStyleDeclaration = window.getComputedStyle(headerElement);

                    if (headerComp) {
                      if (headerElement.classList.contains('card-header')) {
                        headerHeight += this.extractNumberFromCss(headerComp.height);
                      } else {
                        headerHeight += this.extractNumberFromCss(headerComp.paddingTop);
                      }
                    }
                  }

                  if (findElement.children[0]?.children.length > 1) {
                    let cardBody: CSSStyleDeclaration = window.getComputedStyle(findElement.children[0]?.children[1]);

                    if (cardBody) {
                      headerHeight += this.extractNumberFromCss(cardBody.paddingTop);
                    }
                  }
                  headerHeight += 1;
                } catch (err) {
                  headerHeight = defaultPanelHeaderHeight + this.formIoPanelPadding;
                }

                onBufferHeight = (forcedCanvasWidth * headerHeight) / htmlContentWidth;
              } else {
                let panelFooterHeight: number = 0;

                try {
                  if (findElement.children[0]?.children.length > 0) {
                    let cardBody;
                    let bodyElement: Element = findElement.children[0]?.children[0];

                    if (bodyElement.classList.contains('card-body')) {
                      cardBody = window.getComputedStyle(bodyElement);
                    }

                    if (findElement.children[0]?.children.length > 1) {
                      if (!bodyElement.classList.contains('card-body')) {
                        bodyElement = findElement.children[0]?.children[1];
                        cardBody = window.getComputedStyle(bodyElement);
                      }
                      if (!bodyElement.classList.contains('card-body')) {
                        throw 'panelBodyNotFound';
                      }
                    }
                    let nextElementIsPanelBottom: boolean = false;

                    if (index < childrenComponentCoordinatesArray.length) {
                      let nextElement: IDocumentFormIoSelectors = childrenComponentCoordinatesArray[index];
                      nextElementIsPanelBottom =
                        (nextElement.isPanel || false) && (!nextElement.isPanelHeader || false);
                    }

                    if (findElement.children.length > 1) {
                      if (findElement.children[0].classList.contains('border')) {
                        let cardBorderElement: Element = findElement.children[0];
                        let cardBorder: CSSStyleDeclaration = window.getComputedStyle(cardBorderElement);
                        const calculatedBottomMargin: number = this.extractNumberFromCss(cardBorder.marginBottom);

                        if (nextElementIsPanelBottom) {
                          panelFooterHeight += calculatedBottomMargin;
                        } else {
                          let parentIndex: string = this.getParentIndex(childComp.index);

                          if (panelBottomMarginBalanceArray[parentIndex]) {
                            panelBottomMarginBalanceArray[parentIndex] += calculatedBottomMargin;
                          } else {
                            panelBottomMarginBalanceArray[parentIndex] = calculatedBottomMargin;
                          }
                        }
                      }
                    }

                    if (panelBottomMarginBalanceArray[childComp.index]) {
                      panelFooterHeight += panelBottomMarginBalanceArray[childComp.index];
                      delete panelBottomMarginBalanceArray[childComp.index];
                    }

                    if (cardBody) {
                      panelFooterHeight += this.extractNumberFromCss(cardBody.paddingBottom);
                    }
                    panelFooterHeight += 1;
                  }
                } catch (err) {
                  panelFooterHeight = 1 + this.formIoPanelBottomMargin;
                }

                if (htmlContentWidth > 0) {
                  onBufferHeight = (forcedCanvasWidth * panelFooterHeight) / htmlContentWidth;
                }

                if (
                  onBufferHeight > forcedCanvasHeight - cumulativeCalculatedHeight &&
                  forcedCanvasHeight - cumulativeCalculatedHeight > 0
                ) {
                  onBufferHeight = forcedCanvasHeight - cumulativeCalculatedHeight;
                }
              }

              previousBottomMargin = 0;
            } else {
              let findElementStyle: CSSStyleDeclaration = window.getComputedStyle(findElement);

              let componentHeight: number = this.extractNumberFromCss(findElementStyle.height);
              let marginBottom: number = this.extractNumberFromCss(findElementStyle.marginBottom);
              let marginTop: number = this.extractNumberFromCss(findElementStyle.marginTop);

              componentHeight += marginTop + marginBottom;

              if (findElement.classList.contains('formio-component-content')) {
                try {
                  let figureBody: CSSStyleDeclaration = window.getComputedStyle(findElement.children[0]?.children[0]);
                  let marginBottom: number = this.extractNumberFromCss(figureBody.marginBottom);

                  if (figureBody && previousBottomMargin === 0) {
                    componentHeight += this.extractNumberFromCss(figureBody.marginTop) + marginBottom;
                  }
                } catch (err) {}
              } else if (findElement.classList.contains('formio-component-columns')) {
                try {
                  let figureBody: CSSStyleDeclaration = window.getComputedStyle(findElement.children[0]);
                  let marginBottom: number = this.extractNumberFromCss(figureBody.marginBottom);

                  if (figureBody && previousBottomMargin === 0) {
                    componentHeight += this.extractNumberFromCss(figureBody.marginTop) + marginBottom;
                  }
                } catch (err) {}
              }

              onBufferHeight = (forcedCanvasWidth * componentHeight) / htmlContentWidth;
              previousBottomMargin = marginBottom;
              realComponentCount++;
            }
          }
        }
      }
      if (
        calculatedHeight + onBufferHeight >=
        this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance
      ) {
        if (calculatedHeight > 0 && realComponentCount > 0) {
          imageCropPositionCounterInPixel = await this.dumpCanvasToPage(
            doc,
            canvas,
            calculatedHeight,
            forcedCanvasWidth,
            imageCropPositionCounterInPixel,
            positionX,
            positionMovingYPixelLocal,
          );

          this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance -= calculatedHeight;
          calculatedHeight = 0;
        }

        await this.createNextPage(doc, printableComponentsSelector.orientationPreference);
        positionMovingYPixelLocal = 0;
        calculatedHeight += onBufferHeight;
        cumulativeCalculatedHeight += calculatedHeight;
        realComponentCount = 0;
      } else {
        calculatedHeight += onBufferHeight;
        cumulativeCalculatedHeight += calculatedHeight;

        if (index === printableComponentsSelector.childrenComponentCoordinates?.length) {
          imageCropPositionCounterInPixel = await this.dumpCanvasToPage(
            doc,
            canvas,
            calculatedHeight,
            forcedCanvasWidth,
            imageCropPositionCounterInPixel,
            positionX,
            positionMovingYPixelLocal,
          );

          positionMovingYPixelLocal += calculatedHeight;
          this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance -= calculatedHeight;
        }
      }
      index++;
    }

    const remainingHeightBalance: number =
      forcedCanvasHeight - (forcedCanvasWidth * imageCropPositionCounterInPixel) / htmlContentWidth;

    if (remainingHeightBalance > 0) {
      calculatedHeight = remainingHeightBalance + 5;

      if (calculatedHeight >= this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance) {
        await this.createNextPage(doc, printableComponentsSelector.orientationPreference);
        positionMovingYPixelLocal = 0;
      }

      await this.dumpCanvasToPage(
        doc,
        canvas,
        calculatedHeight,
        forcedCanvasWidth,
        imageCropPositionCounterInPixel,
        positionX,
        positionMovingYPixelLocal,
      );
      positionMovingYPixelLocal += calculatedHeight;
      this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance -= calculatedHeight;
    }

    return positionMovingYPixelLocal;
  }

  private getParentIndex(childIndex: string): string {
    const childIndexArray: string[] = childIndex.split('-');
    childIndexArray.pop();
    return childIndexArray.join('-');
  }

  private async regularHeightCropping(
    doc: jsPDF,
    canvas: HTMLCanvasElement,
    orientationPreference: PageOrientation,
    forcedCanvasWidth: number,
    remainingCanvasHeight: number,
    positionX: number,
    positionMovingYPixel: number,
  ): Promise<number> {
    let positionMovingYPixelLocal: number = positionMovingYPixel;
    let imageCropPositionCounterInPixel: number = 0;

    while (remainingCanvasHeight > 0) {
      const splitCanvas: HTMLCanvasElement = document.createElement('canvas');
      splitCanvas.width = canvas.width;
      splitCanvas.height = canvas.height;
      const calculatedHeight: number =
        this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance < remainingCanvasHeight
          ? this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance
          : remainingCanvasHeight;

      imageCropPositionCounterInPixel = await this.dumpCanvasToPage(
        doc,
        canvas,
        calculatedHeight,
        forcedCanvasWidth,
        imageCropPositionCounterInPixel,
        positionX,
        positionMovingYPixelLocal,
        10,
      );

      this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance -= calculatedHeight;
      remainingCanvasHeight -= calculatedHeight;
      positionMovingYPixelLocal += calculatedHeight;

      if (this.pageConfigs[this.currentPageIndex].remainingPageContentHeightBalance <= 5) {
        await this.createNextPage(doc, orientationPreference);
        positionMovingYPixelLocal = 0;
      }
    }
    return positionMovingYPixelLocal;
  }

  private async dumpCanvasToPage(
    doc: jsPDF,
    canvas: HTMLCanvasElement,
    calculatedHeight: number,
    forcedCanvasWidth: number,
    imageCropPositionCounterInPixel: number,
    positionX: number,
    positionMovingYPixel: number,
    widthToleranceOverlapInPixel: number = 0,
  ): Promise<number> {
    const splitCanvas: HTMLCanvasElement = document.createElement('canvas');
    splitCanvas.width = canvas.width;
    const revertedCalculatedHeightInPixelLocal: number = (calculatedHeight * canvas.width) / forcedCanvasWidth;
    splitCanvas.height = revertedCalculatedHeightInPixelLocal;
    const croppedContext: CanvasRenderingContext2D | null = splitCanvas.getContext('2d');

    if (croppedContext) {
      croppedContext.fillStyle = 'white';
      croppedContext.fillRect(0, 0, canvas.width, canvas.height);

      let sy: number = imageCropPositionCounterInPixel;

      if (
        widthToleranceOverlapInPixel &&
        imageCropPositionCounterInPixel > 0 &&
        imageCropPositionCounterInPixel > widthToleranceOverlapInPixel
      ) {
        sy = imageCropPositionCounterInPixel - widthToleranceOverlapInPixel;
        splitCanvas.height = splitCanvas.height + widthToleranceOverlapInPixel;
      }
      croppedContext.drawImage(
        canvas,
        0, //source x
        sy, //source y
        splitCanvas.width, //source width
        splitCanvas.height, //source height
        0, //destination x
        0, //destination y
        splitCanvas.width, //destination width
        splitCanvas.height, //destination height
      );
    }
    const canvasImgURL: string = splitCanvas.toDataURL(this.dataUrlType, this.dataUrlQuality);

    doc.addImage(
      canvasImgURL,
      this.fileFormat,
      positionX,
      (positionMovingYPixel ?? 0) +
        (this.pageConfigs[this.currentPageIndex].page?.padding?.top ?? this.pageDimensions.topOrBottomPadding),
      forcedCanvasWidth,
      calculatedHeight,
      this.defaultImageAlias,
      this.imageCompression,
    );
    imageCropPositionCounterInPixel += revertedCalculatedHeightInPixelLocal;
    return imageCropPositionCounterInPixel;
  }

  private extractNumberFromCss(element: string): number {
    return parseFloat(element.replace('px', ''));
  }

  private async putHeaderFooterIntoPages(doc: jsPDF) {
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      let pageConfig: IDocumentPageConfig = _.cloneDeep(this.defaultDocumentPageConfig);

      if (doc.internal.pageSize.height > doc.internal.pageSize.width) {
        pageConfig.page.orientation = PageOrientation.portrait;
      } else {
        pageConfig.page.orientation = PageOrientation.landscape;
      }
      await this.createPageConfig(pageConfig);
      await this.prepareHeader(doc);
      await this.prepareFooter(doc);
    }
  }

  private async prepareHeader(doc: jsPDF): Promise<void> {
    doc.setFillColor('#ffffff');

    const imageWidth: number = 80;
    const imageHeight: number = 24;

    if (this.document.bannerConfiguration.header?.uri) {
      const rightXCoordinate: number = this.pageDimensions.leftOrRightPadding;
      const rightYCoordinate: number = this.pageDimensions.topOrBottomPadding - 10;

      doc.addImage(
        this.footerImage,
        this.fileFormat,
        rightXCoordinate,
        rightYCoordinate,
        imageWidth,
        imageHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      if (this.document.bannerConfiguration.footer?.doubleImage) {
        doc.addImage(
          this.defaultImage,
          this.fileFormat,
          rightXCoordinate + imageWidth + 5,
          rightYCoordinate,
          imageWidth,
          imageHeight,
          this.defaultImageAlias,
          this.imageCompression,
        );
      }

      doc.setFontSize(12);
      doc.text(
        this.document.bannerConfiguration.header?.title ?? '',
        this.pageDimensions.width - this.pageDimensions.leftOrRightPadding,
        this.pageDimensions.topOrBottomPadding - 5,
        { baseline: 'top', align: 'right' },
      );
    } else {
      doc.setTextColor('#4C5054');
      doc.setFontSize(
        DocumentGeneratorComponent.calculateHeaderFontSize(this.document.bannerConfiguration.header?.title),
      );
      doc.text(
        this.document.bannerConfiguration.header?.title ?? '',
        this.pageDimensions.leftOrRightPadding,
        this.pageDimensions.topOrBottomPadding - 5,
        { baseline: 'top', align: 'left' },
      );
      doc.setFontSize(7);
      doc.text(
        this.document.bannerConfiguration.header?.signature ?? '',
        this.pageDimensions.width - this.pageDimensions.leftOrRightPadding,
        this.pageDimensions.topOrBottomPadding,
        { baseline: 'top', align: 'right' },
      );
    }

    this.prepareDatetimeValueAsText(
      doc,
      this.pageDimensions.width - this.pageDimensions.leftOrRightPadding,
      this.pageDimensions.topOrBottomPadding + 8,
    );

    const borderXCoordinate: number = this.pageDimensions.leftOrRightPadding;
    const borderYCoordinate: number = this.pageDimensions.topOrBottomPadding + 15;
    const borderLength: number = this.pageDimensions.width - this.pageDimensions.leftOrRightPadding * 2;
    doc.rect(borderXCoordinate, borderYCoordinate, borderLength, 1, 'F');
  }

  private async prepareFooter(doc: jsPDF): Promise<void> {
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);

    const imageWidth: number = 80;
    const imageHeight: number = 24;
    const borderXCoordinate: number = this.pageDimensions.leftOrRightPadding;
    const borderYCoordinate: number = this.pageDimensions.height - (this.pageDimensions.topOrBottomPadding + 15);
    const borderWidth: number = this.pageDimensions.width - this.pageDimensions.leftOrRightPadding * 2;
    doc.rect(borderXCoordinate, borderYCoordinate, borderWidth, 1, 'F');

    if (!this.document.bannerConfiguration?.footer?.noPage) {
      const titleXCoordinate: number = this.pageDimensions.width - this.pageDimensions.leftOrRightPadding;
      const titleYCoordinate: number =
        this.pageDimensions.height - (this.pageDimensions.topOrBottomPadding + 5) + imageHeight / 2;

      doc.text(
        String(
          `${this.pageTranslation} ${doc.getCurrentPageInfo().pageNumber} ${
            this.pageOfTranslation
          } ${doc.getNumberOfPages()}`,
        ),
        titleXCoordinate,
        titleYCoordinate,
        {
          baseline: 'bottom',
          align: 'right',
        },
      );
    }

    if (!this.document.bannerConfiguration.footer?.noImage) {
      const rightXCoordinate: number = this.pageDimensions.leftOrRightPadding;
      const rightYCoordinate: number = this.pageDimensions.height - (this.pageDimensions.topOrBottomPadding + 5);

      doc.addImage(
        this.footerImage,
        this.fileFormat,
        rightXCoordinate,
        rightYCoordinate,
        imageWidth,
        imageHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      if (this.document.bannerConfiguration.footer?.doubleImage) {
        doc.addImage(
          this.defaultImage,
          this.fileFormat,
          rightXCoordinate + imageWidth + 5,
          rightYCoordinate,
          imageWidth,
          imageHeight,
          this.defaultImageAlias,
          this.imageCompression,
        );
      }
    }
  }

  private static calculateHeaderFontSize(headerText: string | undefined): number {
    const headerTextLength: number = Number(headerText?.length);

    if (headerTextLength > 45) {
      return 11;
    }

    if (headerTextLength > 40) {
      return 12;
    }

    if (headerTextLength > 35) {
      return 13;
    }

    if (headerTextLength > 30) {
      return 14;
    }

    return 15;
  }

  private async getBase64FromUrl(url: string): Promise<string | ArrayBuffer | null> {
    const blob: Blob = await lastValueFrom(
      this.httpClient.get(url, {
        responseType: 'blob',
      }),
    );

    return new Promise<string | ArrayBuffer | null>((resolve) => {
      const reader: FileReader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data: string | ArrayBuffer | null = reader.result;

        resolve(base64data);
      };
    });
  }

  private prepareDatetimeValueAsText(doc: jsPDF, titleXCoordinate: number, titleYCoordinate: number) {
    if (this.filterParameters) {
      const startDate: string = this.filterParameters.dateRange.startDate;
      const endDate: string = this.filterParameters.dateRange.endDate;

      const dateText: string = `${startDate} - ${endDate}`;

      doc.setFontSize(10);

      doc.text(dateText, titleXCoordinate, titleYCoordinate, {
        baseline: 'top',
        align: 'right',
      });

      doc.setFontSize(12);
    }
  }

  public ngOnDestroy(): void {
    if (this.initializePrint) {
      this.initializePrint.unsubscribe();
    }

    if (this.pageHeaderSubscription) {
      this.pageHeaderSubscription.unsubscribe();
    }
  }

  public async setHeaderImageSrc(): Promise<void> {
    if (this.document.bannerConfiguration.header?.uri) {
      this.headerImage.src = `${await this.getBase64FromUrl(this.document.bannerConfiguration.header?.uri)}`;
    }
  }

  public async setFooterImageSrc(): Promise<void> {
    if (this.document.bannerConfiguration.footer?.uri && !this.document.bannerConfiguration.footer.noImage) {
      this.footerImage.src = `${await this.getBase64FromUrl(this.document.bannerConfiguration.footer?.uri)}`;
    }
  }
}
