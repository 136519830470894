import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  public jwtRefreshToken!: string;

  constructor(public router: Router, public http: HttpClient, private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    const renewUrl = `${environment.ssoUrl}${environment.ssoSilentAuthUrl}`;
    return this.http.get<any>(renewUrl).pipe(
      map((response) => {
        if (response['success']) {
          return true;
        }
        return this.redirectToLogin();
      }),
    );
  }

  redirectToLogin(err?: any): boolean {
    if (
      err.error !== undefined &&
      err.error.errors.length > 0 &&
      _.find(err.error.errors, 'login') !== undefined &&
      err.error.errors[0].errors.login[0].code === 'IP_DENIED'
    ) {
      const redirectionUrl: string = `${environment.ssoUrl}${environment.ssoLogoutUrl}?state=${err.error.errors[0].errors.login[0].code}`;
      window.location.replace(redirectionUrl);
      return false;
    }
    const redirectionUrl: string = `${environment.ssoUrl + environment.ssoAuthUrl}?state=${encodeURIComponent(
      window.location.href,
    )}`;
    window.location.replace(redirectionUrl);
    return false;
  }
}
