<div class="page-body">
  <mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="true"></mat-progress-bar>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col-12">
              <scw-mat-dropdown [menu]="clientOptions" (onClickItem)="onClickDropdown($event)">
                <em class="fas fa-arrow-down"></em>
                {{ 'clientSelection.selectClient' | translate }}
              </scw-mat-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



