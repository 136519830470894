<div class="scw-mat-dropdown-menu-container" [class.block]="block" #dropdownMenuContainer>
  <scw-mat-small-button
    [disabled]="disabled"
    [block]="block"
    [className]="{ active: show }"
    (onClick)="onClickDropdownButton(!show)"
  >
    <ng-content></ng-content>
  </scw-mat-small-button>
  <div class="scw-mat-dropdown-menu" [class.show]="show">
    <ng-template ngFor let-item let-i="index" [ngForOf]="menu">
      <scw-mat-button
        [block]="true"
        [className]="{
          'scw-dropdown-item-button': true,
          'first-item': i === 0,
          'last-item': menu.length === 1 || menu.length === i + 1
        }"
        type="standard"
        (onClick)="onClickDropdownMenuItem(item)"
      >
        {{ item.text }}
      </scw-mat-button>
    </ng-template>
  </div>
</div>
