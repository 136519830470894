import { HttpClient } from '@angular/common/http';
import { ISavablePageFilters } from './savable-filter.type';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../../model/interface/generic-api-response.model';

@Injectable({ providedIn: 'root' })
export class SavableFilterService {
  constructor(private readonly httpClient: HttpClient, @Inject('API_BASE_URL') private readonly baseURL: string) {}
  public saveFilters(
    filter: ISavablePageFilters,
    isUpdate: boolean = false,
  ): Observable<IResponse<ISavablePageFilters>> {
    return isUpdate
      ? this.httpClient.put<IResponse<ISavablePageFilters>>(this.url, { payload: filter })
      : this.httpClient.post<IResponse<ISavablePageFilters>>(this.url, { payload: filter });
  }

  public getSavedFilters(location: string): Observable<IResponse<ISavablePageFilters>> {
    return this.httpClient.get<IResponse<ISavablePageFilters>>(this.url, { params: { location } });
  }

  private get url(): string {
    return `${this.baseURL}/filter-configurations/`;
  }
}
