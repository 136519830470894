import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import {
  AddButtonCustomizerModel,
  IDatatableHeader,
  IDatatableOutputParameter,
  IDatatableOutputSort,
  IDatatableSort,
  ICustomSort,
  IDatatableItem,
  IDatatableOnClickRow,
  SortOrderType,
} from './datatable.model';
import { ComponentUtilities } from '../../helper/component-utilities';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnChanges, AfterViewChecked {
  @Input() headers: IDatatableHeader[] = [];
  @Input() items: IDatatableItem[] = [];
  @Input() rowsPerPage: number = 10;
  @Input() rowsPerPageItems = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];
  @Input() serverSide: boolean = false;
  @Input() clientSide: boolean = false;
  @Input() customSorts: ICustomSort = {};
  @Input() itemsCount: number = 0;
  @Input() currentPage: number = 1;
  @Input() loading: boolean = false;
  @Input() search: boolean = false;
  @Input() searchDelay: number = 600;
  @Input() searchPlaceholder: string = this.translate.instant('general.search');
  @Input() addButton: boolean = false;
  @Input() addButtonCustomizer: AddButtonCustomizerModel = {
    buttonClass: 'btn btn-primary btn-sm icon-btn ripple light',
    disabled: false,
    iconClass: 'fas fa-plus',
    text: '',
  };
  @Input() theme: 'default' | 'gray' = 'default'; // 'theme' parameter has been deprecated since SCW UI Kit.
  @Input() noDataText!: string;
  @Input() paginationSize: 'sm' | 'lg' = 'lg'; // 'paginationSize' parameter has been deprecated since SCW UI Kit.
  @Output() onClickRow = new EventEmitter<IDatatableOnClickRow>();
  @Output() onDataRequest = new EventEmitter<IDatatableOutputParameter>();
  @Output() clientSideRequest = new EventEmitter<IDatatableOutputParameter>();
  @Output() onAddButtonClick = new EventEmitter<object>();
  @Output() onHeaderCheckboxClick = new EventEmitter<{ event: any; header: any }>();
  @ContentChild(TemplateRef, { static: false }) templateRef!: TemplateRef<any>;
  @Input() shouldPaginate: boolean = true;
  @Input() tableStyle!: CSSStyleDeclaration;
  @Input() tableClass: string = '';
  @Input() topRightContent!: TemplateRef<any>;
  @Input() topLeftContent!: TemplateRef<any>;
  @Input() searchValue: string = '';
  @Input() headerContent!: TemplateRef<any>;
  @Input() footerContent!: TemplateRef<any>;
  @Input() justDataTable: boolean = false;
  @Input() stickyLeadColumn: boolean = false;
  @Input() stickyEndColumn: number = 0;
  @Input() nestedTable: boolean = false;
  @Input() trackByProperty?: string | number;
  @Input() overflowYHidden: boolean = false;
  @Input() disableCheckbox: boolean = false;

  /**
   * For to be mainly used with clientSide mode
   */
  @Output() onClickPagination = new EventEmitter<IDatatableOutputParameter>();
  @Output() onClickSort = new EventEmitter<void>();
  @Output() onStickyEndColumnRightValuesUpdated = new EventEmitter<string[]>();

  public pending: boolean = false;
  public paginationCustomizer: string = 'scw-theme-pagination';
  public tableCustomizer: string = 'scw-theme-table ';
  protected delayTimer!: ReturnType<typeof setTimeout>;
  protected readonly sortOrder: IDatatableSort[] = [
    {
      type: 'none',
      sortIconClass: 'none-type',
    },
    {
      type: 'ascending',
      sortIconClass: 'asc',
    },
    {
      type: 'descending',
      sortIconClass: 'desc',
    },
  ];
  protected readonly sortMap = {
    none: 'ascending',
    ascending: 'descending',
    descending: 'none',
  };
  public sortedHeader!: IDatatableHeader;
  public currentSort: SortOrderType = 'none';
  public sortedItems!: IDatatableItem[];
  public stickyEndColumnRightValues: string[] = [];
  public entryRangeLowerBound: number = 1;
  public entryRangeUpperBound: number = 1;
  public totalNumberOfItems: number = 1;

  protected EMIT_PAGE!: number;
  protected EMIT_ROWS_PER_PAGE!: number;
  protected EMIT_SORT!: IDatatableOutputSort;
  protected EMIT_SEARCH!: string;

  private readonly MAX_COLUMN_CHECK_COUNT: number = 30;
  private isColumnInfoReceived: boolean = false;
  private tableRows!: NodeListOf<Element>;
  private oldTableRows!: NodeListOf<Element>;
  private stickyHeaders!: NodeListOf<Element>;
  private stickyHeaderLength!: number;
  private stickyColumnsArranged: boolean = false;
  private tableRowsChanged: boolean = true;
  private additionalColumnCheckRequired: boolean = false;
  private columnCheckCount: number = 0;

  constructor(private readonly translate: TranslateService, private readonly changeDetector: ChangeDetectorRef) {
    if (this.serverSide) {
      this.pending = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.pending = false;
    this.isColumnInfoReceived = false;
    this.tableRowsChanged = !_.isEqual(this.oldTableRows, this.tableRows);

    this.headers.forEach((_item: IDatatableHeader, index: number) => {
      if (typeof this.headers[index].sort === 'undefined') {
        this.headers[index].sort = this.sortOrder[0];
      }

      this.headers[index].pointerEvents = 'all';
    });

    if (this.clientSide && this.sortedHeader && this.currentSort !== 'none' && changes.hasOwnProperty('items')) {
      const sortType: 'desc' | 'asc' = this.currentSort === 'descending' ? 'desc' : 'asc';

      if (DatatableComponent.isHeaderCustomSortEnabled(this.customSorts, this.sortedHeader)) {
        this.customSort(this.sortedHeader, sortType);
      } else {
        this.standardSort(this.sortedHeader.value, sortType);
      }
    }

    if (changes['items']?.previousValue !== changes['items']?.currentValue) {
      this.calculateEntryRangeBounds();
    }

    if (
      this.stickyEndColumn > 1 &&
      (this.isColumnInfoReceived || this.tableRowsChanged) &&
      !this.stickyColumnsArranged
    ) {
      this.arrangeStickyCells();
    }
  }

  private arrangeStickyCells(): void {
    this.stickyHeaders?.forEach((node: Element, index: number) => {
      const nextTargetExists: boolean = index + 1 !== this.stickyHeaderLength;
      const columnIndex: number = _.get(node, 'cellIndex', 0);

      if (nextTargetExists) {
        const nextTargetWidth: number = this.stickyHeaders[index + 1].getBoundingClientRect().width;
        this.isColumnInfoReceived = Boolean(nextTargetWidth);
        this.stickyEndColumnRightValues[columnIndex] = `${nextTargetWidth}px`;

        return;
      }

      this.stickyEndColumnRightValues[columnIndex] = '0';
    });

    this.columnCheckCount += 1;

    if (this.isColumnInfoReceived) {
      this.columnCheckCount = 0;
      this.stickyColumnsArranged = true;
      this.oldTableRows = this.tableRows;
      this.onStickyEndColumnRightValuesUpdated.emit(this.stickyEndColumnRightValues);
    }
  }

  public ngAfterViewChecked(): void {
    if (
      this.stickyEndColumn > 1 &&
      (!this.isColumnInfoReceived || this.additionalColumnCheckRequired) &&
      this.columnCheckCount <= this.MAX_COLUMN_CHECK_COUNT
    ) {
      this.stickyEndColumnRightValues = [];
      this.tableRows = document.querySelectorAll('.datatable-row');

      if (!this.tableRows.length && this.itemsCount) {
        return;
      }

      this.stickyHeaders = document.querySelectorAll('.datatable-head .sticky-end-header');
      this.stickyHeaderLength = this.stickyHeaders.length;

      if (!this.stickyHeaderLength) {
        this.isColumnInfoReceived = false;

        return;
      }

      this.stickyColumnsArranged = false;
      this.isColumnInfoReceived = true;
      this.additionalColumnCheckRequired = Boolean(!this.itemsCount && this.tableRows.length);

      this.ngOnChanges({});
      this.changeDetector.detectChanges();
    }
  }

  public recheckColumnSizes(): void {
    this.columnCheckCount = 0;
    this.isColumnInfoReceived = false;
  }

  public onChangeDataSearch(tableFilter: NgForm): void {
    if (!tableFilter.form.valid) {
      return;
    }

    this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
    this.EMIT_PAGE = this.currentPage;

    if (this.serverSide || this.clientSide) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.pending = true;
        this.EMIT_SEARCH = this.searchValue;

        this.onDataRequest.emit({
          page: this.EMIT_PAGE,
          rowsPerPage: this.EMIT_ROWS_PER_PAGE,
          search: this.EMIT_SEARCH,
          sort: this.EMIT_SORT,
        });
      }, this.searchDelay);
    }
  }

  public onChangeDataSort(header: IDatatableHeader, index: number): void {
    const requestedSort: IDatatableSort | undefined = this.sortOrder.find(
      (sort: IDatatableSort) => sort.type === this.sortMap[header?.sort?.type ?? 'none'],
    );

    this.currentSort = requestedSort?.type || 'none';
    this.sortedHeader = header;

    this.headers.forEach((_headerItems: IDatatableHeader, headerIndex: number) => {
      this.headers[headerIndex].pointerEvents = 'none';

      if (headerIndex !== index) {
        this.headers[headerIndex].sort = this.sortOrder[0];
      }
    });

    this.headers[index].sort = requestedSort;

    if (this.serverSide && !this.clientSide) {
      this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
      this.EMIT_SEARCH = this.searchValue;
      this.EMIT_PAGE = this.currentPage;
      this.EMIT_SORT = { column: header.value, type: this.currentSort };
      this.pending = true;
      this.onDataRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    } else if (this.clientSide && this.currentSort !== 'none') {
      const sortType: 'desc' | 'asc' = this.currentSort === 'descending' ? 'desc' : 'asc';

      if (DatatableComponent.isHeaderCustomSortEnabled(this.customSorts, header)) {
        this.customSort(header, sortType);
      } else {
        this.standardSort(header.value, sortType);
      }
    }

    if (this.currentSort === 'none') {
      this.sortedItems = [];
    }

    this.onClickSort.emit();
  }

  private standardSort(header: string, sortType: 'desc' | 'asc'): void {
    this.sortedItems = _.orderBy(
      this.items,
      [
        (item: IDatatableItem) => {
          const property: string | null | undefined = _.get(item, header, '');

          if (_.isNil(property)) {
            return '';
          }

          return String(property).toLowerCase();
        },
      ],
      [sortType],
    );
  }

  private static isHeaderCustomSortEnabled(customSorts: ICustomSort, header: IDatatableHeader): boolean {
    return Boolean(_.get(customSorts, header.value, undefined) || _.get(customSorts, `${header.value}.type`));
  }

  private customSort(header: IDatatableHeader, sortType: 'desc' | 'asc'): void {
    const headerToBeSortedWith: string = this.customSorts[header.value]['sortColumnId'] || '';

    switch (this.customSorts[header.value].type) {
      case 'number':
        this.sortedItems = _.orderBy(
          this.items,
          [(item: IDatatableItem): number => Number(_.get(item, headerToBeSortedWith ?? header.value, 0))],
          [sortType],
        );
        break;

      case 'date':
        this.sortedItems = _.orderBy(
          this.items,
          [
            (item: IDatatableItem): Date =>
              moment(
                _.get(item, headerToBeSortedWith ?? header.value, moment()),
                _.get(this.customSorts, `${header.value}.dateFormat`, undefined) as string | undefined,
              ).toDate(),
          ],
          [sortType],
        );
        break;

      case 'duration':
        this.sortedItems = _.orderBy(
          this.items,
          [
            (item: IDatatableItem): number => {
              const hourMinuteArray: string[] = _.get(item, headerToBeSortedWith ?? header.value, '00:00').split(':');

              return Number(hourMinuteArray[0]) * 60 + Number(hourMinuteArray[1]);
            },
          ],
          [sortType],
        );
        break;

      case 'string':
      default:
        this.standardSort(headerToBeSortedWith ?? header.value, sortType);
        break;
    }
  }

  public onChangePaginationTrigger(page: number): void {
    this.EMIT_PAGE = page;
    this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
    this.EMIT_SEARCH = this.searchValue;

    if (this.serverSide || !this.clientSide) {
      this.pending = true;
      this.onDataRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    }

    this.onClickPagination.emit({
      page: this.EMIT_PAGE,
      rowsPerPage: this.EMIT_ROWS_PER_PAGE,
      search: this.EMIT_SEARCH,
      sort: this.EMIT_SORT,
    });

    if (this.clientSide) {
      this.clientSideRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    }

    this.calculateEntryRangeBounds();
  }

  public onClickRowTrigger(data: IDatatableOnClickRow): void {
    this.onClickRow.emit(data);
  }

  public onAddButtonClickEvent(): void {
    this.onAddButtonClick.emit();
  }

  public onHeaderCheckboxClickEvent(event: boolean, header: string): void {
    this.onHeaderCheckboxClick.emit({ event, header });
  }

  public datatableItemTrackBy: TrackByFunction<any> = (_index: number, item: any) => {
    return this.trackByProperty ? item[this.trackByProperty] : item;
  };

  public datatableHeaderTrackBy: TrackByFunction<IDatatableHeader> = ComponentUtilities.datatableHeaderTrackBy;

  private calculateEntryRangeBounds(): void {
    this.totalNumberOfItems = this.serverSide ? this.itemsCount : this.items.length;
    this.entryRangeLowerBound = this.rowsPerPage * (this.currentPage - 1) + 1;
    this.entryRangeUpperBound = Math.min(this.totalNumberOfItems, this.rowsPerPage * this.currentPage);
  }
}
