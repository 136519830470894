import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatBorderColoringComponent } from './scw-mat-border-coloring.component';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, ScwMatSmallButtonModule, NgbModule],
  exports: [ScwMatBorderColoringComponent],
  declarations: [ScwMatBorderColoringComponent],
})
export class ScwMatBorderColoringModule {}
