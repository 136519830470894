<div>
  <ng-content *ngTemplateOutlet="topLeftContent"></ng-content>
  <div class="float-right">
    <ng-content *ngTemplateOutlet="topRightContent"></ng-content>
  </div>
  <div *ngIf="search" class="float-right">
    <form #tableFilter="ngForm" name="tableFilter" action="return false;">
      <div class="datatable-items" [ngClass]="paginationCustomizer">
        <div class="input-group no-margin">
          <div class="search_box-container">
            <input
              type="text"
              name="search"
              class="form-control search-input"
              maxlength="1000"
              [class.is-invalid]="search.invalid"
              [placeholder]="searchPlaceholder"
              [(ngModel)]="searchValue"
              (keyup)="onChangeDataSearch(tableFilter)"
              #search="ngModel"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <em class="fas fa-search"></em>
              </span>
            </div>
          </div>
          <button
            class="add-button"
            *ngIf="addButton"
            [disabled]="!!addButtonCustomizer.disabled"
            [ngClass]="addButtonCustomizer.buttonClass || ''"
            (click)="onAddButtonClickEvent()"
          >
            <em *ngIf="addButtonCustomizer.iconClass" [ngClass]="addButtonCustomizer.iconClass"></em>
            {{ addButtonCustomizer.text }}
          </button>
          <div class="invalid-feedback" *ngIf="search.invalid && (search.dirty || search.touched)">
            <div class="messages text-danger" *ngIf="search.errors && search.errors['maxlength']">
              {{ 'general.inputErrorMessages.maxLength' | translate: { value: 1000 } }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="table-responsive" [class.overflow-y-hidden]="overflowYHidden">
  <table
    class="table table-striped table-bordered full-data-table tiny-table-td"
    [ngClass]="tableCustomizer + tableClass"
    [ngStyle]="tableStyle"
    [class.sticky-end-column]="stickyEndColumn"
  >
    <ng-content *ngTemplateOutlet="headerContent"></ng-content>
    <thead class="datatable-head">
      <tr>
        <th
          id="{{ 'header-' + i }}"
          scope="col"
          class="header-cell"
          *ngFor="let header of headers; index as i; trackBy: datatableHeaderTrackBy"
          [style.width]="header.width ? header.width : 'auto'"
          [style.pointer-events]="
            header.tooltip || header.checkboxLocation || header.sortable !== false ? 'all' : 'none'
          "
          (click)="header.sortable !== false ? onChangeDataSort(header, i) : null"
          [ngClass]="header.class || ''"
          [class.sticky-left-col-head]="stickyLeadColumn && i === 0"
          [class.first-not-sticky]="!stickyLeadColumn && i === 0"
          [class.sticky-end-header]="i > headers.length - 1 - stickyEndColumn && stickyEndColumn"
          [style.right]="stickyEndColumn && stickyEndColumnRightValues[i] ? stickyEndColumnRightValues[i] : ''"
        >
          <div
            [ngClass]="
              header.checkboxLocation === 'bottom'
                ? 'd-flex flex-column justify-content-center align-items-center'
                : header.checkboxLocation === 'right'
                ? 'd-flex justify-content-center'
                : ''
            "
          >
            <p class="mb-0" *ngIf="header.subTitle">{{ header.subTitle }}</p>
            <span *ngIf="header.icon">
              <em [class]="header.icon"></em>
            </span>
            {{ header.name }}
            <em
              *ngIf="header.tooltip"
              [ngClass]="header.tooltip?.icon || ''"
              [ngbTooltip]="header.tooltip?.text || ''"
              [tooltipClass]="header?.tooltip?.tooltipClass || ''"
              [autoClose]="false"
              container="body"
            ></em>
            <span class="sort-icon-container" *ngIf="header.sortable !== false">
              <em class="fas fa-long-arrow-alt-up" [class.asc]="header?.sort?.sortIconClass === 'asc'"></em>
              <em class="fas fa-long-arrow-alt-down" [class.desc]="header?.sort?.sortIconClass === 'desc'"></em>
            </span>
            <ng-template [ngIf]="header.checkboxLocation">
              <div
                class="d-flex align-items-center justify-content-center header-checkbox-container"
                [class.m-b-5]="header.checkboxLocation === 'bottom'"
                [class.m-l-5]="header.checkboxLocation === 'right'"
              >
                <scw-mat-checkbox
                  [disabled]="disableCheckbox"
                  [value]="header.value"
                  [(inputModel)]="!!header.checkbox"
                  (inputModelChange)="onHeaderCheckboxClickEvent($event, header.value)"
                ></scw-mat-checkbox>
              </div>
            </ng-template>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="datatable-body">
      <tr *ngIf="noDataText && items.length <= 0">
        <td [attr.colspan]="headers.length">
          {{ noDataText }}
        </td>
      </tr>
      <ng-template [ngIf]="clientSide && !justDataTable">
        <tr
          *ngFor="
            let item of (currentSort === 'none' ? items : sortedItems)
              | slice: (currentPage - 1) * rowsPerPage:currentPage * rowsPerPage;
            index as i;
            trackBy: datatableItemTrackBy
          "
          class="datatable-row"
          (click)="onClickRowTrigger({ item: item, event: $event })"
          [class.sticky-left-cell]="stickyLeadColumn"
        >
          <ng-container
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            [class.sticky-end-cell]="i > headers.length - 1 - stickyEndColumn && stickyEndColumn"
            [style.right]="stickyEndColumn && stickyEndColumnRightValues[i] ? stickyEndColumnRightValues[i] : ''"
          ></ng-container>
        </tr>
      </ng-template>
      <ng-template [ngIf]="serverSide || justDataTable">
        <tr
          *ngFor="
            let item of clientSide && currentSort !== 'none' ? sortedItems : items;
            index as i;
            trackBy: datatableItemTrackBy
          "
          class="datatable-row"
          (click)="onClickRowTrigger({ item: item, event: $event })"
          [class.sticky-left-cell]="stickyLeadColumn"
        >
          <ng-container
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            [class.sticky-end-cell]="i > headers.length - 1 - stickyEndColumn && stickyEndColumn"
            [style.right]="stickyEndColumn && stickyEndColumnRightValues[i] ? stickyEndColumnRightValues[i] : ''"
          ></ng-container>
        </tr>
      </ng-template>
      <ng-template [ngIf]="nestedTable">
        <ng-container
          *ngFor="let item of items; index as i; trackBy: datatableItemTrackBy"
          (click)="onClickRowTrigger({ item: item, event: $event })"
        >
          <ng-container
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: item, index: i }"
          ></ng-container>
        </ng-container>
      </ng-template>
    </tbody>
    <ng-content *ngTemplateOutlet="footerContent"></ng-content>
  </table>
</div>
<div class="row align-items-center">
  <div class="col-4 col-xl-5 m-auto">
    <div [hidden]="!shouldPaginate">
      <label class="label-control rows-per-page-select-text">
        <span class="mr-2" [hidden]="items?.length" [innerHTML]="'datatable.show' | translate"></span>
        <span
          class="mr-2"
          [hidden]="!items?.length"
          [innerHTML]="'datatable.paginationPrior' | translate: { total: totalNumberOfItems }"
        ></span>
        <select
          class="rows-per-page-select"
          [disabled]="pending"
          [(ngModel)]="rowsPerPage"
          (change)="onChangePaginationTrigger(1)"
        >
          <option *ngFor="let item of rowsPerPageItems">
            {{ item.value }}
          </option>
        </select>
        <span class="ml-2" [hidden]="items?.length">
          {{ 'general.datatable.entries' | translate }}
        </span>
        <br class="d-xl-none" />
        <span
          class="d-inline-block mt-1 mt-xl-0 ml-0 ml-xl-2"
          *ngIf="items?.length"
          [innerHTML]="
            'datatable.paginationPosterior'
              | translate
                : {
                    upperBound: entryRangeUpperBound,
                    lowerBound: entryRangeLowerBound,
                    total: totalNumberOfItems
                  }
          "
        ></span>
      </label>
    </div>
  </div>
  <div class="col-8 col-xl-7">
    <div class="pg-right">
      <ngb-pagination
        [collectionSize]="serverSide ? itemsCount : items.length"
        [pageSize]="rowsPerPage"
        [maxSize]="5"
        [disabled]="pending"
        [boundaryLinks]="true"
        [rotate]="true"
        [(page)]="currentPage"
        (pageChange)="onChangePaginationTrigger($event)"
        [hidden]="!shouldPaginate"
        appPaginationCustomizer
        [additionalClassName]="paginationCustomizer"
      ></ngb-pagination>
    </div>
  </div>
</div>
