import {
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { ILogbookVersion, ILogbookTasksData, IFieldSetData, IFormVersionLite } from './logbook-tasks.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ILogbookTasksHistory } from '../../../view/my-tasks/logbook-tasks/logbook-tasks.component.model';
import { ITableHeader } from '../../../../constants.model';
import { IReview, IReviewResults } from '../my-tasks.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';

export const START_LOGBOOK_TASKS_LOADING = '[Logbook Tasks] Start Logbook Tasks Loading';
export const LOGBOOK_TASKS_LOADED = '[Logbook Tasks] Logbook Tasks Loaded';
export const START_LOGBOOK_TASKS_BEFORE_RECORD_LOADING = '[Logbook Tasks] Start Logbook Tasks Before Record Loading';
export const LOGBOOK_TASKS_BEFORE_RECORD_LOADED = '[Logbook Tasks] Logbook Tasks Before Record Loaded';
export const START_LOGBOOK_TASKS_AFTER_RECORD_LOADING = '[Logbook Tasks] Start Logbook Tasks After Record Loading';
export const LOGBOOK_TASKS_AFTER_RECORD_LOADED = '[Logbook Tasks] Logbook Tasks After Record Loaded';
export const START_LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADING = '[Logbook Tasks] Start Logbook Tasks Before FieldSet Loading';
export const LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADED = '[Logbook Tasks] Logbook Tasks Before FieldSet Loaded';
export const START_LOGBOOK_TASKS_AFTER_FIELD_SET_LOADING = '[Logbook Tasks] Start Logbook Tasks After FieldSet Loading';
export const LOGBOOK_TASKS_AFTER_FIELD_SET_LOADED = '[Logbook Tasks] Logbook Tasks After FieldSet Loaded';
export const START_LOGBOOK_TASKS_HISTORY_LOADING = '[Logbook Tasks] Start Logbook Tasks History Loading';
export const LOGBOOK_TASKS_HISTORY_LOADED = '[Logbook Tasks] Logbook Tasks History Loaded';
export const START_LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADING =
  '[Logbook Tasks] Start Logbook Tasks Active Version History Loading';
export const LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADED = '[Logbook Tasks] Logbook Tasks Active Version History Loaded';
export const START_LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADING =
  '[Logbook Tasks] Start Logbook Tasks Current Version History Loading';
export const LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADED = '[Logbook Tasks] Logbook Tasks Current Version History Loaded';
export const START_LOGBOOK_TASKS_SELECTED_FORMS_LOADING = '[Logbook Tasks] Start Logbook Tasks Selected Forms Loading';
export const LOGBOOK_TASKS_SELECTED_FORMS_LOADED = '[Logbook Tasks] Logbook Tasks Selected Forms Loaded';
export const LOGBOOK_TASKS_SELECTED_FORMS_CLEAR = '[Logbook Tasks] Logbook Tasks Selected Forms clear';
export const START_ENTRY_APPROVE_LOADING = '[Logbook Tasks] Entry Approve Loading';
export const ENTRY_APPROVE_LOADED = '[Logbook Tasks] Entry Approve Loaded';
export const START_ENTRY_REJECT_LOADING = '[Logbook Tasks] Entry Reject Loading';
export const ENTRY_REJECT_LOADED = '[Logbook Tasks] Entry Reject Loaded';
export const START_ENTRY_REJECT_PERMANENT_LOADING = '[Logbook Tasks] Entry Reject Permanent Loading';
export const ENTRY_REJECT_PERMANENT_LOADED = '[Logbook Tasks] Entry Reject Permanent Loaded';
export const START_WORKFLOW_DATA_LOADING = '[Logbook Tasks] Start Workflow Data Loading';
export const WORKFLOW_DATA_LOADED = '[Logbook Tasks] Workflow Data Loaded';
export const SET_TABLE_SETTINGS = '[Logbook Tasks] Logbook Tasks Set Table Settings';
export const CLEAR_STATE = '[Logbook Tasks] Clear State';
export const FETCH_ERROR = '[Logbook Tasks] Fetch Error';

export class StartLogbookTasksLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_LOADING;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery, public reviewType: string) {}
}

export class LogbookTasksLoaded implements Action {
  readonly type = LOGBOOK_TASKS_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookTasksData>) {}
}

export class StartLogbookTasksBeforeRecordLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_BEFORE_RECORD_LOADING;

  constructor(public logbookId: number) {}
}

export class LogbookTasksBeforeRecordLoaded implements Action {
  readonly type = LOGBOOK_TASKS_BEFORE_RECORD_LOADED;

  constructor(public payload: ILogbookVersion) {}
}

export class StartLogbookTasksAfterRecordLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_AFTER_RECORD_LOADING;

  constructor(public logbookId: number, public id: number, public reviewType: string) {}
}

export class LogbookTasksAfterRecordLoaded implements Action {
  readonly type = LOGBOOK_TASKS_AFTER_RECORD_LOADED;

  constructor(public payload: ILogbookVersion) {}
}

export class StartLogbookTasksBeforeFieldSetLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class LogbookTasksBeforeFieldSetLoaded implements Action {
  readonly type = LOGBOOK_TASKS_BEFORE_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartLogbookTasksAfterFieldSetLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_AFTER_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class LogbookTasksAfterFieldSetLoaded implements Action {
  readonly type = LOGBOOK_TASKS_AFTER_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartLogbookTasksHistoryLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class LogbookTasksHistoryLoaded implements Action {
  readonly type = LOGBOOK_TASKS_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookTasksHistory>) {}
}

export class StartLogbookTasksActiveVersionHistoryLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class LogbookTasksActiveVersionHistoryLoaded implements Action {
  readonly type = LOGBOOK_TASKS_ACTIVE_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookTasksHistory>) {}
}

export class StartLogbookTasksCurrentVersionHistoryLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class LogbookTasksCurrentVersionHistoryLoaded implements Action {
  readonly type = LOGBOOK_TASKS_CURRENT_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<ILogbookTasksHistory>) {}
}

export class StartLogbookTasksSelectedFormsLoading implements Action {
  readonly type = START_LOGBOOK_TASKS_SELECTED_FORMS_LOADING;

  constructor(public entry_ids: number[], public getOnlyActiveForms: boolean = true) {}
}

export class LogbookTasksSelectedFormsLoaded implements Action {
  readonly type = LOGBOOK_TASKS_SELECTED_FORMS_LOADED;

  constructor(public payload: IBulkResponseRecord<IFormVersionLite>) {}
}

export class LogbookTasksSelectedFormsClear implements Action {
  readonly type = LOGBOOK_TASKS_SELECTED_FORMS_CLEAR;
  constructor() {}
}

export class StartEntryApproveLoading implements Action {
  readonly type = START_ENTRY_APPROVE_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryApproveLoaded implements Action {
  readonly type = ENTRY_APPROVE_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectLoading implements Action {
  readonly type = START_ENTRY_REJECT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryRejectLoaded implements Action {
  readonly type = ENTRY_REJECT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectPermanentLoading implements Action {
  readonly type = START_ENTRY_REJECT_PERMANENT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryRejectPermanentLoaded implements Action {
  readonly type = ENTRY_REJECT_PERMANENT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;

  constructor(public payload: IGenericObject<any>) {}
}

export class StartWorkflowDataLoading implements Action {
  readonly type = START_WORKFLOW_DATA_LOADING;
}

export class WorkflowDataLoaded implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export type LogbookTasksActions =
  | StartLogbookTasksLoading
  | LogbookTasksLoaded
  | StartLogbookTasksBeforeRecordLoading
  | LogbookTasksBeforeRecordLoaded
  | StartLogbookTasksAfterRecordLoading
  | LogbookTasksAfterRecordLoaded
  | StartLogbookTasksBeforeFieldSetLoading
  | LogbookTasksBeforeFieldSetLoaded
  | StartLogbookTasksAfterFieldSetLoading
  | LogbookTasksAfterFieldSetLoaded
  | StartLogbookTasksHistoryLoading
  | LogbookTasksHistoryLoaded
  | StartLogbookTasksActiveVersionHistoryLoading
  | LogbookTasksActiveVersionHistoryLoaded
  | StartLogbookTasksCurrentVersionHistoryLoading
  | LogbookTasksCurrentVersionHistoryLoaded
  | StartLogbookTasksSelectedFormsLoading
  | LogbookTasksSelectedFormsLoaded
  | LogbookTasksSelectedFormsClear
  | StartEntryApproveLoading
  | EntryApproveLoaded
  | StartEntryRejectLoading
  | EntryRejectLoaded
  | StartEntryRejectPermanentLoading
  | EntryRejectPermanentLoaded
  | SetTableSettings
  | FetchError
  | ClearState
  | StartWorkflowDataLoading
  | WorkflowDataLoaded;
