export enum BorderColors {
  attention = 'attention',
  available = 'available',
  ongoing = 'ongoing',
  active = 'active',
  inactive = 'inactive',
  busy = 'busy',
  awaitingApproval = 'awaiting-approval',
  sendBack = 'sendBack',
  submitted = 'submitted',
  notSubmitted = 'draft',
  rejected = 'rejected',
  obsolete = 'obsolete',
  approved = 'approved',
  completed = 'completed',
  canceled = 'canceled',
  flaggedToBeObsolete = 'flagged-to-be-obsolete',
}

export type TColorInput =
  | 'attention'
  | 'available'
  | 'ongoing'
  | 'active'
  | 'inactive'
  | 'busy'
  | 'submitted'
  | 'awaitingApproval'
  | 'notSubmitted'
  | 'rejected'
  | 'sendBack'
  | 'obsolete'
  | 'approved'
  | 'completed'
  | 'canceled'
  | 'flaggedToBeObsolete';
