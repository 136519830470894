import { ElementRef, Inject, Injectable, isDevMode, Type } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../store/logbook.reducer';
import { WINDOW } from '../../window.providers';
import * as moment from 'moment';

moment.tz.setDefault('utc');

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as AppActions from '../../store/app/actions';
import { NgForm } from '@angular/forms';
import { MomentDateFormat, UserDateFormat } from '../helper/date.constants';
import { IBulkResponseData } from '../model/interface/crud-response-interface.model';
import { ErrorMessageService } from './error-message.service';
import { CustomDateFormat, IConfig, ILogbookHomeConfiguration, User } from '../../store/user/model';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { GenericCrudRequestConstructionParameters } from '../model/interface/generic-api-request.model';
import { DropdownComponent } from '../component/filter/dropdown/dropdown.component';
import { FilterableObjectTypes } from '../component/filter/filterable-objects.class';
import { IRowConfiguration } from '../component/filter/filter.class';
import { AdvancedFilterComponent } from '../component/filter/advanced-filter/advanced-filter.component';
import { AdvancedFilterClass, TargetEndpoints } from '../component/filter/advanced-filter/advanced-filter.model';
import { BehaviorSubject, take } from 'rxjs';
import { IAdvancedFilterStore } from '../../store/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../component/filter/advanced-filter/advanced-filter.service';
import {
  IScwMatRules,
  ISelect,
  ScwMatMaxLengthRule,
  ScwMatMaxValueRule,
  ScwMatMinLengthRule,
  ScwMatMinValueRule,
  ScwMatRegexRule,
  ScwMatRule,
  ScwMatSelectRule,
} from '../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInput } from '../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { DependencyType, IDropdownSettings } from '../component/filter/dropdown/dropdown.model';
import { ILocaleDateObject } from '../model/enum/excel-date-format';
import { TimeZoneOptions } from '../model/enum/timezone-list';
import {
  DateRanges,
  ELanguages,
  emptyUserMeta,
  EOtherLanguages,
  EStatuses,
  formIoTraverseKeys,
  localeDateObject,
  noApprovalRequiredDbId,
  TOtherLanguageOptions,
  TSeparator,
} from '../../../constants';
import { IGenericObject, NonEmptyArray } from '../model/interface/generic.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IFormIoFile, ITableHeader } from '../../../constants.model';
import * as UserConfigurationActions from '../../store/user-configuration/user-configuration.actions';
import { ComponentNamesForUserConfiguration } from '../../store/user-configuration/user-configuration.model';
import { IAllowedDomain } from '../../store/main/main.model';
import { IFilterMap, IFilterMapped, IFormPdfProperties } from '../component/file-generator/file-generator.model';
import { IDatatableOnClickRow } from '../component/datatable/datatable.model';
import { EFormActivityType, IForm, IFormVersionSettings } from 'src/app/store/forms/forms.model';
import {
  IFieldMetaDropdown,
  IFormForm,
  IFormIoComponentKeyId,
  IFormIoDefaultComponentSchema,
  IFormIoTableComponentSchema,
} from '../../view/settings/form/form.model';
import { ExtendedComponentSchema, Utils } from 'formiojs';
import { IFieldSetMeta } from '../../store/my-tasks/logbook-tasks/logbook-tasks.model';
import { ILogbookForm, IModalContent } from '../../view/settings/logbook/logbook.model';
import { IScwMatDatepickerReturn } from '../component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { IFormMasterDataForm } from '../../view/settings/master-data/form-master-data/form-master-data.component.model';
import { ILogbookMasterDataForm } from '../../view/settings/master-data/logbook-master-data/logbook-master-data.component.model';
import {
  ILogbookMasterDataDetail,
  ILogbookMasterDataFields,
} from '../../store/settings/logbook-master-data/logbook-master-data.model';
import {
  ComponentUtilities,
  formIoLayoutComponentTypes,
  formIoNonFieldAreaComponentTypes,
  TFormIoComponentKey,
} from '../helper/component-utilities';
import { IFormMasterDataDetail } from '../../store/settings/form-master-data/form-master-data.model';
import { ILogbookVersion } from '../../store/logbook-versions/logbook-versions.model';
import StartOf = moment.unitOfTime.StartOf;
import { EApprovalStatuses, EFormActivationFlow } from '../model/enum/constants';
import { mysqlTimestampFormat } from '../helper/date';
import { IWorkflow } from '../../store/settings/workflows/workflows.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public isTouchDevice: boolean;
  private decimalSeparator!: TSeparator | null;
  private thousandSeparator!: string | null;
  private userDateFormat$!: string | null;
  private userDateTimeFormat$!: string | null;
  private userLocale$!: string | null;
  public static userDatePref: { dateFormat: string; timeFormat: string; weekFormat: string };
  private timezone$!: string | null;
  private successTitle!: string | null;
  private changesSavedSuccessfullyMessage!: string | null;
  private errorTitle!: string | null;
  public static disablePageConfigurationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public static userTimeZone: string | null = 'utc';
  public static userDateTimeFormat: string = '';
  public static userDateFormat: string = '';
  public static userTimeFormat: string = '';

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<LogbookAppState>,
    private translate: TranslateService,
    private toast: ToastrService,
    private errorMessageService: ErrorMessageService,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {
    this.isTouchDevice = 'ontouchstart' in document.documentElement;

    this.store.select('user').subscribe((state: User) => {
      this.decimalSeparator = state.decimalSeparator;
      this.thousandSeparator = state.thousandSeparator;
      this.userDateFormat$ = state.dateFormat;
      this.userLocale$ = state.language;
      this.timezone$ = state.timezone;
      const userTimeFormat: string = moment()
        .locale(state.locale ?? 'en')
        .localeData()
        .longDateFormat('LT');
      HelperService.userDateTimeFormat = `${this.userDateFormat$} ${userTimeFormat} [GMT]Z`;
      HelperService.userTimeZone = this.timezone$;
      HelperService.userDateFormat = this.userDateFormat$ ?? '';
      HelperService.userTimeFormat = `${userTimeFormat} [GMT]Z`;
      HelperService.getDateTimePref(state.dateFormat, state.dateFormat);
    });

    this.translate
      .stream(['general.success', 'general.changesSavedSuccessfully', 'general.error'])
      .subscribe((data) => {
        this.successTitle = data['general.success'];
        this.changesSavedSuccessfullyMessage = data['general.changesSavedSuccessfully'];
        this.errorTitle = data['general.error'];
      });
  }

  formattedNumberToNumber(formattedNumber: string) {
    if (typeof formattedNumber === 'undefined' || formattedNumber === null || formattedNumber === '') {
      return 0;
    }

    const thousandRegex = this.thousandSeparator === '.' ? /[.]/g : /[,]/g;

    const decimalRegex = this.decimalSeparator === '.' ? /[.]/g : /[,]/g;

    return parseFloat(formattedNumber.replace(thousandRegex, '').replace(decimalRegex, '.'));
  }

  numberToFormattedNumber(number: string | number, decimalCount = 1) {
    if (typeof number !== 'number' || !isFinite(number)) {
      return 0;
    }

    const decimal = this.decimalSeparator;
    const thousands = this.thousandSeparator;
    let decimalCountVar = Math.abs(decimalCount);
    decimalCountVar = isNaN(decimalCountVar) ? 2 : decimalCountVar;

    const negativeSign = number < 0 ? '-' : '';

    let numberVar;
    const i: any = parseInt((numberVar = Math.abs(Number(number) || 0).toFixed(decimalCountVar)), 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(Number(numberVar) - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  }

  public setFormSelectorsForPdf(
    selectors: IFormPdfProperties[],
    item: any,
    initialElementId: string,
    temp: string = '',
    selectorIndex: string = '',
  ): void {
    const initialSelector: string = `#${initialElementId} formio .formio-component-form .formio-form `;
    const panelSelector: string = ' .formio-component-';

    for (const component of item.components) {
      const itemIndex: number = item.components.indexOf(component) + 1;
      const index: string = `${selectorIndex}${selectorIndex !== '' ? '-' : ''}${itemIndex}`;
      let selectedElementClassList: string[] = [];
      const duplicatedKeySelector: string = '';
      const selectedElements: HTMLElement[] = Array.from(
        document.querySelectorAll(initialSelector + temp + panelSelector + component.key),
      );
      let modifiedQuerySelector: string | null = null;
      let selectedElement: HTMLElement | null = null;

      for (const element of selectedElements) {
        const elementClassList: string[] = element.classList.value.split(' ');
        const duplicateKeyClassCount: number = elementClassList.filter(
          (className: string) => className === `formio-component-${component.key}`,
        )?.length;

        if (duplicateKeyClassCount > 1) {
          selectedElement = element;
          modifiedQuerySelector = `#${element.id}`;
          break;
        }
      }

      if (_.isNil(selectedElement) && selectedElements?.length) {
        selectedElement = selectedElements[0];
      }

      if (selectedElement) {
        selectedElementClassList = Array.from(selectedElement.classList);
      }

      if (selectedElementClassList.includes('formio-hidden')) {
        continue;
      }

      if (component.type === 'panel') {
        selectors.push({
          index,
          selector: _.isNil(modifiedQuerySelector)
            ? initialSelector + temp + panelSelector + component.key + duplicatedKeySelector
            : initialSelector + modifiedQuerySelector,
          isPanel: true,
        });
        this.setFormSelectorsForPdf(
          selectors,
          component,
          initialElementId,
          _.isNil(modifiedQuerySelector)
            ? temp + panelSelector + component.key + duplicatedKeySelector
            : modifiedQuerySelector,
          index,
        );
      } else {
        selectors.push({
          index,
          selector: _.isNil(modifiedQuerySelector)
            ? initialSelector + temp + panelSelector + component.key + duplicatedKeySelector
            : initialSelector + modifiedQuerySelector,
          isPanelEnd: item.components.length === itemIndex,
        });
      }
    }
  }

  markEmptyField(value: any) {
    let output = value;
    if (typeof value === 'undefined' || value === null || value === '') {
      output = '--';
    }
    return output;
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  scrollButton(element: any, position: any) {
    switch (position) {
      case 'up': {
        document.querySelector(`.modal.show ${element}`)?.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        break;
      }
      case 'down': {
        document.querySelector(`.modal.show ${element}`)?.scroll({
          top: 9999999,
          left: 0,
          behavior: 'smooth',
        });
        break;
      }
      default:
    }
  }

  calculateRate(dividend: any, denominator: any, isProgress = false): number | string | any {
    let rate;
    let decimalCountVal = 1;
    let dividendVal = dividend;
    let denominatorVal = denominator;

    if (
      typeof dividend === 'undefined' ||
      typeof denominator === 'undefined' ||
      dividend === null ||
      denominator === null ||
      dividend === '' ||
      denominator === ''
    ) {
      return 0;
    }

    if (typeof dividend !== 'number') {
      dividendVal = this.formattedNumberToNumber(dividend);
    }

    if (typeof denominator !== 'number') {
      denominatorVal = this.formattedNumberToNumber(denominator);
    }

    if (denominatorVal === 0) {
      return 0;
    }

    rate = (dividendVal / denominatorVal) * 100;

    if (isProgress && rate <= 0) {
      return 0;
    }

    if (isProgress && rate >= 100) {
      return 100;
    }

    if (isProgress) {
      return rate;
    }

    if (!isProgress) {
      decimalCountVal = 0;
    }

    return this.numberToFormattedNumber(rate, decimalCountVal);
  }

  checkIsValidDateOrFail(date: any, format: string): void {
    const momentDate = moment(date, format, true);

    if (!momentDate.isValid()) {
      this.toast.error(this.translate.instant('general.incorrectDateFormat'), this.translate.instant('general.error'), {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });
      this.store.dispatch(new AppActions.HideTopLoader());
      this.store.dispatch(new AppActions.HideLoader());
      throw new Error('Invalid Date Format');
    }
  }

  getEditedFields<T>(form: NgForm): Partial<T> {
    let editedFields: Partial<T> = {};
    for (const [key, value] of Object.entries(form.form.controls)) {
      if (value.dirty) {
        editedFields = Object.assign(editedFields, { [key]: value.value });
      }
    }
    return editedFields;
  }

  public formatDuration(duration: number, withSecond: boolean = false, suffix: boolean = false) {
    if (duration === null) {
      return '';
    }

    let negative = '';

    if (duration < 0) {
      duration = duration * -1;
      negative = '-';
    }

    const hours: string = Math.floor(duration / 3600)
      .toString()
      .padStart(2, '0');
    const minutes: string = Math.floor((duration / 60) % 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (duration % 60).toString().padStart(2, '0');

    let shorteningHr = '';
    let shorteningMin = '';
    let shorteningSec = '';
    let separator = ':';

    if (suffix) {
      shorteningHr = this.translate.instant('general.shortHour');
      shorteningMin = this.translate.instant('general.shortMinute');
      shorteningSec = this.translate.instant('general.shortSecond');
      separator = ' ';
    }

    if (withSecond) {
      return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}${separator}${seconds}${shorteningSec}`;
    }

    return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}`;
  }

  public formattedDurationToSeconds(formattedDuration: string): number {
    if (typeof formattedDuration !== 'string') {
      return 0;
    }

    const split: string[] = formattedDuration.split(':');

    if (!split || split.length !== 2 || isNaN(Number(split[0])) || isNaN(Number(split[1]))) {
      return 0;
    }

    return Number(split[0]) * 3600 + Number(split[1]) * 60;
  }

  public minutesToFormattedHm(duration: number): string {
    let formattedDuration: string = '0';
    let hours: number = Math.floor(duration / 3600);
    const shortDay: string = this.translate.instant('general.shortDay');
    const shortHour: string = this.translate.instant('general.shortHour');
    const shortMinute: string = this.translate.instant('general.shortMinute');
    const days: number = Math.floor(duration / 86400);
    const minutes: number = Math.floor((duration - hours * 3600) / 60);

    if (days > 0) {
      hours = hours - 24 * days;

      formattedDuration = `${days}${shortDay} ${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (hours > 0) {
      formattedDuration = `${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (minutes > 0) {
      formattedDuration = `${minutes}${shortMinute}`;
    }

    return formattedDuration;
  }

  public pageSizeFormatter(pageSize: number) {
    let fixedPageSize = 10;

    if (10 < pageSize && pageSize <= 25) {
      fixedPageSize = 25;
    }

    if (25 < pageSize && pageSize <= 50) {
      fixedPageSize = 50;
    }

    if (50 < pageSize && pageSize <= 100) {
      fixedPageSize = 100;
    }
    return fixedPageSize;
  }

  public decimalToNumberFormatter(value: number | string): number | null {
    if (value === null) {
      return null;
    }

    const formatted: string = this.decimalSeparator === ',' ? value.toString().replace(/\,/g, '.') : value.toString();

    return Number(formatted);
  }

  public decimalToStringFormatter(value: number | string): string | null {
    if (value === null) {
      return null;
    }

    return this.decimalSeparator === ',' ? value.toString().replace(/\./g, ',') : value.toString().replace(/\,/g, '.');
  }

  getUserMomentTimeFormat(): MomentDateFormat.TIME_12H | MomentDateFormat.TIME_24H {
    return this.userDateFormat$ === UserDateFormat.DATETIME_12H ? MomentDateFormat.TIME_12H : MomentDateFormat.TIME_24H;
  }

  public showToastMessage(success: boolean, title: string | undefined, message: string): void {
    const type: 'success' | 'error' = success ? 'success' : 'error';

    this.toast[type](message, title, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
  }

  public decimalToPercentageFormat(value: number | string, decimalCount: number = 2): string | null {
    if (value === null || isNaN(Number(value))) {
      return null;
    }

    return this.decimalToStringFormatter(Number(value).toFixed(decimalCount));
  }

  public toastBulkErrors(response: IBulkResponseData): void {
    if (response && response.data) {
      this.errorMessageService.getTranslatedErrorMessage(response.data);

      for (const message of response.data) {
        if (!message.success) {
          this.showToastMessage(
            message.success,
            this.translate.instant('general.failed'),
            (message.message || []).join(', '),
          );
        }
      }
    }
  }

  public setUserDateTimeFormat(
    date: string | moment.Moment | Date,
    showTime: boolean = false,
    showYear: boolean = true,
  ): string {
    if (date === null || date === '') {
      return '';
    }

    let dateFormat = this.userDateFormat$;
    let formattedDate: string = '';

    if (showTime) {
      dateFormat = this.userDateTimeFormat$;
    }

    if (dateFormat && moment(date).isValid()) {
      formattedDate = moment(date).format(dateFormat);
    } else if (dateFormat && moment(date, dateFormat).isValid()) {
      formattedDate = moment(date, dateFormat).format(dateFormat);
    }

    if (!showYear) {
      formattedDate = this.removeYear(moment(date), formattedDate);
    }

    return formattedDate;
  }

  public setCustomDateFormats(): CustomDateFormat {
    return {
      parseInput: 'LL LT',
      fullPickerInput: this.userDateTimeFormat$,
      datePickerInput: this.userDateFormat$,
      timePickerInput: 'H:mm',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    };
  }

  public setLocale(): string | null {
    return this.userLocale$;
  }

  public getPlaceholderForDate(showTime: boolean = false): string {
    const now = showTime
      ? moment()
          .tz(this.timezone$ as string)
          .format(this.userDateTimeFormat$ as string)
      : moment()
          .tz(this.timezone$ as string)
          .format(this.userDateFormat$ as string);
    return this.translate.instant('general.datePlaceholder', {
      datePlaceholder: now,
    });
  }

  public removeYear(momentObject: moment.Moment, dateString: string): string {
    const regex: RegExp = new RegExp(`[^]${momentObject.format('Y')}`);
    return dateString.replace(regex, '');
  }

  public static getDateTimePref(timeFormat: string | null, dateFormat: string | null): void {
    const userDatePref = {
      timeFormat: 'h:mm a',
      dateFormat: 'ddd MM/DD',
      weekFormat: 'w.W MMM/YYYY',
    };

    if (timeFormat === 'MMM D, YYYY H:mm') {
      userDatePref.timeFormat = 'H:mm';
    }

    if (dateFormat === 'MM/DD/YYYY') {
      userDatePref.dateFormat = 'ddd DD/MM';
    }

    HelperService.userDatePref = userDatePref;
  }

  public static transformToBoolean(value: unknown): boolean | unknown {
    if (_.isNil(value) || _.isBoolean(value)) {
      return value;
    }

    try {
      const parsedValue = JSON.parse(String(value));

      return [1, 0, true, false].includes(parsedValue) ? Boolean(parsedValue) : value;
    } catch {
      return value;
    }
  }

  public static prepareDecimalValue(decimal: number, decimalSeparator: ',' | '.'): number | string {
    if (decimalSeparator === '.') return decimal;

    return String(decimal).replace('.', ',');
  }

  public getRecordRangeOptions(
    totalData: number,
    excelUploadLimit: number = 1000,
    selectedCount: number | null = null,
  ): any[] {
    const totalExcelData: number = selectedCount ? selectedCount : totalData;

    return Array.from(Array(Math.ceil(totalData / excelUploadLimit))).map((_value, index) => {
      const rangeStart = index * excelUploadLimit;
      const rangeEnd = rangeStart + excelUploadLimit;

      return {
        value: String(index + 1),
        label: `${rangeStart + 1} - ${rangeEnd < totalExcelData ? rangeEnd : totalExcelData}`,
      };
    });
  }

  public static enumToArray<T extends object>(enumeration: T): Array<T[keyof T]> {
    return Object.keys(enumeration)
      .filter((key: string) => isNaN(Number(key)))
      .map((key: string) => enumeration[key as keyof T]);
  }

  public getLocales(): ISelect<string, string>[] {
    const locales: ISelect<string, string>[] = [];
    for (const locale in localeDateObject) {
      locales.push({ id: locale, name: localeDateObject[locale as keyof ILocaleDateObject] });
    }
    return locales;
  }

  public getFormActivationFlowDropdown(): ISelect<string, string>[] {
    return [
      {
        id: EFormActivationFlow.ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES,
        name: this.translate.instant(
          'settings.client.form.formActivationFlow.activateVersionWhileThereAreOngoingEntries',
        ),
      },
      {
        id: EFormActivationFlow.ACTIVATE_VERSION_AFTER_COMPLETING_ONGOING_ENTRIES,
        name: this.translate.instant(
          'settings.client.form.formActivationFlow.activateVersionAfterCompletingOngoingEntries',
        ),
      },
    ];
  }

  public getYesNoDropdown(): ISelect<number, string>[] {
    return [
      { id: 1, name: this.translate.instant('general.yes') },
      { id: 0, name: this.translate.instant('general.no') },
    ];
  }

  public getYesNoDropdownExcel(): ISelect<number | string, string>[] {
    return [
      { id: 1, name: this.translate.instant('general.yes') },
      { id: '0', name: this.translate.instant('general.no') },
    ];
  }

  public getYesDropdownExcel(): ISelect<number | string, string>[] {
    return [{ id: 1, name: this.translate.instant('general.yes') }];
  }

  public getYesNoDropdownBoolean(): ISelect<boolean, string>[] {
    return [
      { id: true, name: this.translate.instant('general.yes') },
      { id: false, name: this.translate.instant('general.no') },
    ];
  }

  public getIpRestrictionDropdownBoolean(): ISelect<number, string>[] {
    return [
      { id: 1, name: this.translate.instant('settings.users.clientDefault') },
      { id: 0, name: this.translate.instant('general.no') },
    ];
  }

  public getActiveInactive(): ISelect<number, string>[] {
    return [
      { id: 1, name: this.translate.instant('general.active') },
      { id: 0, name: this.translate.instant('general.inactive') },
    ];
  }

  public getUserActiveInactive(): ISelect<number, string>[] {
    return [
      { id: 1, name: this.translate.instant('general.active') },
      { id: 0, name: this.translate.instant('settings.users.disabled') },
    ];
  }

  public getFlowType(): ISelect<boolean, string>[] {
    return [
      { id: false, name: this.translate.instant('general.consecutive') },
      { id: true, name: this.translate.instant('general.parallel') },
    ];
  }

  public getTimeType(): ISelect<number, string>[] {
    return [
      { id: 0, name: this.translate.instant('general.hours') },
      { id: 1, name: this.translate.instant('general.days') },
    ];
  }

  public getTimeTypeExcel(): ISelect<number | string, string>[] {
    return [
      { id: '0', name: this.translate.instant('general.hours') },
      { id: 1, name: this.translate.instant('general.days') },
    ];
  }

  public getLogBookStatus(): ISelect<number, string>[] {
    return [
      { id: 0, name: this.translate.instant('general.draft') },
      { id: 1, name: this.translate.instant('general.awaitingApproval') },
      { id: -3, name: this.translate.instant('general.approved') },
      { id: -4, name: this.translate.instant('general.completed') },
      { id: -5, name: this.translate.instant('general.active') },
      { id: -2, name: this.translate.instant('general.archived') },
      { id: -1, name: this.translate.instant('general.sendBack') },
      { id: -7, name: this.translate.instant('general.rejected') },
    ];
  }

  public getFormActivityTypes(): ISelect<EFormActivityType, string>[] {
    return [
      { id: EFormActivityType.CLEANING, name: this.translate.instant('general.logbookActivityTypes.cleaning') },
      {
        id: EFormActivityType.MAINTENANCE,
        name: this.translate.instant('general.logbookActivityTypes.maintenance'),
      },
      { id: EFormActivityType.SETUP, name: this.translate.instant('general.logbookActivityTypes.setup') },
      { id: EFormActivityType.RUN, name: this.translate.instant('general.logbookActivityTypes.run') },
      {
        id: EFormActivityType.LINE_CLEARANCE,
        name: this.translate.instant('general.logbookActivityTypes.line_clearance'),
      },
      { id: EFormActivityType.CALIBRATION, name: this.translate.instant('general.logbookActivityTypes.calibration') },
      { id: EFormActivityType.OTHER, name: this.translate.instant('general.logbookActivityTypes.other') },
    ];
  }

  public getDefaultStatusValues(): ISelect<number, string>[] {
    return [
      { id: 0, name: this.translate.instant('general.draft') },
      { id: 1, name: this.translate.instant('general.awaitingApproval') },
      { id: -3, name: this.translate.instant('general.approved') },
      { id: -4, name: this.translate.instant('general.completed') },
      { id: -5, name: this.translate.instant('general.active') },
      { id: -2, name: this.translate.instant('general.obsolete') },
      { id: -7, name: this.translate.instant('general.rejected') },
      { id: -1, name: this.translate.instant('general.sendBack') },
    ];
  }

  public getFormStatusValues(): ISelect<number, string>[] {
    let isFormActivationFlowFlaggedToBeObsolete: boolean = false;

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        isFormActivationFlowFlaggedToBeObsolete =
          user.formActivationFlow === EFormActivationFlow.ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES;
      });

    return [
      { id: EApprovalStatuses.NOT_SUBMITTED, name: this.translate.instant('general.draft') },
      { id: EApprovalStatuses.SUBMITTED, name: this.translate.instant('general.awaitingApproval') },
      { id: EApprovalStatuses.APPROVED, name: this.translate.instant('general.approved') },
      { id: EApprovalStatuses.COMPLETED, name: this.translate.instant('general.completed') },
      { id: EApprovalStatuses.ACTIVE, name: this.translate.instant('general.active') },
      ...(isFormActivationFlowFlaggedToBeObsolete
        ? [
            {
              id: EApprovalStatuses.FLAGGED_TO_BE_OBSOLETE,
              name: this.translate.instant('general.formActivationFlow'),
            },
          ]
        : []),
      { id: EApprovalStatuses.OBSOLETE, name: this.translate.instant('general.obsolete') },
      { id: EApprovalStatuses.SEND_BACK, name: this.translate.instant('general.sendBack') },
    ];
  }

  public getAllowDeny(): ISelect<number, string>[] {
    return [
      { id: 0, name: this.translate.instant('general.denied') },
      { id: 1, name: this.translate.instant('general.allowed') },
    ];
  }

  public getActiveInactiveOfStatus(): ISelect<EStatuses, string>[] {
    return [
      { id: EStatuses.ACTIVE, name: this.translate.instant('general.active') },
      { id: EStatuses.INACTIVE, name: this.translate.instant('general.inactive') },
    ];
  }

  public getDateTimeFormats(locale: string, timezone?: string | null): ISelect<string, string>[] {
    return [
      {
        id: moment().locale(locale).localeData().longDateFormat('ll'),
        name: (timezone ? moment().tz(timezone) : moment()).locale(locale).format('ll LT'),
      },
      {
        id: moment().locale(locale).localeData().longDateFormat('L'),
        name: (timezone ? moment().tz(timezone) : moment()).locale(locale).format('L LT'),
      },
    ];
  }

  public getDateTimeFormatsWithLocales(): ISelect<string, string>[] {
    const locales: ISelect<string, string>[] = this.getLocales();
    const dateTimeFormatsWithLocale: ISelect<string, string>[] = [];
    locales.forEach((locale) => {
      dateTimeFormatsWithLocale.push({
        id: `${moment().locale(locale.id).localeData().longDateFormat('ll')}|${locale.id}`,
        name: `${locale.name} - ${moment()
          .tz(this.timezone$ ?? 'utc')
          .locale(locale.id)
          .format('ll LT')}`,
      });
      dateTimeFormatsWithLocale.push({
        id: `${moment().locale(locale.id).localeData().longDateFormat('L')}|${locale.id}`,
        name: `${locale.name} - ${moment()
          .tz(this.timezone$ ?? 'utc')
          .locale(locale.id)
          .format('L LT')}`,
      });
    });
    return dateTimeFormatsWithLocale;
  }

  public getTimezones(): ISelect<string, string>[] {
    return Object.values(TimeZoneOptions).map((value: string) => ({ id: value, name: value })) as ISelect<
      string,
      string
    >[];
  }

  public insertGenericCrudRequestParameters(parameters: GenericCrudRequestConstructionParameters): HttpParams {
    let params: HttpParams = new HttpParams();

    if (parameters.page) {
      params = params.set('page', String(parameters.page));
    }

    if (parameters.perPage) {
      params = params.set('per_page', String(parameters.perPage));
    }

    if (parameters.limit) {
      params = params.set('limit', String(parameters.limit));
    }

    if (parameters.fields?.length) {
      params = params.set('fields', parameters.fields.join(','));
    }

    for (const sort of parameters.sort ?? []) {
      params = params.append('sort', `${sort.column},${sort.type === 'descending' ? 'DESC' : 'ASC'}`);
    }

    if (parameters.join?.length) {
      for (const join of parameters.join) {
        params = params.append('join', join);
      }
    }

    return this.prepareSearchParamsFilter(parameters, params);
  }

  private prepareSearchParamsFilter(
    parameters: GenericCrudRequestConstructionParameters,
    params: HttpParams,
  ): HttpParams {
    const searchCondition = [];

    if (parameters.additionalCustomSearch) {
      searchCondition.push(parameters.additionalCustomSearch);
    }

    if (parameters.search?.searchText.length) {
      searchCondition.push({
        $or: parameters.search.searchedFields.map((field: string) => {
          return {
            [field]: { $cont: parameters.search?.searchText },
          };
        }),
      });
    }

    const filterCondition =
      parameters.filters?.map((filter) => {
        return { [filter.field]: { $in: filter.ids } };
      }) ?? [];

    const combinedConditions: { $and: { [key: string]: any }[] } = {
      $and: [...filterCondition, ...searchCondition],
    };

    return combinedConditions.$and.length ? params.set('s', JSON.stringify(combinedConditions)) : params;
  }

  public getGenericDropdownFilter(
    entity: {
      type: Type<FilterableObjectTypes>;
      name: string;
    },
    cls: string = 'col-md-3 m-t-5 m-b-5',
    overrideOptions: Partial<IDropdownSettings> = {},
    depends?: DependencyType,
  ): IRowConfiguration {
    return {
      cls,
      elementId: `${entity.name}Dropdown`,
      type: DropdownComponent,
      object: entity.type,
      outputOptions: {
        filterObjectId: entity.name,
        filterObjectProp: 'id',
        returnFilterObjectAllProp: false,
      },
      options: {
        isRequired: false,
        singleSelection: false,
        badgeShowLimit: 1,
        text: this.translate.instant(`filterCard.${entity.name}.dropdownPlaceHolder`),
        enableSearchFilter: true,
        ...overrideOptions,
      },
      ...(depends ? { depends } : {}),
    };
  }

  public getGenericAdvancedFilter(
    _advancedFilterObject: Type<AdvancedFilterClass>,
    cls: string = 'col-md-4 m-t-5 m-b-5',
    elementId: string = 'advancedFilter',
  ): IRowConfiguration {
    return {
      cls,
      elementId,
      type: AdvancedFilterComponent,
      outputOptions: {
        filterObjectId: 'advancedFilter',
      },
      options: {
        isRequired: false,
        autoApply: true,
      },
    };
  }

  public pageFilterChangeOperations(
    event: any,
    pageData: {
      pageDataCrudParameters: GenericCrudRequestConstructionParameters;
      pageName: string;
      isFirstTimeOpening: boolean[];
      selectedItems: unknown[];
    },
    additionalOperations: Function,
    target: TargetEndpoints = TargetEndpoints.DjangoDefault,
  ): void {
    this.store
      .select('advancedFilterStore')
      .pipe(take(1))
      .subscribe((state: IAdvancedFilterStore) => {
        pageData.pageDataCrudParameters.page = 1;

        if (pageData.isFirstTimeOpening[0]) {
          pageData.pageDataCrudParameters.advancedFilter = {
            target,
            filters: [],
            page: pageData.pageName,
          };

          if (!_.isEmpty(_.get(state.defaultFilters, pageData.pageName, null))) {
            pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
            pageData.pageDataCrudParameters.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
              state.defaultFilters[pageData.pageName],
            );
          }

          pageData.isFirstTimeOpening[0] = false;
        } else if (event.advancedFilter !== null) {
          pageData.pageDataCrudParameters.advancedFilter = _.get(event, 'advancedFilter', null);
          pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
        }

        pageData.selectedItems = [];
        pageData.pageDataCrudParameters.filters = [
          ...(Array.isArray(event.site) ? [{ field: 'siteId', ids: event.site }] : []),
          ...(Array.isArray(event.line) ? [{ field: 'lineId', ids: event.line }] : []),
        ];

        additionalOperations();
      });
  }

  public selectOrUnselectAllCheckboxes(
    isSelectAll: boolean,
    elementRef: ElementRef,
    componentName: string,
    pageData: { id: number }[],
  ): void {
    if (elementRef.nativeElement.querySelector(`[id^="${componentName}-checkbox-"]`) !== null) {
      pageData.forEach((item) => {
        const checkbox = _.head(
          document.getElementById(`${componentName}-checkbox-${item.id}`)?.getElementsByTagName('input'),
        );

        if ((isSelectAll && !checkbox?.checked) || (!isSelectAll && checkbox?.checked)) {
          checkbox?.click();
        }
      });
    }
  }

  public onDatatableRowClicked($event: IDatatableOnClickRow, componentName: string): void {
    const { event, item } = $event;
    const index = Array.from(event.target.parentElement.children).indexOf(event.target);

    if (
      index !== 0 ||
      event.target.classList.contains('mat-checkbox-inner-container') ||
      event.target.tagName !== 'TD'
    ) {
      return;
    }

    _.head(document.getElementById(`${componentName}-checkbox-${item['id']}`)?.getElementsByTagName('input'))?.click();
  }

  public onBulkEditCheckboxChange(
    inputParameters: {
      value: boolean;
      index: string;
      formComponentInstance: ScwMatInput;
      defaultValue?: any;
    },
    inputFormParameters: {
      form: any;
      rules: IScwMatRules;
      areAnyOfTheBulkEditFormSelectBoxesSelected: boolean;
      isBulk: boolean;
      dropdownOptions: { [dropdownName: string]: ISelect<number | string, string>[] };
    },
    dependentInputParameters?: {
      index: string;
      formComponentInstance: ScwMatInput;
    }[],
  ): void {
    if (inputParameters.value) {
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.rules`,
        _.get(inputFormParameters.rules, inputParameters.index),
      );

      if (inputParameters.defaultValue !== undefined) {
        _.set(inputFormParameters.form, `${inputParameters.index}.value`, inputParameters.defaultValue);
      }
    } else {
      inputParameters.formComponentInstance.reset();
      _.set(inputFormParameters.form, `${inputParameters.index}.rules`, []);
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.value`,
        Array.isArray(_.get(inputFormParameters.form, `${inputParameters.index}.value`)) ? [] : null,
      );

      for (const dependentInput of dependentInputParameters ?? []) {
        dependentInput.formComponentInstance.reset();
        _.set(inputFormParameters.form, `${dependentInput.index}.rules`, []);
        _.set(
          inputFormParameters.form,
          `${dependentInput.index}.value`,
          Array.isArray(_.get(inputFormParameters.form, `${dependentInput.index}.value`)) ? [] : null,
        );
        _.set(inputFormParameters.form, `${dependentInput.index}.isEnabled`, false);
        _.set(inputFormParameters.dropdownOptions, dependentInput.index, []);
      }
    }

    inputFormParameters.areAnyOfTheBulkEditFormSelectBoxesSelected = Object.values(inputFormParameters.form).some(
      (item: any) => item.isEnabled,
    );
  }

  public getRequiredFormRule(): ScwMatSelectRule {
    return {
      required: true,
      message: this.translate.instant('scwMatForm.validation.required'),
    };
  }

  public getMaxLengthFormRule(maxLength: number): ScwMatMaxLengthRule {
    return {
      maxLength,
      message: this.translate.instant('scwMatForm.validation.maxLength', { maxLength }),
    };
  }

  public getNotSpecialCharactersFormRule(fieldName: string): ScwMatRegexRule {
    return {
      pattern: new RegExp('^[A-Za-z0-9üıçşğöÜİÇŞĞÖ ]+$'),
      message: this.translate.instant('scwMatForm.validation.specialCharacters', { name: fieldName }),
    };
  }

  public nameNotEqualFormRule(value: string): ScwMatRegexRule {
    const escapedWords: string[] = value.split(/\s+/).map((word) => {
      return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    });
    const escapedValue: string = escapedWords.join('\\s+');

    return {
      pattern: new RegExp(`^(?!\\s*${escapedValue}\\s*$).*$`, 'i'),
      message: this.translate.instant('apiErrorMessages.scopeNameAlreadyExists'),
    };
  }

  public getMinLengthFormRule(minLength: number): ScwMatMinLengthRule {
    return {
      minLength,
      message: this.translate.instant('scwMatForm.validation.minLength', { minLength }),
    };
  }

  public getMinValueFormRule(minValue: number): ScwMatMinValueRule {
    return {
      minValue,
      message: this.translate.instant('scwMatForm.validation.minValue', { minValue }),
    };
  }

  public getMaxValueFormRule(maxValue: number): ScwMatMaxValueRule {
    return {
      maxValue,
      message: this.translate.instant('scwMatForm.validation.maxValue', { maxValue }),
    };
  }

  public isNotBlankFormRule(): ScwMatRule {
    return {
      custom: true,
      message: this.translate.instant('alertSettings.validation.isNotBlank'),
      validator: (value) => {
        return !_.isEmpty(String(value ?? '').trim());
      },
    };
  }

  public getAtLeastOneAlphanumericalRule(): ScwMatRegexRule {
    return {
      pattern: new RegExp('[A-Za-z0-9]+'),
      message: this.translate.instant('scwMatForm.validation.atLeastOneAlphanumerical'),
    };
  }

  public multipleModalDismiss(modals: NgbModalRef[]): void {
    for (const modal of modals) {
      if (modal) {
        modal.dismiss();
      }
    }
  }

  public nest<T>(seq: unknown, keys: NonEmptyArray<string>): T {
    if (!keys.length) {
      return seq as T;
    }

    const first: string = keys[0];
    const rest: string[] = keys.slice(1);
    return _.mapValues(_.groupBy(seq as any, first), (value) => {
      return this.nest(value, rest as NonEmptyArray<string>);
    }) as T;
  }

  public static formatDropdownFilterOptionOutput<T>(output: T[] | T): -1 | T[] | T {
    return Array.isArray(output) && (output?.[0] ?? -1) === -1 ? -1 : output;
  }

  public showGenericChangesSavedSuccessfullyToastMessage(message = this.changesSavedSuccessfullyMessage): void {
    this.showToastMessage(true, this.successTitle ?? '', message ?? '');
  }

  public static getSuccessAndFailedFromBulkResponse<T>(response: T[]): { fail: T[]; success: T[] } {
    return response.reduce(
      (filtered: { fail: T[]; success: T[] }, data) => {
        filtered[_.get(data, 'errorMessages') ? 'fail' : 'success'].push(data);

        return filtered;
      },
      { fail: [], success: [] },
    );
  }

  public getDefaultRanges(range: DateRanges): DateRanges {
    const temporaryRanges: DateRanges = {};

    Object.entries(_.cloneDeep(range)).forEach(([index, [start, end]]) => {
      const key = this.translate.instant(`dateRangePicker.ranges.${index}`) as keyof DateRanges;
      temporaryRanges[key] = [start.startOf('day'), end];
    });

    return temporaryRanges;
  }

  public onApplyClick(
    data: ITableHeader[],
    defaultHeaderArray: ITableHeader[],
    insertToFirst: boolean,
    setAsDefault: boolean,
    componentName: ComponentNamesForUserConfiguration,
    _userId: number,
    storeName: string,
    logbookId?: number,
  ): ITableHeader[] {
    const rows = data;
    let headers: ITableHeader[] = [];

    if (insertToFirst) {
      headers = [defaultHeaderArray[0]];
    }

    rows.forEach((item: any) => {
      if (item.selected) {
        const headerData = defaultHeaderArray.find((value: ITableHeader) => value.value === item.value);
        if (headerData && headerData?.value !== null) {
          headers.push(headerData);
        }
      }
    });

    if (setAsDefault) {
      this.onSetAsDefault(componentName, storeName, logbookId);
    }

    return headers;
  }

  public onSetAsDefault(
    componentName: ComponentNamesForUserConfiguration,
    storeName: string,
    logbookId?: number,
  ): void {
    let selectedColumns: ITableHeader[] = [];

    this.store
      .select(storeName as keyof LogbookAppState)
      .pipe(take(1))
      .subscribe((state: LogbookAppState[keyof LogbookAppState] & { tableSettings?: ITableHeader[] }) => {
        selectedColumns = _.cloneDeep(state?.tableSettings) ?? [];
      });

    let userConfig!: IConfig | null;

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        userConfig = { ..._.cloneDeep(emptyUserMeta), ..._.cloneDeep(state.configuration) };
      });

    if (userConfig) {
      userConfig.pageConfig[componentName] = selectedColumns.reduce((filtered: Partial<ITableHeader>[], column) => {
        if (column.selected) {
          filtered.push({ value: column.value, type: column.type });
        }

        return filtered;
      }, []);

      if (logbookId) {
        userConfig.logbookHomeConfiguration = userConfig?.logbookHomeConfiguration ?? [];
        const matchedLogbookConfiguration: ILogbookHomeConfiguration | undefined =
          userConfig.logbookHomeConfiguration.find(
            (item: ILogbookHomeConfiguration): boolean => item.logbookId === logbookId,
          );

        if (matchedLogbookConfiguration) {
          matchedLogbookConfiguration.pageConfig = userConfig.pageConfig.LogbookHomeDetailComponent;
        } else {
          userConfig.logbookHomeConfiguration.push({
            logbookId,
            pageConfig: userConfig.pageConfig.LogbookHomeDetailComponent,
          });
        }
      }
    }

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(userConfig));
  }

  public static emailAllowedDomainMatch(input: string, allowedDomains: IAllowedDomain[]): boolean {
    const emailDomain = input?.split('@')?.[1];

    return emailDomain ? allowedDomains.some((domain: IAllowedDomain) => domain.domain === emailDomain) : false;
  }

  public getMappedFilteredValues(requestModel: IFilterMap): IFilterMapped[] | null {
    if (!requestModel) {
      return null;
    }

    const mappedValues: IFilterMapped[] = [];
    this.store
      .select(requestModel.storeName)
      .pipe(take(1))
      .subscribe((state: any) => {
        if (!state) {
          return;
        }
        let filter = [];
        const reqIds: number[] | number = requestModel.filteredDataIds as number | number[];

        if (reqIds === -1 || reqIds === undefined) {
          filter = state.data;
        } else {
          filter = state.data.filter((data: any) => reqIds.toString().includes(data[requestModel.filterPrefix]));
        }
        filter.map((prop: any) => {
          mappedValues.push({
            value: prop.hasOwnProperty(requestModel.expectedProperty) ? prop[requestModel.expectedProperty] : null,
            id: prop.hasOwnProperty('id') ? prop['id'] : null,
          });
        });
      });
    return mappedValues;
  }

  public getTranslation(userLanguage: ELanguages, translations: TOtherLanguageOptions, originalText: string): string {
    return HelperService.getTranslation(userLanguage, translations, originalText);
  }

  public static getTranslation(
    userLanguage: ELanguages,
    translations: TOtherLanguageOptions,
    originalText: string,
  ): string {
    if (_.isNil(userLanguage) || userLanguage === ELanguages.EN || _.isNil(translations)) {
      return originalText;
    }

    const userLanguageLowerCase: EOtherLanguages = userLanguage.toLowerCase() as EOtherLanguages;

    return !_.isNil(translations[userLanguageLowerCase]) && translations[userLanguageLowerCase].trim() !== ''
      ? translations[userLanguageLowerCase]
      : originalText;
  }

  public showWaitMessage(): void {
    this.toast.info(this.translate.instant('general.pleaseWaitForLongProcess'), '', {
      closeButton: false,
      progressBar: false,
      positionClass: 'toast-bottom-right',
    });
  }

  public formatNumber(value: number): number | string | null {
    if (_.isNil(value) || typeof value !== 'number') {
      return null;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 20,
    });

    const [intPart, decimalPart] = formatter.format(value).split('.');
    return [intPart.replace(/[,]/g, this.thousandSeparator ?? ''), ...(decimalPart ? [decimalPart] : [])].join(
      this.decimalSeparator ?? '.',
    );
  }

  public static createHttpParamsWithObject(parameters: IGenericObject<any>, arrayJoinSeparator = ','): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    if (typeof parameters === 'object' && !_.isNil(parameters)) {
      for (const [key, value] of Object.entries(parameters)) {
        httpParams = httpParams.append(
          key,
          Array.isArray(value) ? value.join(arrayJoinSeparator) : (value as string | number | boolean),
        );
      }
    }

    return httpParams;
  }

  public lineDropdownOptionCompareFunction(
    line1: { name?: string; title?: string; departmentName: string },
    line2: { name?: string; title?: string; departmentName: string },
  ): number {
    return `${line1.departmentName}${line1.name ?? line1.title}`.localeCompare(
      `${line2.departmentName}${line2.name ?? line2.title}`,
      undefined,
      {
        numeric: true,
        sensitivity: 'base',
      },
    );
  }

  public dropdownOptionCompareFunction(
    item1: { name?: string; title?: string },
    item2: { name?: string; title?: string },
  ): number {
    return `${item1.name ?? item1.title}`.localeCompare(`${item2.name ?? item2.title}`, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  public static get baseUri(): string {
    if (isDevMode()) {
      return `${location.origin}`;
    }

    return `${location.origin}/new/`;
  }

  public getIsNotBlankFormRule(): ScwMatSelectRule {
    return {
      custom: true,
      message: this.translate.instant('general.inputErrorMessages.blank'),
      validator: (value) => {
        return !_.isEmpty(String(value).trim());
      },
    };
  }

  public getLanguages(): ISelect<string, string>[] {
    return [
      { id: 'en', name: this.translate.instant('general.english') },
      { id: 'fr', name: this.translate.instant('general.french') },
      { id: 'tr', name: this.translate.instant('general.turkish') },
    ];
  }

  public getDecimalAndThousandSeparators(): ISelect<number, string>[] {
    return [
      { id: 1, name: this.translate.instant('general.dotAndComma') },
      { id: 2, name: this.translate.instant('general.commaAndDot') },
    ];
  }

  public static numberEnumToObject<T>(enumToTransform: T, translateKeyPrefix: string): IGenericObject<string> {
    return Object.entries(enumToTransform).reduce((result: IGenericObject<string>, [key, _value]) => {
      if (isNaN(Number(key))) {
        result[_value] = `${translateKeyPrefix}.${_.camelCase(key)}`;
      }

      return result;
    }, {});
  }

  public static numberEnumToSelectOption<T>(enumToTransform: T, translateKeyPrefix: string): ISelect<number, string>[] {
    return Object.entries(enumToTransform)
      .filter(([key, _value]) => isNaN(Number(key)))
      .map(([key, value]) => {
        return {
          id: value as number,
          name: `${translateKeyPrefix}.${_.camelCase(key)}`,
        };
      });
  }

  public translateSelectOptions<T>(selectOptions: ISelect<T, string>[]): ISelect<T, string>[] {
    return selectOptions.map((option: ISelect<T, string>) => {
      return {
        ...option,
        name: this.translate.instant(option.name),
      };
    });
  }

  public translateObjectValues(genericObject: IGenericObject<string>): any {
    return Object.entries(genericObject).reduce((result: IGenericObject<string>, [key, value]) => {
      result[key] = this.translate.instant(value);

      return result;
    }, {});
  }

  public getDateRangesWithTimezone(dateRangeOptions: (keyof DateRanges)[]): DateRanges {
    const allRanges: DateRanges = {
      today: [moment().startOf('day'), moment().endOf('day')],
      todayWhole: [moment().startOf('day'), moment().endOf('day')],
      yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      thisWeek: [moment().startOf('week'), moment().endOf('week')],
      lastWeek: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
      nextSevenDays: [moment(), moment().add(7, 'day')],
      thisMonth: [moment().startOf('month'), moment().endOf('month')],
      lastMonth: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      nextThirtyDays: [moment(), moment().add(30, 'day')],
      lastThreeMonths: [moment().startOf('month').subtract(3, 'month'), moment().endOf('month').subtract(1, 'month')],
      thisYear: [moment().startOf('year'), moment().endOf('year')],
      lastYear: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    };

    return Object.keys(allRanges)
      .filter((range) => dateRangeOptions.includes(range as keyof DateRanges))
      .reduce((obj, range) => {
        return {
          ...obj,
          [range]: (allRanges as any)[range],
        };
      }, {});
  }

  public getAuditTrailActions(
    includeHidden: boolean = false,
    filterByUserStore: boolean = true,
  ): ISelect<string, string>[] {
    let isFormActivationFlowFlaggedToBeObsolete: boolean = false;

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        isFormActivationFlowFlaggedToBeObsolete =
          user.formActivationFlow === EFormActivationFlow.ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES;
      });

    const output = [
      { id: 'created', name: this.translate.instant('auditTrail.actions.created') },
      { id: 'updated', name: this.translate.instant('auditTrail.actions.updated') },
      { id: 'deleted', name: this.translate.instant('auditTrail.actions.deleted') },
      ...(isFormActivationFlowFlaggedToBeObsolete || !filterByUserStore
        ? [{ id: 'flagged_to_be_obsolete', name: this.translate.instant('auditTrail.actions.flagged_to_be_obsolete') }]
        : []),
      { id: 'obsoleted', name: this.translate.instant('auditTrail.actions.obsoleted') },
      { id: 'canceled', name: this.translate.instant('auditTrail.actions.canceled') },
      { id: 'to_be_archived', name: this.translate.instant('auditTrail.actions.to_be_archived') },
      { id: 'archived', name: this.translate.instant('auditTrail.actions.archived') },
      { id: 'submitted', name: this.translate.instant('auditTrail.actions.submit') },
      { id: 'approved', name: this.translate.instant('auditTrail.actions.approved') },
      { id: 'completed', name: this.translate.instant('auditTrail.actions.completed') },
      { id: 'rejected', name: this.translate.instant('auditTrail.actions.rejected') },
      { id: 'activated', name: this.translate.instant('auditTrail.actions.activated') },
      { id: 'inactivated', name: this.translate.instant('auditTrail.actions.inactivated') },
      { id: 'send_back', name: this.translate.instant('auditTrail.actions.send_back') },
      { id: 'archive_in_progress', name: this.translate.instant('auditTrail.actions.archive_in_progress') },
      { id: 'activation_in_progress', name: this.translate.instant('auditTrail.actions.activation_in_progress') },
      { id: 'login', name: this.translate.instant('auditTrail.actions.login') },
      { id: 'logout', name: this.translate.instant('auditTrail.actions.logout') },
      { id: 'excel_import_export', name: this.translate.instant('auditTrail.actions.importExportExcel') },
      { id: 'pdf_download', name: this.translate.instant('auditTrail.actions.createPdf') },
      { id: 'updating', name: this.translate.instant('auditTrail.actions.updating') },
      { id: 'signed', name: this.translate.instant('auditTrail.actions.signed') },
      { id: 'check_in', name: this.translate.instant('auditTrail.actions.check_in') },
      { id: 'check_out', name: this.translate.instant('auditTrail.actions.check_out') },
    ];

    if (includeHidden) {
      output.push({ id: 'excel_import', name: this.translate.instant('auditTrail.actions.importExcel') });
      output.push({ id: 'excel_download', name: this.translate.instant('auditTrail.actions.exportExcel') });
      output.push({
        id: 'excel_template_download',
        name: this.translate.instant('auditTrail.actions.exportTemplateExcel'),
      });
    }

    return output;
  }

  public getAuditTrailLocations(includeHidden: boolean = false): ISelect<string, string>[] {
    const output = [
      { id: 'home', name: this.translate.instant('auditTrail.locations.form_submissions') },
      { id: 'my_tasks', name: this.translate.instant('auditTrail.locations.my_tasks') },
      { id: 'report_form_entries', name: this.translate.instant('auditTrail.locations.form_entries_report') },
      { id: 'report_form_templates', name: this.translate.instant('auditTrail.locations.form_templates_reports') },
      {
        id: 'report_logbook_templates',
        name: this.translate.instant('auditTrail.locations.logbook_templates_reports'),
      },
      { id: 'report_logs', name: this.translate.instant('auditTrail.locations.logs_reports') },
      { id: 'clients', name: this.translate.instant('auditTrail.locations.clients') },
      { id: 'predefined_reasons', name: this.translate.instant('auditTrail.locations.predefined_reasons') },
      { id: 'roles', name: this.translate.instant('auditTrail.locations.roles') },
      { id: 'scopes', name: this.translate.instant('auditTrail.locations.scopes') },
      { id: 'users', name: this.translate.instant('auditTrail.locations.users') },
      { id: 'workflows', name: this.translate.instant('auditTrail.locations.workflows') },
      { id: 'field_versions', name: this.translate.instant('auditTrail.locations.field_versions') },
      { id: 'logbooks', name: this.translate.instant('auditTrail.locations.logbooks') },
      { id: 'forms', name: this.translate.instant('auditTrail.locations.forms') },
      { id: 'activity_types', name: this.translate.instant('auditTrail.locations.activity_types') },
      { id: 'login_page', name: this.translate.instant('auditTrail.locations.login_page') },
      { id: 'configurations', name: this.translate.instant('auditTrail.locations.configurations') },
      { id: 'states', name: this.translate.instant('auditTrail.locations.states') },
    ];

    if (includeHidden) {
      output.push({ id: 'home', name: this.translate.instant('auditTrail.locations.form_submissions') });
      output.push({ id: 'form_submissions', name: this.translate.instant('auditTrail.locations.form_submissions') });
      output.push({ id: 'workflow_items', name: this.translate.instant('auditTrail.locations.workflow_items') });
      output.push({
        id: 'field_version_items',
        name: this.translate.instant('auditTrail.locations.field_version_items'),
      });
      output.push({ id: 'logbook_versions', name: this.translate.instant('auditTrail.locations.logbook_versions') });
      output.push({ id: 'form_versions', name: this.translate.instant('auditTrail.locations.form_versions') });
      output.push({
        id: 'my_tasks_logs_and_form_entries',
        name: this.translate.instant('auditTrail.locations.my_tasks_logs_and_form_entries'),
      });
      output.push({
        id: 'my_tasks_logbook_tasks',
        name: this.translate.instant('auditTrail.locations.my_tasks_logbook_tasks'),
      });
      output.push({
        id: 'my_tasks_form_tasks',
        name: this.translate.instant('auditTrail.locations.my_tasks_form_tasks'),
      });
      output.push({
        id: 'my_tasks_logbook_tasks_archival',
        name: this.translate.instant('auditTrail.locations.my_tasks_logbook_tasks_archival'),
      });
      output.push({
        id: 'my_tasks_form_tasks_archival',
        name: this.translate.instant('auditTrail.locations.my_tasks_form_tasks_archival'),
      });
      output.push({
        id: 'my_tasks_master_data',
        name: this.translate.instant('auditTrail.locations.my_tasks_master_data'),
      });
      output.push({ id: 'my_reports', name: this.translate.instant('auditTrail.locations.form_entries_report') });
    }

    return output;
  }

  public static shallowSnakeCaseToCamelCaseMapper<T>(target: T | T[]): T | T[] | undefined {
    const mappedTarget: T[] = [];

    if (!target) {
      return mappedTarget;
    }

    if (_.isPlainObject(target) && target instanceof Object) {
      return _.mapKeys(target, (_value: any, key: string) => _.camelCase(key)) as unknown as T;
    }

    if (!Array.isArray(target)) {
      return;
    }

    target.forEach((user: T) =>
      mappedTarget.push(_.mapKeys(user as Object, (_value: any, key: string) => _.camelCase(key)) as unknown as T),
    );

    return mappedTarget;
  }

  public static formatDateTimeWithLineBreak(
    dateTime: string | moment.Moment,
    dateFormat?: string,
    timeFormat?: string,
  ): string {
    const userDateFormat: string = dateFormat ?? HelperService.userDateFormat;
    const userTimeFormat: string = timeFormat ?? HelperService.userTimeFormat;
    let datePart: string = '';
    let timePart: string = '';

    if (this.userTimeZone) {
      if (moment(dateTime).tz(this.userTimeZone).isValid()) {
        datePart = moment(dateTime).tz(this.userTimeZone).format(userDateFormat);
      }

      if (moment(dateTime).tz(this.userTimeZone).isValid()) {
        timePart = moment(dateTime).tz(this.userTimeZone).format(userTimeFormat);
      }
      return `${datePart} \n ${timePart}`;
    }

    if (moment(dateTime).isValid()) {
      datePart = moment(dateTime).format(userDateFormat);
    }

    if (moment(dateTime).isValid()) {
      timePart = moment(dateTime).format(userTimeFormat);
    }
    return `${datePart} \n ${timePart}`;
  }

  public formatDateTimeTz(
    dateTime: string | null,
    now: boolean = false,
    timezone: string = this.timezone$ ?? 'utc',
  ): string | null {
    if (now) {
      return moment().tz(timezone).format(HelperService.userDateTimeFormat);
    }

    if (!dateTime) {
      return null;
    }

    return moment(dateTime).tz(timezone).format(HelperService.userDateTimeFormat);
  }

  public traverseFormIoComponents(components: ExtendedComponentSchema[], propertyKey: formIoTraverseKeys): number {
    let criticalFieldCounter = 0;
    components.forEach((component: any) => {
      if (component.hasOwnProperty('columns')) {
        criticalFieldCounter += this.traverseFormIoComponents(component.columns, propertyKey);
      }

      if (component.hasOwnProperty('components')) {
        criticalFieldCounter += this.traverseFormIoComponents(component.components, propertyKey);
      }
      if (propertyKey === 'criticalField') {
        criticalFieldCounter += Number(Boolean(JSON.parse(component.properties?.criticalField ?? 'false')));
      } else if (propertyKey === 'isConformative' && component.properties?.hasOwnProperty(propertyKey)) {
        criticalFieldCounter += Number(
          Boolean(JSON.parse(component.properties[propertyKey] === 'true' ? 'false' : 'true' ?? 'false')),
        );
      }
    });

    return criticalFieldCounter;
  }

  public getFormIoComponentsKeys(
    componentSchema: IFormIoDefaultComponentSchema | IFormIoTableComponentSchema,
    formIoComponentKeyIds: IFormIoComponentKeyId[] = [],
  ): IFormIoComponentKeyId[] {
    let newComponentSchema: IFormIoDefaultComponentSchema | IFormIoTableComponentSchema;

    if (componentSchema.isTableRows) {
      componentSchema.components.forEach((components: ExtendedComponentSchema[]): void => {
        newComponentSchema = { components, isTableRows: false };

        this.getFormIoComponentsKeys(newComponentSchema, formIoComponentKeyIds);
      });

      return formIoComponentKeyIds;
    }

    componentSchema.components.forEach((component: ExtendedComponentSchema): void => {
      if (component.hasOwnProperty('columns')) {
        newComponentSchema = { components: component['columns'], isTableRows: false };

        this.getFormIoComponentsKeys(newComponentSchema, formIoComponentKeyIds);
      }

      if (component.hasOwnProperty('components')) {
        newComponentSchema = { components: component['components'], isTableRows: false };

        this.getFormIoComponentsKeys(newComponentSchema, formIoComponentKeyIds);
      }

      if (component.hasOwnProperty('rows') && (component.type as TFormIoComponentKey) === 'table') {
        newComponentSchema = { components: component['rows'], isTableRows: true };

        this.getFormIoComponentsKeys(newComponentSchema, formIoComponentKeyIds);
      }

      if (component.hasOwnProperty('key')) {
        if (!component.key) {
          return;
        }

        formIoComponentKeyIds.push({
          id: component['id'],
          key: component.key,
          type: component.type as TFormIoComponentKey,
          label: component['label'],
        });
      }
    });

    return formIoComponentKeyIds;
  }

  public isFormIoLayoutComponent(component: IFormIoComponentKeyId): boolean {
    return component.type ? formIoLayoutComponentTypes.includes(component.type) : false;
  }

  public isFormIoNonFieldAreaComponent(component: IFormIoComponentKeyId): boolean {
    return component.type ? formIoNonFieldAreaComponentTypes.includes(component.type) : false;
  }

  public saveData(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    try {
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch {}
    a.remove();
  }

  public setPageConfigurationDisabledStatus(isDisabled: boolean): void {
    HelperService.disablePageConfigurationSubject.next(isDisabled);
  }

  public setUserDefinedFieldsInputModels(field: any, timezone: string): any | undefined {
    if (
      field?.inputModel &&
      typeof field.inputModel === 'object' &&
      field?.inputModel?.hasOwnProperty('startDate') &&
      field?.inputModel?.hasOwnProperty('endDate')
    ) {
      field.inputModel = {
        startDate: moment(field.inputModel.startDate).tz(timezone, true).format(mysqlTimestampFormat),
        endDate: moment(field.inputModel.startDate).tz(timezone, true).format(mysqlTimestampFormat),
      };
    }

    return field?.inputModel;
  }

  public assignInputModel(item: IFieldSetMeta, fieldValue: any, timezone: string) {
    if (item.type === 'Datetime') {
      item.inputModel = {
        startDate: moment(fieldValue['inputModel'].startDate).tz(timezone),
        endDate: moment(fieldValue['inputModel'].startDate).tz(timezone),
      };
    } else {
      item.inputModel = fieldValue['inputModel'];
    }
  }

  public setTimezoneToIssueDates(
    formInstance: IFormForm | ILogbookForm | IFormMasterDataForm | ILogbookMasterDataForm,
    timezone: string,
  ): IScwMatDatepickerReturn | null {
    if (
      formInstance.issuedDate.value?.startDate &&
      formInstance.issuedDate.value?.endDate &&
      formInstance.issuedDate.value?.startDate.isUTC()
    ) {
      formInstance.issuedDate.value = {
        startDate: moment(formInstance.issuedDate.value.startDate).tz(timezone).startOf('day'),
        endDate: moment(formInstance.issuedDate.value.startDate).tz(timezone).endOf('day'),
      };
    }

    return formInstance.issuedDate.value;
  }

  public setTimezoneToToday(
    formInstance: IFormForm | ILogbookForm | IFormMasterDataForm | ILogbookMasterDataForm,
    timezone: string,
  ): IScwMatDatepickerReturn | null {
    formInstance.issuedDate.value = {
      startDate: moment().tz(timezone).set('hours', 0).set('minutes', 0).set('seconds', 0),
      endDate: moment().tz(timezone).set('hours', 23).set('minutes', 59).set('seconds', 59),
    };

    return formInstance.issuedDate.value;
  }

  public dateIsAfter(date: moment.Moment | null | undefined, dateSecond: moment.Moment | null | undefined): boolean {
    const checkParameter: StartOf[] = ['year', 'month', 'day', 'hours', 'minutes'];
    let checkResult: boolean = false;
    checkParameter.forEach((param: StartOf) => {
      if (dateSecond && date?.isAfter(dateSecond, param)) {
        checkResult = true;
        return;
      }

      if (dateSecond && date?.isBefore(dateSecond, param)) {
        checkResult = false;
        return;
      }
    });
    return checkResult;
  }

  public compareOldNewMasterData(
    oldMasterData: ILogbookMasterDataDetail | IFormMasterDataDetail | null,
    activeMasterDataData: ILogbookMasterDataDetail | IFormMasterDataDetail,
    targetItem: ILogbookVersion | IFormVersionSettings | null,
  ): IModalContent[] {
    const modalContent: IModalContent[] = ComponentUtilities.generateUserDefinedModalContent(
      _.cloneDeep(activeMasterDataData?.fields),
    );

    if (!oldMasterData) {
      return [];
    }

    if (!oldMasterData.fields || !activeMasterDataData?.fields) {
      return [];
    }

    oldMasterData.fields.forEach((oldItem: ILogbookMasterDataFields) => {
      // @ts-ignore
      const commonItem = this.activeMasterDataData$?.fields.filter(
        (newItem: any) => newItem.meta['type'] === oldItem.meta['type'] && newItem.name === oldItem.name,
      )[0];
      if (commonItem) {
        const newItem = modalContent.filter(
          (item: IModalContent) => item.name === commonItem.name && item.type === commonItem.meta['type'],
        )[0];
        const targetItemIndex = _.findIndex(targetItem?.fieldMeta, { fieldId: newItem.fieldId });
        const modalContentIndex = _.findIndex(modalContent, { fieldId: newItem.fieldId });
        if (targetItemIndex !== -1 && modalContentIndex !== -1) {
          modalContent[modalContentIndex] = {
            ...modalContent[modalContentIndex],
            inputModel: targetItem?.fieldMeta[targetItemIndex].inputModel,
          };
        }
      }
    });
    return modalContent;
  }

  public isDuplicateFieldNames(masterDataArray: any): boolean {
    const fieldNames: string[] = masterDataArray.map((item: { name: string }) => {
      return item.name;
    });
    const isDuplicate: boolean = fieldNames.some((item: string, idx: number) => {
      return fieldNames.indexOf(item) !== idx;
    });

    if (isDuplicate) {
      this.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('settings.masterData.duplicateFieldNameError'),
      );
    }

    return isDuplicate;
  }

  public static isReasonObsoletedAutomatically(approvalStepPosition: number): boolean {
    const automaticallyObsoletedStatuses: EApprovalStatuses[] = [
      EApprovalStatuses.FLAGGED_TO_BE_OBSOLETE,
      EApprovalStatuses.APPROVED,
      EApprovalStatuses.COMPLETED,
    ];

    return automaticallyObsoletedStatuses.indexOf(approvalStepPosition) !== -1;
  }

  public removeInactiveForms(selectedForms: number[], formData$: IForm[]): ISelect<number, string>[] {
    let selectedFormInput: ISelect<number, string>[];

    selectedFormInput = formData$.filter((formData) => {
      if (selectedForms.indexOf(formData.id) !== -1) {
        return formData;
      }

      return null;
    });

    return selectedFormInput;
  }

  public getFormNameWithArchiveLabel(selectedForm: any): any {
    const archiveFormLabel: string = this.translate.instant('home.logbook.archived');

    return selectedForm?.map((item: any) => {
      return {
        ...item,
        name: item.isArchived ? `${item.name} (${archiveFormLabel})` : item.name,
      };
    });
  }

  public getNameWithArchiveLabel(isArchived: boolean, name: string): string {
    return isArchived ? `${name} (${this.translate.instant('general.archived')})` : name;
  }

  public getFormIoMultipleValues(input: string[]): string {
    return _.isNil(input)
      ? ''
      : input
          .filter((value: string): boolean => !!value)
          .reduce((previousValue: string, currentValue: string, currentIndex: number): string => {
            return !currentValue ? previousValue : `${currentValue}${currentIndex === 0 ? '' : ', '}${previousValue}`;
          }, '');
  }

  public formatWorkflowDataList(workflowData: IWorkflow[]): IWorkflow[] {
    return _.orderBy(
      _.cloneDeep(
        workflowData.map((workflowData: IWorkflow): IWorkflow => {
          return workflowData.id === noApprovalRequiredDbId
            ? { ...workflowData, name: this.translate.instant('general.workflowNoStepName') }
            : workflowData;
        }),
      ),
      [(workflow: IWorkflow) => workflow.name.toLowerCase()],
    );
  }

  public getNoApprovalRequiredWorkflowName(workflowItem: Partial<IWorkflow>): string {
    return workflowItem.id === noApprovalRequiredDbId
      ? this.translate.instant('general.workflowNoStepName')
      : workflowItem.name;
  }

  public getFormIoFileWithURL(formMeta: any, url: string): IFormIoFile | undefined {
    if (!formMeta) {
      return undefined;
    }

    for (const key of Object.keys(formMeta)) {
      if (formMeta[key][0]?.url) {
        const replacedURL: string = (formMeta[key][0].url as string).replace(/ /g, '%20');

        if (replacedURL === url) {
          return formMeta[key][0];
        }
      }
    }

    return undefined;
  }
}
