import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ObjectActions from './advanced-filter.actions';
import * as logbookAppReducer from '../logbook.reducer';
import * as AppActions from '../../store/app/actions';
import { AdvancedFilterService } from '../../shared/component/filter/advanced-filter/advanced-filter.service';
import { IConfig, User } from '../user/model';
import { of } from 'rxjs';
import { IFilter } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import * as _ from 'lodash';
import { emptyUserMeta } from '../../../constants';

@Injectable()
export class AdvancedFilterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  public saveFilterConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_FILTER_CONFIG),
      switchMap((objectData: ObjectActions.SaveFilterConfig) => {
        const normalizedFilters: IFilter[] = _.cloneDeep(objectData).filters.map(
          (filter: IFilter): IFilter => ({
            ...filter,
            operators: undefined,
            value: this.advancedFilterService.prepareValue(filter),
          }),
        );
        let userConfig!: IConfig | null;

        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            userConfig = { ..._.cloneDeep(emptyUserMeta), ..._.cloneDeep(state.configuration) };
          });
        Object.assign(userConfig?.filterConfig, { [objectData.pageName]: normalizedFilters });

        return this.advancedFilterService.setAsDefault(userConfig).pipe(
          switchMap((response: any) => {
            return of(new ObjectActions.SaveFilterConfigCompleted(response), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
