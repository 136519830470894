import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  EManualLogActionType,
  EManualLogObjectContentModel,
  ERights,
  IAllowedDomain,
  TManualLogLocation,
} from './main.model';
import { IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { TEmpty } from '../../shared/model/interface/generic.model';
import { IGetCurrentUserResponse } from '../app/model';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private readonly ROUTES = {
    ALLOWED_DOMAINS: '/allowed-domains/',
    GET_CUSTOM_MAIL_GROUPS: '/notifications/mail-groups',
    CHECK_USER_RIGHT: '/users/right',
    MANUAL_LOG: '/logs/manual',
    CHANGE_UPDATING_MANUAL_LOG: '/logs/manual/change-updating-log',
    GET_CURRENT_USER: '/users/get-current-user',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getAllowedDomains(params?: HttpParams): Observable<IGetManyResponse<IAllowedDomain>> {
    return this.http.get<IGetManyResponse<IAllowedDomain>>(`${this.baseUrl}${this.ROUTES.ALLOWED_DOMAINS}`, {
      params,
    });
  }

  public checkUserRight(right: ERights, issuer: IIssuer): Observable<IResponse<any>> {
    const params: HttpParams = new HttpParams().append('right', right);
    return this.http.get<IResponse<any>>(`${this.baseUrl}${this.ROUTES.CHECK_USER_RIGHT}`, {
      params,
      headers: ServiceUtilities.makeBasicAuthHeaderFromIssuer(issuer),
    });
  }

  public createManualLog(
    actionType: EManualLogActionType,
    contentModel: EManualLogObjectContentModel,
    objectId?: number | undefined,
    location?: TManualLogLocation | undefined,
    issuer?: IIssuer | TEmpty,
    reason?: string | TEmpty,
  ): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`${this.baseUrl}${this.ROUTES.MANUAL_LOG}`, {
      payload: {
        actionType,
        contentModel,
        objectId,
        location,
        issuer,
        reason,
      },
    });
  }

  public changeUpdatingManualLog(
    actionType: EManualLogActionType,
    logId: number,
    issuer: IIssuer | TEmpty,
  ): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.baseUrl}${this.ROUTES.CHANGE_UPDATING_MANUAL_LOG}/${logId}`, {
      issuer,
      payload: {
        actionType,
      },
    });
  }

  public getAuthorizedPages(action: string): Observable<IGetCurrentUserResponse> {
    return this.http.get<IGetCurrentUserResponse>(`${this.baseUrl}${this.ROUTES.GET_CURRENT_USER}`, {
      params: { action },
    });
  }
}
