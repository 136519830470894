<ng-template [ngIf]="isSystemUser" [ngIfElse]="else1">
  <div class="col-12 p-0">{{ signatureDetails?.firstName }} {{ signatureDetails?.lastName }}</div>
</ng-template>
<ng-template #else1>
  <div class="col-12 signature">
    <div class="row d-block">
      <span class="font-weight"> {{ 'workflow.digitallySignedBy' | translate }}:
      &nbsp;
      </span>
      {{ signatureDetails?.firstName }} {{ signatureDetails?.lastName }}<div class="hiddenForDocument"><br></div>
    </div>
    <div class="row d-block">
      <span class="font-weight"> {{ 'general.modal.label.title' | translate }}:
      &nbsp;</span
      >{{ signatureDetails?.title }}<div class="hiddenForDocument"><br></div>
    </div>
    <div class="row d-block">
      <span class="font-weight"> {{ 'general.modal.label.email' | translate }}:
        &nbsp;
      </span
      >{{ signatureDetails?.email }}<div class="hiddenForDocument"><br></div>
    </div>
    <div class="row d-block">
      <span class="font-weight"> {{ 'general.dateLabel' | translate }}:
        &nbsp;
      </span>{{ createdAtTime }}
    </div>
  </div>
</ng-template>
