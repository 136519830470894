import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPageHeader } from './page-header.model';

@Injectable()
export class PageHeaderService implements OnDestroy {
  public pageHeader: BehaviorSubject<IPageHeader> = new BehaviorSubject<IPageHeader>({
    icon: undefined,
    titleKey: undefined,
    title: undefined,
    fullScreenButton: false,
    fullScreenTargetElementId: undefined,
  });

  setHeader(pageHeader: IPageHeader): void {
    this.pageHeader.next(pageHeader);
  }


  ngOnDestroy(): void {
    this.pageHeader.unsubscribe();
  }
}
