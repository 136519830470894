import { defaultEnvironmentConfigs } from './environment.defaults';

export const environment = {
  ...defaultEnvironmentConfigs,
  ssoEnv: 'TEST',
  apiUrl: 'https://test-scwai-logbook-api.scw.ai',
  ssoUrl: 'https://test-scwai-logbook-api.scw.ai',
  appInsights: {
    instrumentationKey: 'b333e547-acc9-4ca1-ad70-610a395e32eb',
    cloudRole: 'logbook-angular-test-scwai',
  },
};
