import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { Cell, Column, DataValidation, Row, Style, ValueType, Workbook, Worksheet } from 'exceljs';
import { mysqlTimestampFormat } from '../../helper/date';
import { IBulkResponseData } from '../../model/interface/crud-response-interface.model';
import { HelperService } from '../helper.service';
import { Subject, takeUntil } from 'rxjs';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../model/enum/excel-date-format';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ISelect } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IGenericObject } from '../../model/interface/generic.model';

/**
 * @deprecated This constant is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export const EXCEL_DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

/**
 * @deprecated This constant is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export const EXCEL_DEFAULT_TIME_FORMAT = 'HH:MM';

/**
 * @deprecated This constant is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export const CELL_ADDRESS_CONSTANT = '$_CELLADDRESS_$';

/**
 * @deprecated This constant is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export const twoDigitNumberFormat = '0.00############################';

/**
 * @deprecated This enum is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export enum CellTypes {
  LIST = 'list',
  WHOLE = 'whole',
  DECIMAL = 'decimal',
  DATE = 'date',
  TEXTLENGTH = 'textLength',
  CUSTOM = 'custom',
  READONLY = 'readOnly',
}

/**
 * @deprecated This enum is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export enum EExcelSheetState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  VERY_HIDDEN = 'veryHidden',
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IExcelDropdownSettings {
  data: any[] | null;
  prop: string;
  dataProperty: string;
  dataId: string;
  sheetName?: string;
  primaryKeyColumnWidth?: number;
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IExcelColumnDefinition extends Partial<Column> {
  header: string;
  key: string;
  width: number;
  type: ValueType;
  dataValidation: Partial<DataValidation> & Pick<DataValidation, 'type'>;
  dropdownOptions?: IExcelDropdownSettings;
  hidden?: boolean;
  style?: Partial<Style>;
  maxLength?: number;
  allowPunctuation?: boolean;
  number?: number;
  isAdditional?: boolean;
  isDateTimeFormat?: boolean;
  removePropertyIfNull?: boolean;
  isRequired?: boolean;
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface ICreateExcel {
  data?: any[];
  columns: IExcelColumnDefinition[];
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IExcelColumnKeys {
  [header: string]: {
    key: string;
    type: ValueType;
    dataValidationType: CellTypes;
    isDateTimeFormat?: boolean;
    removePropertyIfNull?: boolean;
  };
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IDownloadExcelFilters {
  siteId: number;
  selectedDownloadOffset: string | null;
  limit: number;
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IDataWithExcelId {
  excelId: string;
  excelLabel: string;
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface IDependentColumnConfiguration {
  data: IGenericObject<any>[];
  key: string;
  label: string;
  relatesToField?: string;
}

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface EnumeratedDropdownOption {
  id: string;
  name: string;
}

/**
 * @deprecated This constant is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export const EXCEL_FONT_FAMILY = {
  arial: { name: 'Arial Black', value: 2 },
};

/**
 * @deprecated This interface is moved to the {@link /src/app/shared/service/excel/excel-creator.type.ts} property.
 */
export interface ExcelDateFormatInformation {
  timeFormat$: string;
  dateFormat$: string;
  timezone: string;
  locale$?: string;
  dateFormatRaw$?: string;
  dateTimeFormatRaw$?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExcelHelperService {
  constructor(private readonly translate: TranslateService, private readonly store: Store<AppState>) { }

  /**
   * @deprecated This method is deprecated. Use {@link ExcelExporterService#createAndDownload} instead!
   */
  public createExcel(
    sheetTitle: string,
    excelName: string,
    baseParams: ICreateExcel,
    withData: boolean = false,
    timezone: string = 'utc',
    dateFormat: string = EXCEL_DEFAULT_DATE_FORMAT,
    timeFormat: string = EXCEL_DEFAULT_TIME_FORMAT,
    createAsCsvFile = false,
    isDisabledColumnsFirstLine = false,
    addDateTimeFormula: boolean = true,
    excelRowFormatLimit: number = 1001,
    locked: boolean = true,
    masterDataID?: number,
    fixColumnKeyIssues: boolean = false,
  ): Promise<void> {
    if (fixColumnKeyIssues) {
      this.fixColumnKeyIssues(baseParams);
    }

    const params: ICreateExcel = _.cloneDeep(baseParams);
    const workbook: Workbook = new Workbook();
    workbook.creator = 'Supply Chain Wizard';
    workbook.created = moment().tz(timezone).toDate();
    workbook.calcProperties.fullCalcOnLoad = true;
    if (masterDataID) {
      workbook.description = String(masterDataID);
    }

    const sheet: Worksheet = workbook.addWorksheet(sheetTitle, {
      headerFooter: { firstHeader: sheetTitle, firstFooter: sheetTitle },
    });
    const worksheetColumns: Partial<Column>[] = [];
    for (let i: number = 0; i < params.columns.length; i = i + 1) {
      const column: IExcelColumnDefinition = params.columns[i];

      ExcelHelperService.prepareHiddenSheet(column, workbook);
      this.addDateColumnValidation(column, dateFormat, timeFormat, timezone, addDateTimeFormula);

      const worksheetColumn: Partial<Column> = ExcelHelperService.prepareDefaultColumn({ ...column, number: i });
      _.set(worksheetColumn, 'style.protection.locked', false);
      _.set(worksheetColumn, 'protection.locked', false);

      if (_.get(column, 'dataValidation.type', null) === CellTypes.READONLY) {
        _.set(worksheetColumn, 'style.protection.locked', locked);
        _.set(worksheetColumn, 'protection.locked', locked);
        _.set(worksheetColumn, 'style.font.name', EXCEL_FONT_FAMILY.arial.name);
        _.set(worksheetColumn, 'style.font.color', { argb: 'C0C0C0' });
        _.set(worksheetColumn, 'style.font.family', EXCEL_FONT_FAMILY.arial.value);
        _.set(worksheetColumn, 'style.font.size', 10);
      }

      if (column.key === 'id') {
        _.set(worksheetColumn, 'style.protection.locked', locked);
        _.set(worksheetColumn, 'protection.locked', locked);
        _.set(worksheetColumn, 'hidden', locked);
      }

      worksheetColumns.push(worksheetColumn);

      if (_.get(column, 'dataValidation.type', null) === CellTypes.LIST) {
        const newColumn: IExcelColumnDefinition = {
          header: `${column.header} ${this.translate.instant('apiErrorMessages.properties.id')}`,
          key: `${column.key}_ID`,
          width: column.dropdownOptions?.primaryKeyColumnWidth || 10,
          type: ValueType.String,
          alignment: { vertical: 'middle', horizontal: 'center' },
          style: {
            font: {
              name: EXCEL_FONT_FAMILY.arial.name,
              color: { argb: 'C0C0C0' },
              family: EXCEL_FONT_FAMILY.arial.value,
              size: 10,
            },
            protection: {
              locked: locked,
            },
          },
          dataValidation: {
            type: 'custom',
          },
        };
        i = i + 1;
        params.columns.splice(i, 0, { ...newColumn, isAdditional: true });
        worksheetColumns.push(ExcelHelperService.prepareDefaultColumn({ ...newColumn, number: i }));
      }
    }
    if (isDisabledColumnsFirstLine) {
      this.changeOrderOfTheListColumns(params.columns, worksheetColumns);
    }
    sheet.columns = worksheetColumns;

    const headerRow: Row = sheet.getRow(1);
    headerRow.eachCell({ includeEmpty: true }, (cell: Cell) => {
      cell.protection = {
        locked: locked,
      };

      const key = sheet.getColumn(cell.col).key;

      if (_.find(params.columns, { key })?.isRequired) {
        cell.note = this.translate.instant('scwMatForm.validation.required');
      }
    });

    for (let i: number = 2; i <= excelRowFormatLimit; i = i + 1) {
      const row: Row = sheet.getRow(i);
      const dataIndex = i - 2;

      params.columns.forEach((column: IExcelColumnDefinition, index: number) => {
        if (column.isAdditional) {
          return;
        }
        const cell: Cell = row.getCell(index + 1);
        cell.addName(column.key);

        const additionalColumn = ExcelHelperService.addListColumnValidation(column, cell);
        if (Object.keys(additionalColumn).length === 2) {
          const additionalCell = sheet.getCell(additionalColumn['columnLetter']);
          additionalCell.value = {
            formula: additionalColumn['formula'],
            date1904: false,
          };
          if (
            _.get(column, 'dataValidation.type', null) === CellTypes.LIST &&
            withData &&
            params.data?.length &&
            params.data[dataIndex] &&
            column.dropdownOptions?.dataId
          ) {
            _.set(params.data[dataIndex], `${column.key}_ID`, {
              formula: _.get(additionalColumn, 'formula'),
              value: _.get(params.data[dataIndex], column.dropdownOptions.dataId, null),
              result: _.get(params.data[dataIndex], column.dropdownOptions.dataId, null),
              date1904: false,
            });
          }
        }

        const formulae = [];
        if (column.dataValidation.formulae?.length) {
          const re = /\$_CELLADDRESS_\$/gi;
          for (const formula of column.dataValidation.formulae) {
            if (typeof formula === 'string' && formula) {
              formulae.push(`${formula.replace(re, cell.address)}`);
            }
          }
        }

        if (column.type === ValueType.Number) {
          column.dataValidation.type = CellTypes.DECIMAL;
        }

        _.set(cell, 'dataValidation', { ...column.dataValidation, ...{ formulae } });

        if (column.type === ValueType.Date && dateFormat) {
          const sheetColumn: Partial<Column> = sheet.getColumn(cell.col);
          sheetColumn.numFmt = '@';
          _.set(sheetColumn, 'style.numFmt', '@');
          cell.style.numFmt = '@';
          cell.style.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        if (withData && params.data?.length && params.data[dataIndex]) {
          ExcelHelperService.prepareDropdownColumnData(column, params.data[dataIndex], dateFormat, timeFormat);
        }
      });

      row.commit();
      if (withData && params.data?.length && params.data[dataIndex]) {
        sheet.insertRow(i, params.data[dataIndex]).commit();
      }
    }

    const excelRowFormatLatestLimit = excelRowFormatLimit + 1;
    const latestRow = sheet.getRow(excelRowFormatLatestLimit);
    if (latestRow !== undefined) {
      latestRow.values = [];
    }

    const protectOptions = {
      selectLockedCells: false,
      selectUnlockedCells: true,
      insertRows: true,
      spinCount: excelRowFormatLatestLimit,
    };

    if (locked) {
      sheet.protect('', protectOptions);
    }

    return new Promise((resolve, reject) => {
      if (createAsCsvFile) {
        workbook.csv.writeBuffer().then((data: any) => {
          try {
            const blob: Blob = new Blob([data], {
              type: 'text/csv',
            });
            fs.saveAs(blob, `${excelName}.csv`);
            resolve();
          } catch (e) {
            reject();
          }
        });
      } else {
        workbook.xlsx.writeBuffer().then((data: any) => {
          try {
            const blob: Blob = new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            fs.saveAs(blob, `${excelName}.xlsx`);
            resolve();
          } catch (e) {
            reject();
          }
        });
      }
    });
  }

  private static prepareDefaultColumn(column: IExcelColumnDefinition): Partial<Column> {
    return {
      collapsed: _.get(column, 'collapsed', false),
      defn: _.get(column, 'defn', undefined),
      headerCount: _.get(column, 'headerCount', 0),
      headers: _.get(column, 'headers', []),
      isCustomWidth: _.get(column, 'isCustomWidth', false),
      isDefault: _.get(column, 'isDefault', false),
      letter: _.get(column, 'letter', ''),
      number: _.get(column, 'number', 0),
      header: _.get(column, 'header', ''),
      key: _.get(column, 'key', ''),
      width: _.get(column, 'width', 30),
      outlineLevel: _.get(column, 'outlineLevel', 0),
      hidden: _.get(column, 'hidden', false),
      style: _.get(column, 'style', { font: { bold: false, size: 11 }, protection: { locked: false } }),
      border: _.get(column, 'border', undefined),
      fill: _.get(column, 'fill', undefined),
      numFmt: _.get(column, 'numFmt', undefined),
      font: _.get(column, 'font', undefined),
      alignment: _.get(column, 'alignment', undefined),
      protection: _.get(column, 'protection', { locked: false }),
    };
  }

  private static prepareHiddenSheet(column: IExcelColumnDefinition, workbook: Workbook): void {
    if (column.dropdownOptions !== undefined && _.isArray(column.dropdownOptions.data)) {
      const newSheetName: string = column.key.slice(0, 20);
      _.set(column.dropdownOptions, 'sheetName', newSheetName);
      const newSheet: Worksheet = workbook.addWorksheet(newSheetName, {
        headerFooter: { firstHeader: newSheetName, firstFooter: newSheetName },
      });
      const newSheetColumns: Partial<IExcelColumnDefinition>[] = [];

      let property: string;
      let id: string = 'id';

      switch (column.dropdownOptions.dataProperty) {
        case 'siteName':
          property = 'name';
          break;
        case 'productId':
          property = 'productId';
          break;
        case 'scheduledLineTitle':
          property = 'title';
          break;
        default:
          const columnPropertyContent: string[] = column.dropdownOptions.dataProperty.split('.');
          const columnIdContent: string[] = column.dropdownOptions.dataId.split('.');
          property = columnPropertyContent[columnPropertyContent.length - 1];
          id = columnIdContent[columnIdContent.length - 1];
      }

      newSheetColumns.push(
        {
          header: property,
          key: property,
          type: ValueType.String,
          style: { numFmt: '@' },
        },
        {
          header: id,
          key: id,
          type: ValueType.String,
          style: { numFmt: '@' },
        },
      );

      newSheet.columns = newSheetColumns;
      newSheet.state = EExcelSheetState.VERY_HIDDEN;

      column.dropdownOptions.data.forEach((item) => {
        const addItem = {};
        _.set(addItem, property, _.get(item, property, null));
        _.set(addItem, id, _.get(item, id, null));
        newSheet.addRow(addItem).commit();
      });

      if (column.dataValidation !== undefined) {
        column.dataValidation.formulae = [];
      }

      column.dataValidation.type = CellTypes.LIST;

      const dataLength: number = column.dropdownOptions.data.length || 1;

      const dropdownColumn: Partial<Column> = newSheet.getColumn(property);

      column.dataValidation.formulae?.push(
        `${newSheetName}!$${dropdownColumn.letter}$2:$${dropdownColumn.letter}$${dataLength + 1}`,
      );
    }
  }

  private addDateColumnValidation(
    column: IExcelColumnDefinition,
    dateFormat: string,
    timeFormat: string,
    timezone: string,
    addFormula: boolean = true,
  ): void {
    if (column.type !== ValueType.Date) {
      return;
    }

    if (column.dataValidation.formulae?.length === 0 && addFormula) {
      column.dataValidation.type = 'date';
      column.dataValidation.operator = 'between';
      column.dataValidation.formulae = [
        moment('01-01-1970 00:00:00').tz(timezone).toDate().toString(),
        moment().add(5, 'years').tz(timezone).toDate().toString(),
      ];
    }

    const dateColumnFormat: string = column.isDateTimeFormat ? `${dateFormat} ${timeFormat}` : dateFormat;
    const translateKey: string = column.isDateTimeFormat ? 'excel.dateTime' : 'excel.date';
    column.dataValidation.error =
      column.dataValidation.error ??
      this.translate.instant(`${translateKey}.error`, {
        header: column.header,
        format: moment().format(dateColumnFormat),
      });

    column.dataValidation.prompt =
      column.dataValidation.prompt ??
      this.translate.instant(`${translateKey}.prompt`, {
        header: column.header,
        format: moment().format(dateColumnFormat),
      });
  }

  private static prepareDropdownColumnData(
    column: IExcelColumnDefinition,
    data: any,
    dateFormat: string,
    timeFormat: string,
  ): void {
    const dropdownOptions = _.get(column, 'dropdownOptions', undefined);

    if (dropdownOptions && column.type !== ValueType.Date) {
      data[column.key] = _.get(data, dropdownOptions.dataProperty, null);
    }

    if (column.type === ValueType.Date && data) {
      const value = _.get(data, column.key, null);
      let columnValue: string | null = null;

      if (value !== null) {
        const momentDate = moment(value, mysqlTimestampFormat, true);
        columnValue = momentDate.isValid()
          ? momentDate.format(column.isDateTimeFormat ? `${dateFormat} ${timeFormat}` : dateFormat)
          : value;
      }

      data[column.key] = columnValue;
    }
  }

  private static addListColumnValidation(column: IExcelColumnDefinition, cell: Cell): IGenericObject<any> {
    const cellValue = {};
    try {
      const sheetName = _.get(column.dropdownOptions, 'sheetName', null);
      if (sheetName !== null) {
        const workbook = cell.workbook;
        const additionalSheet = workbook.getWorksheet(sheetName);
        const currentSheet = workbook.getWorksheet(cell.worksheet.name);
        const additionalColumn: Partial<Column> = currentSheet.getColumn(`${column.key}_ID`);
        const idColumn: Partial<Column> = additionalSheet.getColumn(
          column.dropdownOptions?.dataId?.split('.')?.[1] ?? 1,
        );
        const value = `IFERROR(VLOOKUP(${cell.address}&"", ${sheetName}!${additionalSheet.dimensions.$range}, ${idColumn.number}, FALSE), "")`;
        _.set(cellValue, 'columnLetter', `${additionalColumn.letter}${cell.row}`);
        _.set(cellValue, 'formula', value);
      }
      return cellValue;
    } catch (e) {
      return cellValue;
    }
  }

  public excelRowToDto<T>(excelRow: any, columnKeys: IExcelColumnKeys, changeOptions: { [key: string]: any }): T {
    let dto = {} as T;

    for (const [key, value] of Object.entries(excelRow)) {
      if (columnKeys[key]) {
        const isListItem: boolean = columnKeys[key].dataValidationType === CellTypes.LIST;
        let cellValue: any = value === 0 ? 0 : value || null;
        const valueResult = _.has(value, 'result');

        if (valueResult) {
          // @ts-ignore
          cellValue = value.result === 0 ? 0 : value.result || null;
        }

        const dateColumnFormat: string = columnKeys[key].isDateTimeFormat
          ? `${changeOptions['dateFormat']} ${changeOptions['timeFormat']}`
          : changeOptions['dateFormat'];

        if (cellValue instanceof Date) {
          cellValue = this.translate.instant('general.invalidDate');
        }

        if (
          columnKeys[key].type === ValueType.Date &&
          moment(cellValue, dateColumnFormat, true).isValid() &&
          !isListItem
        ) {
          cellValue = moment(cellValue, dateColumnFormat).startOf('minute').format(dateColumnFormat);
        }

        if (isListItem) {
          cellValue = _.get(
            excelRow,
            `${key} ${this.translate.instant('apiErrorMessages.properties.id')}.result`,
            cellValue,
          );
        }

        if (columnKeys[key].type === ValueType.String && cellValue !== null && !isListItem) {
          cellValue = String(cellValue).trim() ? String(cellValue) : null;
        }

        if (columnKeys[key].type === ValueType.Number && cellValue !== null && !isNaN(cellValue) && !isListItem) {
          cellValue = Number(cellValue);
        }

        if (columnKeys[key].type === ValueType.Boolean && cellValue !== null) {
          cellValue = HelperService.transformToBoolean(cellValue);
        }

        if (columnKeys[key].removePropertyIfNull && _.isNil(cellValue)) {
          continue;
        }

        dto = Object.assign(dto, {
          [columnKeys[key].key]: cellValue,
        });
      }
    }

    return dto;
  }

  public async getExcelWorkBookFromFile(file: File): Promise<Workbook> {
    const fileBuffer: ArrayBuffer = await new Response(file).arrayBuffer();
    const workbook: Workbook = new Workbook();
    await workbook.xlsx.load(fileBuffer);

    return workbook;
  }

  public getExcelRowsFromWorkSheet<T extends IGenericObject<any>>(
    workSheet: Worksheet,
    columnKeys: IExcelColumnKeys,
    changeOptions: { [key: string]: unknown } = {},
  ): T[] {
    const resultArray: T[] = [];
    let filledRowsHeaders: any[] = [];

    workSheet.eachRow({ includeEmpty: false }, (row: any, rowNumber: number) => {
      if (rowNumber === 1) {
        filledRowsHeaders = row.values as [];
        return;
      }

      const rowObject = _.zipObject(filledRowsHeaders, row.values as []);
      const dto = this.excelRowToDto<T>(rowObject, columnKeys, changeOptions);

      if (!_.isEmpty(_.pickBy(dto, (value: unknown) => value))) {
        resultArray.push(dto);
      }
    });

    return resultArray;
  }

  public getExcelDateFormula(dateFormat: string): string {
    const isDayFirst: boolean = dateFormat === 'DD/MM/YYYY';
    const monthValue: string = isDayFirst ? `MID(${CELL_ADDRESS_CONSTANT},4,2)` : `LEFT(${CELL_ADDRESS_CONSTANT},2)`;
    const dayValue: string = isDayFirst ? `LEFT(${CELL_ADDRESS_CONSTANT},2)` : `MID(${CELL_ADDRESS_CONSTANT},4,2)`;

    const year: string = `1970<VALUE(RIGHT(${CELL_ADDRESS_CONSTANT},4)),VALUE(RIGHT(${CELL_ADDRESS_CONSTANT},4))<2038`;
    const month: string = `0<VALUE(${monthValue}),VALUE(${monthValue})<13`;
    const day: string = `0<VALUE(${dayValue}),VALUE(${dayValue})<32`;

    const dateSeparator: string = `MID(${CELL_ADDRESS_CONSTANT},3,1)="/", MID(${CELL_ADDRESS_CONSTANT},6,1)="/"`;

    return `=IFERROR(AND(${year},${month},${day},${dateSeparator}),FALSE)`;
  }

  public getSheetColumnKeys(columns: IExcelColumnDefinition[]): IExcelColumnKeys {
    let columnKeys: IExcelColumnKeys = {};

    for (const column of columns) {
      columnKeys = Object.assign(columnKeys, {
        [column.header]: {
          key: column.key,
          type: column.type,
          dataValidationType: column.dataValidation.type,
          isDateTimeFormat: column.isDateTimeFormat,
          removePropertyIfNull: column.removePropertyIfNull,
        },
      });
    }

    return columnKeys;
  }

  public mergeBulkResponseWithRequestData<T>(
    response: IBulkResponseData,
    baseRequestData: T[],
  ): (T & { errorMessages?: string })[] {
    const requestData: T[] = _.cloneDeep(baseRequestData);

    return response?.data?.reduce((filtered: (T & { errorMessages?: string })[], res, index) => {
      if (!res.success) {
        filtered.push(
          Object.assign(requestData[index], {
            errorMessages: Array.from(new Set(res.message)).join(', '),
          }),
        );
      }
      return filtered;
    }, []);
  }

  public prepareExcelColumns(columns: IExcelColumnDefinition[], withErrorColumn: boolean = false): void {
    const defaultColumnDataValidationOptions: Partial<DataValidation> = {
      allowBlank: false,
      formulae: [],
      showErrorMessage: true,
      errorStyle: 'Error',
      showInputMessage: true,
      errorTitle: this.translate.instant('excel.column.errorTitle'),
    };

    for (const column of columns) {
      const promptType = column.dropdownOptions ? 'DropDown' : 'Input';

      Object.assign(column.dataValidation, {
        ...defaultColumnDataValidationOptions,
        ...column.dataValidation,
        error: column.dataValidation.error
          ? column.dataValidation.error
          : this.translate.instant('excel.column.error', { field: column.header }),
        prompt: column.dataValidation.prompt
          ? column.dataValidation.prompt
          : this.translate.instant(`excel.column.prompt${promptType}`, { field: column.header }),
        promptTitle: column.dataValidation.promptTitle
          ? column.dataValidation.promptTitle
          : this.translate.instant(`excel.column.prompt${promptType}Title`, { field: column.header }),
      });
    }

    if (withErrorColumn) {
      columns.push({
        header: this.translate.instant('excel.column.errorTitle'),
        key: 'errorMessages',
        width: 255,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          allowBlank: false,
          formulae: [],
          showErrorMessage: false,
          error: this.translate.instant('general.error'),
          errorStyle: 'Error',
          showInputMessage: false,
        },
      });
    }
  }

  public changeOrderOfTheListColumns(columns: Partial<Column>[], worksheetColumns: Partial<Column>[]) {
    let indexOfTheListColumn = 0;
    for (let i = 0; i < worksheetColumns.length; i = i + 1) {
      if ((worksheetColumns[i].key?.indexOf('_ID') ?? 0) > 0) {
        worksheetColumns.splice(indexOfTheListColumn, 0, worksheetColumns.splice(i, 1)[0]);
        columns.splice(indexOfTheListColumn, 0, columns.splice(i, 1)[0]);
        _.set(worksheetColumns[i], 'number', i); // TODO check if this work number is read only property
        i = i + 1;
        indexOfTheListColumn = indexOfTheListColumn + 1;
      }
    }
  }

  public static updateExcelDropdownOptionsWithData<TypeData, TypeOption>(
    excelData: any[],
    dropdownOptions: TypeOption[],
    key: string,
  ): TypeOption[] {
    const optionsFromData: Set<TypeOption> = excelData.reduce((filteredData: Set<TypeOption>, product) => {
      const value = _.get(product, key, null);

      if (value !== null) {
        filteredData.add(value);
      }

      return filteredData;
    }, new Set());

    return _.unionBy(dropdownOptions, Array.from(optionsFromData), 'id');
  }

  public getExcelBooleanDropdownOptions(): ISelect<string, string>[] {
    return [
      { id: String(true), name: this.translate.instant('general.yes') },
      { id: String(false), name: this.translate.instant('general.no') },
    ];
  }

  static generateDependentOptions(
    dependent: IDependentColumnConfiguration,
    related: IDependentColumnConfiguration,
  ): (object & IDataWithExcelId)[] {
    return related.data.reduce((result: (object & IDataWithExcelId)[], relatedOption: IGenericObject<any>) => {
      const dependentItems =
        related.relatesToField === undefined
          ? dependent.data
          : dependent.data.filter((data: IGenericObject<any>) =>
            related.relatesToField === undefined
              ? false
              : relatedOption[related.relatesToField].split(',').includes(String(data[dependent.key])),
          );
      return result.concat(
        dependentItems.map((dependentOption) => {
          return {
            ...dependentOption,
            excelId: `${relatedOption[related.key]}-${dependentOption[dependent.key]}`,
            excelLabel: `${dependentOption[dependent.label]} (${relatedOption[related.label]})`,
          };
        }) as (object & IDataWithExcelId)[],
      );
    }, []);
  }

  public getMultipleCheckInsOptions(): EnumeratedDropdownOption[] {
    return [
      { id: '2', name: this.translate.instant('general.siteDefault') },
      { id: '1', name: this.translate.instant('general.yes') },
      { id: '0', name: this.translate.instant('general.no') },
    ];
  }

  getUserDateFormatInformation(): ExcelDateFormatInformation {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    let timezone: string = 'utc';
    let dateFormat$: string = excelDateFormat['en'];
    let timeFormat$: string = excelTimeFormat['en'];

    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone ?? 'utc';
          if (state.locale && state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            timeFormat$ = excelTimeFormat[state.locale as keyof IExcelDateFormat];
          }
          destroySubject.next(true);
          destroySubject.complete();
        }
      });

    return { timezone, dateFormat$, timeFormat$ };
  }

  public fixColumnKeyIssues(excelOptions: ICreateExcel): void {
    const updatedKeys: Record<string, string> = {};
    const containsNonLetterCharacterRegex: RegExp = new RegExp(/[^a-zA-Z_]+/g);

    excelOptions.columns.forEach((column: IExcelColumnDefinition, index: number) => {
      if (column.key.match(containsNonLetterCharacterRegex) || column.key.length < 2) {
        const uniqueKey: string = `_${index}_${Date.now()}`;
        updatedKeys[column.key] = uniqueKey;
        column.key = uniqueKey;
      }
    });

    const originalKeys: string[] = Object.keys(updatedKeys);

    excelOptions.data?.forEach((row: any) => {
      Object.entries(row).forEach(([originalKey, value]: [string, unknown]) => {
        if (originalKeys.includes(originalKey)) {
          row[updatedKeys[originalKey]] = value;

          delete row[originalKey];
        }
      });
    });
  }
}
