import { Component, OnInit, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import * as ForgotPinActions from '../../../store/forgot-pin/forgot-pin.actions';
import { IAllowedDomain } from '../../../store/main/main.model';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'scw-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss'],
})
export class ForgotPinComponent implements OnInit {
  @ViewChild('forgot_pin') forgotPinModalRef!: ViewChildDecorator;
  constructor(
    private readonly ngbModal: NgbModal,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly storeActions: ActionsSubject,
  ) {}
  private allowedDomains$: IAllowedDomain[] = [];
  private readonly subscriptions: Subscription[] = [];
  public ngbModalRef!: NgbModalRef;
  public email!: string | null;

  public formRules = {
    email: [
      this.helperService.getRequiredFormRule(),
      this.helperService.getMaxLengthFormRule(255),
      {
        email: true,
        message: this.translate.instant('scwMatForm.validation.email'),
      },
      {
        custom: true,
        validator: this.allowedDomainsValidator.bind(this),
        message: this.translate.instant('scwMatForm.validation.emailNotMatchWithAllowedDomains'),
      },
    ],
  };

  ngOnInit(): void {
    this.store.dispatch(new ForgotPinActions.GetAllowedDomains());
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(ForgotPinActions.GET_ALLOWED_DOMAINS_COMPLETED))
        .subscribe((response: ForgotPinActions.GetAllowedDomainsCompleted) => {
          this.allowedDomains$ = response.payload;
        }),
    );
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(ForgotPinActions.FORGOT_PIN_COMPLETED))
        .subscribe((response: ForgotPinActions.ForgotPinCompleted) => {
          this.closeModal();
          this.helperService.showToastMessage(
            response.payload.success,
            this.translate.instant(response.payload.success ? 'general.success' : 'general.failed'),
            response.payload.success
              ? this.translate.instant('forgotPin.successToastMessage')
              : this.translate.instant('general.failed'),
          );
        }),
    );
  }

  public showModal(): void {
    this.ngbModalRef = this.ngbModal.open(this.forgotPinModalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  public closeModal(): void {
    this.email = null;
    this.ngbModalRef?.dismiss();
  }

  public sendMailToAPI(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    if (this.email) {
      this.store.dispatch(new ForgotPinActions.ForgotPin(this.email));
    }
  }

  private allowedDomainsValidator(input: string | number | null): boolean {
    return HelperService.emailAllowedDomainMatch(input as string, this.allowedDomains$);
  }
}
