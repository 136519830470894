import { Inject, Injectable } from '@angular/core';
import {
  CellTypes,
  ICreateExcel,
  ExcelHelperService,
  IExcelColumnDefinition,
} from '../../../shared/service/excel/excel-helper.service';
import {
  IUser,
  IUserExcelContent,
  ILanguageResponse,
  IUserExcel,
  IUserSettings,
  IUserAndRoleRightAssignments,
  IUserTableQuery,
  IUserWithExcelDropdowns,
  IRightAssignmentsAndRights,
  IUserTableHeadersToFieldNamesMap,
  IUserSettingsState,
  IUserShortInfo,
} from './users.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../logbook.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { forkJoin, Observable, Subject, map, takeUntil, take } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../shared/model/enum/excel-date-format';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as UsersActions from './users.actions';
import { HelperService } from '../../../shared/service/helper.service';
import { localeDateObject } from '../../../../constants';
import { IRole } from '../../../view/settings/roles/roles.model';
import { MainService } from '../../main/main.service';
import { IRightAssignment } from '../roles/roles.model';
import { RightsService } from '../rights/rights.service';
import { IRight } from '../rights/rights.model';
import { ISelect } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import { IScope } from '../../scopes/scopes.model';
import { ScopesService } from '../../scopes/scopes.service';
import { LogbooksService } from '../../logbooks/logbooks.service';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { RolesService } from '../roles/roles.service';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { FormsService } from '../../forms/forms.service';
import { ILogbook } from '../../logbooks/logbooks.model';
import { IForm } from '../../forms/forms.model';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { EColumnWidth } from '../../../shared/service/datatable/datatable.model';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';
import { EStaticScopeIDs } from '../../../view/settings/scopes/scopes.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly URLS = {
    USERS: `${this.baseUrl}/users/`,
    USERS_BULK: `${this.baseUrl}/users/bulk`,
    USER_CHANGE_PIN: `${this.baseUrl}/users/change-pin`,
    ROLES: `${this.baseUrl}/roles/`,
    RIGHT_ASSIGNMENTS: `${this.baseUrl}/right-assignments/`,
    RIGHT_ASSIGNMENTS_BULK: `${this.baseUrl}/right-assignments/bulk`,
    USER_VIA_USERNAME: `${this.baseUrl}/users/via-username/`,
  };
  private timezone: string = 'utc';
  private dateFormat$!: string;
  private timeFormat$!: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly commonExcelAttributes: Partial<IExcelColumnDefinition> = {
    type: ValueType.String,
    isRequired: true,
    dataValidation: {
      type: CellTypes.CUSTOM,
      allowBlank: false,
      showErrorMessage: true,
      formulae: [],
      errorStyle: 'Error',
      showInputMessage: true,
    },
  };

  public static readonly userTableHeadersToFieldNamesMap: IUserTableHeadersToFieldNamesMap = {
    firstName: 'firstName',
    lastName: 'lastName',
    title: 'title',
    username: 'username',
    email: 'email',
    details: 'details',
    roleId: 'roleName',
    logbookScopeId: 'logbookScopeName',
    formScopeId: 'formScopeName',
    language: 'languageFormatted',
    timezone: 'timezone',
    decimalSeparator: 'decimalThousandSeparatorFormatted',
    thousandSeparator: 'decimalThousandSeparatorFormatted',
    isAllLogbookScope: 'logbookScopeName',
    isAllFormScope: 'formScopeName',
    locale: 'localeFormatted',
    dateFormat: 'dateFormatFormatted',
    isActive: 'isActive',
    isIpRestrictionEnabled: 'isIpRestrictionEnabled',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly mainService: MainService,
    private readonly translate: TranslateService,
    private readonly scopesService: ScopesService,
    public readonly advancedFilterService: AdvancedFilterService,
    public readonly helperService: HelperService,
    public readonly excelHelperService: ExcelHelperService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    public readonly rolesService: RolesService,
    public readonly logbooksService: LogbooksService,
    public readonly formsService: FormsService,
    public readonly rightsService: RightsService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
          }

          this.timezone = state.timezone ?? 'utc';

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getUsers(params: HttpParams): Observable<IGetManyResponse<IUser>> {
    return this.http.get<IGetManyResponse<IUser>>(this.URLS.USERS, {
      params,
    });
  }

  public addUser(user: IUser, issuer: IIssuer | null): Observable<IBaseOneResponse<IUser>> {
    return this.http.post<IBaseOneResponse<IUser>>(`${this.URLS.USERS}`, {
      issuer,
      payload: user,
    });
  }

  public editUser(
    user: Partial<IUser>,
    issuer: IIssuer | null,
    forceCheckOut?: boolean,
  ): Observable<IBaseOneResponse<IUser>> {
    return this.http.put<IBaseOneResponse<IUser>>(`${this.URLS.USERS}${user.id}`, {
      issuer,
      payload: { ...user, forceUpdate: forceCheckOut },
    });
  }

  public changeUserPin(user: Partial<IUser>, issuer: IIssuer | null): Observable<IBaseOneResponse<IUser>> {
    return this.http.put<IBaseOneResponse<IUser>>(`${this.URLS.USER_CHANGE_PIN}`, {
      issuer,
      payload: user,
    });
  }

  public editUsers(
    users: Partial<IUser>[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IUser>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IUser>>>(`${this.URLS.USERS_BULK}`, {
      issuer: issuerAndReason.issuer,
      reason: issuerAndReason.reason,
      payload: users,
    });
  }

  public getRoles(params: HttpParams): Observable<IGetManyResponse<IRole>> {
    return this.http.get<IGetManyResponse<IRole>>(this.URLS.ROLES, { params });
  }

  public getRightAssignments(params: HttpParams): Observable<IGetManyResponse<IRightAssignment>> {
    return this.http.get<IGetManyResponse<IRightAssignment>>(this.URLS.RIGHT_ASSIGNMENTS, { params });
  }

  public getUserSettingsData(userParams: HttpParams): Observable<IUserSettings> {
    const allStatuses: EApprovalStatuses[] = [
      EApprovalStatuses.SUBMITTED,
      EApprovalStatuses.NOT_SUBMITTED,
      EApprovalStatuses.SEND_BACK,
      EApprovalStatuses.OBSOLETE,
      EApprovalStatuses.APPROVED,
      EApprovalStatuses.COMPLETED,
      EApprovalStatuses.ACTIVE,
      EApprovalStatuses.CANCELED,
      EApprovalStatuses.REJECTED,
      EApprovalStatuses.FLAGGED_TO_BE_OBSOLETE,
    ];

    const params: HttpParams = new HttpParams()
      .append('limit', 5000)
      .append('is_active', true)
      .append('ordering', 'name');

    const formAndLogbookParams: HttpParams = new HttpParams().append('limit', 5000).append('ordering', 'id');

    const logbookParams: HttpParams = formAndLogbookParams
      .append('statuses', JSON.stringify(allStatuses))
      .append('include_archived', 1);

    const observables: any[] = [
      this.getUsers(userParams),
      this.getRoles(params),
      this.scopesService.getScopes(params),
      this.logbooksService.getLogbooks(logbookParams),
      this.formsService.getForms(formAndLogbookParams),
      this.mainService.getAllowedDomains(params),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          ...(UsersService.getAdditionalResponseParameters(responseList?.[0]) as IUserSettings),
          users: _.get(responseList, '0.data', []),
          roles: _.get(responseList, '1.data', []),
          logbookScopes: _.get(responseList, '2.data', []).filter((scope: any) => scope.objectType === 'logbook'),
          formScopes: _.get(responseList, '2.data', []).filter((scope: any) => scope.objectType === 'form'),
          logbooks: _.get(responseList, '3.data', []) as ILogbook[],
          forms: _.get(responseList, '4.data', []) as IForm[],
          allowedDomains: _.get(responseList, '5.data', []),
        };
      }),
    );
  }

  public getPermissions(
    userRightAssignmentParams: HttpParams,
    roleRightAssignmentParams: HttpParams,
    rightParams: HttpParams,
    getUserRightAssignments: boolean,
    getRoleRightAssignments: boolean,
  ): Observable<IUserAndRoleRightAssignments> {
    const observables: any = [this.rightsService.getRights(rightParams)];

    if (getRoleRightAssignments) {
      observables.push(this.getRightAssignments(roleRightAssignmentParams));
    }

    if (getUserRightAssignments) {
      observables.push(this.getRightAssignments(userRightAssignmentParams));
    }

    const userRightAssignmentsIndex: number = getRoleRightAssignments ? 2 : 1;

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          rights: _.get(responseList, '0.data', []) as IRight[],
          roleRightAssignments: getRoleRightAssignments
            ? (_.get(responseList, '1.data', []) as IRightAssignment[])
            : [],
          userRightAssignments: getUserRightAssignments
            ? (_.get(responseList, `${userRightAssignmentsIndex}.data`, []) as IRightAssignment[])
            : [],
        };
      }),
    );
  }

  public getRoleRightsAssignmentsWithRights(
    rightAssignmentParams: HttpParams,
    rightsParams: HttpParams,
  ): Observable<IRightAssignmentsAndRights> {
    const observables: any = [
      this.getRightAssignments(rightAssignmentParams),
      this.rightsService.getRights(rightsParams),
    ];

    return forkJoin(observables).pipe(
      map((responseList) => {
        return {
          roleRightAssignments: _.get(responseList, '0.data', []) as IRightAssignment[],
          rights: _.get(responseList, '1.data', []) as IRight[],
        };
      }),
    );
  }

  public getUserRightsAssignments(params: HttpParams): Observable<IGetManyResponse<IRightAssignment>> {
    return this.http.get<IGetManyResponse<IRightAssignment>>(this.URLS.RIGHT_ASSIGNMENTS, { params });
  }

  public setUserRightsAssignments(
    userRightAssignments: IRightAssignment[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IRightAssignment>> {
    return this.http.put<IGetManyResponse<IRightAssignment>>(this.URLS.RIGHT_ASSIGNMENTS_BULK, {
      issuer: issuerAndReason.issuer,
      reason: issuerAndReason.reason,
      payload: userRightAssignments,
      dontLog: true,
    });
  }

  public getLocaleFormatList(): ISelect<string, string>[] {
    const locales: string[] = Object.keys(localeDateObject);
    const timeFormat: string = 'LTS';
    let output: ISelect<string, string>[] = [];

    for (const locale of locales) {
      const options: ISelect<string, string>[] = [
        moment().locale(locale).localeData().longDateFormat('ll'),
        moment().locale(locale).localeData().longDateFormat('L'),
      ].map((format: string) => {
        const formatAndTimeFormat: string = `${format} ${timeFormat}`;

        return {
          id: `${locale}_${format}`,
          name: `${localeDateObject[locale as keyof typeof localeDateObject]} - ${moment()
            .locale(locale)
            .format(formatAndTimeFormat)}`,
        };
      });

      output = output.concat(options);
    }

    return output;
  }

  private getUserObservables(withData: boolean, data?: IUser[]) {
    const params: HttpParams = new HttpParams().append('limit', 5000);
    const observables: Observable<any | ILanguageResponse | IGetManyResponse<IUser>>[] = [
      this.getRoles(params),
      this.scopesService.getScopes(params),
    ];

    if (!withData || data) {
      return observables;
    }

    let usersTableQuery!: IUserTableQuery | null;
    this.store
      .select('userSettingsStore')
      .pipe(take(1))
      .subscribe((store: IUserSettingsState) => {
        usersTableQuery = store.filters;
      });

    if (!usersTableQuery) {
      return observables;
    }

    let userParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest<ITableQuery>({
      page: 1,
      rowsPerPage: 5000,
      sort: usersTableQuery.sort,
      ...(usersTableQuery.search && { search: usersTableQuery.search }),
      ...(usersTableQuery.isActive?.length && {
        isActive: Boolean(usersTableQuery.isActive[0]),
      }),
    });

    if (!_.isEmpty(usersTableQuery.advancedFilter)) {
      userParams = this.advancedFilterService.getSearchString(usersTableQuery, userParams);
    }

    observables.push(this.getUsers(userParams));

    return observables;
  }

  public async downloadUserExcel(
    withData: boolean,
    _filters: IUserTableQuery | null,
    selectedColumns: string[] | null,
    withErrorColumn: boolean = false,
    data?: IUserWithExcelDropdowns[],
  ): Promise<void> {
    forkJoin(this.getUserObservables(withData, data)).subscribe((responseList) => {
      const allRoles: IRole[] = _.get(responseList, '0.data', []);
      const roles: IRole[] = allRoles.filter((item: IRole) => item.isActive);
      const logbookScopes: ISelect<string | number, string>[] = _.get(responseList, '1.data', []).filter(
        (scope: IScope) => scope.objectType === 'logbook',
      );
      const formScopes: ISelect<string | number, string>[] = _.get(responseList, '1.data', []).filter(
        (scope: IScope) => scope.objectType === 'form',
      );
      const allLogbookScopeIndex: number = _.findIndex(logbookScopes, { id: EStaticScopeIDs.ALL_LOGBOOKS_SCOPE_ID });
      logbookScopes[allLogbookScopeIndex].name = this.translate.instant('logbookScopes.allLogbooks');
      const allFormScopeIndex: number = _.findIndex(formScopes, { id: EStaticScopeIDs.ALL_FORMS_SCOPE_ID });
      formScopes[allFormScopeIndex].name = this.translate.instant('formScopes.allForms');
      const allLogbookScopeData: ISelect<string | number, string> = logbookScopes.splice(allLogbookScopeIndex, 1)[0];
      logbookScopes.splice(0, 0, allLogbookScopeData);
      const allFormScopeData: ISelect<string | number, string> = formScopes.splice(allFormScopeIndex, 1)[0];
      formScopes.splice(0, 0, allFormScopeData);
      const languages: ISelect<string, string>[] = this.helperService.getLanguages();
      const timezones: ISelect<string, string>[] = this.helperService.getTimezones();
      const localeFormats: ISelect<string, string>[] = this.getLocaleFormatList();
      const separators: ISelect<number, string>[] = this.helperService.getDecimalAndThousandSeparators();
      const statuses: ISelect<string, string>[] = this.getUserActiveInactiveAsBoolean();
      const ipRestrictions: ISelect<string, string>[] = this.getIpRestrictionDropdownAsBoolean();
      const sheetTitle: string = this.translate.instant('pageTitles.users');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: IUserWithExcelDropdowns[] = [];

      if (withData) {
        excelData = _.get(responseList, '2.data', []) as IUserWithExcelDropdowns[];

        if (excelData.length === 0 && data) {
          excelData = _.cloneDeep(data);
        }

        excelData.forEach((user: IUserWithExcelDropdowns) => {
          UsersService.prepareUserDropdown(user, {
            languages,
            statuses,
            ipRestrictions,
            separators,
            localeFormats,
            roles,
            logbookScopes,
            formScopes,
          });
        });
      }

      const excelOptions: ICreateExcel = this.getUserExcelColumns(
        {
          languages,
          timezones,
          localeFormats,
          separators,
          roles,
          statuses,
          logbookScopes,
          formScopes,
          ipRestrictions,
        },
        withErrorColumn,
        selectedColumns,
        withData,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      this.excelHelperService
        .createExcel(
          sheetTitle,
          excelName,
          excelOptions,
          withData,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
          true,
        )
        .then(
          () => {
            if (withData) {
              this.store.dispatch(new UsersActions.DownloadUsersExcelWithDataCompleted(withErrorColumn));
            } else {
              this.store.dispatch(new UsersActions.DownloadUsersExcelCompleted());
            }
          },
          () => {
            this.store.dispatch(new UsersActions.FetchError({}));
          },
        );
    });
  }

  private static prepareUserDropdown(user: IUserWithExcelDropdowns, content: Partial<IUserExcelContent>): void {
    let selectedSeparatorId: number | null = null;

    if (user.decimalSeparator) {
      selectedSeparatorId = user.decimalSeparator === '.' ? 1 : 2;
    }

    UsersService.setForeignKeyDropdowns(user, content);

    user.timezoneDropdown = { id: user.timezone, name: user.timezone };
    user.languageDropdown = ComponentUtilities.findOneOptionForSelect(content.languages ?? [], user.language)?.[0];
    user.localeFormatDropdown = ComponentUtilities.findOneOptionForSelect(
      content.localeFormats ?? [],
      `${user.locale}_${user.dateFormat}`,
    )?.[0];
    user.activeDropdown = {
      id: String(user.isActive),
      name: ComponentUtilities.findOneOptionForSelect(content.statuses ?? [], String(user.isActive))?.[0]?.name,
    };
    user.ipRestrictionDropdown = {
      id: String(user.isIpRestrictionEnabled),
      name: ComponentUtilities.findOneOptionForSelect(content.ipRestrictions ?? [], String(user.isIpRestrictionEnabled))?.[0]?.name,
    };
    user.separatorsDropdown = {
      id: selectedSeparatorId,
      name: ComponentUtilities.findOneOptionForSelect(content.separators ?? [], selectedSeparatorId)?.[0]?.name,
    };
  }

  private static setForeignKeyDropdowns(user: IUserWithExcelDropdowns, content: Partial<IUserExcelContent>) {
    let logbookScopeId: number | string | null = user.logbookScopeId ?? null;
    let formScopeId: number | string | null = user.formScopeId ?? null;

    if (!user.logbookScopeId) {
      logbookScopeId = user.isAllLogbookScope ? EStaticScopeIDs.ALL_LOGBOOKS_SCOPE_ID : null;
    }

    if (!user.formScopeId) {
      formScopeId = user.isAllFormScope ? EStaticScopeIDs.ALL_FORMS_SCOPE_ID : null;
    }

    user.roleDropdown = {
      id: user.roleId ?? 0,
      name: ComponentUtilities.findOneOptionForSelect(content.roles ?? [], user.roleId ?? 0)?.[0]?.name,
    };
    user.logbookScopeDropdown = {
      id: logbookScopeId,
      name: ComponentUtilities.findOneOptionForSelect(content.logbookScopes ?? [], logbookScopeId)?.[0]?.name,
    };
    user.formScopeDropdown = {
      id: formScopeId,
      name: ComponentUtilities.findOneOptionForSelect(content.formScopes ?? [], formScopeId)?.[0]?.name,
    };
  }

  private getUserExcelColumns(
    content: IUserExcelContent,
    withErrorColumn: boolean,
    selectedColumns: string[] | null,
    withData: boolean,
  ): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.firstName'),
          key: 'firstName',
          width: 20,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.lastName'),
          key: 'lastName',
          width: 20,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.title'),
          key: 'title',
          width: 20,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.email'),
          key: 'email',
          width: 35,
        },
        {
          ...(_.cloneDeep(this.commonExcelAttributes) as IExcelColumnDefinition),
          header: this.translate.instant('excel.column.username'),
          key: 'username',
          width: 20,
        },
        {
          header: this.translate.instant('excel.column.password.name'),
          key: 'password',
          width: 15,
          type: ValueType.String,
          style: { numFmt: '@' },
          isRequired: !withData || _.isNil(selectedColumns),
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.column.password.prompt'),
          },
        },
        {
          header: this.translate.instant('excel.column.details'),
          key: 'details',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('excel.column.role'),
          key: 'roleName',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.roles,
            prop: 'roleDropdown.id',
            dataProperty: 'roleDropdown.name',
            dataId: 'roleDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.logbookScope'),
          key: 'logbookScopeName',
          width: 20,
          type: ValueType.String,
          dropdownOptions: {
            data: content.logbookScopes,
            prop: 'logbookScopeDropdown.id',
            dataProperty: 'logbookScopeDropdown.name',
            dataId: 'logbookScopeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.formScope'),
          key: 'formScopeName',
          width: 20,
          type: ValueType.String,
          dropdownOptions: {
            data: content.formScopes,
            prop: 'formScopeDropdown.id',
            dataProperty: 'formScopeDropdown.name',
            dataId: 'formScopeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.language'),
          key: 'languageFormatted',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.languages,
            prop: 'languageDropdown.id',
            dataProperty: 'languageDropdown.name',
            dataId: 'languageDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.timezone'),
          key: 'timezone',
          width: 20,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.timezones,
            prop: 'timezoneDropdown.name',
            dataProperty: 'timezoneDropdown.name',
            dataId: 'timezoneDropdown.name',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.separators'),
          key: 'separators',
          width: 30,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.separators,
            prop: 'separatorsDropdown.id',
            dataProperty: 'separatorsDropdown.name',
            dataId: 'separatorsDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.localeFormat'),
          key: 'localeFormat',
          width: 40,
          type: ValueType.String,
          isRequired: true,
          dropdownOptions: {
            data: content.localeFormats,
            prop: 'localeFormatDropdown.id',
            dataProperty: 'localeFormatDropdown.name',
            dataId: 'localeFormatDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.isActive'),
          key: 'isActive',
          width: 20,
          type: ValueType.Boolean,
          isRequired: true,
          dropdownOptions: {
            data: content.statuses,
            prop: 'activeDropdown.id',
            dataProperty: 'activeDropdown.name',
            dataId: 'activeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.column.enableIpRestriction'),
          key: 'isIpRestrictionEnabled',
          width: 20,
          type: ValueType.Boolean,
          isRequired: true,
          dropdownOptions: {
            data: content.ipRestrictions,
            prop: 'ipRestrictionDropdown.id',
            dataProperty: 'ipRestrictionDropdown.name',
            dataId: 'ipRestrictionDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: 'id',
          key: 'id',
          width: 20,
          type: ValueType.Number,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    if (withData && !_.isNil(selectedColumns)) {
      const selectedColumnsAndIdColumn: string[] = [...selectedColumns, 'id'];
      const separatorsIndex: number = selectedColumnsAndIdColumn.indexOf('decimalThousandSeparatorFormatted');
      const localeIndex: number = selectedColumnsAndIdColumn.indexOf('localeFormatted');
      const dateFormatIndex: number = selectedColumnsAndIdColumn.indexOf('dateFormatFormatted');

      _.remove(selectedColumnsAndIdColumn, (column) => column === 'content');

      if (separatorsIndex !== -1) {
        selectedColumnsAndIdColumn.splice(separatorsIndex, 1, 'separators');
      }

      if (localeIndex !== -1 || dateFormatIndex !== -1) {
        const minIndex: number = _.min([localeIndex, dateFormatIndex]) as number;

        selectedColumnsAndIdColumn.splice(_.max([localeIndex, dateFormatIndex]) as number, 1, 'localeFormat');
        selectedColumnsAndIdColumn.splice(minIndex, minIndex === -1 ? 0 : 1);
      }

      excelColumns.columns = excelColumns.columns
        .filter((column: IExcelColumnDefinition) => selectedColumnsAndIdColumn.includes(column.key))
        .sort(
          (a: IExcelColumnDefinition, b: IExcelColumnDefinition) =>
            selectedColumnsAndIdColumn.indexOf(a.key) - selectedColumnsAndIdColumn.indexOf(b.key),
        );
    }

    this.excelHelperService.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getUsersFromExcel(file: File): Promise<IUserExcel | null> {
    const workbook: Workbook = await this.excelHelperService.getExcelWorkBookFromFile(file);
    const userSheet: Worksheet = workbook.getWorksheet(this.translate.instant('pageTitles.users'));
    const roleDataSheet: Worksheet = workbook.getWorksheet('roleNameDataSheet');
    const logbookScopeDataSheet: Worksheet = workbook.getWorksheet('logbookScopeNameDataSheet');
    const formScopeDataSheet: Worksheet = workbook.getWorksheet('formScopeNameDataSheet');
    const languageDataSheet: Worksheet = workbook.getWorksheet('languageFormattedDataSheet');
    const timezoneDataSheet: Worksheet = workbook.getWorksheet('timezoneDataSheet');
    const separatorsDataSheet: Worksheet = workbook.getWorksheet('separatorsDataSheet');
    const localeFormatDataSheet: Worksheet = workbook.getWorksheet('localeFormatDataSheet');
    const activeDataSheet: Worksheet = workbook.getWorksheet('isActiveDataSheet');
    const isIpRestrictionDataSheet: Worksheet = workbook.getWorksheet('isIpRestrictionDataSheet');

    if (!userSheet) {
      return null;
    }

    const roleData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(roleDataSheet);
    const logbookScopeData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(logbookScopeDataSheet);
    const formScopeData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(formScopeDataSheet);
    const languageData: ISelect<string, string>[] = this.getGenericSheetData<string, string>(languageDataSheet);
    const timezoneData: ISelect<string, string>[] = this.getGenericSheetData<string, string>(timezoneDataSheet);
    const separatorsData: ISelect<string, string>[] = this.getGenericSheetData<string, string>(separatorsDataSheet);
    const localeFormatData: ISelect<string, string>[] = this.getGenericSheetData<string, string>(localeFormatDataSheet);
    const activeData: ISelect<number, string>[] = this.getGenericSheetData<number, string>(activeDataSheet);
    const ipRestrictionData: ISelect<number, string>[] =
      this.getGenericSheetData<number, string>(isIpRestrictionDataSheet);
    const { columns } = this.getUserExcelColumns(
      {
        languages: null,
        timezones: null,
        localeFormats: null,
        separators: null,
        roles: null,
        statuses: null,
        logbookScopes: null,
        formScopes: null,
        ipRestrictions: null,
      },
      false,
      [],
      false,
    );
    const columnKeys = this.excelHelperService.getSheetColumnKeys(columns);

    return {
      roleData,
      logbookScopeData,
      languageData,
      formScopeData,
      timezoneData,
      separatorsData,
      localeFormatData,
      activeData,
      ipRestrictionData,
      userData: {
        users: this.excelHelperService.getExcelRowsFromWorkSheet<IUserWithExcelDropdowns>(userSheet, columnKeys, {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        }),
      },
    };
  }

  private getGenericSheetData<IdType, NameType>(sheet: Worksheet): ISelect<IdType, NameType>[] {
    if (!sheet) {
      return [];
    }

    const genericColumns = {
      id: { key: 'id', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
      name: { key: 'name', type: ValueType.String, dataValidationType: CellTypes.CUSTOM },
    };

    return this.excelHelperService.getExcelRowsFromWorkSheet<ISelect<IdType, NameType>>(sheet, genericColumns);
  }

  public uploadExcel(users: IUser[], issuerAndReason: IIssuerAndReason | null): Observable<IBulkResponseData> {
    return this.http.post<IBulkResponseData>(this.URLS.USERS_BULK, {
      issuer: issuerAndReason?.issuer,
      reason: issuerAndReason?.reason,
      payload: users,
    });
  }

  public static getUserTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: '',
        name: '',
        selected: true,
        sortable: false,
        width: EColumnWidth.checkbox,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'firstName',
        name: translateService.instant('general.datatable.headers.firstName'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'lastName',
        name: translateService.instant('general.datatable.headers.lastName'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'title',
        name: translateService.instant('general.datatable.headers.title'),
        selected: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'username',
        name: translateService.instant('general.datatable.headers.username'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
        disabled: true,
      },
      {
        value: 'email',
        name: translateService.instant('general.datatable.headers.email'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'details',
        name: translateService.instant('general.datatable.headers.details'),
        selected: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'roleName',
        name: translateService.instant('general.datatable.headers.role'),
        selected: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'logbookScopeName',
        name: translateService.instant('general.datatable.headers.logbookScope'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'formScopeName',
        name: translateService.instant('general.datatable.headers.formScope'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'languageFormatted',
        name: translateService.instant('general.datatable.headers.language'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'timezone',
        name: translateService.instant('general.datatable.headers.timezone'),
        selected: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'decimalThousandSeparatorFormatted',
        name: translateService.instant('general.datatable.headers.decimalThousandSeparator'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'localeFormatted',
        name: translateService.instant('general.datatable.headers.locale'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'dateFormatFormatted',
        name: translateService.instant('general.datatable.headers.dateFormat'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'notificationScopeFormatted',
        name: translateService.instant('general.datatable.headers.notificationScope'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
        sortable: false,
      },
      {
        value: 'isActive',
        name: translateService.instant('general.datatable.headers.status'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
      {
        value: 'content',
        name: translateService.instant('general.datatable.headers.content'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
        sortable: false,
      },
      {
        value: 'isIpRestrictionEnabled',
        name: translateService.instant('settings.users.isIpRestrictionEnabled'),
        selected: false,
        sortable: false,
        type: PageConfigurationTypes.TABLE,
        draggable: true,
      },
    ]);
  }

  private static getAdditionalResponseParameters(
    response: IGetManyResponse<unknown>,
  ): Partial<IGetManyResponse<unknown>> {
    return {
      page: _.get(response, 'page', 1),
      pageCount: _.get(response, 'pageCount', 0),
      count: _.get(response, 'count', 0),
      total: _.get(response, 'total', 0),
      success: _.get(response, 'success', true),
      date: _.get(response, 'date', undefined),
      message: _.get(response, 'message', undefined),
    };
  }

  public getUserActiveInactiveAsBoolean(): ISelect<string, string>[] {
    return [
      { id: String(false), name: this.translate.instant('settings.users.disabled') },
      { id: String(true), name: this.translate.instant('general.active') },
    ];
  }

  public getIpRestrictionDropdownAsBoolean(): ISelect<string, string>[] {
    return [
      { id: String(true), name: this.translate.instant('settings.users.clientDefault') },
      { id: String(false), name: this.translate.instant('general.no') },
    ];
  }

  public getUserViaUsername(username: string | null | undefined): Observable<IGetOneResponse<IUserShortInfo>> {
    return this.http.get<IGetOneResponse<IUserShortInfo>>(`${this.URLS.USER_VIA_USERNAME}${username}`);
  }
}
