import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, of } from 'rxjs';
import * as ObjectActions from './rights.actions';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IRight } from './rights.model';
import { RightsService } from './rights.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class RightsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: RightsService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
  ) {}

  getRights = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.RIGHTS_DATA_LOADING),
    switchMap(() => {
      this.store.dispatch(new AppActions.ShowLoader());

      const httpParams = new HttpParams().append('limit', String(1000));

      return this.service.getRights(httpParams).pipe(
        switchMap((response: IGetManyResponse<IRight>) => {
          return of(new ObjectActions.RightsDataLoaded(response), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));
}
