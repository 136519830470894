import { Action } from '@ngrx/store';
import {
  INavigationInformation,
  IAllowedDomain,
  IUserRights,
  ERights,
  EManualLogActionType,
  EManualLogObjectContentModel,
  TManualLogLocation,
} from './main.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';
import { SafeUrl } from '@angular/platform-browser';
import { TEmpty } from '../../shared/model/interface/generic.model';

export const GET_NAVIGATION_MENU = '[Main] Get Navigation Menu';
export const GET_NAVIGATION_MENU_COMPLETED = '[Main] Get Navigation Menu Completed';
export const GET_AUTHORIZED_PAGES = '[Main] Get authorized pages';
export const SET_AUTHORIZED_PAGES = '[Main] Set authorized pages';
export const GET_ALLOWED_DOMAINS = '[Main] Get Allowed Domains';
export const GET_ALLOWED_DOMAINS_COMPLETED = '[Main] Get Allowed Domains Completed';
export const CHECK_PERMISSION = '[Main] Check Permission';
export const CHECK_PERMISSION_COMPLETED = '[Main] Check Permission Completed';
export const FETCH_ERROR = '[Main] Fetch Error';
export const PDF_DOWNLOADED = '[Main] Pdf Downloaded';
export const CREATE_MANUAL_LOG = '[Main] Create Log Without Object';
export const CREATE_MANUAL_LOG_COMPLETED = '[Main] Create Log Without Object Completed';
export const CHANGE_UPDATING_MANUAL_LOG = '[Main] Update Log Without Object';
export const CHANGE_UPDATING_MANUAL_LOG_COMPLETED = '[Main] Update Log Without Object Completed';
export const SET_CLIENT_LOGO_URL = '[Main] Set Client Logo Url';

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class GetNavigationMenu implements Action {
  readonly type = GET_NAVIGATION_MENU;
}

export class GetNavigationMenuCompleted implements Action {
  readonly type = GET_NAVIGATION_MENU_COMPLETED;

  constructor(public data: INavigationInformation, public success: boolean) {}
}

export class GetAllowedDomains implements Action {
  readonly type = GET_ALLOWED_DOMAINS;
}

export class GetAllowedDomainsCompleted implements Action {
  readonly type = GET_ALLOWED_DOMAINS_COMPLETED;

  constructor(public data: IAllowedDomain[]) {}
}

export class GetAuthorizedPages implements Action {
  readonly type = GET_AUTHORIZED_PAGES;
}

export class SetAuthorizedPages implements Action {
  readonly type = SET_AUTHORIZED_PAGES;

  constructor(public data: IUserRights) {}
}

export class CheckPermission implements Action {
  readonly type = CHECK_PERMISSION;

  constructor(public payload: ERights, public issuer: IIssuer | null, public dispatchHideLoader: boolean = true) {}
}

export class CheckPermissionCompleted implements Action {
  readonly type = CHECK_PERMISSION_COMPLETED;

  constructor(public payload: any, public issuer: IIssuer | null) {}
}

export class PdfDownloaded implements Action {
  readonly type = PDF_DOWNLOADED;
}

export class CreateManualLog implements Action {
  readonly type = CREATE_MANUAL_LOG;

  constructor(
    public actionType: EManualLogActionType,
    public contentModel: EManualLogObjectContentModel,
    public objectId?: number,
    public location?: TManualLogLocation,
    public issuer?: IIssuer | null,
    public reason?: string | null,
  ) {}
}

export class CreateManualLogCompleted implements Action {
  readonly type = CREATE_MANUAL_LOG_COMPLETED;

  constructor(public payload: number) {}
}

export class ChangeUpdatingManualLog implements Action {
  readonly type = CHANGE_UPDATING_MANUAL_LOG;

  constructor(public actionType: EManualLogActionType, public logId: number, public issuer: IIssuer | TEmpty) {}
}

export class ChangeUpdatingManualLogCompleted implements Action {
  readonly type = CHANGE_UPDATING_MANUAL_LOG_COMPLETED;

  constructor(public payload: number) {}
}

export class SetClientLogoUrl implements Action {
  readonly type = SET_CLIENT_LOGO_URL;

  constructor(public payload: SafeUrl | null) {}
}

export type MainActions =
  | FetchError
  | GetNavigationMenu
  | GetNavigationMenuCompleted
  | GetAllowedDomains
  | GetAllowedDomainsCompleted
  | GetAuthorizedPages
  | SetAuthorizedPages
  | PdfDownloaded
  | CreateManualLog
  | CreateManualLogCompleted
  | ChangeUpdatingManualLog
  | ChangeUpdatingManualLogCompleted
  | SetClientLogoUrl;
