import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormSubmissionUserAction, ITableHeader } from '../../../../constants.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IFormEntriesData } from './form-entries.model';
import { ILogsFormEntriesColumn } from '../../../view/my-tasks/logs-form-entries/logs-form-entries.component.model';
import { IFormSubmissionData } from '../../my-tasks/logs-form-entries/logs-form-entries.model';
import { IMyReportMeta } from '../my-reports/my-reports.model';

export const START_FORM_ENTRIES_LOADING = '[Form Entries] Start Form Entries Loading';
export const FORM_ENTRIES_LOADED = '[Form Entries] Form Entries Loaded';
export const START_FORM_ENTRIES_RECORD_LOADING = '[Form Entries] Start Form Entries Record Loading';
export const FORM_ENTRIES_RECORD_LOADED = '[Form Entries] Form Entries Record Loaded';
export const START_FORM_ENTRIES_COLUMN_LOADING = '[Form Entries] Start Form Entries Column Loading';
export const FORM_ENTRIES_COLUMN_LOADED = '[Form Entries] Form Entries Column Loaded';
export const START_FORM_ENTRIES_HISTORY_LOADING = '[Form Entries] Start Form Entries History Loading';
export const FORM_ENTRIES_HISTORY_LOADED = '[Form Entries] Form Entries History Loaded';
export const DOWNLOAD_REPORT_EXCEL = '[Form Entries] Download Report Excel';
export const DOWNLOAD_REPORT_EXCEL_COMPLETED = '[Form Entries] Download Report Excel Completed';
export const SET_TABLE_SETTINGS = '[Form Entries] Form Tasks Set Table Settings';
export const INITIALIZE_SAVE_REPORT_TABLE_SETTINGS = '[Form Entries] Form Tasks Initialize Save Report Table Settings';
export const SAVE_REPORT_TABLE_SETTINGS = '[Form Entries] Form Tasks Save Report Table Settings';
export const SET_FORM_ENTRIES_HEADERS = '[Form Entries] Form Tasks Set Table Headers';
export const CLEAR_STATE = '[Form Entries] Clear State';
export const FETCH_ERROR = '[Form Entries] Fetch Error';

export class StartFormEntriesLoading implements Action {
  readonly type = START_FORM_ENTRIES_LOADING;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery, public dispatchHideLoader: boolean = true) {}
}

export class FormEntriesLoaded implements Action {
  readonly type = FORM_ENTRIES_LOADED;

  constructor(public payload: IGetManyResponse<IFormEntriesData>) {}
}

export class StartFormEntriesRecordLoading implements Action {
  readonly type = START_FORM_ENTRIES_RECORD_LOADING;

  constructor(public logbookId: number, public id: number, public dispatchLoader: boolean = true) {}
}

export class FormEntriesRecordLoaded implements Action {
  readonly type = FORM_ENTRIES_RECORD_LOADED;

  constructor(public payload: IFormSubmissionData) {}
}

export class StartFormEntriesColumnLoading implements Action {
  readonly type = START_FORM_ENTRIES_COLUMN_LOADING;

  constructor(public formIds: number[], public dispatchHideLoader: boolean = true, public parentLogbookId?: number) {}
}

export class FormEntriesColumnLoaded implements Action {
  readonly type = FORM_ENTRIES_COLUMN_LOADED;

  constructor(public payload: IGetManyResponse<ILogsFormEntriesColumn>) {}
}

export class StartFormEntriesHistoryLoading implements Action {
  readonly type = START_FORM_ENTRIES_HISTORY_LOADING;

  constructor(public id: number, public dispatchLoader: boolean = true) {}
}

export class FormEntriesHistoryLoaded implements Action {
  readonly type = FORM_ENTRIES_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormSubmissionUserAction>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public headers: ITableHeader[], public setAsDefault: boolean) {}
}

export class InitializeSaveReportTableSettings implements Action {
  readonly type = INITIALIZE_SAVE_REPORT_TABLE_SETTINGS;

  constructor(public headers: ITableHeader[]) {}
}

export class SaveReportTableSettings implements Action {
  readonly type = SAVE_REPORT_TABLE_SETTINGS;

  constructor(public reportId: number, public meta: IMyReportMeta) {}
}

export class SetFormEntriesHeaders implements Action {
  readonly type = SET_FORM_ENTRIES_HEADERS;

  constructor(public headers: ITableHeader[], public defaultHeaders: ITableHeader[]) {}
}

export class DownloadReportExcel implements Action {
  readonly type = DOWNLOAD_REPORT_EXCEL;

  constructor(
    public tableQuery: ITableQuery,
    public selectedColumns: ITableHeader[],
    public formEntriesDynamicColumns: ILogsFormEntriesColumn[],
  ) {}
}

export class DownloadReportExcelCompleted implements Action {
  readonly type = DOWNLOAD_REPORT_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export type FormEntriesActions =
  | StartFormEntriesLoading
  | FormEntriesLoaded
  | StartFormEntriesRecordLoading
  | FormEntriesRecordLoaded
  | StartFormEntriesColumnLoading
  | FormEntriesColumnLoaded
  | StartFormEntriesHistoryLoading
  | FormEntriesHistoryLoaded
  | DownloadReportExcel
  | DownloadReportExcelCompleted
  | SetTableSettings
  | SaveReportTableSettings
  | InitializeSaveReportTableSettings
  | SetFormEntriesHeaders
  | FetchError
  | ClearState;
