import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef,
  OnChanges,
} from '@angular/core';
import { FilterableComponents } from '../filterable-objects.class';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { DependencyType, DropdownFilterConfiguration } from '../dropdown/dropdown.model';
import { Subject } from 'rxjs';
import { FilterDateRangePickerComponent } from '../filter-date-range-picker/filter-date-range-picker.component';
import { TranslateService } from '@ngx-translate/core';
import {
  IDefaultSelectionValues,
  IDropdownItemFilter,
  IFilterCardOption,
  FilterDataObjectTypes,
  IRowConfiguration,
} from '../filter.class';

import * as _ from 'lodash';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import { AdvancedFilterObjects, IFilter } from '../advanced-filter/advanced-filter.model';
import { ButtonGroupComponent } from '../button-group/button-group.component';
import { IGenericObject } from '../../../model/interface/generic.model';
import { IDateRangeReturn } from '../../date-range-picker/date-range-picker.model';

@Component({
  selector: 'filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: [],
})
export class FilterCardComponent implements OnInit, OnChanges {
  @ViewChild('row1', { read: ViewContainerRef, static: true }) row1!: ViewContainerRef;
  @ViewChild('row2', { read: ViewContainerRef, static: true }) row2!: ViewContainerRef;
  @Input() options: IFilterCardOption = { rows: [] };
  @Input() mainGridWide: number = 8;
  @Input() additionalGridWide: number = 4;
  @Input() enableSetAsDefault: boolean = false;
  @Input() defaultFilterSelectionsSubject!: Subject<IDefaultSelectionValues>;
  @Input() strictControlForSelected: boolean = false;
  @Input() autoApply: boolean = false;
  @Input() advancedFilterDefaultValues: IFilter[] = [];
  @Input() isAdvancedFilterSetDefaultButtonHide: boolean = false;
  @Input() validUpdateMessage: string = this.translate.instant('filterUpdate.filterUpdateText', {
    object: this.translate.instant('filterUpdate.objectTypes.table'),
  });
  @Input() showSaveFilterSettingsButton: boolean = false;
  @Input() isCard: boolean = true;
  @Input() enableFilterUpdateCard: boolean = true;
  @Input() hiddenElementIds: string[] = [];
  @Input() triggerUpdateButtonClickSubject!: Subject<void>;

  @Output() filterChange = new EventEmitter<any>();
  @Output() saveFilterSettings = new EventEmitter<any>();
  @Output() setAsDefault = new EventEmitter<any>();
  @Output() filterEventListener = new EventEmitter<any>();
  private readonly createdComponents = Array<any>();
  private filterElements: ComponentRef<FilterableComponents>[] = [];
  private outputSubjects: Subject<FilterDataObjectTypes>[] = [];
  private filterOutput: IGenericObject<any> = {};
  private defaultSelectedFieldCount: number = 0;
  private requiredFieldCount: number = 0;
  public showUpdateCard: boolean = false;
  public updateMessage: string | null = null;
  public invalidUpdate: boolean = false;
  public filterAutoApply: boolean = false;
  public invalidSelectBoxMessage: string = 'filterCard.messages.invalidFilter';

  constructor(
    private translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
  ) {}

  private add(
    row: number,
    componentClass: any,
    objectsClass: any,
    outputOptions: any,
    elementID?: string,
    dependency?: DependencyType,
    options?: any,
    cls?: string,
    disableComponent?: any,
    filter?: IDropdownItemFilter,
    advancedFilterObject?: Type<AdvancedFilterObjects>,
    dependedOptions?: any,
  ): ComponentRef<FilterableComponents> {
    const componentFactory: ComponentFactory<FilterableComponents> =
      this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const element = this.viewContainerRef.createComponent(componentFactory);
    this.renderer.appendChild(_.get(this, `row${row + 1}`)?.element?.nativeElement, element.location.nativeElement);

    if (cls !== null && cls !== undefined) {
      element.location.nativeElement.className = cls;
    }

    if (
      (options.selectAll !== undefined && options.selectAll) ||
      options.inputModel !== undefined ||
      options.defaultSelection !== undefined
    ) {
      this.defaultSelectedFieldCount = this.defaultSelectedFieldCount + 1;
    }

    if (options.isRequired) {
      this.requiredFieldCount = this.requiredFieldCount + 1;
    }

    if (elementID !== null && elementID !== undefined) {
      element.instance.elementID = elementID;
    }

    if (typeof disableComponent !== 'undefined' && disableComponent !== null) {
      _.set(element.instance, 'disableComponent', {
        elementId: disableComponent['elementId'],
      });
    }

    if (filter) {
      _.set(element.instance, 'filter', filter);
    }

    element.instance.outputOptions = outputOptions;
    switch (componentClass) {
      case DropdownComponent:
        _.set(element.instance, 'dropdownObjectClass', objectsClass);
        _.set(element.instance, 'strictControlForSelected', this.strictControlForSelected);

        if (dependency !== null && dependency !== undefined) {
          _.set(element.instance, 'depends', dependency);
        }

        if (options !== null && options !== undefined) {
          _.set(element.instance, 'dropdownSettings', options);
        }
        break;
      case FilterDateRangePickerComponent:
        if (options !== null && options !== undefined) {
          _.set(element.instance, 'defaultSettings', options);
        }
        break;
      case AdvancedFilterComponent:
        _.set(
          element.instance,
          'filterObject',
          advancedFilterObject ? this.injector.get<AdvancedFilterObjects>(advancedFilterObject) : undefined,
        );
        _.set(element.instance, 'defaultValues', this.advancedFilterDefaultValues);
        _.set(element.instance, 'isSetDefaultButtonHide', this.isAdvancedFilterSetDefaultButtonHide);
        break;
      case ButtonGroupComponent:
        _.set(element.instance, 'buttons', options.buttons);
        _.set(element.instance, 'value', options.value);
        _.set(element.instance, 'disabled', options.disabled);
        break;
    }

    if (dependedOptions !== null && dependedOptions !== undefined) {
      _.set(element.instance, 'dependedOptions', dependedOptions);
    }

    this.createdComponents.push(element.instance);
    return element;
  }

  ngOnInit(): void {
    const rows = this.options.rows;
    if (Array.isArray(rows)) {
      this.filterElements = _.flatten(
        rows.map((row, index) => {
          return row.map((component: any) => {
            const outputData: IGenericObject<any> = {};

            outputData[component.outputOptions.filterObjectId] = null;
            if (!component.options.isRequired && component.type === DropdownComponent) {
              outputData[component.outputOptions.filterObjectId] = -1;
            }

            if (!component.options.isRequired && component.type === ButtonGroupComponent) {
              outputData[component.outputOptions.filterObjectId] = component.options.value;
            }

            this.filterOutput = { ...this.filterOutput, ...outputData };
            return this.add(
              index,
              _.get(component, 'type', null),
              _.get(component, 'object', null),
              _.get(component, 'outputOptions', null),
              _.get(component, 'elementId', null),
              _.get(component, 'depends', null),
              _.get(component, 'options', null),
              _.get(component, 'cls', null),
              _.get(component, 'disableComponent', null),
              _.get(component, 'filter', null),
              _.get(component, 'advancedFilterObject', null),
              _.get(component, 'dependedOptions', null),
            );
          });
        }),
      );
      this.showOrHideFilterElements();
    }

    this.createdComponents.forEach((component) => {
      const outputSubject = new Subject<FilterDataObjectTypes>();
      component.outputSubject = outputSubject;
      this.outputSubjects.push(outputSubject);

      if (
        component.hasOwnProperty('disableComponent') &&
        component.disableComponent &&
        component.disableComponent.elementId
      ) {
        const result = this.createdComponents.find(
          (element) => element.elementID === component.disableComponent.elementId,
        );
        if (result !== undefined) {
          result.disabledSubject.next(true);
          outputSubject.asObservable().subscribe((data) => {
            const output = _.get(data, component.outputOptions.filterObjectId, -1);
            let disableStatus: boolean = true;
            if (output !== -1) {
              disableStatus = false;
            }
            result.disabledSubject.next(disableStatus);
          });
        }
      }

      if (component.dependedOptions && Array.isArray(component.dependedOptions)) {
        for (const dependedOption of component.dependedOptions) {
          const result = this.createdComponents.find((element) => element.elementID === dependedOption.elementId);

          if (!result) {
            continue;
          }

          const dependedOptionSubject = new Subject();
          const componentDependedOptionConfiguration = {
            elementId: component.elementID,
            dependedElementId: result.elementID,
            dependedOptionListener: dependedOptionSubject,
            dependedOption: dependedOption.dependedOption,
            additionalChanges: dependedOption.additionalChanges,
            getDependentValue:
              dependedOption.getDependentValue ??
              function (value: any) {
                return value;
              },
            submit: false,
          };
          const resultDependedOptionConfiguration = {
            elementId: result.elementID,
            dependedElementId: component.elementID,
            dependedOptionListener: dependedOptionSubject,
            dependedOption: dependedOption.dependedOption,
            additionalChanges: dependedOption.additionalChanges,
            getDependentValue:
              dependedOption.getDependentValue ??
              function (value: any) {
                return value;
              },
            submit: true,
          };
          component.dependedOptionConfiguration.push(componentDependedOptionConfiguration);
          component.subscribeDependedOptionListener(componentDependedOptionConfiguration);
          result.dependedOptionConfiguration.push(resultDependedOptionConfiguration);
        }
      }

      if (component.depends) {
        const result = this.createdComponents.find((element) => element.elementID === component.depends.elementId);

        if (result === undefined) {
          return;
        }

        const filterSubject = new Subject();
        let componentFilterConfiguration: DropdownFilterConfiguration = {
          elementId: component.elementID,
          filteredElementId: result.elementID,
          filterListener: filterSubject,
          submit: false,
        };
        let resultFilterConfiguration: DropdownFilterConfiguration = {
          elementId: result.elementID,
          filteredElementId: component.elementID,
          filterListener: filterSubject,
          submit: true,
        };
        if (component.depends.isDataLocatedAtParent && component.depends.dataPathAtParent) {
          componentFilterConfiguration = {
            ...componentFilterConfiguration,
            isDataLocatedAtParent: component.depends.isDataLocatedAtParent,
            dataPathAtParent: component.depends.dataPathAtParent,
          };
          resultFilterConfiguration = {
            ...resultFilterConfiguration,
            isDataLocatedAtParent: component.depends.isDataLocatedAtParent,
            dataPathAtParent: component.depends.dataPathAtParent,
          };
        }
        component.filterListenerConfiguration.push(componentFilterConfiguration);
        component.subscribeFilterListener(componentFilterConfiguration);
        result.filterListenerConfiguration.push(resultFilterConfiguration);
      }
    });
    this.outputSubjects.forEach((subject) => {
      subject.asObservable().subscribe((data) => {
        this.filterOutput = { ...this.filterOutput, ...data };
        this.filterEventListener.emit(this.filterOutput);
        this.publishDataDecider();

        if (!this.showUpdateCard && this.defaultSelectedFieldCount === 0 && !this.invalidUpdate) {
          this.filterChange.emit(this.filterOutput);
        }
      });
    });

    this.triggerUpdateButtonClickSubject?.asObservable()?.subscribe(() => {
      this.onUpdateButtonClick();
    });

    if (this.defaultFilterSelectionsSubject) {
      this.defaultFilterSelectionsSubject.asObservable().subscribe((data) => {
        if (Object.keys(data).length > 0) {
          this.defaultSelectedFieldCount += Object.keys(data).length;

          this.createdComponents.forEach((component) => {
            if (component instanceof DropdownComponent) {
              component.dropdownSettings.defaultSelection = data[component.elementID];

              if (component.dropdownObject.data !== undefined) {
                component.dropdownObject.dataSubject.next(component.dropdownObject.data);
                if (this.autoApply && !this.filterAutoApply) {
                  this.filterAutoApply = true;
                }
              }
            }

            if(component instanceof FilterDateRangePickerComponent) {
              if(
                !data.hasOwnProperty('dateRange') ||
                !data['dateRange'].values ||
                !data['dateRange'].values.hasOwnProperty('startDate') ||
                !data['dateRange'].values.hasOwnProperty('endDate')
              ) {
                return;
              }

              component.setSelectedDates(data['dateRange'].values as unknown as IDateRangeReturn);
            }
          });
        }
      });
    }

    if (this.defaultSelectedFieldCount !== 0) {
      return;
    }

    if (this.requiredFieldCount === 0) {
      this.filterChange.emit(this.filterOutput);
    } else {
      this.publishDataDecider();
    }
  }

  ngOnChanges(): void {
    this.showOrHideFilterElements();
  }

  onUpdateButtonClick(): void {
    this.showUpdateCard = false;
    this.filterChange.emit(this.filterOutput);
  }

  onSaveFilterSettingsButtonClick(): void {
    if (!this.showSaveFilterSettingsButton) {
      return;
    }

    this.onUpdateButtonClick();
    this.saveFilterSettings.emit(this.filterOutput);
  }

  onSetAsDefaultButtonClick(): void {
    if (!this.enableSetAsDefault) {
      return;
    }

    this.onUpdateButtonClick();
    this.setAsDefault.emit(this.filterOutput);
  }

  private publishDataDecider(): void {
    const invalidFields = this.validateRequiredIsSelected();
    let invalidMessage: string | null = null;
    this.invalidUpdate = invalidFields.length !== 0;
    this.defaultSelectedFieldCount = this.defaultSelectedFieldCount - 1;

    if (this.invalidUpdate) {
      invalidMessage = this.translate.instant(this.invalidSelectBoxMessage, {
        fields: invalidFields.join(', '),
      });
    }

    this.updateMessage = this.invalidUpdate ? invalidMessage : this.validUpdateMessage;
    this.showUpdateCard = this.defaultSelectedFieldCount < 0 || this.invalidUpdate;
  }

  private validateRequiredIsSelected(): string[] {
    const data = Object.values(this.options.rows);
    const invalidFieldNames: string[] = [];

    data.forEach((row: IRowConfiguration[]) => {
      row.forEach((element: IRowConfiguration) => {
        const elementId = element.outputOptions.filterObjectId;
        const isDatePicker = element.type === FilterDateRangePickerComponent;

        if (
          element.type === SearchBoxComponent &&
          this.filterOutput[elementId] !== null &&
          this.filterOutput[elementId].startsWith(' ')
        ) {
          invalidFieldNames.push(element.options?.text ?? '');
          this.invalidSelectBoxMessage = 'filterCard.messages.invalidInput';
        }

        if (element.options?.isRequired && this.filterOutput.hasOwnProperty(elementId)) {
          if (
            this.filterOutput[elementId] === null ||
            (isDatePicker &&
              this.filterOutput[elementId].startDate === null &&
              this.filterOutput[elementId].endDate === null)
          ) {
            invalidFieldNames.push(element.options?.text ?? '');
          } else if (this.strictControlForSelected && this.filterOutput[elementId] === -1) {
            invalidFieldNames.push(element.options?.text ?? '');
          }
        }
      });
    });

    return invalidFieldNames;
  }

  private showOrHideFilterElements() {
    for (const index of Object.keys(this.filterElements)) {
      _.set(
        this.filterElements[Number(index)],
        'location.nativeElement.hidden',
        this.hiddenElementIds.includes(this.filterElements[Number(index)].instance.elementID),
      );
    }
  }
}
