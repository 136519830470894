import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as ObjectActions from './form-master-data.actions';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { catchError, switchMap, of } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { WorkflowsService } from '../workflows/workflows.service';
import { IWorkflow } from '../workflows/workflows.model';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';
import { FormMasterDataService } from './form-master-data.service';
import { IFormMasterData, IFormMasterDataHistory } from './form-master-data.model';

@Injectable()
export class FormMasterDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: FormMasterDataService,
    private readonly workflowsService: WorkflowsService,
  ) {}

  getFormMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_DATA_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataDataLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest(payload.tableQuery);

        return this.service.getFormMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormMasterData>) => {
            return of(new ObjectActions.FormMasterDataDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getShowFormMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_HISTORY_DATA_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataHistoryDataLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormMasterDataHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<IFormMasterDataHistory>) => {
            return of(new ObjectActions.FormMasterDataHistoryDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormMasterDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_DETAIL_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataDetailLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormMasterDetailData(payload.id, payload.getPreviousVersion).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataDetailLoaded({
                success: response[0].success && response[1].success,
                data: { ...response[0].data, fields: response[1].data ?? [] },
              }),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  sendToReviewFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_SEND_TO_REVIEW_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataSendToReviewLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.sendToReviewFormMaster(payload.id, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataSendToReviewLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.FormMasterDataRefreshLoad(ObjectActions.FORM_MASTER_DATA_SEND_TO_REVIEW_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  activateFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_ACTIVATE_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataActivateLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.activateFormMaster(payload.id, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataActivateLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.FormMasterDataRefreshLoad(ObjectActions.FORM_MASTER_DATA_ACTIVATE_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_ADD_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataAddLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addFormMaster(payload.payload, payload.issuerAndReason).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataAddLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.FormMasterDataRefreshLoad(ObjectActions.FORM_MASTER_DATA_ADD_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_EDIT_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataEditLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editFormMaster(payload.id, payload.payload, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataEditLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.FormMasterDataRefreshLoad(ObjectActions.FORM_MASTER_DATA_EDIT_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_DELETE_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataDeleteLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteFormMaster(payload.id, payload.issuerAndReason).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataDeleteLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.FormMasterDataRefreshLoad(ObjectActions.FORM_MASTER_DATA_DELETE_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormMasterDataApprovalWorkflow = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataApprovalWorkflowLoad) => {
        if (payload.skipLoader) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          limit: payload.limit,
          search: payload.searchText,
          just_workflow_data: true,
          include_disabled: true,
        });

        return this.workflowsService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(
              new ObjectActions.FormMasterDataApprovalWorkflowLoaded(response),
              payload.skipLoader ? new AppActions.EmptyAction() : new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormMasterWorkflowsStepsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataWorkflowsStepsLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workflowsService.getWorkflowsSteps(payload.id).pipe(
          switchMap((response) => {
            return of(new ObjectActions.FormMasterDataWorkflowsStepsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  cloneFormMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_CLONE_LOAD),
      switchMap((payload: ObjectActions.FormMasterDataCloneLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormMasterDetailData(payload.id).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.FormMasterDataAddLoad(
                this.service.formatDetailToAdd({ ...response[0].data, fields: response[1].data ?? [] }, true),
                payload.issuerAndReason,
              ),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  passedIssueDateFormMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOAD),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams()
          .append('issued_date__lte', moment().utc().format())
          .append('issued_date__isnull', false)
          .append('is_active', false)
          .append(
            'approval_step_position__not_in',
            [EApprovalStatuses.ACTIVE, EApprovalStatuses.OBSOLETE, EApprovalStatuses.CANCELED].join(','),
          )
          .append('limit', 1);

        return this.service.getFormMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormMasterData>) => {
            return of(new ObjectActions.FormMasterDataPassedIssueDateLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  draftVersionExistsLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('approval_step_position__gte', -1).append('limit', 1);

        return this.service.getFormMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormMasterData>) => {
            return of(new ObjectActions.FormMasterDataDraftVersionExistsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
