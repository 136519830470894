import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { spinkit } from './spinkits';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss', './spinkit-css/sk-line-material.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;
  public spinkit = spinkit;
  @Input() public backgroundColor = 'rgba(255, 255, 255, 0.8)';
  @Input() public spinner = spinkit.skLine;
  private routerEventSubscription: Subscription;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
    this.routerEventSubscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      },
    );
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }
}
