import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import * as AppActions from './store/app/actions';
import * as HomeActions from './store/home/home.actions';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PageHeaderService } from './shared/service/page-header/page-header.service';
import { IPageHeader } from './shared/service/page-header/page-header.model';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { User } from './store/user/model';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import { BROWSER_TITLE_SUFFIX } from '../constants';
import { SignalRService } from './shared/service/signalr/signalr.service';
import * as UserConfigurationActions from './store/user-configuration/user-configuration.actions';
import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { toNumber } from 'lodash';
import { MonitoringService } from './shared/service/error-service/monitoring.service';
import { IIssuerAndReason } from './shared/component/before-action-preparer/before-action-preparer.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Logbook';
  private url: string;
  private isTokenLoaded: boolean = false;
  private roleName: string | null = '';

  constructor(
    public translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private pageHeaderService: PageHeaderService,
    private cookieService: CookieService,
    private http: HttpClient,
    private readonly signalRService: SignalRService,
    private idle: Idle,
    private keepalive: Keepalive,
    private monitoringService: MonitoringService,
  ) {
    this.url = `${environment.ssoUrl}${environment.ssoSilentAuthUrl}`;
    window.addEventListener('online', () => {
      window.location.reload();
      this.store.dispatch(new AppActions.HideMask());
    });

    window.addEventListener('offline', () => {
      this.store.dispatch(new AppActions.ShowMask());
      this.toast.warning(
        this.translate.instant('general.internetErrorMessage'),
        this.translate.instant('general.warning'),
        {
          closeButton: false,
          progressBar: false,
          disableTimeOut: true,
          tapToDismiss: false,
          positionClass: 'toast-bottom-right',
        },
      );
    });

    this.handleIdleOperations();
  }

  signDocument(signFieldId: string) {
    this.store.dispatch(new HomeActions.SignDocument(signFieldId));
  }
  ngOnInit(): void {
    // @ts-ignore
    window.globalFunctions = window.globalFunctions || {};
    // @ts-ignore
    window.globalFunctions.signDocument = this.signDocument.bind(this);
    if (!localStorage['scwLBOpenTabCount'] || Number(localStorage['scwLBOpenTabCount']) === 0) {
      localStorage['scwLBOpenTabCount'] = 1;
    } else {
      localStorage['scwLBOpenTabCount'] = Number(localStorage['scwLBOpenTabCount']) + 1;
    }

    this.store.dispatch(new UserConfigurationActions.GetUserConfigurationLoading());
    this.store
      .select('user')
      .pipe(takeWhile(() => !this.isTokenLoaded))
      .subscribe((state: User) => {
        if (state.isTokenRefreshed) {
          this.isTokenLoaded = state.isTokenRefreshed;
        }
      });
    this.store
      .select('user')
      .subscribe((state: User) => {
        if (state.role) {
          this.roleName = state.role?.name;
          this.writeHeader();
        }
      })
      .unsubscribe();

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.writeHeader();
      }
    });
  }

  private writeHeader(): void {
    const configuration: IPageHeader[] = this.getPageConfiguration(
      this.router.routerState,
      this.router.routerState.root,
    );
    if (configuration.length === 0 || configuration[0].titleKey === undefined) {
      return;
    }

    const pageConfig: IPageHeader = configuration[0];
    const stringDash: string = ' - ';
    let pageConfigTitle: string = this.translate.instant(`pageTitles.${pageConfig.titleKey}`);
    if (pageConfig.titleKey === 'myTasks') {
      pageConfigTitle += stringDash + this.roleName;
    }
    this.titleService.setTitle(pageConfigTitle + BROWSER_TITLE_SUFFIX);
    this.setHeader({
      ...pageConfig,
      title: pageConfigTitle,
      titleKey: pageConfig.title,
    });
  }

  private getPageConfiguration(state: any, parent: ActivatedRoute): IPageHeader[] {
    const data: IPageHeader[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data as IPageHeader);
    }

    if (state && parent) {
      data.push(...this.getPageConfiguration(state, state.firstChild(parent)));
    }

    return data;
  }

  private setHeader(config: IPageHeader): void {
    let pageHeader: IPageHeader = {
      title: undefined,
      titleKey: undefined,
      icon: undefined,
    };

    if (config.setPageHeader !== undefined && config.setPageHeader) {
      pageHeader = {
        title: config.title || undefined,
        titleKey: config.titleKey,
        icon: config.icon || undefined,
        fullScreenButton: config.fullScreenButton || false,
        fullScreenTargetElementId: config.fullScreenTargetElementId || undefined,
        isTabPage: config.isTabPage,
        showPageConfiguration: config.showPageConfiguration || false,
        showCountDownButton: config.showCountDownButton || false,
        showFilterBarVisibilityButton: config.showFilterBarVisibilityButton || false,
        showPrintFunctionalityButton: config.showPrintFunctionalityButton || false,
        hidePageHeader: config.hidePageHeader || false,
        noOverlap: config.noOverlap || false,
        moveUncheckedToEnd: config.moveUncheckedToEnd ?? true,
      };
    }

    this.pageHeaderService.setHeader(pageHeader);
  }

  private renewToken(): void {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    this.http
      .get<any>(this.url)
      .pipe(takeUntil(destroySubject))
      .subscribe(() => destroySubject.next(true));
  }

  private handleIdleOperations(): void {
    const idleWarningTimeOut: number = toNumber(`${environment.idleWarningTimeOutInSeconds}`);
    const idleLogoutTimeOut: number = toNumber(`${environment.idleLogoutTimeOutInSeconds}`);
    const keepAliveTimeOut: number = toNumber(`${environment.keepAliveTimeOutInSeconds}`);

    this.idle.setIdle(idleWarningTimeOut);
    this.idle.setTimeout(idleLogoutTimeOut);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.renewToken();

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdleTimer();
    });

    this.idle.onTimeout.subscribe(() => {
      this.resetIdleTimer();
      this.logout();
    });

    this.keepalive.interval(keepAliveTimeOut);

    this.keepalive.onPing.subscribe(() => this.renewToken());

    this.resetIdleTimer();
  }

  private resetIdleTimer(): void {
    this.idle.watch();
  }

  private logout(): void {
    if (!localStorage['scwLBOpenTabCount'] || Number(localStorage['scwLBOpenTabCount']) <= 1) {
      this.http
        .get<any>(`${environment.ssoUrl}${environment.ssoLogoutUrl}`)
        .pipe()
        .subscribe(() => {
          window.location.href = environment.ssoProviderLogoutUrl + location.origin;
        });
    }
  }
}
