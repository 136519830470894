import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  DateRangeDropsTypes,
  IDateRange,
  IDateRangeLocale,
  DateRangeOpensTypes,
  IDateRangeRangesDefault,
  IScwMatDatepickerReturn,
  ScwMatDatepickerRule,
} from './scw-mat-datepicker.model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../../store/logbook.reducer';
import * as moment from 'moment';
import { take, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { User } from '../../../../store/user/model';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { HelperService } from '../../../service/helper.service';
import { DateRanges } from '../../../../../constants';
import StartOf = moment.unitOfTime.StartOf;

@Component({
  selector: 'scw-mat-datepicker',
  templateUrl: './scw-mat-datepicker.component.html',
  styleUrls: ['./scw-mat-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScwMatDatepickerComponent implements IDateRange, OnInit, OnDestroy {
  @ViewChild(DaterangepickerDirective) pickerDirective!: DaterangepickerDirective;

  @Input() alwaysShowCalendars: boolean = true;
  @Input() autoApply: boolean = false;
  @Input() className: string = '';
  @Input() closeOnAutoApply: boolean = false;
  @Input() customRangeDirection: boolean = false;
  @Input() customStyle: any = null;
  @Input() disabled: boolean = false;
  @Input() drops: DateRangeDropsTypes = 'down';
  @Input() emptyWeekRowClass: string = '';
  @Input() firstDayOfNextMonthClass: string = '';
  @Input() firstMonthDayClass: string = '';
  @Input() id: string = '';
  @Input() inputModel!: IScwMatDatepickerReturn | null;
  @Input() isCustomDate: () => boolean = () => {
    return false;
  };
  @Input() isInvalidDate: () => boolean = () => {
    return false;
  };
  @Input() keepCalendarOpeningWithRange: boolean = true;
  @Input() lastDayOfPreviousMonthClass: string = '';
  @Input() lastMonthDayClass: string = '';
  @Input() linkedCalendars!: any;
  @Input() locale!: any;
  @Input() lockStartDate: boolean = false;
  @Input() maxDate!: moment.Moment;
  @Input() minDate!: moment.Moment;
  @Input() opens: DateRangeOpensTypes = DateRangeOpensTypes.right;
  @Input() placeholder: string = '';
  @Input() hint: string | null = null;
  @Input() ranges: any;
  @Input() readonly: boolean = true;
  @Input() showCalendarIcon: boolean = true;
  @Input() showCancel: boolean = true;
  @Input() showClearButton: boolean = true;
  @Input() showCustomRangeLabel: boolean = true;
  @Input() showISOWeekNumbers: boolean = false;
  @Input() showRangeLabelOnInput: boolean = false;
  @Input() showWeekNumbers: boolean = true;
  @Input() singleDatePicker: boolean = false;
  @Input() timePicker: boolean = false;
  @Input() timePickerIncrement: number = 1;
  @Input() timePickerSeconds: boolean = false;
  @Input() emitOnNull: boolean = false;
  @Input() errorText: string | null = null;
  @Input() isValid: boolean = false;
  @Input() hasErrors: boolean = false;
  @Input() rules: ScwMatDatepickerRule[] = [];
  @Input() label!: string;
  @Input() wide!: boolean;
  @Input() noPadding: boolean = false;
  @Input() emitUtc: boolean = false;
  @Input() containerClass: string = '';
  @Input() rangePickerStartLabel!: string;
  @Input() rangePickerEndLabel!: string;
  @Input() dataPropertyKey?: string;
  @Input() formatInitialModel: boolean = true;

  @Output() inputModelChange: EventEmitter<IScwMatDatepickerReturn | null> =
    new EventEmitter<IScwMatDatepickerReturn | null>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public timePicker24Hour: boolean = true;
  public locale$!: string;
  public timezone$!: string;
  public dateFormat$!: string;
  public dateTimeFormat$!: string;
  private isAnyError: boolean = false;
  private firstTime!: boolean;
  private userSubscription!: Subscription;
  public prevValue!: IScwMatDatepickerReturn | null;

  constructor(
    private readonly store: Store<LogbookAppState>,
    public readonly translate: TranslateService,
    public readonly helperService: HelperService,
  ) {}

  public getDefaultRanges(): DateRanges {
    const rangeTimes: IDateRangeRangesDefault = {
      today: [moment(), moment()],
      yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      thisWeek: [moment().startOf('week'), moment().endOf('week')],
      lastWeek: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
      thisMonth: [moment().startOf('month'), moment().endOf('month')],
      lastMonth: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      thisYear: [moment().startOf('year'), moment().endOf('year')],
      lastYear: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    };

    return this.helperService.getDefaultRanges(rangeTimes);
  }

  public onClickIcon(): void {
    if (this.disabled) {
      return;
    }

    setTimeout(() => {
      this.pickerDirective.open();
    });
  }

  public onClickClear(): void {
    if (this.disabled) {
      return;
    }
    this.inputModelChange.emit(null);
  }

  public ngOnInit(): void {
    this.firstTime = true;
    this.userSubscription = this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.locale$ = state.language ?? '';
        this.timezone$ = state.timezone ?? '';
        this.dateFormat$ = state.dateFormat ?? '';
        this.dateTimeFormat$ = HelperService.userDateTimeFormat ?? '';
        const displayFormatValue: string = this.timePicker ? this.dateTimeFormat$ : this.dateFormat$;

        const defaultLocale: IDateRangeLocale = {
          applyLabel: this.translate.instant('dateRangePicker.locale.applyLabel'),
          cancelLabel: this.translate.instant('dateRangePicker.locale.cancelLabel'),
          clearLabel: this.translate.instant('dateRangePicker.locale.clearLabel'),
          customRangeLabel: this.translate.instant('dateRangePicker.locale.customRangeLabel'),
          daysOfWeek: moment.localeData(this.locale$).weekdaysShort(),
          direction: 'ltr',
          firstDay: moment.localeData(this.locale$).firstDayOfWeek(),
          format: displayFormatValue,
          monthNames: moment.localeData(this.locale$).monthsShort(),
          separator: ' - ',
          weekLabel: this.translate.instant('dateRangePicker.locale.weekLabel'),
        };

        this.locale = { ...this.locale, ...defaultLocale };

        if (typeof this.ranges === 'undefined') {
          this.ranges = this.getDefaultRanges();
        }
      });

    if (!_.isEmpty(this.inputModel) && !_.isNil(this.inputModel?.startDate) && !_.isNil(this.inputModel?.endDate)) {
      if (this.emitUtc) {
        this.inputModel = {
          startDate: moment(moment.utc(this.inputModel?.startDate).valueOf()),
          endDate: moment(moment.utc(this.inputModel?.endDate).valueOf()),
        };
      } else {
        const startDate: moment.Moment = moment(this.inputModel?.startDate);
        const endDate: moment.Moment = moment(this.inputModel?.endDate);

        this.inputModel = {
          startDate: this.formatInitialModel ? startDate.tz('GMT+0', true).tz(this.timezone$) : startDate,
          endDate: this.formatInitialModel ? endDate.tz('GMT+0', true).tz(this.timezone$) : endDate,
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public reset(): void {
    this.inputModel = null;
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  private clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatDatepickerRule): void {
    if (
      this.inputModel === null ||
      (this.inputModel?.startDate === null && (!this.singleDatePicker || this.singleDatePicker)) ||
      this.inputModel?.endDate === null
    ) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      if ('required' in rule) {
        this.requiredRule(rule);
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  private setInputModelBoundaries(): void {
    if (
      this.timePicker ||
      this.inputModel === null ||
      this.inputModel?.startDate === null ||
      this.inputModel?.endDate === null
    ) {
      return;
    }

    this.inputModel?.startDate.set('hours', 0).set('minutes', 0).set('seconds', 0);
    this.inputModel?.endDate.set('hours', 23).set('minutes', 59).set('seconds', 59);
  }

  public onNgModelChange(): void {
    if (
      this.prevValue?.endDate &&
      this.prevValue?.startDate &&
      this.dateIsSame(this.inputModel?.startDate, this.prevValue?.startDate) &&
      this.dateIsSame(this.inputModel?.endDate, this.prevValue?.endDate)
    ) {
      return;
    }

    this.prevValue = this.inputModel;

    this.setInputModelBoundaries();

    const isStartEndDateNull: boolean = _.isNil(this.inputModel?.startDate) && _.isNil(this.inputModel?.endDate);

    if (
      this.emitUtc &&
      !isStartEndDateNull &&
      !this.inputModel?.startDate?.isUTC() &&
      !this.inputModel?.endDate?.isUTC()
    ) {
      this.inputModel = {
        startDate: moment.utc(this.inputModel?.startDate ?? undefined),
        endDate: moment.utc(this.inputModel?.endDate ?? undefined),
      };
    }

    this.inputModelChange.emit(!isStartEndDateNull ? this.inputModel : null);

    if (this.firstTime) {
      this.firstTime = false;
      return;
    }

    this.checkRules();
  }

  private dateIsSame(date: moment.Moment | null | undefined, dateSecond: moment.Moment | null | undefined): boolean {
    const checkParameter: StartOf[] = ['year', 'month', 'day', 'hours', 'minutes'];

    for (const param of checkParameter) {
      if (_.isNil(date) !== _.isNil(dateSecond) || (date && dateSecond && !date.isSame(dateSecond, param))) {
        return false;
      }
    }

    return true;
  }
}
