import * as Actions from './logbook-scope.actions';
import { ILogbookScopesState } from '../scopes.model';
import { Action } from '@ngrx/store';

export const logbookScopesState: ILogbookScopesState = {
  logbookScopesDataLoaded: false,
  logbookScopesDataLoading: false,
  logbookScopes: null,
  logbookScopeAddLoading: false,
  logbookScopeAddLoaded: false,
  logbookScopeEditLoading: false,
  logbookScopeEditLoaded: false,
  logbookScopesBulkEditLoading: false,
  logbookScopesBulkEditLoaded: false,
};

export function logbookScopesReducer(
  state: ILogbookScopesState = logbookScopesState,
  baseAction: Action,
): ILogbookScopesState {
  const action = baseAction as Actions.LogbookScopeActions;

  switch (action.type) {
    case Actions.LOGBOOK_SCOPES_DATA_LOADING:
      return {
        ...state,
        logbookScopesDataLoading: true,
        logbookScopesDataLoaded: false,
      };
    case Actions.LOGBOOK_SCOPES_DATA_LOADED:
      return {
        ...state,
        logbookScopes: action.payload,
        logbookScopesDataLoading: false,
        logbookScopesDataLoaded: true,
      };
    case Actions.ADD_LOGBOOK_SCOPE:
      return {
        ...state,
        logbookScopeAddLoading: true,
        logbookScopeAddLoaded: false,
      };
    case Actions.ADD_LOGBOOK_SCOPE_COMPLETED:
      return {
        ...state,
        logbookScopeAddLoading: false,
        logbookScopeAddLoaded: true,
      };
    case Actions.EDIT_LOGBOOK_SCOPE:
      return {
        ...state,
        logbookScopeEditLoading: true,
        logbookScopeEditLoaded: false,
      };
    case Actions.EDIT_LOGBOOK_SCOPE_COMPLETED:
      return {
        ...state,
        logbookScopeEditLoading: false,
        logbookScopeEditLoaded: true,
      };
    case Actions.BULK_EDIT_LOGBOOK_SCOPES:
      return {
        ...state,
        logbookScopesBulkEditLoading: true,
        logbookScopesBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_LOGBOOK_SCOPES_COMPLETED:
      return {
        ...state,
        logbookScopesBulkEditLoading: false,
        logbookScopesBulkEditLoaded: true,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        logbookScopeAddLoading: false,
        logbookScopeEditLoading: false,
      };
    default:
      return state;
  }
}
