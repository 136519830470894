import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'top-loader',
  templateUrl: './top-loader.component.html',
  styleUrls: ['./top-loader.component.scss'],
})
export class TopLoaderComponent implements OnInit, OnDestroy {
  public topLoading$: boolean = false;
  private appStoreSubscription!: Subscription;

  constructor(private store: Store<LogbookAppState>) {}
  ngOnDestroy(): void {
    if (this.appStoreSubscription) {
      this.appStoreSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.appStoreSubscription = this.store.select('app').subscribe((state) => {
      this.topLoading$ = state.topLoading;
    });
  }
}
