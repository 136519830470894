import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IAddState, IBulkEditStates, IState, IValidatePayload, IValidationResult } from './states.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  private readonly routes = {
    STATES: '/states/',
    BULK_EDIT: 'bulk-edit/',
    VALIDATE: 'validate/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getStates(params: Record<string, string | number>): Observable<IGetManyResponse<IState>> {
    return this.http.post<IGetManyResponse<IState>>(
      `${this.baseUrl}${this.routes.STATES}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addState(state: IAddState, issuer: IIssuer | null): Observable<IBaseOneResponse<IState>> {
    return this.http.post<IBaseOneResponse<IState>>(`${this.baseUrl}${this.routes.STATES}`, {
      issuer,
      payload: state,
    });
  }

  public editState(
    state: IAddState,
    id: number,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IState>> {
    return this.http.put<IBaseOneResponse<IState>>(`${this.baseUrl}${this.routes.STATES}${id}/`, {
      issuer: issuerAndReason.issuer,
      reason: issuerAndReason.reason,
      payload: state,
    });
  }

  public bulkEditStates(
    states: IBulkEditStates[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IState>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IState>>>(
      `${this.baseUrl}${this.routes.STATES}${this.routes.BULK_EDIT}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: states,
      },
    );
  }

  public deleteState(
    stateIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IState>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IState>>>(`${this.baseUrl}${this.routes.STATES}`, {
      body: {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: stateIds,
      },
    });
  }

  public validateUtilization(
    payload: IValidatePayload,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<IValidationResult>> {
    return this.http.post<IGetManyResponse<IValidationResult>>(
      `${this.baseUrl}${this.routes.STATES}${this.routes.VALIDATE}`,
      {
        issuer,
        payload,
      },
    );
  }
}
