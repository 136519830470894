<ng-template [ngIf]="!isSubmitted" [ngIfElse]="formio">
  <form-builder [form]="form" [options]="formIoConfiguration" #formBuilderComponent></form-builder>
</ng-template>
<ng-template #formio>
  <form action="javascript:void(0);" onSubmit="return false;" autocomplete="off">
    <scw-formio
      [form]="{
        title: 'My Test Form',
        components: form.components
      }"
    ></scw-formio>
  </form>
</ng-template>
<scw-mat-button (onClick)="buildForm()"></scw-mat-button>
