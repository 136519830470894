import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IFilterComponent, ISimpleOutputOptions } from '../filter.class';
import { BehaviorSubject, Subject, Subscription, take, timer } from 'rxjs';
import { IGenericObject } from '../../../model/interface/generic.model';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy, IFilterComponent {
  outputOptions!: ISimpleOutputOptions;
  searchBoxNgModel!: string;
  elementID!: string;
  outputSubject!: Subject<any>;

  @Input() maxLength: number = 100;
  @Input() placeHolder: string = this.translate.instant('filterCard.searchBox.placeHolder');
  @Input() cls: string = 'form-control';
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private delayTimer!: Subscription;
  private disabledSubjectSubscription!: Subscription;
  public isDisabled: boolean = false;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus:boolean) => {
      this.isDisabled = disabledStatus;
    });
  }
  public getFiltersOutputs(): void | {} {
    this.delayTimer = timer(600).pipe(take(1)).subscribe(() => {
      const output: IGenericObject<any> = {};
      output[this.outputOptions.filterObjectId] = this.searchBoxNgModel;
      this.outputSubject.next(output);
      return output;
    });
    this.delayTimer?.unsubscribe();
  }
  ngOnDestroy(): void {
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();
  }
}
