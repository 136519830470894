import * as Actions from './page-header.actions';
import { IPageHeaderState } from './page-header.model';
import { Action } from '@ngrx/store';

const initialState: IPageHeaderState = {
  isDashboardMode: false,
  isFilterBarVisible: false,
  isPrintAllowed: false,
  isPrintButtonDisabled: false,
  hideHeaderActions: false,
  pageHeader: {},
};

export function pageHeaderReducer(
  state: IPageHeaderState = initialState,
  baseAction: Action,
): IPageHeaderState {
  const action = baseAction as Actions.PageHeaderActions;

  switch (action.type) {
    case Actions.UPDATE_PAGE_MODE:
      return {
        ...state,
        isDashboardMode: action.isDashboardMode,
      };
    case Actions.UPDATE_FILTER_BAR_VISIBILITY:
      return {
        ...state,
        isFilterBarVisible: action.isVisible,
      };
    case Actions.RUN_PRINT_FUNCTIONALITY:
      return {
        ...state,
        isPrintAllowed: action.isPrintAllowed,
      };
    case Actions.CHANGE_PRINT_BUTTON_STATUS:
      return {
        ...state,
        isPrintButtonDisabled: action.isPrintButtonDisabled,
      };
    case Actions.UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY:
      return {
        ...state,
        ...action.isPageHeaderButtonsVisible,
      };
    case Actions.SET_PAGE_HEADER_INFO:
      return {
        ...state,
        pageHeader: action.pageHeader,
      };
    case Actions.RESET_STORE_TO_DEFAULT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
