import { TranslateService } from '@ngx-translate/core';
import {
  EExcelExportWorkerResponseAction,
  EExcelExporterWorkerRequestAction,
  ICreateAndDownloadOptions,
  ICreateExcel,
} from './excel-creator.type';
import { Injectable, OnDestroy } from '@angular/core';
import * as fs from 'file-saver';

@Injectable({ providedIn: 'root' })
export class ExcelExporterService implements OnDestroy {
  private static readonly WORKER_PREFIX = 'export_excel_worker';
  private static readonly MAX_RETRIES_FOR_WORKER_ID = 10;

  private readonly requiredTranslations: { key: string, value: string }[] = [
    {
      key: 'apiErrorMessages.properties.id',
      value: this.translateService.instant('apiErrorMessages.properties.id')
    },
    {
      key: 'scwMatForm.validation.required',
      value: this.translateService.instant('scwMatForm.validation.required')
    },
    {
      key: 'excel.dateTime.error',
      value: this.translateService.instant('excel.dateTime.error')
    },
    {
      key: 'excel.date.error',
      value: this.translateService.instant('excel.date.error')
    },
    {
      key: 'excel.dateTime.prompt',
      value: this.translateService.instant('excel.dateTime.prompt')
    },
    {
      key: 'excel.date.prompt',
      value: this.translateService.instant('excel.date.prompt')
    },
  ];

  private workers: Record<string, Worker> = {};

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  public createAndDownload(
    sheetTitle: string,
    excelName: string,
    baseParams: ICreateExcel,
    options: Partial<ICreateAndDownloadOptions>,
    onComplate: (blob: Blob) => void,
    onError: (errorEvent: ErrorEvent | Error) => void,
  ): void {
    const { worker, workerID } = this.createNewWorker();

    worker.onmessage = this.assignHandlerForExportProcess(workerID, excelName, onComplate, onError);

    worker.postMessage(
      {
        action: EExcelExporterWorkerRequestAction.EXECUTE_EXPORT_PROCESS,
        payload: {
          sheetTitle,
          excelName,
          params: baseParams,
          options,
        },
        dependencies: {
          translations: this.requiredTranslations,
        }
      }
    );
  }

  private assignHandlerForExportProcess(
    workerID: string,
    excelName: string,
    onComplate: (blob: Blob) => void,
    onError: (errorEvent: ErrorEvent | Error) => void,
  ): (event: MessageEvent) => void {
    return (event: MessageEvent) => {
      switch (event.data.action) {
        case EExcelExportWorkerResponseAction.TRANSMIT_RESULT:
          onComplate(event.data.payload);
          this.triggerDownload(event.data.payload, excelName);
          this.terminateWorkers([workerID]);
          break;
        case EExcelExportWorkerResponseAction.ERROR:
          onError(event.data.payload);
          this.terminateWorkers([workerID]);
          break;
        default:
          break;
      }
    };
  }

  private triggerDownload(blob: Blob, fileName: string): void {
    fs.saveAs(blob, fileName);
  }

  private createNewWorker(): { worker: Worker, workerID: string } {
    let workerID = this.generateWorkerID();

    let retries = 0;
    while (this.workers[workerID]) {
      if (retries > ExcelExporterService.MAX_RETRIES_FOR_WORKER_ID) {
        throw new Error('An error occurred while creating a new worker. Please try again.');
      }

      workerID = this.generateWorkerID();
      retries++;
    }

    const worker = new Worker(new URL('./worker/excel-exporter.worker', import.meta.url), { type: 'module', name: workerID });
    worker.postMessage({ action: EExcelExporterWorkerRequestAction.SET_WORKER_ID, payload: { workerID } });

    this.workers[workerID] = worker;

    return { worker, workerID };
  }

  private terminateWorkers(workerIDList: string[] = []): void {
    (workerIDList.length ? workerIDList : Object.keys(this.workers)).forEach(
      (workerID: string) => {
        if (this.workers[workerID]) {
          this.workers[workerID].terminate();
          delete this.workers[workerID];
        }
      }
    );
  }

  private generateWorkerID(prefix?: string): string {
    return [prefix ?? ExcelExporterService.WORKER_PREFIX, Date.now(), self.crypto.randomUUID()].join('|');
  }

  public ngOnDestroy(): void {
    this.terminateWorkers();
  }
}