import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IMasterDataVersion, IMasterDataTasksData, IFieldSetData } from './master-data-tasks.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IMasterDataTasksHistory } from '../../../view/my-tasks/master-data-tasks/master-data-tasks.component.model';
import { ITableHeader } from '../../../../constants.model';
import { IReview, IReviewResults } from '../my-tasks.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';

export const START_MASTER_DATA_TASKS_LOADING = '[Master Data Tasks] Start Master Data Tasks Loading';
export const MASTER_DATA_TASKS_LOADED = '[Master Data Tasks] Master Data Tasks Loaded';
export const START_MASTER_DATA_TASKS_BEFORE_RECORD_LOADING =
  '[Master Data Tasks] Start Master Data Tasks Before Record Loading';
export const MASTER_DATA_TASKS_BEFORE_RECORD_LOADED = '[Master Data Tasks] Master Data Tasks Before Record Loaded';
export const START_MASTER_DATA_TASKS_AFTER_RECORD_LOADING =
  '[Master Data Tasks] Start Master Data Tasks After Record Loading';
export const MASTER_DATA_TASKS_AFTER_RECORD_LOADED = '[Master Data Tasks] Master Data Tasks After Record Loaded';
export const START_MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADING =
  '[Master Data Tasks] Start Master Data Tasks Before FieldSet Loading';
export const MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADED = '[Master Data Tasks] Master Data Tasks Before FieldSet Loaded';
export const START_MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADING =
  '[Master Data Tasks] Start Master Data Tasks After FieldSet Loading';
export const MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADED = '[Master Data Tasks] Master Data Tasks After FieldSet Loaded';
export const START_MASTER_DATA_TASKS_HISTORY_LOADING = '[Master Data Tasks] Start Master Data Tasks History Loading';
export const MASTER_DATA_TASKS_HISTORY_LOADED = '[Master Data Tasks] Master Data Tasks History Loaded';
export const START_MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADING =
  '[Master Data Tasks] Start Master Data Tasks Active Version History Loading';
export const MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADED =
  '[Master Data Tasks] Master Data Tasks Active Version History Loaded';
export const START_MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADING =
  '[Master Data Tasks] Start Master Data Tasks Current Version History Loading';
export const MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADED =
  '[Master Data Tasks] Master Data Tasks Current Version History Loaded';
export const START_MASTER_DATA_TASKS_SELECTED_FORMS_LOADING =
  '[Master Data Tasks] Start Master Data Tasks Selected Forms Loading';
export const MASTER_DATA_TASKS_SELECTED_FORMS_LOADED = '[Master Data Tasks] Master Data Tasks Selected Forms Loaded';
export const START_ENTRY_APPROVE_LOADING = '[Master Data Tasks] Entry Approve Loading';
export const ENTRY_APPROVE_LOADED = '[Master Data Tasks] Entry Approve Loaded';
export const START_ENTRY_REJECT_LOADING = '[Master Data Tasks] Entry Reject Loading';
export const ENTRY_REJECT_LOADED = '[Master Data Tasks] Entry Reject Loaded';
export const START_ENTRY_REJECT_PERMANENT_LOADING = '[Master Data Tasks] Entry Reject Permanent Loading';
export const ENTRY_REJECT_PERMANENT_LOADED = '[Master Data Tasks] Entry Reject Permanent Loaded';
export const SET_TABLE_SETTINGS = '[Master Data Tasks] Master Data Tasks Set Table Settings';
export const START_WORKFLOW_DATA_LOADING = '[Master Data Tasks] Start Workflow Data Loading';
export const WORKFLOW_DATA_LOADED = '[Master Data Tasks] Workflow Loaded';
export const CLEAR_STATE = '[Master Data Tasks] Clear State';
export const FETCH_ERROR = '[Master Data Tasks] Fetch Error';

export class StartMasterDataTasksLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_LOADING;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery) {}
}

export class MasterDataTasksLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_LOADED;

  constructor(public payload: IGetManyResponse<IMasterDataTasksData>) {}
}

export class StartMasterDataTasksBeforeRecordLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_BEFORE_RECORD_LOADING;

  constructor(public fieldVersionType: string) {}
}

export class MasterDataTasksBeforeRecordLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_BEFORE_RECORD_LOADED;

  constructor(public payload: IMasterDataVersion) {}
}

export class StartMasterDataTasksAfterRecordLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_AFTER_RECORD_LOADING;

  constructor(public id: number) {}
}

export class MasterDataTasksAfterRecordLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_AFTER_RECORD_LOADED;

  constructor(public payload: IMasterDataVersion) {}
}

export class StartMasterDataTasksBeforeFieldSetLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class MasterDataTasksBeforeFieldSetLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartMasterDataTasksAfterFieldSetLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class MasterDataTasksAfterFieldSetLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartMasterDataTasksHistoryLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_HISTORY_LOADING;

  constructor(public id: number) {}
}

export class MasterDataTasksHistoryLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IMasterDataTasksHistory>) {}
}

export class StartMasterDataTasksActiveVersionHistoryLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADING;

  constructor(public id: number) {}
}

export class MasterDataTasksActiveVersionHistoryLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IMasterDataTasksHistory>) {}
}

export class StartMasterDataTasksCurrentVersionHistoryLoading implements Action {
  readonly type = START_MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADING;

  constructor(public id: number) {}
}

export class MasterDataTasksCurrentVersionHistoryLoaded implements Action {
  readonly type = MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IMasterDataTasksHistory>) {}
}

export class StartEntryApproveLoading implements Action {
  readonly type = START_ENTRY_APPROVE_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryApproveLoaded implements Action {
  readonly type = ENTRY_APPROVE_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectLoading implements Action {
  readonly type = START_ENTRY_REJECT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryRejectLoaded implements Action {
  readonly type = ENTRY_REJECT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectPermanentLoading implements Action {
  readonly type = START_ENTRY_REJECT_PERMANENT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null) {}
}

export class EntryRejectPermanentLoaded implements Action {
  readonly type = ENTRY_REJECT_PERMANENT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;

  constructor(public payload: IGenericObject<any>) {}
}

export class StartWorkflowDataLoading implements Action {
  readonly type = START_WORKFLOW_DATA_LOADING;
}

export class WorkflowDataLoaded implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export type MasterDataTasksActions =
  | StartMasterDataTasksLoading
  | MasterDataTasksLoaded
  | StartMasterDataTasksBeforeRecordLoading
  | MasterDataTasksBeforeRecordLoaded
  | StartMasterDataTasksAfterRecordLoading
  | MasterDataTasksAfterRecordLoaded
  | StartMasterDataTasksBeforeFieldSetLoading
  | MasterDataTasksBeforeFieldSetLoaded
  | StartMasterDataTasksAfterFieldSetLoading
  | MasterDataTasksAfterFieldSetLoaded
  | StartMasterDataTasksHistoryLoading
  | MasterDataTasksHistoryLoaded
  | StartMasterDataTasksActiveVersionHistoryLoading
  | MasterDataTasksActiveVersionHistoryLoaded
  | StartMasterDataTasksCurrentVersionHistoryLoading
  | MasterDataTasksCurrentVersionHistoryLoaded
  | StartEntryApproveLoading
  | EntryApproveLoaded
  | StartEntryRejectLoading
  | EntryRejectLoaded
  | StartEntryRejectPermanentLoading
  | EntryRejectPermanentLoaded
  | SetTableSettings
  | FetchError
  | ClearState
  | StartWorkflowDataLoading
  | WorkflowDataLoaded;
