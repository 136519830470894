<li
  *ngFor="let root of navItems; index as i"
  class="header-notification navbar-root ripple light"
  (click)="root.children.length ? toggleNavBarDropdown(root.name) : goLink({ external: false, state: root.link })"
  (clickOutside)="collapseOpenDropdown(root.name)"
>
  {{ root.nameKey || '' | translate }}
  <em *ngIf="root.children.length" class="dropdown-icon feather icon-chevron-down"></em>
  <ul
    *ngIf="root.children.length"
    class="router-dropdown show-notification profile-notification"
    [ngClass]="navBarDropdowns[root.name]['klass']"
    [@notificationBottom]="navBarDropdowns[root.name]['state']"
  >
    <li *ngFor="let menuItem of root['children']; index as i" class="ripple">
      <a (click)="goLink({ external: false, state: menuItem.link })">
        {{ menuItem.nameKey || '' | translate }}
      </a>
    </li>
  </ul>
</li>
