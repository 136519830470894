import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../workflows/workflows.model';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IFormMasterDataFormPayload } from '../../../view/settings/master-data/form-master-data/form-master-data.component.model';
import { IFormMasterData, IFormMasterDataDetail, IFormMasterDataHistory } from './form-master-data.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export const FORM_MASTER_DATA_DATA_LOAD = '[FORM_MASTER_DATA] FORM_MASTER_DATA DATA LOAD';
export const FORM_MASTER_DATA_REFRESH_LOAD = '[FORM_MASTER_DATA] FORM_MASTER_REFRESH DATA LOAD';
export const FORM_MASTER_DATA_DATA_LOADED = '[FORM_MASTER_DATA] FORM_MASTER_DATA DATA LOADED';
export const FORM_MASTER_DATA_ADD_LOAD = '[FORM_MASTER_ADD] FORM_MASTER_ADD DATA LOAD';
export const FORM_MASTER_DATA_ADD_LOADED = '[FORM_MASTER_ADD] FORM_MASTER_ADD DATA LOADED';
export const FORM_MASTER_DATA_EDIT_LOAD = '[FORM_MASTER_EDIT] FORM_MASTER_EDIT DATA LOAD';
export const FORM_MASTER_DATA_EDIT_LOADED = '[FORM_MASTER_EDIT] FORM_MASTER_EDIT DATA LOADED';
export const FORM_MASTER_DATA_HISTORY_DATA_LOAD = '[FORM_MASTER_DATA_HISTORY] FORM_MASTER_DATA_HISTORY DATA LOAD';
export const FORM_MASTER_DATA_HISTORY_DATA_LOADED = '[FORM_MASTER_DATA_HISTORY] FORM_MASTER_DATA_HISTORY DATA LOADED';
export const FORM_MASTER_DATA_DETAIL_LOAD = '[FORM_MASTER_DETAIL] FORM_MASTER_DETAIL DATA LOAD';
export const FORM_MASTER_DATA_DETAIL_LOADED = '[FORM_MASTER_DETAIL] FORM_MASTER_DETAIL DATA LOADED';
export const FORM_MASTER_DATA_SEND_TO_REVIEW_LOAD = '[FORM_MASTER_SEND_TO_REVIEW] FORM_MASTER_SEND_TO_REVIEW DATA LOAD';
export const FORM_MASTER_DATA_SEND_TO_REVIEW_LOADED =
  '[FORM_MASTER_SEND_TO_REVIEW] FORM_MASTER_SEND_TO_REVIEW DATA LOADED';
export const FORM_MASTER_DATA_ACTIVATE_LOAD = '[FORM_MASTER_ACTIVATE] FORM_MASTER_ACTIVATE DATA LOAD';
export const FORM_MASTER_DATA_ACTIVATE_LOADED = '[FORM_MASTER_ACTIVATE] FORM_MASTER_ACTIVATE DATA LOADED';
export const FORM_MASTER_DATA_DELETE_LOAD = '[FORM_MASTER_DELETE] FORM_MASTER_DELETE DATA LOAD';
export const FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD =
  '[FORM_MASTER_APPROVAL_WORKFLOW] FORM_MASTER_APPROVAL_WORKFLOW DATA LOAD';
export const FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOADED =
  '[FORM_MASTER_APPROVAL_WORKFLOW] FORM_MASTER_APPROVAL_WORKFLOW DATA LOADED';
export const FORM_MASTER_DATA_DELETE_LOADED = '[FORM_MASTER_DELETE] FORM_MASTER_DELETE DATA LOADED';
export const FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD = '[FORM_MASTER_WORKFLOW_STEPS] FORM_MASTER_WORKFLOW_STEPS DATA LOAD';
export const FORM_MASTER_DATA_WORKFLOW_STEPS_LOADED =
  '[FORM_MASTER_WORKFLOW_STEPS] FORM_MASTER_WORKFLOW_STEPS DATA LOADED';
export const FORM_MASTER_DATA_CLONE_LOAD = '[FORM_MASTER_CLONE] FORM_MASTER_CLONE DATA LOAD';
export const FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOAD =
  '[FORM_MASTER_PASSED_ISSUE_DATE] FORM_MASTER_PASSED_ISSUE_DATE DATA LOAD';
export const FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOADED =
  '[FORM_MASTER_PASSED_ISSUE_DATE] FORM_MASTER_PASSED_ISSUE_DATE DATA LOADED';
export const FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD =
  '[FORM_MASTER_DRAFT_VERSION_EXISTS] FORM_MASTER_DRAFT_VERSION_EXISTS DATA LOAD';
export const FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED =
  '[FORM_MASTER_DRAFT_VERSION_EXISTS] FORM_MASTER_DRAFT_VERSION_EXISTS DATA LOADED';
export const FETCH_ERROR = '[FORM_MASTER_DATA] FETCH ERROR';

export class FormMasterDataDataLoad implements Action {
  readonly type = FORM_MASTER_DATA_DATA_LOAD;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery) {}
}

export class FormMasterDataDataLoaded implements Action {
  readonly type = FORM_MASTER_DATA_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IFormMasterData>) {}
}

export class FormMasterDataAddLoad implements Action {
  readonly type = FORM_MASTER_DATA_ADD_LOAD;

  constructor(public payload: IFormMasterDataFormPayload, public issuerAndReason: IIssuerAndReason) {}
}

export class FormMasterDataAddLoaded implements Action {
  readonly type = FORM_MASTER_DATA_ADD_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataEditLoad implements Action {
  readonly type = FORM_MASTER_DATA_EDIT_LOAD;

  constructor(public id: number | null, public payload: IFormMasterDataFormPayload, public issuer: IIssuer | null) {}
}

export class FormMasterDataEditLoaded implements Action {
  readonly type = FORM_MASTER_DATA_EDIT_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataHistoryDataLoad implements Action {
  readonly type = FORM_MASTER_DATA_HISTORY_DATA_LOAD;

  constructor(public id: number) {}
}

export class FormMasterDataHistoryDataLoaded implements Action {
  readonly type = FORM_MASTER_DATA_HISTORY_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IFormMasterDataHistory>) {}
}

export class FormMasterDataDetailLoad implements Action {
  readonly type = FORM_MASTER_DATA_DETAIL_LOAD;

  constructor(
    public id: number,
    public dispatchHideLoader: boolean = true,
    public getPreviousVersion: boolean = false,
  ) {}
}

export class FormMasterDataDetailLoaded implements Action {
  readonly type = FORM_MASTER_DATA_DETAIL_LOADED;

  constructor(public payload: IBaseOneResponse<IFormMasterDataDetail>) {}
}

export class FormMasterDataSendToReviewLoad implements Action {
  readonly type = FORM_MASTER_DATA_SEND_TO_REVIEW_LOAD;

  constructor(public id: number | null, public issuer: IIssuer | null) {}
}

export class FormMasterDataSendToReviewLoaded implements Action {
  readonly type = FORM_MASTER_DATA_SEND_TO_REVIEW_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataActivateLoad implements Action {
  readonly type = FORM_MASTER_DATA_ACTIVATE_LOAD;

  constructor(public id: number | null, public issuer: IIssuer | null) {}
}

export class FormMasterDataActivateLoaded implements Action {
  readonly type = FORM_MASTER_DATA_ACTIVATE_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataDeleteLoad implements Action {
  readonly type = FORM_MASTER_DATA_DELETE_LOAD;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class FormMasterDataDeleteLoaded implements Action {
  readonly type = FORM_MASTER_DATA_DELETE_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataApprovalWorkflowLoad implements Action {
  readonly type = FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD;

  constructor(public searchText: string, public limit?: number, public skipLoader?: boolean) {}
}

export class FormMasterDataApprovalWorkflowLoaded implements Action {
  readonly type = FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export class FormMasterDataWorkflowsStepsLoad implements Action {
  readonly type = FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD;

  constructor(public id: number) {}
}

export class FormMasterDataWorkflowsStepsLoaded implements Action {
  readonly type = FORM_MASTER_DATA_WORKFLOW_STEPS_LOADED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class FormMasterDataCloneLoad implements Action {
  readonly type = FORM_MASTER_DATA_CLONE_LOAD;

  constructor(public id: number | null, public issuerAndReason: IIssuerAndReason) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: IGenericObject<any>) {}
}

export class FormMasterDataRefreshLoad implements Action {
  readonly type = FORM_MASTER_DATA_REFRESH_LOAD;

  constructor(public payload: FormMasterDataActions['type'] | null = null) {}
}

export class FormMasterDataPassedIssueDateLoad implements Action {
  readonly type = FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOAD;
}

export class FormMasterDataPassedIssueDateLoaded implements Action {
  readonly type = FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOADED;

  constructor(public payload: IGetManyResponse<IFormMasterData>) {}
}

export class FormMasterDataDraftVersionExistsLoad implements Action {
  readonly type = FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD;
}

export class FormMasterDataDraftVersionExistsLoaded implements Action {
  readonly type = FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED;

  constructor(public payload: IGetManyResponse<IFormMasterData>) {}
}

export type FormMasterDataActions =
  | FormMasterDataDataLoad
  | FormMasterDataDataLoaded
  | FormMasterDataAddLoad
  | FormMasterDataAddLoaded
  | FormMasterDataEditLoad
  | FormMasterDataEditLoaded
  | FormMasterDataHistoryDataLoad
  | FormMasterDataHistoryDataLoaded
  | FormMasterDataDetailLoad
  | FormMasterDataDetailLoaded
  | FormMasterDataDeleteLoad
  | FormMasterDataDeleteLoaded
  | FormMasterDataSendToReviewLoad
  | FormMasterDataSendToReviewLoaded
  | FormMasterDataActivateLoad
  | FormMasterDataActivateLoaded
  | FormMasterDataApprovalWorkflowLoad
  | FormMasterDataApprovalWorkflowLoaded
  | FormMasterDataWorkflowsStepsLoad
  | FormMasterDataWorkflowsStepsLoaded
  | FormMasterDataCloneLoad
  | FormMasterDataRefreshLoad
  | FormMasterDataPassedIssueDateLoad
  | FormMasterDataPassedIssueDateLoaded
  | FormMasterDataDraftVersionExistsLoad
  | FormMasterDataDraftVersionExistsLoaded
  | FetchError;

export const FORM_MASTER_DATA_ACTIONS: {
  FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_EDIT_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_EDIT_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DETAIL_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_SEND_TO_REVIEW_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_HISTORY_DATA_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_WORKFLOW_STEPS_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_ADD_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_SEND_TO_REVIEW_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_ACTIVATE_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DELETE_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DELETE_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DATA_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_HISTORY_DATA_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_ACTIVATE_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DATA_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_ADD_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DETAIL_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_CLONE_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_REFRESH_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOADED: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD: FormMasterDataActions['type'];
  FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED: FormMasterDataActions['type'];
  FETCH_ERROR: FormMasterDataActions['type'];
} = {
  FORM_MASTER_DATA_DATA_LOAD,
  FORM_MASTER_DATA_DATA_LOADED,
  FORM_MASTER_DATA_ADD_LOAD,
  FORM_MASTER_DATA_ADD_LOADED,
  FORM_MASTER_DATA_EDIT_LOAD,
  FORM_MASTER_DATA_EDIT_LOADED,
  FORM_MASTER_DATA_HISTORY_DATA_LOAD,
  FORM_MASTER_DATA_HISTORY_DATA_LOADED,
  FORM_MASTER_DATA_DETAIL_LOAD,
  FORM_MASTER_DATA_DETAIL_LOADED,
  FORM_MASTER_DATA_SEND_TO_REVIEW_LOAD,
  FORM_MASTER_DATA_SEND_TO_REVIEW_LOADED,
  FORM_MASTER_DATA_DELETE_LOAD,
  FORM_MASTER_DATA_DELETE_LOADED,
  FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOAD,
  FORM_MASTER_DATA_APPROVAL_WORKFLOW_LOADED,
  FORM_MASTER_DATA_WORKFLOW_STEPS_LOAD,
  FORM_MASTER_DATA_WORKFLOW_STEPS_LOADED,
  FORM_MASTER_DATA_ACTIVATE_LOAD,
  FORM_MASTER_DATA_ACTIVATE_LOADED,
  FORM_MASTER_DATA_CLONE_LOAD,
  FORM_MASTER_DATA_REFRESH_LOAD,
  FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOAD,
  FORM_MASTER_DATA_PASSED_ISSUE_DATE_LOADED,
  FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD,
  FORM_MASTER_DATA_DRAFT_VERSION_EXISTS_LOADED,
  FETCH_ERROR,
};
