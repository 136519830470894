import { NgModule } from '@angular/core';
import { ScwMatSelectComponent } from './scw-mat-select.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScwMatSearchModule } from '../scw-mat-search/scw-mat-search.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    AngularMultiSelectModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [ScwMatSelectComponent],
  declarations: [ScwMatSelectComponent],
})
export class ScwMatSelectModule {}
