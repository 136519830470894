import * as Actions from './forms.actions';
import { IFormsState } from './forms.model';
import { Action } from '@ngrx/store';

export const formsState: IFormsState = {
  formsDataLoaded: false,
  formsDataLoading: false,
  forms: [],
  allFormVersionsDataLoaded: false,
  allFormVersionsDataLoading: false,
  allFormVersions: [],
  allVersionTotal: 0,
  formAddLoading: false,
  formAddLoaded: false,
  formEditLoading: false,
  formEditLoaded: false,
  formsBulkEditLoading: false,
  formsBulkEditLoaded: false,
};

export function formsReducer(
  state: IFormsState = formsState,
  baseAction: Action): IFormsState {
  const action: Actions.FormsActions = baseAction as Actions.FormsActions;
  switch (action.type) {
    case Actions.FORMS_DATA_LOADING:
      return {
        ...state,
        ...{
          formsDataLoading: true,
          formsDataLoaded: false,
        },
      };
    case Actions.FORMS_DATA_LOADED:
      return {
        ...state,
        ...{
          forms: action.payload.data,
          formsTotal: action.payload.total,
          formsDataLoading: false,
          formsDataLoaded: true,
        },
      };
    case Actions.ALL_FORM_VERSIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          allFormVersionsDataLoading: true,
          allFormVersionsDataLoaded: false,
        },
      };
    case Actions.ALL_FORM_VERSIONS_DATA_LOADED:
      return {
        ...state,
        ...{
          allFormVersions: action.payload.data,
          allVersionTotal: action.payload.total,
          allFormVersionsDataLoading: false,
          allFormVersionsDataLoaded: true,
        },
      };
    case Actions.ADD_FORM:
      return {
        ...state,
        formAddLoading: true,
        formAddLoaded: false,
      };
    case Actions.ADD_FORM_COMPLETED:
      return {
        ...state,
        formAddLoading: false,
        formAddLoaded: true,
      };
    case Actions.EDIT_FORM:
      return {
        ...state,
        formEditLoading: true,
        formEditLoaded: false,
      };
    case Actions.EDIT_FORM_COMPLETED:
      return {
        ...state,
        formEditLoading: false,
        formEditLoaded: true,
      };
    default:
      return state;
  }
}
