import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss'],
})
export class InvalidLinkComponent {
  constructor(public translate: TranslateService) {}
}
