import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import * as AppActions from '../../../store/app/actions';
import * as MainActions from '../../../store/main/main.actions';
import { Store } from '@ngrx/store';
import { HelperService } from '../../service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { PageHeaderService } from '../../service/page-header/page-header.service';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { ImageCompression, jsPDF, jsPDFOptions } from 'jspdf';
import {
  EElementSelector,
  EPdfInitialElementId,
  IBanner,
  IChart,
  IFile,
  IFilter,
  IFilterMap,
  IFormPdfProperties,
  IPadding,
  IPageHeaderFooterInfo,
  ISearchSelectorCounters,
  ISplitCanvasProperties,
  Orientation,
  PageOrder,
} from './file-generator.model';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { IPageHeader } from '../../service/page-header/page-header.model';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import * as htmlToImage from 'html-to-image';
import { fontawesomeCssBase64Data } from '../../../../assets/icon/fontawesome/fontawesome-css-data';

@Component({
  selector: 'file-generator',
  template:
    '<link href="assets/fonts/ZenKakuGothicAntique-Regular.ttf" rel="prefetch" as="font" type="font/tff" class="d-none">',
})
export class FileGeneratorComponent implements OnInit, OnDestroy {
  @Input() isPrintAllowed: Subject<boolean> = new Subject<boolean>();
  @Input() file!: IFile;
  @Input() filterParameters?: IFilter;
  @Input() filterMap!: IFilterMap[];
  @Input() bannerConfig: IBanner = FileGeneratorComponent.bannerDefaultConfig;
  @Input() filteredValuesAsText!: string;
  @Input() elementSelector: EElementSelector = EElementSelector.IS_PRINTABLE;
  @Input() shouldPrintFilters: boolean = true;

  private chartData: IChart[] = [];
  private readonly shortEdge: number = 210;
  private readonly longEdge: number = 297;
  private readonly rightOrLeftPadding: number = 15;
  private readonly topOrBottomPadding: number = 5;
  private readonly headerHeight: number = this.topOrBottomPadding * 2 + 15;
  private readonly pageType: string = 'a4';
  private readonly fileFormat: string = 'JPEG';
  private readonly imageCompression: ImageCompression = 'FAST';
  private readonly defaultImageAlias: string = '';
  private readonly dataUrlType: string = 'image/jpeg';
  private readonly dataUrlQuality: number = 0.5;
  private readonly filterTranslation = this.translate.instant('general.filters');
  private readonly dateTranslation = this.translate.instant('general.dateLabel');
  private readonly ignoreCanvasElements: string[] = ['sunburst-chart'];
  private downloadedFileName!: string;
  private pageHeaderSubscription!: Subscription;
  private allCanvasOfPage: HTMLCanvasElement[] | HTMLElement[] = [];
  private headerImage: HTMLImageElement = new Image();
  private defaultImage: HTMLImageElement = new Image();
  private footerImage: HTMLImageElement = new Image();
  private deletedReferencePage: number[] = [];
  private filteredChartData: IChart[] = [];
  private pageWidth: number = 210;
  private pageHeight: number = 297;
  private pageHeaderFooterInfo: IPageHeaderFooterInfo[] = [];
  public static bannerDefaultConfig: IBanner = {
    header: {
      title: '',
      uri: 'assets/images/logbook-logo.png',
    },
    footer: {
      uri: 'assets/images/scw-logo-2.png',
    },
  };
  public defaultFilePadding: IPadding = {
    top: 25,
    bottom: 90,
    left: 15,
    right: 15,
  };

  public defaultChartData: IChart = {
    id: 1,
    page: {
      padding: this.defaultFilePadding,
      order: PageOrder.First,
      orientation: Orientation.portrait,
    },
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly pageHeaderService: PageHeaderService,
    private readonly store: Store<LogbookAppState>,
  ) {}

  public ngOnInit(): void {
    this.isPrintAllowed.subscribe((isPrintAllowed) => {
      if (!isPrintAllowed) {
        return;
      }

      this.store.dispatch(new AppActions.ShowLoader());
      setTimeout(() => {
        this.downloadPDF()
          .then(() => {
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new MainActions.PdfDownloaded());
          })
          .catch((e) => {
            console.log(e);
            this.store.dispatch(new AppActions.HideLoader());
          });
      });
    });
  }

  private initializeJsPDF(): jsPDF {
    let orientation: Orientation = Orientation.portrait;

    if (this.chartData[0].page) {
      orientation = this.chartData[0].page.orientation;
    }

    const pdfOptions: jsPDFOptions = {
      orientation,
      compress: true,
    };

    return new jsPDF(pdfOptions);
  }

  public async downloadPDF(): Promise<void> {
    this.store.dispatch(new AppActions.ShowLoader());
    this.findPrintElements();
    this.setChartData();

    if (!this.chartData) {
      return;
    }

    const doc: jsPDF = this.initializeJsPDF();

    doc.addFont('assets/fonts/OpenSans-Regular.ttf', 'OpenSans', 'normal');
    doc.setFont('OpenSans', 'normal');

    if (this.elementSelector !== EElementSelector.CANVAS) {
      await this.convertToCanvas();
    }

    this.filterAllCanvas();
    this.sortChartsByPageOrder();

    if (!this.allCanvasOfPage || this.allCanvasOfPage.length === 0) {
      return;
    }

    if (this.bannerConfig.header?.fileName) {
      this.downloadedFileName = this.bannerConfig.header?.fileName?.replace(/ /g, '') ?? '';
    } else if (this.bannerConfig.header?.title === '') {
      this.pageHeaderSubscription = this.pageHeaderService.pageHeader.subscribe((item: IPageHeader) => {
        _.set(this.bannerConfig, 'header.title', item.title);
        this.downloadedFileName = item.title?.replace(/ /g, '') ?? '';
      });
    } else {
      this.downloadedFileName = this.bannerConfig.header?.title.replace(/ /g, '') ?? '';
    }

    await this.setHeaderImageSrc();
    await this.setFooterImageSrc();

    this.defaultImage.src = `${await this.getBase64FromUrl('assets/images/digital-logbook-black-text.png')}`;
    this.deletedReferencePage = [];

    this.setPageEdgeSize(Orientation.portrait);
    const splitedCanvasIndexes: ISplitCanvasProperties[] = await this.iterateChartData(doc);
    this.filteredChartData = this.chartData;
    this.deletedReferencePagePageAndFilterChartData(doc);
    const pageCount: number = doc.getNumberOfPages();

    for (let pageIndex = 0; pageIndex < pageCount; pageIndex += 1) {
      doc.setPage(pageIndex + 1);
      const selectedPageChartDataIndex: number | undefined = _.find(splitedCanvasIndexes, { pageNumber: pageIndex })
        ?.index[0];
      const chart: IChart = this.filteredChartData[selectedPageChartDataIndex ?? pageIndex];
      this.setPageEdgeSize(chart?.page?.orientation ?? Orientation.portrait);
      await this.decideHeaderAndFooterPreferences(doc, pageIndex);
    }

    doc.save(`${this.downloadedFileName}_${moment().format('YYYY_MM_DD')}.pdf`);

    const removeItem: NodeListOf<HTMLDivElement> = document.querySelectorAll('.pdf-remove-item');
    removeItem.forEach((item: HTMLDivElement) => item.remove());
  }

  private setChartData(): void {
    if (this.file.autoFill) {
      const chartData: IChart[] = [];

      for (let index: number = 0; index < this.allCanvasOfPage.length; index += 1) {
        const findSpecificData: IChart | undefined = _.find(this.file.chartData, { id: index + 1 });

        if (!findSpecificData) {
          chartData.push({ ...this.defaultChartData, id: index + 1 });
        } else {
          chartData.push(findSpecificData);
        }
      }

      this.file.chartData = _.sortBy(chartData, 'id');
    }

    this.chartData = _.cloneDeep(this.file.chartData);
  }

  private findPrintElements(): void {
    const counters: ISearchSelectorCounters = {};
    let styleWidth: string = '850px';
    this.allCanvasOfPage = [];
    for (let index: number = 0; index < this.file.searchSelectors.length; index += 1) {
      const selector: string = this.file.searchSelectors[index];

      if (!counters[selector]) {
        counters[selector] = 1;
      } else {
        counters[selector] += 1;
      }

      const nthChildQuery: string = counters[selector] === 1 ? '' : `:nth-child(${counters[selector]})`;
      const query: string = `${selector}${nthChildQuery}`;
      let findElement: HTMLCanvasElement | null = document.querySelector(query);

      if (Array.from((findElement as HTMLElement)?.classList).includes('formio-hidden')) {
        index -= 1;
        continue;
      }

      if (!findElement) {
        findElement = document.querySelector(selector);
      }

      if (findElement && (findElement.clientHeight || findElement.scrollHeight || findElement.offsetHeight)) {
        const findSpecificData: IChart | undefined = _.find(this.file.chartData, { id: index + 1 });
        styleWidth =
          findSpecificData?.page?.orientation === Orientation.landscape ||
          findSpecificData?.isNotLargestFontSize ||
          this.file?.isNotLargestFontSizeAllPdf
            ? '1450px'
            : styleWidth;
        const printElement: HTMLElement = document.createElement('div');
        printElement.style.width = styleWidth;
        printElement.classList.add('pdf-remove-item');
        printElement.appendChild(findElement.cloneNode(true));
        document.body.appendChild(printElement);

        if (selector === '.qr-code') {
          const selectedSVGElement: SVGSVGElement | null = printElement.querySelector('svg');
          printElement.style.paddingTop = '150px';

          if (selectedSVGElement) {
            selectedSVGElement.setAttribute('width', '400');
            selectedSVGElement.setAttribute('height', '400');
          }
        }

        if (selector === '.qr-code-detail') {
          printElement.classList.add('h3');
          printElement.style.lineHeight = '50px';
          let selectedElements: HTMLElement[] = Array.from(printElement.querySelectorAll('.logbook-data-label'));
          selectedElements.forEach((element: HTMLElement) => {
            element.classList.remove('logbook-data-label');
          });

          selectedElements = Array.from(printElement.querySelectorAll('.logbook-data-text'));
          selectedElements.forEach((element: HTMLElement) => {
            element.classList.remove('logbook-data-text');
            element.classList.add(...['h2', 'font-weight-bolder']);
          });
        }
        this.allCanvasOfPage.push(printElement as HTMLCanvasElement);
      }
    }
  }
  private sortChartsByPageOrder(): void {
    if (this.chartData.length <= 1) {
      return;
    }

    this.allCanvasOfPage = this.allCanvasOfPage
      .slice()
      .map((canvas, index: number) => ({ index, canvas }))
      .sort((a, b) => {
        return (this.chartData?.[a.index]?.page?.order ?? 0) - (this.chartData?.[b.index]?.page?.order ?? 0);
      })
      .map((item) => item.canvas);

    this.chartData = this.chartData.slice().sort((a: IChart, b: IChart) => (a.page?.order ?? 0) - (b.page?.order ?? 0));
  }

  private cropCanvas(
    canvas: HTMLCanvasElement,
    positionX: number,
    positionY: number,
    width: number,
    height: number,
  ): HTMLCanvasElement {
    const croppedCanvas: HTMLCanvasElement = document.createElement('canvas');
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const croppedContext: CanvasRenderingContext2D | null = croppedCanvas.getContext('2d');

    croppedContext?.drawImage(canvas, positionX, positionY, width, height, 0, 0, width, height);

    return croppedCanvas;
  }

  private async prepareHeader(
    doc: jsPDF,
    pageName: string | undefined,
    pageSignature: string[] | undefined,
    image: HTMLImageElement,
  ): Promise<void> {
    doc.setFillColor('#ffffff');

    const imageWidth: number = 30;
    const imageHeight: number = 12;
    const titleXCoordinate: number = this.pageWidth - this.rightOrLeftPadding;
    const titleYCoordinate: number = this.topOrBottomPadding + 10;

    if (this.bannerConfig.header?.uri) {
      doc.addImage(
        image,
        this.fileFormat,
        this.rightOrLeftPadding,
        this.topOrBottomPadding,
        imageWidth,
        imageHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      if (this.bannerConfig.header.doubleImage) {
        doc.addImage(
          this.defaultImage,
          this.fileFormat,
          this.rightOrLeftPadding + imageWidth + 5,
          this.topOrBottomPadding,
          imageWidth,
          imageHeight,
          this.defaultImageAlias,
          this.imageCompression,
        );
      }

      doc.setFontSize(12);
      doc.text(pageName ?? '', titleXCoordinate, titleYCoordinate, { baseline: 'top', align: 'right' });
    } else {
      doc.setTextColor('#4C5054');
      doc.setFontSize(FileGeneratorComponent.calculateHeaderFontSize(pageName));
      doc.text(pageName ?? '', this.rightOrLeftPadding, 13, { baseline: 'top', align: 'left' });
      doc.setFontSize(7);
      doc.text(pageSignature ?? '', titleXCoordinate, titleYCoordinate, { baseline: 'top', align: 'right' });
    }

    this.prepareDatetimeValueAsText(doc, titleXCoordinate, titleYCoordinate + 8);

    const borderXCoordinate: number = this.rightOrLeftPadding;
    const borderYCoordinate: number = this.topOrBottomPadding * 2 + imageHeight;
    const borderWidth: number = this.pageWidth - this.rightOrLeftPadding * 2;
    doc.rect(borderXCoordinate, borderYCoordinate, borderWidth, 0.5, 'F');
  }

  private static calculateHeaderFontSize(headerText: string | undefined): number {
    const headerTextLength: number = Number(headerText?.length);

    if (headerTextLength > 45) {
      return 11;
    }

    if (headerTextLength > 40) {
      return 12;
    }

    if (headerTextLength > 35) {
      return 13;
    }

    if (headerTextLength > 30) {
      return 14;
    }

    return 15;
  }

  private prepareFooter(doc: jsPDF, image: HTMLImageElement): void {
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);

    const imageWidth: number = 36;
    const imageHeight: number = 11;
    const borderXCoordinate: number = this.rightOrLeftPadding;
    const borderYCoordinate: number = this.pageHeight - (this.topOrBottomPadding * 2 + imageHeight);
    const borderWidth: number = this.pageWidth - this.rightOrLeftPadding * 2;

    doc.rect(borderXCoordinate, borderYCoordinate, borderWidth, 0.5, 'F');

    if (!this.bannerConfig?.footer?.noPage) {
      const titleXCoordinate = this.pageWidth - this.rightOrLeftPadding;
      const titleYCoordinate = this.pageHeight - (this.topOrBottomPadding * 2 + imageHeight) / 2;
      const pageInfo: string = String(this.translate.instant(
        'general.pageWithTotalNumber',
        {
          pageNumber: doc.getCurrentPageInfo().pageNumber,
          totalPage: doc.getNumberOfPages(),
        },
      ));
      doc.text(
        pageInfo,
        titleXCoordinate,
        titleYCoordinate,
        {
          baseline: 'bottom',
          align: 'right',
        },
      );
    }

    if (!this.bannerConfig.footer?.noImage) {
      const rightXCoordinate: number = this.rightOrLeftPadding;
      const rightYCoordinate: number = this.pageHeight - (this.topOrBottomPadding + imageHeight);

      doc.addImage(
        image,
        this.fileFormat,
        rightXCoordinate,
        rightYCoordinate,
        imageWidth,
        imageHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      if (this.bannerConfig.footer?.doubleImage) {
        doc.addImage(
          this.defaultImage,
          this.fileFormat,
          rightXCoordinate + imageWidth + 5,
          rightYCoordinate,
          imageWidth,
          imageHeight,
          this.defaultImageAlias,
          this.imageCompression,
        );
      }
    }
  }

  private async getBase64FromUrl(url: string): Promise<string | ArrayBuffer | null> {
    const blob: Blob = await lastValueFrom(
      this.httpClient.get(url, {
        responseType: 'blob',
      }),
    );

    return new Promise<string | ArrayBuffer | null>((resolve) => {
      const reader: FileReader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64data: string | ArrayBuffer | null = reader.result;

        resolve(base64data);
      };
    });
  }

  private setPageEdgeSize(orientation: Orientation = Orientation.portrait): void {
    if (orientation === Orientation.landscape) {
      this.pageHeight = this.shortEdge;
      this.pageWidth = this.longEdge;
    } else {
      this.pageHeight = this.longEdge;
      this.pageWidth = this.shortEdge;
    }
  }

  private printTextElement(
    doc: jsPDF,
    elementType: string,
    fontSize: number,
    fontWeight: string,
    text: string,
    yCoordinate: number,
  ): void {
    const element: HTMLElement = document.createElement(elementType);
    const elementNode: Text = document.createTextNode(text);

    element.appendChild(elementNode);
    doc.setFontSize(fontSize).setFont('ZenKakuGothicAntique', fontWeight);
    doc.setTextColor(0, 0, 0);
    doc.text(element.innerText, this.rightOrLeftPadding, this.headerHeight + yCoordinate);
  }

  private async convertToCanvas(): Promise<void> {
    this.allCanvasOfPage = await Promise.all(
      this.allCanvasOfPage.map(async (htmlElement: HTMLElement) => {
        const imageData: string = await htmlToImage.toJpeg(htmlElement, {
          backgroundColor: 'white',
          quality: 0.5,
          cacheBust: true,
          skipAutoScale: true,
          skipFonts: true,
          fontEmbedCSS: fontawesomeCssBase64Data,
        });
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
        const image = new Image();
        image.src = imageData;
        image.onload = () => {
          canvas.height = image.height;
          canvas.width = image.width;
          context?.drawImage(image, 0, 0);
        };

        return canvas;
      }),
    );
  }

  private async decideHeaderAndFooterPreferences(doc: jsPDF, pageIndex: number): Promise<void> {
    if (this.pageHeaderFooterInfo[pageIndex].header || this.shouldPrintFilters) {
      await this.prepareHeader(
        doc,
        this.bannerConfig.header?.title,
        this.bannerConfig.header?.signature,
        this.headerImage,
      );
    }

    if (this.pageHeaderFooterInfo[pageIndex].footer) {
      this.prepareFooter(doc, this.footerImage);
    }
  }

  private prepareDatetimeValueAsText(doc: jsPDF, titleXCoordinate: number, titleYCoordinate: number) {
    if (this.filterParameters) {
      const startDate: string = this.filterParameters.dateRange.startDate;
      const endDate: string = this.filterParameters.dateRange.endDate;

      const dateText: string = `${startDate} - ${endDate}`;

      doc.setFontSize(10);

      doc.text(dateText, titleXCoordinate, titleYCoordinate, {
        baseline: 'top',
        align: 'right',
      });

      doc.setFontSize(12);
    }
  }

  public ngOnDestroy(): void {
    if (this.isPrintAllowed) {
      this.isPrintAllowed.unsubscribe();
    }

    if (this.pageHeaderSubscription) {
      this.pageHeaderSubscription.unsubscribe();
    }
  }

  public filterAllCanvas(): void {
    this.allCanvasOfPage = this.allCanvasOfPage.filter((canvas: HTMLElement) => {
      return (
        canvas instanceof HTMLCanvasElement &&
        canvas.width !== 0 &&
        !this.ignoreCanvasElements.includes(canvas.parentElement?.parentElement?.id ?? '')
      );
    });
  }

  public async setHeaderImageSrc(): Promise<void> {
    if (this.bannerConfig.header?.uri) {
      this.headerImage.src = `${await this.getBase64FromUrl(this.bannerConfig.header?.uri)}`;
    }
  }

  public async setFooterImageSrc(): Promise<void> {
    if (this.bannerConfig.footer?.uri && !this.bannerConfig.footer.noImage) {
      this.footerImage.src = `${await this.getBase64FromUrl(this.bannerConfig.footer?.uri)}`;
    }
  }

  public deletedReferencePagePageAndFilterChartData(doc: jsPDF): void {
    if (!this.deletedReferencePage.length) {
      return;
    }

    this.deletedReferencePage
      .slice()
      .reverse()
      .forEach((deletedId: number) => {
        const deletedPage: number = this.chartData.findIndex((chart: IChart) => chart.id === deletedId);

        doc.deletePage(deletedPage + 1);
      });

    this.filteredChartData = this.chartData.filter((chart: IChart) => {
      return !this.deletedReferencePage.includes(chart.id);
    });
  }

  public setChartDataNewImagesForm(
    index: number,
    remainingTotalHeight: number,
    formSelectorIndex: number,
    pageContentHeight: number,
  ): number {
    const canvas: HTMLCanvasElement = this.allCanvasOfPage[index] as HTMLCanvasElement;
    const pdfPageWidth: number = this.chartData[index]?.isNotLargestFontSize ? 1450 : 850;
    const canvasWidth: number = this.chartData[index].size?.width ?? canvas.width;
    const canvasHeight: number = this.chartData[index].size?.height ?? canvas.height;
    const imgHeight: number = (canvasHeight * this.pageWidth) / canvasWidth;
    const itemMarginSize: number = 18;
    const defaultPanelHeaderHeight: number = (this.pageWidth * 70) / canvasWidth;
    const defaultPanelFooterHeight: number = (this.pageWidth * 26) / canvasWidth;
    let totalHeight: number = remainingTotalHeight;
    let filteredFormSelectors: IFormPdfProperties[] | undefined;
    let cloneFilteredSelectorIndex: number = _.cloneDeep(formSelectorIndex);

    for (
      let selectorIndex: number = cloneFilteredSelectorIndex;
      selectorIndex < (this.file?.formSelectors?.length as number);
      selectorIndex += 1
    ) {
      filteredFormSelectors = this.file.formSelectors?.filter(
        (item: IFormPdfProperties) => item.index.split('-')[0] === cloneFilteredSelectorIndex.toString(),
      );
      if (!filteredFormSelectors || !filteredFormSelectors.length) {
        cloneFilteredSelectorIndex += 1;
      } else {
        break;
      }
    }

    if (imgHeight > pageContentHeight) {
      let imgRemainingHeight: number = imgHeight;
      let indexForCroppedCanvas: number = index;
      let currentTotalHeight: number = 0;

      if (!filteredFormSelectors || !filteredFormSelectors.length) {
        return cloneFilteredSelectorIndex;
      }

      for (let i: number = 0; i < filteredFormSelectors.length; i += 1) {
        if (imgRemainingHeight <= 0) {
          break;
        }

        const formSelectors: IFormPdfProperties = filteredFormSelectors[i];
        const isEndItem: boolean = i === filteredFormSelectors.length - 1;
        let printedElementHeight: number = 0;

        if (formSelectors.isPanel) {
          printedElementHeight += defaultPanelHeaderHeight * (canvasWidth / pdfPageWidth);
          currentTotalHeight += defaultPanelHeaderHeight * (canvasWidth / pdfPageWidth);
        } else {
          let findSelectedElement: NodeListOf<HTMLElement>;
          findSelectedElement = document.querySelectorAll(formSelectors.selector);

          if (!findSelectedElement?.length) {
            findSelectedElement = document.querySelectorAll(
              `.printable-formio-form ${formSelectors.selector.split('formio .formio-component-form .formio-form')[1]}`,
            );
          }


          if (findSelectedElement?.length > 0) {
            printedElementHeight +=
              (this.pageWidth * (findSelectedElement[0]?.offsetHeight + itemMarginSize)) / pdfPageWidth;
            currentTotalHeight += printedElementHeight;
          }
        }

        if (formSelectors.isPanelEnd || (formSelectors.index.split('-').length === 1 && isEndItem)) {
          const footerCount: number = isEndItem
            ? formSelectors.index.split('-').length - 1
            : formSelectors.index.split('-').length - filteredFormSelectors[i + 1].index.split('-').length;
          printedElementHeight += footerCount * defaultPanelFooterHeight * (canvasWidth / pdfPageWidth);
          currentTotalHeight += footerCount * defaultPanelFooterHeight * (canvasWidth / pdfPageWidth);
        }

        const isBigContent: boolean = totalHeight + currentTotalHeight > pageContentHeight;
        const printedCanvasHeight: number =
          imgRemainingHeight < currentTotalHeight - (isEndItem && !isBigContent ? 0 : printedElementHeight)
            ? imgRemainingHeight
            : currentTotalHeight - (isEndItem && !isBigContent ? 0 : printedElementHeight);
        const isBigElement: boolean =
          printedElementHeight > pageContentHeight && currentTotalHeight - printedElementHeight < 1;

        if (isBigContent && currentTotalHeight - printedElementHeight < 1 && printedElementHeight < pageContentHeight) {
          totalHeight = 0;
          currentTotalHeight = 0;
          printedElementHeight = 0;
          i -= 1;
          continue;
        }

        if (isBigContent || isEndItem) {
          const croppedCanvas: HTMLCanvasElement = this.cropCanvas(
            canvas,
            0,
            (canvasWidth * (imgHeight - imgRemainingHeight)) / this.pageWidth,
            canvasWidth,
            (canvasWidth *
              (!isBigElement
                ? printedCanvasHeight
                : printedElementHeight > imgRemainingHeight
                ? imgRemainingHeight
                : printedElementHeight)) /
              this.pageWidth,
          );
          this.allCanvasOfPage.splice(indexForCroppedCanvas + 1, 0, croppedCanvas);
          this.chartData.splice(indexForCroppedCanvas + 1, 0, this.chartData[index]);
          this.file.searchSelectors.splice(indexForCroppedCanvas + 1, 0, 'formio-images');
          imgRemainingHeight -= printedCanvasHeight;
          indexForCroppedCanvas += 1;
          totalHeight = 0;
          currentTotalHeight = isEndItem && !isBigContent ? currentTotalHeight : printedElementHeight;
          currentTotalHeight = printedElementHeight < pageContentHeight ? currentTotalHeight : 0;
          printedElementHeight = 0;
          i = isEndItem && isBigContent && !isBigElement ? i - 1 : i;
        }
      }

      this.allCanvasOfPage.splice(index, 1);
      this.chartData.splice(index, 1);
      this.file.searchSelectors.splice(index, 1);
    }

    return cloneFilteredSelectorIndex;
  }

  public setChartDataNewImages(index: number, totalHeight: number, pageContentHeight: number): void {
    const canvas: HTMLCanvasElement = this.allCanvasOfPage[index] as HTMLCanvasElement;
    const canvasHeight: number = this.chartData[index].size?.height ?? canvas.height;
    const canvasWidth: number = this.chartData[index].size?.width ?? canvas.width;
    const imgHeight: number = (canvasHeight * this.pageWidth) / canvasWidth;
    const clippingTolerance: number = 3;
    let imgReameningHeight: number = imgHeight;
    let indexForCroppedCanvas: number = index;

    while (imgReameningHeight > 0) {
      const newImageHeight: number =
        index === indexForCroppedCanvas ? pageContentHeight - totalHeight : pageContentHeight;
      const croppedCanvas: HTMLCanvasElement = this.cropCanvas(
        canvas,
        0,
        (canvasHeight * (imgHeight - imgReameningHeight)) / imgHeight,
        canvasWidth,
        (canvasHeight * (imgReameningHeight < pageContentHeight ? imgReameningHeight : newImageHeight)) / imgHeight,
      );

      if (index === indexForCroppedCanvas) {
        this.allCanvasOfPage[index] = croppedCanvas;
      } else {
        this.allCanvasOfPage.splice(indexForCroppedCanvas, 0, croppedCanvas);
        this.chartData.splice(indexForCroppedCanvas, 0, this.chartData[index]);
      }

      imgReameningHeight -= pageContentHeight - clippingTolerance - (index === indexForCroppedCanvas ? totalHeight : 0);

      if (imgReameningHeight > 0) {
        this.file.searchSelectors.splice(indexForCroppedCanvas + 1, 0, 'clipping-images');
      }

      indexForCroppedCanvas += 1;
    }
  }

  public async iterateChartData(doc: jsPDF): Promise<ISplitCanvasProperties[]> {
    this.pageHeaderFooterInfo = [];
    const splitedCanvasIndexes: ISplitCanvasProperties[] = [];
    let totalHeight: number = 0;
    let pageIndex: number = 0;
    let formSelectorIndex: number = 0;

    for (let index: number = 0; index < this.allCanvasOfPage.length; index += 1) {
      const selectedChartData: IChart = this.chartData[index];
      const headerAndFooterTotalHeight: number =
        selectedChartData?.page?.orientation === Orientation.landscape ? 95 : 47;
      totalHeight = selectedChartData.page?.isSinglePage ? 0 : totalHeight;
      const pageContentHeight: number =
        (selectedChartData?.page?.orientation === Orientation.landscape ? this.shortEdge : this.longEdge) -
        headerAndFooterTotalHeight;
      const canvas: HTMLCanvasElement = this.allCanvasOfPage[index] as HTMLCanvasElement;
      const canvasHeight: number = this.chartData[index].size?.height ?? canvas.height;
      const canvasWidth: number = this.chartData[index].size?.width ?? canvas.width;
      const imgHeight: number = (canvasHeight * this.pageWidth) / canvasWidth;
      const isFormCanvas: boolean =
        this.file.searchSelectors[index] === `#${EPdfInitialElementId.FORM} .formio-form > .formio-component`;

      if (isFormCanvas) {
        formSelectorIndex += 1;
      }

      if (imgHeight > pageContentHeight) {
        if (isFormCanvas) {
          totalHeight += 5;
          formSelectorIndex = this.setChartDataNewImagesForm(index, totalHeight, formSelectorIndex, pageContentHeight);
        } else {
          this.setChartDataNewImages(index, totalHeight, pageContentHeight);
          index -= 1;
          continue;
        }
      }

      if (
        index > 0 &&
        (this.chartData[index].page?.isSinglePage || this.chartData[index - 1]?.page?.isSinglePage) &&
        splitedCanvasIndexes[pageIndex]?.index?.length > 0
      ) {
        pageIndex += 1;
        totalHeight = 0;
      }

      if (!(splitedCanvasIndexes[pageIndex]?.pageNumber >= 0)) {
        splitedCanvasIndexes.push({
          pageNumber: pageIndex,
          index: [],
          positionY: [],
        });
      }

      const reloadedCanvas: HTMLCanvasElement = this.allCanvasOfPage[index] as HTMLCanvasElement;
      const reloadedCanvasHeight: number = this.chartData[index].size?.height ?? reloadedCanvas.height;
      const reloadedCanvasWidth: number = this.chartData[index].size?.width ?? reloadedCanvas.width;
      const reloadedImgHeight: number = (reloadedCanvasHeight * this.pageWidth) / reloadedCanvasWidth;

      if (totalHeight + reloadedImgHeight <= pageContentHeight || !splitedCanvasIndexes[pageIndex].index.length) {
        splitedCanvasIndexes[pageIndex].index.push(index);
        splitedCanvasIndexes[pageIndex].positionY.push(totalHeight);
        totalHeight += reloadedImgHeight;
      } else {
        pageIndex += 1;
        totalHeight = 0;
        index -= 1;
        formSelectorIndex -= isFormCanvas ? 1 : 0;
      }
    }

    let printPageIndex: number = 0;
    let positionIndex: number = 0;

    for (let index: number = 0; index < this.chartData?.length; index += 1) {
      const chart: IChart = this.chartData[index];
      if (!this.allCanvasOfPage[index]) {
        continue;
      }

      const canvas: HTMLCanvasElement = this.allCanvasOfPage[index] as HTMLCanvasElement;
      this.setPageEdgeSize(chart.page?.orientation ?? Orientation.portrait);
      const canvasImgURL: string = canvas.toDataURL(this.dataUrlType, this.dataUrlQuality);
      const canvasHeight: number = chart.size?.height ?? canvas.height;
      const canvasWidth: number = chart.size?.width ?? canvas.width;
      const imgHeight: number = (canvasHeight * this.pageWidth) / canvasWidth;
      const imgWidth: number = this.pageWidth - (chart.page?.padding?.right ?? 0) * 2;
      const positionX: number = chart.page?.padding?.left ?? 0;
      const defaultPaddingTop: number = 30;
      const pageInfo: ISplitCanvasProperties | undefined = _.find(
        splitedCanvasIndexes,
        (item: ISplitCanvasProperties) => _.includes(item.index, index),
      );

      if (!canvas.height) {
        continue;
      }

      if (pageInfo?.pageNumber !== printPageIndex) {
        doc.addPage(this.pageType, chart.page?.orientation ?? Orientation.portrait);
        this.pageHeaderFooterInfo.push({
          header: Boolean(this.bannerConfig.header),
          footer: Boolean(this.bannerConfig.footer),
        });
        printPageIndex += 1;
        positionIndex = 0;
      }

      doc.addImage(
        canvasImgURL,
        this.fileFormat,
        positionX,
        (pageInfo?.positionY[positionIndex] ?? 0) + (chart.page?.padding?.top ?? defaultPaddingTop),
        imgWidth,
        imgHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      this.pageHeaderFooterInfo.push({
        header: Boolean(this.bannerConfig.header),
        footer: Boolean(this.bannerConfig.footer),
      });

      positionIndex += 1;
    }

    return splitedCanvasIndexes;
  }

  public addImageBasedOnRefChartIndex(
    refChartIndex: number,
    xCoordinate: number,
    yCoordinate: number,
    doc: jsPDF,
    subChart: IChart | undefined,
    imgWidth: number,
    imgHeight: number,
    refImgURL: string,
    chart: IChart,
  ): void {
    if (refChartIndex > 0) {
      yCoordinate += chart.page?.padding?.bottom ?? 115;

      doc.addImage(
        refImgURL,
        this.fileFormat,
        xCoordinate,
        yCoordinate,
        subChart?.size?.width ?? imgWidth,
        subChart?.size?.height ?? imgHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );
    } else {
      doc.addImage(
        refImgURL,
        this.fileFormat,
        xCoordinate,
        yCoordinate,
        imgWidth,
        imgHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );
    }
  }

  public addImageBasedOnChartReference(
    chart: IChart,
    canvas: HTMLCanvasElement,
    doc: jsPDF,
    canvasImgURL: string,
    x: number,
    imgWidth: number,
    imgHeight: number,
  ): void {
    if (!chart.reference) {
      doc.addImage(
        canvasImgURL,
        this.fileFormat,
        x,
        chart.page?.padding?.top ?? 30,
        imgWidth,
        imgHeight,
        this.defaultImageAlias,
        this.imageCompression,
      );

      return;
    }

    let refChart: HTMLCanvasElement[] = [];

    chart.reference.ids.forEach((id: number) => {
      refChart.push(
        canvas,
        this.allCanvasOfPage[
          this.chartData.findIndex((innerChart: IChart) => innerChart.id === id)
        ] as HTMLCanvasElement,
      );

      if (!this.deletedReferencePage.includes(id)) {
        this.deletedReferencePage.push(id);
      }
    });

    const y: number = 30;
    let refCanvas: HTMLCanvasElement;

    refChart = refChart.filter((innerCanvas: HTMLCanvasElement | undefined) => innerCanvas);

    for (let refChartIndex = 0; refChartIndex < refChart.length; refChartIndex += 1) {
      refCanvas = Array.isArray(refChart) ? refChart[refChartIndex] : refChart;
      const subChart: IChart | undefined = this.chartData.find((subChartElement: IChart) =>
        chart.reference?.ids.includes(subChartElement.id),
      );
      const refImgURL: string = refCanvas.toDataURL(this.dataUrlType, this.dataUrlQuality);

      this.addImageBasedOnRefChartIndex(refChartIndex, x, y, doc, subChart, imgWidth, imgHeight, refImgURL, chart);
    }
  }
}
