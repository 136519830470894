import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

moment.tz.setDefault('utc');

import { DatePipe } from '@angular/common';
import { HelperService } from '../service/helper.service';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  private dateRegExp: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3,6})?Z$/;

  constructor(private readonly datePipe: DatePipe, private readonly helperService: HelperService) {}

  transform(
    value: moment.Moment | string | Date,
    format: string | undefined = HelperService.userDateTimeFormat ?? undefined,
    options?: { withoutYear: boolean; withLineBreak: boolean },
    locale?: string,
    timezone: string = HelperService.userTimeZone ?? 'utc',
  ): any {
    let cloneDate: moment.Moment | string | Date = value;

    if (_.isString(value) && this.dateRegExp.test(value)) {
      cloneDate = moment(value);
    }

    let date: moment.Moment = moment(cloneDate);

    try {
      date = moment(cloneDate, format);
    } catch (error) {}

    if (!date.isValid()) {
      date = moment(cloneDate);
    }

    const dateValue: Date | null = value && date.isValid() ? date.toDate() : null;

    if (dateValue) {
      const momentFormatted: string = date.tz(timezone).format(format);

      if (options?.withLineBreak) {
        return HelperService.formatDateTimeWithLineBreak(date);
      }

      if (moment(momentFormatted, format, true).isValid()) {
        return options && options.withoutYear ? this.helperService.removeYear(date, momentFormatted) : momentFormatted;
      }
    }

    return this.datePipe.transform(dateValue, format, locale, timezone);
  }
}
