import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IAllowedDomain } from '../main/main.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPinService {
  private readonly FORGOT_PIN = {
    ALLOWED_DOMAINS_URL: `${this.baseUrl}/allowed-domains/`,
    FORGOT_PIN: `${this.baseUrl}/users/forgot-pin`,
    RESET_PIN: `${this.baseUrl}/users/reset-pin`,
    CHECK_VALIDITY: `${this.baseUrl}/users/check-validity`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getAllowedDomains(): Observable<IResponse<IAllowedDomain[]>> {
    return this.http.get<IResponse<IAllowedDomain[]>>(this.FORGOT_PIN.ALLOWED_DOMAINS_URL);
  }

  public forgotPin(email: string): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(this.FORGOT_PIN.FORGOT_PIN, {
      payload: {
        email,
      },
    });
  }

  public resetPin(userId: number, pin: string): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(this.FORGOT_PIN.RESET_PIN, {
      payload: {
        userId,
        pin,
      },
    });
  }

  public checkValidity(userId: number, refreshToken: string): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(this.FORGOT_PIN.CHECK_VALIDITY, {
      payload: {
        userId,
        refreshToken,
      },
    });
  }
}
