import { IFilterCondition } from '../../store/filter/filter.model';

export function generateSearchObject(
  search: string,
  searchProps: IFilterCondition[],
): IFilterCondition[] {
  const filterArray: IFilterCondition[] = [];
  searchProps.forEach((filter: IFilterCondition) => {
    filterArray.push({
      ...filter,
      query: filter.query !== undefined ? filter.query : search,
    });
  });

  return filterArray;
}

export function isMobile(): boolean {
  return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
}
