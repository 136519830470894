import * as Actions from './forgot-pin.actions';
import { Action } from '@ngrx/store';
import { IForgotPinState } from './forgot-pin.model';

export const reasonState: IForgotPinState = {
  allowedDomains: [],
  getAllowedDomainsLoaded: false,
  getAllowedDomainsLoading: false,
};

export function forgotPinReducer(state: IForgotPinState = reasonState, baseAction: Action): IForgotPinState {
  const action = baseAction as Actions.ForgotPinActions;
  switch (action.type) {
    case Actions.GET_ALLOWED_DOMAINS:
      return {
        ...state,
        getAllowedDomainsLoading: true,
        getAllowedDomainsLoaded: false,
      };
    case Actions.GET_ALLOWED_DOMAINS_COMPLETED:
      return {
        ...state,
        getAllowedDomainsLoading: false,
        getAllowedDomainsLoaded: true,
        allowedDomains: action.payload,
      };
    default:
      return state;
  }
}
