import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScwMatButtonGroupButtons, ScwMatButtonGroupSize } from './scw-mat-button-group.model';

@Component({
  selector: 'scw-mat-button-group',
  templateUrl: './scw-mat-button-group.component.html',
})
export class ScwMatButtonGroupComponent {
  @Input() inputModel: any;
  @Input() buttons: ScwMatButtonGroupButtons[] = [];
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() size: ScwMatButtonGroupSize = 'md';
  @Input() stateless: boolean = false;
  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();

  public onNgModelChange(value: any): void {
    this.inputModel = value;
    this.inputModelChange.emit(this.inputModel);
  }
}
