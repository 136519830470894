<div
  class="page-header"
  [class.tab-page]="pageHeader.isTabPage"
  [class.no-overlap-page-header]="pageHeader.noOverlap"
  [style.padding-top]="pageHeaderPaddingTop"
  *ngIf="pageHeader.hidePageHeader !== true"
>
  <div *ngIf="pageHeader.title !== null" class="page-block">
    <div class="page-header-container">
      <div>
        <h4>
          <em *ngIf="pageHeader.icon !== null" class="{{ pageHeader.icon }}"></em>
          {{ pageHeader.title }}
        </h4>
      </div>
      <div class="page-header-buttons" *ngIf="!hideHeaderActions">
        <a
          *ngIf="pageHeader.fullScreenButton"
          data-target="{{ pageHeader.fullScreenTargetElementId }}"
          appToggleFullScreen
        >
          <em class="text-white fas fa-expand-arrows-alt full-screen"></em>
        </a>
        <app-filter-visibility-button *ngIf="showFilterBarVisibilityButton"></app-filter-visibility-button>
        <app-print-functionality-button *ngIf="showPrintFunctionalityButton"></app-print-functionality-button>
        <app-page-configuration
          *ngIf="pageHeader.showPageConfiguration"
          [pageHeader]="pageHeader"
        ></app-page-configuration>
      </div>
    </div>
  </div>
</div>
