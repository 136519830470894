import { IActivityTypeState } from './activity-type.model';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as ActivityTypeActions from './activity-type.actions';

export const initialState: IActivityTypeState = {
  activityTypesDataLoading: false,
  activityTypesDataLoaded: false,
  addActivityTypesLoading: false,
  addActivityTypesLoaded: false,
  editActivityTypesLoading: false,
  editActivityTypesLoaded: false,
  deleteActivityTypesLoading: false,
  deleteActivityTypesLoaded: false,
  bulkDeleteActivityTypesLoading: false,
  bulkDeleteActivityTypesLoaded: false,
  bulkEditActivityTypesLoading: false,
  bulkEditActivityTypesLoaded: false,
  activityTypesData: null,
};

export const activityTypeReducer: ActionReducer<IActivityTypeState> = createReducer(
  initialState,
  on(ActivityTypeActions.ActivityTypesDataLoading, (state: IActivityTypeState) => ({
    ...state,
    activityTypesDataLoading: true,
    activityTypesDataLoaded: false,
  })),
  on(ActivityTypeActions.ActivityTypesDataLoaded, (state: IActivityTypeState, { payload }) => ({
    ...state,
    activityTypesDataLoading: false,
    activityTypesDataLoaded: true,
    activityTypesData: payload,
  })),
  on(ActivityTypeActions.AddActivityType, (state: IActivityTypeState) => ({
    ...state,
    addActivityTypesLoading: true,
    addActivityTypesLoaded: false,
  })),
  on(ActivityTypeActions.AddActivityTypeCompleted, (state: IActivityTypeState) => ({
    ...state,
    addActivityTypesLoading: false,
    addActivityTypesLoaded: true,
  })),
  on(ActivityTypeActions.EditActivityType, (state: IActivityTypeState) => ({
    ...state,
    addActivityTypesLoading: true,
    addActivityTypesLoaded: false,
  })),
  on(ActivityTypeActions.EditActivityTypeCompleted, (state: IActivityTypeState) => ({
    ...state,
    editActivityTypesLoading: false,
    editActivityTypesLoaded: true,
  })),
  on(ActivityTypeActions.DeleteActivityType, (state: IActivityTypeState) => ({
    ...state,
    deleteActivityTypesLoading: true,
    deleteActivityTypesLoaded: false,
  })),
  on(ActivityTypeActions.DeleteActivityTypeCompleted, (state: IActivityTypeState) => ({
    ...state,
    deleteActivityTypesLoading: false,
    deleteActivityTypesLoaded: true,
  })),
  on(ActivityTypeActions.BulkDeleteActivityType, (state: IActivityTypeState) => ({
    ...state,
    bulkDeleteActivityTypesLoading: true,
    bulkDeleteActivityTypesLoaded: false,
  })),
  on(ActivityTypeActions.BulkDeleteActivityTypeCompleted, (state: IActivityTypeState) => ({
    ...state,
    bulkDeleteActivityTypesLoading: false,
    bulkDeleteActivityTypesLoaded: true,
  })),
  on(ActivityTypeActions.BulkEditActivityType, (state: IActivityTypeState) => ({
    ...state,
    bulkEditActivityTypesLoading: true,
    bulkEditActivityTypesLoaded: false,
  })),
  on(ActivityTypeActions.BulkEditActivityTypeCompleted, (state: IActivityTypeState) => ({
    ...state,
    bulkEditActivityTypesLoading: false,
    bulkEditActivityTypesLoaded: true,
  })),
);
