import { IStateStates } from './states.model';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as StateActions from './states.actions';

export const initialState: IStateStates = {
  stateDataLoading: false,
  stateDataLoaded: false,
  addStateLoading: false,
  addStateLoaded: false,
  editStateLoading: false,
  editStateLoaded: false,
  bulkEditStatesLoading: false,
  bulkEditStatesLoaded: false,
  deleteStateLoading: false,
  deleteStateLoaded: false,
  bulkDeleteStateLoading: false,
  bulkDeleteStateLoaded: false,
  stateData: [],
};

export const statesReducer: ActionReducer<IStateStates> = createReducer(
  initialState,
  on(StateActions.statesDataLoading, (state: IStateStates) => ({
    ...state,
    stateDataLoading: true,
    stateDataLoaded: false,
  })),
  on(StateActions.statesDataLoaded, (state: IStateStates, { payload }) => ({
    ...state,
    stateDataLoading: false,
    stateDataLoaded: true,
    stateData: payload.data,
  })),
  on(StateActions.addState, (state: IStateStates) => ({
    ...state,
    addStateLoading: true,
    addStateLoaded: false,
  })),
  on(StateActions.addStateCompleted, (state: IStateStates) => ({
    ...state,
    addStateLoading: false,
    addStateLoaded: true,
  })),
  on(StateActions.editState, (state: IStateStates) => ({
    ...state,
    addStateLoading: true,
    addStateLoaded: false,
  })),
  on(StateActions.editStateCompleted, (state: IStateStates) => ({
    ...state,
    editStateLoading: false,
    editStateLoaded: true,
  })),
  on(StateActions.bulkEditStates, (state: IStateStates) => ({
    ...state,
    bulkEditStatesLoading: true,
    bulkEditStatesLoaded: false,
  })),
  on(StateActions.bulkEditStatesCompleted, (state: IStateStates) => ({
    ...state,
    bulkEditStatesLoading: false,
    bulkEditStatesLoaded: true,
  })),
  on(StateActions.editState, (state: IStateStates) => ({
    ...state,
    deleteStateLoading: true,
    deleteStateLoaded: false,
  })),
  on(StateActions.editStateCompleted, (state: IStateStates) => ({
    ...state,
    deleteStateLoading: false,
    deleteStateLoaded: true,
  })),
  on(StateActions.bulkDeleteState, (state: IStateStates) => ({
    ...state,
    bulkDeleteStateLoading: true,
    bulkDeleteStateLoaded: false,
  })),
  on(StateActions.bulkDeleteStateCompleted, (state: IStateStates) => ({
    ...state,
    bulkDeleteStateLoading: false,
    bulkDeleteStateLoaded: true,
  })),
);
