import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, map } from 'rxjs';
import { IResponse } from '../../../shared/model/interface/generic-api-response.model';
import { IClient, IClientSettings, IUploadResponse } from './client.model';
import { MainService } from '../../main/main.service';
import * as _ from 'lodash';
import { IAllowedDomain } from '../../main/main.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly CLIENT = {
    CLIENT_URL: `${this.baseUrl}/clients/`,
    FILE_URL: `${this.baseUrl}/files/`,
  };

  constructor(
    public http: HttpClient,
    public readonly mainService: MainService,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {}

  public getClientSettings(): Observable<IResponse<IClientSettings>> {
    return this.http.get<IResponse<IClientSettings>>(this.CLIENT.CLIENT_URL);
  }

  public editClientSettings(params: IClientSettings, issuer: IIssuer | null): Observable<IResponse<IClientSettings>> {
    return this.http.put<IResponse<IClientSettings>>(this.CLIENT.CLIENT_URL, {
      issuer,
      payload: params,
    });
  }

  public getClientWithAllowedDomain(): Observable<IClient> {
    const observables: any = [this.getClientSettings(), this.mainService.getAllowedDomains()];

    return forkJoin(observables).pipe(
      map((responseList) => {
        const clientSettings: IClientSettings = _.get(responseList, '0.data', []);
        const allowedDomains: IAllowedDomain[] = _.get(responseList, '1.data', []);

        return {
          ...clientSettings,
          allowedDomains,
        };
      }),
    );
  }

  public uploadLogo(params: File, issuer: IIssuer | null): Observable<IResponse<IUploadResponse>> {
    const formData = new FormData();
    formData.append('uploaded_file', params);
    formData.append('issuer', JSON.stringify(issuer));
    formData.append('payload', JSON.stringify({ module: 'clients', file_type: params.type }));
    return this.http.put<IResponse<IUploadResponse>>(this.CLIENT.FILE_URL, formData);
  }
}
