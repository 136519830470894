import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { ScwMatButtonGroupComponent } from './scw-mat-button-group.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, ScwMatButtonModule],
  exports: [ScwMatButtonGroupComponent],
  declarations: [ScwMatButtonGroupComponent],
})
export class ScwMatButtonGroupModule {}
