import { IRightsState } from './rights.model';
import * as Actions from './rights.actions';
import { Action } from '@ngrx/store';

export const rightsState: IRightsState = {
  rightsDataLoaded: false,
  rightsDataLoading: false,
};

export function rightReducer(state: IRightsState = rightsState, baseAction: Action): IRightsState {
  const action = baseAction as Actions.RightsActions;

  switch (action.type) {
    case Actions.RIGHTS_DATA_LOADING:
      return {
        ...state,
        ...{
          rightsDataLoading: true,
          rightsDataLoaded: false,
        },
      };
    case Actions.RIGHTS_DATA_LOADED:
      return {
        ...state,
        ...{
          rightsData: action.payload,
          rightsDataLoading: false,
          rightsDataLoaded: true,
        },
      };
    default:
      return state;
  }
}
