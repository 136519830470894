import { Inject, Injectable } from '@angular/core';
import { IFilterService, IFormsCRUDResponse } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IFormsCRUD } from '../../component/filter/filter.class';
import * as _ from 'lodash';
import { ActiveStatuses } from '../../../../constants';
import {IFilterCondition, IFormFilterCard} from '../../../store/filter/filter.model';

@Injectable({
  providedIn: 'root',
})
export class FormService implements IFilterService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly routes: string = 'forms/';

  getForms(filter: IFormFilterCard): Promise<IFormsCRUD[]> {
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    let params: HttpParams = new HttpParams();
    const searchObject: object = {};
    const orObject: { $or: object[] } = { $or: [] };

    _.set(searchObject, 'isActive.$eq', ActiveStatuses.ACTIVE);

    if (typeof filter.params.filterParams !== 'undefined') {
      filter.params.filterParams.forEach((filterParam: IFilterCondition) => {
        if (filterParam.prop === 'currentLine' || !filterParam.query) {
          return;
        }

        orObject.$or.push({ [filterParam.prop]: { [filterParam.condition]: filterParam.query } });
      });
    }

    queryParams.$and.push(searchObject, orObject);

    params = params.set('page', String(filter.params.page));
    params = params.set('limit', String(filter.params.limit));
    params = params.set('include_archived', filter.params.includeArchived);
    params = params.set('s', JSON.stringify(queryParams));

    return this.getData(params);
  }

  public getData(params?: HttpParams): Promise<IFormsCRUD[]> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      this.http.get(`${this.baseUrl}/${this.routes}`, { params }).subscribe((response: IFormsCRUDResponse) => {
        if (response.hasOwnProperty('data')) {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }
}
