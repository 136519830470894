import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IAddCheckInUsers, ICheckInExists, ICheckInResponse } from './check-in.model';
import { HomeCheckInUserInterface } from '../home/home.model';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CheckInService {
  private readonly URLS = {
    LOAD_CHECKINS: `${this.baseUrl}/check-ins/form_submissions/`,
    CHECKIN_USER: `${this.baseUrl}/check-ins/`,
    CHECKOUT_USER: `${this.baseUrl}/check-ins/check-out`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
  ) {}

  public loadCheckInUsers(
    formSubmissionId: number | undefined,
  ): Observable<IGetManyResponse<HomeCheckInUserInterface>> {
    return this.http.get<IGetManyResponse<HomeCheckInUserInterface>>(`${this.URLS.LOAD_CHECKINS}${formSubmissionId}`);
  }

  public addCheckInUsers(checkInUser: IAddCheckInUsers): Observable<IBaseOneResponse<ICheckInResponse>> {
    return this.http.post<IBaseOneResponse<ICheckInResponse>>(this.URLS.CHECKIN_USER, checkInUser);
  }

  public updateCheckInUsers(checkInUser: IAddCheckInUsers): Observable<IBaseOneResponse<ICheckInResponse>> {
    return this.http.post<IBaseOneResponse<ICheckInResponse>>(this.URLS.CHECKOUT_USER, checkInUser);
  }

  public getCheckInExistsMessageMultipleUsers(errorData: ICheckInExists[]): ICheckInExists[][] {
    const uniqueGroupedErrorData: ICheckInExists[][] = Object.values(
      _.groupBy(errorData, (item: ICheckInExists) => `${item.source}${item.logbookName}${item.formEntryName}`),
    );
    const mappedErrorData: ICheckInExists[] = uniqueGroupedErrorData.map((row: ICheckInExists[]) => {
      const originalRow: ICheckInExists = _.first(row) as ICheckInExists;

      return {
        ...originalRow,
        errorLogIds: _.map(row, 'logId').join(', '),
      };
    });

    return Object.values(_.groupBy(mappedErrorData, 'source'));
  }
}
