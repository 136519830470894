import { Action } from '@ngrx/store';
import { IFormsCRUD } from '../../../shared/component/filter/filter.class';
import { IFilterCondition } from '../filter.model';

export const START_LOAD_FILTER_FORMS = '[Filter Form] Load Filter Forms';
export const FILTER_FORMS_LOADED = '[Filter Form] Filter Forms Loaded';
export const RESET_FORM_STORE = '[Filter Form] Reset store data for form filter';

export class StartLoadFilterForms implements Action {
  readonly type = START_LOAD_FILTER_FORMS;
  constructor(
    public page: number | undefined,
    public limit: number | undefined,
    public filter: IFilterCondition[] | undefined,
    public includeArchived: number,
  ) {}
}
export class FilterFormsLoaded implements Action {
  readonly type = FILTER_FORMS_LOADED;
  constructor(public data: IFormsCRUD[]) {}
}
export class ResetFormStore implements Action {
  readonly type = RESET_FORM_STORE;
}

export type FilterFormActions = StartLoadFilterForms | FilterFormsLoaded | ResetFormStore;
