export enum MomentDateFormat {
  DATETIME_12H = 'MMM D, YYYY h:mm A',
  DATETIME_24H = 'MMM D, YYYY H:mm',
  TIME_12H = 'h:mm A',
  TIME_24H = 'H:mm',
  LL = 'll',
  L = 'L',
}

export enum UserDateFormat {
  DATETIME_12H = 'MMM d, yyyy h:mm aa',
}