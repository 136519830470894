import { Injectable } from '@angular/core';
import { WorkflowsService } from './workflows.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import * as ObjectActions from './workflows.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IWorkflowStepsData } from '../../../view/settings/workflows/workflows.model';
import { of } from 'rxjs';
import { IWorkflow, IWorkflowSteps } from './workflows.model';
import { IRole } from '../../../view/settings/roles/roles.model';
import { IFormMasterData } from '../form-master-data/form-master-data.model';
import { FormMasterDataService } from '../form-master-data/form-master-data.service';
import { LogbookMasterDataService } from '../logbook-master-data/logbook-master-data.service';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { ILogbookMasterData } from '../logbook-master-data/logbook-master-data.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';

@Injectable()
export class WorkflowsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: WorkflowsService,
    private readonly formMasterDataService: FormMasterDataService,
    private readonly logbookMasterDataService: LogbookMasterDataService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
  ) {}

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOWS_DATA_LOADING),
      switchMap((payload: ObjectActions.WorkflowsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest<ITableQuery>({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        httpParams = httpParams.append('include_disabled', true);

        return this.service.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.WorkflowsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowStepsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOWS_STEPS_DATA_LOADING),
      switchMap((payload: ObjectActions.WorkflowsStepsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getWorkflowsSteps(payload.id).pipe(
          switchMap((response: IGetManyResponse<IWorkflowStepsData>) => {
            return of(new ObjectActions.WorkflowsStepsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addWorkflow = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_WORKFLOW),
      switchMap((objectData: ObjectActions.AddWorkflow) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addWorkflow(objectData.workflow, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IWorkflow>) => {
            return of(new ObjectActions.SaveWorkflowCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addWorkflowSteps = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_WORKFLOW_STEPS),
      switchMap((objectData: ObjectActions.SaveWorkflowSteps) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addWorkflowSteps(objectData.workflowSteps, objectData.id, objectData.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<IWorkflowStepsData>) => {
            return of(new ObjectActions.AddWorkflowStepsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editWorkflow = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_WORKFLOW),
      switchMap((objectData: ObjectActions.EditWorkflow) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editWorkflow(objectData.workflow, objectData.id, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IWorkflow>) => {
            return of(new ObjectActions.EditWorkflowCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editWorkflowSteps = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_WORKFLOW_STEPS),
      switchMap((objectData: ObjectActions.EditWorkflowSteps) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editWorkflowSteps(objectData.workflowSteps, objectData.id, objectData.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<IWorkflowSteps>) => {
            return of(new ObjectActions.EditWorkflowStepsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getRoleData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ROLES_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('limit', 1000);

        return this.service.getRoles(httpParams).pipe(
          switchMap((response: IGetManyResponse<IRole>) => {
            return of(new ObjectActions.GetRolesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FORM_MASTER_DATA_WORKFLOW_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('limit', 1000);

        return this.formMasterDataService.getFormMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IFormMasterData>) => {
            return of(new ObjectActions.GetFormMasterDataWorkflowLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_MASTER_DATA_WORKFLOW_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('limit', 1000);

        return this.logbookMasterDataService.getLogbookMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterData>) => {
            return of(new ObjectActions.GetLogbookMasterDataWorkflowLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteWorkflowSteps = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_WORKFLOW_STEPS),
      switchMap((objectData: ObjectActions.DeleteWorkflowSteps) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteWorkflowSteps(objectData.workflowSteps, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IWorkflowSteps>) => {
            return of(new ObjectActions.DeleteWorkflowStepsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
