import { ICheckInUsersState } from './check-in.model';
import { Action } from '@ngrx/store';
import * as Actions from './check-in.actions';

export const checkInUsersState: ICheckInUsersState = {
  isCheckInUsersLoading: false,
  isCheckInUsersLoaded: false,
  isCheckInUsersAdd: false,
  isCheckInUsersAddCompleted: false,
  isCheckInUsersUpdate: false,
  isCheckInUsersUpdateCompleted: false,
  checkInUsers: [],
};

export function checkInUsersReducer(
  state: ICheckInUsersState = checkInUsersState,
  baseAction: Action,
): ICheckInUsersState {
  const action: Actions.CheckInUsersActions = baseAction as Actions.CheckInUsersActions;
  switch (action.type) {
    case Actions.CHECKIN_USERS_LOADING:
      return {
        ...state,
        checkInUsers: [],
        isCheckInUsersLoading: true,
        isCheckInUsersLoaded: false,
      };
    case Actions.CHECKIN_USERS_LOADED:
      return {
        ...state,
        checkInUsers: action.payload.data,
        isCheckInUsersLoading: false,
        isCheckInUsersLoaded: true,
      };
    case Actions.ADD_CHECKIN_USERS:
      return {
        ...state,
        isCheckInUsersAdd: true,
        isCheckInUsersAddCompleted: false,
      };
    case Actions.ADD_CHECKIN_USERS_COMPLETED:
      return {
        ...state,
        isCheckInUsersAdd: false,
        isCheckInUsersAddCompleted: true,
      };
    case Actions.UPDATE_CHECKIN_USERS:
      return {
        ...state,
        isCheckInUsersUpdate: true,
        isCheckInUsersUpdateCompleted: false,
      };
    case Actions.UPDATE_CHECKIN_USERS_COMPLETED:
      return {
        ...state,
        isCheckInUsersUpdate: false,
        isCheckInUsersUpdateCompleted: true,
      };
    default:
      return state;
  }
}
