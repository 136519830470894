import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SavableFilterService } from '../../../shared/service/filter/savable-filter/savable-filter.service';
import * as SavableFilterActions from './savable-filter.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { ISavablePageFilters } from '../../../shared/service/filter/savable-filter/savable-filter.type';
import { IResponse } from '../../../shared/model/interface/generic-api-response.model';

@Injectable()
export class SavableFilterEffects {
  constructor(private readonly actions$: Actions, private readonly savableFilterService: SavableFilterService) {}

  public readonly writeSavableFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(SavableFilterActions.writeSavableFilter),
      switchMap((payload: ReturnType<typeof SavableFilterActions.writeSavableFilter>) =>
        this.savableFilterService.saveFilters(payload.filterData, payload.isUpdate).pipe(
          map(
            (response: IResponse<ISavablePageFilters>) => SavableFilterActions.writeSavableFilterSuccess(response),
            catchError((error) => {
              return of(SavableFilterActions.writeSavableFilterFailure({ error }));
            }),
          ),
        ),
      ),
    ),
  );

  public readonly readSavableFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(SavableFilterActions.readSavableFilter),
      switchMap((payload: ReturnType<typeof SavableFilterActions.readSavableFilter>) =>
        this.savableFilterService.getSavedFilters(payload.location).pipe(
          map((response: IResponse<ISavablePageFilters>) => SavableFilterActions.readSavableFilterSuccess(response)),
          catchError((error) => {
            return of(SavableFilterActions.readSavableFilterFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
