import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { catchError, switchMap, of } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { MasterDataTasksService } from '../../../shared/service/my-tasks/master-data-tasks/master-data-tasks.service';
import * as ObjectActions from './master-data-tasks.actions';
import { IMasterDataVersion, IMasterDataTasksData, IFieldSetData } from './master-data-tasks.model';
import { IMasterDataTasksHistory } from '../../../view/my-tasks/master-data-tasks/master-data-tasks.component.model';
import { IReviewResults } from '../my-tasks.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';
import { IWorkflow } from '../../settings/workflows/workflows.model';

@Injectable()
export class MasterDataTasksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: MasterDataTasksService,
    private readonly workflowService: WorkflowsService,
  ) {}

  getReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);

        return this.service.getReviewData(body).pipe(
          switchMap((response: IGetManyResponse<IMasterDataTasksData>) => {
            return of(new ObjectActions.MasterDataTasksLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksBeforeRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_BEFORE_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksBeforeRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksActiveRecord(payload.fieldVersionType).pipe(
          switchMap((response: IGetOneResponse<IMasterDataVersion>) => {
            return of(new ObjectActions.MasterDataTasksBeforeRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksAfterRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_AFTER_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksAfterRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksRecord(payload.id).pipe(
          switchMap((response: IGetOneResponse<IMasterDataVersion>) => {
            return of(new ObjectActions.MasterDataTasksAfterRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksBeforeFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_BEFORE_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksBeforeFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(
              new ObjectActions.MasterDataTasksBeforeFieldSetLoaded(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksAfterFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_AFTER_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksAfterFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(new ObjectActions.MasterDataTasksAfterFieldSetLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksHistoryForOneVersion(payload.id).pipe(
          switchMap((response: IGetManyResponse<IMasterDataTasksHistory>) => {
            return of(new ObjectActions.MasterDataTasksHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksActiveVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_ACTIVE_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksActiveVersionHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksHistoryForOneVersion(payload.id).pipe(
          switchMap((response: IGetManyResponse<IMasterDataTasksHistory>) => {
            return of(
              new ObjectActions.MasterDataTasksActiveVersionHistoryLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMasterDataTasksCurrentVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MASTER_DATA_TASKS_CURRENT_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartMasterDataTasksCurrentVersionHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMasterDataTasksHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<IMasterDataTasksHistory>) => {
            return of(
              new ObjectActions.MasterDataTasksCurrentVersionHistoryLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  reviewFormEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_APPROVE_LOADING),
      switchMap((payload: ObjectActions.StartEntryApproveLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryApproveLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectPermanentEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_PERMANENT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectPermanentLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.reviewEntries(payload.reviewPayload, payload.issuer).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectPermanentLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().append('limit', 1000).append('just_workflow_data', true);

        return this.workflowService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.WorkflowDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
