import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

export class LayoutAnimations {
  public static notificationBottom = trigger('notificationBottom', [
    state(
      'an-off, void',
      style({
        overflow: 'hidden',
        height: '0px',
      }),
    ),
    state(
      'an-animate',
      style({
        overflow: 'hidden',
        height: AUTO_STYLE,
      }),
    ),
    transition('an-off <=> an-animate', [animate('400ms ease-in-out')]),
  ]);

  public static mobileHeaderNavRight = trigger('mobileHeaderNavRight', [
    state(
      'nav-off, void',
      style({
        overflow: 'hidden',
        height: '0px',
      }),
    ),
    state(
      'nav-on',
      style({
        height: AUTO_STYLE,
      }),
    ),
    transition('nav-off <=> nav-on', [animate('400ms ease-in-out')]),
  ]);

  public static fadeInOutTranslate = trigger('fadeInOutTranslate', [
    transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))]),
    transition(':leave', [style({ transform: 'translate(0)' }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
  ]);

  public static mobileMenuTop = trigger('mobileMenuTop', [
    state(
      'no-block, void',
      style({
        overflow: 'hidden',
        height: '0px',
      }),
    ),
    state(
      'yes-block',
      style({
        height: AUTO_STYLE,
      }),
    ),
    transition('no-block <=> yes-block', [animate('400ms ease-in-out')]),
  ]);
}
