import { Action } from '@ngrx/store';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IAddCheckInUsers, ICheckInResponse } from './check-in.model';
import { HomeCheckInUserInterface } from '../home/home.model';

export const CHECKIN_USERS_LOADING = '[CHECK IN] CHECK IN USER LOADING';
export const CHECKIN_USERS_LOADED = '[CHECK IN] CHECK IN USER LOADED';
export const ADD_CHECKIN_USERS = '[CHECK IN] CHECK IN USER INSERT';
export const ADD_CHECKIN_USERS_COMPLETED = '[CHECK IN] CHECK IN USER INSERTED';
export const UPDATE_CHECKIN_USERS = '[CHECK IN] CHECK IN USER UPDATE';
export const UPDATE_CHECKIN_USERS_COMPLETED = '[CHECK IN] CHECK IN USER UPDATED';
export const FETCH_ERROR = '[CHECK IN] FETCH ERROR';

export class CheckInUsersLoading implements Action {
  public readonly type = CHECKIN_USERS_LOADING;

  constructor(public formSubmissionId: number | undefined) {}
}

export class CheckInUsersLoaded implements Action {
  public readonly type = CHECKIN_USERS_LOADED;

  constructor(public payload: IGetManyResponse<HomeCheckInUserInterface>) {}
}

export class AddCheckInUsers implements Action {
  public readonly type = ADD_CHECKIN_USERS;

  constructor(public checkInUsers: IAddCheckInUsers) {}
}

export class AddCheckInUsersCompleted implements Action {
  public readonly type = ADD_CHECKIN_USERS_COMPLETED;

  constructor(public payload: IBaseOneResponse<ICheckInResponse>) {}
}

export class UpdateCheckInUsers implements Action {
  public readonly type = UPDATE_CHECKIN_USERS;

  constructor(public checkInUsers: IAddCheckInUsers) {}
}

export class UpdateCheckInUsersCompleted implements Action {
  public readonly type = UPDATE_CHECKIN_USERS_COMPLETED;

  constructor(public payload: IBaseOneResponse<ICheckInResponse>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type CheckInUsersActions =
  | CheckInUsersLoading
  | CheckInUsersLoaded
  | AddCheckInUsers
  | AddCheckInUsersCompleted
  | UpdateCheckInUsers
  | UpdateCheckInUsersCompleted
  | FetchError;
