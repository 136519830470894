import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MonitoringService } from './monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private monitoringService: MonitoringService) {}

  handleError(error: Error | HttpErrorResponse) {
    this.monitoringService.logException(error);
  }
}
