import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, catchError, switchMap } from 'rxjs';
import * as logBookAppReducer from '../../store/logbook.reducer';
import * as AppActions from '../app/actions';
import * as ForgotPinActions from './forgot-pin.actions';
import { ForgotPinService } from './forgot-pin.service';

@Injectable()
export class ForgotPinEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<logBookAppReducer.LogbookAppState>,
    private readonly service: ForgotPinService,
  ) {}

  getAllowedDomains = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPinActions.GET_ALLOWED_DOMAINS),
      switchMap(() => {
        return this.service.getAllowedDomains().pipe(
          switchMap((response) => {
            return of(new ForgotPinActions.GetAllowedDomainsCompleted(response.data));
          }),
          catchError((errorRes) => {
            return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  forgotPin = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPinActions.FORGOT_PIN),
      switchMap((payload: ForgotPinActions.ForgotPin) => {
        return this.service.forgotPin(payload.mail).pipe(
          switchMap((response) => {
            return of(new ForgotPinActions.ForgotPinCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  resetPin = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPinActions.RESET_PIN),
      switchMap((payload: ForgotPinActions.ResetPin) => {
        return this.service.resetPin(payload.userId, payload.pin).pipe(
          switchMap((response) => {
            return of(new ForgotPinActions.ResetPinCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ForgotPinActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  checkValidity = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPinActions.CHECK_VALIDITY),
      switchMap((payload: ForgotPinActions.CheckValidity) => {
        return this.service.checkValidity(payload.userId, payload.pin).pipe(
          switchMap((response) => {
            return of(new ForgotPinActions.CheckValidityCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ForgotPinActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ForgotPinActions.FetchError(errorRes));
      }),
    ),
  );
}
