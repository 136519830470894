import * as Actions from './logbook.actions';
import { ILogbooksCRUD } from '../../../shared/component/filter/filter.class';
import { Action } from '@ngrx/store';

export interface FilterLogbookState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ILogbooksCRUD[];
}

export const initialFilterLogbookState: FilterLogbookState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterLogbookReducer(state = initialFilterLogbookState, baseAction: Action): FilterLogbookState {
  const action = baseAction as Actions.FilterLogbookActions;

  switch (action.type) {
    case Actions.START_LOAD_FILTER_LOGBOOKS:
      return { ...state, isLoading: true };

    case Actions.FILTER_LOGBOOKS_LOADED:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case Actions.RESET_LOGBOOK_STORE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
