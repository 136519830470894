import { Action } from '@ngrx/store';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IRole, IRoleSettings } from '../../../view/settings/roles/roles.model';
import { IAddRole, IBulkEditRole, IRoleTableQuery } from './roles.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export const ROLES_DATA_LOADING = '[ROLES] ROLE DATA LOADING';
export const ROLES_DATA_LOADED = '[ROLES] ROLE DATA LOADED';
export const ADD_ROLE = '[ROLES] ROLE ADD ROLE';
export const ADD_ROLE_COMPLETED = '[ROLES] ROLE ADD ROLE COMPLETED';
export const EDIT_ROLE = '[ROLES] ROLE EDIT ROLE';
export const EDIT_ROLE_COMPLETED = '[ROLES] ROLE EDIT ROLE COMPLETED';
export const BULK_EDIT_ROLE = '[ROLES] ROLE BULK EDIT ROLE';
export const BULK_EDIT_ROLE_COMPLETED = '[ROLES] BULK EDIT ROLE COMPLETED';
export const FETCH_ERROR = '[ROLES] FETCH ERROR';

export class RolesDataLoading implements Action {
  readonly type = ROLES_DATA_LOADING;

  constructor(public tableQuery: IRoleTableQuery) {}
}

export class RolesDataLoaded implements Action {
  readonly type = ROLES_DATA_LOADED;

  constructor(public payload: IRoleSettings) {}
}

export class AddRole implements Action {
  readonly type = ADD_ROLE;

  constructor(public role: IAddRole, public issuer: IIssuer | null) {}
}

export class AddRoleCompleted implements Action {
  readonly type = ADD_ROLE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IAddRole>) {}
}

export class EditRole implements Action {
  readonly type = EDIT_ROLE;

  constructor(
    public role: IAddRole,
    public id: number,
    public issuer: IIssuer | null,
    public forceCheckOut?: boolean,
  ) {}
}

export class EditRoleCompleted implements Action {
  readonly type = EDIT_ROLE_COMPLETED;

  constructor(public payload: IBaseOneResponse<IAddRole>) {}
}

export class BulkEditRole implements Action {
  readonly type = BULK_EDIT_ROLE;

  constructor(public roles: IBulkEditRole[], public issuerAndReason: IIssuerAndReason) {}
}

export class BulkEditRoleCompleted implements Action {
  readonly type = BULK_EDIT_ROLE_COMPLETED;

  constructor(public payload: IGetManyResponse<IBulkResponseRecord<IRole>>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type RolesActions =
  | RolesDataLoading
  | RolesDataLoaded
  | AddRole
  | AddRoleCompleted
  | EditRole
  | EditRoleCompleted
  | BulkEditRole
  | BulkEditRoleCompleted
  | FetchError;
