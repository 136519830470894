<div class="error-400">
  <div class="container-fluid">
    <div class="row mb40">
      <div class="col-sm-12">
        <div class="logo-container d-flex justify-content-center">
          <div class="lock-circle d-flex justify-content-center align-items-center">
            <em class="fas fa-solid fa-lock lock-icon"></em>
          </div>
        </div>
        <div class="access-denied text-center">
          {{ 'general.accessDenied' | translate }}
        </div>
        <div class="access-denied-info">
          {{ 'general.noPagePermission' | translate }}
          <br />
          {{ 'general.contactAdmin' | translate }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <a routerLink="['/home']" class="btn btn-primary btn-lg ripple button-capitalization">{{
            'general.backToHome' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
