import { LogbookAppState } from '../../../store/logbook.reducer';

export interface IFilterMap {
  storeName: keyof LogbookAppState;
  expectedProperty: string;
  filteredDataIds: (string | number)[] | number;
  filterPrefix: string;
  filterName: string;
  reference?: IReferenceChart;
  values?: string | string[];
}

export interface IFilterMapped {
  id: number;
  value: string;
}

export interface IFilter {
  dateRange: IDate;
}

export interface IDate {
  startDate: string;
  endDate: string;
}

export interface IChart extends IAbilities {
  id: ChartType;
  reference?: IReferenceChart;
}

export interface IReferenceChart {
  ids: ChartType[];
}

export interface IAbilities {
  page?: {
    orientation: Orientation;
    order: PageOrder;
    padding?: IPadding;
    isSinglePage?: boolean;
  };
  size?: ISize;
  borderElementSelector?: string;
  isNotLargestFontSize?: boolean;
}

export interface ISize {
  width?: number;
  height?: number;
}

export interface IPadding {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

export interface IBanner {
  header?: IHeader;
  footer?: IFooter;
}

export interface IHeader {
  title: string;
  signature?: string[];
  doubleImage?: boolean;
  fileName?: string;
  uri?: string;
}

export interface IFooter extends IUri {
  doubleImage?: boolean;
  noImage?: boolean;
  noPage?: boolean;
  uri: string;
}

export interface IUri {
  uri: string;
}

export interface IFile {
  type: FileTypes;
  chartData: IChart[];
  autoFill: boolean;
  searchSelectors: string[];
  isNotLargestFontSizeAllPdf?: boolean;
  formSelectors?: IFormPdfProperties[];
}

export enum FileTypes {
  PDF = 'pdf',
  TEXT = 'text',
  IMG = 'img',
}

export enum Orientation {
  portrait = 'p',
  landscape = 'l',
}

export enum PageOrder {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
  Seventh = 7,
  Eighth = 8,
  Ninth = 9,
  Tenth = 10,
}

export enum ChartType {
  QR = 1,
  QR_LABEL = 2,
  FORM_ENTRY = 3,
}

export enum EElementSelector {
  CANVAS = 'canvas',
  IS_PRINTABLE = '.is-printable',
}

export interface IPageHeaderFooterInfo {
  header: boolean;
  footer: boolean;
}

export interface ISearchSelectorCounters {
  [key: string]: number;
}

export interface ISplitCanvasProperties {
  pageNumber: number;
  index: number[];
  positionY: number[];
}

export interface IFormPdfProperties {
  index: string;
  selector: string;
  isPanel?: boolean;
  isPanelEnd?: boolean;
}

export enum EPdfInitialElementId {
  FORM = 'printableModal',
}
