import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import * as _ from 'lodash';
import { ScwMatInputComponent } from '../scw-mat-input/scw-mat-input.component';
import { ScwMatSelectComponent } from '../scw-mat-select/scw-mat-select.component';
import { ScwMatButtonComponent } from '../scw-mat-button/scw-mat-button.component';
import { ScwMatDatepickerComponent } from '../scw-mat-datepicker/scw-mat-datepicker.component';
import { ScwMatPickerComponent } from '../scw-mat-picker/scw-mat-picker.component';
import { ScwMatCheckboxComponent } from '../scw-mat-checkbox/scw-mat-checkbox.component';
import { ScwMatTextareaComponent } from '../scw-mat-textarea/scw-mat-textarea.component';
import { ScwMatCheckboxGroupComponent } from '../scw-mat-checkbox-group/scw-mat-checkbox-group.component';

@Component({
  selector: 'scw-mat-form',
  templateUrl: './scw-mat-form.component.html',
  styleUrls: ['./scw-mat-form.component.scss'],
})
export class ScwMatFormComponent implements AfterContentInit, AfterContentChecked {
  @Input() overrideButtons!: QueryList<ScwMatButtonComponent>;
  @Input() overrideSelects!: QueryList<ScwMatSelectComponent>;
  @Input() overrideInputs!: QueryList<ScwMatInputComponent>;
  @Input() overrideDatePickers!: QueryList<ScwMatDatepickerComponent>;
  @Input() overridePickers!: QueryList<ScwMatPickerComponent>;
  @Input() overrideTextAreas!: QueryList<ScwMatTextareaComponent>;
  @Input() overrideCheckboxes!: QueryList<ScwMatCheckboxComponent>;
  @Input() overrideCheckboxGroups!: QueryList<ScwMatCheckboxGroupComponent>;
  @Input() shouldAutoComplete: boolean = false;

  @ContentChildren(ScwMatInputComponent, { descendants: true }) public _inputs!: QueryList<ScwMatInputComponent>;
  @ContentChildren(ScwMatSelectComponent, { descendants: true }) public _selects!: QueryList<ScwMatSelectComponent>;
  // tslint:disable-next-line:max-line-length
  @ContentChildren(ScwMatDatepickerComponent, { descendants: true })
  public _datePickers!: QueryList<ScwMatDatepickerComponent>;
  @ContentChildren(ScwMatButtonComponent, { descendants: true }) public _buttons!: QueryList<ScwMatButtonComponent>;
  @ContentChildren(ScwMatPickerComponent, { descendants: true }) public _pickers!: QueryList<ScwMatPickerComponent>;
  @ContentChildren(ScwMatTextareaComponent, { descendants: true })
  public _textAreas!: QueryList<ScwMatTextareaComponent>;
  @ContentChildren(ScwMatCheckboxComponent, { descendants: true })
  public _checkboxes!: QueryList<ScwMatCheckboxComponent>;
  @ContentChildren(ScwMatCheckboxGroupComponent, { descendants: true })
  public _checkboxGroups!: QueryList<ScwMatCheckboxGroupComponent>;
  @Output() scwFormSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isValid!: boolean;
  public buttons!: QueryList<ScwMatButtonComponent>;
  public inputs!: QueryList<ScwMatInputComponent>;
  public selects!: QueryList<ScwMatSelectComponent>;
  public datePickers!: QueryList<ScwMatDatepickerComponent>;
  public pickers!: QueryList<ScwMatPickerComponent>;
  public textAreas!: QueryList<ScwMatTextareaComponent>;
  public checkboxes!: QueryList<ScwMatCheckboxComponent>;
  public checkboxGroups!: QueryList<ScwMatCheckboxGroupComponent>;

  private isInputsAreValid(): boolean {
    return _.find(_.get(this.inputs, '_results'), { isValid: false }) === undefined;
  }

  private isSelectsAreValid(): boolean {
    return _.find(_.get(this.selects, '_results'), { isValid: false }) === undefined;
  }

  private isDatePickersAreValid(): boolean {
    return _.find(_.get(this.datePickers, '_results'), { isValid: false }) === undefined;
  }

  private isCheckboxesAreValid(): boolean {
    return _.find(_.get(this.checkboxes, '_results'), { hasErrors: true }) === undefined;
  }

  private isCheckboxGroupsAreValid(): boolean {
    return _.find(_.get(this.checkboxGroups, '_results'), { hasErrors: true }) === undefined;
  }

  private isValidChecker(): void {
    this.isValid =
      this.isInputsAreValid() &&
      this.isSelectsAreValid() &&
      this.isDatePickersAreValid() &&
      this.isCheckboxesAreValid() &&
      this.isCheckboxGroupsAreValid();
  }

  public reset(): void {
    for (const input of _.get(this.inputs, '_results')) {
      input.reset();
    }

    for (const select of _.get(this.selects, '_results')) {
      select.reset();
    }

    for (const datepicker of _.get(this.datePickers, '_results')) {
      datepicker.reset();
    }

    for (const textarea of _.get(this.textAreas, '_results')) {
      textarea.reset();
    }

    for (const checkbox of _.get(this.checkboxes, '_results')) {
      checkbox.reset();
    }

    for (const checkboxGroup of _.get(this.checkboxGroups, '_results')) {
      checkboxGroup.reset();
    }
  }

  public triggerRuleCheckers(): boolean {
    for (const input of _.get(this.inputs, '_results')) {
      input.checkRules();
    }

    for (const select of _.get(this.selects, '_results')) {
      select.checkRules();
    }

    for (const datepicker of _.get(this.datePickers, '_results')) {
      datepicker.checkRules();
    }

    for (const picker of _.get(this.pickers, '_results')) {
      picker.checkRules();
    }

    for (const textarea of _.get(this.textAreas, '_results')) {
      textarea.checkRules();
    }

    for (const checkbox of _.get(this.checkboxes, '_results')) {
      checkbox.checkRules();
    }

    for (const checkboxGroup of _.get(this.checkboxGroups, '_results')) {
      checkboxGroup.checkRules();
    }

    this.isValidChecker();

    return this.isValid;
  }

  public ngAfterContentChecked(): void {
    this.isValidChecker();
  }

  public ngAfterContentInit(): void {
    this.buttons = this.overrideButtons ?? this._buttons;
    this.inputs = this.overrideInputs ?? this._inputs;
    this.selects = this.overrideSelects ?? this._selects;
    this.datePickers = this.overrideDatePickers ?? this._datePickers;
    this.pickers = this.overridePickers ?? this._pickers;
    this.textAreas = this.overrideTextAreas ?? this._textAreas;
    this.checkboxes = this.overrideCheckboxes ?? this._checkboxes;
    this.checkboxGroups = this.overrideCheckboxGroups ?? this._checkboxGroups;

    this.buttons.forEach((item) => {
      if (item.isSubmitButton !== false) {
        item.onClickEmitterForScwMatForm = () => {
          this.triggerRuleCheckers();
          this.scwFormSubmit.emit(this.isValid);
        };
      }
    });
  }
}
