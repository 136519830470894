import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appPaginationCustomizer]',
})
export class PaginationCustomizerDirective implements OnInit {

  @Input() additionalClassName!: string;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const domElement = this.el.nativeElement;
    domElement.className = this.additionalClassName;
  }

}
