import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../store/logbook.reducer';
import * as ForgotPinActions from '../../store/forgot-pin/forgot-pin.actions';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { HelperService } from '../../shared/service/helper.service';

@Component({
  selector: 'scw-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.scss'],
})
export class ResetPinComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly storeActions: ActionsSubject,
  ) {
    this.route.params.subscribe((params) => {
      if (!_.isInteger(Number(params['userId']) || !params['userId'])) {
        this.router.navigate(['/home']);
      }
      this.userId = Number(params['userId']);
      this.pinTokenId = params['tokenId'];
    });
    this.store.dispatch(new ForgotPinActions.CheckValidity(this.userId, this.pinTokenId));
  }
  public windowLocation: string = new URL('/#/home', window.location.origin).href;
  public window = window;
  public confirmPin!: string;
  public pin!: string;
  public userId!: number;
  public pinTokenId!: string;
  private readonly subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(ForgotPinActions.RESET_PIN_COMPLETED))
        .subscribe((response: ForgotPinActions.ResetPinCompleted) => {
          this.helperService.showToastMessage(
            response.payload.success,
            this.translate.instant(response.payload.success ? 'general.success' : 'general.failed'),
            response.payload.success
              ? this.translate.instant('general.changesSavedSuccessfully')
              : this.translate.instant('general.failed'),
          );
          if (response.payload.success) {
            this.router.navigate(['/home']);
          }
        }),
      this.storeActions
        .pipe(ofType(ForgotPinActions.CHECK_VALIDITY_COMPLETED))
        .subscribe((response: ForgotPinActions.CheckValidityCompleted) => {
          if (!response.payload.success) {
            this.router.navigate(['/error/invalid-link']);
          }
        }),
      this.storeActions
        .pipe(ofType(ForgotPinActions.FETCH_ERROR))
        .subscribe((response: ForgotPinActions.FetchError) => {
          if (
            !response.payload.success &&
            !(
              response.payload?.error?.errors?.length &&
              response.payload?.error?.errors[0].errors?.user?.length &&
              response.payload?.error?.errors[0].errors?.user[0].code === 'can_not_use_previous_pin'
            )
          ) {
            this.router.navigate(['/error/invalid-link']);
          }
        }),
    );
  }

  public passwordChange(event: any): void {
    const passwordConfirmElement: any = document.querySelector('#passwordConfirm');
    const passwordElement: any = document.querySelector('#password');
    const errorMessageText: any = document.querySelector('.error-message');
    const submitButton: any = document.getElementById('submitButton');
    const numberIcon: any = document.getElementById('number');

    if (submitButton || !this.pin.length || !this.confirmPin.length) {
      submitButton.disabled = true;
    }

    const numberFound = /^\d{4,6}$/.test(event.target.value);

    if (event.target.id === 'password') {
      this.checkControl(numberIcon, numberFound);
    }

    if (
      (event.target.value !== passwordConfirmElement.value && passwordConfirmElement.value.length > 0) ||
      (event.target.value !== passwordElement.value && passwordElement.value.length > 0)
    ) {
      passwordConfirmElement.className += ' input-error';
      errorMessageText.className = 'error-message visible';
      submitButton.disabled = true;
    } else if (
      this.pin?.length <= 6 &&
      this.pin?.length >= 4 &&
      this.confirmPin?.length <= 6 &&
      this.confirmPin?.length >= 4
    ) {
      passwordConfirmElement.className = 'password-input';
      errorMessageText.className = 'error-message invisible';
      submitButton.disabled = false;
    }
  }

  public checkControl(icon: any, findControl: any) {
    if (findControl) {
      icon.classList.remove();
      icon.className = 'fas fa-check-circle';
    } else {
      const submitButton: any = document.getElementById('submitButton');
      submitButton.disabled = true;
      icon.classList.remove();
      icon.className = 'fas fa-times-circle';
    }
  }

  public showPin(isConfirmPassword: boolean): void {
    const input: Element | null = document.querySelector(isConfirmPassword ? '#passwordConfirm' : '#password');
    const eyeIcon: Element | null = document.querySelector(
      isConfirmPassword ? '#passwordConfirmEyeIcon' : '#passwordEyeIcon',
    );
    if (!input || !eyeIcon) {
      return;
    }
    const selectedDefinitionType = input.getAttribute('type') === 'password' ? 'password' : 'text';
    if (selectedDefinitionType === 'password') {
      input.setAttribute('type', 'text');
      eyeIcon.classList.remove('fa-eye-slash');
      eyeIcon.classList.add('fa-eye');
    } else {
      input.setAttribute('type', 'password');
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }
  }

  public sendPassword(): void {
    this.store.dispatch(new ForgotPinActions.ResetPin(this.userId, this.pin));
  }
}
