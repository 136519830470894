import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './logbook-scope.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { ScopesService } from '../scopes.service';
import { IScope } from '../scopes.model';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { User } from '../../user/model';

@Injectable()
export class LogbookScopesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly service: ScopesService,
  ) {}

  getLogbookScopeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_SCOPES_DATA_LOADING),
      switchMap((payload: ObjectActions.LogbookScopesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          objectType: 'logbook',
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        return this.service.getScopes(httpParams).pipe(
          switchMap((response: IGetManyResponse<IScope>) => {
            return of(new ObjectActions.LogbookScopesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addLogbookScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LOGBOOK_SCOPE),
      switchMap((objectData: ObjectActions.AddLogbookScope) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addScope(objectData.scope, objectData.issuer).pipe(
          switchMap((response: IBaseOneResponse<IScope>) => {
            return of(new ObjectActions.AddLogbookScopeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editLogbookScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LOGBOOK_SCOPE),
      switchMap((objectData: ObjectActions.EditLogbookScope) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let userLogbookScopeId: number | null | undefined = null;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            userLogbookScopeId = state.logbookScopeId;
          });

        return this.service
          .editScope(objectData.scope, objectData.id, objectData.issuer, objectData.forceCheckOut)
          .pipe(
            switchMap((response: IBaseOneResponse<IScope>) => {
              if (objectData.id === userLogbookScopeId) {
                return of(
                  new ObjectActions.EditLogbookScopeCompleted(response),
                  new AppActions.HideLoader(),
                  new AppActions.GetCurrentUser('application-parameters'),
                );
              }
              return of(new ObjectActions.EditLogbookScopeCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditLogbookScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_LOGBOOK_SCOPES),
      switchMap((objectData: ObjectActions.BulkEditLogbookScopes) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditScope(objectData.scopes, objectData.issuer, objectData.reason).pipe(
          switchMap((response: IGetManyResponse<IBulkResponseRecord<IScope>>) => {
            return of(new ObjectActions.BulkEditLogbookScopesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
