import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NotificationComponent } from './notification/notification.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [SharedModule, ScrollingModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
})
export class MainModule {}
