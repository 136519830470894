import { Component, Input } from '@angular/core';
import { ISimpleOutputOptions } from '../filter.class';
import { Subject } from 'rxjs';
import { ScwMatButtonGroupButtons } from '../../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
})
export class ButtonGroupComponent {
  @Input() buttons: ScwMatButtonGroupButtons[] = [];
  @Input() value: any = null;
  @Input() disabled: boolean = false;

  public outputOptions!: ISimpleOutputOptions;
  public outputSubject!: Subject<any>;
  public elementID!: string;

  public dependedOptionConfiguration: any[] = [];

  public getFiltersOutputs(): void {
    this.outputSubject.next({
      [this.outputOptions.filterObjectId]: this.value,
    });
    this.publishValue(this.value);
  }

  public publishValue(value: any): void {
    this.dependedOptionConfiguration.forEach((config: any) => {
      if (config?.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((config: any) => {
      if (this.elementID === config?.dependedElementId && this.hasOwnProperty(config?.dependedOption)) {
        _.set(this, config?.dependedOption, config?.getDependentValue(config?.value));
        this.getFiltersOutputs();
      }
    });
  }

  ngOnDestroy(): void {
    this.dependedOptionConfiguration?.forEach((option) => {
      option.dependedOptionListener?.complete();
    });
  }
}
