import * as Actions from './main.actions';
import { ERightOperators, IMainState, IMenu } from './main.model';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

export const initialState: IMainState = {
  getNavigationMenuLoading: false,
  getNavigationMenuLoaded: false,
  authorizedPages: [],
  menu: { menus: [], clientLogo: '' },
  clientLogo: null,
  clientLogoUrl: null,
  allowedDomainsLoading: false,
  allowedDomainsLoaded: false,
  authorizedPagesLoading: false,
  authorizedPagesLoaded: false,
  allowedDomains: [],
  userRights: {},
};

export function mainReducer(state = initialState, baseAction: Action): IMainState {
  const action = baseAction as Actions.MainActions;

  switch (action.type) {
    case Actions.GET_NAVIGATION_MENU:
      return {
        ...state,
        getNavigationMenuLoading: true,
        getNavigationMenuLoaded: false,
      };

    case Actions.GET_NAVIGATION_MENU_COMPLETED:
      return {
        ...state,
        getNavigationMenuLoading: false,
        getNavigationMenuLoaded: true,
        authorizedPages: state.menu.menus
          .filter(
            (node: IMenu) =>
              node.link !== '#' &&
              !node.external &&
              (!node.requiredRights.rights.length ||
                (node.requiredRights.operator === ERightOperators.OR
                  ? _.some(node.requiredRights.rights, (requiredRight) => {
                      return _.keys(_.pickBy(state.userRights)).includes(requiredRight);
                    })
                  : _.every(node.requiredRights.rights, (requiredRight) => {
                      return _.keys(_.pickBy(state.userRights)).includes(requiredRight);
                    }))),
          )
          .map((node: IMenu) => node.link),
        menu: action.data,
        clientLogo: action.data.clientLogo,
      };

    case Actions.GET_ALLOWED_DOMAINS:
      return {
        ...state,
        ...{
          allowedDomainsLoading: true,
          allowedDomainsLoaded: false,
        },
      };

    case Actions.GET_ALLOWED_DOMAINS_COMPLETED:
      return {
        ...state,
        ...{
          allowedDomainsLoading: false,
          allowedDomainsLoaded: true,
          allowedDomains: action.data,
        },
      };

    case Actions.GET_AUTHORIZED_PAGES:
      return {
        ...state,
        authorizedPagesLoading: true,
        authorizedPagesLoaded: false,
      };

    case Actions.SET_AUTHORIZED_PAGES:
      return {
        ...state,
        authorizedPagesLoading: false,
        authorizedPagesLoaded: true,
        authorizedPages: state.menu.menus
          .filter(
            (node: IMenu) =>
              node.link !== '#' &&
              !node.external &&
              (!node.requiredRights.rights.length ||
                (node.requiredRights.operator === ERightOperators.OR
                  ? _.some(node.requiredRights.rights, (requiredRight) => {
                      return _.keys(_.pickBy(action.data)).includes(requiredRight);
                    })
                  : _.every(node.requiredRights.rights, (requiredRight) => {
                      return _.keys(_.pickBy(action.data)).includes(requiredRight);
                    }))),
          )
          .map((node: IMenu) => node.link),
        userRights: action.data,
      };

    case Actions.SET_CLIENT_LOGO_URL:
      return {
        ...state,
        clientLogoUrl: action.payload,
      };

    default:
      return state;
  }
}
