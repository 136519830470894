import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-mat-checkbox',
  templateUrl: './scw-mat-checkbox.component.html',
  styleUrls: ['./scw-mat-checkbox.component.scss'],
  host: { class: 'scw-mat-checkbox-host' },
})
export class ScwMatCheckboxComponent {
  @Input() inputModel: boolean = false;
  @Input() disabled: boolean = false;
  @Input() id!: string;
  @Input() labelPosition!: 'before' | 'after';
  @Input() name!: string;
  @Input() required: boolean = false;
  @Input() value!: string;
  @Input() hasErrors: boolean = false;
  @Input() label!: string;
  @Input() errorText: string | null = null;
  @Output() onChange: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
  @Output() inputModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly translate: TranslateService) {}

  public onChangeEmitter($event: MatCheckboxChange): void {
    this.onChange.emit($event);
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }

  public reset(): void {
    this.inputModel = false;
    this.clearErrorMessage();
  }

  public clearErrorMessage(): void {
    this.hasErrors = false;
    this.errorText = null;
  }

  public showErrorMessage(message: string): void {
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  private checkRules(): void {
    if (!this.inputModel && this.required) {
      this.showErrorMessage(this.errorText ?? this.translate.instant('scwMatForm.validation.required'));
    } else {
      this.clearErrorMessage();
    }
  }
}
