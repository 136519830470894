export interface ILayout {
  navType: string;
  themeLayout: string;
  verticalPlacement: string;
  verticalLayout: string;
  pcodedDeviceType: string;
  verticalNavType: string;
  verticalEffect: string;
  freamType: string;
  layoutType: string;
  headerTheme: string;
  pcodedHeaderPosition: string;
  profileDropdown: string;
  profileDropdownClass: string;
  navBarDropdowns: any;
  rippleClass: string;
  searchWidth: number;
  searchWidthString: string;
  navRight: string;
  windowWidth: number;
  toggleOn: boolean;
  toggleIcon: string;
  navBarTheme: string;
  activeItemTheme: string;
  headerFixedTop: string;
  menuTitleTheme: string;
  dropDownIcon: string;
  subItemIcon: string;
  configOpenRightBar: string;
  displayBoxLayout: string;
  isVerticalLayoutChecked: boolean;
  isHeaderChecked: boolean;
  headerFixedMargin: string;
  itemBorderStyle: string;
  subItemBorder: boolean;
  itemBorder: boolean;
  pageHeaderPaddingTop: string;
  psDisabled: string | null;
  perfectDisable: string;
  hideNavMenu: boolean;
  clientLogo: number | null;
  userName: string | null;
  config: any;
}

export class DeviceType {
  public static desktop: string = 'desktop';
  public static tablet: string = 'tablet';
  public static phone: string = 'phone';
}

export class LayoutConstants implements ILayout {
  public animateSidebar = '';
  public navType = 'st2';
  public themeLayout = 'horizontal';
  public verticalPlacement = 'top';
  public verticalLayout = 'wide';
  public pcodedDeviceType = DeviceType.desktop;
  public verticalNavType = 'expanded';
  public verticalEffect = 'shrink';
  public vnavigationView = 'view1';
  public freamType = 'theme6';
  public layoutType = 'light';
  public headerTheme = 'theme6';
  public pcodedHeaderPosition = 'fixed';
  public headerFixedTop = 'auto';
  public profileDropdown = 'an-off';
  public profileDropdownClass!: string;
  public navBarDropdowns: any;
  public rippleClass = 'ripple';
  public searchWidth = 0;
  public searchWidthString = '';
  public navRight = 'nav-on';
  public windowWidth = window.innerWidth;
  public toggleOn = true;
  public toggleIcon = 'icon-x';
  public navBarTheme = 'themelight1';
  public activeItemTheme = 'theme9';
  public menuTitleTheme = 'theme6';
  public dropDownIcon = 'style1';
  public subItemIcon = 'style1';
  public displayBoxLayout = 'd-none';
  public isVerticalLayoutChecked = false;
  public isHeaderChecked = true;
  public headerFixedMargin = '50px';
  public itemBorderStyle = 'none';
  public subItemBorder = true;
  public itemBorder = true;
  public pageHeaderPaddingTop = '0';
  public perfectDisable = '';
  public clientLogo!: number | null;
  public config: any;
  public configOpenRightBar: string = '';
  public hideNavMenu = true;
  public psDisabled!: string | null;
  public userName!: string | null;
}
