import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScopeType } from './scope-settings-navigator-modal.model';
import { TranslateService } from '@ngx-translate/core';
import { LogbookActionsType } from '../../../view/settings/logbook/logbook.model';
import { FormActionsType } from '../../../view/settings/form/form.model';
import { LogbookVersionActionType } from '../../../view/settings/logbook-versions/logbook-versions.model';
import * as _ from 'lodash';

@OnDestroyDecorator
@Component({
  selector: 'app-scope-settings-navigator-modal',
  templateUrl: './scope-settings-navigator-modal.component.html',
})
export class ScopeSettingsNavigatorModalComponent {
  @ViewChild('scope_modal') scopeModal!: TemplateRef<any>;
  @Input() scopeType!: ScopeType;

  constructor(private readonly modal: NgbModal, private readonly translate: TranslateService) {}

  private scopeModalRef!: NgbModalRef;
  public scopeModalText: string = '';
  public scopeModalHeader: string = '';

  public showModal(
    actionType: LogbookActionsType | FormActionsType | LogbookVersionActionType,
    successCount: number,
    isAllScope?: boolean,
    scopeName?: string | null,
  ): void {
    const scopeTypeTranslationKey: string = this.scopeType === 'form' ? 'forms.modal' : 'logbooks';
    const hasNoSpecificScope: boolean = isAllScope || _.isNil(scopeName);

    if (actionType === 'add') {
      this.scopeModalText = hasNoSpecificScope
        ? this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.textAll`)
        : this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.text`, {
            scopeName,
          });
      this.scopeModalHeader = this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.header`);
    } else if (actionType === 'excel') {
      this.scopeModalText =
        successCount === 1
          ? hasNoSpecificScope
            ? this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.textAll`)
            : this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.text`, {
                scopeName,
              })
          : hasNoSpecificScope
          ? this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.textPluralAll`)
          : this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.textPlural`, {
              scopeName,
            });
      this.scopeModalHeader =
        successCount === 1
          ? this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.header`)
          : this.translate.instant(`settings.${scopeTypeTranslationKey}.scope.headerPlural`);
    }

    this.scopeModalRef = this.modal.open(this.scopeModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  public goToScopeSettings(): void {
    this.scopeModalRef.close();
    window.location.href = `#/settings/scopes#${this.scopeType}-scopes`;
  }
}
