import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IBaseCrudResponse,
  IBaseOneResponse,
  IGetManyResponse,
  IGetOneResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IDeleteMyReport, IMyReportsData, IAddMyReport } from './my-reports.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';

export const START_MY_REPORTS_LOADING = '[My Reports] Start My Reports Loading';
export const MY_REPORTS_LOADED = '[My Reports] My Reports Loaded';
export const START_GET_RECORD_LOADING = '[My Reports] Start Get Record Loading';
export const GET_RECORD_COMPLETED = '[My Reports] Get Record Loaded';
export const START_ADD_RECORD_LOADING = '[My Reports] Start Add Record Loading';
export const ADD_RECORD_COMPLETED = '[My Reports] Add Record Completed';
export const START_EDIT_RECORD_LOADING = '[My Reports] Start Edit Record Loading';
export const EDIT_RECORD_COMPLETED = '[My Reports] Edit Record Completed';
export const START_REMOVE_RECORD_LOADING = '[My Reports] Start Remove Record Loading';
export const REMOVE_RECORD_COMPLETED = '[My Reports] Remove Record Completed';
export const START_BULK_REMOVE_RECORD_LOADING = '[My Reports] Start Bulk Remove Record Loading';
export const BULK_REMOVE_RECORD_COMPLETED = '[My Reports] Remove Bulk Record Completed';
export const CLEAR_STATE = '[My Reports] Clear State';
export const REPORT_NOT_FOUND = '[My Reports] Report Not Found';
export const FETCH_ERROR = '[My Reports] Fetch Error';

export class StartMyReportsLoading implements Action {
  readonly type = START_MY_REPORTS_LOADING;

  constructor(public tableQuery: ITableQuery) {}
}

export class MyReportsLoaded implements Action {
  readonly type = MY_REPORTS_LOADED;

  constructor(public payload: IGetManyResponse<IMyReportsData>) {}
}

export class StartGetRecordLoading implements Action {
  readonly type = START_GET_RECORD_LOADING;

  constructor(public id: number, public dispatchHideLoader: boolean = true) {}
}
export class GetRecordCompleted implements Action {
  readonly type = GET_RECORD_COMPLETED;

  constructor(public payload: IGetOneResponse<IMyReportsData>) {}
}

export class StartAddRecordLoading implements Action {
  readonly type = START_ADD_RECORD_LOADING;

  constructor(public record: IAddMyReport, public issuer: IIssuer | null) {}
}
export class AddRecordCompleted implements Action {
  readonly type = ADD_RECORD_COMPLETED;

  constructor(public payload: IBaseOneResponse<IAddMyReport>) {}
}

export class StartEditRecordLoading implements Action {
  readonly type = START_EDIT_RECORD_LOADING;

  constructor(public record: IAddMyReport, public issuer: IIssuer | null) {}
}
export class EditRecordCompleted implements Action {
  readonly type = EDIT_RECORD_COMPLETED;

  constructor(public payload: IBaseOneResponse<IAddMyReport>) {}
}

export class StartRemoveRecordLoading implements Action {
  readonly type = START_REMOVE_RECORD_LOADING;

  constructor(public record: IAddMyReport, public issuer: IIssuer | null) {}
}
export class RemoveRecordCompleted implements Action {
  readonly type = REMOVE_RECORD_COMPLETED;

  constructor(public payload: IBaseOneResponse<IAddMyReport>) {}
}

export class StartBulkRemoveRecordLoading implements Action {
  readonly type = START_BULK_REMOVE_RECORD_LOADING;

  constructor(public payload: IDeleteMyReport, public issuer: IIssuer | null) {}
}
export class BulkRemoveRecordCompleted implements Action {
  readonly type = BULK_REMOVE_RECORD_COMPLETED;

  constructor(public payload: IBaseCrudResponse) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ReportNotFound implements Action {
  readonly type = REPORT_NOT_FOUND;
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;

  constructor(public payload: IGenericObject<any>) {}
}

export type MyReportsActions =
  | StartMyReportsLoading
  | MyReportsLoaded
  | StartGetRecordLoading
  | GetRecordCompleted
  | StartAddRecordLoading
  | AddRecordCompleted
  | StartEditRecordLoading
  | EditRecordCompleted
  | StartRemoveRecordLoading
  | RemoveRecordCompleted
  | StartBulkRemoveRecordLoading
  | BulkRemoveRecordCompleted
  | FetchError
  | ReportNotFound
  | ClearState;
