import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as ObjectActions from './logbook-master-data.actions';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { LogbookMasterDataService } from './logbook-master-data.service';
import { catchError, switchMap, of } from 'rxjs';
import { ILogbookMasterData, ILogbookMasterDataHistory } from './logbook-master-data.model';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { WorkflowsService } from '../workflows/workflows.service';
import { IWorkflow } from '../workflows/workflows.model';
import { EApprovalStatuses } from '../../../shared/model/enum/constants';

@Injectable()
export class LogbookMasterDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: LogbookMasterDataService,
    private readonly workflowsService: WorkflowsService,
  ) {}

  getLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_DATA_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataDataLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest(payload.tableQuery);

        return this.service.getLogbookMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterData>) => {
            return of(new ObjectActions.LogbookMasterDataDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getShowLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_HISTORY_DATA_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataHistoryDataLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookMasterDataHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterDataHistory>) => {
            return of(new ObjectActions.LogbookMasterDataHistoryDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookMasterDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_DETAIL_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataDetailLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookMasterDetailData(payload.id, payload.getPreviousVersion).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataDetailLoaded({
                success: response[0].success && response[1].success,
                data: { ...response[0].data, fields: response[1].data ?? [] },
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  sendToReviewLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataSendToReviewLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.sendToReviewLogbookMaster(payload.id, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataSendToReviewLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.LogbookMasterDataRefreshLoad(ObjectActions.LOGBOOK_MASTER_DATA_SEND_TO_REVIEW_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  activateLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_ACTIVATE_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataActivateLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.activateLogbookMaster(payload.id, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataActivateLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.LogbookMasterDataRefreshLoad(ObjectActions.LOGBOOK_MASTER_DATA_ACTIVATE_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_ADD_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataAddLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addLogbookMaster(payload.payload, payload.issuerAndReason).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataAddLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.LogbookMasterDataRefreshLoad(ObjectActions.LOGBOOK_MASTER_DATA_ADD_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_EDIT_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataEditLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editLogbookMaster(payload.id, payload.payload, payload.issuer).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataEditLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.LogbookMasterDataRefreshLoad(ObjectActions.LOGBOOK_MASTER_DATA_EDIT_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_DELETE_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataDeleteLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteLogbookMaster(payload.id, payload.issuerAndReason).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataDeleteLoaded(response),
              new AppActions.HideLoader(),
              new ObjectActions.LogbookMasterDataRefreshLoad(ObjectActions.LOGBOOK_MASTER_DATA_DELETE_LOADED),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookMasterDataApprovalWorkflow = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_APPROVAL_WORKFLOW_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataApprovalWorkflowLoad) => {
        if (payload.skipLoader) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          limit: payload.limit,
          search: payload.searchText,
          just_workflow_data: true,
          include_disabled: true,
        });

        return this.workflowsService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(
              new ObjectActions.LogbookMasterDataApprovalWorkflowLoaded(response),
              payload.skipLoader ? new AppActions.EmptyAction() : new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookMasterWorkflowsStepsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_WORKFLOW_STEPS_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataWorkflowsStepsLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workflowsService.getWorkflowsSteps(payload.id).pipe(
          switchMap((response) => {
            return of(new ObjectActions.LogbookMasterDataWorkflowsStepsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  cloneLogbookMaster = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_CLONE_LOAD),
      switchMap((payload: ObjectActions.LogbookMasterDataCloneLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookMasterDetailData(payload.id).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.LogbookMasterDataAddLoad(
                this.service.formatDetailToAdd({ ...response[0].data, fields: response[1].data ?? [] }, true),
                payload.issuerAndReason,
              ),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  passedIssueDateLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_PASSED_ISSUE_DATE_LOAD),
      switchMap((_payload: ObjectActions.LogbookMasterDataPassedIssueDateLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams()
          .append('issued_date__lte', moment().utc().format())
          .append('issued_date__isnull', false)
          .append('is_active', false)
          .append(
            'approval_step_position__not_in',
            [EApprovalStatuses.ACTIVE, EApprovalStatuses.OBSOLETE, EApprovalStatuses.CANCELED].join(','),
          )
          .append('limit', 1);

        return this.service.getLogbookMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterData>) => {
            return of(new ObjectActions.LogbookMasterDataPassedIssueDateLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  draftVersionExistsLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_MASTER_DATA_DRAFT_VERSION_EXISTS_LOAD),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().append('approval_step_position__gte', -1).append('limit', 1);

        return this.service.getLogbookMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterData>) => {
            return of(
              new ObjectActions.LogbookMasterDataDraftVersionExistsLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
