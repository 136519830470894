import * as Actions from './reason.actions';
import { Action } from '@ngrx/store';
import { IReasonState } from './reason.model';

export const reasonState: IReasonState = {
  reasonData: null,
  getReasonsLoaded: false,
  getReasonsLoading: false,
};

export function reasonReducer(state: IReasonState = reasonState, baseAction: Action): IReasonState {
  const action = baseAction as Actions.ReasonActions;
  switch (action.type) {
    case Actions.GET_REASONS:
      return {
        ...state,
        getReasonsLoading: true,
        getReasonsLoaded: false,
        reasonData: null,
      };
    case Actions.GET_REASONS_COMPLETED:
      return {
        ...state,
        getReasonsLoading: false,
        getReasonsLoaded: true,
        reasonData: action.payload,
      };
    default:
      return state;
  }
}
