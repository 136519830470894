export enum ELogbookGridView {
  Favorites = 'favorites',
  Ongoing = 'ongoing',
  Network = 'network',
  Parent = 'parent',
}

export interface IGridViewHeader {
  title: string;
  icon: string;
}
