import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { HelperService } from '../../service/helper.service';
import { HttpStatusCode } from '@angular/common/http';
import * as _ from 'lodash';
import * as ReasonActions from '../../../store/reason/reason.actions';
import { IReason, IReasonState } from '../../../store/reason/reason.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';

@OnDestroyDecorator
@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss'],
})
export class ReasonComponent implements OnInit, OnDestroy {
  @ViewChild('reason_modal') reasonModalRef!: ViewChildDecorator;
  @Input() actionsToSubscribe: string[] = [];
  @Input() reason!: string | null;
  @Input() dispatchHideLoader: boolean = true;
  @Output() reasonChange: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() onReasonEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public readonly translate: TranslateService,
    private readonly storeActions: ActionsSubject,
    public readonly helperService: HelperService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly ngbModal: NgbModal,
  ) {}

  public ngbModalRef!: NgbModalRef;
  public reasonRule: ScwMatInputRule[] = [
    {
      custom: true,
      message: this.translate.instant('scwMatForm.validation.maxLength', { maxLength: 255 }),
      validator: (value: string | number | null) => {
        return (
          (value?.toString().length || 0) +
            ' - '.length +
            (this.reasonInputModel.length ? this.reasonInputModel[0].name?.length || 0 : 0) <=
          255
        );
      },
    },
    {
      required: true,
    },
    {
      custom: true,
      message: this.translate.instant('alertSettings.validation.isNotBlank'),
      validator: (value: string | number | null) => {
        return !_.isEmpty(String(value ?? '').trim());
      },
    },
  ];
  private readonly subscriptions: Subscription[] = [];
  public reasons$: IReason[] = [];
  public reasonInputModel: IReason[] = [];
  public showInput: boolean = true;
  public inputLabelTranslation: string = `${this.translate.instant('modal.reason.reason.label')}*`;
  public inputLabelWithPredefinedReasons: string = `${this.translate.instant('modal.reason.otherReason.label')}*`;
  private reasonLoading: boolean = false;

  public submit(isValid: boolean): void {
    this.reason = this.reason?.trim() ?? null;

    if (
      this.reasonInputModel.length &&
      !this.reasonInputModel[0].hasAdditionalField &&
      this.reasonInputModel[0].name &&
      isValid
    ) {
      this.reason = this.reasonInputModel[0].name;
    }

    if (this.reason && this.reasonInputModel[0]?.hasAdditionalField && isValid) {
      this.reason = [this.reasonInputModel[0].name, this.reason].join(' - ');
    }

    this.reasonChange.emit(this.reason);
    this.onReasonEmit.emit(isValid && Boolean(this.reason?.length));
  }

  public showModal(): void {
    this.ngbModalRef = this.ngbModal.open(this.reasonModalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  public closeModal(): void {
    this.reason = null;
    this.reasonInputModel = [];

    if (this.reasons$.length) {
      this.showInput = false;
    }
    this.reasonChange.emit(this.reason);
    this.ngbModalRef?.dismiss();
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('reasonStore').subscribe((state: IReasonState) => {
        if (state.getReasonsLoaded && !state.getReasonsLoading && state.reasonData) {
          this.reasons$ = state.reasonData.filter((reason: IReason): boolean => reason.isActive);

          this.showInput = !this.reasons$.length;
        }

        if (!state.getReasonsLoaded && state.getReasonsLoading) {
          this.reasonLoading = true;
        }
      }),
    );

    if (!this.reasonLoading) {
      this.store.dispatch(new ReasonActions.GetReasons(this.dispatchHideLoader));
    }

    this.actionsToSubscribe.forEach((action: string) => {
      this.subscriptions.push(
        this.storeActions.pipe(ofType(action)).subscribe((response: any) => {
          if (
            response.payload?.error?.errors?.some(
              (error: any) => error.message === 'issuer_not_found' || error.message === 'issuer_not_authorized',
            ) ||
            response.payload?.status === HttpStatusCode.NotAcceptable
          ) {
            return;
          }

          this.closeModal();
        }),
      );
    });
  }

  public onReasonChange(selectedReason: IReason[]): void {
    if (selectedReason.length === 0 || selectedReason[0].hasAdditionalField) {
      this.reason = null;
    }

    this.showInput = selectedReason[0] && selectedReason[0].hasAdditionalField;

    if (this.showInput) {
      this.inputLabelTranslation =
        `${selectedReason[0]?.additionalFieldLabel}*` ?? this.inputLabelWithPredefinedReasons;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
