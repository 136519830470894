import { ISelect } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { FormioForm } from 'angular-formio';

export interface ICompareField {
  type: EFieldType;
  label: string;
  highlighted?: boolean;
  required?: boolean;
  values: any;
  button?: {
    disabled: any;
    buttonIconCls: string;
  };
  data?: ISelect<number, string>[];
  depends?: {
    [header: string]: boolean;
  };
  content?: ICompareField[];
}

export interface ICompareHeader {
  label: string;
  iconCls?: string;
  innerLabel?: string;
  innerLabelIconCls?: string;
  showButton?: boolean;
  value: string;
}

export interface ICompareFormio {
  [value: string]: {
    name: string;
    form: FormioForm;
  } | undefined;
}

export enum EFieldType {
  INPUT = 'input',
  DATE = 'date',
  SELECT = 'select',
  LABEL = 'label',
  GROUP = 'group',
}
