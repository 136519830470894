import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './logbook-versions.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import { IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { LogbookVersionsService } from './logbook-versions.service';
import { ILogbookVersionWithLogbookDetail } from './logbook-versions.model';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import * as _ from 'lodash';
import { ELogbookVersionsRequestingPages } from '../../shared/model/enum/constants';

@Injectable()
export class LogbookVersionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly service: LogbookVersionsService,
  ) {}

  getLogbookVersions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOK_VERSIONS_DATA_LOADING),
      switchMap((payload: ObjectActions.LogbookVersionsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery?.page ?? 1,
          rowsPerPage: payload.tableQuery?.rowsPerPage ?? 10,
          sort: payload.tableQuery?.sort ?? { type: '-', column: 'logbook' },
          ...(payload.tableQuery?.search && { search: payload.tableQuery.search }),
          ...(!_.isNil(payload.tableQuery?.versionType) && { versionType: payload.tableQuery?.versionType }),
          ...(!_.isNil(payload.statuses) &&
            Array.isArray(payload.statuses) && { statuses: JSON.stringify(payload.statuses) }),
        });

        httpParams = httpParams.append('requesting_page', ELogbookVersionsRequestingPages.LOGBOOK_SETTINGS);

        return this.service.getLogbookVersions(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookVersionWithLogbookDetail>) => {
            return of(
              new ObjectActions.LogbookVersionsDataLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
