import { IUserSettingsState } from './users.model';
import * as Actions from './users.actions';
import { Action } from '@ngrx/store';

export const userSettingState: IUserSettingsState = {
  userDataLoading: false,
  userDataLoaded: false,
  userData: null,
  users: null,
  roles: null,
  logbookScopes: null,
  formScopes: null,
  logbooks: null,
  forms: null,
  allowedDomains: null,
  rights: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  addUserLoading: false,
  addUserLoaded: false,
  editUserLoading: false,
  editUserLoaded: false,
  cicoControlLoading: false,
  cicoControlLoaded: false,
  pinChangeLoading: false,
  pinChangeLoaded: false,
  bulkEditUserLoading: false,
  bulkEditUserLoaded: false,
  rightAssignmentsLoading: false,
  rightAssignmentsLoaded: false,
  userRightAssignments: null,
  roleRightAssignments: null,
  roleRightAssignmentsLoading: false,
  roleRightAssignmentsLoaded: false,
  upsertUserRightAssignmentsLoading: false,
  upsertUserRightAssignmentsLoaded: false,
  userRightAssignmentsLoading: false,
  userRightAssignmentsLoaded: false,
  setUserRightAssignmentsLoading: false,
  setUserRightAssignmentsLoaded: false,
  filters: null,
  tableSettings: [],
};

export function userSettingsReducer(
  state: IUserSettingsState = userSettingState,
  baseAction: Action,
): IUserSettingsState {
  const action = baseAction as Actions.UserSettingsActions;

  switch (action.type) {
    case Actions.USER_SETTINGS_DATA_LOADING:
      return {
        ...state,
        userDataLoading: true,
        userDataLoaded: false,
      };
    case Actions.USER_SETTINGS_DATA_LOADED:
      return {
        ...state,
        users: action.payload.users,
        roles: action.payload.roles,
        logbookScopes: action.payload.logbookScopes,
        formScopes: action.payload.formScopes,
        logbooks: action.payload.logbooks,
        forms: action.payload.forms,
        allowedDomains: action.payload.allowedDomains,
        userDataLoading: false,
        userDataLoaded: true,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        userDataLoading: false,
        userDataLoaded: false,
        tableSettings: action.payload,
      };
    case Actions.DOWNLOAD_USERS_ERROR_EXCEL:
    case Actions.DOWNLOAD_USERS_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };
    case Actions.DOWNLOAD_USERS_ERROR_EXCEL_COMPLETED:
    case Actions.DOWNLOAD_USERS_EXCEL_COMPLETED:
    case Actions.DOWNLOAD_USERS_EXCEL_WITH_DATA_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };
    case Actions.UPLOAD_USER_EXCEL:
      return {
        ...state,
        isUploadExcelLoading: true,
        isUploadExcelLoaded: false,
      };
    case Actions.UPLOAD_USER_EXCEL_COMPLETED:
      return {
        ...state,
        excelUploadResult: action.payload,
        isUploadExcelLoading: false,
        isUploadExcelLoaded: true,
      };
    case Actions.ADD_USER:
      return {
        ...state,
        addUserLoading: true,
        addUserLoaded: false,
      };
    case Actions.ADD_USER_COMPLETED:
      return {
        ...state,
        addUserLoading: false,
        addUserLoaded: true,
      };
    case Actions.EDIT_USER:
      return {
        ...state,
        editUserLoading: true,
        editUserLoaded: false,
      };
    case Actions.EDIT_USER_COMPLETED:
      return {
        ...state,
        editUserLoading: false,
        editUserLoaded: true,
      };
    case Actions.PIN_CHANGE:
      return {
        ...state,
        pinChangeLoading: true,
        pinChangeLoaded: false,
      };
    case Actions.PIN_CHANGE_COMPLETED:
      return {
        ...state,
        pinChangeLoading: false,
        pinChangeLoaded: true,
      };
    case Actions.EDIT_USERS:
      return {
        ...state,
        bulkEditUserLoading: true,
        bulkEditUserLoaded: false,
      };
    case Actions.EDIT_USERS_COMPLETED:
      return {
        ...state,
        bulkEditUserLoading: false,
        bulkEditUserLoaded: true,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.GET_RIGHT_ASSIGNMENTS:
      return {
        ...state,
        rightAssignmentsLoading: true,
        rightAssignmentsLoaded: false,
      };
    case Actions.GET_RIGHT_ASSIGNMENTS_COMPLETED:
      return {
        ...state,
        userRightAssignments: action.payload.userRightAssignments,
        roleRightAssignments: action.payload.roleRightAssignments,
        rights: action.payload.rights,
        rightAssignmentsLoading: false,
        rightAssignmentsLoaded: true,
      };
    case Actions.GET_ROLE_RIGHT_ASSIGNMENTS:
      return {
        ...state,
        roleRightAssignmentsLoading: true,
        roleRightAssignmentsLoaded: false,
      };
    case Actions.GET_ROLE_RIGHT_ASSIGNMENTS_COMPLETED:
      return {
        ...state,
        rights: action.payload.rights,
        roleRightAssignments: action.payload.roleRightAssignments,
        roleRightAssignmentsLoading: false,
        roleRightAssignmentsLoaded: true,
      };
    case Actions.GET_USER_RIGHT_ASSIGNMENTS:
      return {
        ...state,
        userRightAssignmentsLoading: true,
        userRightAssignmentsLoaded: false,
      };
    case Actions.GET_USER_RIGHT_ASSIGNMENTS_COMPLETED:
      return {
        ...state,
        userRightAssignments: action.payload,
        userRightAssignmentsLoading: false,
        userRightAssignmentsLoaded: true,
      };
    case Actions.GET_USER_RIGHT_ASSIGNMENTS:
      return {
        ...state,
        setUserRightAssignmentsLoading: true,
        setUserRightAssignmentsLoaded: false,
      };
    case Actions.GET_USER_RIGHT_ASSIGNMENTS_COMPLETED:
      return {
        ...state,
        setUserRightAssignmentsLoading: false,
        setUserRightAssignmentsLoaded: true,
      };
    default:
      return state;
  }
}
