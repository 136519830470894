import { Injectable } from '@angular/core';
import { ITableHeader } from '../../../../../constants.model';
import { HelperService } from '../../helper.service';
import moment from 'moment';
import { IFieldMetaDropdown } from '../../../../view/settings/form/form.model';
import { TranslateService } from '@ngx-translate/core';
import { IFieldVersion } from '../../../../view/reports/form-templates/form-templates.model';
import * as _ from 'lodash';
import { IFieldMetaField, ITemplateData } from './templates-helper.model';

@Injectable({ providedIn: 'root' })
export class TemplatesHelperService {
  constructor(private readonly translate: TranslateService) {}

  private readonly masterDataItemPropertyPrefix: 'master_data_item_' = 'master_data_item_';

  public formatTemplateMasterDataValues(
    templateData: (unknown & ITemplateData)[],
    masterDataColumns: ITableHeader[],
  ): (any & ITemplateData)[] {
    return templateData.map((data: unknown & ITemplateData): unknown & ITemplateData => {
      let fieldMetaValues: { [key: string]: string } = {};

      data.fieldMeta.forEach((field: IFieldMetaField): void => {
        const fieldKey: string = field.fieldId.split('_').splice(2, field.fieldId.split('_').length).join('_');

        for (const item of masterDataColumns) {
          if (fieldKey === item.originalValue) {
            const formattedFieldMetaValue: string = this.formatColumnInputModel(field);
            const fieldMetaItemKey: string = item.value;
            const fieldMetaIteValue: string = !fieldMetaValues[fieldMetaItemKey]
              ? formattedFieldMetaValue
              : `${fieldMetaValues[fieldMetaItemKey]}${!formattedFieldMetaValue ? '' : `, ${formattedFieldMetaValue}`}`;

            fieldMetaValues = {
              ...fieldMetaValues,
              [fieldMetaItemKey]: fieldMetaIteValue,
            };

            break;
          }
        }
      });

      return {
        ...data,
        ...fieldMetaValues,
      };
    });
  }

  public formatTemplateColumns(masterDataItems: IFieldVersion[]): [ITableHeader[], ITableHeader[]] {
    const tableHeaders: ITableHeader[] = [];

    masterDataItems.forEach((item: IFieldVersion) => {
      if (!item.meta) {
        return;
      }

      const fieldIdSplit: string[] = item.meta.fieldId.split('_');
      const fieldIdKey: string = `${this.masterDataItemPropertyPrefix}${_.snakeCase(item.name.toLowerCase())}`;

      tableHeaders.push({
        originalValue: fieldIdSplit.splice(2, fieldIdSplit.length).join('_'),
        value: fieldIdKey,
        name: item.name,
        sortable: false,
        formattedValue: item.meta.fieldId,
      });
    });

    const uniqueTableHeaders: ITableHeader[] = _.uniqBy(tableHeaders, 'value');

    return [uniqueTableHeaders, tableHeaders];
  }

  private formatColumnInputModel(data: IFieldMetaField): string {
    const inputModel = data.inputModel;

    switch (typeof inputModel) {
      case 'boolean':
        return inputModel ? this.translate.instant('general.checked') : this.translate.instant('general.unChecked');
      case 'string':
      case 'number':
        return String(inputModel);
      case 'object':
        if (inputModel && !Array.isArray(inputModel)) {
          const timezone: string = HelperService.userTimeZone ?? 'utc';

          return moment(inputModel.startDate).tz(timezone).format(HelperService.userDateTimeFormat);
        }
        return inputModel?.map((key: IFieldMetaDropdown) => key.label).join(', ');
      default:
        return inputModel;
    }
  }
}
