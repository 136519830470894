import { Pipe, PipeTransform } from '@angular/core';
import { IFormSubmissionHistory } from '../../view/home/form-submission-table/form-submission-table.model';
import { systemUserDefaultEmail } from '../../../constants';
import { TranslateService } from '@ngx-translate/core';

const apiReasonLanguageKeys: string[] = [
  'manualApiReason.automaticallyActivatedOnDueDate',
  'manualApiReason.logbookAutomaticallyAddedToScope',
  'manualApiReason.formAutomaticallyAddedToScope',
  'manualApiReason.logbookRemovedFromScopeDueToDeletion',
  'manualApiReason.formRemovedFromScopeDueToDeletion',
  'manualApiReason.systemActivatedLogbookVersion',
  'manualApiReason.systemActivatedFormVersion',
  'manualApiReason.obsoletedAutomatically',
  'manualApiReason.obsoletedAutomaticallyFromFlaggedToBeObsolete',
  'general.archived',
  'general.logbookMove',
  'manualApiReason.theSystemAutomaticallyDeletedThisLogConfiguration',
  'manualApiReason.theSystemAutomaticallyUpdatedThisLogConfiguration',
];

function translateApiReason(translate: TranslateService, reason: string): string {
  return apiReasonLanguageKeys.includes(reason) ? translate.instant(reason) : reason;
}

@Pipe({
  name: 'formSubmissionReason',
})
export class FormSubmissionReasonPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  private readonly systemCheckInMessage: string = this.translate.instant('checkIn.systemCheckInReasonMessage');
  private readonly systemCheckOutMessage: string = this.translate.instant('checkIn.systemCheckOutReasonMessage');

  transform(item: IFormSubmissionHistory): string {
    if (item.actionBy.email === systemUserDefaultEmail && ['check_in', 'check_out'].indexOf(item.actionRaw) !== -1) {
      switch (item.actionRaw) {
        case 'check_in':
          return this.systemCheckInMessage;
        case 'check_out':
          return this.systemCheckOutMessage;
      }
    }

    return translateApiReason(this.translate, item.actionReason);
  }
}

@Pipe({
  name: 'logHistoryReason',
})
export class LogHistoryReasonPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(reason: string): string {
    return translateApiReason(this.translate, reason);
  }
}
