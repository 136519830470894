import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';
import { ScwMatDropdownComponent } from './scw-mat-dropdown.component';

@NgModule({
  imports: [CommonModule, ScwMatButtonModule, ScwMatSmallButtonModule],
  exports: [ScwMatDropdownComponent],
  declarations: [ScwMatDropdownComponent],
})
export class ScwMatDropdownModule {}
