<div class="page-body">
  <div class="row">
    <div class="col">
      <filter-card
        [options]="filterOptions"
        [strictControlForSelected]="true"
        [defaultFilterSelectionsSubject]="defaultFilterSelectionsSubject"
        [additionalGridWide]="3"
        [mainGridWide]="9"
        (filterChange)="onFiltersChanged($event)"
      >
        <div class="additional-fields" additionalFields>
          <div class="search-box-container">
            <div class="no-margin">
              <scw-mat-search
                placeholder="{{ 'general.search' | translate }}"
                behavior="delayed"
                [minLength]="0"
                [block]="true"
                [(inputModel)]="searchBoxText"
                (onSearch)="onKeyUpSearchBox($event)"
              >
              </scw-mat-search>
            </div>
          </div>
        </div>
      </filter-card>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-block">
    <div class="row">
      <div class="col-lg-6">
        <div class="left-grp">
          <scw-mat-small-button (onClick)="selectOrUnselectAll()" [disabled]="noSelectableRecord">
            {{ isSelectAll ? ('general.selectAll' | translate) : ('general.unselectAll' | translate) }}
          </scw-mat-small-button>
          <scw-mat-small-button
            (onClick)="showBulkActionConfirmationModal('bulk-submit')"
            [disabled]="!this.isDraftRecordSelected || this.isApprovedOrCompletedRecordSelected"
          >
            {{ 'general.submit' | translate }}
          </scw-mat-small-button>
          <scw-mat-small-button
            (onClick)="showBulkActionConfirmationModal('bulk-activate')"
            [disabled]="!this.isApprovedOrCompletedRecordSelected || this.isDraftRecordSelected"
          >
            {{ 'general.activate' | translate }}
          </scw-mat-small-button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="right-grp">
          <input
            hidden
            required
            type="file"
            class="upload input-common"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, "
            (change)="readExcel($event)"
            #uploader
          />
          <scw-mat-small-button (onClick)="downloadExcel()">
            <em class="fas fa-download"></em>
            {{ 'excel.downloadTemplate' | translate }}
          </scw-mat-small-button>
          <scw-mat-dropdown [menu]="dropdownMenuItems" (onClickItem)="dropdownClick($event)">
            <em class="fas fa-plus"></em>
            {{ 'forms.addForm' | translate }}
          </scw-mat-dropdown>
        </div>
      </div>
    </div>
    <mat-hint
      class="error p-b-5 bulk-action-error-hint"
      *ngIf="this.isDraftRecordSelected && this.isApprovedOrCompletedRecordSelected"
    >
      <em class="sub-text-icon fas fa-exclamation-circle"></em>
      {{ selectedStatusesIncompatibleErrorMessage }}
    </mat-hint>
    <datatable
      paginationSize="lg"
      noDataText="{{ 'datatable.noData' | translate }}"
      [serverSide]="true"
      [headers]="selectedFormTableHeaders"
      [items]="formTableData$"
      [itemsCount]="formTablePageCount$"
      [currentPage]="tableQuery.page"
      [rowsPerPage]="tableQuery.rowsPerPage"
      (onDataRequest)="onDataRequestHandler($event)"
    >
      <ng-template let-item let-i="index">
        <td
          *ngFor="let header of selectedFormTableHeaders; index as i"
          [class.versionCellWithBorderColor]="header.value === 'approvalStepPosition'"
        >
          <div *ngIf="i === 0">
            <scw-mat-checkbox
              [id]="versionRecordCheckboxIdPrefix + item.id"
              [value]="item.id"
              [disabled]="!bulkActionService.selectableStatuses.includes(item['approvalStepPosition'])"
              (onChange)="onTableCheckboxChanged($event)"
            >
            </scw-mat-checkbox>
          </div>
          <ng-container *ngIf="i !== 0">
            <ng-container *ngIf="header.value === 'approvalStepPosition'; else else1">
              <scw-mat-border-coloring
                class="d-flex h-100"
                [type]="
                  item.isActive
                    ? approvalStatusesClasses[EApprovalStatuses.ACTIVE]
                    : approvalStatusesClasses[item[header.value]] ||
                      approvalStatusesClasses[EApprovalStatuses.SUBMITTED]
                "
                [text]="
                  item.isActive
                    ? approvalStatuses[EApprovalStatuses.ACTIVE]
                    : approvalStatuses[item[header.value]] || approvalStatuses[EApprovalStatuses.SUBMITTED]
                "
              ></scw-mat-border-coloring>
            </ng-container>
            <ng-template #else1>
              <ng-container *ngIf="header.value === 'createdBy'; else else2">
                <app-e-signature [createdAt]="item['createdAt']" [signatureDetails]="item['createdBy']">
                </app-e-signature>
              </ng-container>
            </ng-template>
            <ng-template #else2>
              <ng-container *ngIf="header.value === 'action'; else else3">
                <div class="h-100 w-100 d-flex justify-content-start align-items-center m-0 p-0 gap-3">
                  <scw-mat-small-button
                    *ngIf="
                      [EApprovalStatuses.REJECTED, EApprovalStatuses.NOT_SUBMITTED].includes(
                        item['approvalStepPosition']
                      );
                      else cloneButton
                    "
                    [iconButton]="true"
                    (onClick)="onIssuerActionClicked('edit-modal-open', item.id)"
                  >
                    <em class="fas fa-pen"></em>
                  </scw-mat-small-button>
                  <ng-template #cloneButton>
                    <scw-mat-small-button
                      *ngIf="
                        !(
                          item['approvalStepPosition'] >= EApprovalStatuses.SUBMITTED ||
                          item['approvalStepPosition'] === EApprovalStatuses.REJECTED
                        );
                        else emptyDiv
                      "
                      [iconButton]="true"
                      (onClick)="onIssuerActionClicked('clone', item.id)"
                    >
                      <em class="fas fa-clone"></em>
                    </scw-mat-small-button>
                  </ng-template>
                  <scw-mat-small-button
                    *ngIf="item['approvalStepPosition'] === EApprovalStatuses.NOT_SUBMITTED; else emptyDiv"
                    ngbTooltip="{{
                      item['isSubmittedOnce']
                        ? ('settings.masterData.form.submittedOnceDraftCannotBeDeleted' | translate)
                        : null
                    }}"
                    [autoClose]="false"
                    [disabled]="item['isSubmittedOnce']"
                    [iconButton]="true"
                    ><em class="fas fa-trash"></em
                  ></scw-mat-small-button>
                  <scw-mat-small-button
                    *ngIf="item['approvalStepPosition'] === EApprovalStatuses.NOT_SUBMITTED; else emptyDiv"
                    (onClick)="showFormModal('submit', submit_confirmation, selectedForm?.id)"
                    >{{ 'general.submit' | translate }}
                  </scw-mat-small-button>
                  <scw-mat-small-button
                    *ngIf="!item.isActive && completedAndApproved.includes(item['approvalStepPosition']); else emptyDiv"
                    (onClick)="showFormModal('activate', activation_confirmation_modal, selectedForm?.id)"
                    >{{ 'general.activate' | translate }}
                  </scw-mat-small-button>
                  <ng-template #emptyDiv>
                    <div>&nbsp;</div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-template>
            <ng-template #else3>
              <ng-container *ngIf="header.value === 'history'; else else4">
                <div class="h-100 w-100 d-flex justify-content-start align-items-center m-0 p-0 gap-3">
                  <scw-mat-button
                    type="standard"
                    size="xs"
                    (onClick)="showFormModal('all-version-show', form_modal, selectedForm?.form?.id)"
                  >
                    {{ 'settings.forms.show' | translate }}
                  </scw-mat-button>
                  <scw-mat-button
                    type="standard"
                    size="xs"
                    [iconButton]="true"
                    (onClick)="showFormModal('history', form_history, selectedForm?.id)"
                    ><em class="fas fa-history"></em>
                  </scw-mat-button>
                </div>
              </ng-container>
            </ng-template>
            <ng-template #else4>
              <ng-container *ngIf="header.value === 'allVersion'; else defaultElse">
                <div class="h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0 gap-3">
                  <scw-mat-button
                    type="standard"
                    size="xs"
                    (onClick)="showFormModal('all-version', show_all_versions_modal, item.id)"
                  >
                    {{ 'settings.forms.allVersions.button' | translate }}
                  </scw-mat-button>
                </div>

              </ng-container>
            </ng-template>
            <ng-template #defaultElse>
              <span class="white-space-pre">
                {{ item[header.value] }}
              </span>
            </ng-template>
          </ng-container>
        </td>
      </ng-template>
    </datatable>
  </div>
</div>

<!-- Main Modals -->
<ng-template #form_modal let-modal>
  <scw-mat-form (scwFormSubmit)="checkFormFieldValidity($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        <ng-template [ngIf]="formActionType === 'add'">
          {{ 'forms.addForm' | translate }}
        </ng-template>
        <ng-template [ngIf]="formActionType === 'add-new-version'">
          {{ 'forms.addNewFormVersion' | translate }}
        </ng-template>
        <ng-template [ngIf]="formActionType === 'edit'"> {{ 'forms.editForm' | translate }}</ng-template>
        <ng-template [ngIf]="formActionType === 'all-version-show'">
          {{ 'forms.showVersionInformation' | translate }}
        </ng-template>
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <h6 class="scw-header">
        <em class="fas fa-file"></em>&nbsp;{{ 'general.subtitles.preDefinedFields' | translate }}
      </h6>
      <div class="row">
        <div class="col-md-6">
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.formName.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.formName.value"
              [rules]="formRules.formName"
              [disabled]="formActionType === 'all-version-show'"
              (focusout)="onFocusOut()"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              label="{{ 'settings.forms.modal.formApprovalWorkflow.label' | translate }}*"
              class="scw_mat-add_edit_modal-item-input"
              [rules]="formRules.formApprovalWorkFlow"
              [data]="workflowData$"
              [disabled]="formActionType === 'all-version-show'"
              [autoPosition]="false"
              [(inputModel)]="formForm.formApprovalWorkFlow.value"
              (inputModelChange)="onWorkFlowChanged($event, 'formApprovalDisableEyeIcon')"
              [enableSearchFilter]="true"
            ></scw-mat-select>
            <scw-mat-button
              class="display-information-button"
              type="standard"
              [iconButton]="true"
              [disabled]="formApprovalDisableEyeIcon"
              (onClick)="onShowWorkFlowStepClicked(formForm.formApprovalWorkFlow.value, show_work_flow_steps_modal)"
            >
              <em class="fas fa-eye"></em>
            </scw-mat-button>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.versionNumber.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.versionNumber.value"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.activityType.label' | translate }}*"
              [rules]="formRules.activityType"
              [data]="formActivityTypeDropdownItems$"
              [disabled]="formActionType === 'all-version-show'"
              [(inputModel)]="formForm.activityType.value"
              [position]="'bottom'"
              [autoPosition]="false"
            ></scw-mat-select>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.checkInMechanism.label' | translate }}*"
              [rules]="formRules.checkInMechanism"
              [disabled]="formActionType === 'all-version-show'"
              [(inputModel)]="formForm.useCheckIn.value"
              [data]="yesNo"
              [position]="'bottom'"
              [autoPosition]="false"
            ></scw-mat-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.formId.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.formId.value"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              label="{{ 'settings.forms.modal.entryReviewWorkflow.label' | translate }}*"
              class="scw_mat-add_edit_modal-item-input"
              [rules]="formRules.entryReviewWorkFlow"
              [enableSearchFilter]="true"
              [data]="workflowData$"
              [disabled]="formActionType === 'all-version-show'"
              [(inputModel)]="formForm.entryReviewWorkFlow.value"
              [autoPosition]="false"
              (inputModelChange)="onWorkFlowChanged($event, 'entryReviewWorkFlowDisableEyeIcon')"
            ></scw-mat-select>
            <scw-mat-button
              class="display-information-button"
              type="standard"
              [iconButton]="true"
              [disabled]="entryReviewWorkFlowDisableEyeIcon"
              (onClick)="onShowWorkFlowStepClicked(formForm.entryReviewWorkFlow.value, show_work_flow_steps_modal)"
            >
              <em class="fas fa-eye"></em>
            </scw-mat-button>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.legacyId.label' | translate }}"
              [block]="true"
              [(inputModel)]="formForm.legacyId.value"
              [rules]="formRules.legacyId"
              [disabled]="formActionType === 'all-version-show'"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <ng-template [ngIf]="formActionType === 'all-version-show'" [ngIfElse]="editableInput">
              <scw-mat-datepicker
                class="scw_mat-add_edit_modal-item-input"
                label="{{ 'settings.forms.modal.issuedDate.label' | translate }}"
                dataPropertyKey="issuedDate"
                [(inputModel)]="formForm.issuedDate.value"
                [singleDatePicker]="true"
                (inputModelChange)="dateInputModelChanged()"
                [showCalendarIcon]="false"
                [showClearButton]="false"
                [disabled]="true"
              ></scw-mat-datepicker>
            </ng-template>
            <ng-template #editableInput>
              <scw-mat-datepicker
                class="scw_mat-add_edit_modal-item-input"
                label="{{ 'settings.forms.modal.issuedDate.label' | translate }}"
                dataPropertyKey="issuedDate"
                [(inputModel)]="formForm.issuedDate.value"
                [singleDatePicker]="true"
                [autoApply]="true"
                [showCustomRangeLabel]="false"
                [ranges]="null"
                [showClearButton]="true"
                [minDate]="minDate"
                (inputModelChange)="dateInputModelChanged()"
              ></scw-mat-datepicker>
            </ng-template>
          </div>
        </div>
      </div>
      <hr class="scw-hr" />
      <h6 class="scw-header" *ngIf="modalContent.length">
        <em class="fas fa-user"></em>&nbsp;{{ 'general.subtitles.userDefinedFields' | translate }}
      </h6>
      <scw-user-defined-field-renderer
        (isValid)="sendFormToAPI($event)"
        [actionSubject]="actionSubject"
        [fieldArray]="modalContent"
        [disableAllFields]="disableAllFields"
        [(inputModel)]="userDefinedFieldInputModels"
        [twoColumns]="true"
      ></scw-user-defined-field-renderer>
      <div class="pt-3 mb-3 px-3 inner-form-section">
        <ng-template [ngIf]="formActionType === 'all-version-show'" [ngIfElse]="notClone">
          <form action="javascript:void(0);" onSubmit="return false;" autocomplete="off">
            <scw-formio [form]="{ components: formClone.components }"></scw-formio>
          </form>
        </ng-template>
        <ng-template #notClone>
          <form action="javascript:void(0);" onSubmit="return false;" autocomplete="off">
            <form-builder
              [form]="form"
              [options]="formIoConfiguration"
              [rebuild]="rebuildEmitter.asObservable()"
            ></form-builder>
          </form>
        </ng-template>
      </div>
      <div class="float-right">
        <scw-mat-button size="xs" type="standard" (onClick)="showPreviewModal(form_preview_modal)">
          {{ 'settings.forms.formPreview' | translate }}
        </scw-mat-button>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <ng-template [ngIf]="formActionType !== 'all-version-show'" [ngIfElse]="close">
          <scw-mat-button type="standard" (onClick)="modal.close()">
            {{ 'general.cancel' | translate }}
          </scw-mat-button>
          <scw-mat-button [isSubmitButton]="true">
            <ng-template [ngIf]="formActionType === 'add'" [ngIfElse]="saveChanges">
              {{ 'forms.addForm' | translate }}
            </ng-template>
            <ng-template #saveChanges>
              {{ 'general.saveChanges' | translate }}
            </ng-template>
          </scw-mat-button>
        </ng-template>
        <ng-template #close>
          <scw-mat-button type="standard" (onClick)="modal.close()">
            {{ 'general.close' | translate }}
          </scw-mat-button>
        </ng-template>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<ng-template #show_all_versions_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="mw-50 modal-title scw-header">{{ selectedForm?.name }}</h3>
    <h3 class="mw-25 modal-title scw-header">&nbsp;({{ selectedForm?.form?.formId }})</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedFormActivationInProgress && !selectedForm?.form?.toBeArchived" class="d-flex gap-1">
      <span class="warning-text c-red">
        <em class="sub-text-icon fas fa-exclamation-circle c-red"></em>
        {{ 'settings.forms.modal.allVersions.pendingActivation' | translate }}
      </span>
    </div>
    <div class="d-flex justify-content-between width-100">
      <div>
        <div *ngIf="selectedForm?.form?.isArchived" class="d-flex gap-1">
          <span class="warning-text c-red">
            <em class="sub-text-icon fas fa-exclamation-circle c-red"></em
            >{{ 'settings.forms.modal.allVersions.formArchived' | translate }}</span
          >
        </div>
        <div
          *ngIf="
            selectedForm?.form?.toBeArchived &&
            !selectedForm?.form?.approveCompleted &&
            !selectedForm?.form?.archiveInProgress
          "
          class="d-flex gap-1"
        >
          <span class="warning-text c-red">
            <em class="sub-text-icon fas fa-exclamation-circle c-red"></em>
            {{ 'settings.forms.modal.allVersions.formFlagToBeArchived' | translate }}</span
          >
          <span class="warning-text">({{ 'general.awaitingApproval' | translate }})</span>
        </div>
        <div
          *ngIf="
            selectedForm?.form?.toBeArchived &&
            selectedForm?.form?.approveCompleted &&
            !selectedForm?.form?.isArchived &&
            !selectedForm?.form?.archiveInProgress
          "
          class="d-flex gap-1"
        >
          <span class="warning-text c-red">
            <em class="sub-text-icon fas fa-exclamation-circle c-red"></em>
            {{ 'settings.forms.modal.allVersions.formFlagToBeArchived' | translate }}
          </span>
          <span class="warning-text">({{ 'general.approved' | translate }})</span>
        </div>
        <div
          *ngIf="
            selectedForm?.form?.archiveInProgress && selectedForm?.form?.toBeArchived && !selectedForm?.form?.isArchived
          "
          class="d-flex gap-1"
        >
          <span class="warning-text c-red">
            <em class="sub-text-icon fas fa-exclamation-circle c-red"></em>
            {{ 'settings.forms.modal.allVersions.flaggedAndAutomaticArchiveInfo' | translate }}
          </span>
        </div>
      </div>
      <scw-mat-button
        *ngIf="
          selectedForm?.form?.toBeArchived || selectedForm?.form?.isArchived || selectedForm?.form?.approveCompleted
        "
        class="float-right"
        type="standard"
        size="xs"
        (onClick)="showFormModal('history', form_history, selectedForm?.id, true)"
      ><em class="fas fa-history"></em>
        {{ 'logbook.settings.allVersions.viewAllHistory' | translate }}
      </scw-mat-button
      >
      <div *ngIf="!(selectedForm?.form?.toBeArchived || selectedForm?.form?.isArchived)">
        <div
          *ngIf="
            !selectedForm?.form?.toBeArchived &&
            !selectedForm?.form?.isArchived &&
            !selectedForm?.form?.approveCompleted
          "
          class="d-flex flex-row gap-3 mb-16px"
        >
          <scw-mat-button
            type="danger"
            size="xs"
            [disabled]="isFlagToArchiveDisabled"
            (onClick)="showFormModal('flag-to-be-archive', flag_to_be_archived_confirmation_modal)"
          >{{ 'forms.modal.allVersions.flagToBeArchived' | translate }}
          </scw-mat-button
          >
          <scw-mat-button
            type="standard"
            size="xs"
            (onClick)="showFormModal('history', form_history, selectedForm?.id, true)"
          ><em class="fas fa-history"></em>
            {{ 'logbook.settings.allVersions.viewAllHistory' | translate }}
          </scw-mat-button
          >
          <scw-mat-button type="standard" size="xs" (onClick)="onIssuerActionClicked('add-new-version-modal-open')">
            <em class="fas fa-plus"></em>
            {{ 'forms.modal.allVersions.addNewVersion' | translate }}
          </scw-mat-button>
        </div>
      </div>
    </div>
    <datatable
      noDataText="{{ 'datatable.noData' | translate }}"
      [items]="formVersionData$"
      [headers]="formVersionTableHeaders"
      [itemsCount]="formVersionsCount$"
      [currentPage]="getFormVersionsParams.page"
      [rowsPerPage]="getFormVersionsParams.rowsPerPage"
      [justDataTable]="true"
      [shouldPaginate]="true"
      [serverSide]="true"
      (onDataRequest)="onFormVersionsDataRequest($event)"
    >
      <ng-template let-item let-i="index">
        <td
          *ngFor="let header of formVersionTableHeaders; index as i"
          [class.versionCellWithBorderColor]="header.value === 'approvalStepPosition'"
        >
          <ng-container *ngIf="header.value === 'approvalStepPosition'; else else1">
            <scw-mat-border-coloring
              class="d-flex h-100"
              [type]="
                item.isActive
                  ? approvalStatusesClasses[EApprovalStatuses.ACTIVE]
                  : approvalStatusesClasses[item[header.value]] || approvalStatusesClasses[EApprovalStatuses.SUBMITTED]
              "
              [text]="
                item.isActive
                  ? approvalStatuses[EApprovalStatuses.ACTIVE]
                  : approvalStatuses[item[header.value]] || approvalStatuses[EApprovalStatuses.SUBMITTED]
              "
            ></scw-mat-border-coloring>
          </ng-container>
          <ng-template #else1>
            <ng-container *ngIf="header.value === 'createdBy'; else else2">
              <app-e-signature
                [createdAt]="item['createdAt']"
                [signatureDetails]="{
                  firstName: item['createdByUserFirstName'],
                  lastName: item['createdByUserLastName'],
                  title: item['createdByTitle'],
                  email: item['createdByEmail']
                }"
              >
              </app-e-signature>
            </ng-container>
          </ng-template>
          <ng-template #else2>
            <ng-container *ngIf="header.value === 'action'; else else3">
              <div class="h-100 w-100 d-flex justify-content-start align-items-center m-0 p-0 gap-3">
                <scw-mat-small-button
                  *ngIf="
                    [EApprovalStatuses.SEND_BACK, EApprovalStatuses.NOT_SUBMITTED].includes(
                      item['approvalStepPosition']
                    );
                    else cloneButton
                  "
                  [iconButton]="true"
                  (onClick)="onIssuerActionClicked('edit-modal-open', item?.id)"
                >
                  <em class="fas fa-pen"></em>
                </scw-mat-small-button>
                <ng-template #cloneButton>
                  <scw-mat-small-button
                    *ngIf="
                      !(
                        item['approvalStepPosition'] >= EApprovalStatuses.SUBMITTED ||
                        item['approvalStepPosition'] === EApprovalStatuses.SEND_BACK
                      );
                      else emptyDiv
                    "
                    [iconButton]="true"
                    (onClick)="onIssuerActionClicked('clone', item?.id)"
                  >
                    <em class="fas fa-clone"></em>
                  </scw-mat-small-button>
                </ng-template>
                <scw-mat-small-button
                  *ngIf="item['approvalStepPosition'] === EApprovalStatuses.NOT_SUBMITTED; else emptyDiv"
                  ngbTooltip="{{
                    item['isSubmittedOnce']
                      ? ('settings.masterData.form.submittedOnceDraftCannotBeDeleted' | translate)
                      : null
                  }}"
                  [autoClose]="false"
                  [disabled]="item['isSubmittedOnce']"
                  [iconButton]="true"
                  (onClick)="showFormModal('delete', deletion_confirmation_modal, item?.id)"
                ><em class="fas fa-trash"></em
                ></scw-mat-small-button>
                <scw-mat-small-button
                  *ngIf="item['approvalStepPosition'] === EApprovalStatuses.NOT_SUBMITTED; else emptyDiv"
                  (onClick)="showFormModal('submit', submit_confirmation, item?.id)"
                >{{ 'general.submit' | translate }}
                </scw-mat-small-button>
                <scw-mat-small-button
                  *ngIf="!item.isActive && completedAndApproved.includes(item['approvalStepPosition']); else emptyDiv"
                  (onClick)="showFormModal('activate', activation_confirmation_modal, item?.id)"
                  [disabled]="selectedFormActivationInProgress"
                >{{ 'general.activate' | translate }}
                </scw-mat-small-button>
                <ng-template #emptyDiv>
                  <div>&nbsp;</div>
                </ng-template>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #else3>
            <ng-container *ngIf="header.value === 'history'; else defaultElse">
              <div class="h-100 w-100 d-flex justify-content-start align-items-center m-0 p-0 gap-3">
                <scw-mat-button
                  type="standard"
                  size="xs"
                  (onClick)="showFormModal('all-version-show', show_all_version_modal, item?.id)"
                >{{ 'general.show' | translate }}
                </scw-mat-button
                >
                <scw-mat-button
                  type="standard"
                  size="xs"
                  [iconButton]="true"
                  (onClick)="showFormModal('history', form_history, item?.id)"
                ><em class="fas fa-history"></em
                ></scw-mat-button>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #defaultElse>
            <span class="white-space-pre">
              {{ item[header.value] }}
            </span>
          </ng-template>
        </td>
      </ng-template>
    </datatable>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button
        *ngIf="!selectedForm?.form?.isArchived"
        [disabled]="
          !(selectedForm?.form?.toBeArchived && selectedForm?.form?.approveCompleted) ||
          !!selectedForm?.form?.archiveInProgress
        "
        type="danger"
        (onClick)="showFormModal('archive', archive_confirmation_modal)"
      >
        {{ 'general.archiveForm' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #show_work_flow_steps_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.showWorkFlowSteps.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <label class="input-label">{{ 'settings.forms.showWorkFlowSteps.text' | translate }}</label>
    <div class="pt-3 mb-3 px-3 pb-1 form-frame">
      <datatable
        noDataText="{{ 'datatable.noData' | translate }}"
        [items]="workflowStepData$"
        [headers]="workFlowStepHeaders"
        [justDataTable]="true"
        [shouldPaginate]="false"
      >
        <ng-template let-item let-i="index">
          <td *ngFor="let header of workFlowStepHeaders; index as i">
            {{ item[header.value] }}
          </td>
        </ng-template>
      </datatable>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #form_history let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ recordHeader }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="scw_mat-add_edit_modal-body">
      <div class="scw_mat-add_edit_modal-item" *ngIf="!isAllViewHistory">
        <h5 class="scw-header scw-header-muted mt-3">
          {{ 'general.version' | translate }}: {{ targetItem?.versionNumber }}
        </h5>
      </div>
      <div class="scw_mat-add_edit_modal-item">
        <div class="pt-3 mb-3 px-3 inner-form-section">
          <datatable
            class="w-100"
            [items]="formHistoryData$"
            [headers]="historyTableHeader"
            [justDataTable]="true"
            [serverSide]="false"
            [shouldPaginate]="false"
          >
            <ng-template let-item>
              <ng-container *ngFor="let header of historyTableHeader">
                <td>
                  <ng-container *ngIf="header.value === 'actionBy'; else actionAt">
                    <app-e-signature
                      [signatureDetails]="item['createdBy']"
                      [createdAt]="item['createdAt']"
                    ></app-e-signature>
                  </ng-container>
                  <ng-template #actionAt>
                    <ng-container *ngIf="header.value === 'createdAt'; else action">
                      <span class="white-space-pre">
                        {{ item[header.value] | momentDate: undefined:{ withoutYear: false, withLineBreak: true } }}
                      </span>
                    </ng-container>
                  </ng-template>
                  <ng-template #action>
                    <ng-container *ngIf="header.value === 'action'; else reason">
                      {{ 'general.userActions.' + item[header.value] | translate }}
                    </ng-container>
                  </ng-template>
                  <ng-template #reason>
                    <ng-container *ngIf="header.value === 'reason'; else base">
                      {{ item[header.value] | logHistoryReason }}
                    </ng-container>
                  </ng-template>
                  <ng-template #base>
                    {{ item[header.value] }}
                  </ng-template>
                </td>
              </ng-container>
            </ng-template>
          </datatable>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #form_preview_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ formForm.formName.value ? formForm.formName.value : selectedForm?.name }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body printableModalBody" id="printableModal">
    <div class="is-printable">
      <h6 class="scw-header">
        <em class="fas fa-file"></em>&nbsp;{{ 'general.subtitles.preDefinedFields' | translate }}
      </h6>
      <div class="row">
        <div class="col-md-6">
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.formName.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.formName.value"
              [rules]="formRules.formName"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              label="{{ 'settings.forms.modal.formApprovalWorkflow.label' | translate }}*"
              class="scw_mat-add_edit_modal-item-input"
              [rules]="formRules.formApprovalWorkFlow"
              [data]="workflowData$"
              [disabled]="true"
              [enableSearchFilter]="true"
              [(inputModel)]="formForm.formApprovalWorkFlow.value"
              (inputModelChange)="onWorkFlowChanged($event, 'formApprovalDisableEyeIcon')"
            ></scw-mat-select>
            <scw-mat-button
              class="display-information-button"
              type="standard"
              [disabled]="formApprovalDisableEyeIcon"
              [iconButton]="true"
              (onClick)="onShowWorkFlowStepClicked(formForm.formApprovalWorkFlow.value, show_work_flow_steps_modal)"
            >
              <em class="fas fa-eye"></em>
            </scw-mat-button>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.versionNumber.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.versionNumber.value"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.activityType.label' | translate }}*"
              [rules]="formRules.activityType"
              [data]="formActivityTypeDropdownItems$"
              [disabled]="true"
              [(inputModel)]="formForm.activityType.value"
              [position]="'bottom'"
              [autoPosition]="false"
            ></scw-mat-select>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.checkInMechanism.label' | translate }}*"
              [rules]="formRules.checkInMechanism"
              [data]="yesNo"
              [disabled]="true"
              [(inputModel)]="formForm.useCheckIn.value"
              [position]="'bottom'"
              [autoPosition]="false"
            ></scw-mat-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.formId.label' | translate }}*"
              [block]="true"
              [(inputModel)]="formForm.formId.value"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-select
              label="{{ 'settings.forms.modal.entryReviewWorkflow.label' | translate }}*"
              class="scw_mat-add_edit_modal-item-input"
              [rules]="formRules.entryReviewWorkFlow"
              [enableSearchFilter]="true"
              [data]="workflowData$"
              [disabled]="true"
              [(inputModel)]="formForm.entryReviewWorkFlow.value"
              (inputModelChange)="onWorkFlowChanged($event, 'entryReviewWorkFlowDisableEyeIcon')"
            ></scw-mat-select>
            <scw-mat-button
              class="display-information-button"
              type="standard"
              [iconButton]="true"
              [disabled]="entryReviewWorkFlowDisableEyeIcon"
              (onClick)="onShowWorkFlowStepClicked(formForm.entryReviewWorkFlow.value, show_work_flow_steps_modal)"
            >
              <em class="fas fa-eye"></em>
            </scw-mat-button>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <scw-mat-input
              class="scw_mat-add_edit_modal-item-input"
              label="{{ 'settings.forms.modal.legacyId.label' | translate }}"
              [block]="true"
              [(inputModel)]="formForm.legacyId.value"
              [rules]="formRules.legacyId"
              [disabled]="true"
            ></scw-mat-input>
          </div>
          <div class="scw_mat-add_edit_modal-item">
            <ng-template [ngIf]="formActionType === 'all-version-show'" [ngIfElse]="editableInput">
              <scw-mat-datepicker
                class="scw_mat-add_edit_modal-item-input"
                label="{{ 'settings.forms.modal.issuedDate.label' | translate }}"
                dataPropertyKey="issuedDate"
                [(inputModel)]="formForm.issuedDate.value"
                [disabled]="true"
                [singleDatePicker]="true"
                [autoApply]="true"
                [showCustomRangeLabel]="false"
                [ranges]="null"
                [showClearButton]="false"
                [showCalendarIcon]="false"
                [formatInitialModel]="false"
              ></scw-mat-datepicker>
            </ng-template>
            <ng-template #editableInput>
              <scw-mat-datepicker
                class="scw_mat-add_edit_modal-item-input"
                label="{{ 'settings.forms.modal.issuedDate.label' | translate }}"
                dataPropertyKey="issuedDate"
                [(inputModel)]="formForm.issuedDate.value"
                [disabled]="true"
                [singleDatePicker]="true"
                [autoApply]="true"
                [showCustomRangeLabel]="false"
                [ranges]="null"
                [showClearButton]="false"
                [showCalendarIcon]="false"
                [minDate]="minDate"
                [formatInitialModel]="false"
              ></scw-mat-datepicker>
            </ng-template>
          </div>
        </div>
      </div>
      <hr class="scw-hr mb-0 pb-4" />
      <h6 class="scw-header" *ngIf="modalContent.length">
        <em class="fas fa-user"></em>&nbsp;{{ 'general.subtitles.userDefinedFields' | translate }}
      </h6>
      <scw-user-defined-field-renderer
        (isValid)="sendFormToAPI($event)"
        [actionSubject]="actionSubject"
        [fieldArray]="previewModalContent"
        [disableAllFields]="true"
        [(inputModel)]="userDefinedFieldInputModels"
        [twoColumns]="true"
        [isPreviewModal]="true"
      ></scw-user-defined-field-renderer>
    </div>
    <form action="javascript:void(0);" onSubmit="return false;" autocomplete="off" class="printable-formio-form">
      <scw-formio [form]="formClone" (ready)="formIOLinkObserverSelector()"></scw-formio>
    </form>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="decidePDFExportType('printableModalBody')">
        {{ 'general.download' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<!-- Confirmation Modals -->

<ng-template #flag_to_be_archived_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.flagToBeArchive.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.forms.flagToBeArchive.text' | translate }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onIssuerActionClicked('flag-to-be-archive', null)">
        {{ 'general.flagToBeArchived' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #archive_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.archiveForm.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.forms.archiveForm.text' | translate }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onIssuerActionClicked('archive', null)">
        {{ 'general.archiveForm' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #ongoing_information_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.ongoingInfo.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      <span *ngIf="formActionType === 'archive'">
        {{ 'settings.forms.ongoingInfo.currentlyInUse' | translate }}
      </span>
      <span *ngIf="formActionType === 'activate'">
        {{ 'settings.forms.ongoingInfo.currentlyInUsePreviousVersion' | translate }}
      </span>
    </h5>
    <ul>
      <li *ngFor="let ongoingForm of selectedFormOngoingForms">
        {{ ongoingForm.id }} - {{ ongoingForm.logbookVersion.logbook.logbookId }} -
        {{ ongoingForm.logbookVersion.name }}
        <ng-template
          [ngIf]="clientFormActivationFlow === EFormActivationFlow.ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES"
        >
          ({{ 'general.versionNumberShorthand' | translate }}{{ ongoingForm.formVersion.versionNumber }})
        </ng-template>
      </li>
    </ul>
    <h5 *ngIf="formActionType === 'archive'" class="scw-header scw-header-muted mt-3">
      {{ 'settings.forms.ongoingInfo.systemAutomaticArchive' | translate }}
    </h5>
    <h5 *ngIf="formActionType === 'activate'" class="scw-header scw-header-muted mt-3">
      {{
        (clientFormActivationFlow === EFormActivationFlow.ACTIVATE_VERSION_WHILE_THERE_ARE_ONGOING_ENTRIES
          ? 'settings.forms.ongoingInfo.systemAutomaticObsolete'
          : 'settings.forms.ongoingInfo.systemAutomaticActivate'
        ) | translate
      }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #submit_confirmation let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.submitForm.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.forms.submitForm.text' | translate }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="onIssuerActionClicked('submit', null)">
        {{ 'general.submit' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #activation_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.activateForm.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.forms.activateForm.text' | translate }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="onIssuerActionClicked('activate', null)">
        {{ 'general.activate' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #deletion_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <ng-template [ngIf]="formVersionData$.length > 1">
      <h3 class="modal-title scw-header">
        {{ 'settings.forms.deleteForm.headerVersion' | translate }}
      </h3>
    </ng-template>
    <ng-template [ngIf]="formVersionData$.length === 1">
      <h3 class="modal-title scw-header">
        {{ 'settings.forms.deleteForm.header' | translate }}
      </h3>
    </ng-template>

    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      <ng-template [ngIf]="formVersionData$.length === 1" [ngIfElse]="deleteVersion">
        {{ 'settings.forms.deleteForm.text' | translate }}
      </ng-template>
      <ng-template #deleteVersion>
        {{ 'settings.forms.deleteForm.textVersion' | translate }}
      </ng-template>
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onIssuerActionClicked('delete', null)">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #draft_exist_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.logbooks.draftVersionExists.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.logbooks.draftVersionExists.text' | translate }}
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #master_data_mismatch_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header" *ngIf="isHasNotWorkflow && !isMasterMismatch">
      {{ 'settings.forms.inactiveWorkflow.header' | translate }}
    </h3>
    <h3 class="modal-title scw-header" *ngIf="isMasterMismatch && !isHasNotWorkflow">
      {{ 'settings.forms.masterDataMismatch.header' | translate }}
    </h3>
    <h3 class="modal-title scw-header" *ngIf="isHasNotWorkflow && isMasterMismatch">
      {{ 'settings.forms.inactiveWorkflowAndMasterMismatch' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      <ng-template [ngIf]="formActionType === 'edit-modal-open'">
        {{ 'settings.forms.masterDataMismatch.text' | translate }}
      </ng-template>
      <ng-template [ngIf]="formActionType === 'submit' && isMasterMismatch">
        {{ 'settings.forms.masterDataMismatch.textSubmit' | translate }}
      </ng-template>
      <ng-template [ngIf]="isHasNotWorkflow && isMasterMismatch">
        <br>
        <em class="fas fa-exclamation-circle mt-3"></em>
      </ng-template>
      <ng-template [ngIf]="isHasNotWorkflow">
        {{ 'settings.forms.inactiveWorkflow.text' | translate }}
      </ng-template>
    </h5>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="proceedToEdit()">
        {{ 'general.proceedToEdit' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #passed_issue_date_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'settings.forms.passedIssueDate.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="scw-header scw-header-muted mt-3">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'settings.forms.passedIssueDate.text' | translate }}
    </h5>
    <div *ngFor="let form of passedIssueDateForms; index as i">
      <ul class="scw-basic-list">
        <li>FRM-{{ form.formId }} - {{ form.name }}</li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<scw-before-action-preparer
  [reasonActionsToSubscribe]="reasonActions"
  [issuerActionsToSubscribe]="issuerActions"
  [parentRef]="this"
  [functionToContinue]="dispatchAction"
  [actionSubject]="beforeActionPreparerActionSubject"
  [dispatchHideLoader]="false"
></scw-before-action-preparer>

<file-generator
  [filterMap]="[]"
  [file]="file"
  [isPrintAllowed]="isPrintAllowedSubject"
  [shouldPrintFilters]="false"
  [bannerConfig]="bannerConfiguration"
></file-generator>

<document-generator
  [document]="document"
  [initializePrint]="initializePrintSubject"
></document-generator>


<ng-template #excel_error_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ excelErrorHeader }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="onExcelErrorModalClose(modal)">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <h6 class="scw-header">
        {{ excelErrorMessage }}
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="onExcelErrorModalClose(modal)">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<app-scope-settings-navigator-modal
  [scopeType]="'form'"
></app-scope-settings-navigator-modal>

<ng-template #show_all_version_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'forms.showVersionInformation' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <scw-compare-changes
      [compareHeaders]="compareHeaders"
      [compareFields]="compareFields"
      [fieldArray]="{ after: modalContent, before: previousVersionMasterData }"
      [showUserDefinedFields]="true"
      [formioFields]="compareFormio"
      (buttonClick)="onShowWorkFlowStepClicked($event, show_work_flow_steps_modal)"
    >
      <div class="float-right">
        <scw-mat-button size="xs" type="standard" (onClick)="showPreviewModal(form_preview_modal)">
          {{ 'settings.forms.formPreview' | translate }}
        </scw-mat-button>
      </div>
    </scw-compare-changes>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<bulk-error-modal #bulk_error_modal></bulk-error-modal>

<bulk-action-confirmation-modal
  #bulk_action_confirmation_modal
  (onBulkActionConfirmation)="onBulkAction($event)"
></bulk-action-confirmation-modal>
