import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatSearchComponent } from './scw-mat-search.component';
import { ScwMatInputModule } from '../scw-mat-input/scw-mat-input.module';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';

@NgModule({
  imports: [CommonModule, ScwMatInputModule, ScwMatSmallButtonModule],
  exports: [ScwMatSearchComponent],
  declarations: [ScwMatSearchComponent],
})
export class ScwMatSearchModule {}
