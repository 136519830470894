import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import { MyReportsService } from '../../../shared/service/reports/my-reports/my-reports.service';
import * as ObjectActions from '../../reports/my-reports/my-reports.actions';
import * as AppActions from '../../app/actions';
import { catchError, of, switchMap } from 'rxjs';
import { IBaseOneResponse, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IAddMyReport, IMyReportsData } from './my-reports.model';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';

@Injectable()
export class MyReportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: MyReportsService,
  ) {}

  getMyReportData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_MY_REPORTS_LOADING),
      switchMap((payload: ObjectActions.StartMyReportsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest(payload.tableQuery);

        return this.service.getMyReportData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IMyReportsData>) => {
            return of(new ObjectActions.MyReportsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_GET_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartGetRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getReport(payload.id).pipe(
          switchMap((response: IBaseOneResponse<IMyReportsData>) => {
            return of(
              new ObjectActions.GetRecordCompleted(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(errorRes),
              new ObjectActions.ReportNotFound(),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ADD_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartAddRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addReport(payload.record, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IAddMyReport>) => {
            return of(new ObjectActions.AddRecordCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_EDIT_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartEditRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editReport(payload.record, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IAddMyReport>) => {
            return of(new ObjectActions.EditRecordCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  removeMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_REMOVE_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartRemoveRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteReport(payload.record, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IAddMyReport>) => {
            return of(new ObjectActions.RemoveRecordCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  removeBulkMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_BULK_REMOVE_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartBulkRemoveRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkDeleteReport(payload.payload, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<IAddMyReport>) => {
            return of(new ObjectActions.BulkRemoveRecordCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
