import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { MessageAction } from '../../model/enum/message-action';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { User } from '../../../store/user/model';
import { Router } from '@angular/router';
import { ISignalrBroadcast } from './signalr.model';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public broadcastMessage: Subject<ISignalrBroadcast> = new Subject<ISignalrBroadcast>();
  public connectionIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  get hubConnection(): signalR.HubConnection {
    return this.signalRHubConnection;
  }

  private signalRHubConnection!: signalR.HubConnection;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<LogbookAppState>,
    private readonly router: Router,
  ) {
    this.buildConnection();
    this.listenBroadcastMessage();
    this.signalRHubConnection.onclose(this.startConnection);
    this.signalRHubConnection.onreconnected((connectionId) => {
      this.connectionIdSubject.next(connectionId ?? null);
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          if (state.clientCode) {
            this.addToGroup('page-refresh', state.clientCode);
          }
        });
    });
  }

  public buildConnection(): void {
    this.signalRHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + environment.signalRHubUrl)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build();
  }

  public async startConnection(): Promise<void> {
    try {
      this.listenBroadcastMessage();
      await this.signalRHubConnection.start();
      this.connectionIdSubject.next(this.signalRHubConnection.connectionId);
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          if (state.clientCode) {
            this.addToGroup('page-refresh', state.clientCode);
          }
        });
    } catch (err) {
      const threeSeconds: number = 3000;
      setTimeout(this.startConnection, threeSeconds);
    }
  }

  public addToGroup(objectType: string, objectId: number | string): void {
    if (this.signalRHubConnection.connectionId && environment.production) {
      this.http
        .get(
          `${environment.apiUrl + environment.signalRHubUrl}/add-to-group/${objectType}/${objectId}/${this.signalRHubConnection.connectionId}`,
        )
        .subscribe();
    }
  }

  private listenBroadcastMessage(): void {
    this.signalRHubConnection.on('broadcastMessage', (message: number, additionalData: any) => {
      if (message === MessageAction.PAGE_RELOAD) {
        return window.location.reload();
      }

      this.broadcastMessage.next({
        message,
        additionalData,
      });
    });
  }
}
