import { Component, ViewChild } from '@angular/core';
import { FormBuilderComponent, FormioForm } from '@formio/angular';
import { formIoConfiguration } from '../../../../shared/helper/component-utilities';

@Component({
  selector: 'app-form-version-detail',
  templateUrl: './form-version-detail.component.html',
  styleUrls: ['./form-version-detail.component.scss'],
})
export class FormVersionDetailComponent {
  @ViewChild('formBuilderComponent') formBuilderComponent!: FormBuilderComponent;
  public form: FormioForm = {
    components: [],
  };
  public formIoConfiguration: any = {
    ...formIoConfiguration,
    noDefaultSubmitButton: true,
    builder: {
      basic: {
        title: 'Basic',
        weight: 0,
        default: true,
        key: 'basic',
        components: {
          textfield: {
            title: 'TextField',
            icon: 'terminal',
            group: 'basic',
            documentation: 'http://help.form.io/userguide/#textfield',
            weight: 0,
            schema: {
              input: true,
              key: 'textField',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'TextField',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'textfield',
              mask: false,
              inputType: 'text',
              inputFormat: 'plain',
              inputMask: '',
              spellcheck: true,
            },
            key: 'textfield',
          },
          textarea: {
            title: 'TextArea',
            group: 'basic',
            icon: 'font',
            documentation: 'http://help.form.io/userguide/#textarea',
            weight: 20,
            schema: {
              input: true,
              key: 'textArea',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'TextArea',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
                minWords: '',
                maxWords: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'textarea',
              mask: false,
              inputType: 'text',
              inputFormat: 'html',
              inputMask: '',
              spellcheck: true,
              rows: 3,
              wysiwyg: false,
              editor: '',
              fixedSize: true,
            },
            key: 'textarea',
          },
          number: {
            title: 'Number',
            icon: 'hashtag',
            group: 'basic',
            documentation: 'http://help.form.io/userguide/#number',
            weight: 30,
            schema: {
              input: true,
              key: 'number',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Number',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                min: '',
                max: '',
                step: 'any',
                integer: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'number',
            },
            key: 'number',
          },
          checkbox: {
            title: 'Checkbox',
            group: 'basic',
            icon: 'check-square',
            documentation: 'http://help.form.io/userguide/#checkbox',
            weight: 50,
            schema: {
              input: true,
              key: 'checkbox',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Checkbox',
              labelPosition: 'right',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'checkbox',
              inputType: 'checkbox',
              dataGridLabel: true,
              value: '',
              name: '',
            },
            key: 'checkbox',
          },
          selectboxes: {
            title: 'SelectBoxes',
            group: 'basic',
            icon: 'plus-square',
            weight: 60,
            documentation: 'http://help.form.io/userguide/#selectboxes',
            schema: {
              input: true,
              key: 'selectBoxes',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'SelectBoxes',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'selectboxes',
              inputType: 'radio',
              values: [
                {
                  label: '',
                  value: '',
                },
              ],
              fieldSet: false,
              inline: false,
            },
            key: 'selectboxes',
          },
          select: {
            title: 'Select',
            group: 'basic',
            icon: 'th-list',
            weight: 70,
            documentation: 'http://help.form.io/userguide/#select',
            schema: {
              input: true,
              key: 'select',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'Select',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'select',
              data: {
                values: [],
                json: '',
                url: '',
                resource: '',
                custom: '',
              },
              clearOnRefresh: false,
              limit: 100,
              dataSrc: 'values',
              valueProperty: '',
              lazyLoad: true,
              filter: '',
              searchEnabled: true,
              searchField: '',
              minSearch: 0,
              readOnlyValue: false,
              authenticate: false,
              template: '<span>{{ item.label }}</span>',
              selectFields: '',
              searchThreshold: 0.3,
              uniqueOptions: false,
              fuseOptions: {
                include: 'score',
                threshold: 0.3,
              },
              customOptions: {},
            },
            key: 'select',
          },
          radio: {
            title: 'Radio',
            group: 'basic',
            icon: 'times-circle',
            weight: 80,
            documentation: 'http://help.form.io/userguide/#radio',
            schema: {
              input: true,
              key: 'radio',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Radio',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'radio',
              inputType: 'radio',
              values: [
                {
                  label: '',
                  value: '',
                },
              ],
              fieldSet: false,
            },
            key: 'radio',
          },
          button: {
            title: 'Button',
            group: 'basic',
            icon: 'stop',
            documentation: 'http://help.form.io/userguide/#button',
            weight: 110,
            schema: {
              input: true,
              key: 'submit',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: false,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Submit',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'button',
              size: 'md',
              leftIcon: '',
              rightIcon: '',
              block: false,
              action: 'submit',
              disableOnInvalid: false,
              theme: 'primary',
              dataGridLabel: true,
            },
            key: 'button',
          },
          password: false,
        },
        componentOrder: ['textfield', 'textarea', 'number', 'checkbox', 'selectboxes', 'select', 'radio', 'button'],
        subgroups: [],
      },
      advanced: {
        title: 'Advanced',
        weight: 10,
        key: 'advanced',
        components: {
          email: {
            title: 'Email',
            group: 'advanced',
            icon: 'at',
            documentation: 'http://help.form.io/userguide/#email',
            weight: 10,
            schema: {
              input: true,
              key: 'email',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'Email',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'email',
              mask: false,
              inputType: 'email',
              inputFormat: 'plain',
              inputMask: '',
              spellcheck: true,
              kickbox: {
                enabled: false,
              },
            },
            key: 'email',
          },
          url: {
            title: 'URL',
            group: 'advanced',
            icon: 'link',
            documentation: 'http://help.form.io/userguide/#url',
            weight: 20,
            schema: {
              input: true,
              key: 'url',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'URL',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'url',
              mask: false,
              inputType: 'url',
              inputFormat: 'plain',
              inputMask: '',
              spellcheck: true,
            },
            key: 'url',
          },
          phoneNumber: {
            title: 'PhoneNumber',
            group: 'advanced',
            icon: 'phone-square',
            weight: 30,
            documentation: 'http://help.form.io/userguide/#phonenumber',
            schema: {
              input: true,
              key: 'phoneNumber',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'PhoneNumber',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'phoneNumber',
              mask: false,
              inputType: 'tel',
              inputFormat: 'plain',
              inputMask: '(999) 999-9999',
              spellcheck: true,
            },
            key: 'phoneNumber',
          },
          datetime: {
            title: 'DateTime',
            group: 'advanced',
            icon: 'calendar',
            documentation: 'http://help.form.io/userguide/#datetime',
            weight: 40,
            schema: {
              input: true,
              key: 'dateTime',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: '',
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'DateTime',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'datetime',
              format: 'yyyy-MM-dd hh:mm a',
              useLocaleSettings: false,
              allowInput: true,
              enableDate: true,
              enableTime: true,
              defaultDate: '',
              displayInTimezone: 'viewer',
              timezone: '',
              datepickerMode: 'day',
              datePicker: {
                showWeeks: true,
                startingDay: 0,
                initDate: '',
                minMode: 'day',
                maxMode: 'year',
                yearRows: 4,
                yearColumns: 5,
                minDate: null,
                maxDate: null,
              },
              timePicker: {
                hourStep: 1,
                minuteStep: 1,
                showMeridian: true,
                readonlyInput: false,
                mousewheel: true,
                arrowkeys: true,
              },
              customOptions: {},
            },
            key: 'datetime',
          },
          time: {
            title: 'Time',
            icon: 'clock-o',
            group: 'advanced',
            documentation: 'http://help.form.io/userguide/#time',
            weight: 55,
            schema: {
              input: true,
              key: 'time',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: true,
              modalEdit: false,
              label: 'Time',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: {
                type: 'input',
              },
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
                minLength: '',
                maxLength: '',
                pattern: '',
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'time',
              mask: false,
              inputType: 'time',
              inputFormat: 'plain',
              inputMask: '',
              spellcheck: true,
              format: 'HH:mm',
              dataFormat: 'HH:mm:ss',
            },
            key: 'time',
          },
          day: false,
          currency: false,
          survey: false,
          address: false,
          tags: false,
          signature: false,
        },
        componentOrder: ['email', 'url', 'phoneNumber', 'datetime', 'time'],
        subgroups: [],
      },
      layout: {
        title: 'Layout',
        weight: 20,
        key: 'layout',
        components: {
          content: {
            title: 'Content',
            group: 'layout',
            icon: 'html5',
            preview: false,
            documentation: 'http://help.form.io/userguide/#content-component',
            weight: 5,
            schema: {
              input: false,
              key: 'content',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Content',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'content',
              html: '',
            },
            key: 'content',
          },
          columns: {
            title: 'Columns',
            icon: 'columns',
            group: 'layout',
            documentation: 'http://help.form.io/userguide/#columns',
            weight: 10,
            schema: {
              input: false,
              key: 'columns',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: false,
              hidden: false,
              clearOnHide: false,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Columns',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              tree: false,
              type: 'columns',
              columns: [
                {
                  components: [],
                  width: 6,
                  offset: 0,
                  push: 0,
                  pull: 0,
                  size: 'md',
                },
                {
                  components: [],
                  width: 6,
                  offset: 0,
                  push: 0,
                  pull: 0,
                  size: 'md',
                },
              ],
              autoAdjust: false,
              hideOnChildrenHidden: false,
            },
            key: 'columns',
          },
          panel: {
            title: 'Panel',
            icon: 'list-alt',
            group: 'layout',
            documentation: 'http://help.form.io/userguide/#panels',
            weight: 30,
            schema: {
              input: false,
              key: 'panel',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: false,
              hidden: false,
              clearOnHide: false,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Panel',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              tree: false,
              type: 'panel',
              title: 'Panel',
              theme: 'default',
              breadcrumb: 'default',
              components: [],
            },
            key: 'panel',
          },
          table: {
            title: 'Table',
            group: 'layout',
            icon: 'table',
            weight: 40,
            documentation: 'http://help.form.io/userguide/#table',
            schema: {
              input: false,
              key: 'table',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: false,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Table',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              tree: false,
              type: 'table',
              numRows: 3,
              numCols: 3,
              rows: [
                [
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                ],
                [
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                ],
                [
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                  {
                    components: [],
                  },
                ],
              ],
              header: [],
              caption: '',
              cloneRows: false,
              striped: false,
              bordered: false,
              hover: false,
              condensed: false,
            },
            key: 'table',
          },
          htmlelement: false,
          fieldset: false,
          tabs: false,
          well: false,
        },
        componentOrder: ['content', 'columns', 'panel', 'table'],
        subgroups: [],
      },
      data: false,
      premium: {
        title: 'Premium',
        weight: 40,
        key: 'premium',
        components: {
          file: {
            title: 'File',
            group: 'premium',
            icon: 'file',
            documentation: 'http://help.form.io/userguide/#file',
            weight: 100,
            schema: {
              input: true,
              key: 'file',
              placeholder: '',
              prefix: '',
              customClass: '',
              suffix: '',
              multiple: false,
              defaultValue: null,
              protected: false,
              unique: false,
              persistent: true,
              hidden: false,
              clearOnHide: true,
              refreshOn: '',
              redrawOn: '',
              tableView: false,
              modalEdit: false,
              label: 'Upload',
              labelPosition: 'top',
              description: '',
              errorLabel: '',
              tooltip: '',
              hideLabel: false,
              tabindex: '',
              disabled: false,
              autofocus: false,
              dbIndex: false,
              customDefaultValue: '',
              calculateValue: '',
              calculateServer: false,
              widget: null,
              attributes: {},
              validateOn: 'change',
              validate: {
                required: false,
                custom: '',
                customPrivate: false,
                strictDateValidation: false,
                multiple: false,
                unique: false,
              },
              conditional: {
                show: null,
                when: null,
                eq: '',
              },
              overlay: {
                style: '',
                left: '',
                top: '',
                width: '',
                height: '',
              },
              allowCalculateOverride: false,
              encrypted: false,
              showCharCount: false,
              showWordCount: false,
              properties: {},
              allowMultipleMasks: false,
              type: 'file',
              image: false,
              privateDownload: false,
              imageSize: '200',
              filePattern: '*',
              fileMinSize: '0KB',
              fileMaxSize: '1GB',
              uploadOnly: false,
            },
            key: 'file',
          },
          form: false,
          resource: false,
          unknown: false,
          recaptcha: false,
        },
        componentOrder: ['file'],
        subgroups: [],
      },
    },
  };
  public isSubmitted: boolean = false;

  public buildForm(): void {
    this.formBuilderComponent?.ngOnChanges(this.form);
    this.isSubmitted = !this.isSubmitted;
  }
}
