<div class="d-flex flex-row align-items-center justify-content-end search-div" *ngIf="showSearchBox">
  <scw-mat-search
    class="search-input"
    placeholder="{{ this.searchPlaceholder }}"
    behavior="delayed"
    [minLength]="0"
    size="xs"
    [block]="true"
    [(inputModel)]="searchString"
    (onSearch)="searchTreeTable($event)"
  ></scw-mat-search>
</div>

<p-treeTable
  #treeTable
  class="tree-table"
  [value]="items"
  [columns]="columns"
  [paginator]="shouldPaginate"
  [rows]="rowsPerPage"
  [lazy]="false"
  [totalRecords]="itemsCount"
  [autoLayout]="true"
  [sortField]="sortBy"
  [class.overflow-y-hidden]="overflowYHidden"
  [class.sticky-end-column]="stickyEndColumn"
  [rowTrackBy]="trackByFunction"
  (onNodeExpand)="onNodeExpand($event)"
>
  <ng-template class="p-template-header" pTemplate="header" let-columns>
    <tr>
      <th
        class="header-cell"
        [ngClass]="col.button ? 'd-flex gap-3 justify-content-start align-items-center' : ''"
        [class.sticky-end-header]="columnIndex > columns.length - 1 - stickyEndColumn && stickyEndColumn"
        [class.checkbox-column]="col.checkbox"
        *ngFor="let col of columns; index as columnIndex"
        [style.width]="col.width"
        [style.right]="
          stickyEndColumn && stickyEndColumnRightValues[columnIndex] ? stickyEndColumnRightValues[columnIndex] : ''
        "
        [id]="col.field"
      >
        {{ col.name }}
        <ng-template [ngIf]="!singleSelect">
          <div [ngClass]="col.class || ''" *ngIf="col.checkbox && !readonly">
            <div class="all-select-checkbox" id="selectOrUnSelectCheckbox" (click)="headerCheckboxChanged(col.field)">
              <span class="mat-checkbox-background"
                ><svg
                  version="1.1"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  class="mat-checkbox-checkmark"
                >
                  <path
                    fill="none"
                    stroke="white"
                    d="M4.1,12.7 9,17.6 20.3,6.3"
                    class="mat-checkbox-checkmark-path"
                  ></path></svg
              ></span>
            </div>
          </div>
          <div *ngIf="col.button && !readonly">
            <div>
              <scw-mat-button
                [type]="col['buttonConfig']['type']"
                [size]="col['buttonConfig']['size']"
                (onClick)="
                  allInteractiveColumnInputsValid ? col['buttonConfig']['onClick'].call(this, $event) : undefined
                "
                [iconButton]="col['buttonConfig']['iconButton']"
              >
                <em
                  [ngClass]="
                    col['buttonConfig']['changeAbleIcon']
                      ? col['buttonConfig']['icon'][0]
                      : col['buttonConfig']['icon'][1]
                  "
                ></em>
                {{ col['buttonConfig']['translation'] }}
              </scw-mat-button>
            </div>
          </div>
          <div [ngClass]="col.class || ''" *ngIf="col.button && !readonly && col.showCancelButton">
            <div class="d-flex">
              <scw-mat-button
                type="standard"
                size="xs"
                (onClick)="interactiveColumnOnCancelButtonClick(col, $event)"
                [iconButton]="true"
              >
                <em class="fas fa-times"></em>
              </scw-mat-button>
            </div>
          </div>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template class="tree-table-body" pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr class="treetable-row">
      <td
        class="body"
        [class.no-padding]="col['hasBorderColoring'] === true"
        *ngFor="let col of columns; let i = index"
        [class.sticky-end-cell]="i > columns.length - 1 - stickyEndColumn && stickyEndColumn"
        [style.right]="stickyEndColumn && stickyEndColumnRightValues[i] ? stickyEndColumnRightValues[i] : ''"
      >
        <div [ngClass]="col['hasBorderColoring'] === true ? 'd-flex h-100' : ''">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
          <ng-template [ngIf]="col['hasBorderColoring'] !== true && !col['actionColumn'] && !col['interactiveColumn']">
            <span [class.white-space-pre]="col.field !== 'includeInScope'">
              {{ rowData[col.field] }}
            </span>
          </ng-template>
          <div
            [ngClass]="col.class || ''"
            *ngIf="rowData['checkboxes'] && rowData['checkboxes'][col.field] !== undefined"
          >
            <scw-mat-checkbox
              [(inputModel)]="rowData['checkboxes'][col.field]"
              (inputModelChange)="onRowCheckboxClick($event, rowData['id'], col.field)"
              [disabled]="readonly || rowData['checkboxes']['disabled']"
            >
            </scw-mat-checkbox>
          </div>
          <div
            *ngIf="rowData['borderColoring'] && rowData['borderColoring'][col.field] !== undefined"
            class="d-flex h-100"
          >
            <scw-mat-border-coloring
              class="d-flex h-100"
              [type]="rowData['borderColoring'][col.field]['type']"
              [text]="rowData['borderColoring'][col.field]['text']"
            >
            </scw-mat-border-coloring>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            *ngIf="rowData['logbookStatesCountColumn'] && rowData['logbookStatesCountColumn'][col.field] !== undefined"
          >
            <div class="pr-3">
              <scw-mat-small-button
                [ngbPopover]="popOverBody"
                popoverClass="lines-popover"
                placement="left"
                class="form-button"
                (onClick)="
                  rowData['logbookStatesCountColumn'][col.field]['onClickShowStatePopOver'].call(this, rowData['id'])
                "
              >
                {{ rowData['logbookStatesCountColumn'][col.field]['translation'] }}
                ({{ rowData['logbookStatesCount'] }})
              </scw-mat-small-button>
            </div>
            <div>
              <scw-mat-small-button
                [iconButton]="true"
                (onClick)="
                  rowData['logbookStatesCountColumn'][col.field]['onClickShowStateEdit'].call(this, rowData['id'])
                "
              >
                <em class="fas fa-pen"></em>
              </scw-mat-small-button>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            *ngIf="rowData['actionColumn'] && rowData['actionColumn'][col.field] !== undefined"
          >
            <scw-mat-button
              [type]="rowData['actionColumn'][col.field]['type']"
              [size]="rowData['actionColumn'][col.field]['size']"
              (onClick)="rowData['actionColumn'][col.field]['onClick'].call(this, $event)"
            >
              {{ rowData['actionColumn'][col.field]['translation'] }}
            </scw-mat-button>
          </div>
          <div
            class="pt-1"
            *ngIf="col['interactiveColumn'] && rowData['input'] && rowData['input']['inputConfiguration'] !== undefined"
          >
            <scw-mat-input
              style="white-space: pre-wrap !important"
              size="xs"
              [block]="true"
              [(inputModel)]="rowData['input']['inputConfiguration']['inputModel']"
              (inputModelChange)="
                rowData['input']['inputConfiguration']['inputModelChange'].call(this, rowData, $event)
              "
              (isValidChange)="interactiveColumnInputIsValidChange($event, rowData.id)"
              [rules]="rowData['input']['inputConfiguration']['rules']"
            >
            </scw-mat-input>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">{{ noDataText }}</td>
    </tr>
  </ng-template>
</p-treeTable>

<ng-template #popOverBody>
  <ng-container *ngTemplateOutlet="templateRef"></ng-container>
</ng-template>
